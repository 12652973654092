import React from "react";
import { connect } from "react-redux";
import { Button, Col, Container, Form, Row, Card, Modal } from "react-bootstrap";
import Breadcrumb from '../components/layout/Breadcrumb';
import PageFooter from "../components/layout/PageFooter";
import PageHeader from "../components/layout/PageHeader";
import { IAppState } from "../redux/storeTypes";
import { useState } from "react";
import RequiredIcon from "../components/RequiredIcon";
import FormActions from "../components/layout/FormActions";

const AccountInfo = () => {
    const [showAccInfoForm, setShowAccInfoForm] = useState(false);
    const [showConInfoForm, setShowConInfoForm] = useState(false);

    const clearForm = () => {
        setShowAccInfoForm(false);
        setShowConInfoForm(false);
    }
    return (
        <>
            <PageHeader />

            <Container className="mt-4">
                <Breadcrumb name='Home/Account information' />
                <Modal show={showAccInfoForm} size="sm" onHide={() => setShowAccInfoForm(false)}>
                    <Modal.Header closeButton />
                    <Modal.Body>
                        <Form>
                            <h3 className="fw-bold">Edit Account information</h3>
                            <Form.Group controlId="accHolderName" className="mt-4">
                                <Form.Label><RequiredIcon />Account holder’s name</Form.Label>
                                <Form.Control type="input" required maxLength={50} placeholder="Enter account holder's name" />
                                <Form.Control.Feedback type="invalid">Enter account holder's name.</Form.Control.Feedback>
                                <FormActions hasSubmit customProps={{ submitButtonDisplay: 'Update' }} onCancel={clearForm} />
                            </Form.Group>
                        </Form>
                    </Modal.Body>
                </Modal>
                <Modal show={showConInfoForm} size="sm" onHide={() => setShowConInfoForm(false)}>
                    <Modal.Header closeButton />
                    <Modal.Body>
                        <Form>
                            <h3 className="fw-bold">Edit Contact information</h3>
                            <Form.Group controlId="accHolderName" className="mt-4">
                                <Form.Label>Phone number</Form.Label>
                                <Form.Control type="number" maxLength={10} placeholder="Enter phone number" />            
                            </Form.Group>
                            <Form.Group controlId="accHolderName" className="mt-4">
                                <Form.Label>Email address</Form.Label>
                                <Form.Control type="input" maxLength={50} placeholder="Enter email address" />
                            </Form.Group>
                            <FormActions hasSubmit customProps={{ submitButtonDisplay: 'Update' }} onCancel={clearForm} />
                        </Form>
                    </Modal.Body>
                </Modal>
                <Card>
                    <Card.Header className="payment-container">
                        <h2>Account information</h2>
                        <Button variant="link" className="brand-link-primary" onClick={() => setShowAccInfoForm(!showAccInfoForm)}>Edit</Button>
                    </Card.Header>
                    <Card.Body>
                        <Form>
                            <Form.Group as={Row} controlId="accountNumber">
                                <Form.Label column sm="4" className="fw-bold">
                                    Account number
                                </Form.Label>
                                <Col sm="8">
                                    <Form.Control plaintext readOnly defaultValue="111946042021" />
                                </Col>
                            </Form.Group>

                            <Form.Group as={Row} controlId="accountName">
                                <Form.Label column sm="4" className="fw-bold">
                                    Account holder’s name
                                </Form.Label>
                                <Col sm="8">
                                    <Form.Control plaintext readOnly placeholder="No name" />
                                </Col>
                            </Form.Group>
                        </Form>
                    </Card.Body>
                </Card>
                <Card>
                    <Card.Header className="payment-container">
                        <h2>Contact information</h2>
                        <Button variant="link" className="brand-link-primary" onClick={() => setShowConInfoForm(!showConInfoForm)}>Edit</Button>
                    </Card.Header>
                    <Card.Body>
                        <Form>
                            <Form.Group as={Row} controlId="accountNumber">
                                <Form.Label column sm="4" className="fw-bold">
                                    Phone number
                                </Form.Label>
                                <Col sm="8">
                                    <Form.Control plaintext readOnly defaultValue="(123) 456-7890" />
                                </Col>
                            </Form.Group>

                            <Form.Group as={Row} controlId="accountName">
                                <Form.Label column sm="4" className="fw-bold">
                                    Email address
                                </Form.Label>
                                <Col sm="8">
                                    <Form.Control plaintext readOnly placeholder="noemail@email.com" />
                                </Col>
                            </Form.Group>
                        </Form>
                    </Card.Body>
                </Card>
                <Card>
                    <Card.Header className="payment-container">
                        <h2>Login credentials</h2>
                        <Button variant="link" className="brand-link-primary">Edit</Button>
                    </Card.Header>
                    <Card.Body>
                        <Form>
                            <Form.Group as={Row} controlId="accountNumber">
                                <Form.Label column sm="4" className="fw-bold">
                                    Username
                                </Form.Label>
                                <Col sm="8">
                                    <Form.Control plaintext readOnly defaultValue="noemail@email.com" />
                                </Col>
                            </Form.Group>

                            <Form.Group as={Row} controlId="accountName">
                                <Form.Label column sm="4" className="fw-bold">
                                    Password
                                </Form.Label>
                                <Col sm="8">
                                    <Form.Control plaintext readOnly placeholder="******" />
                                </Col>
                            </Form.Group>
                        </Form>
                    </Card.Body>
                </Card>
            </Container>

            <PageFooter />
        </>
    );
};

const mapStateToProps = (state: IAppState) => {
    return {
    };
};

export default connect(mapStateToProps)(AccountInfo);




