import { AccountBalanceOutlined, KeyboardOutlined } from "@mui/icons-material";
import { Box, Grid, TextField, Button, InputAdornment, MenuItem } from "@mui/material";
import { IAppState } from "../../redux/storeTypes";
import { Checkout, Billing, Contact, CheckoutStatus } from "../../models/CheckoutLink";
import { connect, useDispatch } from "react-redux";
import { CheckoutBilling } from "./CheckoutBilling";
import _ from "lodash";
import { simpleCalculateOrderAction, updateOrderDetailsAction } from "../../redux/actions/orderManagement";
import { useEffect, useRef, useState } from "react";
import { Order, PaymentCardTypeEnum, PaymentType } from "../../models/Order";
import { BusinessTypeEnum, PaymentChannel, SubMerchantAcceptedCardTypeEnum, Terminal } from "../../models/Client";
import store from "../../redux/store";
import { statusCheckoutAction } from "../../redux/actions/checkout";

export interface IPayByTerminalProps {
    order: Order;
    checkout: Checkout;
    paymentChannel: PaymentChannel;
    status: CheckoutStatus;
    setStatus: any;
}

const PayByTerminal = ({ order, checkout, paymentChannel, status, setStatus }: IPayByTerminalProps) => {
    const dispatch = useDispatch();
    const [cardIssuer, setCardIssuer] = useState("");
    const [terminal, setTerminal] = useState("");
    const [terminals, setTerminals] = useState<Array<Terminal> | undefined>();

    const billingName = useRef<any>("");
    const billingAddress1 = useRef<any>("");
    const billingAddress2 = useRef<any>("");
    const billingCity = useRef<any>("");
    const billingState = useRef<any>("");
    const billingZip = useRef<any>("");
    const billingCountry = useRef<any>("");
    const billingPhone = useRef<any>("");
    const billingEmail = useRef<any>("");

    useEffect(() => {
        getTerminals();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getTerminals = async () => {
        const headers = new Headers();
        headers.append('Authorization', `Bearer ${store.getState().webAppSettings.bearerToken}`);
        const response = await fetch(`${store.getState().webAppSettings.baseApiUrl}/MSB_Client/api/v1/PaymentChannels/${paymentChannel?.msbId}`, { headers: headers });
        const pc = await response.json();
        const _terminals = pc.processors.find((p: any) => p.merchantProcessor?.businessType === BusinessTypeEnum.Retail)?.merchantProcessor?.terminals || [];
        setTerminals(_terminals);
        const _terminal = _terminals.find((t: any) => t.name === checkout.terminalName);
        if (_terminal) setTerminal(`${_terminal.msbId}|${_terminal.laneId}`);

    }

    const validate = (event: any) => {
        event.preventDefault();
        const form = event.currentTarget;

        if (form.reportValidity()) {
            order.methodName = "Sale";
            order.isMailOrTelephoneOrder = false;
            order.addressLine1 = billingAddress1.current.value
            order.addressLine2 = billingAddress2.current.value;
            order.city = billingCity.current.value;
            order.state = billingState.current.value;
            order.zip = billingZip.current.value;
            order.country = billingCountry.current.value;
            order.phone = billingPhone.current.value?.replace(/[^0-9]/g, "") || "";
            order.emailAddress = billingEmail.current.value;
            order.initiatedBy = checkout.initiatedBy;
            order.paymentType = PaymentType.CreditCard;
            const cardType = cardIssuer as unknown as PaymentCardTypeEnum;
            order.cardType = cardType;
            order.nameOnCard = "";
            order.creditCardNumber = "";
            order.expirationDate = "";
            order.cvv = "";
            order.eCheckAccountHolderName = "";
            order.eCheckAccountNumber = "";
            order.eCheckRoutingNumber = "";
            order.isCardPresent = true;
            order.isDebitCard = PaymentCardTypeEnum[cardType].toString().indexOf("Debit") >= 0;
            order.externalCustomerReference = checkout.uniqueId;
            const ids = JSON.parse(checkout.iDs);
            order.clientMsbId = ids.clientId;
            order.departmentMsbId = ids.departmentId;
            order.paymentChannelMsbId = ids.paymentChannelId;
            const terminalInfo = terminal.split("|");
            order.terminalId = terminalInfo[0];
            order.laneId = parseInt(terminalInfo[1]);
            dispatch(updateOrderDetailsAction(order, "CheckoutPage"));
            dispatch(simpleCalculateOrderAction("CheckoutPage"));
            setStatus();
        }
    }

    const options = () => {
        let options = [];
        if (!paymentChannel?.softBlockedAcceptedCards.some(c => c === SubMerchantAcceptedCardTypeEnum.AmericanExpress)) {
            options.push({ value: PaymentCardTypeEnum.AmericanExpressCredit, label: "AMEX CREDIT" });
            options.push({ value: PaymentCardTypeEnum.AmericanExpressDebit, label: "AMEX DEBIT" });
        }
        if (!paymentChannel?.softBlockedAcceptedCards.some(c => c === SubMerchantAcceptedCardTypeEnum.Discover)) {
            options.push({ value: PaymentCardTypeEnum.DiscoverCredit, label: "DISCOVER CREDIT" });
            options.push({ value: PaymentCardTypeEnum.DiscoverDebit, label: "DISCOVER DEBIT" });
        }
        if (!paymentChannel?.softBlockedAcceptedCards.some(c => c === SubMerchantAcceptedCardTypeEnum.MasterCard)) {
            options.push({ value: PaymentCardTypeEnum.MastercardCredit, label: "MASTERCARD CREDIT" });
            options.push({ value: PaymentCardTypeEnum.MastercardDebit, label: "MASTERCARD DEBIT" });
        }
        if (!paymentChannel?.softBlockedAcceptedCards.some(c => c === SubMerchantAcceptedCardTypeEnum.Visa)) {
            options.push({ value: PaymentCardTypeEnum.VisaCredit, label: "VISA CREDIT" });
            options.push({ value: PaymentCardTypeEnum.VisaDebit, label: "VISA DEBIT" });
        }
        return options;
    }

    const handleChange = () => {
        if (status.payBy) dispatch(statusCheckoutAction({ ...status, payBy: false }));
    }

    return (
        <form onSubmit={validate} onChange={handleChange}>
            <Grid container spacing="3" style={{ paddingTop: "8px" }}>
                <Grid item xs>
                    <TextField
                        required
                        select
                        label="Card Issuer/Scheme"
                        SelectProps={{
                            MenuProps: {
                                PaperProps: {
                                    style: { background: 'whitesmoke' },
                                },
                            },
                        }}
                        fullWidth
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <AccountBalanceOutlined sx={{ color: "lightgrey" }} />
                                </InputAdornment>
                            )
                        }}
                        size="small"
                        value={cardIssuer}
                        onChange={(e) => setCardIssuer(e.target.value)}>
                            {options().map((option) => (<MenuItem key={option.value} value={option.value}>{option.label}</MenuItem>))}
                    </TextField>
                </Grid>
            </Grid>
            <Grid container spacing="3" style={{ paddingTop: "8px" }}>
                <Grid item xs>
                    <TextField
                        required
                        select
                        label="Terminal"
                        SelectProps={{
                            MenuProps: {
                                PaperProps: {
                                    style: { background: 'whitesmoke' },
                                },
                            },
                        }}
                        fullWidth
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <KeyboardOutlined sx={{ color: "lightgrey" }} />
                                </InputAdornment>
                            )
                        }}
                        size="small"
                        value={terminal}
                        onChange={(e) => setTerminal(e.target.value)}>
                        {terminals && terminals?.length === 0 && <MenuItem key={1} value={""}>No Terminals Assigned</MenuItem>}
                        {terminals && terminals?.map((terminal) => (<MenuItem key={`${terminal.msbId}|${terminal.laneId}`} value={`${terminal.msbId}|${terminal.laneId}`}>{terminal.name}</MenuItem>))}
                    </TextField>
                </Grid>
            </Grid>
            <CheckoutBilling
                //payerName={checkout.payerName}
                billing={checkout.billing || new Billing()}
                contact={checkout.contact || new Contact()}
                addressRequirement={checkout.terminalAddressRequirement}
                contactRequirement={checkout.terminalContactRequirement}
                billingName={billingName}
                billingAddress1={billingAddress1}
                billingAddress2={billingAddress2}
                billingCity={billingCity}
                billingState={billingState}
                billingZip={billingZip}
                billingCountry={billingCountry}
                billingPhone={billingPhone}
                billingEmail={billingEmail}
            />
            <Box sx={{ textAlign: 'center', paddingTop: '8px' }}>
                <Button type="submit" fullWidth variant="contained" size='small' color="primary">CONTINUE</Button>
            </Box>
        </form>
    );
};

const mapStateToProps = (state: IAppState) => {
    return {
        order: state.orderManagement.order,
        checkout: state.checkout.checkout,
        paymentChannel: state.paymentChannelWebApplication.paymentChannel,
        status: state.checkout.status,
    };
};

export default connect(mapStateToProps)(PayByTerminal);

  