import { CellPlugin } from '@react-page/editor';
import LoganShoppingCart, { TITLE, SUBTITLE, PAYMENT_CODE_LABEL, PARCEL_NUMBER_LABEL, TAXPAYER_NAME_LABEL, 
     SITUS_ADDRESS_LABEL, AMOUNT_LABEL, ACTIONS_LABEL, HEADER_BACKGROUND_COLOR, HEADER_PADDING, MARGIN, PADDING,  
    CART_BUTTON_BACKGROUND_COLOR, CART_BUTTON_FONT_COLOR, CART_BUTTON_FONT_SIZE, 
    CART_BUTTON_FONT_STYLE, CART_BUTTON_FONT_WEIGHT, CART_BUTTON_BORDER_COLOR, CART_ICON_COLOR
} from './components/LoganShoppingCart';

type Data = {
    title: string,
    subTitle: string,
    paymentCode: string,
    parcelNumber: string,
    taxpayerName: string,
    situsAddress: string,
    amount: string,
    actions: string,
    headerBackgroundColor: string,
    headerPadding: string,
    margin: string,
    padding: string,
    cartButtonBackgroundColor: string,
    cartButtonFontColor: string,
    cartButtonFontSize: string,
    cartButtonFontStyle: string,
    cartButtonFontWeight: string,
    cartButtonBorderColor: string,
    cartIconColor: string
}

const LoganShoppingCartPlugin: CellPlugin<Data> = {
    Renderer: ({ data }) => (
        <LoganShoppingCart
            title = {data.title}
            subTitle={data.subTitle}
            paymentCodeLabel={data.paymentCode}
            parcelNumberLabel={data.parcelNumber}
            taxpayerNameLabel={data.taxpayerName}
            situsAddressLabel={data.situsAddress}
            amountLabel={data.amount}
            actionsLabel={data.actions}
            margin={data.margin}
            padding={data.padding}
            cartButtonBackgroundColor={data.cartButtonBackgroundColor}
            cartButtonFontColor={data.cartButtonFontColor}
            cartButtonFontSize={data.cartButtonFontSize}
            cartButtonFontStyle={data.cartButtonFontStyle}
            cartButtonFontWeight={data.cartButtonFontWeight}
            cartButtonBorderColor={data.cartButtonBorderColor}
            headerBackgroundColor={data.headerBackgroundColor}
            headerPadding={data.headerPadding}
            cartIconColor={data.cartIconColor}
        />
    ),
    id: 'loganShoppingCartPlugin',
    title: 'Logan Shopping Cart ',
    description: 'Adds items to shopping cart',
    version: 1,
    controls: [
        {
            title: 'Labels',
            controls: {
                type: 'autoform',
                schema: {
                    properties: {
                        title: {
                            type: 'string',
                            default: TITLE,
                        },
                        subTitle: {
                            type: 'string',
                            default: SUBTITLE,
                        },
                        paymentCode: {
                            type: 'string',
                            default: PAYMENT_CODE_LABEL,
                        },
                        parcelNumber: {
                            type: 'string',
                            default: PARCEL_NUMBER_LABEL,
                        },
                        taxpayerName: {
                            type: 'string',
                            default: TAXPAYER_NAME_LABEL,
                        },
                        situsAddress: {
                            type: 'string',
                            default: SITUS_ADDRESS_LABEL,
                        },
                        amount: {
                            type: 'string',
                            default: AMOUNT_LABEL,
                        },
                        actions: {
                            type: 'string',
                            default: ACTIONS_LABEL,
                        }
                    },
                    required: [],
                },
            },
        },
        {
            title: 'Styles',
            controls: {
                type: 'autoform',
                schema: {
                    properties: {
                        margin: {
                            type: 'string',
                            default: MARGIN,
                        },
                        padding: {
                            type: 'string',
                            default: PADDING,
                        },
                        headerBackgroundColor: {
                            type: 'string',
                            default: HEADER_BACKGROUND_COLOR,
                        },
                        headerPadding: {
                            type: 'string',
                            default: HEADER_PADDING,
                        },
                        cartButtonBackgroundColor: {
                            type: 'string',
                            default: CART_BUTTON_BACKGROUND_COLOR,
                        },
                        cartButtonFontColor: {
                            type: 'string',
                            default: CART_BUTTON_FONT_COLOR,
                        },
                        cartButtonFontSize: {
                            type: 'string',
                            default: CART_BUTTON_FONT_SIZE,
                        },
                        cartButtonFontStyle: {
                            type: 'string',
                            default: CART_BUTTON_FONT_STYLE,
                        },
                        cartButtonFontWeight: {
                            type: 'string',
                            default: CART_BUTTON_FONT_WEIGHT,
                        },
                        cartButtonBorderColor: {
                            type: 'string',
                            default: CART_BUTTON_BORDER_COLOR,
                        },
                        cartIconColor: {
                            type: 'string',
                            default: CART_ICON_COLOR
                        }
                        
                    },
                    required: [],
                },
            },
        },
    ],
};

export default LoganShoppingCartPlugin;