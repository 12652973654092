import { CellPlugin } from '@react-page/editor';
import _ from 'lodash';
import React from 'react';
import { Col, Row } from 'react-bootstrap';


// use a type here, not an interface
type Data = {
    label: string,
    tag: string,
    value: any
}

const getValue = (value: any) => {
    if (value) {
        const objectKeys = Object.fromEntries(Object.entries(value).filter(([key]) => key.includes('cmd_')));
        return objectKeys;
    } else {
        return {};
    }
}

const ClientMetadataPropertyPlugin: CellPlugin<Data> = {
    Renderer: ({ data }) => (
        <>
            <Row>
                <Col><strong>{data.label}</strong></Col>
            </Row>
            {
                (Object.entries(getValue(data.value))).map(
                    ([index, value]: any) => {
                        return (
                            <Row key={index}>
                                <Col>
                                    <div><strong>{_.upperFirst(_.lowerCase(index.replace('cmd_', '')))}:</strong></div>
                                </Col>
                                <Col>
                                    <span>{value}</span>
                                </Col>
                            </Row>
                        );
                    }
                )
            }
        </>
    ),
    id: 'clientMetadataPropertyPlugin',
    title: 'Client Metadata Property',
    description: 'Client Metadata Property.',
    version: 1,
    controls: {
        type: 'autoform',
        schema: {
            properties: {
                label: {
                    type: 'string',
                    default: 'Client Metadata',
                },
                tag: {
                    type: 'string',
                    default: 'Client Metadata',
                    readOnly: true
                },
            },
            required: ['label'],
        },
    },
};

export default ClientMetadataPropertyPlugin;