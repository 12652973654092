import moment from 'moment';
import { TransactionDetails } from '../models/Order';

export const EllipseTextCellFormatter = (text: string, row: any, rowIndex:number, length:number) => {
    return EllipseTextFormatter(text, length);
}

export const EllipseTextFormatter = (text: string, length:number) => {
    if (text) {
        return text.length > length? `${text.substr(0, length)}...`:text;
    }
    return (<></>)
}

export const DateFormatter = (date:string) => {
    const dateString = moment.utc(date).local().format('MM-DD-YYYY h:mm:ss a');
    return (
        <span>{dateString}</span>
    );
}

export const DateOnlyFormatter = (date:string,format:string) => {
    const dateString = moment.utc(date).local().format(format);
    return (
        <span>{dateString}</span>
    );
}

export const DateWithoutUTCFormatter = (date:string) => {
    const dateString = moment(date).local().format('MM-DD-YYYY');
    return (
        <span>{dateString}</span>
    );
}

export const TimeFormatter = (date:string) => {
    const dateString = moment.utc(date).local().format('h:mm:ss a');
    return (
        <span>{dateString}</span>
    );
}

export const CurrencyFormatter = (amount: any) => {
    if (!isNaN(amount)) {
        const formatter = new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD',
        });

        const formattedAmount = formatter.format(Number(amount));
        return (<span style={{ color: amount < 0?'#F5222D':"#414141", justifyContent: 'flex-end' }}>{formattedAmount}</span>);
    }
    return (<></>);
}

export const DateWithoutUTC = (date: any) => {
    return moment(date).local().format('MM-DD-YYYY');
}