import { combineReducers } from 'redux';

import account from './account';
import auth from './auth';
import cms from './cms';
import clients from './clients';
import emails from './emails';
import tylerEagleIntegration from './tylerEagleIntegration';
import redirectIntegration from './redirectIntegration';
import paymentChannelWebApplication from './paymentChannelWebApplication';
import webAppSettings from './webAppSettings';
import paymentMethods from './paymentMethods';
import toast from './toast';
import orderManagement from './orderManagement';
import users from './users';
import checkout from './checkout';
import link from './link';
import wallet from './wallet';

const rootReducer = combineReducers(
    {
        account,
        auth,
        clients,
        cms,
        emails,
        tylerEagleIntegration,
        redirectIntegration,
        checkout,
        link,
        wallet,
        paymentChannelWebApplication,
        toast,
        orderManagement,
        webAppSettings,
        paymentMethods,
        users
    });

export default rootReducer;
