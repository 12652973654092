import React, { useEffect } from "react";
import { Button, Card, Col, Container, Form, Nav, Row } from "react-bootstrap";
import { connect, useDispatch } from "react-redux";
import { IAppState } from "../redux/storeTypes";
import { Routes } from "../routes";
import { Link, useParams } from "react-router-dom";
import LoginHeader from "../components/layout/LoginHeader";
import LoginImage from "../assets/img/Login.svg";
import RequiredIcon from "../components/RequiredIcon";
import Paypallogo from "../assets/img/icons/PayPal-Color.svg";
import ApplePaylogo from "../assets/img/icons/Apple-Pay-Color.svg";
import DiscoverLogo from "../assets/img/icons/Discover-Color.svg";
import eCheckLogo from "../assets/img/icons/eCheck-Color.svg";
import GooglePayLogo from "../assets/img/icons/Google-Pay-Color.svg";
import JcbLogo from "../assets/img/icons/JCB-Color.svg";
import MastercardLogo from "../assets/img/icons/Mastercard-Color.svg";
import VisaLogo from "../assets/img/icons/Visa-Color.svg";
import AmexLogo from "../assets/img/icons/AMEX-Color.svg";
import PageFooter from "../components/layout/PageFooter";

export interface ILoginPageProps {
}

export interface IParams {
    id: string
}

const LoginPage = ({ }: ILoginPageProps) => {
    const setRoute = (path:string) => {
        return path.replace(":id", "Demo");
    }

    return (
        <>
        <main className="msb-portal">
            <LoginHeader />
            <section className="login-hero">
                <img src={LoginImage} className="w-100" alt="Hero image" loading="lazy" />
                <div className="img-heading">
                    <h1>Welcome to County Utilities</h1>
                    <h5>Some representative placeholder content</h5>
                </div>
            </section>
            <section className="login-section">
                <Card className="login-card mx-auto">
                    <Card.Body>
                        <Row>
                            <Col lg={6} md={12} className="login-account">
                                <h3>Login to your account</h3>
                                <span>Login to your account to be able to use extended features.</span>
                                <Row>
                                    <Form.Group controlId="emailAddress">
                                        <Form.Label><RequiredIcon />Email address</Form.Label>
                                        <Form.Control required type="input" placeholder="Enter email address" />
                                        <Form.Control.Feedback type="invalid">Email is required.</Form.Control.Feedback>
                                    </Form.Group>
                                </Row>
                                <Row>
                                    <Form.Group controlId="password">
                                        <Form.Label><RequiredIcon />Password</Form.Label>
                                        <Form.Control required type="input" placeholder="Enter password" />
                                        <Form.Control.Feedback type="invalid">Password is required.</Form.Control.Feedback>
                                    </Form.Group>
                                </Row>
                                <Row>
                                    <Link to={setRoute(Routes.AccountLandingStatic.path)}>
                                        <Button className="brand-btn-primary w-100">Log in</Button>
                                    </Link>
                                </Row>
                                <Row className="mb-0">
                                    <Link to="#Login" className="forgot-password">Forgot password?</Link>
                                </Row>
                            </Col>
                            <Col lg={6} md={12} className="login-noaccount">
                                <h3>No login required</h3>
                                <span>Pay online with a Quick Pay option, no registration required.</span>
                                <Row>
                                    <Form.Group controlId="invoiceNumber">
                                        <Form.Label><RequiredIcon />Invoice number</Form.Label>
                                        <Form.Control required type="input" placeholder="Enter invoice number" />
                                        <Form.Control.Feedback type="invalid">Invoice number is required.</Form.Control.Feedback>
                                    </Form.Group>
                                </Row>
                                <Link to={setRoute(Routes.QuickPayStatic.path)}>
                                    <Button className="brand-btn-primary w-100">Find my invoice</Button>
                                </Link>
                            </Col>
                        </Row>
                    </Card.Body>
                    <Card.Footer>
                        <p>Don’t have an account?<Link to={setRoute(Routes.RegisterStatic.path)} className="brand-link-primary">Sign up</Link></p>
                    </Card.Footer>
                </Card>
                <div className="login-desc mx-auto">
                    <Row>
                        <Col lg={4} md={12}>
                            <h3>Fast and Easy</h3>
                            <span>No registration is required for Quick Pay, the fastest way to pay online and confirm payment.</span>
                        </Col>
                        <Col lg={4} md={12}>
                            <h3>Safe and Secure</h3>
                            <span>Your information is kept confidential, secure, and backed by the highest security standards.</span>
                        </Col>
                        <Col lg={4} md={12}>
                            <h3>Eco-Friendly</h3>
                            <span>Paying online reduces paper use and is an easy way to help the environment.</span>
                        </Col>
                    </Row>
                </div>
                <div className="login-payment-icons mx-auto">
                    <img src={VisaLogo} />
                    <img src={MastercardLogo} />
                    <img src={DiscoverLogo} />
                    <img src={AmexLogo} />
                    <img src={ApplePaylogo} />
                    <img src={GooglePayLogo} />
                    <img src={eCheckLogo} />
                    <img src={JcbLogo} />
                    <img src={Paypallogo} />
                </div>
                <div className="login-disclaimer">
                    <p>By continuing, you agree to accept our Privacy Policy and Terms and Conditions</p>
                </div>
            </section>
        </main>
        <PageFooter />
        </>
    );
}

const mapStateToProps = (state: IAppState) => {
    return {
    };
};

export default connect(mapStateToProps)(LoginPage);

