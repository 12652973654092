import { Row, Col, Spinner, Button, Form } from "react-bootstrap"
import { useEffect, useState } from "react";
import Table from '../../../../../../components/Table';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircle } from "@fortawesome/free-solid-svg-icons";
import { Order, OrderLine } from "../../../../../../models/Order";
import { addOrderLineAction, addToShoppingCart, updateOrderDetailsAction, updateShoppingCart } from '../../../../../../redux/actions/orderManagement';
import { IAppState } from '../../../../../../redux/storeTypes';
import { connect, useDispatch } from "react-redux";
import { ScheduledPortionType } from "../../../../../../models/Schedule";
import { ItemType } from "../../../../../../models/Account";
import HeaderBar from "../../../../../../components/HeaderBar";
import icoError from "../../../../../../assets/img/icons/ico-error-outline.svg";
import CurrencyInput from "../../../../../../components/currency/CurrencyInput";


interface IMultiSearchResults {
    taxTypeLabel: string,
    hideTaxType: boolean,
    accountNumberLabel: string,
    ownerLabel: string,
    balanceDueLabel: string,
    paymentAmountLabel: string,
    staticPaymentAmount: boolean,
    isSchoolTaxPartial: boolean,
    isTaxPartial: boolean,
    isPerCapitaTaxPartial: boolean,
    statusLabel: string,
    actionsLabel: string,
    margin: string,
    padding: string,
    headerBackgroundColor: string,
    headerPadding: string,
    shoppingCart: any,
    order: Order,
    itemDetails: any,
    isFetching: boolean
}

export const TAX_TYPE_LABEL = 'Tax type';
export const HIDE_TAX_TYPE = true;
export const ACCOUNT_NUMBER_LABEL = 'Account number';
export const OWNER_LABEL = 'Owner';
export const BALANCE_DUE_LABEL = 'Balance Due';
export const PAYMENT_AMOUNT_LABEL = 'Payment Amount';
export const STATUS_LABEL = 'Status';
export const ACTIONS_LABEL = 'Actions';
export const MARGIN = '0px';
export const PADDING = '0px';
export const HEADER_BACKGROUND_COLOR = 'rgb(250, 250, 250)';
export const HEADER_PADDING = '0';

const checkStyleForDefault = (style: string, defaultStyle: string) => {
    return style && style !== '' ? style : defaultStyle;
}

const MultiSearchResults = ({ taxTypeLabel, hideTaxType, accountNumberLabel, ownerLabel, balanceDueLabel, statusLabel, actionsLabel,
    margin, padding, headerBackgroundColor, headerPadding, shoppingCart, order, staticPaymentAmount, isSchoolTaxPartial, isTaxPartial, isPerCapitaTaxPartial,
    paymentAmountLabel, itemDetails, isFetching }: IMultiSearchResults) => {


    if (!taxTypeLabel) { taxTypeLabel = TAX_TYPE_LABEL }
    if (!accountNumberLabel) { accountNumberLabel = ACCOUNT_NUMBER_LABEL }
    if (!ownerLabel) { ownerLabel = OWNER_LABEL }
    if (!balanceDueLabel) { balanceDueLabel = BALANCE_DUE_LABEL }
    if (!statusLabel) { statusLabel = STATUS_LABEL }
    if (!actionsLabel) { actionsLabel = ACTIONS_LABEL }
    if (!margin) { margin = MARGIN }
    if (!paymentAmountLabel) { paymentAmountLabel = PAYMENT_AMOUNT_LABEL }
    if (!padding) { padding = PADDING }
    if (!headerBackgroundColor) { headerBackgroundColor = HEADER_BACKGROUND_COLOR }
    if (!headerPadding) { headerPadding = HEADER_PADDING }

    const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
    });

    const amountFormatter = (cell: any) => {
        return (<span>{formatter.format(cell)}</span>)
    }

    const statusFormatter = (cell: any) => {
        let color: string = "#F5222D";
        if (cell === 'Paid') { color = "#52C41A" }
        return (
            <span style={{ display: 'flex', alignItems: 'center' }}><FontAwesomeIcon icon={faCircle} size="xs" className="btn-icon" style={{ color: `${color}` }} />{'Unpaid'}</span>
        )
    }

    const paymentOptionsFormatter = (cell: any, row: any) => {

        if (staticPaymentAmount) {
            if (row?.metaData?.ItemType === ItemType.SchoolMortgage && isSchoolTaxPartial) {
                const paymentAmountByDate = amountSelection(row)
                return (
                    <CurrencyInput
                        className="form-control"
                        disabled={paymentAmountByDate == 0}
                        id="paymentAmount"
                        name="paymentAmount"
                        maxLength={10}
                        decimalsLimit={2}
                        prefix="$ "
                        value={paymentOption}
                        onValueChange={(value) => handlePartialAmount(value, row, paymentAmountByDate)}
                        defaultValue={cell ? cell : paymentAmountByDate}
                    />
                );
            }
            return (renderDynamicPaymentOptions(cell, row))
        } else {
            return (
                <Form.Control as="select" value={paymentOption} onChange={(e) => handlePaymentOption(e, row)} defaultValue={cell}>
                    <option key={0} value="" selected disabled hidden>Select payment option</option>
                    {renderDynamicPaymentOptions(cell, row)}
                </Form.Control>
            )
        }
    }

    const handlePartialAmount = (value: any, item: any, paymentAmountByDate: any) => {

        if (value > paymentAmountByDate) {
            setPaymentOption(paymentAmountByDate);
            setDynamicPaymentAmount({ rowDetails: item, dynamicAmount: paymentAmountByDate, selectedPortion: paymentAmountByDate })
            setCustomErrorMessage(`Payment amount of account number ${item?.metaData?.AccountNumber} cannot be greater than balance due`);
            return false;
        }
        else {
            if (value && value > 0) {
                setPaymentOption(value);
                setDynamicPaymentAmount({ rowDetails: item, dynamicAmount: value, selectedPortion: value })
            }
            else {
                setPaymentOption(paymentAmountByDate);
                setDynamicPaymentAmount({ rowDetails: item, dynamicAmount: paymentAmountByDate, selectedPortion: paymentAmountByDate })
            }
        }
        setCustomErrorMessage('');
    }

    const balanceDueFormatter = (cell: any, row: any) => {
        return (renderDynamicPaymentOptions(cell, row))
    }

    const taxFormatter = (cell: any, row: any) => {
        return (
            <span>{ItemType[cell]}</span>
        );

    }

    const initalColumns = [{
        dataField: 'metaData.ItemType',
        text: taxTypeLabel,
        hidden: hideTaxType,
        headerStyle: {
            backgroundColor: checkStyleForDefault(headerBackgroundColor, HEADER_BACKGROUND_COLOR)
        },
        formatter: taxFormatter,
        editable: false,
        sort: true
    }, {
        dataField: 'metaData.AccountNumber',
        text: accountNumberLabel,
        editable: false,
        headerStyle: {
            backgroundColor: checkStyleForDefault(headerBackgroundColor, HEADER_BACKGROUND_COLOR)
        },
        sort: true,
    }, {
        dataField: 'metaData.OwnerName',
        text: ownerLabel,
        headerStyle: {
            backgroundColor: checkStyleForDefault(headerBackgroundColor, HEADER_BACKGROUND_COLOR)
        },
        editable: false,

    }, {
        dataField: 'paymentAmount',
        text: paymentAmountLabel,
        headerStyle: {
            backgroundColor: checkStyleForDefault(headerBackgroundColor, HEADER_BACKGROUND_COLOR)
        },
        formatter: paymentOptionsFormatter,
        editable: false,

    }, {
        dataField: 'metaData.GrandTotal',
        text: balanceDueLabel,
        headerStyle: {
            backgroundColor: checkStyleForDefault(headerBackgroundColor, HEADER_BACKGROUND_COLOR)
        },
        editable: false,
        formatter: staticPaymentAmount ? balanceDueFormatter : amountFormatter,

    }, {
        dataField: 'status',
        text: statusLabel,
        headerStyle: {
            backgroundColor: checkStyleForDefault(headerBackgroundColor, HEADER_BACKGROUND_COLOR)
        },
        formatter: statusFormatter,
        editable: false,
    }
    ];

    const renderDynamicPaymentOptions = (cell: any, rowDetails: any) => {
        const dueDates = dateRules(rowDetails);
        const firstHalfRangeOptions = (
            <>
                {!staticPaymentAmount ?
                    <>
                        <option value={ScheduledPortionType.FirstHalf}>{`First Half (${formatter.format(rowDetails?.metaData?.FirstHalfDue)})`}</option>
                        <option value={ScheduledPortionType.SecondHalf} disabled={rowDetails?.metaData?.FirstHalfDue == 0 ? false : true}>{`Second Half (${formatter.format(rowDetails?.metaData?.SecondHalfDue)})`}</option>
                        <option value={ScheduledPortionType.Full}>{`Full Payment (${formatter.format(rowDetails?.metaData?.GrandTotal)})`}</option>
                    </>
                    :
                    <span>{formatter.format(rowDetails?.metaData?.FirstHalfDue)}</span>
                }
            </>
        )

        const fullRangeOptions = (
            <>
                {!staticPaymentAmount ?
                    <>
                        <option value={ScheduledPortionType.SecondHalf}>{`Second Half (${formatter.format(rowDetails?.metaData?.SecondHalfDue)})`}</option>
                        <option value={ScheduledPortionType.Full}>{`Full Payment (${formatter.format(rowDetails?.metaData?.GrandTotal)})`}</option>
                    </>
                    :
                    <span>{formatter.format(rowDetails?.metaData?.GrandTotal)}</span>
                }
            </>
        )
        const secondHalfRangeOption = (
            <>
                {!staticPaymentAmount ?
                    <>
                        <option value={ScheduledPortionType.SecondHalf}>{`Second Half (${formatter.format(rowDetails?.metaData?.SecondHalfDue)})`}</option>
                    </>
                    :
                    <span>{formatter.format(rowDetails?.metaData?.SecondHalfDue)}</span>
                }
            </>
        )
        const noPaymentOptions = (
            <>
                <option value='' disabled>{`No payment options available`}</option>
            </>
        )

        const prowersRules = dueDates.firstHalf ? firstHalfRangeOptions : dueDates.full ? fullRangeOptions : dueDates.secondHalf ? secondHalfRangeOption : noPaymentOptions
        const defaultPaymentRules = dueDates.firstHalf ? firstHalfRangeOptions : dueDates.secondHalf ? secondHalfRangeOption : dueDates.full ? fullRangeOptions : noPaymentOptions
        return staticPaymentAmount ? defaultPaymentRules : prowersRules
    }

    const handlePaymentOption = (e: any, rowDetails: any) => {
        const selectedOption = e.target.value;
        if (selectedOption != '') { setPaymentOption(selectedOption) };

        const amount = selectedOption == ScheduledPortionType.FirstHalf ? rowDetails?.metaData?.FirstHalfDue
            : selectedOption == ScheduledPortionType.SecondHalf ? rowDetails?.metaData?.SecondHalfDue
                : selectedOption == ScheduledPortionType.Full ? rowDetails?.metaData?.GrandTotal
                    : null;
        if (amount) { setDynamicPaymentAmount({ rowDetails: rowDetails, dynamicAmount: amount, selectedPortion: selectedOption }) }
    }

    const amountSelection = (rowDetails: any) => {
        const dueDates = dateRules(rowDetails);
        const defaultAmount = dueDates.firstHalf ? rowDetails?.metaData?.FirstHalfDue : dueDates.secondHalf ? rowDetails?.metaData?.SecondHalfDue : dueDates.full ? rowDetails?.metaData?.GrandTotal : ''
        return defaultAmount
    }

    const dateRules = (item: any) => {
        const todaysDate = new Date();
        const firstHalfDueDate = todaysDate < new Date(item?.metaData?.DateRangeFirstPO);
        const secondHalfDueDate = todaysDate < new Date(item?.metaData?.DateRangeSecondPO);
        const fullDueDate = todaysDate < new Date(item?.metaData?.DateRangeFullPO);
        return ({ 'firstHalf': firstHalfDueDate, 'secondHalf': secondHalfDueDate, 'full': fullDueDate })
    }

    const dispatch = useDispatch();
    const [columns, setColumns] = useState<any>(initalColumns);
    const actionToken = 'MultiSearchResults';
    const shoppingCartactionToken = 'Shopping Cart';
    const [paymentOption, setPaymentOption] = useState<any>();
    const [data, setData] = useState<any>([]);
    const [dynamicPaymentAmount, setDynamicPaymentAmount] = useState<any>();
    const [nonSelectableRows, setNonSelectableRows] = useState<any>();
    const [selectedRows, setSelectedRows] = useState<any>();
    const [customErrorMessage, setCustomErrorMessage] = useState<string>('');

    useEffect(() => {
        if (itemDetails) {
            if (shoppingCart) {
                const updatedItemList = itemDetails.map(function (details: any) {
                    const result = shoppingCart.filter((shoppingCartDetail: any) => shoppingCartDetail.msbId == details.msbId);
                    if (result.length > 0) { details['paymentAmount'] = result[0].paymentPortion; }
                    return details
                })
                setData(updatedItemList)
            }
            if (!shoppingCart) { setData(itemDetails) }
        }
        const nonSelectable = itemDetails?.map((details: any) => { return details?.msbId })
        setNonSelectableRows(nonSelectable);
        setSelectedRows([]);
        setCustomErrorMessage('')
    }, [itemDetails])

    useEffect(() => {
        const newColumns = columns.map((column: any) => {
            if (column.dataField !== 'paymentAmount') return column;
            return {
                ...column,
                paymentAmount: dynamicPaymentAmount?.dynamicAmount
            }
        })
        setColumns(newColumns)
        setNonSelectableRows((prevState: any) => prevState?.filter((prevItem: any) => prevItem !== dynamicPaymentAmount?.rowDetails?.msbId))

        if (dynamicPaymentAmount) {
            const addorderLine = order?.orderLines.map((orderLine: any) => {
                return orderLine?.itemId
            });
            const checkaddOrderLine = addorderLine?.includes(dynamicPaymentAmount?.rowDetails?.msbId);
            if (!checkaddOrderLine) {
                let orderLine = new OrderLine();
                orderLine.accountNumber = dynamicPaymentAmount?.rowDetails?.metaData?.AccountNumber;
                orderLine.itemReferenceNumber = dynamicPaymentAmount?.rowDetails?.metaData?.AccountNumber;
                orderLine.quantity = 1;
                orderLine.unitPrice = Number(dynamicPaymentAmount?.dynamicAmount);
                orderLine.itemId = dynamicPaymentAmount?.rowDetails?.msbId;
                const paymentPortionInfo = { ...dynamicPaymentAmount?.rowDetails, paymentAmount: dynamicPaymentAmount?.dynamicAmount, paymentPortion: dynamicPaymentAmount?.selectedPortion }
                dispatch(addToShoppingCart(paymentPortionInfo, shoppingCartactionToken));
                dispatch(addOrderLineAction(orderLine, actionToken));
                setSelectedRows([dynamicPaymentAmount?.rowDetails?.msbId]);
            }
            else if (order) {
                const orderLines = order?.orderLines.map((orderLine: any) => {
                    if (orderLine?.itemId == dynamicPaymentAmount?.rowDetails?.msbId && orderLine?.unitPrice != dynamicPaymentAmount?.dynamicAmount) {
                        return { ...orderLine, unitPrice: dynamicPaymentAmount?.dynamicAmount }
                    }
                    return orderLine
                }).filter(Boolean);
                const updatedShoppingCart = shoppingCart?.map((details: any) => {
                    if (details?.msbId == dynamicPaymentAmount?.rowDetails?.msbId) {
                        return { ...details, paymentAmount: dynamicPaymentAmount?.dynamicAmount, paymentPortion: dynamicPaymentAmount?.selectedPortion }
                    }
                    return details
                }).filter(Boolean);
                if (orderLines) {
                    order.orderLines = orderLines;
                    dispatch(updateOrderDetailsAction(order, actionToken));
                    dispatch(updateShoppingCart(updatedShoppingCart, shoppingCartactionToken));
                }
            }
        }
    }, [dynamicPaymentAmount]);

    const selectRow = {
        mode: 'checkbox',
        clickToSelect: false,
        clickToEdit: true,
        selected: selectedRows,
        nonSelectable: !staticPaymentAmount ? nonSelectableRows : '',
        selectColumnPosition: 'right',
        selectionHeaderRenderer: () => staticPaymentAmount ? 'Select' : '',
        selectionRenderer: ({ mode, rowKey, ...rest }: any) => {
            const getmsbId = shoppingCart?.map((itemDetails: any) => { return itemDetails.msbId }).filter(Boolean);
            const cartValidation = getmsbId?.some((item: any) => rowKey.includes(item));
            if (cartValidation) {
                return (<input type={'checkbox'} checked />)
            }
            return (
                <input type={'checkbox'} {...rest} />)
        },
        onSelect: (row: any, isSelect: any, rowIndex: any, e: any) => {
            const checkedProp = e.target.checked;
            if (isSelect && staticPaymentAmount && checkedProp) {
                const amount = amountSelection(row);
                if (amount != 0) { setDynamicPaymentAmount({ rowDetails: row, dynamicAmount: amount, selectedPortion: '' }) }
            }
        },
    };



    const renderTable = () => {
        if (isFetching) {
            return (<div style={{ textAlign: 'center' }} ><Spinner animation="border" /> </div>);
        }
        else if (data.length === 0) {
            return (<div>Currently there are no records to display. Please do a search to find the record you are looking for.</div>)
        } else {
            return (
                <>
                    {customErrorMessage.length != 0 &&
                        <Form.Group as={Row} className="mb-3">
                            <HeaderBar backgroundColor='#FFF1F0' message={customErrorMessage} icon={icoError} />
                        </Form.Group>
                    }
                    <Table
                        keyField="msbId"
                        data={data}
                        columns={columns}
                        remote={false}
                        selectRow={selectRow}
                        sizePerPage={5}
                    />
                </>);
        }
    };

    return (

        <div style={{ margin: margin, padding: padding }}>
            <Row>
                <Col>
                    {renderTable()}
                </Col>
            </Row>
        </div>

    )
}

const mapStateToProps = (state: IAppState) => {
    return {
        isFetching: state.account.isFetching,
        shoppingCart: state.orderManagement.shoppingCart,
        order: state.orderManagement.order,
        itemDetails: state.account.itemDetails,
    };
};

export default connect(mapStateToProps)(MultiSearchResults);