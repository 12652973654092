import React, { useState } from 'react';
import { connect, useDispatch } from "react-redux";
import { Toast } from 'react-bootstrap';
import { Toast as ToastModel } from '../models/Toast';
import { REMOVE_TOAST, SUCESS, INFO, WARNING, ERROR } from '../redux/actions/toast';
import { IAppState } from '../redux/storeTypes';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { removeToastAction } from '../redux/actions/toast';

interface ToastProps {
    toast: ToastModel;
}

interface ToastsProps {
    toasts: Array<ToastModel>;
}

const ToastWrapper = ({ toast }: ToastProps) => {
    const dispatch = useDispatch();
    const [show, setShow] = useState(true);

    const doOnClose = (id: string) => {
        setShow(false);
        dispatch(removeToastAction(id));
    }

    let className = "toast-success";
    if (toast.level === INFO) {
        className = "toast-info";
    } else if (toast.level === WARNING) {
        className = "toast-warning";
    } else if (toast.level === ERROR) {
        className = "toast-error";
    }

    return (
        <Toast className={className} onClose={() => doOnClose(toast.id)} show={show} delay={5000} autohide>
            <Toast.Body>{toast.message}</Toast.Body>
        </Toast>
    )
}

const ToastContainer = ({ toasts }: ToastsProps) => {
    if (toasts.length === 0) {
        return (<></>)
    } else {
        return (
            <div className="toast-container">
                {toasts.map((value, index) => {
                    return <ToastWrapper toast={value} key={index} />
                })}
            </div>
        )
    }
}

const mapStateToProps = (state:IAppState) => {
    return { toasts: state.toast.toasts };
};

export default connect(mapStateToProps)(ToastContainer);