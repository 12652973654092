import { Order, ShoppingCartDetails } from '../../models/Order';
import {
    ADD_ORDER_LINE, UPDATE_ORDER_DETAILS,ADD_TO_SHOPPING_CART,UPDATE_SHOPPING_CART,ADD_ACCOUNT_DETAILS,
    PROCESS_ORDER_REQUEST, PROCESS_ORDER_SUCCESS, PROCESS_ORDER_FAILURE,
    CALCULATE_ORDER_REQUEST, CALCULATE_ORDER_SUCCESS, CALCULATE_ORDER_FAILURE,
    SET_ITEM_SEARCH, CLEAR_ITEM_SEARCH, CLEAR_ORDER, CANCEL_ORDER_REQUEST, CANCEL_ORDER_SUCCESS, 
    UPDATE_ORDER_VALIDATION_ERRORS, CLEAR_BANK_NAME, UPDATE_BANK_NAME
} from '../actions/orderManagement';

export default function orderManagement(state = {
    isSaving: false,
    order: null,
    accountDetails: null,
    itemSearchDetails: null,
    shoppingCart: null,
    processedOrder: null,
    isStCharles: null,
    isPike: null,
    statusUpdate: null,
    actionResult: null,
    validationErrors: null,
    bankName: '',
    errorMessage: '',
}, { type, payload }) {
    switch (type) {
        case CLEAR_ORDER:
            return Object.assign({}, state, {
                isSaving: false,
                order: null,
                accountDetails: null,
                processedOrder: null,
                actionResult: null,
            });
        case CANCEL_ORDER_REQUEST:
            return Object.assign({}, state, {
                isSaving: true,
                order: null,
                shoppingCart: null,
                processedOrder: null,
                actionResult: { type: CANCEL_ORDER_REQUEST, result: null, token: payload.actionToken },
            });
        case CANCEL_ORDER_SUCCESS:
            return Object.assign({}, state, {
                isSaving: false,
                order: null,
                processedOrder: null,
                actionResult: { type: CANCEL_ORDER_REQUEST, result: CANCEL_ORDER_SUCCESS, token: payload.actionToken },
            });
        case ADD_TO_SHOPPING_CART:
            {
                let _shoppingCart = [];
                if (state.shoppingCart != null) {
                    _shoppingCart = Object.assign([], state.shoppingCart);
                }
                _shoppingCart.push(payload.selectedRow)
                return Object.assign({}, state, {
                    isSaving: true,
                    shoppingCart: _shoppingCart,
                    actionResult: { type: ADD_TO_SHOPPING_CART, result: null, token: payload.actionToken },
                });
            }
        case UPDATE_SHOPPING_CART:
            return Object.assign([], state, {
                isSaving: true,
                shoppingCart: Object.assign([], payload.updatedList),
            });
        case ADD_ORDER_LINE:
            {
                let _order = new Order();
                if (state.order === null) {
                    _order.methodName = "Sale";
                    //_order.type = "QuickPay";
                    _order.clientMsbId = payload.paymentChannel.clientMSBId;
                    _order.paymentChannelMsbId = payload.paymentChannel.msbId;
                    _order.paymentChannelName = payload.paymentChannel.name;
                    _order.departmentMsbId = payload.paymentChannel.departmentMSBId;
                    _order.initiatedBy = payload.paymentChannel.name;
                } else {
                    _order = Object.assign({}, state.order);
                }

                _order.orderLines.push(payload.orderLine);

                return Object.assign({}, state, {
                    isSaving: true,
                    order: _order,
                    actionResult: { type: ADD_ORDER_LINE, result: null, token: payload.actionToken },
                });
            }
        case UPDATE_ORDER_DETAILS:
            return Object.assign({}, state, {
                isSaving: true,
                order: Object.assign({}, payload.order),
                actionResult: { type: UPDATE_ORDER_DETAILS, result: null, token: payload.actionToken },
            });
        case ADD_ACCOUNT_DETAILS:
            return Object.assign({}, state, {
                accountDetails: Object.assign({}, payload.accountDetails),
            });
        case CALCULATE_ORDER_REQUEST:
            return Object.assign({}, state, {
                isSaving: true,
                errorMessage: null,
                actionResult: { type: CALCULATE_ORDER_REQUEST, result: null, token: payload.actionToken },
            });
        case CALCULATE_ORDER_SUCCESS:
            {
                let _order = payload.order;
                _order.paymentType = state.order.paymentType;
                _order.paymentChannelMsbId = payload.paymentChannel.msbId;
                _order.paymentChannelName = payload.paymentChannel.name;
                _order.departmentMsbId = payload.paymentChannel.departmentMSBId;
                return Object.assign({}, state, {
                    isSaving: false,
                    order: _order,
                    actionResult: { type: CALCULATE_ORDER_REQUEST, result: CALCULATE_ORDER_SUCCESS, token: payload.actionToken }
                });
            }
        case CALCULATE_ORDER_FAILURE:
            return Object.assign({}, state, {
                isSaving: false,
                actionResult: { type: CALCULATE_ORDER_REQUEST, result: CALCULATE_ORDER_FAILURE, token: payload.actionToken },
                errorMessage: payload.error
            });
        case UPDATE_ORDER_VALIDATION_ERRORS: {
            return Object.assign({}, state, {
                isSaving: false,
                processedOrder: null,
                errorMessage: null,
                validationErrors: payload.validationErrors,
                actionResult: { type: UPDATE_ORDER_VALIDATION_ERRORS, result: null, token: payload.actionToken } ,
            });
        }
        case PROCESS_ORDER_REQUEST:
            return Object.assign({}, state, {
                isSaving: true,
                processedOrder: null,
                errorMessage: null,
                validationErrors: null,
                actionResult: { type: PROCESS_ORDER_REQUEST, result: null, token: payload.actionToken } ,
            });
        case PROCESS_ORDER_SUCCESS:
            return Object.assign({}, state, {
                isSaving: true,
                validationErrors: null,
                actionResult: { type: PROCESS_ORDER_REQUEST, result: PROCESS_ORDER_SUCCESS, token: payload.actionToken } ,
                processedOrder: payload.processedOrder,
                isStCharles: payload.stCharlesStatusCall,
                isPike: payload.pikeStatusCall,
                statusUpdate: payload.statusUpdateCall
            });
        case PROCESS_ORDER_FAILURE:
            return Object.assign({}, state, {
                isSaving: false,
                validationErrors: null,
                actionResult: { type: PROCESS_ORDER_REQUEST, result: PROCESS_ORDER_FAILURE, token: payload.actionToken } ,
                errorMessage: payload.error
            });
        case CLEAR_BANK_NAME:
            return Object.assign({}, state, {
                bankName: ""
            });
        case UPDATE_BANK_NAME:
            return Object.assign({}, state, {
                bankName: payload.bankName,
                actionResult: { type: UPDATE_BANK_NAME, result: null, token: null },
            });
        case SET_ITEM_SEARCH:
            return Object.assign({}, state, {
                itemSearchDetails: payload.itemSearchDetails,
                actionResult: { type: SET_ITEM_SEARCH, result: null, token: null },
            });
        case CLEAR_ITEM_SEARCH:
            return Object.assign({}, state, {
                itemSearchDetails: null
            });
        default:
            return state;
    }
}
