import { useEffect } from "react";
import { connect } from "react-redux";
import { Button } from 'react-bootstrap';
import { TylerEagleRequest } from "../../../../../../models/TylerEagleIntegration";
import { IAppState } from "../../../../../../redux/storeTypes";
import * as React from "react";

interface ITylerEagleRedirect {
    label: string,
    tylerEagleRequest: TylerEagleRequest
}

export const LABEL = 'View Balance and Detailed Receipt';

const TylerEagleRedirect = ({ label, tylerEagleRequest }: ITylerEagleRedirect) => {
    const actionToken = "TylerEagleRedirect";
    
    if (!label) {
        label = LABEL;
    }

    const handleClick = (event: any) => {
        setTimeout(function (){
            let htmlFormElement = document!.getElementById("tylerEagleRedirectForm")! as HTMLFormElement;
            htmlFormElement.submit();
          }, 100);
    }

    return (
        <>
            <form id="tylerEagleRedirectForm" name="tylerEagleRedirectForm" action={tylerEagleRequest.redirectURL} method="POST">
                <input style={{ display: "none" }} name="ResponseXML" value={`<?xml version=\"1.0\" encoding=\"utf-8\"?><PaymentResponse><ClientKey>${tylerEagleRequest.clientKey}</ClientKey><TransactionID>${tylerEagleRequest.transactionID}</TransactionID><Amount>${tylerEagleRequest.amount}</Amount></PaymentResponse>`} />
            </form>
            <Button className="d-print-none" variant="outline-secondary" onClick={handleClick} >{label}</Button>
        </>
    )
}

const mapStateToProps = (state: IAppState) => {
    return {
        tylerEagleRequest: state.tylerEagleIntegration.tylerEagleRequest
    };
};

export default connect(mapStateToProps)(TylerEagleRedirect);