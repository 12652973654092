import { CellPlugin } from '@react-page/editor';
import moment from "moment-timezone";
import React from 'react';
import { Col, Row } from 'react-bootstrap';

// use a type here, not an interface
type Data = {
  title: string
}

const currentYear = moment().get("year");

const CopyrightFooterPlugin: CellPlugin<Data> = {
  Renderer: ({ data }) => (
      <footer className="footer section py-5 d-print-none">
      <Row>
        <Col lg={12}>
          <p className="mb-0 text-center">
            Copyright &copy; {`${currentYear} `}
          </p>
        </Col>
      </Row>
    </footer>
  ),
  id: 'copyrightFooterPlugin',
  title: 'Copyright Footer',
  description: 'A generic copyright footer.',
  version: 1
};

export default CopyrightFooterPlugin;