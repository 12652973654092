
export class MetaData{
    AccountNumber?: string;
    Address1?: string;
    Address2?:string;
    City?: string;
    OwnerName?:string;
    State?:string;
    Zip?:string;
}


export class ItemDetails {
    clientId: string = "";
    departmentId: string = "";
    externalItemReference: string = "";
    metaData: Array<MetaData> = new Array<MetaData>();
}

export enum ItemType{
    Unknown = 0, 
    BlindPayment = 1,
    Physical = 2,
    Tax = 3,
    Utility = 4,
    PerCapita = 5,
    SchoolMortgage = 6
}