import axios from 'axios';
import { Dispatch } from 'react';
import store from '../store';
import { CreditCard, eCheck } from '../../models/User'
import { ConvenienceFee } from '../../models/Schedule';
import { Client, Department, PaymentChannel } from '../../models/Client';
import { Address } from '../../pages/paymentChannels/web/components/plugins/components/StCharlesAddressChange';

export const GET_ITEMS_REQUEST = 'GET_ITEMS_REQUEST';
export const GET_ITEMS_SUCCESS = 'GET_ITEMS_SUCCESS';
export const GET_ITEMS_FAILURE = 'GET_ITEMS_FAILURE';

export const GET_USER_PAYMENT_METHODS_REQUEST = 'GET_USER_PAYMENT_METHODS_REQUEST';
export const GET_USER_PAYMENT_METHODS_SUCCESS = 'GET_USER_PAYMENT_METHODS_SUCCESS';
export const GET_USER_PAYMENT_METHODS_FAILURE = 'GET_USER_PAYMENT_METHODS_FAILURE';

export const ADD_CREDIT_CARD_REQUEST = 'ADD_CREDIT_CARD_REQUEST';
export const ADD_CREDIT_CARD_SUCCESS = 'ADD_CREDIT_CARD_SUCCESS';
export const ADD_CREDIT_CARD_FAILURE = 'ADD_CREDIT_CARD_FAILURE';

export const ADD_BANK_ACCOUNT_REQUEST = 'ADD_BANK_ACCOUNT_REQUEST';
export const ADD_BANK_ACCOUNT_SUCCESS = 'ADD_BANK_ACCOUNT_SUCCESS';
export const ADD_BANK_ACCOUNT_FAILURE = 'ADD_BANK_ACCOUNT_FAILURE';

export const DELETE_CREDIT_CARD_REQUEST = 'DELETE_CREDIT_CARD_REQUEST';
export const DELETE_CREDIT_CARD_SUCCESS = 'DELETE_CREDIT_CARD_SUCCESS';
export const DELETE_CREDIT_CARD_FAILURE = 'DELETE_CREDIT_CARD_FAILURE';

export const DELETE_BANK_ACCOUNT_REQUEST = 'DELETE_BANK_ACCOUNT_REQUEST';
export const DELETE_BANK_ACCOUNT_SUCCESS = 'DELETE_BANK_ACCOUNT_SUCCESS';
export const DELETE_BANK_ACCOUNT_FAILURE = 'DELETE_BANK_ACCOUNT_FAILURE';

export const CALCULATE_CONVENIENCE_FEE = 'CALCULATE_CONVENIENCE_FEE';
export const CALCULATE_CONVENIENCE_FEE_SUCCESS = 'CALCULATE_CONVENIENCE_FEE_SUCCESS';
export const CALCULATE_CONVENIENCE_FEE_FAILURE = 'CALCULATE_CONVENIENCE_FEE_FAILURE';
export const RESET_CONVENIENCE_FEE_REQUEST = 'RESET_CONVENIENCE_FEE_REQUEST';

export const SCHEDULE_PAYMENT_REQUEST = 'SCHEDULE_PAYMENT_REQUEST';
export const SCHEDULE_PAYMENT_SUCCESS = 'SCHEDULE_PAYMENT_SUCCESS';
export const SCHEDULE_PAYMENT_FAILURE = 'SCHEDULE_PAYMENT_FAILURE';

export const UPDATED_SCHEDULE_PAYMENT_REQUEST = 'UPDATED_SCHEDULE_PAYMENT_REQUEST';
export const UPDATED_SCHEDULE_PAYMENT_SUCCESS = 'UPDATED_SCHEDULE_PAYMENT_SUCCESS';
export const UPDATED_SCHEDULE_PAYMENT_FAILURE = 'UPDATED_SCHEDULE_PAYMENT_FAILURE';

export const SCHEDULE_ITEMS_REQUEST = 'SCHEDULE_ITEMS_REQUEST';
export const SCHEDULE_ITEMS_SUCCESS = 'SCHEDULE_ITEMS_SUCCESS';
export const SCHEDULE_ITEMS_FAILURE = 'SCHEDULE_ITEMS_FAILURE';

export const DELETE_PAYMENT_REQUEST = 'DELETE_PAYMENT_REQUEST';
export const DELETE_PAYMENT_SUCCESS = 'DELETE_PAYMENT_SUCCESS';
export const DELETE_PAYMENT_FAILURE = 'DELETE_PAYMENT_FAILURE'

export const RESET_BANK_NAME = 'RESET_BANK_NAME';
export const RETRIEVE_BANK_NAME = 'RETRIEVE_BANK_NAME';

export const MULTI_ITEMS_SEARCH_REQUEST = 'MULTI_ITEMS_SEARCH_REQUEST';
export const MULTI_ITEMS_SEARCH_SUCCESS = 'MULTI_ITEMS_SEARCH_SUCCESS';
export const MULTI_ITEMS_SEARCH_FAILURE = 'MULTI_ITEMS_SEARCH_FAILURE';
export const MULTI_ITEMS_RESET_REQUEST = 'MULTI_ITEMS_RESET_REQUEST';

export const SUBMIT_ADDRESS_CHANGE_REQUEST = 'SUBMIT_ADDRESS_CHANGE_REQUEST';
export const SUBMIT_ADDRESS_CHANGE_SUCCESS = 'SUBMIT_ADDRESS_CHANGE_SUCCESS';
export const SUBMIT_ADDRESS_CHANGE_FAILURE = 'SUBMIT_ADDRESS_CHANGE_FAILURE';
export const SUBMIT_ADDRESS_RESET_REQUEST = 'SUBMIT_ADDRESS_RESET_REQUEST';

export const getExternalItemRefernce = (externalItemReference: string, actionToken: string) => (dispatch: Dispatch<any>) => {
    dispatch({
        type: GET_ITEMS_REQUEST,
        payload: {
            actionToken
        }
    });

    let paymentChannel = store.getState().paymentChannelWebApplication.paymentChannel! as PaymentChannel;
    let baseApiUrl = store.getState().webAppSettings.baseApiUrl;
    let queryParameters = new URLSearchParams(
        {
            clientId: paymentChannel.department.client.msbId!,
            departmentId: paymentChannel.department.msbId!,
            externalItemReference: externalItemReference
        }
    );
    let _url = baseApiUrl + '/MSB_Item/api/v1/Items/itemReferenceForClientAndDepartment?' + queryParameters.toString();

    let bearerToken = store.getState().webAppSettings.bearerToken;
    const config = {
        headers: { Authorization: `Bearer ${bearerToken}` }
    };

    axios.get(_url.toString(), config)
        .then(function (response) {
            let items = response.data;
            dispatch({
                type: GET_ITEMS_SUCCESS,
                payload: {
                    items,
                    actionToken
                }
            });
        })
        .catch(function (error) {
            dispatch({
                type: GET_ITEMS_FAILURE,
                payload: {
                    error,
                    actionToken
                }
            });
        })
        .then(function () {
        });
};


export const getUserPaymentMethods = (msbid: string, actionToken: string) => (dispatch: Dispatch<any>) => {
    dispatch({
        type: GET_USER_PAYMENT_METHODS_REQUEST,
        payload: {
            actionToken
        }
    });

    let baseApiUrl = store.getState().webAppSettings.baseApiUrl;
    let _url = baseApiUrl + '/MSB_User/api/v1/Users/' + msbid

    let bearerToken = store.getState().webAppSettings.bearerToken;
    const config = {
        headers: { Authorization: `Bearer ${bearerToken}` }
    };

    axios.get(_url, config)
        .then(function (response) {
            let user = response.data.user;
            dispatch({
                type: GET_USER_PAYMENT_METHODS_SUCCESS,
                payload: {
                    user,
                    actionToken
                }
            });
        })
        .catch(function (error) {
            dispatch({
                type: GET_USER_PAYMENT_METHODS_FAILURE,
                payload: {
                    error,
                    actionToken
                }
            });
        })
        .then(function () {
        });
};

export const addCreditCard = (msbid: string, creditCardDetails: CreditCard, actionToken?: string) => (dispatch: Dispatch<any>) => {
    dispatch({
        type: ADD_CREDIT_CARD_REQUEST,
        payload: {
            actionToken
        }
    });

    let paymentChannel = store.getState().paymentChannelWebApplication.paymentChannel! as PaymentChannel;

    let clientMsbId = paymentChannel.department.client.msbId!;
    let departmentMsbId = paymentChannel.department.msbId!;
    let paymentChannelMsbId = paymentChannel.msbId!;

    let baseApiUrl = store.getState().webAppSettings.baseApiUrl;
    let _url = baseApiUrl + '/MSB_User/api/v1/UserCards/createForUser/' + msbid + '?paymentChannelId=' + paymentChannelMsbId
    let bearerToken = store.getState().webAppSettings.bearerToken;

    const config = {
        headers: {
            Authorization: `Bearer ${bearerToken}`,
            ClientId: clientMsbId,
            DepartmentId: departmentMsbId,
            PaymentChannelId: paymentChannelMsbId
        }
    };

    axios.post(_url, creditCardDetails, config)
        .then(function (response) {
            dispatch({
                type: ADD_CREDIT_CARD_SUCCESS,
                payload: {
                    paymentMethodUniqueId: response.data.cards[0].msbId,
                    actionToken
                }
            });
        })
        .catch(function (error) {
            dispatch({
                type: ADD_CREDIT_CARD_FAILURE,
                payload: {
                    error,
                    actionToken
                }
            });
        })
        .then(function () {
        });
};

export const addBankAccount = (msbid: string, bankDetails: eCheck, actionToken?: string) => (dispatch: Dispatch<any>) => {
    dispatch({
        type: ADD_BANK_ACCOUNT_REQUEST,
        payload: {
            actionToken
        }
    });

    let paymentChannel = store.getState().paymentChannelWebApplication.paymentChannel! as PaymentChannel;

    let clientMsbId = paymentChannel.department.client.msbId!;
    let departmentMsbId = paymentChannel.department.msbId!;
    let paymentChannelMsbId = paymentChannel.msbId!;

    let baseApiUrl = store.getState().webAppSettings.baseApiUrl;
    let _url = baseApiUrl + '/MSB_User/api/v1/userBankAccounts/forUser/' + msbid
    let bearerToken = store.getState().webAppSettings.bearerToken;

    const config = {
        headers: {
            Authorization: `Bearer ${bearerToken}`,
            ClientId: clientMsbId,
            DepartmentId: departmentMsbId,
            PaymentChannelId: paymentChannelMsbId
        }
    };

    axios.post(_url, bankDetails, config)
        .then(function (response) {
            dispatch({
                type: ADD_BANK_ACCOUNT_SUCCESS,
                payload: {
                    paymentMethodUniqueId: response.data.bankAccounts[0].msbId,
                    actionToken
                }
            });
        })
        .catch(function (error) {
            dispatch({
                type: ADD_BANK_ACCOUNT_FAILURE,
                payload: {
                    error,
                    actionToken
                }
            });
        })
        .then(function () {
        });
};

export const deleteCreditCard = (msbid: string, actionToken?: string) => (dispatch: Dispatch<any>) => {
    dispatch({
        type: DELETE_CREDIT_CARD_REQUEST,
        payload: {
            msbid,
            actionToken
        }
    });

    let baseApiUrl = store.getState().webAppSettings.baseApiUrl;

    let _url = baseApiUrl + '/MSB_User/api/v1/UserCards/' + msbid + '?force=true';

    axios.delete(_url)
        .then(function (response) {
            dispatch({
                type: DELETE_CREDIT_CARD_SUCCESS,
                payload: {
                    paymentMethodUniqueId: response.data.token,
                    actionToken
                }
            });
        })
        .catch(function (error) {
            dispatch({
                type: DELETE_CREDIT_CARD_FAILURE,
                payload: {
                    error,
                    actionToken
                }
            });
        })
};

export const deleteBankAccount = (msbid: string, actionToken?: string) => (dispatch: Dispatch<any>) => {
    dispatch({
        type: DELETE_BANK_ACCOUNT_REQUEST,
        payload: {
            msbid,
            actionToken
        }
    });

    let baseApiUrl = store.getState().webAppSettings.baseApiUrl;

    let _url = baseApiUrl + '/MSB_User/api/v1/UserBankAccounts/' + msbid + '?force=true';

    axios.delete(_url)
        .then(function (response) {
            dispatch({
                type: DELETE_BANK_ACCOUNT_SUCCESS,
                payload: {
                    paymentMethodUniqueId: response.data.routingNumber,
                    actionToken
                }
            });
        })
        .catch(function (error) {
            dispatch({
                type: DELETE_BANK_ACCOUNT_FAILURE,
                payload: {
                    error,
                    actionToken
                }
            });
        })
};



export const calculateConvenienceFee = (convenienceFee: ConvenienceFee, actionToken?: string) => (dispatch: Dispatch<any>) => {

    dispatch({
        type: CALCULATE_CONVENIENCE_FEE,
        payload: {
            convenienceFee,
            actionToken
        }
    });

    let paymentChannel = store.getState().paymentChannelWebApplication.paymentChannel! as PaymentChannel;

    let clientMsbId = paymentChannel.department.client.msbId!;
    let departmentMsbId = paymentChannel.department.msbId!;
    let paymentChannelMsbId = paymentChannel.msbId!;

    let baseApiUrl = store.getState().webAppSettings.baseApiUrl;
    let _url = baseApiUrl + "/MSB_Order/api/v1/ProcessOrder/IncrementFee";
    let bearerToken = store.getState().webAppSettings.bearerToken;

    const config = {
        headers: {
            Authorization: `Bearer ${bearerToken}`,
            ClientId: clientMsbId,
            DepartmentId: departmentMsbId,
            PaymentChannelId: paymentChannelMsbId
        }
    };

    axios.post(_url, convenienceFee, config)
        .then(function (response) {
            dispatch({
                type: CALCULATE_CONVENIENCE_FEE_SUCCESS,
                payload: {
                    order: response.data.requestObject,
                    actionToken
                }
            });
        })
        .catch(function (error) {
            let errorMessage = 'We could not calculate the fee to be assessed. Please select a payment type and re-enter the payment details.';
            if (error.response) {
                errorMessage = error.response.data.message;
            }
            dispatch({
                type: CALCULATE_CONVENIENCE_FEE_FAILURE,
                payload: {
                    error: errorMessage,
                    actionToken
                }
            });
        });

};

export const resetConvenienceFeeAction = (actionToken?: string) => (dispatch: Dispatch<any>) => {
    dispatch({
        type: RESET_CONVENIENCE_FEE_REQUEST,
        payload: {
            actionToken
        }
    });
};



export const schedulePayment = (schedule: any, actionToken?: string) => (dispatch: Dispatch<any>) => {

    dispatch({
        type: SCHEDULE_PAYMENT_REQUEST,
        payload: {
            actionToken
        }
    });

    let baseApiUrl = store.getState().webAppSettings.baseApiUrl;
    let paymentChannel = store.getState().paymentChannelWebApplication.paymentChannel! as PaymentChannel;

    let clientMsbId = paymentChannel.department.client.msbId!;
    let departmentMsbId = paymentChannel.department.msbId!;
    let paymentChannelMsbId = paymentChannel.msbId!;

    let _url = baseApiUrl + '/MSB_Item/api/v1/FuturePayments/create'
    let bearerToken = store.getState().webAppSettings.bearerToken;

    const config = {
        headers: {
            Authorization: `Bearer ${bearerToken}`,
            ClientId: clientMsbId,
            DepartmentId: departmentMsbId,
            PaymentChannelId: paymentChannelMsbId
        }
    };

    axios.post(_url, schedule, config)
        .then(function (response) {
            dispatch({
                type: SCHEDULE_PAYMENT_SUCCESS,
                payload: {
                    currentSchedule: response.data,
                    actionToken
                }
            });
        })
        .catch(function (error) {
            dispatch({
                type: SCHEDULE_PAYMENT_FAILURE,
                payload: {
                    error,
                    actionToken
                }
            });
        })
        .then(function () {
        });
};

export const userScheduledItems = (userId: string, actionToken?: string) => (dispatch: Dispatch<any>) => {

    dispatch({
        type: SCHEDULE_ITEMS_REQUEST,
        payload: {
            actionToken
        }
    });

    let baseApiUrl = store.getState().webAppSettings.baseApiUrl;
    let _url = baseApiUrl + '/MSB_Item/api/v1/ItemSchedules/forUser?userId=' + userId
    let bearerToken = store.getState().webAppSettings.bearerToken;

    const config = {
        headers: {
            Authorization: `Bearer ${bearerToken}`,
        }
    };

    axios.get(_url, config)
        .then(function (response) {
            dispatch({
                type: SCHEDULE_ITEMS_SUCCESS,
                payload: {
                    userItems: response.data,
                    actionToken
                }
            });
        })
        .catch(function (error) {
            dispatch({
                type: SCHEDULE_ITEMS_FAILURE,
                payload: {
                    error,
                    actionToken
                }
            });
        })
        .then(function () {
        });

}

export const updatedScheduledPayment = (scheduleId: string, schedule: any, actionToken?: string) => (dispatch: Dispatch<any>) => {

    dispatch({
        type: UPDATED_SCHEDULE_PAYMENT_REQUEST,
        payload: {
            actionToken
        }
    });

    let baseApiUrl = store.getState().webAppSettings.baseApiUrl;
    let paymentChannel = store.getState().paymentChannelWebApplication.paymentChannel! as PaymentChannel;

    let clientMsbId = paymentChannel.department.client.msbId!;
    let departmentMsbId = paymentChannel.department.msbId!;
    let paymentChannelMsbId = paymentChannel.msbId!;

    let _url = baseApiUrl + '/MSB_Item/api/v1/FuturePayments/' + scheduleId + '/recreate'
    let bearerToken = store.getState().webAppSettings.bearerToken;

    const config = {
        headers: {
            Authorization: `Bearer ${bearerToken}`,
            ClientId: clientMsbId,
            DepartmentId: departmentMsbId,
            PaymentChannelId: paymentChannelMsbId
        }
    };

    axios.put(_url, schedule, config)
        .then(function (response) {
            dispatch({
                type: UPDATED_SCHEDULE_PAYMENT_SUCCESS,
                payload: {
                    currentSchedule: response.data,
                    actionToken
                }
            });
        })
        .catch(function (error) {
            dispatch({
                type: UPDATED_SCHEDULE_PAYMENT_FAILURE,
                payload: {
                    error,
                    actionToken
                }
            });
        })
        .then(function () {
        });
}

export const deleteScheduledPayments = (scheduleId: string, actionToken?: string) => (dispatch: Dispatch<any>) => {

    dispatch({
        type: DELETE_PAYMENT_REQUEST,
        payload: {
            actionToken
        }
    });

    let baseApiUrl = store.getState().webAppSettings.baseApiUrl;
    let _url = baseApiUrl + '/MSB_Item/api/v1/FuturePayments/' + scheduleId
    let bearerToken = store.getState().webAppSettings.bearerToken;

    const config = {
        headers: {
            Authorization: `Bearer ${bearerToken}`,
        }
    };

    axios.delete(_url, config)
        .then(function (response) {
            dispatch({
                type: DELETE_PAYMENT_SUCCESS,
                payload: {
                    currentSchedule: response.data,
                    actionToken
                }
            });
        })
        .catch(function (error) {
            dispatch({
                type: DELETE_PAYMENT_FAILURE,
                payload: {
                    error,
                    actionToken
                }
            });
        })
        .then(function () {
        });

}

export const resetBankName = () => (dispatch: Dispatch<any>) => {
    dispatch({
        type: RESET_BANK_NAME
    });
};

export const retrieveBankName = (routingNumber: string) => (dispatch: Dispatch<any>) => {
    let bearerToken = store.getState().webAppSettings.bearerToken;
    const config = {
        headers: { Authorization: `Bearer ${bearerToken}` }
    };
    axios
        .get(`${store.getState().webAppSettings.baseApiUrl}/MSB_ECheck/api/v1/ECheckRoutingNumber/${routingNumber}`, config)
        .then(response => {
            if (response.status === 200 && response.data.routingNumber === routingNumber) {
                dispatch({
                    type: RETRIEVE_BANK_NAME,
                    payload: {
                        bankName: response.data.custName
                    }
                });
            } else {
                dispatch({
                    type: RETRIEVE_BANK_NAME,
                    payload: {
                        bankName: ""
                    }
                });
            }
        })
        .catch(error => { console.log('Failed to lookup bank routing number', error) });
        
};


export const getMultiItems = (taxType: string, searchBy: string, value: string,actionToken:string,taxTypeKey?:string, deepLinkCombination?:any,) => (dispatch: Dispatch<any>) => {
    dispatch({
        type: MULTI_ITEMS_SEARCH_REQUEST,
        payload: {
            actionToken
        }
    });

    let baseApiUrl = store.getState().webAppSettings.baseApiUrl;
    let _url;

    if (!deepLinkCombination) {
        taxType?.length != 0 ? _url = baseApiUrl + `/MSB_Item/api/v1/Items/JsonSearch?MetaData.${taxTypeKey}=${taxType}&MetaData.${searchBy.replace(/\s/g, "")}=` + `${value.toString()}` :
            _url = baseApiUrl + `/MSB_Item/api/v1/Items/JsonSearch?MetaData.${searchBy.replace(/\s/g, "")}=` + `${value.toString()}`;
    }
    else { 
        const deepLinkParameters = deepLinkCombination.split('/search?')[1];
        const modifiedMetaDataString = deepLinkParameters?.split('&').map((pair:any) => `MetaData.${pair}`).join('&')
        _url = baseApiUrl + `/MSB_Item/api/v1/Items/JsonSearch?${modifiedMetaDataString}`;
    }
   
    let paymentChannel = store.getState().paymentChannelWebApplication.paymentChannel! as PaymentChannel;

    let clientMsbId = paymentChannel.department.client.msbId!;
    let departmentMsbId = paymentChannel.department.msbId!;
    let paymentChannelMsbId = paymentChannel.msbId!;

    let bearerToken = store.getState().webAppSettings.bearerToken;
    const config = {
        headers: { 
            Authorization: `Bearer ${bearerToken}`,
            ClientId: clientMsbId,
            DepartmentId: departmentMsbId,
            PaymentChannelId: paymentChannelMsbId
         }
    };

  
    axios.get(_url, config)
        .then(function (response) {
            let multiItems = response.data;
            dispatch({
                type: MULTI_ITEMS_SEARCH_SUCCESS,
                payload: {
                    multiItems,
                    actionToken
                }
            });
        })
        .catch(function (error) {
            dispatch({
                type: MULTI_ITEMS_SEARCH_FAILURE,
                payload: {
                    error,
                    actionToken
                }
            });
        })
        .then(function () {
        });
};


export const getStCharlesItems = (taxType: string, searchBy: string, value: string,actionToken:string) => (dispatch: Dispatch<any>) => {
    dispatch({
        type: MULTI_ITEMS_SEARCH_REQUEST,
        payload: {
            actionToken
        }
    });

    let baseApiUrl = store.getState().webAppSettings.baseApiUrl;
    let _url;

    const key = searchBy.replace(/\s/g, "");
    const urlExtension = key === 'AccountNumber' ? 'byAccountNumberOrPin?search' 
    : searchBy === 'BillNumber' ? 'allByBillNumber?billNumber' : '' as any;

    _url = baseApiUrl + `/MSB_Item/api/v1/SaintCharlesLookup/${urlExtension}=`+ `${value.toString()}`;

    let paymentChannel = store.getState().paymentChannelWebApplication.paymentChannel! as PaymentChannel;

    let clientMsbId = paymentChannel.department.client.msbId!;
    let departmentMsbId = paymentChannel.department.msbId!;
    let paymentChannelMsbId = paymentChannel.msbId!;

    let bearerToken = store.getState().webAppSettings.bearerToken;
    const config = {
        headers: { 
            Authorization: `Bearer ${bearerToken}`,
            ClientId: clientMsbId,
            DepartmentId: departmentMsbId,
            PaymentChannelId: paymentChannelMsbId
         }
    };
  
    axios.get(_url, config)
        .then(function (response) {
            let multiItems = filterStCharlesNetTaxes(response.data);
            dispatch({
                type: MULTI_ITEMS_SEARCH_SUCCESS,
                payload: {
                    multiItems,
                    actionToken
                }
            });
        })
        .catch(function (error) {
            dispatch({
                type: MULTI_ITEMS_SEARCH_FAILURE,
                payload: {
                    error,
                    actionToken
                }
            });
        })
        .then(function () {
        });
};

export const getStCharlesItemsByAddress = (address:string, accountSearchBy: string, actionToken:string) => (dispatch: Dispatch<any>) => {
    dispatch({
        type: MULTI_ITEMS_SEARCH_REQUEST,
        payload: {
            actionToken
        }
    });

    let baseApiUrl = store.getState().webAppSettings.baseApiUrl;
    let _url;
    const isOwnerAddress = accountSearchBy === 'Address1' ? true : accountSearchBy === 'PropertyAddress' ? false : '' as any;

    _url = baseApiUrl + `/MSB_Item/api/v1/SaintCharlesLookup/getByAddressWithType?address=${encodeURIComponent(address)}&isOwnerAddress=${isOwnerAddress}`;

    let paymentChannel = store.getState().paymentChannelWebApplication.paymentChannel! as PaymentChannel;

    let clientMsbId = paymentChannel.department.client.msbId!;
    let departmentMsbId = paymentChannel.department.msbId!;
    let paymentChannelMsbId = paymentChannel.msbId!;

    let bearerToken = store.getState().webAppSettings.bearerToken;
    const config = {
        headers: { 
            Authorization: `Bearer ${bearerToken}`,
            ClientId: clientMsbId,
            DepartmentId: departmentMsbId,
            PaymentChannelId: paymentChannelMsbId
         }
    };
  
    axios.get(_url, config)
        .then(function (response) {
            let multiItems = filterStCharlesNetTaxes(response.data);
            dispatch({
                type: MULTI_ITEMS_SEARCH_SUCCESS,
                payload: {
                    multiItems,
                    actionToken
                }
            });
        })
        .catch(function (error) {
            dispatch({
                type: MULTI_ITEMS_SEARCH_FAILURE,
                payload: {
                    error,
                    actionToken
                }
            });
        })
        .then(function () {
        });
};

const filterStCharlesNetTaxes = (data: any) => {
    const filteredData = data.filter((parent: any) => {
        const totalTaxAmountSum = parent?.billInformation?.reduce(
            (sum: any, nestedItem: any) =>
                sum +
                nestedItem?.taxes?.reduce(
                    (nestedSum: any, calculatedItem: any) => nestedSum + calculatedItem?.netTaxAmount, 0), 0);
        return totalTaxAmountSum > 0
    });
    return filteredData
}


export const submitStCharlesAddressChange = (address: Address, actionToken:string) => (dispatch: Dispatch<any>) => {
    dispatch({
        type: SUBMIT_ADDRESS_CHANGE_REQUEST,
        payload: {
            actionToken
        }
    });

    let paymentChannel = store.getState().paymentChannelWebApplication.paymentChannel! as PaymentChannel;

    let clientMsbId = paymentChannel.department.client.msbId!;
    let departmentMsbId = paymentChannel.department.msbId!;
    let paymentChannelMsbId = paymentChannel.msbId!;

    let baseApiUrl = store.getState().webAppSettings.baseApiUrl;
    let _url = baseApiUrl + '/MSB_Item/api/v1/SaintCharlesDataManagement/addressChange'
    let bearerToken = store.getState().webAppSettings.bearerToken;

    const config = {
        headers: {
            Authorization: `Bearer ${bearerToken}`,
            ClientId: clientMsbId,
            DepartmentId: departmentMsbId,
            PaymentChannelId: paymentChannelMsbId
        }
    };

    axios.post(_url, address, config)
        .then(function (response) {
            dispatch({
                type: SUBMIT_ADDRESS_CHANGE_SUCCESS,
                payload: {
                    actionToken
                }
            });
        })
        .catch(function (error) {
            dispatch({
                type: SUBMIT_ADDRESS_CHANGE_FAILURE,
                payload: {
                    error,
                    actionToken
                }
            });
        })
        .then(function () {
        });
};


export const resetItems = () => (dispatch: Dispatch<any>) => {
    dispatch({
        type: MULTI_ITEMS_RESET_REQUEST,
    });
}

export const resetAddressAction = () => (dispatch: Dispatch<any>) => {
    dispatch({
        type: SUBMIT_ADDRESS_RESET_REQUEST,
    });
}