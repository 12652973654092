import { CellPlugin } from '@react-page/editor';
import TylerEagleRedirect, { LABEL } from './components/TylerEagleRedirect';

type Data = {
  text: string,
}

const TylerEagleRedirectPlugin: CellPlugin<Data> = {
  Renderer: ({ data }) => (
        <TylerEagleRedirect label={data.text} />
  ),
  id: 'tylerEagleRedirectPlugin',
  title: 'Tyler Eagle Redirect',
  description: 'Tyler Eagle Redirect button.',
  version: 1,
  controls: {
    type: 'autoform',
    schema: {
      properties: {
        text: {
          type: 'string',
          default: LABEL,
        },
      },
      required: ['text'],
    },
  },
};

export default TylerEagleRedirectPlugin;