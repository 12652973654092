import { ExpandMore, ErrorOutlineOutlined, CreditCardOutlined, AccountBalanceOutlined, CheckCircleOutlined, PointOfSaleOutlined } from "@mui/icons-material";
import { Accordion, AccordionSummary, Typography, Box, AccordionDetails, FormControl, RadioGroup, Stack, Paper, FormControlLabel, Radio } from "@mui/material";
import { IAppState } from "../../redux/storeTypes";
import { Checkout, CheckoutView, CheckoutStatus } from "../../models/CheckoutLink";
import { connect, useDispatch } from "react-redux";
import { useEffect, useRef, useState } from "react";
import { statusCheckoutAction, viewCheckoutAction } from "../../redux/actions/checkout";
import PayByCreditCard from "./PayByCreditCard";
import PayByECheck from "./PayByECheck";
import PayByTerminal from "./PayByTerminal";
import { Order, PaymentCardTypeEnum } from "../../models/Order";
import _ from "lodash";

export interface IPayByProps {
    order: Order;
    checkout: Checkout;
    status: CheckoutStatus;
    view: CheckoutView;
}

const PayBy = ({ order, checkout, status, view }: IPayByProps) => {
    const dispatch = useDispatch();
    const [expanded, setExpanded] = useState(false);
    const [selectedMethod, setSelectedMethod] = useState("");
    const [paymentSummary, setPaymentSummary] = useState("");

    useEffect(() => {
        if (status) {
            if (status.invoice && !status.payBy) setExpanded(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [status]);

    useEffect(() => {
        if (status.payBy && view !== CheckoutView.PAYBY) setExpanded(false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [view]);

    useEffect(() => {
        if (order) {
            const type = (order.cardType === PaymentCardTypeEnum.ElectronicCheck) ? "eCHECK" : _.startCase(PaymentCardTypeEnum[order.cardType].replace("Credit", "").replace("Debit", "")).toUpperCase().replace("AMERICAN EXPRESS", "AMEX");
            const digits = (order.cardType === PaymentCardTypeEnum.ElectronicCheck) ? order.eCheckAccountNumber.slice(-4) : order.creditCardNumber.slice(-4)
            if (digits) {
                setPaymentSummary(`${type} xxxx-${digits}`);
            } else {
                setPaymentSummary(`${type !== "UNKNOWN" ? type : ""}`);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [order]);

    const statusPayBy = () => {
        setExpanded(false);
        dispatch(statusCheckoutAction({ ...status, payBy: true }));
    }

    const handleExpansion = () => {
        const _expanded = expanded ? !status?.payBy : true && status?.invoice;
        setExpanded(_expanded);
        if (_expanded) {
            dispatch(viewCheckoutAction(CheckoutView.PAYBY));
        } else {
            dispatch(viewCheckoutAction(CheckoutView.NONE));
        }
    }

    const handleMethodChange = (event: any) => {
        setSelectedMethod(event.target.value);
        dispatch(statusCheckoutAction({ ...status, payBy: false }));
    }

    return (
        <>
            <Accordion expanded={expanded} disableGutters={true}>
                <AccordionSummary expandIcon={<ExpandMore onClick={handleExpansion} />}>
                    <Typography style={{ flex: 1 }}><Box component="span">{status?.payBy ? < CheckCircleOutlined sx={{ color: 'green', verticalAlign: 'bottom' }} /> : <ErrorOutlineOutlined sx={{ color: 'red', verticalAlign: 'bottom' }} />} Pay By</Box></Typography>
                    <Box component="div" textAlign={'center'} display={'flex'}>{paymentSummary}</Box>
                </AccordionSummary>
                <AccordionDetails>
                    <FormControl style={{ width: '100%' }}>
                        <RadioGroup onChange={handleMethodChange}>
                            <Stack>
                                {checkout.cardMethod &&
                                <Paper sx={{ padding: '0px 10px 0px 10px' }}>
                                        <FormControlLabel value="CC" control={<Radio />} label={<span>Credit or Debit Card </span>} /><CreditCardOutlined sx={{ color: 'lightgrey', verticalAlign: 'middle', float: 'right', marginTop: '8px' }} />
                                    <Box component="div" sx={{ display: selectedMethod === "CC" ? 'block' : 'none' }}>
                                        <PayByCreditCard setStatus={statusPayBy} />
                                    </Box>
                                    </Paper>}
                                {checkout.eCheckMethod &&
                                <Paper sx={{ padding: '0px 10px 0px 10px' }}>
                                        <FormControlLabel value="AH" control={<Radio />} label={<span>Electronic Check</span>} /><AccountBalanceOutlined sx={{ color: 'lightgrey', verticalAlign: 'middle', float: 'right', marginTop: '8px' }} />
                                    <Box component="div" sx={{ display: selectedMethod === "AH" ? 'block' : 'none' }}>
                                        <PayByECheck setStatus={statusPayBy} />
                                    </Box>
                                    </Paper>}
                                {checkout.terminalMethod &&
                                    <Paper sx={{ padding: '0px 10px 0px 10px' }}>
                                    <FormControlLabel value="TE" control={<Radio />} label={<span>Terminal</span>} /><PointOfSaleOutlined sx={{ color: 'lightgrey', verticalAlign: 'middle', float: 'right', marginTop: '8px' }} />
                                    <Box component="div" sx={{ display: selectedMethod === "TE" ? 'block' : 'none' }}>
                                        <PayByTerminal setStatus={statusPayBy} />
                                    </Box>
                                </Paper>}
                            </Stack>
                        </RadioGroup>
                    </FormControl>
                </AccordionDetails>
            </Accordion>
        </>
    );
};

const mapStateToProps = (state: IAppState) => {
    return {
        checkout: state.checkout.checkout,
        status: state.checkout.status,
        view: state.checkout.view,
        order: state.orderManagement.order,
    };
};

export default connect(mapStateToProps)(PayBy);

  