
import React from "react";
import { Container } from 'react-bootstrap';
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { IAppState } from "../redux/storeTypes";
import { Routes } from "../routes";
import LandingPage from "../pages/LandingPage";

export interface IHomePageProps {
}



const Landing = ({ }: IHomePageProps) => {
  return (
    <main className="marketing">
      <LandingPage />
    </main>
  );
};

const mapStateToProps = (state: IAppState) => {
  return {
  };
};

export default connect(mapStateToProps)(Landing);

