import { useEffect, useRef } from "react";
import { connect, useDispatch } from "react-redux";
import { useLocation, useParams } from "react-router-dom";
import { IAppState } from "../../redux/storeTypes";
import { Container, Box, ThemeProvider, CssBaseline } from '@mui/material';
import { Link, LinkStatus, LinkView, Method } from "../../models/CheckoutLink";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import { processLinkAction, statusLinkAction, viewLinkAction } from "../../redux/actions/link";
import Header from "../link/Header";
import Summary from "../link/Summary";
import Memo from "../link/Memo";
import Footer from "../link/Footer";
import WithMethod from "../link/WithMethod";
import { TokenizeBankAccount, TokenizeCard } from "../../models/Wallet";
import { saveBankAccountAction, saveCardAction } from "../../redux/actions/wallet";
import { getPaymentChannelAction } from "../../redux/actions/paymentChannelWebApplications";
import { PaymentChannel } from "../../models/Client";
import createCustomTheme from "../../components/theme";

export interface IParams {
    id: string
}

export interface ILinkPageProps {
    paymentChannel: PaymentChannel;
    siteKey: string;
    link: Link;
    card: TokenizeCard;
    account: TokenizeBankAccount;
}

const LinkPage = ({ paymentChannel, siteKey, link, card, account }: ILinkPageProps) => {
    const actionToken = "LinkPage"
    const location = useLocation();
    const dispatch = useDispatch();
    const origin = useRef("");
    const { id }: IParams = useParams();

    const message = (msg: any) => {
        window.parent.postMessage(JSON.stringify(msg, null, 4), origin.current);
    }

    const listenerOptions = { capture: true, once: false, passive: true };

    const responder = (e: { origin: any; data: string; }) => {
        if (e.data === id) {
            origin.current = e.origin;
            window.removeEventListener("message", responder, listenerOptions);
        }
    };

    useEffect(() => {
        if (id) window.addEventListener("message", responder, listenerOptions);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id]);

    useEffect(() => {
        if (id) {
            const e = new Uint8Array(Buffer.from(id, 'hex'));
            window.crypto.subtle.importKey('raw', e, { name: 'AES-CBC', length: 256 }, false, ['decrypt'])
                .then(k => {
                    window.crypto.subtle.decrypt({ name: 'AES-CBC', iv: e.slice(0, 16) }, k, Buffer.from(new URLSearchParams(location.search).get("context")!, 'hex'))
                        .then(d => {
                            const params = new TextDecoder().decode(d);
                            dispatch(processLinkAction(JSON.parse(params.substring(params.indexOf('{"'))) as Link, actionToken));
                        })
                        .catch(error => { console.error('error:', error); });
                })
                .catch(error => {
                    console.error('import error:', error);
                });
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id]);

    useEffect(() => {
        if (link) {
            dispatch(statusLinkAction(new LinkStatus()));
            dispatch(viewLinkAction(LinkView.NONE));
            const ids = JSON.parse(link.iDs);
            dispatch(getPaymentChannelAction(ids.paymentChannelId));
            if (link.method === Method.CARD) {
                const _card = new TokenizeCard();
                dispatch(saveCardAction(_card));
            }
            if (link.method === Method.ACH) {
                const _account = new TokenizeBankAccount();
                dispatch(saveBankAccountAction(_account));
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [link]);

    if (window.self !== window.top && link && (card || account) && paymentChannel) {
        const customTheme = createCustomTheme({
            mode: link.style?.Mode,
            fontSize: link.style?.Font?.Size,
            fontFamily: link.style?.Font?.Family,
            colorBackground: link.style?.Color?.Background,
            colorPrimary: link.style?.Color?.Primary
        });
        return (
            <ThemeProvider theme={customTheme}>
                <CssBaseline />
                <Container maxWidth="sm">
                    <Box sx={{ my: 0, mx: 0 }}>
                        <Header />
                        <WithMethod />
                        <GoogleReCaptchaProvider reCaptchaKey={siteKey} useRecaptchaNet={true}>
                            <Summary msg={message} />
                        </GoogleReCaptchaProvider>
                        <Memo />
                        <Footer />
                    </Box>
                </Container>
            </ThemeProvider>
        )
    } else {
        return (<></>)
    }
};

const mapStateToProps = (state: IAppState) => {
    return {
        paymentChannel: state.paymentChannelWebApplication.paymentChannel,
        siteKey: state.webAppSettings.siteKey,
        link: state.link.link,
        card: state.wallet.card,
        account: state.wallet.bankAccount,
    };
};

export default connect(mapStateToProps)(LinkPage);

