import { CellPlugin } from '@react-page/editor';
import React from 'react';
import { Col, Row } from 'react-bootstrap';

// use a type here, not an interface
type Data = {
  label: string,
  href: string,
  openInNewWindow?: boolean
}

const LinkPropertyPlugin: CellPlugin<Data> = {
  Renderer: ({ data }) => (
      <Row>
        <Col>
          <a href={data.href} target={data.openInNewWindow ? '_blank' : undefined}>
            {data.label ? data.label : data.href}
          </a>
        </Col>
      </Row>
  ),
  id: 'linkPropertyPlugin',
  title: 'Link Property',
  description: 'Link Property.',
  version: 1,
  controls: {
    type: 'autoform',
    schema: {
      properties: {
        label: {
          type: 'string',
          default: 'Link',
        },
        href: {
          type: 'string',
        },
        openInNewWindow: {
          type: 'boolean',
        },
      },
      type: 'object',
      required: ['href'],
    },
  },
};

export default LinkPropertyPlugin;