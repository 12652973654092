import { faTimesCircle } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { PayPalButtons, PayPalMessages, PayPalScriptProvider } from "@paypal/react-paypal-js";
import _ from "lodash";
import { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { PaymentChannel } from "../../../../../../models/Client";
import { Order } from "../../../../../../models/Order";
import { payPalResponseAction } from "../../../../../../redux/actions/orderManagement";
import { IAppState } from "../../../../../../redux/storeTypes";


export interface IPayPal {
    disabled: boolean;
    order: Order;
    paymentChannel: PaymentChannel;
}

const PayPal = ({ disabled, order, paymentChannel }: IPayPal) => {
    const actionToken = "QuickPayPage";
    const payPalCredential = paymentChannel.processors.length > 0 ? paymentChannel.processors[0].merchantProcessor?.payPalCredential : undefined;
    const clientName = paymentChannel.department.client.businessName;
    const departmentName = paymentChannel.department.name;
    const dispatch = useDispatch();
    const [orderReady, setOrderReady] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [payPalOptions, setPayPalOptions] = useState({ clientId: "", merchantId: "", "data-partner-attribution-id": "NAVIENT_SP_PPCP", components: "messages,buttons,funding-eligibility", "enable-funding": "venmo,paylater", "currency": "USD" });

    const getClientId = async () => {
        const url = `${window.location.protocol}//${window.location.host}/PayPal/ClientId`;
        const response = await fetch(url, { method: 'GET' });
        const result = await response.text();
        return result;
    }

    const setClientId = async () => {
        let _clientId = await getClientId();
        setPayPalOptions(prevState => ({ ...prevState, clientId: _clientId, merchantId: payPalCredential?.payPalMerchantIdentifier! }));
    }

    useEffect(() => {
        setClientId();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        setOrderReady(false);
        setTimeout(() => {setOrderReady(true)}, 500);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [order]);

    const createOrder = (data: any, actions: any) => {
        const items: any[] = [];
        order.orderLines.forEach(orderLine => {
            var item = {
                name: orderLine?.itemName || orderLine?.itemReferenceNumber || "Unspecified",
                unit_amount: {
                    value: orderLine.unitPrice.toFixed(2),
                    currency_code: "USD"
                },
                quantity: orderLine.quantity.toString(),
                sku: orderLine?.itemReferenceNumber || "Unspecified"
            }
            items.push(item);
        });
        var item = {
            name: "Convenience Fee",
            unit_amount: {
                currency_code: "USD",
                value: order.convenienceFee.toFixed(2)
            },
            quantity: "1",
            sku: "MsbFee",
        }
        items.push(item);
        const url = `${window.location.protocol}//${window.location.host}/PayPal/CreateOrder`;
        return fetch(url, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                intent: "CAPTURE",
                purchase_units: [
                    {
                        amount: {
                            currency_code: "USD",
                            value: order.totalAmount.toFixed(2),
                            breakdown: {
                                item_total: { value: order.totalAmount.toFixed(2), currency_code: "USD" },
                            }
                        },
                        items: items,
                        /*
                        shipping: {
                            type: "SHIPPING",
                            address: {
                                address_line_1: order?.addressLine1 || "Unspecified",
                                address_line_2: order?.addressLine2 || "Unspecified",
                                admin_area_2: order?.city || "Unspecified",
                                admin_area_1: order?.state || "Unspecified",
                                postal_code: order?.zip || "Unspecified",
                                country_code: "US"
                            },
                        },
                        */
                        payee: {
                            merchant_id: payPalCredential?.payPalMerchantIdentifier,
                        },
                        payment_instruction: {
                            disbursement_mode: "INSTANT",
                            platform_fees: [
                                {
                                    amount: {
                                        value: _.round(_.divide(_.multiply(order.amount, payPalCredential?.navientCommissionPercentage!),100), 2).toFixed(2),
                                        currency_code: "USD"
                                    },
                                    payee: {
                                        merchant_id: "",
                                    },
                                }
                            ],
                        },
                    },
                ],
                "payment_source": {
                    "paypal": {
                        "experience_context": {
                            "payment_method_preference": "IMMEDIATE_PAYMENT_REQUIRED",
                            "brand_name": `${clientName} - ${departmentName}`,
                            "locale": "en-US",
                            "landing_page": "LOGIN",
                            "shipping_preference": "NO_SHIPPING",
                            "user_action": "PAY_NOW",
                            //"return_url": "https://example.com/returnUrl",
                            //"cancel_url": "https://example.com/cancelUrl"
                        }
                    }
                }
            })
        })
        .then((response) => response.json())
        .then((order) => order.id);
    }

    const onApprove = async (data: any, actions: any) => {
        const url = `${window.location.protocol}//${window.location.host}/PayPal/CaptureOrder`;
        return fetch(url, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                orderID: data.orderID
            })
        })
        .then((response) => response.json())
        .then((result) => {
            if (result.message) {
                setErrorMessage(result.details.reduce((acc: any, curr: any) => `${acc}${curr.issue}:\n${curr.description}\n`, ''));
            } else {
                let form = document?.forms[0];
                result.payer.email_address = (form.elements.namedItem("emailAddress") as HTMLInputElement).value;
                result.payer.address.address_line_1 = (form.elements.namedItem("addressLine1") as HTMLInputElement).value;
                result.payer.address.address_line_2 = (form.elements.namedItem("addressLine2") as HTMLInputElement).value;
                result.payer.address.admin_area_2 = (form.elements.namedItem("city") as HTMLInputElement).value;
                result.payer.address.admin_area_1 = (form.elements.namedItem("state") as HTMLInputElement).value;
                result.payer.address.postal_code = (form.elements.namedItem("zipCode") as HTMLInputElement).value;
                dispatch(payPalResponseAction(result, order, actionToken));
            }
        });
    }

    const onError = (err: any) => {
        console.log("ERROR...", err);
        setErrorMessage(`${err}`.indexOf("fetch") > -1 ? "Error : We are unable to access PayPal services at this time. Please try again later." : `${err}`);
    }

    const onCancel = () => {
        console.log("CANCEL...");
        setErrorMessage("");
    }

    const onClick = (data: any, actions: any) => {
        console.log("CLICK...");
        setErrorMessage("");
        if (document?.forms[0].checkValidity()) {
            return actions.resolve();
        } else {
            setTimeout(() => { document?.forms[0].reportValidity() }, 300);
            return actions.reject();
        }
    }

    if (orderReady && payPalOptions.clientId && payPalCredential?.permissionGranted && payPalCredential?.consentStatus && payPalCredential?.isEmailConfirmed && payPalCredential?.isPaymentReceivable) {
        return (
            <div style={{ margin: "0 auto", maxWidth: "750px" }}>
                <PayPalScriptProvider options={payPalOptions}>
                    <PayPalButtons
                        disabled={disabled}
                        fundingSource={"paypal"}
                        createOrder={createOrder}
                        onApprove={onApprove}
                        onError={onError}
                        onCancel={onCancel}
                        onClick={onClick}
                    />
                    {(payPalCredential.allowCredit) ?
                        <div>
                            <PayPalButtons
                                disabled={disabled}
                                fundingSource={"credit"}
                                createOrder={createOrder}
                                onApprove={onApprove}
                                onError={onError}
                                onCancel={onCancel}
                                onClick={onClick}
                            />
                            <PayPalButtons
                                style={{ color: "blue" } }
                                disabled={disabled}
                                fundingSource={"paylater"}
                                createOrder={createOrder}
                                onApprove={onApprove}
                                onError={onError}
                                onCancel={onCancel}
                                onClick={onClick}
                            />
                            <div
                                data-pp-message
                                data-pp-amount={order.totalAmount.toFixed(2)} >
                            </div>
                        </div>
                        :
                        <></>
                    }
                    {(payPalCredential.allowVenmo) ?
                        <PayPalButtons
                            disabled={disabled}
                            fundingSource={"venmo"}
                            createOrder={createOrder}
                            onApprove={onApprove}
                            onError={onError}
                            onCancel={onCancel}
                            onClick={onClick}
                        />
                        :
                        <></>
                    }
                    {(payPalCredential.allowCard) ?
                        <PayPalButtons
                            disabled={disabled}
                            fundingSource={"card"}
                            createOrder={createOrder}
                            onApprove={onApprove}
                            onError={onError}
                            onCancel={onCancel}
                            onClick={onClick}
                        />
                        :
                        <></>
                    }
                </PayPalScriptProvider>

                {(errorMessage) ?
                    <div className="alert alert-danger mb-0 mt-2 d-flex align-items-start" role="alert" id="paymentErrorBox">
                        <FontAwesomeIcon icon={faTimesCircle} size="sm" />
                        <pre style={{ marginLeft: "10px", whiteSpace: "pre-wrap" }}>{errorMessage}</pre>
                    </div>
                    :
                    <></>
                }
            </div>
        );
    } else {
        if (!payPalCredential?.permissionGranted || !payPalCredential?.consentStatus) {
            return (
                <h2 style={{ textAlign: "center", backgroundColor: "gold", padding: "5px" }}>Pending PayPal Onboarding Completetion</h2>
            )
        } else {
            return (
                <></>
            )
        }
    }
}

const mapStateToProps = (state: IAppState) => {
    return {
        order: state.orderManagement.order,
        paymentChannel: state.paymentChannelWebApplication.paymentChannel,
        isSaving: state.orderManagement.isSaving
    };
};

export default connect(mapStateToProps)(PayPal);