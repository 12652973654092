import { Row, Col, Spinner, Modal, Form, Popover, OverlayTrigger } from "react-bootstrap"
import Table from '../../../../../../components/Table';
import { IAppState } from "../../../../../../redux/storeTypes";
import { connect } from "react-redux";
import { useEffect, useState } from "react";
import FormHeader from "../../../../../../components/layout/FormHeader";
import { ItemStatusType } from "../../../../../../models/Order";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faExclamationTriangle, faInfoCircle, faTimes } from "@fortawesome/free-solid-svg-icons";
import moment from "moment-timezone";
import LoganPaymentRules from "./LoganPaymentRules";

interface ILoganSearchResults {
    taxPayerNameLabel: string,
    parcelNumberLabel: string,
    streetAddressLabel: string,
    propertyTypeCodeLabel: string,
    propertyTypeDescriptionLabel: string,
    taxYearLabel: string,
    balanceLabel: string,
    statementNumberLabel: string,
    statusLabel: string,
    actionsLabel: string,
    observesWeekends: boolean,
    durationOfPendingHours: number,
    overlayText: string,
    overlayBackground: string,
    overlayTextColor: string,
    overlayIconColor: string,
    margin: string,
    padding: string,
    headerBackgroundColor: string,
    headerPadding: string,
    itemDetails: any,
    isFetching: boolean
}

export const TAXPAYER_NAME_LABEL = 'Taxpayer Name';
export const PARCEL_NUMBER_LABEL = 'Parcel Number';
export const STREET_ADDRESS_LABEL = 'Street Address';
export const PROPERTY_TYPE_LABEL = 'Property Type';
export const PROPERTY_TYPE_DESCRIPTION = 'Property Type Description';
export const TAX_YEAR_LABEL = 'Tax Year';
export const BALANCE_LABEL = 'Balance';
export const STATEMENT_NUMBER_LABEL = 'Statement Number';
export const STATUS_LABEL = 'Status';
export const ACTIONS_LABEL = 'Actions';
export const OBSERVES_WEEKENDS = false;
export const DURATION_OF_PENDING_HOURS = 24;
export const OVERLAY_TEXT = 'I’m sorry. You will need to contact the Logan County Treasurer at 970-522-2462 to retrieve the balance owed for this account.';
export const OVERLAY_BACKGROUND = '#fef1b5';
export const OVERLAY_TEXT_COLOR = '#1e1e1e';
export const OVERLAY_ICON_COLOR = 'orange';
export const MARGIN = '0px';
export const PADDING = '0px';
export const HEADER_BACKGROUND_COLOR = 'rgb(250, 250, 250)';
export const HEADER_PADDING = '0';

const checkStyleForDefault = (style: string, defaultStyle: string) => {
    return style && style !== '' ? style : defaultStyle;
}

const LoganSearchResults = ({ taxPayerNameLabel, parcelNumberLabel, streetAddressLabel, propertyTypeCodeLabel,
    propertyTypeDescriptionLabel, taxYearLabel, balanceLabel, statementNumberLabel, statusLabel, actionsLabel, overlayText, overlayBackground, overlayTextColor, overlayIconColor,
    durationOfPendingHours, observesWeekends, margin, padding, headerBackgroundColor, headerPadding, itemDetails, isFetching }: ILoganSearchResults) => {

    if (!taxPayerNameLabel) { taxPayerNameLabel = TAXPAYER_NAME_LABEL }
    if (!parcelNumberLabel) { parcelNumberLabel = PARCEL_NUMBER_LABEL }
    if (!streetAddressLabel) { streetAddressLabel = STREET_ADDRESS_LABEL }
    if (!propertyTypeCodeLabel) { propertyTypeCodeLabel = PROPERTY_TYPE_LABEL }
    if (!propertyTypeDescriptionLabel) { propertyTypeDescriptionLabel = PROPERTY_TYPE_DESCRIPTION }
    if (!statementNumberLabel) { statementNumberLabel = STATEMENT_NUMBER_LABEL }
    if (!taxYearLabel) { taxYearLabel = TAX_YEAR_LABEL }
    if (!balanceLabel) { balanceLabel = BALANCE_LABEL }
    if (!statusLabel) { statusLabel = STATUS_LABEL }
    if (!actionsLabel) { actionsLabel = ACTIONS_LABEL }
    if (!durationOfPendingHours) { durationOfPendingHours = DURATION_OF_PENDING_HOURS }
    if (!observesWeekends) { observesWeekends = OBSERVES_WEEKENDS }
    if (!overlayText) { overlayText = OVERLAY_TEXT }
    if (!overlayBackground) { overlayBackground = OVERLAY_BACKGROUND }
    if (!overlayTextColor) { overlayTextColor = OVERLAY_TEXT_COLOR }
    if (!overlayIconColor) { overlayIconColor = OVERLAY_ICON_COLOR }
    if (!margin) { margin = MARGIN }
    if (!padding) { padding = PADDING }
    if (!headerBackgroundColor) { headerBackgroundColor = HEADER_BACKGROUND_COLOR }
    if (!headerPadding) { headerPadding = HEADER_PADDING }

    const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
    });

    const amountFormatter = (cell: any) => {
        return (<span>{formatter.format(cell)}</span>)
    }

    const statusFormatter = (cell: any, row: any) => {
        const statusValue = (row?.itemPaymentStatus == ItemStatusType.Paid || row?.metaData?.GrandTotal == 0) ? 'Paid' : row?.itemPaymentStatus == ItemStatusType.Pending ? 'Pending' : 'Unpaid';
        let color: string = "orange";
        if (statusValue === 'Paid') { color = "#52C41A" }
        if (statusValue === 'Pending') { color = "#f5b759" }
        if (statusValue === 'Unpaid') { color = "#F5222D" }
        return (
            <span style={{ display: 'flex', alignItems: 'center' }}>
                <FontAwesomeIcon icon={statusValue === 'Paid' ? faCheck : statusValue === 'Pending' ? faInfoCircle : faTimes} className="btn-icon" style={{ color: `${color}` }} />
                {statusValue}
            </span>
        )
    }

    const paymentOptionsFormatter = (cell: any, row: any) => {
        const isViewable = row?.itemPaymentStatus != ItemStatusType.Pending && row?.itemPaymentStatus != ItemStatusType.Paid && row?.metaData?.GrandTotal > 0;
        const infoOverlay = row?.metaData?.TaxYear == moment().year() -1;
        if (!infoOverlay) {
            return (
                <span style={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
                    <OverlayTrigger trigger="click" rootClose placement="top" overlay={(
                        <Popover id="popover-positioned-top" style={{ backgroundColor: overlayBackground, color: overlayTextColor, minWidth: '400px' }}>
                            <Popover.Content>
                                <div style={{ display: 'flex', alignItems: 'center', columnGap: '8px', fontSize: 'medium' }}>
                                    <FontAwesomeIcon icon={faExclamationTriangle} color={overlayIconColor} className="btn-icon" />
                                    {overlayText}
                                </div>
                            </Popover.Content>
                        </Popover>
                    )}>
                        <FontAwesomeIcon icon={faInfoCircle} className="btn-icon" style={{ color: "#6495ed", cursor: 'pointer' }} />
                    </OverlayTrigger>
                </span>
            )
        }
        if (isViewable) {
            return (
                <span><a onClick={() => { setSelectedRow(row); setShowModal(true) }}>Payment Plans</a></span>
            )
        }
    }

    const initialColumns = [{
        dataField: 'metaData.TaxpayerName',
        text: taxPayerNameLabel,
        headerStyle: {
            backgroundColor: checkStyleForDefault(headerBackgroundColor, HEADER_BACKGROUND_COLOR)
        },
    }, {
        dataField: 'metaData.ParcelNumber',
        text: parcelNumberLabel,
        editable: false,
        headerStyle: {
            backgroundColor: checkStyleForDefault(headerBackgroundColor, HEADER_BACKGROUND_COLOR)
        },
        sort: true,
    }, {
        dataField: 'metaData.SitusAddress',
        text: streetAddressLabel,
        headerStyle: {
            backgroundColor: checkStyleForDefault(headerBackgroundColor, HEADER_BACKGROUND_COLOR)
        },
        editable: false,
        sort: true
    }, {
        dataField: 'metaData.PropertyTypeCode',
        text: propertyTypeCodeLabel,
        headerStyle: {
            backgroundColor: checkStyleForDefault(headerBackgroundColor, HEADER_BACKGROUND_COLOR)
        },
        editable: false,
    }, {
        dataField: 'metaData.TaxYear',
        text: taxYearLabel,
        headerStyle: {
            backgroundColor: checkStyleForDefault(headerBackgroundColor, HEADER_BACKGROUND_COLOR)
        },
        editable: false,
    }, {
        dataField: 'metaData.GrandTotal',
        text: balanceLabel,
        headerStyle: {
            backgroundColor: checkStyleForDefault(headerBackgroundColor, HEADER_BACKGROUND_COLOR)
        },
        formatter: amountFormatter,
        editable: false,
    }, {
        dataField: 'status',
        text: statusLabel,
        headerStyle: {
            backgroundColor: checkStyleForDefault(headerBackgroundColor, HEADER_BACKGROUND_COLOR)
        },
        formatter: statusFormatter,
        editable: false,
    }, {
        dataField: 'action',
        text: actionsLabel,
        headerStyle: {
            backgroundColor: checkStyleForDefault(headerBackgroundColor, HEADER_BACKGROUND_COLOR)
        },
        formatter: paymentOptionsFormatter,
        editable: false,
    }];

    const [columns, setColumns] = useState<any>(initialColumns);
    const [data, setData] = useState<any>([]);
    const [selectedRow, setSelectedRow] = useState<any>([]);
    const [showModal, setShowModal] = useState<boolean>(false);


    const mappingDetails = [
        { heading: taxPayerNameLabel, value: selectedRow?.metaData?.TaxpayerName },
        { heading: parcelNumberLabel, value: selectedRow?.metaData?.ParcelNumber },
        { heading: streetAddressLabel, value: selectedRow?.metaData?.SitusAddress },
        { heading: propertyTypeCodeLabel, value: selectedRow?.metaData?.PropertyTypeCode },
        { heading: propertyTypeDescriptionLabel, value: selectedRow?.metaData?.PropertyTypeDescription },
        { heading: taxYearLabel, value: selectedRow?.metaData?.TaxYear },
        { heading: statementNumberLabel, value: selectedRow?.metaData?.StatementNumber },
    ]

    useEffect(() => {
        if (itemDetails) {
            setData(itemDetails)
        }
    }, [itemDetails])

    const renderTable = () => {
        if (isFetching) {
            return (<div style={{ textAlign: 'center' }} ><Spinner animation="border" /> </div>);
        }
        else if (data.length === 0) {
            return (<div>Currently there are no records to display. Please do a search to find the record you are looking for.</div>)
        } else {
            return (
                <>
                    <Table
                        keyField="msbId"
                        data={data}
                        remote={false}
                        columns={columns}
                        sizePerPage={5}
                    />
                </>);
        }
    };


    return (

        <div style={{ margin: margin, padding: padding }}>
            <Row><Col>{renderTable()}</Col></Row>
            <Modal show={showModal} size={'lg'} backdrop="static" onHide={() => setShowModal(false)}>
                <Modal.Header closeButton />
                <Modal.Body>
                    <FormHeader title="Item Details" description={"Below are the selected item details"} />
                    <Form.Group controlId="printTerminal" >
                        <>
                            {Object.entries(mappingDetails).map(
                                ([index, selectedArray]: any) => {
                                    return (
                                        <div key={index} className="neric_details_row">
                                            <h3>{selectedArray.heading}</h3>
                                            <span>{selectedArray.value}</span>
                                        </div>
                                    )
                                }
                            )}
                        </>
                    </Form.Group>
                    <hr />
                    <FormHeader title="Payment Options" description={"Please select the following payment options"} />
                    <Form.Group controlId="paymentOptions">
                        {
                            selectedRow ?
                            <LoganPaymentRules selectedRow={selectedRow} onClose={() => setShowModal(false)} observesWeekends={observesWeekends} durationOfPendingHours={durationOfPendingHours} />
                            :
                            <span> 'There are no payment options.</span>
                        }
                    </Form.Group>
                </Modal.Body>
            </Modal>
        </div>

    )
}

const mapStateToProps = (state: IAppState) => {
    return {
        isFetching: state.account.isFetching,
        itemDetails: state.account.itemDetails
    };
};

export default connect(mapStateToProps)(LoganSearchResults);
