import { Row, Col, Button, Modal, Form } from "react-bootstrap"
import Table from '../../../../../../components/Table';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCartPlus } from "@fortawesome/free-solid-svg-icons";
import { useEffect, useState } from "react";
import { IActionResult, IAppState } from "../../../../../../redux/storeTypes";
import { connect, useDispatch } from "react-redux";
import { Order } from "../../../../../../models/Order";
import { CurrencyFormatter } from "../../../../../../components/Formatters";
import { ADD_ORDER_LINE, UPDATE_ORDER_DETAILS, calculateOrderAction, updateOrderDetailsAction, updateShoppingCart } from "../../../../../../redux/actions/orderManagement";
import { scrollToPaymentType } from "./StCharlesShoppingCart";
import _ from "lodash";
import { DateFormatter } from "./LarimerSearchResults";

interface ILarimerShoppingCart {
    title: string,
    subTitle: string,
    citationNumberLabel: string,
    fullNameLabel: string,
    issueDateLabel: string,
    amountDueLabel: string,
    actionsLabel: string,
    headerBackgroundColor: string,
    headerPadding: string,
    margin: string,
    padding: string,
    cartButtonBackgroundColor: string,
    cartButtonFontColor: string,
    cartButtonFontSize: string,
    cartButtonFontStyle: string,
    cartButtonFontWeight: string,
    cartButtonBorderColor: string,
    cartIconColor: string,
    actionResult: IActionResult,
    shoppingCart: any,
    order: Order,
}

export const TITLE = 'Shopping Cart';
export const SUBTITLE = ''
export const CITATION_NUMBER_LABEL = 'Citation Number';
export const FULL_NAME_LABEL = 'Name'
export const ISSUE_DATE_LABEL = 'Issue Date';
export const AMOUNT_DUE_LABEL = 'Amount';
export const ACTIONS_LABEL = 'Actions';
export const MARGIN = '0px';
export const PADDING = '0px';
export const HEADER_BACKGROUND_COLOR = 'rgb(250, 250, 250)';
export const HEADER_PADDING = '0';
export const CART_BUTTON_FONT_COLOR = '#414141';
export const CART_BUTTON_FONT_SIZE = '.875rem';
export const CART_BUTTON_FONT_STYLE = 'normal';
export const CART_BUTTON_FONT_WEIGHT = 'normal';
export const CART_BUTTON_BORDER_COLOR = '#D9D9D9';
export const CART_BUTTON_BACKGROUND_COLOR = '#FFFFFF';
export const CART_ICON_COLOR = '#414141';

const checkStyleForDefault = (style: string, defaultStyle: string) => {
    return style && style !== '' ? style : defaultStyle;
}

const checkStyleForDefault3 = (defaultStyle: string) => {
    return defaultStyle as any;
}

const LarimerShoppingCart = ({ title, subTitle, citationNumberLabel, fullNameLabel, issueDateLabel, amountDueLabel, actionsLabel,
    margin, padding, headerBackgroundColor, headerPadding, cartButtonBackgroundColor, cartButtonFontColor, cartButtonFontSize, 
    cartButtonFontStyle, cartButtonFontWeight, cartButtonBorderColor, cartIconColor, shoppingCart, order, actionResult }: ILarimerShoppingCart) => {

    if (!title) { title = TITLE }
    if (!subTitle) { subTitle = SUBTITLE }
    if (!citationNumberLabel) { citationNumberLabel = CITATION_NUMBER_LABEL }
    if (!fullNameLabel) { fullNameLabel = FULL_NAME_LABEL }
    if (!issueDateLabel) { issueDateLabel = ISSUE_DATE_LABEL }
    if (!amountDueLabel) { amountDueLabel = AMOUNT_DUE_LABEL }
    if (!actionsLabel) { actionsLabel = ACTIONS_LABEL }
    if (!margin) { margin = MARGIN }
    if (!padding) { padding = PADDING }
    if (!headerBackgroundColor) { headerBackgroundColor = HEADER_BACKGROUND_COLOR }
    if (!headerPadding) { headerPadding = HEADER_PADDING }
    if (!cartButtonBackgroundColor) { cartButtonBackgroundColor = CART_BUTTON_BACKGROUND_COLOR }
    if (!cartButtonFontColor) { cartButtonFontColor = CART_BUTTON_FONT_COLOR }
    if (!cartButtonFontSize) { cartButtonFontSize = CART_BUTTON_FONT_SIZE }
    if (!cartButtonFontStyle) { cartButtonFontStyle = CART_BUTTON_FONT_STYLE }
    if (!cartButtonFontWeight) { cartButtonFontWeight = CART_BUTTON_FONT_WEIGHT }
    if (!cartButtonBorderColor) { cartButtonBorderColor = CART_BUTTON_BORDER_COLOR }
    if (!cartIconColor) { cartIconColor = CART_ICON_COLOR }


    const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
    });

    const amountFormatter = (cell: any) => {
        return (<span>{formatter.format(cell)}</span>)
    }

    const actionsFormatter = (cell: any, row: any) => {
        return (
            <Button size="sm" variant="outline-secondary" onClick={() => setDeleteRow(row)}>
                Delete
            </Button>
        );
    }

    const initialColumns = [{
        dataField: 'citationNumber',
        text: citationNumberLabel,
        headerStyle: {
            backgroundColor: checkStyleForDefault(headerBackgroundColor, HEADER_BACKGROUND_COLOR)
        },
        sort: false
    }, {
        dataField: 'name',
        text: fullNameLabel,
        editable: false,
        headerStyle: {
            backgroundColor: checkStyleForDefault(headerBackgroundColor, HEADER_BACKGROUND_COLOR)
        },
        sort: true,
    }, {
        dataField: 'issueDate',
        text: issueDateLabel,
        editable: false,
        headerStyle: {
            backgroundColor: checkStyleForDefault(headerBackgroundColor, HEADER_BACKGROUND_COLOR)
        },
        sort: true,
        formatter: DateFormatter,
    }, {
        dataField: 'paymentAmount',
        text: amountDueLabel,
        editable: false,
        headerStyle: {
            backgroundColor: checkStyleForDefault(headerBackgroundColor, HEADER_BACKGROUND_COLOR)
        },
        sort: true,
        formatter: amountFormatter
    }, {
        dataField: 'id',
        text: actionsLabel,
        headerStyle: {
            backgroundColor: checkStyleForDefault(headerBackgroundColor, HEADER_BACKGROUND_COLOR)
        },
        formatter: actionsFormatter,
        editable: false,
    }];

    const [columns, setColumns] = useState<any>(initialColumns);
    const dispatch = useDispatch();
    const [deleteRow, setDeleteRow] = useState<any>('');
    const actionToken = 'ItemShoppingCart';
    const [showModal, setShowModal] = useState<boolean>(false);


    useEffect(() => {
        if (shoppingCart && order) {
            let _shoppingCart = shoppingCart.map((shoppingCartDetails: any) => {
                if (shoppingCartDetails?.id != deleteRow?.id) {
                    return shoppingCartDetails
                }
            }).filter(Boolean)
            let _updatedOrderLines = order?.orderLines.map((orderDetails: any) => {
                if (orderDetails?.itemReferenceNumber != deleteRow?.id) {
                    return orderDetails
                }
            }).filter(Boolean)
            order.orderLines = _updatedOrderLines;
            dispatch(updateShoppingCart(_shoppingCart, actionToken));
            dispatch(updateOrderDetailsAction(order, actionToken));
        }
    }, [deleteRow]);

    useEffect(() => {
        if (actionResult && actionResult.type === ADD_ORDER_LINE) {
            dispatch(calculateOrderAction(actionToken));
        }
        if (actionResult && actionResult.type === UPDATE_ORDER_DETAILS) {
            dispatch(calculateOrderAction(actionToken));
        }
    }, [actionResult]);

    useEffect(() => {
        if(shoppingCart){
            setColumns([...initialColumns]);
        }
    }, [shoppingCart])

    const renderTable = () => {
        return (
            <>
                {shoppingCart?.length > 0 &&
                    <table className="table table-bordered">
                        <tbody>
                            <tr style={{ fontWeight: 'bold' }}>
                                <td></td>
                                <td style={{ float: 'right', marginRight: '20px', borderStyle: 'none' }}>Total : {CurrencyFormatter(_.sumBy(shoppingCart, 'paymentAmount'))}</td>
                                <td><Button size="sm" style={{ border: 'none', backgroundColor: '#f0ad4e' }} onClick={(e) => clearCart(e)}>Empty Cart</Button></td>
                            </tr>
                        </tbody>
                    </table>
                }
                <Table
                    keyField="id"
                    data={shoppingCart}
                    columns={columns}
                    remote={false}
                />
            </>
        );
    };

    const scrollToCheckout = (e: any) => {
        e.preventDefault();
        setShowModal(false);
        setTimeout(() => {
            scrollToPaymentType();
        }, 100);

    }

    const clearCart = (e: any) => {
        e.preventDefault();
        let order = new Order();
        order.orderLines = [];
        dispatch(updateShoppingCart([], actionToken));
        dispatch(updateOrderDetailsAction(order, actionToken));
    }

    return (
        <div style={{ margin: margin, padding: padding }}>
            {shoppingCart?.length > 0 &&
                <Row>
                    <Col>
                        <Button className="brand-btn-primary" style={{
                            marginTop: '29px',
                            backgroundColor: checkStyleForDefault(cartButtonBackgroundColor, CART_BUTTON_BACKGROUND_COLOR),
                            borderColor: checkStyleForDefault(cartButtonBorderColor, CART_BUTTON_BORDER_COLOR),
                            color: checkStyleForDefault(cartButtonFontColor, CART_BUTTON_FONT_COLOR),
                            fontSize: checkStyleForDefault(cartButtonFontSize, CART_BUTTON_FONT_SIZE),
                            fontStyle: checkStyleForDefault(cartButtonFontStyle, CART_BUTTON_FONT_STYLE),
                            fontWeight: checkStyleForDefault3(cartButtonFontWeight)
                        }} onClick={() => setShowModal(true)}>
                            <FontAwesomeIcon icon={faCartPlus} style={{ color: checkStyleForDefault(cartIconColor, CART_ICON_COLOR) }} className="btn-icon" />{`View Cart (${shoppingCart?.length})`}
                        </Button>
                    </Col>
                </Row>
            }
            <Modal show={showModal} size={'xl'} backdrop="static" onHide={() => setShowModal(false)}>
                <Modal.Header closeButton />
                <Modal.Body>
                    <Form.Group>
                        <div className="form-header">
                            <h2>{title}</h2>
                            <p>{subTitle}</p>
                        </div>
                        {renderTable()}
                        <div style={{ float: 'right' }}>
                            <Button style={{ marginRight: '8px' }} onClick={(e) => scrollToCheckout(e)}>Checkout</Button>
                            <Button variant="outline-secondary" onClick={() => setShowModal(false)}>Close</Button>
                        </div>
                    </Form.Group>
                </Modal.Body>
            </Modal>
        </div>

    )
}


const mapStateToProps = (state: IAppState) => {
    return {
        isSaving: state.orderManagement.isSaving,
        actionResult: state.orderManagement.actionResult,
        order: state.orderManagement.order,
        shoppingCart: state.orderManagement.shoppingCart
    };
};

export default connect(mapStateToProps)(LarimerShoppingCart);
