import React from "react";
import { connect } from "react-redux";
import { IAppState } from "../redux/storeTypes";
import StatusPage from "../pages/StatusPage";

export interface IStatusPageProps {
}

const Status = ({ }: IStatusPageProps) => {
  return (
    <main className="msb-portal msb-portal-status">
      <StatusPage />
    </main>
  );
};

const mapStateToProps = (state: IAppState) => {
  return {
  };
};

export default connect(mapStateToProps)(Status);

