import {
    GET_CMS_CONTENT_REQUEST,
    GET_CMS_CONTENT_SUCCESS,
    GET_CMS_CONTENT_FAILURE,
    SAVE_CMS_CONTENT_REQUEST,
    SAVE_CMS_CONTENT_SUCCESS,
    SAVE_CMS_CONTENT_FAILURE
} from '../actions/cms';

export default function cms(state = {
    isFetching: false,
    isSaving: false,
    content: null,
    actionResult: null
}, { type, payload }) {
    switch (type) {
        case GET_CMS_CONTENT_REQUEST:
            return Object.assign({}, state, {
                isFetching: true,
                content: null,
                actionResult: { type: GET_CMS_CONTENT_REQUEST, result: null, token: payload.actionToken } ,
            });
        case GET_CMS_CONTENT_SUCCESS:
            return Object.assign({}, state, {
                isFetching: false,
                content: payload.content,
                actionResult: { type: GET_CMS_CONTENT_REQUEST, result: GET_CMS_CONTENT_SUCCESS, token: payload.actionToken } ,
            });
        case GET_CMS_CONTENT_FAILURE:
            return Object.assign({}, state, {
                isFetching: false,
                actionResult: { type: GET_CMS_CONTENT_REQUEST, result: GET_CMS_CONTENT_FAILURE, token: payload.actionToken } ,
                errorMessage: payload.error
            });
        case SAVE_CMS_CONTENT_REQUEST:
            return Object.assign({}, state, {
                isSaving: true,
                actionResult: { type: SAVE_CMS_CONTENT_REQUEST, result: null, token: payload.actionToken } ,
            });
        case SAVE_CMS_CONTENT_SUCCESS:
            return Object.assign({}, state, {
                isSaving: false,
                actionResult: { type: SAVE_CMS_CONTENT_REQUEST, result: SAVE_CMS_CONTENT_SUCCESS, token: payload.actionToken } ,
                content: payload.content
            });
        case SAVE_CMS_CONTENT_FAILURE:
            return Object.assign({}, state, {
                isSaving: false,
                actionResult: { type: SAVE_CMS_CONTENT_REQUEST, result: SAVE_CMS_CONTENT_FAILURE, token: payload.actionToken } ,
                errorMessage: payload.error
            });
        default:
            return state;
    }
}
