import { CellPlugin } from '@react-page/editor';
import NavigationMenu from './components/NavigationMenu';

type Data = {
  text: string,
  path: string,
}

const NavigationLinkPlugin: CellPlugin<Data> = {
  Renderer: ({ data }) => (
        <NavigationMenu
          text={data.text}
          path={data.path}
        />
  ),
  id: 'navigationMenuPlugin',
  title: 'Navigation Menu',
  description: 'A navigation menu.',
  version: 1,
  controls: {
    type: 'autoform',
    schema: {
      properties: {
        text: {
          type: 'string',
          default: '',
        },
        path: {
          type: 'string',
          default: '',
        },
      },
      required: ['text', 'path'],
    },
  },
};

export default NavigationLinkPlugin;