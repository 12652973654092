import { Dispatch } from 'react';
import axios from 'axios';
import axiosRetry from 'axios-retry';
import { AccountDetails, Order, OrderLine, PaymentCardTypeEnum, PaymentType } from '../../models/Order';
import store from '../store';
import { setRedirectCancelAction } from './redirectIntegration';
import { MULTI_ITEMS_RESET_REQUEST } from './account';
import { IntegrationType, PaymentChannel } from '../../models/Client';

export const CALCULATE_ORDER_REQUEST = 'CALCULATE_ORDER_REQUEST';
export const CALCULATE_ORDER_SUCCESS = 'CALCULATE_ORDER_SUCCESS';
export const CALCULATE_ORDER_FAILURE = 'CALCULATE_ORDER_FAILURE';

export const PROCESS_ORDER_REQUEST = 'PROCESS_ORDER_REQUEST';
export const PROCESS_ORDER_SUCCESS = 'PROCESS_ORDER_SUCCESS';
export const PROCESS_ORDER_FAILURE = 'PROCESS_ORDER_FAILURE';

export const STCHARLES_STATUS_REQUEST = 'STCHARLES_STATUS_REQUEST';
export const STCHARLES_STATUS_SUCCESS = 'STCHARLES_STATUS_SUCCESS';
export const STCHARLES_STATUS_FAILURE = 'STCHARLES_STATUS_FAILURE';

export const ITEM_STATUS_REQUEST = 'ITEM_STATUS_REQUEST';
export const ITEM_STATUS_SUCCESS = 'ITEM_STATUS_SUCCESS';
export const ITEM_STATUS_FAILURE = 'ITEM_STATUS_FAILURE';

export const CANCEL_ORDER_REQUEST = 'CANCEL_ORDER_REQUEST';
export const CANCEL_ORDER_SUCCESS = 'CANCEL_ORDER_SUCCESS';

export const CLEAR_ORDER = 'CLEAR_ORDER';

export const ADD_ORDER_LINE = 'ADD_ORDER_LINE';
export const UPDATE_ORDER_DETAILS = 'UPDATE_ORDER_DETAILS';

export const ADD_ACCOUNT_DETAILS = 'ADD_ACCOUNT_DETAILS';

export const ADD_TO_SHOPPING_CART = 'ADD_TO_SHOPPING_CART';
export const UPDATE_SHOPPING_CART = 'UPDATE_SHOPPING_CART';

export const UPDATE_ORDER_VALIDATION_ERRORS = 'UPDATE_ORDER_VALIDATION_ERRORS';

export const CLEAR_BANK_NAME = 'CLEAR_BANK_NAME';
export const UPDATE_BANK_NAME = 'UPDATE_BANK_NAME';

export const SET_ITEM_SEARCH = 'SET_ITEM_SEARCH';
export const CLEAR_ITEM_SEARCH = 'CLEAR_ITEM_SEARCH';

export const clearOrderAction = () => (dispatch: Dispatch<any>) => {
    dispatch({
        type: CLEAR_ORDER
    });
};

export const cancelOrderAction = (actionToken: string) => (dispatch: Dispatch<any>) => {
    dispatch({
        type: CANCEL_ORDER_REQUEST,
        payload: {
            actionToken
        }
    });

    dispatch({
        type: CANCEL_ORDER_SUCCESS,
        payload: {
            actionToken
        }
    });

    let paymentChannel = store.getState().paymentChannelWebApplication.paymentChannel! as PaymentChannel;
    if (paymentChannel.integrationType == IntegrationType[IntegrationType.Redirect]) dispatch(setRedirectCancelAction());

    dispatch({
        type: MULTI_ITEMS_RESET_REQUEST,
    });
};

export const addOrderLineAction = (orderLine: OrderLine, actionToken: string) => (dispatch: Dispatch<any>) => {
    let paymentChannel = store.getState().paymentChannelWebApplication.paymentChannel! as PaymentChannel;

    dispatch({
        type: ADD_ORDER_LINE,
        payload: {
            orderLine,
            paymentChannel,
            actionToken
        }
    });
};

export const addToShoppingCart = (selectedRow:any,actionToken:string) => (dispatch: Dispatch<any>) =>{
    dispatch({
        type: ADD_TO_SHOPPING_CART,
        payload: {
            selectedRow,
            actionToken
        }
    });
}

export const updateShoppingCart = (updatedList:any,actionToken:string) => (dispatch: Dispatch<any>) => {
    dispatch({
        type: UPDATE_SHOPPING_CART,
        payload: {
            updatedList,
            actionToken
        }
    });
}

export const updateOrderDetailsAction = (order: Order, actionToken: string) => (dispatch: Dispatch<any>) => {
    dispatch({
        type: UPDATE_ORDER_DETAILS,
        payload: {
            order,
            actionToken
        }
    });
};

export const addAccountDetailsAction = (accountDetails:AccountDetails) => (dispatch: Dispatch<any>) => {
    dispatch({
        type: ADD_ACCOUNT_DETAILS,
        payload: {
            accountDetails
        }
    });
}

export const calculateOrderAction = (actionToken: string) => (dispatch: Dispatch<any>) => {
    dispatch({
        type: CALCULATE_ORDER_REQUEST,
        payload: {
            actionToken
        }
    });
    
    let order = store.getState().orderManagement!.order! as Order;
    let paymentChannel = store.getState().paymentChannelWebApplication.paymentChannel! as PaymentChannel;

    let clientMsbId = paymentChannel.department.client.msbId!;
    let departmentMsbId = paymentChannel.department.msbId!;
    let paymentChannelMsbId = paymentChannel.msbId!;

    order.clientMsbId = clientMsbId;
    order.paymentChannelMsbId = paymentChannelMsbId;

    if (order.paymentType === PaymentType.Unknown || order.cardType === PaymentCardTypeEnum.Unknown) {
        let _order = Object.assign({}, order);
        let amount = 0.0;
        _order.orderLines.forEach(orderLine => {
            let _amount = orderLine.unitPrice * orderLine.quantity;
            amount = amount + _amount;
        });

        _order.amount = amount;
        _order.totalAmount = amount;

        dispatch({
            type: CALCULATE_ORDER_SUCCESS,
            payload: {
                order: _order,
                actionToken,
                paymentChannel
            }
        });
    } else {
        let baseApiUrl = store.getState().webAppSettings.baseApiUrl;
        let _url = baseApiUrl + "/MSB_Order/api/v1/ProcessOrder/IncrementFee";
        let bearerToken = store.getState().webAppSettings.bearerToken;

        const config = {
            headers: {
                Authorization: `Bearer ${bearerToken}`,
                ClientId: clientMsbId,
                DepartmentId: departmentMsbId,
                PaymentChannelId: paymentChannelMsbId
            }
        };

        axios.post(_url, order, config)
            .then(function (response) {
                dispatch({
                    type: CALCULATE_ORDER_SUCCESS,
                    payload: {
                        order: response.data.requestObject,
                        actionToken,
                        paymentChannel
                    }
                });
            })
            .catch(function (error) {
                let errorMessage = 'We could not calculate the fee to be assessed. Please select a payment type and re-enter the payment details.';
                if (error.response) {
                    errorMessage = error.response.data.message;
                }
                dispatch({
                    type: CALCULATE_ORDER_FAILURE,
                    payload: {
                        error: errorMessage,
                        actionToken
                    }
                });
            });
    }
};

export const updateOrderValidationErrors = (validationErrors: any,  actionToken?: string) => (dispatch: Dispatch<any>) => {
    dispatch({
        type: UPDATE_ORDER_VALIDATION_ERRORS,
        payload: {
            validationErrors,
            actionToken
        }
    });
}

export const processOrderAction = (order: Order, actionToken?: string, reCaptchaToken?: string) => async (dispatch: Dispatch<any>) => {
    dispatch({
        type: PROCESS_ORDER_REQUEST,
        payload: {
            actionToken
        }
    });

    let paymentChannel = store.getState().paymentChannelWebApplication.paymentChannel! as PaymentChannel;

    let clientMsbId = paymentChannel.department.client.msbId!;
    let departmentMsbId = paymentChannel.department.msbId!;
    let paymentChannelMsbId = paymentChannel.msbId!;

    let baseApiUrl = store.getState().webAppSettings.baseApiUrl;
    let _url = `${baseApiUrl}/MSB_Order/api/v1/ProcessOrder/${order.isCardPresent ? "ProcessOrder" : "PublicProcessOrder"}`;
    let bearerToken = store.getState().webAppSettings.bearerToken;

    //let remoteIpAddress: any = await axios.get("https://get-ip-only.herokuapp.com");

    const config = {
        headers: {
            Authorization: `Bearer ${bearerToken}`,
            ClientId: clientMsbId,
            DepartmentId: departmentMsbId,
            PaymentChannelId: paymentChannelMsbId,
            ReCaptchaToken: reCaptchaToken,
            //ReCaptchaRemoteIp: remoteIpAddress?.data.ip
        }
    };

    axios.post(_url, order, config)
        .then(function (response) {
            const stCharlesStatusCall = order?.orderLines?.some((line:any) => line?.itemMetadata && line?.itemMetadata?.isStCharles === true);
            const pikeStatusCall = order?.orderLines?.some((line:any) => line?.itemMetadata && line?.itemMetadata?.isPike === true);
            const statusUpdateCall = order?.orderLines?.some((line:any) => line?.itemMetadata && line?.itemMetadata?.statusUpdate === true);
            dispatch({
                type: PROCESS_ORDER_SUCCESS,
                payload: {
                    processedOrder: response.data,
                    stCharlesStatusCall: stCharlesStatusCall,
                    pikeStatusCall: pikeStatusCall,
                    statusUpdateCall: statusUpdateCall,
                    actionToken
                }
            });
        })
        .catch(function (error) {
            let errorMessage = '';
            if (error.response) {
                errorMessage = error.response.data?.processorData?._processor?.processorLogs.length > 0 ? error.response.data?.processorData?._processor?.processorLogs[0] : error.response.data.processorMessage;
            }
            dispatch({
                type: PROCESS_ORDER_FAILURE,
                payload: {
                    error: errorMessage,
                    actionToken
                }
            });
        });
    
};

export const clearBankName = () => (dispatch: Dispatch<any>) => {
    dispatch({
        type: CLEAR_BANK_NAME,
        payload: {
            bankName: ""
        }
    });
};

export const getBankName = (routingNumber: string) => (dispatch: Dispatch<any>) => {
    let bearerToken = store.getState().webAppSettings.bearerToken;
    const config = {
        headers: { Authorization: `Bearer ${bearerToken}` }
    };
    axios
        .get(`${store.getState().webAppSettings.baseApiUrl}/MSB_ECheck/api/v1/ECheckRoutingNumber/${routingNumber}`, config)
        .then(response => {
            if (response.status === 200 && response.data.routingNumber === routingNumber) {
                dispatch({
                    type: UPDATE_BANK_NAME,
                    payload: {
                        bankName: response.data.custName
                    }
                });
            } else {
                dispatch({
                    type: CLEAR_BANK_NAME,
                    payload: {
                        bankName: ""
                    }
                });
            }
        })
        .catch(function (error) {
            dispatch({
                type: CLEAR_BANK_NAME,
                payload: {
                    bankName: ""
                }
            });
        });
};

export const payPalResponseAction = (response: any, order: Order, actionToken: string) => async (dispatch: Dispatch<any>) => {
    dispatch({
        type: PROCESS_ORDER_REQUEST,
        payload: {
            actionToken
        }
    });

    let paymentChannel = store.getState().paymentChannelWebApplication.paymentChannel! as PaymentChannel;

    let clientMsbId = paymentChannel.department.client.msbId!;
    let departmentMsbId = paymentChannel.department.msbId!;
    let paymentChannelMsbId = paymentChannel.msbId!;

    let baseApiUrl = store.getState().webAppSettings.baseApiUrl;
    let url = baseApiUrl + "/MSB_Order/api/v1/ProcessOrder/payPalResponse";
    let bearerToken = store.getState().webAppSettings.bearerToken;

    const config = {
        headers: {
            Authorization: `Bearer ${bearerToken}`,
            ClientId: clientMsbId,
            DepartmentId: departmentMsbId,
            PaymentChannelId: paymentChannelMsbId,
        }
    };

    const getProcessedOrder = (summary: any) => {
        let processedOrder = {
            processorData: {
                address: {
                    addressLine1: order.addressLine1,
                    addressLine2: order.addressLine2,
                    country: "US",
                    city: order.city,
                    state: order.state,
                    zip: order.zip,
                },
                receipt: {
                    accountNumbers: "",
                    amount: summary.processorData.orderTransaction.amount.toFixed(2),
                    approvalNumber: "",
                    cardLogo: "",
                    convenienceFee: summary.processorData.orderTransaction.convenienceFee.toFixed(2),
                    createdAt: summary.processorData.orderTransaction.createdAt,
                    hostResponseCode: "",
                    merchantIdentifier: "",
                    nameOnCard: summary.processorData.orderTransaction.nameOnCard,
                    pinVerified: false,
                    terminalIdentifier: "",
                    totalAmount: summary.processorData.orderTransaction.totalAmount.toFixed(2),
                    transactionIdentifier: summary.processorData.orderTransaction.transactionIdentifier,
                    orderId: summary.processorData.orderIdentifier,
                    lastFourOnCard: "",
                    paymentType: "PayPal" as unknown as PaymentType,
                    departmentId: summary.processorData.departmentId!,
                    paymentChannelId: summary.processorData.paymentChannelId!,
                    accountNumberLastFour: "",
                    processorType: "PayPal",
                    orderTransaction: {
                        email: summary.processorData.orderTransaction.email,
                        lastFourOnCard: "",
                        nameOnCard: summary.processorData.orderTransaction.nameOnCard,
                        paymentType: "PayPal",
                        phone: summary.processorData.orderTransaction.phone,
                        transactionType: "Sale",
                        vantivTransactionReferenceNumber: "",
                        zip: summary.processorData.orderTransaction.zip,
                        transactionIdentifier: summary.processorData.orderTransaction.transactionIdentifier,
                        orderIdentifier: summary.processorData.orderTransaction.orderIdentifier,
                        originalAmount: summary.processorData.orderTransaction.originalAmount,
                        remainingBalance: summary.processorData.orderTransaction.remainingBalance,
                        orderSummaryId: summary.processorData.orderTransaction.orderSummaryId,
                        orderStatus: summary.processorData.orderTransaction.orderStatus,
                        chargeBackStatus: summary.processorData.orderTransaction.chargeBackStatus,
                        amount: summary.processorData.orderTransaction.amount,
                        totalAmount: summary.processorData.orderTransaction.totalAmount,
                        convenienceFee: summary.processorData.orderTransaction.convenienceFee,
                        convenienceFeeIsClientAbsorbed: summary.processorData.orderTransaction.convenienceFeeIsClientAbsorbed,
                        orderNotes: "",
                        initiatedBy: summary.processorData.orderTransaction.initiatedBy,
                        addressLine1: summary.processorData.orderTransaction.addressLine1,
                        addressLine2: summary.processorData.orderTransaction.addressLine2,
                        city: summary.processorData.orderTransaction.city,
                        state: summary.processorData.orderTransaction.state,
                        clientMetadata: summary.processorData.orderTransaction.clientMetadata,
                        processorMessage: summary.processorData.orderTransaction.processorMessage,
                        reason: "",
                        orderLines: order.orderLines,
                        isNotChargeBackTransaction: false,
                        isChargeBackTransaction: false,
                        comments: summary.processorData.orderTransaction.comments,
                        files: summary.processorData.orderTransaction.files,
                        chargeBackDueDate: ""
                    }
                }
            }
        }
        return processedOrder;
    }

   /*
    const refundOrder = async () => {
        const url = `${window.location.protocol}//${window.location.host}/PayPal/RefundOrder`;
        let retry = 3;
        while (retry > 0) {
            try {
                const rsp = await fetch(url, {
                    method: 'POST',
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify({
                        id: response?.purchase_units[0]?.payments?.captures[0]?.id,
                        merchantId: response?.purchase_units[0]?.payee?.merchant_id
                    })
                });
                return await rsp.json();
            } catch (error) {
                await new Promise((resolve) => setTimeout(resolve, 5000));
            } finally {
                retry -= 1;
            }
        }
        return { status: "FAILED" };
    }
    */

    const alert = async () => {
        const url = `${window.location.protocol}//${window.location.host}/PayPal/Alert`;
        let retry = 3;
        while (retry > 0) {
            try {
                const rsp = await fetch(url, {
                    method: 'POST',
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify({
                        response: response
                    })
                });
                return await rsp.json();
            } catch (error) {
                await new Promise((resolve) => setTimeout(resolve, 3000));
            } finally {
                retry -= 1;
            }
        }
        return { status: "FAILED" };
    }

    const temporaryAxios = axios.create()

    axiosRetry(temporaryAxios, {
        retries: 3,
        retryDelay: (retryCount) => retryCount * 5000,
        retryCondition: (error) => axiosRetry.isNetworkError(error) || (error?.response?.status !== 200)
    });

    temporaryAxios.post(url, response, config)
        .then(function (response) {
            const stCharlesStatusCall = order?.orderLines?.some((line: any) => line?.itemMetadata && line?.itemMetadata?.isStCharles === true) || false;
            const pikeStatusCall = order?.orderLines?.some((line: any) => line?.itemMetadata && line?.itemMetadata?.isPike === true) || false;
            const statusUpdateCall = order?.orderLines?.some((line: any) => line?.itemMetadata && line?.itemMetadata?.statusUpdate === true) || false;
            dispatch({
                type: PROCESS_ORDER_SUCCESS,
                payload: {
                    processedOrder: getProcessedOrder(response.data),
                    stCharlesStatusCall: stCharlesStatusCall,
                    pikeStatusCall: pikeStatusCall,
                    statusUpdateCall: statusUpdateCall,
                    actionToken
                }
            });
        })
        .catch(async function (error) {
            let errorMessage = "We encountered an issue while processing your payment. We've promptly reversed any charges on your PayPal account. Please retry your payment at your convenience.";
            //let result = await refundOrder();
            let result = await alert();
            if (result?.status !== "COMPLETED") {
                errorMessage = "We encountered an issue while processing your payment. Although your PayPal account was charged, please contact your provider to ensure your payment is reconciled. Do not make another payment.";
            }
            dispatch({
                type: PROCESS_ORDER_FAILURE,
                payload: {
                    error: errorMessage,
                    actionToken
                }
            });
        });
};

export const updateStCharlesStatus = (revObjIds: any, orderTransactionId?:any, actionToken?: string) => async (dispatch: Dispatch<any>) => {
    dispatch({
        type: STCHARLES_STATUS_REQUEST,
        payload: {
            actionToken
        }
    });

    let paymentChannel = store.getState().paymentChannelWebApplication.paymentChannel! as PaymentChannel;

    let clientMsbId = paymentChannel.department.client.msbId!;
    let departmentMsbId = paymentChannel.department.msbId!;
    let paymentChannelMsbId = paymentChannel.msbId!;

    let baseApiUrl = store.getState().webAppSettings.baseApiUrl;
    const queryStringRevObjIds = revObjIds?.map((id:any) => `revObjectIds=${id}`).join('&');
    let _url = baseApiUrl + `/MSB_Item/api/v1/SaintCharlesDataManagement/paymentsProcessed?${queryStringRevObjIds}&paymentStatus=1`;
    let bearerToken = store.getState().webAppSettings.bearerToken;

    const config = {
        headers: {
            Authorization: `Bearer ${bearerToken}`,
            ClientId: clientMsbId,
            DepartmentId: departmentMsbId,
            PaymentChannelId: paymentChannelMsbId,
            
        }
    };

    axios.put(_url,revObjIds,config)
        .then(function (response) {
            dispatch({
                type: STCHARLES_STATUS_SUCCESS,
                payload: {
                    actionToken
                }
            });
        })
        .catch(function (error) {
            dispatch({
                type: STCHARLES_STATUS_SUCCESS,
                payload: {
                    error: error,
                    actionToken
                }
            });
        });
    
};

export const updateItemStatus = (itemIds: any, orderLines:any, orderTransactionId?:any, actionToken?: string) => async (dispatch: Dispatch<any>) => {
    dispatch({
        type: ITEM_STATUS_REQUEST,
        payload: {
            actionToken
        }
    });

    let paymentChannel = store.getState().paymentChannelWebApplication.paymentChannel! as PaymentChannel;

    let clientMsbId = paymentChannel.department.client.msbId!;
    let departmentMsbId = paymentChannel.department.msbId!;
    let paymentChannelMsbId = paymentChannel.msbId!;

    let baseApiUrl = store.getState().webAppSettings.baseApiUrl;
    const queryStringItemIds = itemIds?.map((id:any) => `itemIds=${id}`).join('&');
    let _url = baseApiUrl + `/MSB_Item/api/v1/Items/updateStatuses?${queryStringItemIds}`;
    let bearerToken = store.getState().webAppSettings.bearerToken;

    const config = {
        headers: {
            Authorization: `Bearer ${bearerToken}`,
            ClientId: clientMsbId,
            DepartmentId: departmentMsbId,
            PaymentChannelId: paymentChannelMsbId,
            
        }
    };

    const requestBody = {
        orderTransactionId: orderTransactionId,
        itemPaymentStatus: 1,
        observesWeekends: orderLines[0]?.itemMetadata?.observesWeekends,
        durationOfPendingHours: orderLines[0]?.itemMetadata?.durationOfPendingHours,
    }

    axios.post(_url,requestBody,config)
        .then(function (response) {
            dispatch({
                type: ITEM_STATUS_SUCCESS,
                payload: {
                    actionToken
                }
            });
        })
        .catch(function (error) {
            dispatch({
                type: ITEM_STATUS_FAILURE,
                payload: {
                    error: error,
                    actionToken
                }
            });
        });
    
};


export const simpleCalculateOrderAction = (actionToken: string) => (dispatch: Dispatch<any>) => {
    dispatch({
        type: CALCULATE_ORDER_REQUEST,
        payload: {
            actionToken
        }
    });

    let order = store.getState().orderManagement!.order! as Order;
    let baseApiUrl = store.getState().webAppSettings.baseApiUrl;
    let _url = baseApiUrl + "/MSB_Order/api/v1/ProcessOrder/IncrementFee";
    let bearerToken = store.getState().webAppSettings.bearerToken;

    const config = {
        headers: {
            Authorization: `Bearer ${bearerToken}`,
            ClientId: order.clientMsbId,
            DepartmentId: order.departmentMsbId,
            PaymentChannelId: order.paymentChannelMsbId
        }
    };

    axios.post(_url, order, config)
        .then(function (response) {
            const calculation = response.data.requestObject as Order;
            dispatch({
                type: CALCULATE_ORDER_SUCCESS,
                payload: {
                    order: { ...order, convenienceFee: calculation.convenienceFee, totalAmount: calculation.totalAmount },
                    actionToken: actionToken,
                    paymentChannel: { msbId: order.paymentChannelMsbId, name: "", departmentMSBId: order.departmentMsbId }
                }
            });
        })
        .catch(function (error) {
            let errorMessage = 'We could not calculate the fee to be assessed. Please select a payment type and re-enter the payment details.';
            if (error.response) {
                errorMessage = error.response.data.message;
            }
            dispatch({
                type: CALCULATE_ORDER_FAILURE,
                payload: {
                    error: errorMessage,
                    actionToken
                }
            });
        });
};

export const simpleProcessOrderAction = (order: Order, actionToken?: string, reCaptchaToken?: string, authorize?: boolean) => async (dispatch: Dispatch<any>) => {
    dispatch({
        type: PROCESS_ORDER_REQUEST,
        payload: {
            actionToken
        }
    });

    let baseApiUrl = store.getState().webAppSettings.baseApiUrl;
    let _url = `${baseApiUrl}/MSB_Order/api/v1/ProcessOrder/${authorize && order.paymentType === PaymentType.CreditCard && !order.isCardPresent ? "AuthorizationTransaction" : order.isCardPresent ? "ProcessOrder" : "PublicProcessOrder"
}`;
    let bearerToken = store.getState().webAppSettings.bearerToken;

    const config = {
        headers: {
            Authorization: `Bearer ${bearerToken}`,
            ClientId: order.clientMsbId,
            DepartmentId: order.departmentMsbId,
            PaymentChannelId: order.paymentChannelMsbId,
            ReCaptchaToken: reCaptchaToken,
        }
    };

    axios.post(_url, order, config)
        .then(function (response) {
            dispatch({
                type: PROCESS_ORDER_SUCCESS,
                payload: {
                    processedOrder: response.data,
                    actionToken: actionToken
                }
            });
        })
        .catch(function (error) {
            let errorMessage = '';
            if (error.response) {
                errorMessage = error.response.data.processorMessage || "UNKNOWN ERROR";
            }
            dispatch({
                type: PROCESS_ORDER_FAILURE,
                payload: {
                    error: errorMessage,
                    actionToken
                }
            });
        });
};


export const setItemSearch= (itemSearchDetails: any, actionToken: string) => (dispatch: Dispatch<any>) => {
    dispatch({
        type: SET_ITEM_SEARCH,
        payload: {
            itemSearchDetails: itemSearchDetails,
            actionToken
        }
    });
};

export const clearItemSearch = (actionToken?: string) => (dispatch: Dispatch<any>) => {
    dispatch({
        type: CLEAR_ITEM_SEARCH,
        payload: {
            actionToken
        }
    });
};