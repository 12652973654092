import { CellPlugin } from '@react-page/editor';
import StCharlesSearchResults, {
    OWNER_LABEL, TAX_YEAR_LABEL, BILL_NUMBER_LABEL, TAX_TYPE_LABEL, ADDRESS_LABEL, STATUS_LABEL, PAID_DATE_LABEL, STATUS_FILTER_LABEL, TAX_TYPE_FILTER_LABEL, TAX_YEAR_FILTER_LABEL,
    ACTIONS_LABEL, HEADER_BACKGROUND_COLOR, HEADER_PADDING, MARGIN, PADDING, CHECK_BOX_DISCLAIMER, ADDRESS_CHANGE_DISCLAIMER, FIRST_NAME_LABEL, LAST_NAME_LABEL, EMAIL_LABEL, PHONE_NUMBER_LABEL, NEW_ADDRESS_LABEL, CITY_LABEL, STATE_LABEL, ZIP_CODE_LABEL, FIRST_NAME_REQUIRED, LAST_NAME_REQUIRED, EMAIL_REQUIRED, PHONE_NUMBER_REQUIRED, NEW_ADDRESS_REQUIRED, CITY_REQUIRED, 
    STATE_REQUIRED, ZIP_CODE_REQUIRED, CHECK_BOX_DISCLAIMER_REQUIRED, DISABLE_CART, DELINQUENT_LABEL, DELINQUENT_BACKGROUND_COLOR, DELINQUENT_TEXT_COLOR, DELINQUENT_TEXT_ALIGN  
} from './components/StCharlesSearchResults';

type Data = {
    title: string,
    owner: string,
    taxYear: string,
    billNumber: string,
    address: string,
    taxType: string,
    status: string,
    paidDate: string,
    statusFilter: string,
    taxTypeFilter: string,
    taxYearFilter: string,
    headerBackgroundColor: string,
    actions: string,
    disableCart: boolean,
    disableCartDateRanges: string,
    delinquentLabel: string,
    delinquentBackgroundColor: string,
    delinquentTextColor: string,
    delinquentTextAlign: string,
    firstName: string,
    lastName: string,
    email: string,
    phoneNumber: string,
    newAddress: string,
    city: string,
    state: string,
    zipCode: string,
    checkboxDisclaimer: string,
    addressChangeDisclaimer: string,
    firstNameRequired: boolean,
    lastNameRequired: boolean,
    emailRequired: boolean,
    phoneNumberRequired: boolean,
    newAddressRequired: boolean,
    cityRequired: boolean,
    stateRequired: boolean,
    zipCodeRequired: boolean,
    checkboxDisclaimerRequired: boolean, 
    headerPadding: string,
    margin: string,
    padding: string,
}

const StCharlesSearchResultsPlugin: CellPlugin<Data> = {
    Renderer: ({ data }) => (
        <StCharlesSearchResults
            ownerLabel={data.owner}
            taxYearLabel={data.taxYear}
            billNumberLabel={data.billNumber}
            addressLabel={data.address}
            taxTypeLabel={data.taxType}
            statusLabel={data.status}
            paidDateLabel={data.paidDate}
            statusFilterLabel={data.statusFilter}
            taxTypeFilterLabel={data.taxTypeFilter}
            taxYearFilterLabel={data.taxYearFilter}
            actionsLabel={data.actions}
            disableCart={data.disableCart}
            delinquentLabel={data.delinquentLabel}
            delinquentBackgroundColor={data.delinquentBackgroundColor}
            delinquentTextColor={data.delinquentTextColor}
            delinquentTextAlign={data.delinquentTextAlign}
            disableCartDateRanges={data.disableCartDateRanges}
            firstNameLabel={data.firstName}
            lastNameLabel={data.lastName}
            emailLabel={data.email}
            phoneNumberLabel={data.phoneNumber}
            newAddressLabel={data.newAddress}
            cityLabel={data.city}
            stateLabel={data.state}
            zipLabel={data.zipCode}
            checkboxDisclaimer={data.checkboxDisclaimer}
            addressChangeDisclaimer={data.addressChangeDisclaimer}
            firstNameRequired={data.firstNameRequired}
            lastNameRequired={data.lastNameRequired}
            emailRequired={data.emailRequired}
            phoneNumberRequired={data.phoneNumberRequired}
            newAddressRequired={data.newAddressRequired}
            cityRequired={data.cityRequired}
            stateRequired={data.stateRequired}
            zipCodeRequired={data.zipCodeRequired}
            checkboxDisclaimerRequired={data.checkboxDisclaimerRequired}
            margin={data.margin}
            padding={data.padding}
            headerBackgroundColor={data.headerBackgroundColor}
            headerPadding={data.headerPadding}
        />
    ),
    id: 'stCharlesResultsPlugin',
    title: 'St Charles Search Results',
    description: 'St Charles table results',
    version: 1,
    controls: [
        {
            title: 'Labels',
            controls: {
                type: 'autoform',
                schema: {
                    properties: {
                        owner: {
                            type: 'string',
                            default: OWNER_LABEL,
                        },
                        taxYear: {
                            type: 'string',
                            default: TAX_YEAR_LABEL,
                        },
                        billNumber: {
                            type: 'string',
                            default: BILL_NUMBER_LABEL,
                        },
                        address: {
                            type: 'string',
                            default: ADDRESS_LABEL,
                        },
                        taxType: {
                            type: 'string',
                            default: TAX_TYPE_LABEL,
                        },
                        status:{
                            type: 'string',
                            default: STATUS_LABEL, 
                        },
                        paidDate: {
                            type: 'string',
                            default: PAID_DATE_LABEL,
                        },
                        actions: {
                            type: 'string',
                            default: ACTIONS_LABEL,
                        }
                    },
                    required: [],
                },
            },
        },{
            title: 'ModalSettings',
            controls: {
                type: 'autoform',
                schema: {
                    properties: {
                        delinquentLabel: {
                            type: 'string',
                            default: DELINQUENT_LABEL,
                        },
                        delinquentBackgroundColor: {
                            type: 'string',
                            default: DELINQUENT_BACKGROUND_COLOR,
                        },
                        delinquentTextColor: {
                            type: 'string',
                            default: DELINQUENT_TEXT_COLOR,
                        },
                        delinquentTextAlign:{
                            type: 'string',
                            default: DELINQUENT_TEXT_ALIGN
                        }
                    },
                    required: [],
                },
            },
        },{
            title: 'Filters',
            controls: {
                type: 'autoform',
                schema: {
                    properties: {
                        statusFilter: {
                            type: 'string',
                            default: STATUS_FILTER_LABEL,
                        },
                        taxTypeFilter: {
                            type: 'string',
                            default: TAX_TYPE_FILTER_LABEL,
                        },
                        taxYearFilter: {
                            type: 'string',
                            default: TAX_YEAR_FILTER_LABEL,
                        }
                    },
                    required: [],
                },
            },
        },{
            title: 'Address Change Form',
            controls: {
                type: 'autoform',
                schema: {
                    properties: {
                        firstName: {
                            type: 'string',
                            default: FIRST_NAME_LABEL,
                        },
                        lastName: {
                            type: 'string',
                            default: LAST_NAME_LABEL,
                        },
                        email: {
                            type: 'string',
                            default: EMAIL_LABEL,
                        },
                        phoneNumber: {
                            type: 'string',
                            default: PHONE_NUMBER_LABEL,
                        },
                        newAddress: {
                            type: 'string',
                            default: NEW_ADDRESS_LABEL,
                        },
                        city:{
                            type: 'string',
                            default: CITY_LABEL, 
                        },
                        state: {
                            type: 'string',
                            default: STATE_LABEL,
                        },
                        zipCode: {
                            type: 'string',
                            default: ZIP_CODE_LABEL,
                        },
                        checkboxDisclaimer:{
                            type: 'string',
                            default: CHECK_BOX_DISCLAIMER,
                        },
                        addressChangeDisclaimer:{
                            type: 'string',
                            default: ADDRESS_CHANGE_DISCLAIMER,
                        }
                    },
                    required: [],
                },
            },
        },{
            title: 'Address Change Required Fields/Buttons',
            controls: {
                type: 'autoform',
                schema: {
                    properties: {
                        firstNameRequired: {
                            type: 'boolean',
                            default: FIRST_NAME_REQUIRED,
                        },
                        lastNameRequired: {
                            type: 'boolean',
                            default: LAST_NAME_REQUIRED,
                        },
                        emailRequired: {
                            type: 'boolean',
                            default: EMAIL_REQUIRED,
                        },
                        phoneNumberRequired: {
                            type: 'boolean',
                            default: PHONE_NUMBER_REQUIRED,
                        },
                        newAddressRequired: {
                            type: 'boolean',
                            default: NEW_ADDRESS_REQUIRED,
                        },
                        cityRequired: {
                            type: 'boolean',
                            default: CITY_REQUIRED,
                        },
                        stateRequired: {
                            type: 'boolean',
                            default: STATE_REQUIRED,
                        },
                        zipCodeRequired: {
                            type: 'boolean',
                            default: ZIP_CODE_REQUIRED,
                        },
                        checkboxDisclaimerRequired:{
                            type: 'boolean',
                            default: CHECK_BOX_DISCLAIMER_REQUIRED,
                        }
                    },
                    required: [],
                },
            },
        },{
            title: 'Disable Cart Directly OR With Data Ranges as mm/dd/yyyy<->mm/dd/yyyy in CT Timezone',
            controls: {
                type: 'autoform',
                schema: {
                    properties: {
                        disableCart: {
                            type: 'boolean',
                            default: DISABLE_CART,
                        },
                        disableCartDateRanges: {
                            type: 'string',
                        }
                    },
                    required: [],
                },
            },  
        },{
            title: 'Styles',
            controls: {
                type: 'autoform',
                schema: {
                    properties: {
                        margin: {
                            type: 'string',
                            default: MARGIN,
                        },
                        padding: {
                            type: 'string',
                            default: PADDING,
                        },
                        headerBackgroundColor: {
                            type: 'string',
                            default: HEADER_BACKGROUND_COLOR,
                        },
                        headerPadding: {
                            type: 'string',
                            default: HEADER_PADDING,
                        }
                    },
                    required: [],
                },
            },
        },
    ],
};

export default StCharlesSearchResultsPlugin;