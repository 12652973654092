import { CellPlugin } from '@react-page/editor';
import ModalDetail, {
    MARGIN, PADDING, BUTTON_BACKGROUND_COLOR, BUTTON_FONT_COLOR, BUTTON_FONT_SIZE, BUTTON_FONT_STYLE,
    BUTTON_FONT_WEIGHT, BUTTON_BORDER_COLOR, BUTTON_ICON_COLOR, TITLE, SUB_TITLE, TITLE_COLOR, SUB_HEADING_COLOR, INFO_LABEL
} from './components/ModalDetail';

type Data = {
    title: string,
    subTitle: string,
    itemLists: Field[],
    margin: string,
    padding: string,
    buttonBackgroundColor: string,
    buttonFontColor: string,
    buttonFontSize: string,
    buttonFontStyle: string,
    buttonFontWeight: string,
    buttonBorderColor: string,
    buttonIconColor: string,
    titleColor: string,
    subHeadingColor: string,
    infoLabel: string
}

export type Field = {
    key: string;
    value?: string;
  };

const ModalDetailPlugin: CellPlugin<Data> = {
    Renderer: ({ data }) => (
        <ModalDetail
            infoLabel={data.infoLabel}
            title={data.title}
            subTitle={data.subTitle}
            itemLists={data.itemLists}
            margin={data.margin}
            padding={data.padding}
            buttonBackgroundColor={data.buttonBackgroundColor}
            buttonFontColor={data.buttonFontColor}
            buttonFontSize={data.buttonFontSize}
            buttonFontStyle={data.buttonFontStyle}
            buttonFontWeight={data.buttonFontWeight}
            buttonBorderColor={data.buttonBorderColor}
            buttonIconColor={data.buttonIconColor}
            titleColor={data.titleColor}
            subHeadingColor={data.subHeadingColor}
        />
    ),
    id: 'modalDetailPlugin',
    title: 'Modal Detail',
    description: 'Static Modal Details',
    version: 1,
    controls: [
        {
            title: 'Labels',
            controls: {
                type: 'autoform',
                schema: {
                    properties: {
                        title: {
                            type: 'string',
                            default: TITLE,
                        },
                        subTitle: {
                            type: 'string',
                            default: SUB_TITLE
                        },
                        infoLabel: {
                            type: 'string',
                            default: INFO_LABEL
                        }
                    },
                    required: [],
                },
            },
        },{
            title: 'Item Details Separated by //',
            controls:{
                type:'autoform',
                schema:{
                    properties:{
                        itemLists: {
                            type: 'array',
                            items: {
                                type: 'object',
                                properties: {
                                    key: {
                                        type: 'string',
                                      },
                                      value: {
                                        type: 'string',
                                      },
                                }
                            },
                        },
                    }
                }
            }
        },
        {
            title: 'Styles',
            controls: {
                type: 'autoform',
                schema: {
                    properties: {
                        margin: {
                            type: 'string',
                            default: MARGIN,
                        },
                        padding: {
                            type: 'string',
                            default: PADDING,
                        },
                        buttonBackgroundColor: {
                            type: 'string',
                            default: BUTTON_BACKGROUND_COLOR,
                        },
                        buttonFontColor: {
                            type: 'string',
                            default: BUTTON_FONT_COLOR,
                        },
                        buttonFontSize: {
                            type: 'string',
                            default: BUTTON_FONT_SIZE,
                        },
                        buttonFontStyle: {
                            type: 'string',
                            default: BUTTON_FONT_STYLE,
                        },
                        buttonFontWeight: {
                            type: 'string',
                            default: BUTTON_FONT_WEIGHT,
                        },
                        buttonBorderColor: {
                            type: 'string',
                            default: BUTTON_BORDER_COLOR,
                        },
                        buttonIconColor: {
                            type: 'string',
                            default: BUTTON_ICON_COLOR,
                        },
                        titleColor: {
                            type: 'string',
                            default: TITLE_COLOR,  
                        },
                        subHeadingColor: {
                            type: 'string',
                            default: SUB_HEADING_COLOR,  
                        }
                    },
                    required: [],
                },
            },
        },
    ],
};

export default ModalDetailPlugin;