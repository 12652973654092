import React from "react";
import { Breadcrumb } from 'react-bootstrap';

interface BreadcrumbProps {
    name: any;
}

export default (props: BreadcrumbProps) => {
    let parts: string[] = props.name.split("/");
    let name = parts[parts.length - 1];

    return (
        <Breadcrumb className="d-none d-md-inline-block" listProps={{ className: "breadcrumb-dark breadcrumb-transparent" }}>
            {parts.map((part, index) => (
                <Breadcrumb.Item active key={index}>{part}</Breadcrumb.Item>
            ))}
        </Breadcrumb>
    );
};
