import React, { useState } from "react";
import { Container, Row, Col, Table as ReactBsTable, Button } from 'react-bootstrap';
import PageSectionContainer from '../../components/layout/PageSectionContainer';
import Table, { CellDateFormatter } from '../../components/Table';
import { connect } from "react-redux";
import { Link, Redirect } from 'react-router-dom';
import { IAppState } from "../../redux/storeTypes";
import PageHeader from '../../components/layout/PageHeader';
import Breadcrumb from '../../components/layout/Breadcrumb';
import OpenOrders from './OpenOrders';
import { Routes } from '../../routes';
import PageFooter from "../../components/layout/PageFooter";

export interface IAccountLanding {
}

const mockData: TransactionHistory[] = [
  {
    paymentDate: '4/20/21',
    paymentReference: 'Invoice 031082',
    amount: 100,
    actions: 'View PDF'
  },
  {
    paymentDate: '3/10/21',
    paymentReference: 'Invoice 060113',
    amount: 120,
    actions: 'View PDF'
  },
  {
    paymentDate: '2/3/21',
    paymentReference: 'Invoice 3',
    amount: 100,
    actions: 'View PDF'
  },
  {
    paymentDate: '1/12/21',
    paymentReference: 'Invoice 4',
    amount: 120,
    actions: 'View PDF'
  },
  {
    paymentDate: '12/5/20',
    paymentReference: 'Invoice 5',
    amount: 100,
    actions: 'View PDF'
  }
]

class TransactionHistory {
  paymentDate: string = '';
  paymentReference: string = '';
  amount: number = 0;
  actions: string = '';
}


const AccountLanding = ({ }: IAccountLanding) => {
  const setRoute = (path:string) => {
    return path.replace(":id", "Demo");
  }
  const [redirect, setRedirect] = useState('');
  let data: Array<any> = mockData;
  const columns = [{
    dataField: 'paymentDate',
    text: 'Payment date',
    formatter: CellDateFormatter,
    sort: true,
    editable: false
  }, {
    dataField: 'paymentReference',
    text: 'Payment reference',
    sort: true,
    editable: false
  }, {
    dataField: 'amount',
    text: 'Amount',
    sort: true,
    editable: false
  }, {
    dataField: 'actions',
    text: 'Actions',
    editable: false
  }
  ];

  if (redirect !== "") {
    return (<Redirect push to={redirect} />)
  } else {
    return (
      <>
        <PageHeader />

        <Container className="mt-4">
          <Row>
            <Col>
              <Breadcrumb name='Home' />
              <PageSectionContainer>
                <span style={{ fontWeight: "bold" }}>Welcome John Doe</span>
                <span style={{ float: "right" }}>Account Number: 123123</span>
              </PageSectionContainer>
            </Col>
          </Row>
          <Row>
            <Col lg={8} md={8} sm={12}>
              <OpenOrders />
              <PageSectionContainer>
                <div className="auto-pay">
                  <div className="ap-due">
                    <h2 className="ap-day">Tues</h2>
                    <span className="ap-date">Jun 03</span>
                  </div>
                  <div className="ap-payment">
                    <h2 className="ap-label">Automatic payment</h2>
                    <span className="ap-amount">$245.12 Mastercard ending in 1234</span>
                  </div>
                  <div className="ap-edit">
                    <Link to={setRoute(Routes.PaymentSettingsStatic.path)} className="brand-link-primary">Edit</Link>
                  </div>
                </div>
              </PageSectionContainer>
              <PageSectionContainer title='Transaction history' headerTag='h2' showHeaderBorder={true}>
                  <Table
                    keyField='name'
                    remote={false}
                    data={data}
                    options={{
                      alwaysShowAllBtns: true,
                      withFirstAndLast: false,
                      hideSizePerPage: false,
                      firstPageText: 'First',
                      prePageText: 'Previous',
                      nextPageText: 'Next',
                      lastPageText: 'Last',
                      nextPageTitle: 'Next page',
                      prePageTitle: 'Previous page',
                      firstPageTitle: 'First page',
                      lastPageTitle: 'Last page',
                      showTotal: true,
                      // hidePageListOnlyOnePage: true,
                    }}
                    columns={columns}
                  />
              </PageSectionContainer>
            </Col>
            <Col lg={4} md={4} sm={12}>
              <PageSectionContainer>
                <ReactBsTable responsive className="mb-4">
                  <tbody>
                    <tr>
                      <td>Previous Balance</td>
                      <td>$10</td>
                    </tr>
                    <tr>
                      <td>Credit card payment on June 2, 2021</td>
                      <td>$44</td>
                    </tr>
                    <tr>
                      <td>Balance forward</td>
                      <td>$44</td>
                    </tr>
                    <tr>
                      <td>Account credits</td>
                      <td>$44</td>
                    </tr>
                    <tr>
                      <td>New charges due June 1, 2021</td>
                      <td>$44</td>
                    </tr>
                    <tr>
                      <td>Adjusted total</td>
                      <td>$44</td>
                    </tr>
                    <tr>
                      <td>New items</td>
                      <td>$44</td>
                    </tr>
                    <tr>
                      <td>Subtotal</td>
                      <td>$44</td>
                    </tr>
                  </tbody>
                </ReactBsTable>

                <Button className="brand-btn-primary w-100" onClick={() => { setRedirect(setRoute(Routes.CheckoutStatic.path)) }}>Proceed to checkout</Button>
                <p style={{ marginTop: "12px", marginBottom: "0", textAlign: "center", fontSize: "12px", color: "#8C8C8C"}}>Any applicable fees will be added during checkout.</p>
              </PageSectionContainer>
            </Col>
          </Row>
        </Container>

        <PageFooter />
      </>
    );
  }
};

const mapStateToProps = (state: IAppState) => {
  return {
  };
};

export default connect(mapStateToProps)(AccountLanding);
