import React, { useState } from "react";
import { Container, Button, Card as CardSection, Table, Form } from "react-bootstrap";
import Breadcrumb from '../components/layout/Breadcrumb';
import { connect } from "react-redux";
import PageFooter from "../components/layout/PageFooter";
import PageHeader from "../components/layout/PageHeader";
import { IAppState } from "../redux/storeTypes";
import AddPaymentModal from "./checkout/AddPaymentModal";
import { Card } from "../models/User";
import { faPlus } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const VisaSVG = require('../assets/img/icons/Visa-Color.svg');
const AmexSVG = require('../assets/img/icons/AMEX-Color.svg');
const DiscoverSVG = require('../assets/img/icons/Discover-Color.svg');
const MasterSVG = require('../assets/img/icons/Mastercard-Color.svg');
const cardSvgMap = {
    'amex': AmexSVG,
    'amex-debit': AmexSVG,
    'disc': DiscoverSVG,
    'disc-debit': DiscoverSVG,
    'master': MasterSVG,
    'master-debit': MasterSVG,
    'visa': VisaSVG,
    'visa-debit': VisaSVG
}
const getKeyValue = (key: string) => (obj: Record<string, any>) => obj[key];

export interface IPaymentMethod {
    cardList: Array<Card>;
}

const PaymentMethod = ({ cardList }: IPaymentMethod) => {
    const [showAddNewPmtForm, setShowAddNewPmtForm] = useState(false);

    const renderCardList = () => {
        return (
            <Table borderless hover size="sm">
                {cardList.map(card => (
                    <tr>
                        <td>
                            <Form.Check type="radio" style={{ height: 'auto', display: 'inline-block', verticalAlign: 'middle' }} checked={card.default}/>
                            <img src={getKeyValue(card.cardType)(cardSvgMap)} />
                            {card.cardType} ending in {String(card.cardNumber).slice(-4)}
                            {card.default && <span className='default-tag'>Default</span>}
                            </td>
                        <td>{card.expDate}</td>
                        <td>{card.paymentType === 'creditCard' ? 'Credit Card' : 'E-check'}</td>
                        <td className="text-right"><Button variant="link" className="brand-link-primary">Manage</Button></td>
                    </tr>
                ))}
            </Table>
        )
    }

    return (
        <>
            <PageHeader />

            <Container className="mt-4">
                <Breadcrumb name='Home/Payment method' />
                <AddPaymentModal showForm={showAddNewPmtForm} setShowForm={setShowAddNewPmtForm} />
                <CardSection>
                    <CardSection.Header>
                        <h2>Payment method</h2>
                        <Button onClick={() => setShowAddNewPmtForm(true)} className="brand-btn-primary">
                            <FontAwesomeIcon icon={faPlus} size="sm" className="btn-icon" />Add Payment Method
                        </Button>
                    </CardSection.Header>
                    <CardSection.Body>
                        {
                            cardList.length
                                ? renderCardList()
                                : 'Currently there are no payment methods set up. Please click the Add Payment method button to set one up.'
                        }
                    </CardSection.Body>
                </CardSection>
            </Container>

            <PageFooter />
        </>
    );
};

const mapStateToProps = (state: IAppState) => {
    return {
        cardList: state.paymentMethods.cardList
    };
};

export default connect(mapStateToProps)(PaymentMethod);
