import { CellPlugin } from '@react-page/editor';
import BillingDetails, {
    ADDRESS_LINE_1_LABEL, ADDRESS_LINE_1_PLACEHOLDER, ADDRESS_LINE_2_LABEL, ADDRESS_LINE_2_PLACEHOLDER, COUNTRY_LABEL,
    CITY_LABEL, CITY_PLACEHOLDER, STATE_LABEL, ADDRESS_REQUIRED, ZIP_CODE_LABEL, ZIP_CODE_PLACEHOLDER, ZIP_CODE_REQUIRED,
    PHONE_NUMBER_LABEL, PHONE_NUMBER_REQUIRED, EMAIL_ADDRESS_LABEL, EMAIL_ADDRESS_REQUIRED,
    IS_COPY_OVER_REQUIRED, COPY_OVER_LABEL, MARGIN, PADDING, FULL_STORY_MASK,
} from './components/BillingDetails';

type Data = {
    title: string,
    addressLine1Label: string,
    addressLine1Placeholder: string,
    addressLine2Label: string,
    addressLine2Placeholder: string,
    countryLabel: string,
    cityLabel: string,
    cityPlaceholder: string,
    stateLabel: string,
    addressRequired: boolean,
    zipCodeLabel: string,
    zipCodePlaceholder: string,
    zipCodeRequired: boolean,
    phoneNumberLabel: string,
    phoneNumberRequired: boolean,
    emailAddressLabel: string,
    emailAddressRequired: boolean,
    isCopyOverRequired: boolean,
    copyOverLabel: string,
    fullStoryMask: boolean,
    margin: string,
    padding: string
}

const BillingDetailsPlugin: CellPlugin<Data> = {
    Renderer: ({ data }) => (
        <BillingDetails
            addressLine1Label={data.addressLine1Label}
            addressLine1Placeholder={data.addressLine1Placeholder}
            addressLine2Label={data.addressLine2Label}
            addressLine2Placeholder={data.addressLine2Placeholder}
            countryLabel={data.countryLabel}
            cityLabel={data.cityLabel}
            cityPlaceholder={data.cityPlaceholder}
            stateLabel={data.stateLabel}
            addressRequired={data.addressRequired}
            zipCodeLabel={data.zipCodeLabel}
            zipCodePlaceholder={data.zipCodePlaceholder}
            zipCodeRequired={data.zipCodeRequired}
            phoneNumberLabel={data.phoneNumberLabel}
            phoneNumberRequired={data.phoneNumberRequired}
            emailAddressLabel={data.emailAddressLabel}
            emailAddressRequired={data.emailAddressRequired}
            isCopyOverRequired={data.isCopyOverRequired}
            copyOverLabel={data.copyOverLabel}
            fullStoryMask={data.fullStoryMask}
            margin={data.margin}
            padding={data.padding}
        />
    ),
    id: 'billingDetailsPlugin',
    title: 'Billing Details',
    description: 'Collects the billing details.',
    version: 1,
    controls: [
        {
            title: 'Labels',
            controls: {
                type: 'autoform',
                schema: {
                    properties: {
                        addressLine1Label: {
                            type: 'string',
                            default: ADDRESS_LINE_1_LABEL,
                        },
                        addressLine1Placeholder: {
                            type: 'string',
                            default: ADDRESS_LINE_1_PLACEHOLDER,
                        },
                        addressLine2Label: {
                            type: 'string',
                            default: ADDRESS_LINE_2_LABEL,
                        },
                        addressLine2Placeholder: {
                            type: 'string',
                            default: ADDRESS_LINE_2_PLACEHOLDER,
                        },
                        countryLabel: {
                            type: 'string',
                            default: COUNTRY_LABEL,
                        },
                        cityLabel: {
                            type: 'string',
                            default: CITY_LABEL,
                        },
                        cityPlaceholder: {
                            type: 'string',
                            default: CITY_PLACEHOLDER,
                        },
                        stateLabel: {
                            type: 'string',
                            default: STATE_LABEL,
                        },
                        zipCodeLabel: {
                            type: 'string',
                            default: ZIP_CODE_LABEL,
                        },
                        zipCodePlaceholder: {
                            type: 'string',
                            default: ZIP_CODE_PLACEHOLDER,
                        },
                        phoneNumberLabel: {
                            type: 'string',
                            default: PHONE_NUMBER_LABEL,
                        },
                        emailAddressLabel: {
                            type: 'string',
                            default: EMAIL_ADDRESS_LABEL,
                        },
                        copyOverLabel: {
                            type: 'string',
                            default: COPY_OVER_LABEL,
                        },
                        fullStoryMask:{
                            type: 'boolean',
                            default: FULL_STORY_MASK
                        }
                    },
                    required: [],
                },
            },
        },
        {
            title: 'Required Fields/Buttons',
            controls: {
                type: 'autoform',
                schema: {
                    properties: {
                        addressRequired: {
                            type: 'boolean',
                            default: ADDRESS_REQUIRED,
                        },
                        zipCodeRequired: {
                            type: 'boolean',
                            default: ZIP_CODE_REQUIRED,
                        },
                        phoneNumberRequired: {
                            type: 'boolean',
                            default: PHONE_NUMBER_REQUIRED,
                        },
                        emailAddressRequired: {
                            type: 'boolean',
                            default: EMAIL_ADDRESS_REQUIRED,
                        },
                        isCopyOverRequired: {
                            type: 'boolean',
                            default: IS_COPY_OVER_REQUIRED,
                        },
                    },
                    required: [],
                },
            },
        },
        {
            title: 'Styles',
            controls: {
                type: 'autoform',
                schema: {
                    properties: {
                        margin: {
                            type: 'string',
                            default: MARGIN,
                        },
                        padding: {
                            type: 'string',
                            default: PADDING,
                        },
                    },
                    required: [],
                },
            },
        },
    ],
};

export default BillingDetailsPlugin;