import React, { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { IActionResult, IAppState } from "../../redux/storeTypes";
import { Button, Card, Col, Container, Form, Row } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle } from "@fortawesome/free-regular-svg-icons";
import { getPayPalCredentialsAction, GET_PAYPAL_CREDENTIALS_REQUEST, GET_PAYPAL_CREDENTIALS_SUCCESS, savePayPalCredentialsAction } from "../../redux/actions/clients";
import { PayPalCredential } from "../../models/Client";

export interface IParams {
    id: string;
}

export interface IPartnerReferralPageProps {
    payPalCredential: PayPalCredential;
    actionResult: IActionResult;
}

const PartnerReferralPage = ({ payPalCredential, actionResult }: IPartnerReferralPageProps) => {
    const actionToken = "PayPalReferralPage";
    const { id }: IParams = useParams();
    const dispatch = useDispatch();
    const [grant, setGrant] = useState(false);
    const [started, setStarted] = useState(false);
    const url = `${window.location.protocol}//${window.location.host}/PayPal/PartnerReferral/${id}`;

    const startReferral = async (event: any) => {
        event.preventDefault();
        setStarted(true);
        const response = await fetch(url, { method: 'POST' });
        let result = await response.json();
        window.location.replace(result.links.filter((l: { rel: string; }) => l.rel === "action_url")[0].href);
    }

    const gotoNexus = () => {
        window.location.replace("https://admin.msbnexus.navient.com");
    }

    useEffect(() => {
        if (id === "Onboarded") {
            dispatch(savePayPalCredentialsAction(window.location.search));
        } else {
            dispatch(getPayPalCredentialsAction(id, actionToken));
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (actionResult && actionResult.result) {
            if (actionResult.type === GET_PAYPAL_CREDENTIALS_REQUEST && actionResult.token === actionToken) {
                if (actionResult.result === GET_PAYPAL_CREDENTIALS_SUCCESS) {
                }
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [actionResult]);

    if (id === "Onboarded") {
        return (
            <>
                <Container>
                    <br />
                    <Card>
                        <Card.Body>
                            <Row>
                                <Col>
                                    <img src={"https://www.paypalobjects.com/webstatic/mktg/Logo/pp-logo-200px.png"} alt="PayPal Logo" />
                                </Col>
                                <Col>
                                    <img src={"https://msbgovserv.com/assets/img/MSB-Logo-2022.png"} width="250" alt="MSB Logo" style={{ float: "right" }} />
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>
                    <Card>
                        <Card.Body>
                            <Row>
                                <Col>
                                    <div style={{ textAlign: "center" }}>
                                        <h1 style={{ margin: "20px 0px", fontSize: "40px", fontWeight: "bold" }}>Seller Onboarding</h1>
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <div style={{ textAlign: "center" }}>
                                        <FontAwesomeIcon icon={faCheckCircle} size="5x" style={{ color: 'green' }} />
                                        <h1 style={{ margin: "20px 0px", fontSize: "20px" }}>Thank You - The referral process has been completed successfully.</h1>
                                    </div>
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>
                    <Card>
                        <Card.Body>
                            <Row>
                                <Col></Col>
                                <Col>
                                    <div style={{ display: "flex", justifyContent: "center" }}>
                                        <Button variant="primary" size="lg" block onClick={gotoNexus}>Log into Nexus</Button>
                                    </div>
                                </Col>
                                <Col></Col>
                            </Row>
                        </Card.Body>
                    </Card>
                </Container>
            </>
        );
    } else if (payPalCredential?.permissionGranted === true) {
        return (
            <>
                <Container>
                    <br />
                    <Card>
                        <Card.Body>
                            <Row>
                                <Col>
                                    <img src={"https://www.paypalobjects.com/webstatic/mktg/Logo/pp-logo-200px.png"} alt="PayPal Logo" />
                                </Col>
                                <Col>
                                    <img src={"https://msbgovserv.com/assets/img/MSB-Logo-2022.png"} width="250" alt="MSB Logo" style={{ float: "right" }} />
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>
                    <Card>
                        <Card.Body>
                            <Row>
                                <Col>
                                    <div style={{ textAlign: "center" }}>
                                        <h1 style={{ margin: "20px 0px", fontSize: "40px", fontWeight: "bold" }}>Seller Onboarding</h1>
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <div style={{ textAlign: "center" }}>
                                        <FontAwesomeIcon icon={faCheckCircle} size="5x" style={{ color: 'green' }} />
                                        <h1 style={{ margin: "20px 0px", fontSize: "20px" }}>Thank You - The referral process has already been completed previously.</h1>
                                    </div>
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>
                    <Card>
                        <Card.Body>
                            <Row>
                                <Col></Col>
                                <Col>
                                    <div style={{ display: "flex", justifyContent: "center" }}>
                                        <Button variant="primary" size="lg" block onClick={gotoNexus}>Log into Nexus</Button>
                                    </div>
                                </Col>
                                <Col></Col>
                            </Row>
                        </Card.Body>
                    </Card>
                </Container>
            </>
        );
    } else if (payPalCredential?.permissionGranted === false) {
        return (
            <>
                <Container>
                    <br />
                    <Card>
                        <Card.Body>
                            <Row>
                                <Col>
                                    <img src={"https://www.paypalobjects.com/webstatic/mktg/Logo/pp-logo-200px.png"} alt="PayPal Logo" />
                                </Col>
                                <Col>
                                    <img src={"https://msbgovserv.com/assets/img/MSB-Logo-2022.png"} width="250" alt="MSB Logo" style={{ float: "right" }} />
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>
                    <Card>
                        <Card.Body>
                            <Row>
                                <Col>
                                    <div style={{ textAlign: "center" }}>
                                        <h1 style={{ margin: "30px 0px", fontSize: "40px", fontWeight: "bold" }}>Seller Onboarding</h1>
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <div style={{ textAlign: "center" }}>
                                        <h1 style={{ margin: "20px 0px", fontSize: "20px" }}>MSB as a partner of PayPal requires sellers to grant permissions to conduct PayPal actions on their behalf.</h1>
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <div style={{ textAlign: "center" }}>
                                        <h1 style={{ margin: "20px 20px 20px 30px", fontSize: "20px" }}>To sign up for a PayPal Business Account and grant consent, please click the Sign Up button below.</h1>
                                    </div>
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>
                    <Card>
                        <Card.Body>
                            <Row>
                                <Col>
                                    <div style={{ margin: "20px 0px", display: "flex", justifyContent: "center", fontSize: "20px" }}>
                                        <Form.Check type="checkbox" label="I grant consent to share customer data with PayPal." defaultChecked={grant} onChange={(e) => setGrant(e.target.checked)} />
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col></Col>
                                <Col>
                                    <div style={{ marginBottom: "20px", display: "flex", justifyContent: "center" }}>
                                        <Button variant="primary" size="lg" block disabled={!grant || started} onClick={startReferral}>Sign Up</Button>
                                    </div>
                                </Col>
                                <Col></Col>
                            </Row>
                        </Card.Body>
                    </Card>
                </Container>
            </>
        );
    } else {
        return (
            <>
                <Container>
                    <br />
                    <Card>
                        <Card.Body>
                            <Row>
                                <Col>
                                    <img src={"https://www.paypalobjects.com/webstatic/mktg/Logo/pp-logo-200px.png"} alt="PayPal Logo" />
                                </Col>
                                <Col>
                                    <img src={"https://msbgovserv.com/assets/img/MSB-Logo-2022.png"} width="250" alt="MSB Logo" style={{ float: "right" }} />
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>
                    <Card>
                        <Card.Body>
                            <Row>
                                <Col>
                                    <div style={{ textAlign: "center" }}>
                                        <h1 style={{ margin: "30px 0px", fontSize: "40px", fontWeight: "bold" }}>Invalid Seller Onboarding</h1>
                                    </div>
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>
                </Container>
            </>
        );
    }
};

const mapStateToProps = (state: IAppState) => {
    return {
        payPalCredential: state.clients.payPalCredential,
        actionResult: state.clients.actionResult,
    };
};

export default connect(mapStateToProps)(PartnerReferralPage);
