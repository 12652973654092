import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import axios from 'axios';
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, HashRouter, useHistory } from "react-router-dom";

import { Provider, useDispatch } from 'react-redux'
//import ReduxThunk from 'redux-thunk'

import store from "./redux/store";
import { createHashHistory, createBrowserHistory } from 'history';
import { PublicClientApplication, EventType } from "@azure/msal-browser";
// core styles
import "./scss/nexus.scss";

// vendor styles
import "@fortawesome/fontawesome-free/css/all.css";
import "react-datetime/css/react-datetime.css";

import HomePage from "./pages/HomePage";
import HomePageSubdomain from "./pages/HomePageSubdomain";
import ScrollToTop from "./components/ScrollToTop";

let _url = window.location.protocol + "//" + window.location.host + "/nexus/settings";
let subdomain = null;

/*
let b2cRedirectUri = "http://localhost:3000";
let b2cClientId = "5281f179-7c00-44e4-a091-f6722df6d486";
let b2cAuthority =  "https://devnavientb2c.b2clogin.com/tfp/devnavientb2c.onmicrosoft.com/B2C_1A_SIGNUP_SIGNIN";
let subdomain = null;


let msalConfig = {
  auth: {
      clientId: b2cClientId,
      authority: b2cAuthority,
      knownAuthorities: [b2cAuthority],
      redirectUri: b2cRedirectUri,
      postLogoutRedirectUri: "/"
  }};

  var msalInstance = new PublicClientApplication(msalConfig);

  const accounts = msalInstance.getAllAccounts();

  if (accounts.length > 0) {
      msalInstance.setActiveAccount(accounts[0]);
  }


  console.log("index::subdomain:", window.location.pathname);

  if (localStorage.getItem("subdomain")) {
    subdomain = localStorage.getItem("subdomain");
  }

  msalInstance.addEventCallback((event) => {
      if (event.eventType === EventType.LOGIN_SUCCESS && event.payload) {
          const payload = event.payload;
          const account = payload.account;
          msalInstance.setActiveAccount(account);

          if (localStorage.getItem("subdomain")) {
            subdomain = localStorage.getItem("subdomain");
            window.location.href =  `${window.location.protocol}//${window.location.host}/${subdomain}`;
          }
      }
  });

  msalInstance.handleRedirectPromise().then((authResult) => {
      if (subdomain) {
        const account = msalInstance.getActiveAccount();

        if (!account) {
          msalInstance.loginRedirect();
        }
      }
      }).catch(err=>{
        console.log(err);
      }
  );

  if (subdomain) {
    ReactDOM.render(
      <Provider store={store}>
          <Router>
              <ScrollToTop />
              <HomePageSubdomain publicClientApplication={msalInstance} />
          </Router>
      </Provider>,
    document.getElementById("root")
    );
  } else {
    ReactDOM.render(
      <Provider store={store}>
          <Router>
              <ScrollToTop />
              <HomePage publicClientApplication={msalInstance} />
          </Router>
      </Provider>,
    document.getElementById("root")
    );
  }
*/

var msalInstance = null;

axios.post(_url)
.then(function (response) {
    let webAppSettings = response.data;
    let b2cRedirectUri = webAppSettings.b2CRedirectUri;
    let b2cClientId = webAppSettings.b2CClientId;
    let b2cAuthority =  webAppSettings.b2CAuthority;

    let msalConfig = {
      auth: {
          clientId: b2cClientId,
          authority: b2cAuthority,
          knownAuthorities: [b2cAuthority],
          redirectUri: b2cRedirectUri,
          postLogoutRedirectUri: "/"
        }
    };

    msalInstance = new PublicClientApplication(msalConfig);
    const accounts = msalInstance.getAllAccounts();

    if (accounts.length > 0) {
        msalInstance.setActiveAccount(accounts[0]);
    }
    
    //console.log("index::subdomain", window.location.pathname);

    if (localStorage.getItem("subdomain")) {
        subdomain = localStorage.getItem("subdomain");
        console.log("has subdomain", subdomain);

        var pathname = window.location.pathname;
        if (pathname.startsWith('/')) {
            pathname = pathname.substring(1);
        }

        console.log("pathname", pathname);

        if (pathname.length > 0 && !pathname.startsWith(subdomain)) {
            console.log("different apps clear subdoamin");
            subdomain = null;
            localStorage.removeItem("subdomain");
        }
    }
    
    msalInstance.addEventCallback((event) => {
        if (event.eventType === EventType.LOGIN_SUCCESS && event.payload) {
            const payload = event.payload;
            const account = payload.account;
            msalInstance.setActiveAccount(account);
    
            if (localStorage.getItem("subdomain")) {
                subdomain = localStorage.getItem("subdomain");
                window.location.href =  `${window.location.protocol}//${window.location.host}/${subdomain}`;
            }
        }
    });
    
    msalInstance.handleRedirectPromise().then((authResult) => {
        if (subdomain) {
            const account = msalInstance.getActiveAccount();
            if (!account) {
                msalInstance.loginRedirect();
            }
        }
    }).catch(err => {
        console.log(err);
    });
})
.catch(function (error) {
    console.log(error);
})
.then(function () {
    //console.log("index", subdomain);
    if (subdomain != null) {
        ReactDOM.render(
            <Provider store={store}>
                <Router>
                    <ScrollToTop />
                    <HomePageSubdomain publicClientApplication={msalInstance} />
                </Router>
            </Provider>,
            document.getElementById("root")
        );
    } else {
        ReactDOM.render(
            <Provider store={store}>
                <Router>
                    <ScrollToTop />
                    <HomePage publicClientApplication={msalInstance} />
                </Router>
            </Provider>,
            document.getElementById("root")
        );
    }
});