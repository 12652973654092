import { Row, Col, Spinner, Button, Modal, Form } from "react-bootstrap"
import { useEffect, useState } from "react";
import Table from '../../../../../../components/Table';
import { Order } from "../../../../../../models/Order";
import { updateShoppingCart, ADD_ORDER_LINE, calculateOrderAction, updateOrderDetailsAction, UPDATE_ORDER_DETAILS, addOrderLineAction } from '../../../../../../redux/actions/orderManagement';
import { IActionResult, IAppState } from '../../../../../../redux/storeTypes';
import { connect, useDispatch } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCartPlus } from "@fortawesome/free-solid-svg-icons";
import _ from "lodash";
import { CurrencyFormatter, DateWithoutUTCFormatter } from "../../../../../../components/Formatters";

interface IPikeShoppingCart {
    title: string,
    subTitle: string,
    nameLabel: string,
    accountLabel: string,
    taxYearLabel: string,
    taxTypeLabel: string,
    addressLabel: string,
    totalDueLabel: string,
    dueDateLabel: string,
    actionsLabel: string,
    margin: string,
    padding: string,
    headerBackgroundColor: string,
    headerPadding: string,
    cartButtonBackgroundColor: string,
    cartButtonFontColor: string,
    cartButtonFontSize: string,
    cartButtonFontStyle: string,
    cartButtonFontWeight: string,
    cartButtonBorderColor: string,
    cartIconColor: string,
    actionResult: IActionResult,
    shoppingCart: any,
    order: Order,
}

export const TITLE = 'Shopping cart';
export const SUBTITLE = ''
export const NAME_LABEL = 'Name';
export const ACCOUNT_LABEL = 'Account'
export const TAX_YEAR_LABEL = 'Tax Year';
export const TAX_TYPE_LABEL = 'Tax Type';
export const ADDRESS_LABEL = 'Address';
export const TOTAL_DUE_LABEL = 'Total Due';
export const DUE_DATE_LABEL = 'Due Date';
export const ACTIONS_LABEL = 'Actions';
export const MARGIN = '0px';
export const PADDING = '0px';
export const HEADER_BACKGROUND_COLOR = 'rgb(250, 250, 250)';
export const HEADER_PADDING = '0';
export const CART_BUTTON_FONT_COLOR = '#414141';
export const CART_BUTTON_FONT_SIZE = '.875rem';
export const CART_BUTTON_FONT_STYLE = 'normal';
export const CART_BUTTON_FONT_WEIGHT = 'normal';
export const CART_BUTTON_BORDER_COLOR = '#D9D9D9';
export const CART_BUTTON_BACKGROUND_COLOR = '#FFFFFF';
export const CART_ICON_COLOR = '#414141';

const checkStyleForDefault = (style: string, defaultStyle: string) => {
    return style && style !== '' ? style : defaultStyle;
}

const checkStyleForDefault3 = (defaultStyle: string) => {
    return defaultStyle as any;
}

export const scrollToPaymentType = () => {
    const targetElement = document.getElementById('scrollToPaymentType');
    if (targetElement) {
        targetElement.scrollIntoView({ behavior: 'smooth' });
    }
}

const PikeShoppingCart = ({ title, subTitle, nameLabel, accountLabel, taxYearLabel, taxTypeLabel, addressLabel, totalDueLabel, dueDateLabel, actionsLabel, actionResult,
    cartButtonBackgroundColor, cartButtonFontColor, cartButtonFontSize, cartButtonFontStyle, cartButtonFontWeight, cartButtonBorderColor,
    cartIconColor, margin, padding, headerBackgroundColor, headerPadding, shoppingCart, order }: IPikeShoppingCart) => {

    if (!title) { title = TITLE }
    if (!subTitle) { subTitle = SUBTITLE }
    if (!nameLabel) { nameLabel = NAME_LABEL }
    if (!accountLabel) { accountLabel = ACCOUNT_LABEL }
    if (!taxYearLabel) { taxYearLabel = TAX_YEAR_LABEL }
    if (!taxTypeLabel) { taxTypeLabel = TAX_TYPE_LABEL }
    if (!addressLabel) { addressLabel = ADDRESS_LABEL }
    if (!totalDueLabel) { totalDueLabel = TOTAL_DUE_LABEL }
    if (!dueDateLabel) { dueDateLabel = DUE_DATE_LABEL }
    if (!actionsLabel) { actionsLabel = ACTIONS_LABEL }
    if (!margin) { margin = MARGIN }
    if (!padding) { padding = PADDING }
    if (!headerBackgroundColor) { headerBackgroundColor = HEADER_BACKGROUND_COLOR }
    if (!headerPadding) { headerPadding = HEADER_PADDING }
    if (!cartButtonBackgroundColor) { cartButtonBackgroundColor = CART_BUTTON_BACKGROUND_COLOR }
    if (!cartButtonFontColor) { cartButtonFontColor = CART_BUTTON_FONT_COLOR }
    if (!cartButtonFontSize) { cartButtonFontSize = CART_BUTTON_FONT_SIZE }
    if (!cartButtonFontStyle) { cartButtonFontStyle = CART_BUTTON_FONT_STYLE }
    if (!cartButtonFontWeight) { cartButtonFontWeight = CART_BUTTON_FONT_WEIGHT }
    if (!cartButtonBorderColor) { cartButtonBorderColor = CART_BUTTON_BORDER_COLOR }
    if (!cartIconColor) { cartIconColor = CART_ICON_COLOR }

    const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
    });

    const amountFormatter = (cell: any) => {
        return (<span>{formatter.format(cell)}</span>)
    }

    const actionsFormatter = (cell: any, row: any) => {
        if (row?.isDeletable) {
            return (
                <Button size="sm" variant="outline-secondary" onClick={() => setDeleteRow(row)}>
                    Delete
                </Button>
            );
        }
        if (cell === 'EmptyCart') {
            return (
                <Button size="sm" style={{ border: 'none', backgroundColor: '#f0ad4e' }} onClick={(e) => clearCart(e)}>Empty Cart</Button>
            );
        }
    }

    const initialColumns = [{
        dataField: 'name',
        text: nameLabel,
        headerStyle: {
            backgroundColor: checkStyleForDefault(headerBackgroundColor, HEADER_BACKGROUND_COLOR)
        },
        editable: false,
    }, {
        dataField: 'account',
        text: accountLabel,
        editable: false,
        headerStyle: {
            backgroundColor: checkStyleForDefault(headerBackgroundColor, HEADER_BACKGROUND_COLOR)
        },
        sort: true,
    }, {
        dataField: 'taxYear',
        text: taxYearLabel,
        headerStyle: {
            backgroundColor: checkStyleForDefault(headerBackgroundColor, HEADER_BACKGROUND_COLOR)
        },
        editable: false,
        sort: true,
    }, {
        dataField: 'propertyType',
        text: taxTypeLabel,
        headerStyle: {
            backgroundColor: checkStyleForDefault(headerBackgroundColor, HEADER_BACKGROUND_COLOR)
        },
        editable: false,
    }, {
        dataField: 'address',
        text: addressLabel,
        headerStyle: {
            backgroundColor: checkStyleForDefault(headerBackgroundColor, HEADER_BACKGROUND_COLOR)
        },
        editable: false,
    }, {
        dataField: 'paymentAmount',
        text: totalDueLabel,
        headerStyle: {
            backgroundColor: checkStyleForDefault(headerBackgroundColor, HEADER_BACKGROUND_COLOR)
        },
        editable: false,
        formatter: amountFormatter,
    }, {
        dataField: 'dueDate',
        text: dueDateLabel,
        editable: false,
        headerStyle: {
            backgroundColor: checkStyleForDefault(headerBackgroundColor, HEADER_BACKGROUND_COLOR)
        },
        formatter: DateWithoutUTCFormatter,
    }, {
        dataField: 'id',
        text: actionsLabel,
        headerStyle: {
            backgroundColor: checkStyleForDefault(headerBackgroundColor, HEADER_BACKGROUND_COLOR)
        },
        formatter: actionsFormatter,
        editable: false,
    }];


    const [columns, setColumns] = useState<any>(initialColumns);
    const dispatch = useDispatch();
    const [deleteRow, setDeleteRow] = useState<any>('');
    const actionToken = 'ItemShoppingCart';
    const [showModal, setShowModal] = useState<boolean>(false);

    useEffect(() => {
        if (shoppingCart && order) {
            const deletedAccountObject = shoppingCart.find((details: any) => details?.id == deleteRow?.id)?.account;
            let _shoppingCart = shoppingCart.map((shoppingCartDetails: any) => {
                if (shoppingCartDetails?.id != deleteRow?.id) {
                    return shoppingCartDetails
                }
            }).filter(Boolean)
            const nextYearObject = Math.max(..._shoppingCart.filter((details:any) => details?.account === deletedAccountObject).map((item:any) => item?.taxYear));
            _shoppingCart.forEach((details:any) => { 
                if(details?.account === deletedAccountObject){ 
                    details.isDeletable = details?.taxYear === nextYearObject
                }
            })
            let _updatedOrderLines = order?.orderLines.map((orderDetails: any) => {
                if (orderDetails?.itemMetadata?.uiUniqueId != deleteRow?.id) {
                    return orderDetails
                }
            }).filter(Boolean)
            order.orderLines = _updatedOrderLines;
            dispatch(updateShoppingCart(_shoppingCart, actionToken));
            dispatch(updateOrderDetailsAction(order, actionToken));
        }
    }, [deleteRow]);

    useEffect(() => {
        if (actionResult && actionResult.type === ADD_ORDER_LINE) {
            dispatch(calculateOrderAction(actionToken));
        }
        if (actionResult && actionResult.type === UPDATE_ORDER_DETAILS) {
            dispatch(calculateOrderAction(actionToken));
        }

    }, [actionResult]);

    const renderTable = () => {
        return (
            <>
                {shoppingCart?.length > 0 &&
                    <table className="table table-bordered">
                        <tbody>
                            <tr style={{ fontWeight: 'bold' }}>
                                <td></td>
                                <td style={{float: 'right', marginRight:'20px', borderStyle:'none'}}>Total : {CurrencyFormatter(_.sumBy(shoppingCart, 'paymentAmount'))}</td>
                                <td><Button size="sm" style={{ border: 'none', backgroundColor: '#f0ad4e' }} onClick={(e) => clearCart(e)}>Empty Cart</Button></td>
                            </tr>
                        </tbody>
                    </table>
                }
                <Table
                    keyField="msbId"
                    data={shoppingCart}
                    columns={columns}
                    remote={false}
                />
            </>
        );
    };

    const scrollToCheckout = (e: any) => {
        e.preventDefault();
        setShowModal(false);
        setTimeout(() => {
            scrollToPaymentType();
        }, 100);

    }

    const clearCart = (e: any) => {
        e.preventDefault();
        let order = new Order();
        order.orderLines = [];
        dispatch(updateShoppingCart([], actionToken));
        dispatch(updateOrderDetailsAction(order, actionToken));
    }

    return (
        <div style={{ margin: margin, padding: padding }}>
            {shoppingCart?.length > 0 &&
                <Row>
                    <Col>
                        <Button className="brand-btn-primary" style={{
                            marginTop: '29px',
                            backgroundColor: checkStyleForDefault(cartButtonBackgroundColor, CART_BUTTON_BACKGROUND_COLOR),
                            borderColor: checkStyleForDefault(cartButtonBorderColor, CART_BUTTON_BORDER_COLOR),
                            color: checkStyleForDefault(cartButtonFontColor, CART_BUTTON_FONT_COLOR),
                            fontSize: checkStyleForDefault(cartButtonFontSize, CART_BUTTON_FONT_SIZE),
                            fontStyle: checkStyleForDefault(cartButtonFontStyle, CART_BUTTON_FONT_STYLE),
                            fontWeight: checkStyleForDefault3(cartButtonFontWeight)
                        }} onClick={() => setShowModal(true)}>
                            <FontAwesomeIcon icon={faCartPlus} style={{ color: checkStyleForDefault(cartIconColor, CART_ICON_COLOR) }} className="btn-icon" />{`View Cart (${shoppingCart?.length})`}
                        </Button>
                    </Col>
                </Row>
            }
            <Modal show={showModal} size={'xl'} backdrop="static" onHide={() => setShowModal(false)}>
                <Modal.Header closeButton />
                <Modal.Body>
                    <Form.Group>
                        <div className="form-header">
                            <h2>{title}</h2>
                            <p>{subTitle}</p>
                        </div>
                        {renderTable()}
                        <div style={{ float: 'right' }}>
                            <Button style={{ marginRight: '8px' }} onClick={(e) => scrollToCheckout(e)}>Checkout</Button>
                            <Button variant="outline-secondary" onClick={() => setShowModal(false)}>Close</Button>
                        </div>
                    </Form.Group>
                </Modal.Body>
            </Modal>
        </div>

    )
}

const mapStateToProps = (state: IAppState) => {
    return {
        isSaving: state.orderManagement.isSaving,
        actionResult: state.orderManagement.actionResult,
        order: state.orderManagement.order,
        shoppingCart: state.orderManagement.shoppingCart
    };
};

export default connect(mapStateToProps)(PikeShoppingCart);
