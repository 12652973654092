import { CellPlugin } from '@react-page/editor';
import React from 'react';
import { Col, Row } from 'react-bootstrap';

// use a type here, not an interface
type Data = {
  height: string,
  width: string,
}

const HEIGHT = '24px';
const WIDTH = 'auto';

const checkStyleForDefault = (style:string, defaultStyle:string) => {
  return style && style !== ''?style:defaultStyle;
}

const SpacerPlugin: CellPlugin<Data> = {
  Renderer: ({ data }) => (
    <Row>
      <Col>
        <span style={{
          display:'block',
          height:checkStyleForDefault(data.height, HEIGHT),
          width:checkStyleForDefault(data.width, WIDTH),
        }}></span>
      </Col>
    </Row>
  ),
  id: 'spacerPlugin',
  title: 'Spacer',
  description: 'Resizeable empty space.',
  version: 1,
  controls: {
    type: 'autoform',
    schema: {
      properties: {
        height: {
          type: 'string',
          default: HEIGHT,
        },
        width: {
          type: 'string',
          default: WIDTH,
        },
      },
      required: ['height'],
    },
  },
};

export default SpacerPlugin;