import React from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import cellEditFactory from 'react-bootstrap-table2-editor';
import paginationFactory from 'react-bootstrap-table2-paginator';
import moment from 'moment';
import PerfectScrollbar from 'react-perfect-scrollbar';
import 'react-perfect-scrollbar/dist/css/styles.css';
interface TableProps {
    remote: any;
    keyField: any;
    data: any;
    columns: any;
    options?: any;
    expandRow?: any;
    onTableChange?: any;
    selectRow?: any;
    sizePerPage?: number;
    page?: number;
    defaultSorted?: any
}

export const CellDateFormatter = (date:any) => {
    const dateString = moment(date).format('MM-DD-YYYY HH:mm:ss');
    return (
        <span>{dateString}</span>
    );
}

const Table = ({ keyField, data, columns, options, expandRow, selectRow, remote, onTableChange, page, sizePerPage, defaultSorted }: TableProps) => {
    return (
        <PerfectScrollbar>
            <div style={{paddingBottom:"25px"}}>
                <BootstrapTable
                    remote={remote}
                    keyField={keyField}
                    bordered={true}
                    expandRow={expandRow}
                    data={data}
                    selectRow={selectRow}
                    columns={columns}
                    onTableChange={onTableChange}
                    pagination={paginationFactory(options || {page: page ? page : 1, sizePerPage: sizePerPage ? sizePerPage : 10, })}
                    cellEdit={cellEditFactory({
                        mode: 'click',
                        blurToSave: true,
                        autoSelectText: true,
                    })}
                    wrapperClasses="table-responsive"
                    defaultSorted={defaultSorted}
                />
            </div>
        </PerfectScrollbar>    
    )
}


export default Table