export enum PaymentTypeEnum {
    Unknown = 0,
    CreditCardManual = 1,   //QuickPay & POS
    ECheck = 2,             //QuickPay & POS
    PayPal = 3,             //PayPal
    CreditCardTerminal = 4  //TerminalPay & POS
}

export enum PaymentType {
    Unknown = 0, 
    CreditCard = 1,
    ECheck = 2,
    PayPal = 3,
    Terminal = 4
}

export enum PaymentCardTypeEnum {
    Unknown = 0,
    AmericanExpressCredit = 1,
    AmericanExpressDebit = 2,
    DiscoverCredit = 3,
    DiscoverDebit = 4,
    MastercardCredit = 5,
    MastercardDebit = 6,
    MastercardDebitPIN = 7,
    VisaCredit = 8,
    VisaDebit = 9,
    VisaDebitPIN = 10,
    ElectronicCheck = 11,
    PayPal = 12,
    PayPalCard = 13,
    PayPalCredit = 14,
    PayPalVenmo = 15
}

export class OrderLine {
    accountNumber?: string;
    itemReferenceNumber: string = "";
    itemName: string = "";
    unitPrice: number = 0;
    quantity: number = 0;
    itemId?: string;
    itemMetaData?: {};
}

export enum ItemStatusType{
    Unknown = 0, 
    Pending = 1,
    Unpaid = 2,
    Paid = 3,
    Error = 4
}

export enum PaymentMethodOnFileType {
    Unknown = 0,
    Card = 1,
    BankAccount = 2
}

export class Order {
    type: string = "";
    methodName: string = "";
    initiatedBy: string = "";

    clientMsbId: string = "";
    paymentChannelMsbId: string = "";
    paymentChannelName: string = "";
    departmentMsbId: string = "";

    //paymentType: PaymentTypeEnum = PaymentTypeEnum.Unknown;
    paymentType: PaymentType = PaymentType.Unknown;
    isCardPresent: boolean = false; // false for quick pay/express payments

    nameOnCard: string = "";
    cardType: PaymentCardTypeEnum = PaymentCardTypeEnum.Unknown;
    creditCardNumber: string = "";
    expirationDate: string = "";
    cvv: string = ""

    eCheckAccountHolderName: string = "";
    eCheckRoutingNumber: string = "";
    eCheckAccountNumber: string = "";
    
    referenceNumber: string = "";
    productType: string = "";
    externalCustomerReference: string = "";

    addressLine1: string = "";
    addressLine2: string = "";
    country: string = "";
    city: string = "";
    state: string = "";
    zip: string = "";

    phone: string = "";
    emailAddress: string = "";

    amount: number = 0;
    convenienceFee: number = 0;
    totalAmount: number = 0;

    orderLines: Array<OrderLine> = new Array<OrderLine>();
    laneId: number = 0;
    terminalId: string = "";
    isDebitCard?: boolean;
    isMailOrTelephoneOrder?: boolean;
    metadata: any;

    shouldTokenizeCard: boolean = false;
    paymentMethodOnFileType?: PaymentMethodOnFileType;
    paymentMethodOnFileReference?: string;
    isImplicitAuthorization: boolean = false;
    isTokenisedPayment: boolean = false;
}

export class AccountDetails {
    accountCustomerName : string = "";
    accountAddressLine1 : string = "";
    accountAddressLine2 : string = "";
    accountCountry: string = "";
    accountCity: string = "";
    accountState: string = "";
    accountZipCode: string = "";
    accountPhoneNumber: string = "";
    accountEmailAddress: string = "";
}

export class ShoppingCartDetails{
    taxType?: string;
    accountNumber: string = "";
    balanceDue: number = 0;
    paymentAmount: number = 0;
}

export class ProductLine {
    id: string = "";
    type: string = "";
    price: number = 0;
    quantity: number = 0;
}

export class Metadata {
}

export enum OrderStatusEnum {
    Unknown = "Unknown",
    Pending = "Pending",
    Success = "Success",
    Declined = "Declined",
    Error = "Error",
    Overridden = "Overridden"
}

export enum ChargeBackStatusEnum {
    Undisputed = "Undisputed",
    Pending = "Pending",
    Disputed = "Disputed",
    NavientFavor = "NavientFavor",
    ClientFavor = "ClientFavor"
}

export class OrderSummary {
    msbId?: string;
    createdAt: string = "";
    clientId?: string;
    departmentId?: string;
    paymentChannelId?: string;
    orderIdentifier: string = "";
    isImmutable: boolean = false;
    originalTransactionDate: string = "";
    originalAmount: number = 0;
    remainingBalance: number = 0;
    orderTransactions?: Array<OrderTransaction>;
    isDebitCardTransaction?: boolean = false;
}

export class TransactionComment {
    msbId?: string;
    createdAt: string = "";
    description: string = "";
}

export class TransactionFile {
    msbId?: string;
    createdAt: string = "";
    fileName: string = "";
}

export enum TransactionTypeEnum {
    Unknown = 0,
    Sale = 1,
    Void = 2,
    Refund = 3,
    Chargeback = 4,
    Return = 5,
    Authorization = 6,
    AuthorizationCommit = 7,
    CreateToken = 8
}

export class OrderTransaction {
    msbId?: string;
    createdAt: string = "";
    transactionIdentifier: string = "";
    orderIdentifier: string = "";
    nameOnCard: string = "";
    lastFourOnCard: string = "";
    originalAmount: number = 0;
    remainingBalance: number = 0;
    orderSummaryId: number = 0;
    orderSummary: OrderSummary = new OrderSummary();
    orderStatus: OrderStatusEnum = OrderStatusEnum.Unknown;
    paymentType: string = "";
    transactionType: TransactionTypeEnum = TransactionTypeEnum.Unknown;
    chargeBackStatus: ChargeBackStatusEnum = ChargeBackStatusEnum.Pending;
    amount: number = 0;
    totalAmount: number = 0;
    convenienceFee: number = 0;
    convenienceFeeIsClientAbsorbed: boolean = false;
    orderNotes: string = "";
    initiatedBy: string = "";
    email: string = "";
    phone: string = "";
    addressLine1: string = "";
    addressLine2: string = "";
    city: string = "";
    state: string = "";
    zip: string = "";
    clientMetadata: string = "";
    processorMessage: string = "";
    reason: string = "";
    vantivTransactionReferenceNumber: string = "";
    orderLines: Array<OrderLine> = new Array<OrderLine>();
    isNotChargeBackTransaction: boolean = false;
    isChargeBackTransaction: boolean = false;
    comments: Array<TransactionComment> = new Array<TransactionComment>();
    files: Array<TransactionFile> = new Array<TransactionFile>();
    chargeBackDueDate: string = "";
}

export class OrderReceipt {
    accountNumbers: string = "";
    amount: string = "";
    approvalNumber: string = "";
    cardLogo: string = "";
    convenienceFee: string = "";
    createdAt: string = "";
    hostResponseCode: string = "";
    merchantIdentifier: string = "";
    nameOnCard: string = "";
    orderTransaction?: OrderTransaction;
    pinVerified: boolean = false;
    terminalIdentifier: string = "";
    totalAmount: string = "";
    transactionIdentifier: string = "";
    orderId: string = "";
    lastFourOnCard: string = "";
    paymentType: PaymentType = PaymentType.Unknown;
    departmentId: string = "";
    paymentChannelId: string = "";
    accountNumberLastFour: string = "";
    processorType?: string;
    vantivPaymentType?: string = "";
}

export class ClientAddress {
    addressLine1: string = "";
    addressLine2: string = "";
    country: string = "";
    city: string = "";
    state: string = "";
    zip: string = "";
}

export class ProcessorData {
    address?: ClientAddress;
    receipt?: OrderReceipt;
}

export class ProcessedOrder {
    processorData?: ProcessorData;
}

//Refactor to use only one payment type
export enum ReportsPaymentType {
    allPayments = 1,
    achPayments = 2,
    cash = 3,
    check = 4,
    creditCard = 5,
    moneyOrder = 6
}

export enum OrderStatus{
    Unknown = 0, 
    Pending = 1,
    Success = 2,
    Declined = 3,
    Error = 4,
    Overridden = 5
}

export class PaymentTransactions {
    customerName: string = "";
    totalAmount: number = 0
    transactionDate: string = "";
    transactionId: string = "";
    transactionReference: string = "";
    status: string = "";
    transactionType: string = "";
}

export class FeeCalculation {
    amount: number = 0;
    convenienceFee: number = 0;
    totalAmount: number = 0;
}

export class TransactionDetails {
    additionalValues: any;
    addressLine1: string = "";
    amount: number = 0;
    city: string = "";
    orderIdentifier: string= "";
    convenienceFee: number = 0;
    email: string = "";
    customerName: string = "";
    phone: string = "";
    initiatedBy: string = "";
    localCreatedAt: string = "";
    orderStatus: string = "";
    paymentAccount: string = "";
    paymentChannel: string = "";
    paymentDate: string = "";
    paymentType: string = "";
    relatedTransaction: any;
    state: string = "";
    totalAmount: number = 0;
    transactionDate: string = "";
    status : string= "";
    transactionId: string = "";
    transactionReference: string = "";
    transactionState: string = "";
    transactionType: string = "";
    vantivTransactionReferenceNumber: string="";
    zip: string = "";
}

export class AvailablePaymentChannels {
    paymentChannel: string = ""
    paymentChannelId: string = "";
}

export class DualList {
    label: string = ""
    value: string = "";
}

export class SearchList {
    lastFourOnCard: string = "";
    confirmationCode: string = "";
    customerName: string = "";
    endAmount: string = "";
    endDate: string = "";
    endTime: string = "";
    initiatedBy: string = "";
    paymentAccount: string = "";
    paymentChannels: string [] = [];
    paymentType: string = "";
    startAmount: string = "";
    startDate: string = "";
    startTime: string = "";
    terminalName: string = "";
    orderIdentifier: string = "";
    vantivTransactionReferenceNumber: string = "";
    localCreatedAt: string = "";
}

export enum StCharlesPaymentStatus {
    Paid = 0, 
    UnPaid = 1,
} 