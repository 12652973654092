import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect } from 'react';
import { useState } from "react";
import { Form, Button, ListGroup, Modal } from "react-bootstrap"
import { connect, useDispatch } from "react-redux";
import { Order, PaymentCardTypeEnum, PaymentType } from '../../../../../../models/Order';
import { CALCULATE_ORDER_FAILURE, CALCULATE_ORDER_REQUEST, CALCULATE_ORDER_SUCCESS, cancelOrderAction, PROCESS_ORDER_FAILURE, PROCESS_ORDER_REQUEST, PROCESS_ORDER_SUCCESS } from '../../../../../../redux/actions/orderManagement';
import { IActionResult, IAppState } from '../../../../../../redux/storeTypes';
import { faTimesCircle } from "@fortawesome/pro-light-svg-icons";
import { TylerEagleCancel, TylerEagleRequest } from '../../../../../../models/TylerEagleIntegration';
import { POST_TYLER_EAGLE_CANCEL_REQUEST, POST_TYLER_EAGLE_CANCEL_FAILURE } from '../../../../../../redux/actions/tylerEagleIntegration';
import icoWarning from "../../../../../../assets/img/icons/ico-warning-outline.svg";
import FormHeaderConfirmation from '../../../../../../components/FormHeaderConfirmation';
import moment from 'moment';
import _ from 'lodash';
import PayPal from "../components/PayPal"
import { RedirectRequest } from '../../../../../../models/RedirectIntegration';

export interface IPaymentReference {
    paymentSummaryLabel: string,
    subtotalLabel: string,
    convenienceFeeLabel: string,
    totalAmountLabel: string,
    requireConvenienceFeeAcknowledgment: boolean,
    convenienceFeeAcknowledgmentLabel: string,
    termsLabel: string,
    termsUrl: string,
    submitButtonLabel: string,
    cancelButtonLabel: string,
    order: Order,
    isSaving: boolean,
    errorMessage: string,
    actionResult: IActionResult,
    submitButtonBackgroundColor: string,
    submitButtonFontColor: string,
    submitButtonFontSize: string,
    submitButtonFontStyle: string,
    submitButtonFontWeight: string
    tylerEagleRequest: TylerEagleRequest,
    tylerEagleCancel: TylerEagleCancel,
    tylerEagleActionResult: IActionResult,
    redirectRequest: RedirectRequest,
}

export const PAYMENT_SUMMARY_LABEL = 'Payment Summary';
export const SUBTOTAL_LABEL = 'Subtotal';
export const CONVENIENCE_FEE_LABEL = 'Convenience Fee';
export const TOTAL_AMOUNT_LABEL = 'Total amount';
export const CONVENIENCE_FEE_ACKNOWLEDGMENT_LABEL = 'I agree to accept the Convenience Fee charges';
export const TERMS_URL = 'https://payments.msbpay.navient.com/Payment_Terms_and_Conditions.pdf';
export const TERMS_LABEL = 'I agree to the Terms and Conditions';
export const SUBMIT_BUTTON_LABEL = 'Submit payment';
export const CANCEL_BUTTON_LABEL = 'Cancel payment';
export const SUBMIT_BUTTON_FONT_COLOR = '#FFFFFF';
export const SUBMIT_BUTTON_FONT_SIZE = '.875rem';
export const SUBMIT_BUTTON_FONT_STYLE = 'normal';
export const SUBMIT_BUTTON_FONT_WEIGHT = 'normal';
export const SUBMIT_BUTTON_BACKGROUND_COLOR = '#0057b6';

const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
});

const checkStyleForDefault = (style:string, defaultStyle:string) => {
    return style && style !== ''?style:defaultStyle;
}
  
const checkStyleForDefault3 = (defaultStyle:string) => {
    return defaultStyle as any;
}

const PaymentSummary = ({paymentSummaryLabel, subtotalLabel, convenienceFeeLabel, totalAmountLabel, requireConvenienceFeeAcknowledgment, convenienceFeeAcknowledgmentLabel, termsUrl, termsLabel, submitButtonLabel, cancelButtonLabel, order, isSaving, errorMessage, actionResult,
    submitButtonBackgroundColor, submitButtonFontColor, submitButtonFontSize, submitButtonFontStyle, submitButtonFontWeight,
    tylerEagleRequest, tylerEagleCancel, tylerEagleActionResult, redirectRequest }: IPaymentReference) => {
    let actionToken = "PaymentSummary"
    const dispatch = useDispatch();
    const [subtotalAmount, setSubtotalAmount] = useState<number>(0.00);
    const [convenienceFeeAmount, setConvenienceFeeAmount] = useState<number>(0.00);
    const [totalAmount, setTotalAmount] = useState<number>(0.00);
    const [agreeToTerms, setAgreeToTerms] = useState<boolean>(false);
    const [agreeToConvenienceFee, setAgreeToConvenienceFee] = useState<boolean>(false);
    const [agreeToACHTransaction, setAgreeToACHTransaction] = useState<boolean>(false);
    const [agreeToPayPalTransaction, setAgreeToPayPalTransaction] = useState<boolean>(false);
    const [processingError, setProcessingError] = useState<boolean>(false);
    const [tylerEagleRedirectError, setTylerEagleRedirectError] = useState<boolean>(false);
    const [calculationError, setCalculationError] = useState<boolean>(false);

    if (!paymentSummaryLabel) {
        paymentSummaryLabel = PAYMENT_SUMMARY_LABEL;
    }

    if (!subtotalLabel) {
        subtotalLabel = SUBTOTAL_LABEL;
    }

    if (!convenienceFeeLabel) {
        convenienceFeeLabel = CONVENIENCE_FEE_LABEL;
    }

    if (!totalAmountLabel) {
        totalAmountLabel = TOTAL_AMOUNT_LABEL;
    }

    if (!convenienceFeeAcknowledgmentLabel) {
        convenienceFeeAcknowledgmentLabel = CONVENIENCE_FEE_ACKNOWLEDGMENT_LABEL;
    }

    if (!termsLabel) {
        termsLabel = TERMS_LABEL;
    }

    if (!termsUrl) {
        termsUrl = TERMS_URL;
    }

    if (!submitButtonLabel) {
        submitButtonLabel = SUBMIT_BUTTON_LABEL;
    }

    if (!cancelButtonLabel) {
        cancelButtonLabel = CANCEL_BUTTON_LABEL;
    }

    if (!submitButtonBackgroundColor) {
        submitButtonBackgroundColor = SUBMIT_BUTTON_BACKGROUND_COLOR;
    }

    if (!submitButtonFontColor) {
        submitButtonFontColor = SUBMIT_BUTTON_FONT_COLOR;
    }

    if (!submitButtonFontSize) {
        submitButtonFontSize = SUBMIT_BUTTON_FONT_SIZE;
    }

    if (!submitButtonFontStyle) {
        submitButtonFontStyle = SUBMIT_BUTTON_FONT_STYLE;
    }

    if (!submitButtonFontWeight) {
        submitButtonFontWeight = SUBMIT_BUTTON_FONT_WEIGHT;
    }

    useEffect(() => {
        if (order) {
            setSubtotalAmount(order.amount);
            setTotalAmount(order.totalAmount);
            setConvenienceFeeAmount(order.convenienceFee);
        } else {
            setSubtotalAmount(0);
            setTotalAmount(0);
            setConvenienceFeeAmount(0);
        }
        setAgreeToTerms(false);
        setAgreeToConvenienceFee(false);
        setAgreeToACHTransaction(false);
        setAgreeToPayPalTransaction(false);
    }, [order]);

    useEffect(() => {
        if (actionResult && actionResult.result) {
            setCalculationError(false);
            setProcessingError(false);
            if (actionResult.type === CALCULATE_ORDER_REQUEST) {
                if (actionResult.result === CALCULATE_ORDER_SUCCESS) {
                    setCalculationError(false);
                } else if (actionResult.result === CALCULATE_ORDER_FAILURE) {
                    setCalculationError(true);
                }
            }
            if (actionResult.type === PROCESS_ORDER_REQUEST) {
                if (actionResult.result === PROCESS_ORDER_SUCCESS) {
                    setProcessingError(false);
                } else if (actionResult.result === PROCESS_ORDER_FAILURE) {
                    setProcessingError(true);
                }
            }
        }
    }, [actionResult]);

    useEffect(() => {
        if (tylerEagleActionResult && tylerEagleActionResult.result) {
            if (tylerEagleActionResult.type === POST_TYLER_EAGLE_CANCEL_REQUEST && actionResult.token === actionToken) {
              if (tylerEagleActionResult.result === POST_TYLER_EAGLE_CANCEL_FAILURE) {
                setTylerEagleRedirectError(true);
              }
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tylerEagleActionResult]);

    const handleCancel = () => {
        dispatch(cancelOrderAction(actionToken));
    }

    const disableSubmit = () => {
        if (redirectRequest?.pos) {
            return isSaving
                || !order
                || calculationError
                || (subtotalAmount === 0)
        } else {
            return isSaving
                || !agreeToTerms
                || !order
                || ((requireConvenienceFeeAcknowledgment && convenienceFeeAmount > 0) ? !agreeToConvenienceFee : false)
                || (order?.paymentType === PaymentType.ECheck ? !agreeToACHTransaction : false)
                || calculationError
                || (subtotalAmount === 0)
        }
    }

    return (
        <>
        <Modal 
            show={isSaving && actionResult?.type === PROCESS_ORDER_REQUEST}
            backdrop="static"
            keyboard={false}
            className="modal-confirmation">
            {order?.isCardPresent ?
                <Modal.Body>
                    <FormHeaderConfirmation iconImg={icoWarning} title="Order submission in progress" />
                    <div className="confirmation-body">
                        <p>Please have the customer insert or swipe their credit card into the terminal and accept the payment terms.</p>
                    </div>
                </Modal.Body>
                :
                <Modal.Body>
                    <FormHeaderConfirmation iconImg={icoWarning} title="Your payment is processing" />
                    <div className="confirmation-body">
                        <p>Please do not leave this page until you receive your payment confirmation.</p>
                    </div>
                </Modal.Body>
            }
        </Modal>
        <div className="container-payment-details">
            <div className="container-transaction-summary">
                <ListGroup variant="flush">
                    <ListGroup.Item>
                        <h2>{paymentSummaryLabel}</h2>
                        <div className="summaryDetail">
                            <h3>{subtotalLabel}</h3>
                            <span>{formatter.format(subtotalAmount)}</span>
                        </div>
                        <div className="summaryDetail">
                            <h3>{convenienceFeeLabel}</h3>
                            <span>{formatter.format(convenienceFeeAmount)}</span>
                        </div>
                    </ListGroup.Item>
                    <ListGroup.Item>
                        <div className="summaryDetail">
                            <h3>{totalAmountLabel}</h3>
                            <span className="fw-bold">{formatter.format(totalAmount)}</span>
                        </div>
                    </ListGroup.Item>

                </ListGroup>
            </div>            
        </div>

        {order && order.paymentType === PaymentType.PayPal ?
            <div style={{ marginTop: "48px" }}>
                <Form.Check label={<a href={termsUrl} rel="noreferrer" target="_blank">{termsLabel}</a>} disabled={isSaving || !order} checked={agreeToTerms} onChange={(e) => setAgreeToTerms(e.target.checked && document?.forms[0].reportValidity())} />
                <div className="form-check" style={{ marginBottom: "20px", marginTop: "15px" }}>
                    <input type="checkbox" className="form-check-input" disabled={isSaving || !order} checked={agreeToPayPalTransaction} onChange={(e) => setAgreeToPayPalTransaction(e.target.checked && document?.forms[0].reportValidity())} />
                    <label className="form-check-label">
                        By paying with PayPal, you acknowledge that your data will be processed by PayPal subject to the PayPal Privacy Statement available at PayPal.com.
                    </label>
                </div>
                <PayPal disabled={isSaving || !order || !agreeToTerms || !agreeToPayPalTransaction || calculationError} />
            </div>
            :
            <div>
                <div style={{ marginTop: "48px", marginBottom: "48px" }}>
                    {!redirectRequest?.pos && <>
                        {(requireConvenienceFeeAcknowledgment && convenienceFeeAmount > 0) ?
                            <Form.Check style={{ marginBottom: "10px" }} label={convenienceFeeAcknowledgmentLabel} disabled={isSaving || !order} checked={agreeToConvenienceFee} onChange={(e) => setAgreeToConvenienceFee(e.target.checked && document?.forms[0].reportValidity())} /> : <></>
                        }

                        <Form.Check label={<a href={termsUrl} rel="noreferrer" target="_blank">{termsLabel}</a>} disabled={isSaving || !order} checked={agreeToTerms} onChange={(e) => setAgreeToTerms(e.target.checked && document?.forms[0].reportValidity())} />

                        {(order && order?.paymentType === PaymentType.ECheck) ?
                            <div className="form-check" style={{ marginTop: "10px" }}>
                                <input type="checkbox" className="form-check-input" id="ACHTransactionsAgreement" disabled={isSaving || !order} checked={agreeToACHTransaction} onChange={(e) => setAgreeToACHTransaction(e.target.checked && document?.forms[0].reportValidity())} />
                                <label className="form-check-label" htmlFor="ACHTransactionsAgreement">
                                    By checking here, I authorize Municipal Services Bureau to initiate a single ACH electronic debit to my account ending in {order.eCheckAccountNumber.substr(-4)} in the amount of {formatter.format(totalAmount)} on {moment().format('L')}. I agree that ACH transactions I authorize comply with all applicable laws. Once payment is authorized, there cannot be any changes or corrections. I understand that I may call during normal business hours if I have any questions. It is recommended that you <a onClick={() => window.print()} href="#">print</a> a copy of this authorization and maintain it for your records.
                                </label>
                            </div> : '' 
                        }
                    </>}
                </div>

                <Button className="btn-primary w-100" type="submit" disabled={disableSubmit()}
                    name="specialDisabled"
                    style={{
                        backgroundColor:checkStyleForDefault(submitButtonBackgroundColor, SUBMIT_BUTTON_BACKGROUND_COLOR),
                        borderColor:checkStyleForDefault(submitButtonBackgroundColor, SUBMIT_BUTTON_BACKGROUND_COLOR),
                        color:checkStyleForDefault(submitButtonFontColor, SUBMIT_BUTTON_FONT_COLOR),
                        fontSize:checkStyleForDefault(submitButtonFontSize, SUBMIT_BUTTON_FONT_SIZE),
                        fontStyle:checkStyleForDefault(submitButtonFontStyle, SUBMIT_BUTTON_FONT_STYLE),
                        fontWeight:checkStyleForDefault3(submitButtonFontWeight)
                    }}
                >{submitButtonLabel}</Button>
            </div>
        }

        <Button variant="link" className="btn btn-link" disabled={isSaving} style={{ marginTop: "8px", display: "block", textAlign: "center" }} onClick={handleCancel}>{cancelButtonLabel}</Button>

        { (processingError && errorMessage) || (calculationError && errorMessage && order?.cardType !== PaymentCardTypeEnum.Unknown) ?
            <div className="alert alert-danger mb-0 mt-2 d-flex align-items-start" role="alert" id="paymentErrorBox">
                <FontAwesomeIcon icon={faTimesCircle} size="sm" />
                <pre style={{ marginLeft: "10px", whiteSpace: "pre-wrap" }}>{errorMessage}</pre>
            </div>
            :
            <></>
        }

        { tylerEagleRedirectError ?
            <div className="alert alert-danger mb-0 mt-2 d-flex align-items-start" role="alert" id="paymentErrorTyler">
                <FontAwesomeIcon icon={faTimesCircle} size="sm" />System could not redirect back.
            </div>
            :
            <></>
        }
      </>
    )
}

const mapStateToProps = (state: IAppState) => {
    return {
        isSaving: state.orderManagement.isSaving,
        order: state.orderManagement.order,
        actionResult: state.orderManagement.actionResult,
        errorMessage: state.orderManagement.errorMessage,
        tylerEagleRequest: state.tylerEagleIntegration.tylerEagleRequest,
        tylerEagleCancel: state.tylerEagleIntegration.tylerEagleCancel,
        tylerEagleActionResult: state.tylerEagleIntegration.actionResult,
        redirectRequest: state.redirectIntegration.redirectRequest,
    };
};

export default connect(mapStateToProps)(PaymentSummary);