import axios from 'axios';
import { Dispatch } from 'react';
import store from "../store";
import { User, UserTypeEnum } from '../../models/User';

export const GET_USER_REQUEST = 'GET_USER_REQUEST';
export const GET_USER_SUCCESS = 'GET_USER_SUCCESS';
export const GET_USER_FAILURE = 'GET_USER_FAILURE';

export const SAVE_USER_REQUEST = 'SAVE_USER_REQUEST';
export const SAVE_USER_SUCCESS = 'SAVE_USER_SUCCESS';
export const SAVE_USER_FAILURE = 'SAVE_USER_FAILURE';

export const CLEAR_USER_REQUEST = 'CLEAR_USER_REQUEST';
export const CLEAR_USER_SUCCESS = 'CLEAR_USER_SUCCESS';

export const getUserAction = (msbId:string, actionToken:string) => (dispatch:Dispatch<any>) => {
    dispatch({
        type: GET_USER_REQUEST,
        payload: {
            actionToken
        }
    });

    let _url =  `/MSB_User/api/v1/Users/${msbId}`;

    axios.get(_url)
        .then(function (response) {
            let user  = response.data.user;
            dispatch({
                type: GET_USER_SUCCESS,
                payload: {
                    user,
                    actionToken
                }
            });
        })
        .catch(function (error) {
            dispatch({
                type: GET_USER_FAILURE,
                payload: {
                    error,
                    actionToken
                }
            });
        })
        .then(function () {
        }); 
};

export const saveUserAction = (user: User, actionToken:string) => (dispatch: Dispatch<any>) => {
    dispatch({
        type: SAVE_USER_REQUEST,
        payload: {
            actionToken
        }
    });

    if (!user.msbId) {
        let _url = "/MSB_User/api/v1/Users/withoutAzure";
        axios.post(_url, user)
            .then(function (response) {
                let user = response.data;
                dispatch({
                    type: SAVE_USER_SUCCESS,
                    payload: {
                        user,
                        actionToken
                    }
                });
            })
            .catch(function (error) {
                dispatch({
                    type: SAVE_USER_FAILURE,
                    payload: {
                        error,
                        actionToken
                    }
                });
            })
            .then(function () {
            }); 
    } else {
        let _url = `/MSB_User/api/v1/Users/${user.msbId}`;
        axios.put(_url, user)
        .then(function (response) {
            let user = response.data;
            dispatch({
                type: SAVE_USER_SUCCESS,
                payload: {
                    user,
                    actionToken
                }
            });
        })
        .catch(function (error) {
            dispatch({
                type: SAVE_USER_FAILURE,
                payload: {
                    error,
                    actionToken
                }
            });
        })
        .then(function () {
        }); 
    }
};
    
export const clearUserAction = (actionToken:string) => (dispatch: Dispatch<any>) => {
    dispatch({
        type: CLEAR_USER_REQUEST,
        payload: {
            actionToken
        }
    });
};




