import { Dispatch } from 'react';
import { Link, LinkStatus, LinkView } from '../../models/CheckoutLink';

export const CLEAR_LINK_REQUEST = 'CLEAR_LINK_REQUEST';
export const PROCESS_LINK_REQUEST = 'PROCESS_LINK_REQUEST';
export const STATUS_LINK_REQUEST = 'STATUS_LINK_REQUEST';
export const VIEW_LINK_REQUEST = 'VIEW_LINK_REQUEST';

export const clearLinkAction = () => (dispatch: Dispatch<any>) => {
    dispatch({
        type: CLEAR_LINK_REQUEST
    });
};

export const processLinkAction = (link: Link, actionToken?: string) => async (dispatch: Dispatch<any>) => {
    dispatch({
        type: PROCESS_LINK_REQUEST,
        payload: {
            link: link,
            actionToken: actionToken
        }
    });    
};

export const statusLinkAction = (status: LinkStatus, actionToken?: string) => async (dispatch: Dispatch<any>) => {
    dispatch({
        type: STATUS_LINK_REQUEST,
        payload: {
            status: status,
            actionToken: actionToken
        }
    });
};

export const viewLinkAction = (view: LinkView, actionToken?: string) => async (dispatch: Dispatch<any>) => {
    dispatch({
        type: VIEW_LINK_REQUEST,
        payload: {
            view: view,
            actionToken: actionToken
        }
    });
};
