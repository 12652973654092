import { CellPlugin } from '@react-page/editor';
import React from 'react';

// use a type here, not an interface
type Data = {
  title: string
}

const HorizontalRulePlugin: CellPlugin<Data> = {
  Renderer: ({ data }) => (
      <hr />
  ),
  id: 'horizontalRulePlugin',
  title: 'Horizontal Rule',
  description: 'A basic horizontal rule.',
  version: 1
};

export default HorizontalRulePlugin;