import { CellPlugin } from '@react-page/editor';
import React from 'react';
import { Col, Row } from 'react-bootstrap';

// use a type here, not an interface
type Data = {
  label: string,
  value: string
}

const getValue = (value: string) => {
  if (value && value.length > 0) {
    return value;
  } else {
    return "";
  }
}


const ApprovalCodePropertyPlugin: CellPlugin<Data> = {
  Renderer: ({ data }) => (
      <Row>
        <Col><strong>{data.label}</strong></Col>
        <Col>{getValue(data.value)}</Col>
      </Row>
  ),
  id: 'approvalCodePropertyPlugin',
  title: 'Approval Code Property',
  description: 'Approval Code Property.',
  version: 1,
  controls: {
    type: 'autoform',
    schema: {
      properties: {
        label: {
          type: 'string',
          default: 'Approval Code',
        },
      },
      required: ['label'],
    },
  },
};

export default ApprovalCodePropertyPlugin;