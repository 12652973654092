import React, { useState } from 'react';
import { Accordion, Button, Card, Form, ListGroup, Table } from 'react-bootstrap';
import FormHeader from '../../../../../../components/layout/FormHeader';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faCartPlus, faChevronDown, faTimes, faTimesCircle, faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { CurrencyFormatter, DateOnlyFormatter } from '../../../../../../components/Formatters';
import { formatter } from './NericSearchResults';
import CurrencyInput from '../../../../../../components/currency/CurrencyInput';
import { addToShoppingCart, updateOrderDetailsAction, updateShoppingCart } from '../../../../../../redux/actions/orderManagement';
import { connect, useDispatch } from 'react-redux';
import { Order } from '../../../../../../models/Order';
import _ from 'lodash';
import { IAppState } from '../../../../../../redux/storeTypes';
import StCharlesAddressForm from "./StCharlesAddressChange";
import { sendSuccessToastAction } from '../../../../../../redux/actions/toast';

interface IStCharlesDetails {
    selectedRow: any,
    onClose: any,
    shoppingCart?: any,
    order?: any,
    firstNameLabel: string,
    lastNameLabel: string,
    emailLabel: string,
    phoneNumberLabel: string,
    newAddressLabel: string,
    cityLabel: string,
    stateLabel: string,
    zipLabel: string,
    checkboxDisclaimer: string,
    addressChangeDisclaimer: string,
    firstNameRequired: boolean,
    lastNameRequired: boolean,
    emailRequired: boolean,
    phoneNumberRequired: boolean,
    newAddressRequired: boolean,
    cityRequired: boolean,
    stateRequired: boolean,
    zipCodeRequired: boolean,
    checkboxDisclaimerRequired: boolean,
    disableCartFeature: boolean,
    delinquentLabel: string,
    delinquentBackground: string,
    delinquentTextColor: string,
    delinquentTextAlign: string,
}

const StCharlesDetails = ({ selectedRow, onClose, order, shoppingCart, delinquentLabel, delinquentBackground, delinquentTextColor, delinquentTextAlign,
    firstNameLabel, lastNameLabel, emailLabel, phoneNumberLabel, newAddressLabel, cityLabel, stateLabel, zipLabel, checkboxDisclaimer, addressChangeDisclaimer,
    firstNameRequired, lastNameRequired, emailRequired, phoneNumberRequired, newAddressRequired, cityRequired, stateRequired, zipCodeRequired, 
    checkboxDisclaimerRequired, disableCartFeature }: IStCharlesDetails) => {

    const [amount, setAmount] = useState<any>();
    const dispatch = useDispatch();
    const [selectedOption, setSelectedOption] = useState<any>({ option: 'full' });
    const [cartErrorMessage, setCartErrorMessage] = useState<string>('');

    const allowPartials = selectedRow?.delinquentInformation?.length == 0
        || (selectedRow?.delinquentInformation?.length === 1 && selectedRow?.taxYear === selectedRow?.delinquentInformation[0]?.taxYear);

    const formattedDelinquents = [selectedRow]?.flatMap((billInfo: any) => {
        if (billInfo?.totalCalculatedAmountDue !== 0 && !billInfo?.delinquentInformation?.some((delinquent: any) => delinquent?.taxYear === billInfo?.taxYear)) {
            return [
                {
                    taxYear: billInfo?.taxYear,
                    billNumber: billInfo?.billNumber,
                    taxBillId: billInfo?.billInformation[0]?.taxBillId,
                    revObjId: billInfo?.revObjId,
                    taxAmount: billInfo?.billInformation[0]?.installments[0]?.tax,
                    penaltyAmount: billInfo?.billInformation[0]?.installments[0]?.penalty,
                    interestAmount: billInfo?.billInformation[0]?.installments[0]?.interest,
                    discountAmount: billInfo?.billInformation[0]?.installments[0]?.discount,
                    feeAmount: billInfo?.billInformation[0]?.installments[0]?.fee,
                },
                ...billInfo?.delinquentInformation,
            ]
        }
        else { return [...billInfo?.delinquentInformation] }
    })

    const delinquentBillInfo = formattedDelinquents.map(obj => ({ ...obj, id: selectedRow?.billInformation[0]?.id, billInformation: selectedRow?.billInformation }));

    const paymentInformation = [
        { heading: 'Status', value: selectedRow?.paymentStatus },
        { heading: 'Last Payment Date', value: selectedRow?.billInformation[0]?.paymentInformation[0]?.paymentDate },
        { heading: 'Amount Paid', value: CurrencyFormatter(selectedRow?.billInformation[0]?.paymentInformation[0]?.receiptAmount) },
        { heading: 'Payer Name', value: selectedRow?.billInformation[0]?.paymentInformation[0]?.payerName },
        { heading: 'Receipt Number', value: selectedRow?.billInformation[0]?.paymentInformation[0]?.receiptNumber }
    ]

    const propertyInformation = [
        { heading: 'Tax Year', value: selectedRow?.billInformation[0]?.taxYear },
        { heading: 'Account # / PIN', value: selectedRow?.ain },
        { heading: 'Description', value: selectedRow?.description },
        { heading: 'Geo CD', value: selectedRow?.geoCode },
        { heading: 'Situs Address', value: selectedRow?.propertyFullAddress }
    ]

    const billInformation = [
        { heading: 'Bill Number', value: selectedRow?.billInformation[0]?.billNumber },
        { heading: 'Base Taxes', value: CurrencyFormatter(selectedRow?.billInformation[0]?.installments[0]?.tax) },
        { heading: 'Penalty', value: CurrencyFormatter(selectedRow?.billInformation[0]?.installments[0]?.penalty) },
        { heading: 'Interest', value: CurrencyFormatter(selectedRow?.billInformation[0]?.installments[0]?.interest) },
        { heading: 'Discount', value: CurrencyFormatter(selectedRow?.billInformation[0]?.installments[0]?.discount) },
        { heading: 'Total Due', value: CurrencyFormatter(selectedRow?.totalCalculatedAmountDue) }
    ]

    const handleRadioSelect = (e: any) => {
        setCartErrorMessage('');
        setSelectedOption({ option: e.target.id });
        setAmount(null)
    }


    const handleSubmit = (e: any) => {
        e.preventDefault();
        const shoppingCartObject = allowPartials ? [
            {
                ownerName: selectedRow?.billInformation[0]?.cadOwner, ain: selectedRow?.ain, taxYear: selectedRow?.billInformation[0]?.taxYear,
                billNumber: selectedRow?.billInformation[0]?.billNumber, taxBillId: selectedRow?.billInformation[0]?.taxBillId,
                revObjId: selectedRow?.billInformation[0]?.revObjId, id: selectedRow?.billInformation[0]?.taxBillId,
                paymentAmount: selectedOption?.option === 'partial' ? _.toNumber(amount) : Number(selectedRow?.totalCalculatedAmountDue),
                isDeletable: true,
            }] :
            delinquentBillInfo?.map((delinquent: any, index: any) => {
                console.log(delinquentBillInfo?.length)
                return {
                    ownerName: delinquent?.billInformation[0]?.cadOwner, ain: selectedRow?.ain, taxYear: delinquent?.taxYear,
                    billNumber: delinquent?.billNumber, taxBillId: delinquent?.taxBillId, revObjId: delinquent?.revObjId,
                    paymentAmount: Number(delinquent?.discountAmount + delinquent?.feeAmount + delinquent?.interestAmount + delinquent?.penaltyAmount + delinquent?.taxAmount),
                    id: delinquent?.taxBillId, isDeletable: (index === 0)
                }
            })
        const OrderLinesObject = allowPartials ? [
            {
                accountNumber: selectedRow?.ain, itemReferenceNumber: selectedRow?.revObjId, quantity: 1,
                unitPrice: selectedOption?.option === 'partial' ? _.toNumber(amount) : Number(selectedRow?.totalCalculatedAmountDue),
                itemMetadata: {
                    uiReferenceId: selectedRow?.revObjId,
                    uiTaxBillId: selectedRow?.billInformation[0]?.taxBillId,
                    isStCharles: true,
                },
                itemName: `${selectedRow?.ain}-${selectedRow?.taxYear}-${selectedRow?.billInformation[0]?.billNumber}`,
            }]
            :
            delinquentBillInfo?.map((delinquent: any) => {
                return {
                    accountNumber: selectedRow?.ain, itemReferenceNumber: delinquent?.revObjId, quantity: 1,
                    unitPrice: Number(delinquent?.discountAmount + delinquent?.feeAmount + delinquent?.interestAmount + delinquent?.penaltyAmount + delinquent?.taxAmount),
                    itemMetadata: {
                        uiReferenceId: delinquent?.revObjId,
                        uiTaxBillId: delinquent?.taxBillId,
                        isStCharles: true,
                    },
                    itemName: `${selectedRow?.ain}-${delinquent?.taxYear}-${delinquent?.billNumber}`,
                }
            })

        if (order) {
            const updatedOrderLines = order?.orderLines.filter((orderLine: any) => !OrderLinesObject.some((selected: any) => orderLine?.itemMetadata?.uiReferenceId === selected?.itemMetadata?.uiReferenceId)).concat(OrderLinesObject);
            const updatedShoppingCart = shoppingCart.filter((cart: any) => !shoppingCartObject.some((selected: any) => cart?.id === selected?.id)).concat(shoppingCartObject);
            order.methodName = "Sale";
            order.orderLines = updatedOrderLines;
            dispatch(updateShoppingCart(updatedShoppingCart, 'shoppingCartActionToken'));
            dispatch(updateOrderDetailsAction(order, 'actionToken'));
        }
        else {
            let _order = new Order();
            _order.methodName = "Sale";
            _order.orderLines = OrderLinesObject;
            dispatch(updateShoppingCart(shoppingCartObject, 'shoppingCartActionToken'));
            dispatch(updateOrderDetailsAction(_order, 'actionToken'));
        }
        dispatch(sendSuccessToastAction('Added to cart'))
        onClose();
    }

    const printOnlyModal = () => {
        const element = document.getElementById("item-details-information");
        const body = document.body
        const originalChildren = Array.from(body.childNodes);
        const elementClone = element!.cloneNode(true) as HTMLElement;
        while (body.firstChild) body.firstChild.remove();
        body.append(elementClone);
        window.print();
        while (body.firstChild) (body.firstChild as HTMLElement).remove();
        for (const child of originalChildren) body.append(child);
    }

    const partialAmountChange = (value: any) => {
        if (value > selectedRow?.totalCalculatedAmountDue) {
            setCartErrorMessage('Amounts cannot be greater than total due')
            setAmount(null)
            return
        }
        setCartErrorMessage('')
        setAmount(value)
    }

    const inCart = shoppingCart?.some((cart: any) => cart?.taxBillId === selectedRow?.billInformation[0]?.taxBillId);

    return (
        <>
            <FormHeader title={'Item Details'} description="Below are the item details" />
            <Accordion className="stCharles-accordion">
                <Card>
                    <Accordion.Toggle as={Card.Header} eventKey="0" className="agreement_toggle_header" >
                        <FontAwesomeIcon icon={faChevronDown} />Item details
                    </Accordion.Toggle>
                    <Accordion.Collapse eventKey="0">
                        <Card.Body id="item-details-information">
                            <ListGroup variant="flush">
                                <ListGroup.Item>
                                    <div className='stCharles_container'>
                                        <div className='stCharles_sideBySide'>
                                            <h2>Owner Information</h2>
                                            <div className='column_aligned'>
                                                <span>{selectedRow?.ownerName}</span>
                                                <span>{selectedRow?.ownerFullAddress}</span>
                                                <span>{selectedRow?.ownerAddressCity}, {selectedRow?.ownerAddressState} {selectedRow?.ownerAddressPostalCode} </span>
                                            </div>
                                        </div>
                                        <div className='stCharles_sideBySide'>
                                            <h2>Payment Information</h2>
                                            {paymentInformation?.map((paymentInfo: any) => (
                                                <div className='stCharles_subHeading'>
                                                    <p>{paymentInfo?.heading}</p>
                                                    {paymentInfo?.heading == 'Status'
                                                        ?
                                                        <span className='item_value'>
                                                            <FontAwesomeIcon icon={paymentInfo?.value === 'Paid' ? faCheck : paymentInfo?.value === 'Pending' ? faInfoCircle : faTimes} className="btn-icon" style={{ color: `${paymentInfo?.value === 'Paid' ? '#52C41A' : paymentInfo?.value === 'Pending' ? '#f5b759' : '#F5222D'}` }} />
                                                            {paymentInfo?.value}
                                                        </span>
                                                        :
                                                        <span className='item_value'>{paymentInfo?.value}</span>
                                                    }
                                                </div>
                                            ))}
                                        </div>
                                        <div className='stCharles_sideBySide'>
                                            <div className='centered_span'>
                                                <span>Data as of <strong>{DateOnlyFormatter(selectedRow?.loadedAt, 'MM/DD/YYYY')}</strong></span>
                                            </div>
                                            <div className='centered_print d-print-none'>
                                                <Button variant="link" onClick={printOnlyModal}>Print</Button>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='stCharles_container'>
                                        <div className='stCharles_sideBySide'>
                                            <h2>Property Information</h2>
                                            {propertyInformation?.map((propertyInfo: any) => (
                                                <div className='stCharles_subHeading'>
                                                    <p>{propertyInfo?.heading}</p>
                                                    <span className='item_value'>{propertyInfo?.value}</span>
                                                </div>
                                            ))}
                                        </div>
                                        <div className='stCharles_sideBySide'>
                                            <h2>Bill Information</h2>
                                            {billInformation?.map((billInfo: any) => (
                                                <div className='stCharles_subHeading'>
                                                    <p>{billInfo?.heading}</p>
                                                    <span className='item_value'>{billInfo?.value}</span>
                                                </div>
                                            ))}
                                        </div>
                                        <div className='stCharles_sideBySide'>
                                            <h2>Classes</h2>
                                            <span>{selectedRow?.billInformation[0]?.classCodes[0]?.description}</span>
                                        </div>
                                    </div>
                                </ListGroup.Item>
                                <ListGroup.Item>
                                    <Table responsive bordered={true} className="table-payment-method">
                                        <thead>
                                            <tr>
                                                <th>{'Installment'}</th>
                                                <th>{'Due Date'}</th>
                                                <th>{'Tax'}</th>
                                                <th>{'Penalty'}</th>
                                                <th>{'Interest'}</th>
                                                <th>{'Fee'}</th>
                                                <th>{'Total'}</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {selectedRow?.billInformation[0].installments?.map((installment: any) => (
                                                <tr>
                                                    <td>{installment.number}</td>
                                                    <td>{DateOnlyFormatter(installment.dueDate, 'MM/DD/YYYY')}</td>
                                                    <td>{CurrencyFormatter(installment.tax)}</td>
                                                    <td>{CurrencyFormatter(installment.penalty)}</td>
                                                    <td>{CurrencyFormatter(installment.interest)}</td>
                                                    <td>{CurrencyFormatter(installment.fee)}</td>
                                                    <td>{CurrencyFormatter(installment.totalAmountDue)}</td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </Table>
                                </ListGroup.Item>
                                <ListGroup.Item style={{ marginTop: '20px' }}>
                                    <Table responsive bordered={true} className="table-payment-method">
                                        <thead>
                                            <tr>
                                                <th>{'Name'}</th>
                                                <th>{'Assessed'}</th>
                                                <th>{'Taxable'}</th>
                                                <th>{'Tax Rate'}</th>
                                                <th>{'Gross Tax'}</th>
                                                <th>{'Net Tax'}</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {selectedRow?.billInformation[0]?.taxes.map((taxItem: any) => (
                                                <tr>
                                                    <td>{taxItem.description}</td>
                                                    <td>{CurrencyFormatter(taxItem.assessedValue)}</td>
                                                    <td>{CurrencyFormatter(taxItem.taxableValue)}</td>
                                                    <td>{taxItem.netRate}</td>
                                                    <td>{CurrencyFormatter(taxItem.grossTaxAmount)}</td>
                                                    <td>{CurrencyFormatter(taxItem.netTaxAmount)}</td>
                                                </tr>
                                            ))}
                                            <tr style={{ fontWeight: 'bold' }}>
                                                <td style={{ textAlign: 'right' }}>Total:</td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td>{CurrencyFormatter(_.sumBy(selectedRow?.billInformation[0]?.taxes, (taxInfo: any) => taxInfo?.grossTaxAmount))}</td>
                                                <td>{CurrencyFormatter(_.sumBy(selectedRow?.billInformation[0]?.taxes, (taxInfo: any) => taxInfo?.netTaxAmount))}</td>
                                            </tr>
                                        </tbody>
                                    </Table>
                                </ListGroup.Item>
                            </ListGroup>
                        </Card.Body>
                    </Accordion.Collapse>
                </Card>
            </Accordion>
            {selectedRow?.paymentStatus === 'Unpaid' && !disableCartFeature &&

                <div>
                    {!inCart ?
                        <Accordion className="stCharles-accordion d-print-none" style={{ marginTop: '-1px' }} defaultActiveKey='0'>
                            <Card>
                                <Accordion.Toggle as={Card.Header} eventKey="0" className="agreement_toggle_header">
                                    <FontAwesomeIcon icon={faChevronDown} />Make a payment
                                </Accordion.Toggle>
                                <Accordion.Collapse eventKey="0">
                                    <Card.Body>
                                        {allowPartials ?
                                            <div className='stCharles_cart'>
                                                <div className='box'>
                                                    <label>
                                                        <input
                                                            type='radio'
                                                            name='paymentOption'
                                                            className='neric_input'
                                                            id='full'
                                                            onChange={handleRadioSelect}
                                                            checked={selectedOption?.option === 'full'}
                                                        />
                                                        Total Due
                                                    </label>
                                                    <span className='box_span'>{formatter.format(Number(selectedRow?.totalCalculatedAmountDue))}</span>
                                                </div>
                                                <br />
                                                <div className='box'>
                                                    <label>
                                                        <input
                                                            type='radio'
                                                            name='paymentOption'
                                                            className='neric_input'
                                                            id='partial'
                                                            onChange={(e) => setSelectedOption({ option: `${e.target.id}` })}
                                                            checked={selectedOption?.option === 'partial'}
                                                        />Payment Amount
                                                    </label>
                                                    <Form.Group controlId="amount" className='box_span'>
                                                        <CurrencyInput
                                                            required
                                                            className="form-control"
                                                            id="summaryAmount"
                                                            name="summaryAmount"
                                                            placeholder="$ 0.00"
                                                            maxLength={10}
                                                            decimalsLimit={2}
                                                            prefix="$ "
                                                            disabled={selectedOption?.option != 'partial'}
                                                            value={amount}
                                                            onValueChange={(value: any) => partialAmountChange(value)}
                                                        />
                                                    </Form.Group>
                                                    {cartErrorMessage && selectedOption?.option === 'partial' &&
                                                        <div role="alert" id="paymentErrorBox" style={{ color: 'red' }}>
                                                            <FontAwesomeIcon icon={faTimesCircle} color='red' /> {cartErrorMessage}
                                                        </div>
                                                    }
                                                </div>
                                            </div>
                                            :
                                            <div className='stCharles_delinquent'>
                                                <div className='centered_span'  style={{ justifyContent: delinquentTextAlign, backgroundColor: delinquentBackground}}>
                                                    <span style={{color: delinquentTextColor}}><strong>{delinquentLabel}</strong></span>
                                                </div>
                                                <Table responsive bordered={true} className="table-payment-method">
                                                    <thead>
                                                        <tr>
                                                            <th>{'Name'}</th>
                                                            <th>{'PIN'}</th>
                                                            <th>{'Tax Year'}</th>
                                                            <th>{'Bill Number'}</th>
                                                            <th>{'Tax Bill Id'}</th>
                                                            <th>{'Rev Obj Id'}</th>
                                                            <th>{'Amount'}</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {delinquentBillInfo?.map((info: any) => (
                                                            <tr>
                                                                <td>{selectedRow?.ownerName}</td>
                                                                <td>{selectedRow?.pin}</td>
                                                                <td>{info?.taxYear}</td>
                                                                <td>{info?.billNumber}</td>
                                                                <td>{info?.taxBillId}</td>
                                                                <td>{info?.revObjId}</td>
                                                                <td>{CurrencyFormatter(info?.taxAmount + info?.feeAmount + info?.discountAmount + info?.penaltyAmount + info?.interestAmount)}</td>
                                                            </tr>
                                                        ))}
                                                        <tr style={{ fontWeight: 'bold' }}>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td>Total:</td>
                                                            <td>{CurrencyFormatter(_.sumBy(delinquentBillInfo, (info: any) => (info?.taxAmount + info?.feeAmount + info?.discountAmount + info?.penaltyAmount + info?.interestAmount)))}</td>
                                                        </tr>
                                                    </tbody>
                                                </Table>
                                            </div>
                                        }
                                        <div style={{ float: 'right' }}>
                                            <Form.Group style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '8px' }}>
                                                {
                                                    <Button type="submit" disabled={selectedOption?.option == 'partial' && (amount == undefined || amount == 0)} onClick={handleSubmit}>Add to cart</Button>
                                                }
                                            </Form.Group>
                                        </div>
                                    </Card.Body>
                                </Accordion.Collapse>
                            </Card>
                        </Accordion>
                        :
                        <div className="stCharles-accordion accordion">
                            <div className='card'>
                                <div className='agreement_toggle_header card-header' style={{ justifyContent: 'center' }}>
                                    <FontAwesomeIcon color='green' icon={faCartPlus} />Added to cart
                                </div>
                            </div>
                        </div>
                    }
                </div>
            }
            <Accordion className="stCharles-accordion d-print-none">
                <Card>
                    <Accordion.Toggle as={Card.Header} eventKey="0" className="agreement_toggle_header" >
                        <FontAwesomeIcon icon={faChevronDown} />Address Change
                    </Accordion.Toggle>
                    <Accordion.Collapse eventKey="0">
                        <Card.Body>
                            <ListGroup variant="flush">
                                <ListGroup.Item>
                                    <StCharlesAddressForm selectedRow={selectedRow} addressChangeDisclaimer={addressChangeDisclaimer}
                                        firstNameLabel={firstNameLabel} lastNameLabel={lastNameLabel} emailLabel={emailLabel} phoneNumberLabel={phoneNumberLabel}
                                        newAddressLabel={newAddressLabel} cityLabel={cityLabel} stateLabel={stateLabel} zipLabel={zipLabel} checkboxDisclaimer={checkboxDisclaimer}
                                        firstNameRequired={firstNameRequired} lastNameRequired={lastNameRequired} emailRequired={emailRequired} phoneNumberRequired={phoneNumberRequired}
                                        newAddressRequired={newAddressRequired} cityRequired={cityRequired} stateRequired={stateRequired} zipCodeRequired={zipCodeRequired} checkboxDisclaimerRequired={checkboxDisclaimerRequired}
                                        onClose={onClose}
                                    />
                                </ListGroup.Item>
                            </ListGroup>
                        </Card.Body>
                    </Accordion.Collapse>
                </Card>
            </Accordion>
            <div style={{ float: 'right' }}>
                <Button variant="outline-secondary" onClick={onClose}>Close</Button>
            </div>
        </>
    )
}

const mapStateToProps = (state: IAppState) => {
    return {
        isFetching: state.account.isFetching,
        shoppingCart: state.orderManagement.shoppingCart,
        order: state.orderManagement.order,
    };
};

export default connect(mapStateToProps)(StCharlesDetails);

