import moment from "moment";
import { Department } from "../../../../../../models/Client";
import { Order, PaymentType } from "../../../../../../models/Order";
import { Payload, ScheduledPortionType, PreFlightEmailModel } from "../../../../../../models/Schedule"
import { User } from "../../../../../../models/User";

interface payloadProps {
    paymentOption: any,
    paymentType: PaymentType,
    calculatedConvenienceFee: Order,
    userDetails: User,
    emailAddress: string,
}

interface emailProps {
    accountNumber: string,
    paymentType: PaymentType,
    paymentOption: ScheduledPortionType | string,
    paymentAmount: number,
    userDetails: User,
    paymentDate: string,
    paymentReminderDate: any,
    clientName: string,
    departmentDetails: Department,
}

export const handlePayLoad = (payloadDetails: payloadProps) => {

    let payload = new Payload();
    payload.paymentType = payloadDetails.paymentType;
    payload.initiatedBy = "System"
    payload.methodName = payloadDetails.calculatedConvenienceFee.methodName;
    payload.amount = payloadDetails.calculatedConvenienceFee.amount;
    payload.convenienceFee = payloadDetails.calculatedConvenienceFee.convenienceFee;
    payload.totalAmount = payloadDetails.calculatedConvenienceFee.totalAmount;
    payload.shouldTokenizeCard = false;
    payload.laneId = payloadDetails.calculatedConvenienceFee.laneId;
    payload.orderLines = payloadDetails.calculatedConvenienceFee.orderLines;
    payload.isCardPresent = payloadDetails.calculatedConvenienceFee.isCardPresent;
    payload.isDebitCard = payloadDetails.calculatedConvenienceFee.isDebitCard!;
    payload.isMailOrTelephoneOrder = payloadDetails.calculatedConvenienceFee.isMailOrTelephoneOrder!;
    payload.emailAddress = payloadDetails.emailAddress.length!= 0 ? payloadDetails.emailAddress : payloadDetails.userDetails.email;
    payload.metadata = { "PaymentPortion": ScheduledPortionType[payloadDetails.paymentOption].toString() }

    if (payloadDetails.paymentType == PaymentType.CreditCard) {
        const cardInfo = payloadDetails.userDetails.cards && payloadDetails.userDetails.cards[0]!;
        payload.isTokenizedPayment = true;
        payload.cardType = cardInfo?.vantivCardType!;
        payload.expirationDate = cardInfo?.expirationDate
        payload.token = { tokenID: cardInfo?.token, tokenProvider: "2" }
        payload.nameOnCard = cardInfo?.nameOnCard?.length!= 0 ? cardInfo?.nameOnCard : (payloadDetails?.userDetails?.firstName + ' '+ payloadDetails?.userDetails?.lastName);
        payload.addressLine1 = cardInfo?.addressLine1;
        payload.addressLine2 = cardInfo?.addressLine2;
        payload.city = cardInfo?.city;
        payload.state = cardInfo?.state;
        payload.zip = cardInfo?.zipCode;
    } else {
        const bankInfo = payloadDetails.userDetails.bankAccounts && payloadDetails.userDetails.bankAccounts[0]!;
        payload.cardType = 'ElectronicCheck'
        payload.nameOnCard = bankInfo?.accountHolderName?.length!= 0 ? bankInfo?.accountHolderName:(payloadDetails?.userDetails?.firstName +' '+ payloadDetails?.userDetails?.lastName);
        payload.eCheckAccountNumber = bankInfo?.accountNumber!;
        payload.eCheckRoutingNumber = bankInfo?.routingNumber!;
        payload.addressLine1 = bankInfo?.addressLine1;
        payload.addressLine2 = bankInfo?.addressLine2;
        payload.city = bankInfo?.city;
        payload.state = bankInfo?.state;
        payload.zip = bankInfo?.zipCode;
    }

    return payload;
}


export const handleEmail = (emailDetails: emailProps) => {

    let emailModel = new PreFlightEmailModel();

    emailModel.clientName = emailDetails.clientName;
    emailModel.departmentName = emailDetails.departmentDetails.name;
    emailModel.scheduledPortionType = emailDetails.paymentOption;
    emailModel.paymentAmount = emailDetails.paymentAmount;
    emailModel.scheduledPaymentDate = moment(emailDetails.paymentDate).format('YYYY-MM-DD');

    if (emailDetails.paymentType == PaymentType.CreditCard) {
        const cardInfo = emailDetails.userDetails.cards && emailDetails.userDetails.cards[0]!;
        emailModel.accountNumber = emailDetails?.accountNumber;
        emailModel.paymentMethod = `${cardInfo?.vantivCardType!} ${cardInfo?.lastFourOnCard}`;

    } else {
        const bankInfo = emailDetails.userDetails.bankAccounts && emailDetails.userDetails.bankAccounts[0]!;
        const accountNumber = bankInfo?.accountNumber?.slice(bankInfo?.accountNumber?.length - 4)!
        emailModel.accountNumber = emailDetails?.accountNumber;
        emailModel.paymentMethod = `${bankInfo?.bankAccountType!} ${accountNumber}`;
    }
    return emailModel
}




