import { Dispatch } from 'react';
import { Card } from '../../models/User';

export const SAVE_CARD = 'SAVE_CARD';

export const saveCardDetails = (card:Card, actionToken?:string) => (dispatch:Dispatch<any>) => {
    dispatch({
        type: SAVE_CARD,
        payload: {
            card,
            actionToken
        }
    });
};
