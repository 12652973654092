import React from "react";
import { Col, Form, Row } from "react-bootstrap";
import { connect } from "react-redux";
import RequiredIcon from "../components/RequiredIcon";
import { IAppState } from "../redux/storeTypes";
import InputMask from "react-input-mask";
import { StateDropdown } from "../components/StateDropdown";

const BillingAddress = () => {

    return (
        <Form>
            <h2>Billing details</h2>
            <p>Please enter the billing details below to proceed with your payment.</p>
            <Row>
                <Form.Group as={Col} controlId="addressLine1" >
                    <Form.Label><RequiredIcon />Address line 1</Form.Label>
                    <Form.Control required type="input" maxLength={40} />
                    <Form.Control.Feedback type="invalid">Address line 1 required.</Form.Control.Feedback>
                </Form.Group>
            </Row>
            <Row>
                <Form.Group as={Col} controlId="addressLine2" >
                    <Form.Label>Address line 2</Form.Label>
                    <Form.Control type="input" maxLength={40} />
                </Form.Group>
            </Row>
            <Row>
                <Form.Group as={Col} controlId="country" >
                    <Form.Label><RequiredIcon />Country</Form.Label>
                    <Form.Control required type="input" maxLength={28} />
                    <Form.Control.Feedback type="invalid">Country required.</Form.Control.Feedback>
                </Form.Group>
            </Row>
            <Row>
                <Form.Group as={Col} controlId="city" >
                    <Form.Label><RequiredIcon />City</Form.Label>
                    <Form.Control required type="input" maxLength={28} />
                    <Form.Control.Feedback type="invalid">City required.</Form.Control.Feedback>
                </Form.Group>
            </Row>
            <Row>
                <Col>
                    <Form.Group as={Col} controlId="state" >
                        <Form.Label><RequiredIcon />State</Form.Label>
                        <StateDropdown required></StateDropdown>
                        <Form.Control.Feedback type="invalid">State required.</Form.Control.Feedback>
                    </Form.Group>
                </Col>
                <Col>
                    <Form.Group as={Col} controlId="zipCode" >
                        <Form.Label><RequiredIcon />Zip code</Form.Label>
                        <InputMask id="zipCode" name="zipCode" required type="input" pattern="[0-9]{5}" mask="99999" className="form-control" />
                        <Form.Control.Feedback type="invalid">Zip code required.</Form.Control.Feedback>
                    </Form.Group>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Form.Group controlId="phoneNumber">
                        <Form.Label>Phone number</Form.Label>
                        <Form.Control type="input" placeholder="Enter phone number" />
                    </Form.Group>
                </Col>
                <Col>
                    <Form.Group controlId="email">
                        <Form.Label>Email address</Form.Label>
                        <Form.Control type="input" placeholder="Enter email address" />
                    </Form.Group>
                </Col>
            </Row>
        </Form>
    )
};

const mapStateToProps = (state: IAppState) => {
    return {
    };
};

export default connect(mapStateToProps)(BillingAddress);