import { Row, Col, Modal, Form, Button, Spinner, Popover, OverlayTrigger, Table as BootstrapTable } from "react-bootstrap"
import { useEffect, useState } from "react";
import { IAppState } from '../../../../../../redux/storeTypes';
import { connect, useDispatch } from "react-redux";
import Table from '../../../../../../components/Table';
import FormHeader from "../../../../../../components/layout/FormHeader";
import { CurrencyFormatter, DateWithoutUTCFormatter } from "../../../../../../components/Formatters";
import _ from 'lodash';
import { updateOrderDetailsAction, updateShoppingCart } from "../../../../../../redux/actions/orderManagement";
import { sendSuccessToastAction } from "../../../../../../redux/actions/toast";
import { ItemStatusType, Order } from "../../../../../../models/Order";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faTimes, faInfoCircle, faCartPlus  } from "@fortawesome/free-solid-svg-icons";

interface IPikeSearchResults {
    nameLabel: string,
    accountNumberLabel: string,
    taxYearLabel: string,
    propertyTypeLabel: string,
    addressLabel: string,
    dueDateLabel: string,
    balanceDueLabel: string,
    statusLabel: string,
    viewDetailsLabel: string,
    delinquentLabel: string,
    delinquentBackgroundColor: string,
    delinquentTextColor: string,
    delinquentTextAlign: string,
    observesWeekends: boolean,
    durationOfPendingHours: number,
    margin: string,
    padding: string,
    headerBackgroundColor: string,
    headerPadding: string,
    itemDetails: any,
    isFetching: boolean,
    order?: any,
    shoppingCart?: any
}

export const NAME_LABEL = 'Name';
export const ACCOUNT_NUMBER_LABEL = 'Account Number';
export const TAX_YEAR_LABEL = 'Tax Year';
export const PROPERTY_TYPE_LABEL = 'Property Type';
export const ADDRESS_LABEL = 'Address';
export const BALANCE_DUE_LABEL = 'Balance Due';
export const DUE_DATE_LABEL = 'Due Date';
export const STATUS_LABEL = 'Unpaid';
export const VIEW_DETAILS_LABEL = 'View Details';
export const DELINQUENT_LABEL = 'All the following records must be paid';
export const DELINQUENT_BACKGROUND_COLOR = '#f0f0f0';
export const DELINQUENT_TEXT_ALIGN = 'center';
export const DELINQUENT_TEXT_COLOR = 'black';
export const OBSERVES_WEEKENDS = false;
export const DURATION_OF_PENDING_HOURS = 24;
export const MARGIN = '0px';
export const PADDING = '0px';
export const HEADER_BACKGROUND_COLOR = 'rgb(250, 250, 250)';
export const HEADER_PADDING = '0';



export const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
});

const PikeSearchResults = ({ nameLabel, accountNumberLabel, taxYearLabel, propertyTypeLabel,
    addressLabel, balanceDueLabel, dueDateLabel,statusLabel, viewDetailsLabel,
    delinquentLabel, delinquentBackgroundColor, delinquentTextColor, delinquentTextAlign, durationOfPendingHours, observesWeekends, margin, padding,
    headerBackgroundColor, headerPadding, itemDetails, isFetching, order, shoppingCart }: IPikeSearchResults) => {

    if (!nameLabel) { nameLabel = NAME_LABEL }
    if (!accountNumberLabel) { accountNumberLabel = ACCOUNT_NUMBER_LABEL }
    if (!taxYearLabel) { taxYearLabel = TAX_YEAR_LABEL }
    if (!propertyTypeLabel) { propertyTypeLabel = PROPERTY_TYPE_LABEL }
    if (!addressLabel) { addressLabel = ADDRESS_LABEL }
    if (!statusLabel) { statusLabel = STATUS_LABEL }
    if (!balanceDueLabel) { balanceDueLabel = BALANCE_DUE_LABEL }
    if (!dueDateLabel) { dueDateLabel = DUE_DATE_LABEL }
    if (!viewDetailsLabel) { viewDetailsLabel = VIEW_DETAILS_LABEL }
    if (!delinquentLabel) { delinquentLabel = DELINQUENT_LABEL }
    if (!delinquentBackgroundColor) { delinquentBackgroundColor = DELINQUENT_BACKGROUND_COLOR }
    if (!delinquentTextAlign) { delinquentTextAlign = DELINQUENT_TEXT_ALIGN }
    if (!delinquentTextColor) { delinquentTextColor = DELINQUENT_TEXT_COLOR }
    if (!durationOfPendingHours) { durationOfPendingHours = DURATION_OF_PENDING_HOURS }
    if (!observesWeekends) { observesWeekends = OBSERVES_WEEKENDS }
    if (!margin) { margin = MARGIN }
    if (!padding) { padding = PADDING }
    if (!headerBackgroundColor) { headerBackgroundColor = HEADER_BACKGROUND_COLOR }
    if (!headerPadding) { headerPadding = HEADER_PADDING }

    const paymentOptionsFormatter = (cell: any, row: any) => {
        return (
            <>
                <span><a onClick={() => { setSelectedRow(row); setShowModal(true) }}>{viewDetailsLabel}</a></span>
            </>
        )
    }

    const onHoldOverlay = () => {
        return (
            <OverlayTrigger trigger="click" rootClose placement="top" overlay={(
                <Popover id="popover-positioned-top">
                    <Popover.Content>
                        A payment for this account is currently pending. Please allow 24-48 hours for the payment to post before attempting a payment on this bill
                    </Popover.Content>
                </Popover>
            )}>
                <FontAwesomeIcon icon={faInfoCircle} className="btn-icon" style={{ color: "#6495ed", cursor: 'pointer'}} />
            </OverlayTrigger>
        )
    }

    const checkStyleForDefault = (style: string, defaultStyle: string) => {
        return style && style !== '' ? style : defaultStyle;
    }

    const checkOnHoldItems = (selectedRowItems:any) => {
        const getPendingItems = itemDetails?.filter((item:any) => item?.itemPaymentStatus == ItemStatusType.Pending).map((item:any) => item?.externalItemReference);
        const delinquentIds = selectedRowItems?.metaData?.Delinquents?.map((delinquent:any) => delinquent?.ExternalReferenceNumber)
        const isHold = delinquentIds?.some((id:any) => getPendingItems?.includes(id));
        return isHold
    }
    
    const statusTypeConverter = (prop: any, row: any) => {
        if (itemDetails) {
            if (prop == ItemStatusType.Pending || prop == ItemStatusType.Paid || prop == ItemStatusType[1] || prop == ItemStatusType[3]) {
                const statusType = typeof (prop);
                const value = statusType === 'number' ? ItemStatusType[prop] : prop;
                return value
            }
            else {
                const getPendingItems = itemDetails?.filter((item: any) => item?.itemPaymentStatus == ItemStatusType.Pending).map((item: any) => item?.externalItemReference);
                const delinquentIds = row?.metaData?.Delinquents?.map((delinquent: any) => delinquent?.ExternalReferenceNumber)
                const isHold = delinquentIds?.some((id: any) => getPendingItems?.includes(id));
                return isHold ? 'On Hold' : 'Unpaid'
            }
        }
    }
    
    const statusFormatter = (cell: any, row: any) => {
        const statusValue = statusTypeConverter(cell, row);
        let color: string = "#F5222D";
        if (statusValue === 'Paid') { color = "#52C41A" }
        if (statusValue === 'Pending') { color = "#f5b759" }
        return (
            <span style={{ display: 'flex', alignItems: 'center' }}>
                {statusValue === 'On Hold' ?
                    onHoldOverlay()
                    :
                    <FontAwesomeIcon icon={statusValue === 'Paid' ? faCheck : statusValue === 'Pending' ? faInfoCircle : faTimes} className="btn-icon" style={{ color: `${color}` }} />
                }
                {statusValue}
            </span>
        )
    }

    const initialColumns = [{
        dataField: 'metaData.Name',
        text: nameLabel,
        editable: false,
        headerStyle: {
            backgroundColor: checkStyleForDefault(headerBackgroundColor, HEADER_BACKGROUND_COLOR)
        },
        sort: true,
    }, {
        dataField: 'metaData.Account',
        text: accountNumberLabel,
        headerStyle: {
            backgroundColor: checkStyleForDefault(headerBackgroundColor, HEADER_BACKGROUND_COLOR)
        },
        editable: false,

    }, {
        dataField: 'metaData.TaxYear',
        text: taxYearLabel,
        headerStyle: {
            backgroundColor: checkStyleForDefault(headerBackgroundColor, HEADER_BACKGROUND_COLOR)
        },
        editable: false,
        sort: true,
    }, {
        dataField: 'metaData.TaxType',
        text: propertyTypeLabel,
        headerStyle: {
            backgroundColor: checkStyleForDefault(headerBackgroundColor, HEADER_BACKGROUND_COLOR)
        },
        editable: false,

    }, {
        dataField: 'metaData.Address1',
        text: addressLabel,
        headerStyle: {
            backgroundColor: checkStyleForDefault(headerBackgroundColor, HEADER_BACKGROUND_COLOR)
        },
        editable: false,

    }, {
        dataField: 'metaData.TotalDue',
        text: balanceDueLabel,
        headerStyle: {
            backgroundColor: checkStyleForDefault(headerBackgroundColor, HEADER_BACKGROUND_COLOR)
        },
        editable: false,
        formatter: CurrencyFormatter,
    }, {
        dataField: 'metaData.DueDate',
        text: dueDateLabel,
        headerStyle: {
            backgroundColor: checkStyleForDefault(headerBackgroundColor, HEADER_BACKGROUND_COLOR)
        },
        editable: false,
        formatter: DateWithoutUTCFormatter,

    },{
        dataField: 'itemPaymentStatus',
        text: statusLabel,
        headerStyle: {
            backgroundColor: checkStyleForDefault(headerBackgroundColor, HEADER_BACKGROUND_COLOR)
        },
        formatter: statusFormatter,
        editable: false,
    }, {
        dataField: 'action',
        text: viewDetailsLabel,
        headerStyle: {
            backgroundColor: checkStyleForDefault(headerBackgroundColor, HEADER_BACKGROUND_COLOR)
        },
        formatter: paymentOptionsFormatter,
        editable: false,
    }];

    const defaultSorted = [{
        dataField: 'metaData.TaxYear',
        order: 'asc'
    }]

    const dispatch = useDispatch();
    const [columns, setColumns] = useState<any>(initialColumns);
    const [data, setData] = useState<any>([]);
    const [selectedRow, setSelectedRow] = useState<any>([]);
    const [showModal, setShowModal] = useState<boolean>(false);


    const mappingDetails = [
        { heading: accountNumberLabel, value: selectedRow?.metaData?.Account },
        { heading: nameLabel, value: selectedRow?.metaData?.Name },
        { heading: taxYearLabel, value: selectedRow?.metaData?.TaxYear },
        { heading: propertyTypeLabel, value: selectedRow?.metaData?.TaxType },
        { heading: addressLabel, value: selectedRow?.metaData?.Address1 },
        { heading: 'Due Amount', value: CurrencyFormatter(selectedRow?.metaData?.AmountDue) },
        { heading: 'Late Fees', value: CurrencyFormatter(selectedRow?.metaData?.LateFees) },
        { heading: balanceDueLabel, value: CurrencyFormatter(selectedRow?.metaData?.TotalDue) },
        { heading: dueDateLabel, value: DateWithoutUTCFormatter(selectedRow?.metaData?.DueDate) },
    ]

    const delinquents = selectedRow?.metaData?.Delinquents;
    const delinquentItems = delinquents?.filter((item: any) => item?.TaxYear <= selectedRow?.metaData?.TaxYear)

    const isDelinquent = delinquentItems?.length > 1;

    const inCart = shoppingCart?.some((cart: any) => cart?.id === selectedRow?.metaData?.ExternalReferenceNumber);


    const handleSubmit = (e: any) => {
        e.preventDefault();
        const shoppingCartObject = !isDelinquent ? [
            {
                name: selectedRow?.metaData?.Name, account: selectedRow?.metaData?.Account, taxYear: selectedRow?.metaData?.TaxYear,
                propertyType: selectedRow?.metaData?.TaxType, address: selectedRow?.metaData?.Address1,
                paymentAmount: selectedRow?.metaData?.TotalDue, dueDate: selectedRow?.metaData?.DueDate, id: selectedRow?.metaData?.ExternalReferenceNumber,
                isDeletable: true,
            }] :
            delinquentItems?.sort((a: any, b: any) => b.TaxYear - a.TaxYear)?.map((delinquent: any, index: any) => {
                return {
                    name: delinquent?.Name, account: delinquent?.Account, taxYear: delinquent?.TaxYear,
                    propertyType: delinquent?.TaxType, address: delinquent?.Address1,
                    paymentAmount: delinquent?.TotalDue, dueDate: delinquent?.DueDate, id: delinquent?.ExternalReferenceNumber,
                    isDeletable: (index === 0)
                }
            })
        const OrderLinesObject = !isDelinquent ? [
            {
                itemReferenceNumber: selectedRow?.metaData?.Account,
                quantity: 1,
                unitPrice: selectedRow?.metaData?.TotalDue,
                itemMetadata: {
                    uiUniqueId: selectedRow?.metaData?.ExternalReferenceNumber,
                    isPike: true,
                    itemMsbid: selectedRow?.msbId,
                    observesWeekends: observesWeekends,
                    durationOfPendingHours: durationOfPendingHours
                },
                itemName: selectedRow?.metaData?.TaxYear,
            }]
            :
            delinquentItems?.map((delinquent: any) => {
                return {
                    itemReferenceNumber: delinquent?.Account,
                    quantity: 1,
                    unitPrice: delinquent?.TotalDue,
                    itemMetadata: {
                        uiUniqueId: delinquent?.ExternalReferenceNumber,
                        isPike: true,
                        itemMsbid: selectedRow?.msbId,
                        observesWeekends: observesWeekends,
                        durationOfPendingHours: durationOfPendingHours
                    },
                    itemName: delinquent?.TaxYear,
                }
            })

        if (order) {
            const updatedOrderLines = order?.orderLines.filter((orderLine: any) => !OrderLinesObject.some((selected: any) => orderLine?.itemMetadata?.uiUniqueId === selected?.itemMetadata?.uiUniqueId)).concat(OrderLinesObject);
            const updatedShoppingCart = shoppingCart.filter((cart: any) => !shoppingCartObject.some((selected: any) => cart?.id === selected?.id)).concat(shoppingCartObject);
            order.methodName = "Sale";
            order.orderLines = updatedOrderLines;
            dispatch(updateShoppingCart(updatedShoppingCart, 'shoppingCartActionToken'));
            dispatch(updateOrderDetailsAction(order, 'actionToken'));
        }
        else {
            let _order = new Order();
            _order.methodName = "Sale";
            _order.orderLines = OrderLinesObject;
            dispatch(updateShoppingCart(shoppingCartObject, 'shoppingCartActionToken'));
            dispatch(updateOrderDetailsAction(_order, 'actionToken'));
        }
        dispatch(sendSuccessToastAction('Added to cart'))
        setShowModal(false)
    }

    useEffect(() => {
        if (itemDetails) {
            setData(itemDetails);
            setColumns(initialColumns);
        }
    }, [itemDetails])

    const renderTable = () => {
        if (isFetching) {
            return (<div style={{ textAlign: 'center' }} ><Spinner animation="border" /> </div>);
        }
        else if (data.length === 0) {
            return (<div>Currently there are no records to display. Please do a search to find the record you are looking for.</div>)
        } else {
            return (
                <>
                    <Table
                        keyField="msbId"
                        data={data}
                        remote={false}
                        columns={columns}
                        sizePerPage={10}
                        defaultSorted={defaultSorted}
                    />
                </>);
        }
    };

    return (

        <div style={{ margin: margin, padding: padding }}>
            <Row><Col>{renderTable()}</Col></Row>
            <Modal show={showModal} size={'xl'} backdrop="static" onHide={() => setShowModal(false)}>
                <Modal.Header closeButton />
                <Modal.Body>
                    <FormHeader title="Item Details" description={"Below are the selected item details"} />
                    <Form.Group controlId="printTerminal" className='pikeDisplayResults'>
                        <>
                            {Object.entries(mappingDetails).map(
                                ([index, selectedArray]: any) => {
                                    return (
                                        <div key={index} className="neric_details_row">
                                            <h3>{selectedArray.heading}</h3>
                                            <span>{selectedArray.value}</span>
                                        </div>
                                    )
                                }
                            )}
                        </>
                    </Form.Group>
                    <hr />
                    <div>
                        {!inCart ?
                            <Form.Group controlId="paymentOptions">
                                {!isDelinquent ?
                                    <div style={{ textAlign: 'center', fontSize: 'large'}}>
                                        <span><strong>Total Due: </strong>${selectedRow?.metaData?.TotalDue}</span>
                                    </div>
                                    :
                                    <div className='stCharles_delinquent'>
                                        <div className='centered_span' style={{ justifyContent: delinquentTextAlign, backgroundColor: delinquentBackgroundColor }}>
                                            <span style={{ color: delinquentTextColor }}><strong>{delinquentLabel}</strong></span>
                                        </div>
                                        <BootstrapTable responsive bordered={true} className="table-payment-method">
                                            <thead>
                                                <tr>
                                                    <th>{nameLabel}</th>
                                                    <th>{accountNumberLabel}</th>
                                                    <th>{taxYearLabel}</th>
                                                    <th>{propertyTypeLabel}</th>
                                                    <th>{addressLabel}</th>
                                                    <th>{dueDateLabel}</th>
                                                    <th>{balanceDueLabel}</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {delinquentItems?.map((info: any) => (
                                                    <tr>
                                                        <td>{info?.Name}</td>
                                                        <td>{info?.Account}</td>
                                                        <td>{info?.TaxYear}</td>
                                                        <td>{info?.TaxType}</td>
                                                        <td>{info?.Address1}</td>
                                                        <td>{DateWithoutUTCFormatter(info?.DueDate)}</td>
                                                        <td>{CurrencyFormatter(info?.TotalDue)}</td>
                                                    </tr>
                                                ))}
                                                <tr style={{ fontWeight: 'bold' }}>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td>Total:</td>
                                                    <td>{CurrencyFormatter(_.sumBy(delinquentItems, (info: any) => (info?.TotalDue)))}</td>
                                                </tr>
                                            </tbody>
                                        </BootstrapTable>
                                    </div>
                                }
                            </Form.Group>
                            :
                            <div className="stCharles-accordion accordion">
                                <div className='card'>
                                    <div className='agreement_toggle_header card-header' style={{ justifyContent: 'center' }}>
                                        <FontAwesomeIcon color='green' icon={faCartPlus} />Added to cart
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                    <Form.Group className="form-footer">
                        <Button variant="outline-secondary" onClick={() => setShowModal(false)}>Close</Button>
                        <Button type="submit"
                            disabled={inCart || checkOnHoldItems(selectedRow) == true || statusTypeConverter(selectedRow?.itemPaymentStatus, selectedRow) == 'Pending' || statusTypeConverter(selectedRow?.itemPaymentStatus, selectedRow) == 'Paid'}
                            onClick={(e) => handleSubmit(e)}
                        >
                            {`Add to cart`}
                        </Button>
                    </Form.Group>
                </Modal.Body>
            </Modal>
        </div>
    )
}

const mapStateToProps = (state: IAppState) => {
    return {
        isFetching: state.account.isFetching,
        itemDetails: state.account.itemDetails,
        order: state.orderManagement.order,
        shoppingCart: state.orderManagement.shoppingCart
    };
};

export default connect(mapStateToProps)(PikeSearchResults);