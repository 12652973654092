import { SAVE_CARD } from '../actions/paymentMethods';

export default function paymentMethods(state = {
    cardList: [
        {
        cardType: 'master',
        cardholderName: 'No Name',
        cardNumber: 1232,
        paymentType: 'creditCard',
        expDate: '01/23',
        cvv: '123',
        default: true //TODO :  While adding a new default card, remove previous default card in the server.
    }
    ],
    actionResult: null
}, { type, payload }) {
    switch (type) {
        case SAVE_CARD:
            return Object.assign({}, state, {
                cardList: [...state.cardList, payload.card],
                actionResult: { type: SAVE_CARD, result: null, token: payload.actionToken } ,
            });
        
        default:
            return state;
    }
}
