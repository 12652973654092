import { CellPlugin } from '@react-page/editor';
import React from 'react';
import { Col, Row } from 'react-bootstrap';

// use a type here, not an interface
type Data = {
  label: string,
  fontWeight: number,
}

const FONT_WEIGHT = 400;

const checkStyleForDefault2 = (style:number|undefined, defaultStyle:number) => {
  return style?style:defaultStyle;
}

const DisclaimerPropertyPlugin: CellPlugin<Data> = {
  Renderer: ({ data }) => (
      <Row>
        <Col>
          <span style={{
            fontWeight:checkStyleForDefault2(data.fontWeight, FONT_WEIGHT)
          }}>
            {data.label}
          </span>
        </Col>
      </Row>
  ),
  id: 'disclaimerPropertyPlugin',
  title: 'Disclaimer Property',
  description: 'Disclaimer Property.',
  version: 1,
  controls: {
    type: 'autoform',
    schema: {
      properties: {
        label: {
          type: 'string',
          default: 'This payment will be posted to your account consistent with the posting timeframes established by the agency you are paying, and may not immediately reflect in your balance. Please do not submit payment again until your balance has updated.',
        },
        fontWeight: {
          type: 'integer',
          default: FONT_WEIGHT,
        },
      },
      required: ['label'],
    },
  },
};

export default DisclaimerPropertyPlugin;