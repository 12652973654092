import React from 'react';
import { Container, Col, Row } from 'react-bootstrap';
import logo from '../assets/img/logo.svg';

const StatusPage = () => {

    const setRoute = (path:string) => {
        return path.replace(":id", "Demo");
    }

    return (
        <>
            <Container>
                <header className="d-flex flex-wrap align-items-center justify-content-center justify-content-md-start py-3">
                    <a href="/" className="d-flex align-items-center text-dark text-decoration-none">
                        <img src={logo} alt="MSB Logo" />
                    </a>
                    <span style={{ marginLeft: "24px", fontSize: "1.25rem"}}>System Status</span>
                </header>
            </Container>

            <Container className="pt-5">
                <Row>
                  <Col>
                    <h1 className="display-4">All Systems Operational</h1>
                    <p>This page reflects uptime status as of August 26, 2021 at 8:00PM EST.</p>
                  </Col>
                </Row>
            </Container>

            <Container className="py-5">
              <Row>
                <Col>
                  <div className="card">
                    <div className="card-body">
                      <div className="card-header border-0 bg-white p-0 mb-2 d-flex justify-content-between">
                        <h5 className="card-title mb-0">Nexus</h5>
                        <span className="text-success fw-bold">Operational</span>
                      </div>
                      <div className="block-chart">
                        <div className="bar" data-status="up" data-tooltip="May 29, 2021&#13;&#10;No downtime"></div>
                        <div className="bar" data-status="up" data-tooltip="May 30, 2021&#13;&#10;No downtime"></div>
                        <div className="bar" data-status="up" data-tooltip="May 31, 2021&#13;&#10;No downtime"></div>
                        <div className="bar" data-status="up" data-tooltip="June 1, 2021&#13;&#10;No downtime"></div>
                        <div className="bar" data-status="up" data-tooltip="June 2, 2021&#13;&#10;No downtime"></div>
                        <div className="bar" data-status="up" data-tooltip="June 3, 2021&#13;&#10;No downtime"></div>
                        <div className="bar" data-status="up" data-tooltip="June 4, 2021&#13;&#10;No downtime"></div>
                        <div className="bar" data-status="up" data-tooltip="June 5, 2021&#13;&#10;No downtime"></div>
                        <div className="bar" data-status="up" data-tooltip="June 6, 2021&#13;&#10;No downtime"></div>
                        <div className="bar" data-status="warning" data-tooltip="June 7, 2021&#13;&#10;Degraded for 2h 40m"></div>
                        <div className="bar" data-status="up" data-tooltip="June 8, 2021&#13;&#10;No downtime"></div>
                        <div className="bar" data-status="up" data-tooltip="June 9, 2021&#13;&#10;No downtime"></div>
                        <div className="bar" data-status="up" data-tooltip="June 10, 2021&#13;&#10;No downtime"></div>
                        <div className="bar" data-status="up" data-tooltip="June 11, 2021&#13;&#10;No downtime"></div>
                        <div className="bar" data-status="up" data-tooltip="June 12, 2021&#13;&#10;No downtime"></div>
                        <div className="bar" data-status="up" data-tooltip="June 13, 2021&#13;&#10;No downtime"></div>
                        <div className="bar" data-status="up" data-tooltip="June 14, 2021&#13;&#10;No downtime"></div>
                        <div className="bar" data-status="up" data-tooltip="June 15, 2021&#13;&#10;No downtime"></div>
                        <div className="bar" data-status="up" data-tooltip="June 16, 2021&#13;&#10;No downtime"></div>
                        <div className="bar" data-status="up" data-tooltip="June 17, 2021&#13;&#10;No downtime"></div>
                        <div className="bar" data-status="up" data-tooltip="June 18, 2021&#13;&#10;No downtime"></div>
                        <div className="bar" data-status="up" data-tooltip="June 19, 2021&#13;&#10;No downtime"></div>
                        <div className="bar" data-status="down" data-tooltip="June 20, 2021&#13;&#10;Down for 14m"></div>
                        <div className="bar" data-status="up" data-tooltip="June 21, 2021&#13;&#10;No downtime"></div>
                        <div className="bar" data-status="up" data-tooltip="June 22, 2021&#13;&#10;No downtime"></div>
                        <div className="bar" data-status="up" data-tooltip="June 23, 2021&#13;&#10;No downtime"></div>
                        <div className="bar" data-status="up" data-tooltip="June 24, 2021&#13;&#10;No downtime"></div>
                        <div className="bar" data-status="up" data-tooltip="June 25, 2021&#13;&#10;No downtime"></div>
                        <div className="bar" data-status="up" data-tooltip="June 26, 2021&#13;&#10;No downtime"></div>
                        <div className="bar" data-status="up" data-tooltip="June 27, 2021&#13;&#10;No downtime"></div>
                        <div className="bar" data-status="up" data-tooltip="June 28, 2021&#13;&#10;No downtime"></div>
                        <div className="bar" data-status="up" data-tooltip="June 29, 2021&#13;&#10;No downtime"></div>
                        <div className="bar" data-status="up" data-tooltip="June 30, 2021&#13;&#10;No downtime"></div>
                        <div className="bar" data-status="up" data-tooltip="July 1, 2021&#13;&#10;No downtime"></div>
                        <div className="bar" data-status="up" data-tooltip="July 2, 2021&#13;&#10;No downtime"></div>
                        <div className="bar" data-status="up" data-tooltip="July 3, 2021&#13;&#10;No downtime"></div>
                        <div className="bar" data-status="up" data-tooltip="July 4, 2021&#13;&#10;No downtime"></div>
                        <div className="bar" data-status="up" data-tooltip="July 5, 2021&#13;&#10;No downtime"></div>
                        <div className="bar" data-status="up" data-tooltip="July 6, 2021&#13;&#10;No downtime"></div>
                        <div className="bar" data-status="up" data-tooltip="July 7, 2021&#13;&#10;No downtime"></div>
                        <div className="bar" data-status="up" data-tooltip="July 8, 2021&#13;&#10;No downtime"></div>
                        <div className="bar" data-status="up" data-tooltip="July 9, 2021&#13;&#10;No downtime"></div>
                        <div className="bar" data-status="up" data-tooltip="July 10, 2021&#13;&#10;No downtime"></div>
                        <div className="bar" data-status="up" data-tooltip="July 11, 2021&#13;&#10;No downtime"></div>
                        <div className="bar" data-status="up" data-tooltip="July 12, 2021&#13;&#10;No downtime"></div>
                        <div className="bar" data-status="up" data-tooltip="July 13, 2021&#13;&#10;No downtime"></div>
                        <div className="bar" data-status="up" data-tooltip="July 14, 2021&#13;&#10;No downtime"></div>
                        <div className="bar" data-status="up" data-tooltip="July 15, 2021&#13;&#10;No downtime"></div>
                        <div className="bar" data-status="warning" data-tooltip="July 16, 2021&#13;&#10;Degraded for 2h 40m"></div>
                        <div className="bar" data-status="down" data-tooltip="July 17, 2021&#13;&#10;Down for 30m"></div>
                        <div className="bar" data-status="up" data-tooltip="July 18, 2021&#13;&#10;No downtime"></div>
                        <div className="bar" data-status="up" data-tooltip="July 19, 2021&#13;&#10;No downtime"></div>
                        <div className="bar" data-status="up" data-tooltip="July 20, 2021&#13;&#10;No downtime"></div>
                        <div className="bar" data-status="up" data-tooltip="July 21, 2021&#13;&#10;No downtime"></div>
                        <div className="bar" data-status="up" data-tooltip="July 22, 2021&#13;&#10;No downtime"></div>
                        <div className="bar" data-status="up" data-tooltip="July 23, 2021&#13;&#10;No downtime"></div>
                        <div className="bar" data-status="up" data-tooltip="July 24, 2021&#13;&#10;No downtime"></div>
                        <div className="bar" data-status="up" data-tooltip="July 25, 2021&#13;&#10;No downtime"></div>
                        <div className="bar" data-status="up" data-tooltip="July 26, 2021&#13;&#10;No downtime"></div>
                        <div className="bar" data-status="up" data-tooltip="July 27, 2021&#13;&#10;No downtime"></div>
                        <div className="bar" data-status="up" data-tooltip="July 28, 2021&#13;&#10;No downtime"></div>
                        <div className="bar" data-status="up" data-tooltip="July 29, 2021&#13;&#10;No downtime"></div>
                        <div className="bar" data-status="up" data-tooltip="July 30, 2021&#13;&#10;No downtime"></div>
                        <div className="bar" data-status="up" data-tooltip="July 31, 2021&#13;&#10;No downtime"></div>
                        <div className="bar" data-status="up" data-tooltip="August 1, 2021&#13;&#10;No downtime"></div>
                        <div className="bar" data-status="up" data-tooltip="August 2, 2021&#13;&#10;No downtime"></div>
                        <div className="bar" data-status="up" data-tooltip="August 3, 2021&#13;&#10;No downtime"></div>
                        <div className="bar" data-status="up" data-tooltip="August 4, 2021&#13;&#10;No downtime"></div>
                        <div className="bar" data-status="up" data-tooltip="August 5, 2021&#13;&#10;No downtime"></div>
                        <div className="bar" data-status="up" data-tooltip="August 6, 2021&#13;&#10;No downtime"></div>
                        <div className="bar" data-status="up" data-tooltip="August 7, 2021&#13;&#10;No downtime"></div>
                        <div className="bar" data-status="up" data-tooltip="August 8, 2021&#13;&#10;No downtime"></div>
                        <div className="bar" data-status="up" data-tooltip="August 9, 2021&#13;&#10;No downtime"></div>
                        <div className="bar" data-status="up" data-tooltip="August 10, 2021&#13;&#10;No downtime"></div>
                        <div className="bar" data-status="up" data-tooltip="August 11, 2021&#13;&#10;No downtime"></div>
                        <div className="bar" data-status="up" data-tooltip="August 12, 2021&#13;&#10;No downtime"></div>
                        <div className="bar" data-status="up" data-tooltip="August 13, 2021&#13;&#10;No downtime"></div>
                        <div className="bar" data-status="up" data-tooltip="August 14, 2021&#13;&#10;No downtime"></div>
                        <div className="bar" data-status="up" data-tooltip="August 15, 2021&#13;&#10;No downtime"></div>
                        <div className="bar" data-status="up" data-tooltip="August 16, 2021&#13;&#10;No downtime"></div>
                        <div className="bar" data-status="up" data-tooltip="August 17, 2021&#13;&#10;No downtime"></div>
                        <div className="bar" data-status="up" data-tooltip="August 18, 2021&#13;&#10;No downtime"></div>
                        <div className="bar" data-status="up" data-tooltip="August 19, 2021&#13;&#10;No downtime"></div>
                        <div className="bar" data-status="up" data-tooltip="August 20, 2021&#13;&#10;No downtime"></div>
                        <div className="bar" data-status="up" data-tooltip="August 21, 2021&#13;&#10;No downtime"></div>
                        <div className="bar" data-status="up" data-tooltip="August 22, 2021&#13;&#10;No downtime"></div>
                        <div className="bar" data-status="up" data-tooltip="August 23, 2021&#13;&#10;No downtime"></div>
                        <div className="bar" data-status="up" data-tooltip="August 24, 2021&#13;&#10;No downtime"></div>
                        <div className="bar" data-status="up" data-tooltip="August 25, 2021&#13;&#10;No downtime"></div>
                        <div className="bar" data-status="up" data-tooltip="August 26, 2021&#13;&#10;No downtime"></div>
                      </div>
                      <div className="card-footer border-0 bg-white p-0 d-flex justify-content-between">
                        <small className="gray-7">90 days ago</small>
                        <small className="gray-7">Today</small>
                      </div>
                    </div>
                    <hr/>
                    <div className="card-body">
                      <div className="card-header border-0 bg-white p-0 mb-2 d-flex justify-content-between">
                        <h5 className="card-title mb-0">Constituent Websites</h5>
                        <span className="text-success fw-bold">Operational</span>
                      </div>
                      <div className="block-chart">
                        <div className="bar" data-status="up" data-tooltip="May 29, 2021&#13;&#10;No downtime"></div>
                        <div className="bar" data-status="up" data-tooltip="May 30, 2021&#13;&#10;No downtime"></div>
                        <div className="bar" data-status="up" data-tooltip="May 31, 2021&#13;&#10;No downtime"></div>
                        <div className="bar" data-status="up" data-tooltip="June 1, 2021&#13;&#10;No downtime"></div>
                        <div className="bar" data-status="up" data-tooltip="June 2, 2021&#13;&#10;No downtime"></div>
                        <div className="bar" data-status="up" data-tooltip="June 3, 2021&#13;&#10;No downtime"></div>
                        <div className="bar" data-status="up" data-tooltip="June 4, 2021&#13;&#10;No downtime"></div>
                        <div className="bar" data-status="up" data-tooltip="June 5, 2021&#13;&#10;No downtime"></div>
                        <div className="bar" data-status="up" data-tooltip="June 6, 2021&#13;&#10;No downtime"></div>
                        <div className="bar" data-status="warning" data-tooltip="June 7, 2021&#13;&#10;Degraded for 2h 40m"></div>
                        <div className="bar" data-status="up" data-tooltip="June 8, 2021&#13;&#10;No downtime"></div>
                        <div className="bar" data-status="up" data-tooltip="June 9, 2021&#13;&#10;No downtime"></div>
                        <div className="bar" data-status="up" data-tooltip="June 10, 2021&#13;&#10;No downtime"></div>
                        <div className="bar" data-status="up" data-tooltip="June 11, 2021&#13;&#10;No downtime"></div>
                        <div className="bar" data-status="up" data-tooltip="June 12, 2021&#13;&#10;No downtime"></div>
                        <div className="bar" data-status="up" data-tooltip="June 13, 2021&#13;&#10;No downtime"></div>
                        <div className="bar" data-status="up" data-tooltip="June 14, 2021&#13;&#10;No downtime"></div>
                        <div className="bar" data-status="up" data-tooltip="June 15, 2021&#13;&#10;No downtime"></div>
                        <div className="bar" data-status="up" data-tooltip="June 16, 2021&#13;&#10;No downtime"></div>
                        <div className="bar" data-status="up" data-tooltip="June 17, 2021&#13;&#10;No downtime"></div>
                        <div className="bar" data-status="up" data-tooltip="June 18, 2021&#13;&#10;No downtime"></div>
                        <div className="bar" data-status="up" data-tooltip="June 19, 2021&#13;&#10;No downtime"></div>
                        <div className="bar" data-status="down" data-tooltip="June 20, 2021&#13;&#10;Down for 14m"></div>
                        <div className="bar" data-status="up" data-tooltip="June 21, 2021&#13;&#10;No downtime"></div>
                        <div className="bar" data-status="up" data-tooltip="June 22, 2021&#13;&#10;No downtime"></div>
                        <div className="bar" data-status="up" data-tooltip="June 23, 2021&#13;&#10;No downtime"></div>
                        <div className="bar" data-status="up" data-tooltip="June 24, 2021&#13;&#10;No downtime"></div>
                        <div className="bar" data-status="up" data-tooltip="June 25, 2021&#13;&#10;No downtime"></div>
                        <div className="bar" data-status="up" data-tooltip="June 26, 2021&#13;&#10;No downtime"></div>
                        <div className="bar" data-status="up" data-tooltip="June 27, 2021&#13;&#10;No downtime"></div>
                        <div className="bar" data-status="up" data-tooltip="June 28, 2021&#13;&#10;No downtime"></div>
                        <div className="bar" data-status="up" data-tooltip="June 29, 2021&#13;&#10;No downtime"></div>
                        <div className="bar" data-status="up" data-tooltip="June 30, 2021&#13;&#10;No downtime"></div>
                        <div className="bar" data-status="up" data-tooltip="July 1, 2021&#13;&#10;No downtime"></div>
                        <div className="bar" data-status="up" data-tooltip="July 2, 2021&#13;&#10;No downtime"></div>
                        <div className="bar" data-status="up" data-tooltip="July 3, 2021&#13;&#10;No downtime"></div>
                        <div className="bar" data-status="up" data-tooltip="July 4, 2021&#13;&#10;No downtime"></div>
                        <div className="bar" data-status="up" data-tooltip="July 5, 2021&#13;&#10;No downtime"></div>
                        <div className="bar" data-status="up" data-tooltip="July 6, 2021&#13;&#10;No downtime"></div>
                        <div className="bar" data-status="up" data-tooltip="July 7, 2021&#13;&#10;No downtime"></div>
                        <div className="bar" data-status="up" data-tooltip="July 8, 2021&#13;&#10;No downtime"></div>
                        <div className="bar" data-status="up" data-tooltip="July 9, 2021&#13;&#10;No downtime"></div>
                        <div className="bar" data-status="up" data-tooltip="July 10, 2021&#13;&#10;No downtime"></div>
                        <div className="bar" data-status="up" data-tooltip="July 11, 2021&#13;&#10;No downtime"></div>
                        <div className="bar" data-status="up" data-tooltip="July 12, 2021&#13;&#10;No downtime"></div>
                        <div className="bar" data-status="up" data-tooltip="July 13, 2021&#13;&#10;No downtime"></div>
                        <div className="bar" data-status="up" data-tooltip="July 14, 2021&#13;&#10;No downtime"></div>
                        <div className="bar" data-status="up" data-tooltip="July 15, 2021&#13;&#10;No downtime"></div>
                        <div className="bar" data-status="warning" data-tooltip="July 16, 2021&#13;&#10;Degraded for 2h 40m"></div>
                        <div className="bar" data-status="down" data-tooltip="July 17, 2021&#13;&#10;Down for 30m"></div>
                        <div className="bar" data-status="up" data-tooltip="July 18, 2021&#13;&#10;No downtime"></div>
                        <div className="bar" data-status="up" data-tooltip="July 19, 2021&#13;&#10;No downtime"></div>
                        <div className="bar" data-status="up" data-tooltip="July 20, 2021&#13;&#10;No downtime"></div>
                        <div className="bar" data-status="up" data-tooltip="July 21, 2021&#13;&#10;No downtime"></div>
                        <div className="bar" data-status="up" data-tooltip="July 22, 2021&#13;&#10;No downtime"></div>
                        <div className="bar" data-status="up" data-tooltip="July 23, 2021&#13;&#10;No downtime"></div>
                        <div className="bar" data-status="up" data-tooltip="July 24, 2021&#13;&#10;No downtime"></div>
                        <div className="bar" data-status="up" data-tooltip="July 25, 2021&#13;&#10;No downtime"></div>
                        <div className="bar" data-status="up" data-tooltip="July 26, 2021&#13;&#10;No downtime"></div>
                        <div className="bar" data-status="up" data-tooltip="July 27, 2021&#13;&#10;No downtime"></div>
                        <div className="bar" data-status="up" data-tooltip="July 28, 2021&#13;&#10;No downtime"></div>
                        <div className="bar" data-status="up" data-tooltip="July 29, 2021&#13;&#10;No downtime"></div>
                        <div className="bar" data-status="up" data-tooltip="July 30, 2021&#13;&#10;No downtime"></div>
                        <div className="bar" data-status="up" data-tooltip="July 31, 2021&#13;&#10;No downtime"></div>
                        <div className="bar" data-status="up" data-tooltip="August 1, 2021&#13;&#10;No downtime"></div>
                        <div className="bar" data-status="up" data-tooltip="August 2, 2021&#13;&#10;No downtime"></div>
                        <div className="bar" data-status="up" data-tooltip="August 3, 2021&#13;&#10;No downtime"></div>
                        <div className="bar" data-status="up" data-tooltip="August 4, 2021&#13;&#10;No downtime"></div>
                        <div className="bar" data-status="up" data-tooltip="August 5, 2021&#13;&#10;No downtime"></div>
                        <div className="bar" data-status="up" data-tooltip="August 6, 2021&#13;&#10;No downtime"></div>
                        <div className="bar" data-status="up" data-tooltip="August 7, 2021&#13;&#10;No downtime"></div>
                        <div className="bar" data-status="up" data-tooltip="August 8, 2021&#13;&#10;No downtime"></div>
                        <div className="bar" data-status="up" data-tooltip="August 9, 2021&#13;&#10;No downtime"></div>
                        <div className="bar" data-status="up" data-tooltip="August 10, 2021&#13;&#10;No downtime"></div>
                        <div className="bar" data-status="up" data-tooltip="August 11, 2021&#13;&#10;No downtime"></div>
                        <div className="bar" data-status="up" data-tooltip="August 12, 2021&#13;&#10;No downtime"></div>
                        <div className="bar" data-status="up" data-tooltip="August 13, 2021&#13;&#10;No downtime"></div>
                        <div className="bar" data-status="up" data-tooltip="August 14, 2021&#13;&#10;No downtime"></div>
                        <div className="bar" data-status="up" data-tooltip="August 15, 2021&#13;&#10;No downtime"></div>
                        <div className="bar" data-status="up" data-tooltip="August 16, 2021&#13;&#10;No downtime"></div>
                        <div className="bar" data-status="up" data-tooltip="August 17, 2021&#13;&#10;No downtime"></div>
                        <div className="bar" data-status="up" data-tooltip="August 18, 2021&#13;&#10;No downtime"></div>
                        <div className="bar" data-status="up" data-tooltip="August 19, 2021&#13;&#10;No downtime"></div>
                        <div className="bar" data-status="up" data-tooltip="August 20, 2021&#13;&#10;No downtime"></div>
                        <div className="bar" data-status="up" data-tooltip="August 21, 2021&#13;&#10;No downtime"></div>
                        <div className="bar" data-status="up" data-tooltip="August 22, 2021&#13;&#10;No downtime"></div>
                        <div className="bar" data-status="up" data-tooltip="August 23, 2021&#13;&#10;No downtime"></div>
                        <div className="bar" data-status="up" data-tooltip="August 24, 2021&#13;&#10;No downtime"></div>
                        <div className="bar" data-status="up" data-tooltip="August 25, 2021&#13;&#10;No downtime"></div>
                        <div className="bar" data-status="up" data-tooltip="August 26, 2021&#13;&#10;No downtime"></div>
                      </div>
                      <div className="card-footer border-0 bg-white p-0 d-flex justify-content-between">
                        <small className="gray-7">90 days ago</small>
                        <small className="gray-7">Today</small>
                      </div>
                    </div>
                    <hr/>
                    <div className="card-body">
                      <div className="card-header border-0 bg-white p-0 mb-2 d-flex justify-content-between">
                        <h5 className="card-title mb-0">Web API</h5>
                        <span className="text-warning fw-bold">Degraded Performance</span>
                      </div>
                      <div className="block-chart">
                        <div className="bar" data-status="up" data-tooltip="May 29, 2021&#13;&#10;No downtime"></div>
                        <div className="bar" data-status="up" data-tooltip="May 30, 2021&#13;&#10;No downtime"></div>
                        <div className="bar" data-status="up" data-tooltip="May 31, 2021&#13;&#10;No downtime"></div>
                        <div className="bar" data-status="up" data-tooltip="June 1, 2021&#13;&#10;No downtime"></div>
                        <div className="bar" data-status="up" data-tooltip="June 2, 2021&#13;&#10;No downtime"></div>
                        <div className="bar" data-status="up" data-tooltip="June 3, 2021&#13;&#10;No downtime"></div>
                        <div className="bar" data-status="up" data-tooltip="June 4, 2021&#13;&#10;No downtime"></div>
                        <div className="bar" data-status="up" data-tooltip="June 5, 2021&#13;&#10;No downtime"></div>
                        <div className="bar" data-status="up" data-tooltip="June 6, 2021&#13;&#10;No downtime"></div>
                        <div className="bar" data-status="warning" data-tooltip="June 7, 2021&#13;&#10;Degraded for 2h 40m"></div>
                        <div className="bar" data-status="up" data-tooltip="June 8, 2021&#13;&#10;No downtime"></div>
                        <div className="bar" data-status="up" data-tooltip="June 9, 2021&#13;&#10;No downtime"></div>
                        <div className="bar" data-status="up" data-tooltip="June 10, 2021&#13;&#10;No downtime"></div>
                        <div className="bar" data-status="up" data-tooltip="June 11, 2021&#13;&#10;No downtime"></div>
                        <div className="bar" data-status="up" data-tooltip="June 12, 2021&#13;&#10;No downtime"></div>
                        <div className="bar" data-status="up" data-tooltip="June 13, 2021&#13;&#10;No downtime"></div>
                        <div className="bar" data-status="up" data-tooltip="June 14, 2021&#13;&#10;No downtime"></div>
                        <div className="bar" data-status="up" data-tooltip="June 15, 2021&#13;&#10;No downtime"></div>
                        <div className="bar" data-status="up" data-tooltip="June 16, 2021&#13;&#10;No downtime"></div>
                        <div className="bar" data-status="up" data-tooltip="June 17, 2021&#13;&#10;No downtime"></div>
                        <div className="bar" data-status="up" data-tooltip="June 18, 2021&#13;&#10;No downtime"></div>
                        <div className="bar" data-status="up" data-tooltip="June 19, 2021&#13;&#10;No downtime"></div>
                        <div className="bar" data-status="down" data-tooltip="June 20, 2021&#13;&#10;Down for 14m"></div>
                        <div className="bar" data-status="up" data-tooltip="June 21, 2021&#13;&#10;No downtime"></div>
                        <div className="bar" data-status="up" data-tooltip="June 22, 2021&#13;&#10;No downtime"></div>
                        <div className="bar" data-status="up" data-tooltip="June 23, 2021&#13;&#10;No downtime"></div>
                        <div className="bar" data-status="up" data-tooltip="June 24, 2021&#13;&#10;No downtime"></div>
                        <div className="bar" data-status="up" data-tooltip="June 25, 2021&#13;&#10;No downtime"></div>
                        <div className="bar" data-status="up" data-tooltip="June 26, 2021&#13;&#10;No downtime"></div>
                        <div className="bar" data-status="up" data-tooltip="June 27, 2021&#13;&#10;No downtime"></div>
                        <div className="bar" data-status="up" data-tooltip="June 28, 2021&#13;&#10;No downtime"></div>
                        <div className="bar" data-status="up" data-tooltip="June 29, 2021&#13;&#10;No downtime"></div>
                        <div className="bar" data-status="up" data-tooltip="June 30, 2021&#13;&#10;No downtime"></div>
                        <div className="bar" data-status="up" data-tooltip="July 1, 2021&#13;&#10;No downtime"></div>
                        <div className="bar" data-status="up" data-tooltip="July 2, 2021&#13;&#10;No downtime"></div>
                        <div className="bar" data-status="up" data-tooltip="July 3, 2021&#13;&#10;No downtime"></div>
                        <div className="bar" data-status="up" data-tooltip="July 4, 2021&#13;&#10;No downtime"></div>
                        <div className="bar" data-status="up" data-tooltip="July 5, 2021&#13;&#10;No downtime"></div>
                        <div className="bar" data-status="up" data-tooltip="July 6, 2021&#13;&#10;No downtime"></div>
                        <div className="bar" data-status="up" data-tooltip="July 7, 2021&#13;&#10;No downtime"></div>
                        <div className="bar" data-status="up" data-tooltip="July 8, 2021&#13;&#10;No downtime"></div>
                        <div className="bar" data-status="up" data-tooltip="July 9, 2021&#13;&#10;No downtime"></div>
                        <div className="bar" data-status="up" data-tooltip="July 10, 2021&#13;&#10;No downtime"></div>
                        <div className="bar" data-status="up" data-tooltip="July 11, 2021&#13;&#10;No downtime"></div>
                        <div className="bar" data-status="up" data-tooltip="July 12, 2021&#13;&#10;No downtime"></div>
                        <div className="bar" data-status="up" data-tooltip="July 13, 2021&#13;&#10;No downtime"></div>
                        <div className="bar" data-status="up" data-tooltip="July 14, 2021&#13;&#10;No downtime"></div>
                        <div className="bar" data-status="up" data-tooltip="July 15, 2021&#13;&#10;No downtime"></div>
                        <div className="bar" data-status="warning" data-tooltip="July 16, 2021&#13;&#10;Degraded for 2h 40m"></div>
                        <div className="bar" data-status="down" data-tooltip="July 17, 2021&#13;&#10;Down for 30m"></div>
                        <div className="bar" data-status="up" data-tooltip="July 18, 2021&#13;&#10;No downtime"></div>
                        <div className="bar" data-status="up" data-tooltip="July 19, 2021&#13;&#10;No downtime"></div>
                        <div className="bar" data-status="up" data-tooltip="July 20, 2021&#13;&#10;No downtime"></div>
                        <div className="bar" data-status="up" data-tooltip="July 21, 2021&#13;&#10;No downtime"></div>
                        <div className="bar" data-status="up" data-tooltip="July 22, 2021&#13;&#10;No downtime"></div>
                        <div className="bar" data-status="up" data-tooltip="July 23, 2021&#13;&#10;No downtime"></div>
                        <div className="bar" data-status="up" data-tooltip="July 24, 2021&#13;&#10;No downtime"></div>
                        <div className="bar" data-status="up" data-tooltip="July 25, 2021&#13;&#10;No downtime"></div>
                        <div className="bar" data-status="up" data-tooltip="July 26, 2021&#13;&#10;No downtime"></div>
                        <div className="bar" data-status="up" data-tooltip="July 27, 2021&#13;&#10;No downtime"></div>
                        <div className="bar" data-status="up" data-tooltip="July 28, 2021&#13;&#10;No downtime"></div>
                        <div className="bar" data-status="up" data-tooltip="July 29, 2021&#13;&#10;No downtime"></div>
                        <div className="bar" data-status="up" data-tooltip="July 30, 2021&#13;&#10;No downtime"></div>
                        <div className="bar" data-status="up" data-tooltip="July 31, 2021&#13;&#10;No downtime"></div>
                        <div className="bar" data-status="up" data-tooltip="August 1, 2021&#13;&#10;No downtime"></div>
                        <div className="bar" data-status="up" data-tooltip="August 2, 2021&#13;&#10;No downtime"></div>
                        <div className="bar" data-status="up" data-tooltip="August 3, 2021&#13;&#10;No downtime"></div>
                        <div className="bar" data-status="up" data-tooltip="August 4, 2021&#13;&#10;No downtime"></div>
                        <div className="bar" data-status="up" data-tooltip="August 5, 2021&#13;&#10;No downtime"></div>
                        <div className="bar" data-status="up" data-tooltip="August 6, 2021&#13;&#10;No downtime"></div>
                        <div className="bar" data-status="up" data-tooltip="August 7, 2021&#13;&#10;No downtime"></div>
                        <div className="bar" data-status="up" data-tooltip="August 8, 2021&#13;&#10;No downtime"></div>
                        <div className="bar" data-status="up" data-tooltip="August 9, 2021&#13;&#10;No downtime"></div>
                        <div className="bar" data-status="up" data-tooltip="August 10, 2021&#13;&#10;No downtime"></div>
                        <div className="bar" data-status="up" data-tooltip="August 11, 2021&#13;&#10;No downtime"></div>
                        <div className="bar" data-status="up" data-tooltip="August 12, 2021&#13;&#10;No downtime"></div>
                        <div className="bar" data-status="up" data-tooltip="August 13, 2021&#13;&#10;No downtime"></div>
                        <div className="bar" data-status="up" data-tooltip="August 14, 2021&#13;&#10;No downtime"></div>
                        <div className="bar" data-status="up" data-tooltip="August 15, 2021&#13;&#10;No downtime"></div>
                        <div className="bar" data-status="up" data-tooltip="August 16, 2021&#13;&#10;No downtime"></div>
                        <div className="bar" data-status="up" data-tooltip="August 17, 2021&#13;&#10;No downtime"></div>
                        <div className="bar" data-status="up" data-tooltip="August 18, 2021&#13;&#10;No downtime"></div>
                        <div className="bar" data-status="up" data-tooltip="August 19, 2021&#13;&#10;No downtime"></div>
                        <div className="bar" data-status="up" data-tooltip="August 20, 2021&#13;&#10;No downtime"></div>
                        <div className="bar" data-status="up" data-tooltip="August 21, 2021&#13;&#10;No downtime"></div>
                        <div className="bar" data-status="warning" data-tooltip="August 22, 2021&#13;&#10;Degraded for 2h 40m">
                        </div>
                        <div className="bar" data-status="warning" data-tooltip="August 23, 2021&#13;&#10;Degraded for 2h 40m">
                        </div>
                        <div className="bar" data-status="warning" data-tooltip="August 24, 2021&#13;&#10;Degraded for 2h 40m">
                        </div>
                        <div className="bar" data-status="warning" data-tooltip="August 25, 2021&#13;&#10;Degraded for 2h 40m">
                        </div>
                        <div className="bar" data-status="warning" data-tooltip="August 26, 2021&#13;&#10;Degraded for 2h 40m">
                        </div>
                      </div>
                      <div className="card-footer border-0 bg-white p-0 d-flex justify-content-between">
                        <small className="gray-7">90 days ago</small>
                        <small className="gray-7">Today</small>
                      </div>
                    </div>
                    <hr/>
                    <div className="card-body">
                      <div className="card-header border-0 bg-white p-0 mb-2 d-flex justify-content-between">
                        <h5 className="card-title mb-0">IVR</h5>
                        <span className="text-danger fw-bold">Major Outage</span>
                      </div>
                      <div className="block-chart">
                        <div className="bar" data-status="up" data-tooltip="May 29, 2021&#13;&#10;No downtime"></div>
                        <div className="bar" data-status="up" data-tooltip="May 30, 2021&#13;&#10;No downtime"></div>
                        <div className="bar" data-status="up" data-tooltip="May 31, 2021&#13;&#10;No downtime"></div>
                        <div className="bar" data-status="up" data-tooltip="June 1, 2021&#13;&#10;No downtime"></div>
                        <div className="bar" data-status="up" data-tooltip="June 2, 2021&#13;&#10;No downtime"></div>
                        <div className="bar" data-status="up" data-tooltip="June 3, 2021&#13;&#10;No downtime"></div>
                        <div className="bar" data-status="up" data-tooltip="June 4, 2021&#13;&#10;No downtime"></div>
                        <div className="bar" data-status="up" data-tooltip="June 5, 2021&#13;&#10;No downtime"></div>
                        <div className="bar" data-status="up" data-tooltip="June 6, 2021&#13;&#10;No downtime"></div>
                        <div className="bar" data-status="warning" data-tooltip="June 7, 2021&#13;&#10;Degraded for 2h 40m"></div>
                        <div className="bar" data-status="up" data-tooltip="June 8, 2021&#13;&#10;No downtime"></div>
                        <div className="bar" data-status="up" data-tooltip="June 9, 2021&#13;&#10;No downtime"></div>
                        <div className="bar" data-status="up" data-tooltip="June 10, 2021&#13;&#10;No downtime"></div>
                        <div className="bar" data-status="up" data-tooltip="June 11, 2021&#13;&#10;No downtime"></div>
                        <div className="bar" data-status="up" data-tooltip="June 12, 2021&#13;&#10;No downtime"></div>
                        <div className="bar" data-status="up" data-tooltip="June 13, 2021&#13;&#10;No downtime"></div>
                        <div className="bar" data-status="up" data-tooltip="June 14, 2021&#13;&#10;No downtime"></div>
                        <div className="bar" data-status="up" data-tooltip="June 15, 2021&#13;&#10;No downtime"></div>
                        <div className="bar" data-status="up" data-tooltip="June 16, 2021&#13;&#10;No downtime"></div>
                        <div className="bar" data-status="up" data-tooltip="June 17, 2021&#13;&#10;No downtime"></div>
                        <div className="bar" data-status="up" data-tooltip="June 18, 2021&#13;&#10;No downtime"></div>
                        <div className="bar" data-status="up" data-tooltip="June 19, 2021&#13;&#10;No downtime"></div>
                        <div className="bar" data-status="down" data-tooltip="June 20, 2021&#13;&#10;Down for 14m"></div>
                        <div className="bar" data-status="up" data-tooltip="June 21, 2021&#13;&#10;No downtime"></div>
                        <div className="bar" data-status="up" data-tooltip="June 22, 2021&#13;&#10;No downtime"></div>
                        <div className="bar" data-status="up" data-tooltip="June 23, 2021&#13;&#10;No downtime"></div>
                        <div className="bar" data-status="up" data-tooltip="June 24, 2021&#13;&#10;No downtime"></div>
                        <div className="bar" data-status="up" data-tooltip="June 25, 2021&#13;&#10;No downtime"></div>
                        <div className="bar" data-status="up" data-tooltip="June 26, 2021&#13;&#10;No downtime"></div>
                        <div className="bar" data-status="up" data-tooltip="June 27, 2021&#13;&#10;No downtime"></div>
                        <div className="bar" data-status="up" data-tooltip="June 28, 2021&#13;&#10;No downtime"></div>
                        <div className="bar" data-status="up" data-tooltip="June 29, 2021&#13;&#10;No downtime"></div>
                        <div className="bar" data-status="up" data-tooltip="June 30, 2021&#13;&#10;No downtime"></div>
                        <div className="bar" data-status="up" data-tooltip="July 1, 2021&#13;&#10;No downtime"></div>
                        <div className="bar" data-status="up" data-tooltip="July 2, 2021&#13;&#10;No downtime"></div>
                        <div className="bar" data-status="up" data-tooltip="July 3, 2021&#13;&#10;No downtime"></div>
                        <div className="bar" data-status="up" data-tooltip="July 4, 2021&#13;&#10;No downtime"></div>
                        <div className="bar" data-status="up" data-tooltip="July 5, 2021&#13;&#10;No downtime"></div>
                        <div className="bar" data-status="up" data-tooltip="July 6, 2021&#13;&#10;No downtime"></div>
                        <div className="bar" data-status="up" data-tooltip="July 7, 2021&#13;&#10;No downtime"></div>
                        <div className="bar" data-status="up" data-tooltip="July 8, 2021&#13;&#10;No downtime"></div>
                        <div className="bar" data-status="up" data-tooltip="July 9, 2021&#13;&#10;No downtime"></div>
                        <div className="bar" data-status="up" data-tooltip="July 10, 2021&#13;&#10;No downtime"></div>
                        <div className="bar" data-status="up" data-tooltip="July 11, 2021&#13;&#10;No downtime"></div>
                        <div className="bar" data-status="up" data-tooltip="July 12, 2021&#13;&#10;No downtime"></div>
                        <div className="bar" data-status="up" data-tooltip="July 13, 2021&#13;&#10;No downtime"></div>
                        <div className="bar" data-status="up" data-tooltip="July 14, 2021&#13;&#10;No downtime"></div>
                        <div className="bar" data-status="up" data-tooltip="July 15, 2021&#13;&#10;No downtime"></div>
                        <div className="bar" data-status="warning" data-tooltip="July 16, 2021&#13;&#10;Degraded for 2h 40m"></div>
                        <div className="bar" data-status="down" data-tooltip="July 17, 2021&#13;&#10;Down for 30m"></div>
                        <div className="bar" data-status="up" data-tooltip="July 18, 2021&#13;&#10;No downtime"></div>
                        <div className="bar" data-status="up" data-tooltip="July 19, 2021&#13;&#10;No downtime"></div>
                        <div className="bar" data-status="up" data-tooltip="July 20, 2021&#13;&#10;No downtime"></div>
                        <div className="bar" data-status="up" data-tooltip="July 21, 2021&#13;&#10;No downtime"></div>
                        <div className="bar" data-status="up" data-tooltip="July 22, 2021&#13;&#10;No downtime"></div>
                        <div className="bar" data-status="up" data-tooltip="July 23, 2021&#13;&#10;No downtime"></div>
                        <div className="bar" data-status="up" data-tooltip="July 24, 2021&#13;&#10;No downtime"></div>
                        <div className="bar" data-status="up" data-tooltip="July 25, 2021&#13;&#10;No downtime"></div>
                        <div className="bar" data-status="up" data-tooltip="July 26, 2021&#13;&#10;No downtime"></div>
                        <div className="bar" data-status="up" data-tooltip="July 27, 2021&#13;&#10;No downtime"></div>
                        <div className="bar" data-status="up" data-tooltip="July 28, 2021&#13;&#10;No downtime"></div>
                        <div className="bar" data-status="up" data-tooltip="July 29, 2021&#13;&#10;No downtime"></div>
                        <div className="bar" data-status="up" data-tooltip="July 30, 2021&#13;&#10;No downtime"></div>
                        <div className="bar" data-status="up" data-tooltip="July 31, 2021&#13;&#10;No downtime"></div>
                        <div className="bar" data-status="up" data-tooltip="August 1, 2021&#13;&#10;No downtime"></div>
                        <div className="bar" data-status="up" data-tooltip="August 2, 2021&#13;&#10;No downtime"></div>
                        <div className="bar" data-status="up" data-tooltip="August 3, 2021&#13;&#10;No downtime"></div>
                        <div className="bar" data-status="up" data-tooltip="August 4, 2021&#13;&#10;No downtime"></div>
                        <div className="bar" data-status="up" data-tooltip="August 5, 2021&#13;&#10;No downtime"></div>
                        <div className="bar" data-status="up" data-tooltip="August 6, 2021&#13;&#10;No downtime"></div>
                        <div className="bar" data-status="up" data-tooltip="August 7, 2021&#13;&#10;No downtime"></div>
                        <div className="bar" data-status="up" data-tooltip="August 8, 2021&#13;&#10;No downtime"></div>
                        <div className="bar" data-status="up" data-tooltip="August 9, 2021&#13;&#10;No downtime"></div>
                        <div className="bar" data-status="up" data-tooltip="August 10, 2021&#13;&#10;No downtime"></div>
                        <div className="bar" data-status="up" data-tooltip="August 11, 2021&#13;&#10;No downtime"></div>
                        <div className="bar" data-status="up" data-tooltip="August 12, 2021&#13;&#10;No downtime"></div>
                        <div className="bar" data-status="up" data-tooltip="August 13, 2021&#13;&#10;No downtime"></div>
                        <div className="bar" data-status="up" data-tooltip="August 14, 2021&#13;&#10;No downtime"></div>
                        <div className="bar" data-status="up" data-tooltip="August 15, 2021&#13;&#10;No downtime"></div>
                        <div className="bar" data-status="up" data-tooltip="August 16, 2021&#13;&#10;No downtime"></div>
                        <div className="bar" data-status="up" data-tooltip="August 17, 2021&#13;&#10;No downtime"></div>
                        <div className="bar" data-status="up" data-tooltip="August 18, 2021&#13;&#10;No downtime"></div>
                        <div className="bar" data-status="up" data-tooltip="August 19, 2021&#13;&#10;No downtime"></div>
                        <div className="bar" data-status="down" data-tooltip="August 20, 2021&#13;&#10;Down for 30m"></div>
                        <div className="bar" data-status="down" data-tooltip="August 21, 2021&#13;&#10;Down for 30m"></div>
                        <div className="bar" data-status="down" data-tooltip="August 22, 2021&#13;&#10;Down for 30m"></div>
                        <div className="bar" data-status="down" data-tooltip="August 23, 2021&#13;&#10;Down for 30m"></div>
                        <div className="bar" data-status="down" data-tooltip="August 24, 2021&#13;&#10;Down for 30m"></div>
                        <div className="bar" data-status="down" data-tooltip="August 25, 2021&#13;&#10;Down for 30m"></div>
                        <div className="bar" data-status="down" data-tooltip="August 26, 2021&#13;&#10;Down for 30m"></div>
                      </div>
                      <div className="card-footer border-0 bg-white p-0 d-flex justify-content-between">
                        <small className="gray-7">90 days ago</small>
                        <small className="gray-7">Today</small>
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
            </Container>
            
            <section className="gray-2 py-5">

            <Container >
              <Row>
                <Col>
                  <h3>Past Incidents</h3>
                </Col>
              </Row>
              <Row>
                <Col>
                  <div className="status-day no-incidents">
                    <div className="date">
                      Aug 26, 2021
                    </div>
                    <span className="no-incident">No incidents to report</span>
                  </div>
                  <div className="status-day">
                    <div className="date">
                      Aug 25, 2021
                    </div>
                  </div>
                  <div className="incident-container">
                    <div className="incident-title">
                      Nexus
                    </div>
                    <div className="update resolved">
                      <strong>Resolved – </strong>
                      <span>This incident has been resolved.</span><br/>
                      <small>Aug 25, 11:14 PM EST</small>
                    </div>
                    <div className="update identified">
                      <strong>Identified – </strong>
                      <span>We have identified the root cause of the issue and are actively working to mitigate the
                        impact.</span><br/>
                      <small>Aug 25, 5:25 PM EST</small>
                    </div>
                    <div className="update investigating">
                      <strong>Investigating – </strong>
                      <span>We are currently experiencing delays in our application updates. We are actively investigating and
                        working to mitigate this issue.</span><br/>
                      <small>Aug 25, 3:28 PM EST</small>
                    </div>
                  </div>
                  <div className="incident-container">
                    <div className="incident-title">
                      Upcoming Nexus Maintenance
                    </div>
                    <div className="update completed">
                      <strong>Completed – </strong>
                      <span>The scheduled maintenance has been completed.</span><br/>
                      <small>Aug 25, 5:39 AM EST</small>
                    </div>
                    <div className="update update">
                      <strong>Update – </strong>
                      <span>MSB is performing maintenance.</span><br/>
                      <small>Aug 25, 4:03 AM EST</small>
                    </div>
                    <div className="update in-progress">
                      <strong>In progress – </strong>
                      <span>Scheduled maintenance is currently in progress. We will provide updates as necessary.</span><br/>
                      <small>Aug 25, 4:02 AM EST</small>
                    </div>
                    <div className="update scheduled">
                      <strong>Scheduled – </strong>
                      <span>MSB is planning maintenance from 9:00 to 11:00 EST on August 24, 2021.</span><br/>
                      <small>Aug 20, 8:28 PM EST</small>
                    </div>
                  </div>
                  <div className="status-day no-incidents">
                    <div className="date">
                      Aug 24, 2021
                    </div>
                    <span className="no-incident">No incidents to report</span>
                  </div>
                  <div className="status-day no-incidents">
                    <div className="date">
                      Aug 23, 2021
                    </div>
                    <span className="no-incident">No incidents to report</span>
                  </div>
                </Col>
              </Row>
            </Container>
            </section>

            <footer className="gradient-2">
                <div className="container">
                    <div className="row py-5">
                        <div className="col-md-6 col-sm-12">
                            <a href="#" className="me-3">Privacy Policy</a>
                            <a href="#">Terms of Use</a>
                        </div>
                        <div className="col-md-3 col-sm-12">
                            <h5>Address</h5>
                            <p className="dark-gray">Municipal Services Bureau<br />
                                325 Daniel Zenker Drive<br />
                                Suite 3<br />
                                Horseheads, NY 14845
                            </p>
                        </div>
                        <div className="col-md-3 col-sm-12">
                            <h5>Contact</h5>
                            <p className="dark-gray">
                                Monday &ndash; Friday<br />
                                8:00 am EST &ndash; 12:00 am EST<br />
                                Phone: 1-800-616-0166<br />
                                Fax: 1-800-756-4110
                            </p>
                        </div>
                    </div>
                </div>
            </footer>
            <footer className="footer">
                <div className="container">
                    <div className="row pt-5 mb-5">
                        <div className="col-12">
                            <p className="gray-7">© 2021 Gila, LLC d/b/a Municipal Services Bureau (MSB) is a registered ISO of Fifth Third
                            Bank, N.A.
                            Cincinnati, OH. All rights reserved. The MSB logo is a registered service mark of Gila, LLC. Gila, LLC is a
                            subsidiary of Navient Corporation. Navient Corporation and its subsidiaries, including Gila, LLC, are not
                    sponsored by or agencies of the United States of America.</p>
                        </div>
                    </div>
                </div>
            </footer>
        </>
    );
}
export default StatusPage;