import * as React from "react";
import { Form, Button, Spinner } from 'react-bootstrap';

export interface IFormActionProps {
    showSpinner?: boolean;
    onPrevious?(): void;
    hasSubmit?: boolean;
    onCancel?(): void;
    onEdit?(): void;
    customProps?: any;
    disabled?: boolean;
}

const FormActions = ({ onPrevious, hasSubmit, onCancel, showSpinner, onEdit, customProps, disabled }: IFormActionProps) => {

    function renderSpinner() {
        if (showSpinner) {
            return (<Spinner animation="border" className="margin-left-3" />)
        } else {
            return (<></>)
        }
    }

    function renderPrevious() {
        if (onPrevious) {
            return (<Button type="button" className="margin-right-2" variant="outline-secondary" onClick={onPrevious}>Previous</Button>)
        } else {
            return (<></>)
        }
    }

    function renderSubmit() {
        const submitButtonDisplay = (customProps && customProps.submitButtonDisplay) || "Submit";
        if (hasSubmit) {
            return (<Button type="submit" className="brand-btn-primary" disabled={disabled}>{submitButtonDisplay}</Button>)
        } else {
            return (<></>)
        }
    }

    function renderEdit() {
        if (onEdit) {
            return (<Button type="button" variant="link" className="brand-link-primary" style={{ float: "right" }} onClick={onEdit}>Edit</Button>)
        } else {
            return (<></>)
        }
    }

    function renderCancel() {
        if (onCancel) {
            return (<Button type="button" variant="link" className="brand-link-primary" style={{ float: "right" }} onClick={onCancel}>Cancel</Button>)
        } else {
            return (<></>)
        }
    }

    return (
        <Form.Row className="form-footer">
            {renderPrevious()}
            {renderSubmit()}
            {renderSpinner()}
            {renderEdit()}
            {renderCancel()}
        </Form.Row>
    );
};

export default FormActions;