import { ChildCareOutlined } from '@material-ui/icons';
import { CellPlugin, CellPluginMissingProps } from '@react-page/editor';
import { Container, Row, Table } from 'react-bootstrap';
import { convertCompilerOptionsFromJson } from 'typescript';

//import './../editor.css';

// use a type here, not an interface
type Data = {
}

/*
const renderChildren = (children:any) => {
  console.log("renderChildren");
  console.log(children);

  if (children) {
    return (<>
      {children.map((child:any) => 
        <Row>
            {child}
        </Row>
      )}
    </>);
  }
}*/

const ReceiptContainerPlugin: CellPlugin<Data> = {
  Renderer: (props) => (
    <Container style={{backgroundColor: '#ffffff', marginTop: 0, paddingTop: '24px', paddingBottom: '24px'}}>
        {props.children}
    </Container>
  ),
  id: 'receiptContainerPlugin',
  title: 'Receipt Container',
  description: 'A receipt container.',
  version: 1,
  controls: {
    type: 'autoform',
    schema: {
      properties: {
      },
      required: [],
    },
  },
};

export default ReceiptContainerPlugin;