import { CheckCircleOutlined, ErrorOutlineOutlined } from "@mui/icons-material";
import { Accordion, AccordionSummary, Typography, Box, AccordionDetails, Grid, Button, Dialog, DialogTitle, DialogContent, DialogContentText, CircularProgress, DialogActions, FormControlLabel, Checkbox, useTheme } from "@mui/material";
import { Link, LinkStatus, LinkView } from "../../models/CheckoutLink";
import { IActionResult, IAppState } from "../../redux/storeTypes";
import { connect, useDispatch } from "react-redux";
import { useCallback, useEffect, useState } from "react";
import { statusLinkAction, viewLinkAction } from "../../redux/actions/link";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import { ADD_BANKACCOUNT_FAILURE, ADD_BANKACCOUNT_REQUEST, ADD_BANKACCOUNT_SUCCESS, ADD_CARD_FAILURE, ADD_CARD_REQUEST, ADD_CARD_SUCCESS, addBankAccountAction, addCardAction } from "../../redux/actions/wallet";
import { TokenizeBankAccount, TokenizeCard } from "../../models/Wallet";

export interface ISummaryProps {
    msg: any;
    link: Link;
    status: LinkStatus;
    view: LinkView;
    card: TokenizeCard;
    account: TokenizeBankAccount;
    errorMessage: string;
    actionResult: IActionResult;
}

const Summary = ({ msg, link, status, view, card, account, errorMessage, actionResult }: ISummaryProps) => {
    const actionToken = "LinkPage"
    const dispatch = useDispatch();
    const theme = useTheme();
    const [submitted, setSubmitted] = useState(false);
    const [expanded, setExpanded] = useState(false);
    const [processing, setProcessing] = useState(false);
    const [complete, setComplete] = useState(false);
    const [failure, setFailure] = useState(false);
    const { executeRecaptcha } = useGoogleReCaptcha();

    useEffect(() => {
        if (status) {
            if (status.withMethod) {
                setExpanded(true);
                if (!status.summary) {
                    dispatch(statusLinkAction({ ...status, summary: true }));
                }
            } else {
                if (status.summary) {
                    dispatch(statusLinkAction({ ...status, summary: false }));
                }
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [status]);

    useEffect(() => {
        if (view !== LinkView.SUMMARY) setExpanded(status?.withMethod && (view as LinkView) === LinkView.NONE);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [view]);

    useEffect(() => {
        if (expanded) {
            dispatch(viewLinkAction(LinkView.SUMMARY));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [expanded]);

    useEffect(() => {
        if (actionResult && actionResult.result) {
            if (actionResult.type === ADD_CARD_REQUEST && actionResult.token === actionToken) {
                if (actionResult.result === ADD_CARD_SUCCESS) {
                    setProcessing(false);
                    setComplete(true);
                    msg({ Response: "SUCCESS", Message: "Token Generated", ID: link.uniqueId });
                }
                if (actionResult.result === ADD_CARD_FAILURE) {
                    setProcessing(false);
                    setFailure(true);
                }
            }
            if (actionResult.type === ADD_BANKACCOUNT_REQUEST && actionResult.token === actionToken) {
                if (actionResult.result === ADD_BANKACCOUNT_SUCCESS) {
                    setProcessing(false);
                    setComplete(true);
                    msg({ Response: "SUCCESS", Message: "Token Generated", ID: link.uniqueId });
                }
                if (actionResult.result === ADD_BANKACCOUNT_FAILURE) {
                    setProcessing(false);
                    setFailure(true);
                }
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [actionResult]);

    const handleFailure = () => {
        setFailure(false);
        setSubmitted(false);
        setExpanded(false);
        dispatch(statusLinkAction({ ...status, withMethod: false }));
    }

    const handleReCaptchaVerify = useCallback(async (_card: TokenizeCard, _account: TokenizeBankAccount) => {
        if (!executeRecaptcha) {
            console.log('Execute recaptcha not yet available');
            return;
        }
        setProcessing(true);
        const token = await executeRecaptcha(actionToken);
        if (_card) dispatch(addCardAction(_card, actionToken));
        if (_account) dispatch(addBankAccountAction(_account, actionToken));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [executeRecaptcha]);

    const handleLink = (event: any) => {
        event.preventDefault();
        const form = event.currentTarget;
        if (form.reportValidity()) {
            setSubmitted(true);
            handleReCaptchaVerify(card, account);
        }
    }

    return (
        <>
        <Accordion expanded={expanded} disableGutters={true}>
            <AccordionSummary>
                <Typography><Box component="span">{status?.summary ? < CheckCircleOutlined sx={{ color: 'green', verticalAlign: 'bottom' }} /> : <ErrorOutlineOutlined sx={{ color: 'gold', verticalAlign: 'bottom' }} />} Summary</Box></Typography>
            </AccordionSummary>
                <AccordionDetails>
                    <form onSubmit={handleLink}>
                        <Grid container>
                            <Grid item xs>
                                <Box sx={{ my: -1 }}>
                                    <FormControlLabel
                                        required
                                        control={<Checkbox size="small" />}
                                        label={
                                            <Typography sx={{ textAlign: 'justify' }} fontSize={'0.75em'} color={theme.palette.text.primary}>
                                                I accept the <a href="https://payments.msbpay.navient.com/Payment_Terms_and_Conditions.pdf" target={"_blank"} rel={"noreferrer"}>terms & conditions</a>.
                                            </Typography>
                                        }
                                        sx={{ '& .MuiFormControlLabel-asterisk': { display: 'none', }, }}
                                    />
                                </Box>
                            </Grid>
                        </Grid>
                        <Grid container style={{ paddingTop: "8px" }}>
                            <Grid item xs>
                                <Button type="submit" fullWidth variant="contained" disabled={submitted}>Submit</Button>
                            </Grid>
                        </Grid>
                    </form>
            </AccordionDetails>
        </Accordion>
        <Dialog open={processing} fullWidth maxWidth="sm">
            <DialogTitle style={{ textAlign: 'center' }}><CircularProgress sx={{ color: 'blue', fontSize: 48 }} /></DialogTitle>
            <DialogContent style={{ textAlign: 'center' }}>
                <DialogContentText>Processing...</DialogContentText>
            </DialogContent>
        </Dialog>
        <Dialog open={complete} fullWidth maxWidth="sm">
            <DialogTitle style={{ textAlign: 'center' }}><CheckCircleOutlined sx={{ color: 'green', fontSize: 48 }} /></DialogTitle>
            <DialogContent style={{ textAlign: 'center' }}>
                <DialogContentText>Complete</DialogContentText>
            </DialogContent>
        </Dialog>
        <Dialog open={failure} fullWidth maxWidth="sm">
            <DialogTitle style={{ textAlign: 'center' }}><ErrorOutlineOutlined sx={{ color: 'red', fontSize: 48 }} /></DialogTitle>
            <DialogContent style={{ textAlign: 'center' }}>
                <DialogContentText>{errorMessage}</DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleFailure}>OK</Button>
            </DialogActions>
        </Dialog>
        </>
    );
};

const mapStateToProps = (state: IAppState) => {
    return {
        link: state.link.link,
        status: state.link.status,
        view: state.link.view,
        card: state.wallet.card,
        account: state.wallet.bankAccount,
        errorMessage: state.wallet.errorMessage,
        actionResult: state.wallet.actionResult,

    };
};

export default connect(mapStateToProps)(Summary);