import axios from 'axios';

import {
    POST_AUTH_REQUEST, POST_AUTH_SUCCESS, POST_AUTH_FAILURE,
    LOGOUT_SUCCESS, SAVE_CURRENT_USER_REQUEST, SAVE_CURRENT_USER_SUCCESS, SAVE_CURRENT_USER_FAILURE
} from '../actions/auth';

import { SAVE_USER_REQUEST, SAVE_USER_SUCCESS } from '../actions/users'

export default function auth(state = {
    isFetching: false,
    isSaving: false,
    errorMessage: '',
    currentUser: null,
    actionResult: null,
    isAuthenticated: false,
    msbBearerToken: null,
    msalInstance: {},
    loadingInit: true
}, { type, payload }) {
    switch (type) {
        case LOGOUT_SUCCESS:
            return Object.assign({}, state, {
                isAuthenticated: false,
                currentUser: null,
                loadingInit: false,
            });
        case POST_AUTH_REQUEST:
            return Object.assign({}, state, {
                isFetching: true,
                actionResult: { type: POST_AUTH_REQUEST, result: null, token: payload.actionToken } ,
            });
        case POST_AUTH_SUCCESS:
            return Object.assign({}, state, {
                isFetching: false,
                isAuthenticated: true,
                actionResult: { type: POST_AUTH_REQUEST, result: POST_AUTH_SUCCESS, token: payload.actionToken } ,
                currentUser: payload.currentUser,
                msbBearerToken: payload.token,
                msalInstance: payload.msalInstance
            });
        case POST_AUTH_FAILURE:
            return Object.assign({}, state, {
                isFetching: false,
                isAuthenticated: false,
                actionResult: { type: POST_AUTH_REQUEST, result: POST_AUTH_FAILURE, token: payload.actionToken } ,
                errorMessage: payload.error
            });
        case SAVE_CURRENT_USER_REQUEST:
            return Object.assign({}, state, {
                isSaving: true,
                actionResult: { type: SAVE_CURRENT_USER_REQUEST, result: null, token: payload.actionToken } ,
            });
        case SAVE_CURRENT_USER_SUCCESS:
            return Object.assign({}, state, {
                isSaving: false,
                actionResult: { type: SAVE_CURRENT_USER_REQUEST, result: SAVE_CURRENT_USER_SUCCESS, token: payload.actionToken } ,
                currentUser: payload.currentUser,
            });
        case SAVE_CURRENT_USER_FAILURE:
            return Object.assign({}, state, {
                isSaving: false,
                actionResult: { type: SAVE_CURRENT_USER_REQUEST, result: SAVE_CURRENT_USER_FAILURE, token: payload.actionToken } ,
                errorMessage: payload.error
            });
        case SAVE_USER_SUCCESS:
            if (true) {
                return Object.assign({}, state, {
                    currentUser: payload.user,
                    isSaving: false,
                    actionResult: { type: SAVE_USER_REQUEST, result: SAVE_USER_SUCCESS, token: payload.actionToken }
                });         
            }     
        default:
            return state;
    }
}
