import { CellPlugin } from '@react-page/editor';
import React from 'react';
import { Col, Row } from 'react-bootstrap';

// use a type here, not an interface
type Data = {
  text: string;
  title: string;
  label: string;
  imageUrl: string;
  imageUrl2: string;
  description: string;
  backgroundColor: string;
  fontColor: string;
  fontColorDesc: string;
  fontSize: string;
  fontSizeDesc: string;
  fontWeight: string;
  fontWeightDesc: string;
  height: string;
  heightImage: string;
  margin: string;
  marginImage: string;
  padding: string;
  justifyContent: string;
  position: string;
}

const FONT_COLOR = '#414141';
const FONT_COLOR_DESC = '#8C8C8C';
const FONT_WEIGHT = 'bold';
const FONT_WEIGHT_DESC = 'normal';
const FONT_SIZE = '38px';
const FONT_SIZE_DESC = '20px';
const BACKGROUND_COLOR = '#F0F0F0';
const HEIGHT = '300px';
const HEIGHT_IMAGE = '0px';
const MARGIN = '0px 0px 8px 0px';
const MARGIN_IMAGE = '0px 0px 0px 0px';
const PADDING = '0px 0px 0px 0px';
const JUSTIFY_CONTENT = 'center';
const POSITION = 'relative';

const checkStyleForDefault = (style:string, defaultStyle:string) => {
  return style && style !== ''?style:defaultStyle;
}

const checkStyleForDefault3 = (defaultStyle:string) => {
  return defaultStyle as any;
}

const HeroPlugin: CellPlugin<Data> = {
  Renderer: ({ data }) => (
      <Row>
        <Col>
          <div style={{
              backgroundColor:checkStyleForDefault(data.backgroundColor, BACKGROUND_COLOR),
              backgroundImage:`url(${data.imageUrl})`,
              backgroundPosition:'center',
              backgroundSize:'cover',
              backgroundRepeat:'no-repeat',
              height:checkStyleForDefault(data.height, HEIGHT),
              display:'flex',
              justifyContent:'center',
              flexDirection:'column'
            }}>
            <div style={{
              display:'flex',
              alignItems:'center',
              justifyContent:'center',
              flexDirection:'column',
            }}>
              <h1 style={{
                color:checkStyleForDefault(data.fontColor, FONT_COLOR),
                fontSize:checkStyleForDefault(data.fontSize, FONT_SIZE),
                fontWeight:checkStyleForDefault3(data.fontWeight),
                margin:checkStyleForDefault(data.margin, MARGIN),
                lineHeight:'1.2',
                textAlign:'center'
              }}>
                {data.text}
              </h1>
              <p style={{
                color:checkStyleForDefault(data.fontColorDesc, FONT_COLOR_DESC),
                fontSize:checkStyleForDefault(data.fontSizeDesc, FONT_SIZE_DESC),
                fontWeight:checkStyleForDefault3(data.fontWeightDesc),
                margin:'0px',
                lineHeight:'1.5',
                textAlign:'center',
              }}>
                {data.description}
              </p>
            </div>

            <div style={{
              display:'flex',
              justifyContent:checkStyleForDefault(data.justifyContent, JUSTIFY_CONTENT),
              position:checkStyleForDefault3(data.position),
              top:0,
              right:0,
              bottom:0,
              left:0,
            }}>
              <img style={{ 
                width: 'auto',
                height:checkStyleForDefault(data.heightImage, HEIGHT_IMAGE),
                margin:checkStyleForDefault(data.marginImage, MARGIN_IMAGE),
              }} 
              src={data.imageUrl2} />
            </div>
          </div>
        </Col>
      </Row>
  ),
  id: 'heroPlugin',
  title: 'Hero Content',
  description: 'Hero with background image and text/image overlay property.',
  version: 1,
  controls: [
    {
      title: 'Heading',
      controls: {
        type: 'autoform',
        schema: {
          properties: {
            text: {
              type: 'string',
              default: '',
            },
            fontColor: {
              type: 'string',
              default: FONT_COLOR,
            },
            fontSize: {
              type: 'string',
              default: FONT_SIZE,
            },
            fontWeight: {
              type: 'string',
              default: FONT_WEIGHT,
            },
            margin: {
              type: 'string',
              default: MARGIN,
            },
          },
          required: [],
        },
      },
    },    
    {
      title: 'Subtitle',
      controls: {
        type: 'autoform',
        schema: {
          properties: {
            description: {
              type: 'string',
              default: '',
            },
            fontColorDesc: {
              type: 'string',
              default: FONT_COLOR_DESC,
            },
            fontSizeDesc: {
              type: 'string',
              default: FONT_SIZE_DESC,
            },
            fontWeightDesc: {
              type: 'string',
              default: FONT_WEIGHT_DESC,
            },
          },
          required: [],
        },
      },
    },    
    {
      title: 'Background',
      controls: {
        type: 'autoform',
        schema: {
          properties: {
            imageUrl: {
              type: 'string',
            },
            backgroundColor: {
              type: 'string',
              default: BACKGROUND_COLOR,
            },            
          },
          required: [],
        },
      }
    },
    {
      title: 'Styles',
      controls: {
        type: 'autoform',
        schema: {
          properties: {
            height: {
              type: 'string',
              default: HEIGHT,
            },
          },
        },
      },
    },
    {
      title: 'Image Overlay',
      controls: {
        type: 'autoform',
        schema: {
          properties: {
            imageUrl2: {
              type: 'string',
            },
            heightImage: {
              type: 'string',
              default: HEIGHT_IMAGE,
            },
            justifyContent: {
              type: 'string',
              default: JUSTIFY_CONTENT,
            },
            marginImage: {
              type: 'string',
              default: MARGIN_IMAGE,
            },
            position: {
              type: 'string',
              default: POSITION,
            }
          },
          required: [],
        },
      }
    },
  ]
};

export default HeroPlugin;