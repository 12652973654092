import { CellPlugin } from '@react-page/editor';
import { Form } from 'react-bootstrap';
import RequiredIcon from "../../../../../components/RequiredIcon";

type Data = {
  id: string,
  label: string,
  placeholder: string,
  text: string,
  minLength: number,
  maxLength: number,
  required: boolean,
  readOnly: boolean,
  margin: string,
  padding:string,
  fullStoryMask: boolean,
}

const LABEL = 'Label';
const REQUIRED = false;
const READ_ONLY = false;
const MAX_LENGTH = 50;
const MIN_LENGTH = 0;
const MARGIN = '0px';
const PADDING = '0px';
const FULL_STORY_MASK = false;

const FormInputTextboxPlugin: CellPlugin<Data> = {
  Renderer: ({ data }) => (
    <Form.Group controlId={`cmd_${data.id}`} style={{margin:data.margin,padding:data.padding}} className={data.fullStoryMask ? "fs-mask" : ""}>
      <Form.Label>{data.required?<RequiredIcon />:<></>}{data.label}</Form.Label>
      <Form.Control minLength={data.minLength} maxLength={data.maxLength} type="input" readOnly={data.readOnly} required={data.required} placeholder={data.placeholder} />
      {data.text?
        <Form.Text muted>
          {data.text}
        </Form.Text>
        :<></>
      }
      <Form.Control.Feedback type="invalid">Please provide a valid {data.label}.</Form.Control.Feedback>
    </Form.Group>
  ),
  id: 'formInputTextboxPlugin',
  title: 'Textbox Input',
  description: 'A textbox input.',
  version: 1,
  controls: {
    type: 'autoform',
    schema: {
      properties: {
        id: {
          type: 'string',
        },
        label: {
          type: 'string',
          default: LABEL,
        },
        placeholder: {
          type: 'string',
        },
        text: {
          type: 'string',
        },
        minLength: {
          type: 'number',
          default: MIN_LENGTH
        },
        maxLength: {
          type: 'number',
          default: MAX_LENGTH
        },
        readOnly: {
          type: 'boolean',
          default: READ_ONLY
        },
        required: {
          type: 'boolean',
          default: REQUIRED
        },
        margin: {
          type: 'string',
          default: MARGIN
        },
        padding: {
          type: 'string',
          default: PADDING
        },
        fullStoryMask:{
            type: 'boolean',
            default: FULL_STORY_MASK
        }
      },
      required: ['id', 'label', 'minLength', 'maxLength'],
    },
  },
};

export default FormInputTextboxPlugin;