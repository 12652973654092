import { CellPlugin } from '@react-page/editor';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import RequiredIcon from '../../../../../components/RequiredIcon';

type Option = {
    value: string,
    label: string,
}

type Data = {
    name: string,
    label: string,
    required: boolean,
    options: Option[],
    inline: boolean,
    margin: string,
    padding: string,
    fullStoryMask: boolean,
}

const LABEL = 'Option';
const NAME = 'option';
const REQUIRED = false;
const MARGIN = '0px';
const PADDING = '0px';
const FULL_STORY_MASK = false;

const RadioButtonPlugin: CellPlugin<Data> = {
    Renderer: ({ data }) => {
        const size = {width: '16px', height: '16px' };
        const [state, setState] = useState({});
        const [selected, setSelected] = useState(false);
        const handleChange = (e: any, name: string) => {
            e.persist();
            let _state: any = Object.assign({}, state);
            Object.keys(_state).map(function (key) {
                _state[key] = false;
            });
            _state[name] = e.target.checked;
            setState(_state);
        }
        useEffect(() => {
            let _selected = false;
            Object.keys(state).map(function (key) {
                if (state[key as keyof typeof state]) _selected = true;
            });
            if (selected != _selected) setSelected(_selected);
        }, [state]);
        return (
            <Form.Group style={{ margin: data.margin, padding: data.padding, alignItems: 'flex-end' }}  className={data.fullStoryMask ? "fs-mask" : ""}>
                <Form.Label>{(data.required) ? <RequiredIcon /> : ''}{data.label}</Form.Label>
                {data.options && data.options.map((option, index) => {
                    return (
                        <Form.Check inline={data.inline}>
                            <Form.Check.Input
                                id={`cmd_${data.name}-${option.value}`}
                                key={index} type={'radio'}
                                name={data.name}
                                value={state[option.value as keyof typeof state] ? 'yes' : 'no'}
                                onClick={(e: any) => handleChange(e, option.value)}
                                required={data.required && !selected} style={size} />
                            <Form.Check.Label>{option.label}</Form.Check.Label>
                        </Form.Check>
                    );
                })}
                <Form.Control hidden={true} required={data.required && !selected} />
                <Form.Control.Feedback type="invalid">Please choose a {data.label}.</Form.Control.Feedback>
            </Form.Group>
        );
    },
    id: 'RadioButtonPlugin',
    title: 'Radio Button',
    description: 'Add a Radio button component and define its options.',
    version: 1,
    controls: [
        {
            title: 'Labels',
            controls: {
                type: 'autoform',
                schema: {
                    properties: {
                        label: {
                            type: 'string',
                            default: LABEL,
                        },
                        name: {
                            type: 'string',
                            default: NAME,
                        },
                        required: {
                            type: 'boolean',
                            default: REQUIRED,
                        },
                        options: {
                            type: 'array',
                            items: {
                                type: 'object',
                                default: {
                                    value: 'option-1',
                                    label: 'Option 1',
                                },
                                properties: {
                                    value: {
                                        type: 'string',
                                    },
                                    label: {
                                        type: 'string',
                                    },
                                },
                                required: [],
                            },
                        },
                        inline: {
                            type: 'boolean',
                            default: true,
                        },
                        fullStoryMask:{
                            type: 'boolean',
                            default: FULL_STORY_MASK
                        }
                    },
                    required: [],
                },
            },
        },
        {
            title: 'Styles',
            controls: {
                type: 'autoform',
                schema: {
                    properties: {
                        margin: {
                            type: 'string',
                            default: MARGIN,
                        },
                        padding: {
                            type: 'string',
                            default: PADDING,
                        },
                    },
                    required: [],
                },
            },
        },
    ]
};

export default RadioButtonPlugin;