import { CellPlugin } from '@react-page/editor';
import React from 'react';
import { Col, Row } from 'react-bootstrap';

// use a type here, not an interface
type Data = {
    label: string,
    value: string
}

const getValue = (value: string) => {
    if (value && value.length > 0) {
        return value;
    } else {
        return "";
    }
}

const ReasonPropertyPlugin: CellPlugin<Data> = {
    Renderer: ({ data }) => (
        <Row>
            <Col><strong>{data.label}</strong></Col>
            <Col>{getValue(data.value)}</Col>
        </Row>
    ),
    id: 'reasonPropertyPlugin',
    title: 'Reason Property',
    description: 'Reason Property.',
    version: 1,
    controls: {
        type: 'autoform',
        schema: {
            properties: {
                label: {
                    type: 'string',
                    default: 'Reason',
                },
            },
            required: ['label'],
        },
    },
};

export default ReasonPropertyPlugin;