import { Shadows, Theme, createTheme } from '@mui/material/styles';
import { red } from '@mui/material/colors';
import { PaletteMode } from '@mui/material';

interface ThemeSettings {
    mode: PaletteMode;
    fontSize: number;
    fontFamily: string;
    colorBackground: string;
    colorPrimary: string;
}

declare module '@mui/material/styles' {
    interface Theme extends ThemeSettings { }
}

const createCustomTheme = (settings: ThemeSettings): Theme => {
    return createTheme({
        typography: {
            fontFamily: settings.fontFamily || "'Roboto', 'Helvetica', 'Arial', 'san-serif'",
            fontSize: settings.fontSize || 14,
        },
        palette: {
            mode: settings.mode || 'light',
            background: {
                default: settings.colorBackground || "#fff",
                paper: settings.colorBackground || "#fff",
            },
            primary: {
                main: settings.colorPrimary || '#556cd6',
            },
            secondary: {
                main: '#19857b',
            },
            error: {
                main: red.A400,
            },
        },
        components: {
            MuiFormLabel: {
                styleOverrides: {
                    asterisk: { color: "red" },
                },
            },
            MuiAccordion: {
                defaultProps: {
                    elevation: 0,
                },
            },
            MuiCssBaseline: {
                'styleOverrides': {
                    '.grecaptcha-badge': {
                        visibility: 'hidden',
                    },
                },
            },
            MuiTextField: {
                styleOverrides: {
                    root: {
                        '&.blurry input:not(:focus)': {
                            color: 'transparent',
                            textShadow: '0 0 1.0em rgba(0,0,0,0.5)',
                        },
                        '& .MuiInput-underline:before': {
                            borderBottomColor: 'whitesmoke',
                        },
                    },
                },
            },
        },
        shadows: Array(25).fill("none") as Shadows,
    });
};

export default createCustomTheme;
