import React, { useState } from "react";
import PageFooter from '../components/layout/PageFooter';
import { IAppState } from '../redux/storeTypes';
import { connect } from 'react-redux';
import { Button, Col, Container, Form, Modal, Row } from "react-bootstrap";
import LoginHeader from "../components/layout/LoginHeader";
import RequiredIcon from "../components/RequiredIcon";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons'
import FormActions from "../components/layout/FormActions";
import { Link } from "react-router-dom";

export interface IRegister {

}

const Register = () => {
    const [pwd, setPwd] = useState('');
    const [showForm, setShowForm] = useState<boolean>(false);
    const [validated, setValidated] = useState<boolean>(false);
    const renderPwdHints = () => {
        const check1 = pwd.length >= 8;
        const check2 = pwd.toLowerCase() !== pwd;
        const check3 = /[0-9]/.test(pwd);
        const check4 = /[\!\@#\$%\^\&\*]/.test(pwd);        
            
        return (
            <div className="password-strength-hint">
                <h6 className={check1 && check2 && check3 && check4 ? 'green-border' : ''}>Password strength</h6>
                <ul>
                    <li className={check1 ? 'pwd-success' : ''}>
                        <FontAwesomeIcon icon={faCheckCircle} color={check1 ? '#52C41A' : '#BEBEBE'} />
                        <span>At least 8 characters</span>
                    </li>
                    <li className={check2 ? 'pwd-success' : ''}>
                        <FontAwesomeIcon icon={faCheckCircle} color={check2 ? '#52C41A' : '#BEBEBE'} />
                        <span>Includes at least one uppercase letter</span>
                    </li>
                    <li className={check3 ? 'pwd-success' : ''}>
                        <FontAwesomeIcon icon={faCheckCircle} color={check3 ? '#52C41A' : '#BEBEBE'} />
                        <span>Includes at least one number</span>
                    </li>
                    <li className={check4 ? 'pwd-success' : ''}>
                        <FontAwesomeIcon icon={faCheckCircle} color={check4 ? '#52C41A' : '#BEBEBE'} />
                        <span>Includes a special character (!@#$%^&*)</span>
                    </li>
                </ul>
            </div>
        )
    }
    const clearForm = () => {        
        setShowForm(false);
        setValidated(false);
    }
    return (
        <>
            <Modal show={showForm} size="lg" onHide={() => setShowForm(false)} >
                <Modal.Body className="success-login" >
                <FontAwesomeIcon icon={faCheckCircle} style={{ color: "#52C41A"}} size="2x" />
                <h5>Your account has been created</h5>
                <span>A verification email has been sent to your email address. Please follow the instructions in the email to activate your account.</span>
                <br />                
                <FormActions onCancel={clearForm} />
                </Modal.Body>
            </Modal>
            
            <LoginHeader />

            <Container className="registration-container mt-4">
                <h2 className="registration-header">Register</h2>
                <Form className="registration-form">
                    <h3>Create account</h3>
                    <p className="mb-4">Create an account to manage your payments.</p>
                    <Form.Group controlId="zipCode" className="mb-4">
                        <Form.Label><RequiredIcon />Account number</Form.Label>
                        <Form.Control required type="input" placeholder="Enter account number" />
                        <Form.Control.Feedback type="invalid">Account number is required.</Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group controlId="zipCode" className="mb-4">
                        <Form.Label><RequiredIcon />Email address</Form.Label>
                        <Form.Control required type="input" placeholder="Enter email address" />
                        <Form.Control.Feedback type="invalid">Email address is required.</Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group controlId="pwd" className="mb-4">
                        <Form.Label><RequiredIcon />Create password</Form.Label>
                        <Form.Control required type="password" placeholder="Enter password" onChange={e => setPwd(e.target.value)} />
                        <Form.Control.Feedback type="invalid">Password is required.</Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group controlId="cpwd" className="mb-4">
                        <Form.Label><RequiredIcon />Confirm password</Form.Label>
                        <Form.Control required type="password" placeholder="Confirm password" />
                        <Form.Control.Feedback type="invalid">Confirm password is required.</Form.Control.Feedback>
                    </Form.Group>
                    {renderPwdHints()}
                    <div className='mt-4 text-center'>
                    <Row>
                        <Col>
                            <Button className="brand-btn-primary" onClick={() => setShowForm(true)}>Register</Button>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Link className="brand-link-primary" to={""}>Cancel</Link>
                        </Col>
                    </Row>
                    </div>
                </Form>
            </Container>
            <p className="registration-acknowledge">Registrant hereby acknowledges that he or she is the valid, authorized signatory for this account, with full responsibility for decisions related to this account.</p>
            <PageFooter />
        </>
    );
};

const mapStateToProps = (state: IAppState) => {
    return {
    };
};

export default connect(mapStateToProps)(Register);