import {
    CLEAR_REQUEST,
    ADD_CARD_REQUEST, ADD_CARD_SUCCESS, ADD_CARD_FAILURE,
    SAVE_CARD_REQUEST,
    ADD_BANKACCOUNT_REQUEST, ADD_BANKACCOUNT_SUCCESS, ADD_BANKACCOUNT_FAILURE,
    SAVE_BANKACCOUNT_REQUEST,
} from '../actions/wallet';

export default function wallet(state = {
        card: null,
        bankAccount: null,
        errorMessage: '',
        actionResult: null
    }, { type, payload }) {
    switch (type) {
        case CLEAR_REQUEST:
            return Object.assign({}, state, {
                errorMessage: '',
                card: null,
                bankAccount: null,
                actionResult: { type: CLEAR_REQUEST, result: null, token: payload.actionToken },
            });
        case SAVE_CARD_REQUEST:
            return Object.assign({}, state, {
                errorMessage: '',
                card: Object.assign({}, payload.card),
                actionResult: { type: SAVE_CARD_REQUEST, result: null, token: payload.actionToken },
            });
        case ADD_CARD_REQUEST:
            return Object.assign({}, state, {
                errorMessage: '',
                actionResult: { type: ADD_CARD_REQUEST, result: null, token: payload.actionToken },
            });
        case ADD_CARD_SUCCESS:
            return Object.assign({}, state, {
                errorMessage: payload.error,
                actionResult: { type: ADD_CARD_REQUEST, result: ADD_CARD_SUCCESS, token: payload.actionToken },
            });
        case ADD_CARD_FAILURE:
            return Object.assign({}, state, {
                errorMessage: payload.error,
                actionResult: { type: ADD_CARD_REQUEST, result: ADD_CARD_FAILURE, token: payload.actionToken, message: payload.error },
            });
        case SAVE_BANKACCOUNT_REQUEST:
            return Object.assign({}, state, {
                errorMessage: '',
                bankAccount: Object.assign({}, payload.bankAccount),
                actionResult: { type: SAVE_BANKACCOUNT_REQUEST, result: null, token: payload.actionToken },
            });
        case ADD_BANKACCOUNT_REQUEST:
            return Object.assign({}, state, {
                errorMessage: '',
                actionResult: { type: ADD_BANKACCOUNT_REQUEST, result: null, token: payload.actionToken },
            });
        case ADD_BANKACCOUNT_SUCCESS:
            return Object.assign({}, state, {
                errorMessage: payload.error,
                actionResult: { type: ADD_BANKACCOUNT_REQUEST, result: ADD_BANKACCOUNT_SUCCESS, token: payload.actionToken },
            });
        case ADD_BANKACCOUNT_FAILURE:
            return Object.assign({}, state, {
                errorMessage: payload.error,
                actionResult: { type: ADD_BANKACCOUNT_REQUEST, result: ADD_BANKACCOUNT_FAILURE, token: payload.actionToken, message: payload.error },
            });
        default:
            return state;
    }
}
