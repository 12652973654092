import { Row, Col, Form, Button, Modal } from "react-bootstrap"
import { useState } from "react";
import { Field } from "../ModalDetailPlugin";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faQuestionCircle } from "@fortawesome/free-solid-svg-icons";
import React from "react";
import _ from "lodash";

interface IModalDetail {
    infoLabel: string,
    title: string,
    subTitle: string,
    itemLists: Field[],
    margin: string,
    padding: string,
    buttonBackgroundColor: string,
    buttonFontColor: string,
    buttonFontSize: string,
    buttonFontStyle: string,
    buttonFontWeight: string,
    buttonBorderColor: string,
    buttonIconColor: string,
    titleColor: string,
    subHeadingColor: string,
}

export const INFO_LABEL = ''
export const TITLE = 'Heading';
export const SUB_TITLE = ''
export const MARGIN = '0px';
export const PADDING = '0px';
export const BUTTON_FONT_COLOR = '#414141';
export const BUTTON_FONT_SIZE = '.875rem';
export const BUTTON_FONT_STYLE = 'normal';
export const BUTTON_FONT_WEIGHT = 'normal';
export const BUTTON_BORDER_COLOR = '#D9D9D9';
export const BUTTON_BACKGROUND_COLOR = '#FFFFFF';
export const BUTTON_ICON_COLOR = '#414141';
export const TITLE_COLOR = '#414141';
export const SUB_HEADING_COLOR = '#414141';


const checkStyleForDefault = (style: string, defaultStyle: string) => {
    return style && style !== '' ? style : defaultStyle;
}

const checkStyleForDefault3 = (defaultStyle: string) => {
    return defaultStyle as any;
}

const ModalDetail = ({ infoLabel, title, subTitle, itemLists, margin, padding, buttonBackgroundColor, buttonFontColor, buttonFontSize,
    buttonFontStyle, buttonFontWeight, buttonBorderColor, buttonIconColor, titleColor, subHeadingColor }: IModalDetail) => {

    const [showModal, setShowModal] = useState<boolean>(false);

    if (!infoLabel) { infoLabel = INFO_LABEL }
    if (!title) { title = TITLE }
    if (!subTitle) { subTitle = SUB_TITLE }
    if (!buttonBackgroundColor) { buttonBackgroundColor = BUTTON_BACKGROUND_COLOR }
    if (!buttonFontColor) { buttonFontColor = BUTTON_FONT_COLOR }
    if (!buttonFontSize) { buttonFontSize = BUTTON_FONT_SIZE }
    if (!buttonFontWeight) { buttonFontWeight = BUTTON_FONT_WEIGHT }
    if (!buttonFontStyle) { buttonFontStyle = BUTTON_FONT_STYLE }
    if (!buttonBorderColor) { buttonBorderColor = BUTTON_BORDER_COLOR }
    if (!buttonIconColor) { buttonIconColor = BUTTON_ICON_COLOR }
    if (!titleColor) { titleColor = TITLE_COLOR }
    if (!subHeadingColor) { subHeadingColor = SUB_HEADING_COLOR }

    const renderModal = () => {
        return (
            itemLists && itemLists.map((fields) => (
                <div style={{ marginBottom: '20px' }}>
                    <h2 style={{color: checkStyleForDefault(subHeadingColor, SUB_HEADING_COLOR)}}>{fields.key}</h2>
                    {formatText(fields.value)}
                </div>
            ))
        )
    }

    const formatText = (text: any) => {
        const lines = _.split(text, '//');
        return lines.map((line: any, index: any) => (
            <React.Fragment key={index}>
                <div style={{ marginLeft: '12px' }}> <li>{line}</li> </div>
            </React.Fragment>
        ))
    }

    return (
        <div style={{ margin: margin, padding: padding }}>
            <Row>
                <Col>
                    <Button className="brand-btn-primary" style={{
                        marginTop: '29px',
                        backgroundColor: checkStyleForDefault(buttonBackgroundColor, BUTTON_BACKGROUND_COLOR),
                        borderColor: checkStyleForDefault(buttonBorderColor, BUTTON_BORDER_COLOR),
                        color: checkStyleForDefault(buttonFontColor, BUTTON_FONT_COLOR),
                        fontSize: checkStyleForDefault(buttonFontSize, BUTTON_FONT_SIZE),
                        fontStyle: checkStyleForDefault(buttonFontStyle, BUTTON_FONT_STYLE),
                        fontWeight: checkStyleForDefault3(buttonFontWeight)
                    }} onClick={() => setShowModal(true)}>
                        <FontAwesomeIcon icon={faQuestionCircle} style={{ color: checkStyleForDefault(buttonIconColor, BUTTON_ICON_COLOR) }} className="btn-icon" />{infoLabel}
                    </Button>
                </Col>
            </Row>
            <Modal show={showModal} size={'lg'} backdrop="static" onHide={() => setShowModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>
                        <h2 style={{ fontWeight: 700 , color: checkStyleForDefault(titleColor, TITLE_COLOR)}}>{title}</h2>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Group>
                    <div style={{ backgroundColor: '#f0f0f0', margin: '8px 0', opacity: 1, height: '0.0625rem'}}></div>
                        {renderModal()}
                    </Form.Group>
                </Modal.Body>
            </Modal>
        </div>

    )
}

export default ModalDetail;