import { CellPlugin } from '@react-page/editor';
import NavigationLink, { ALIGN, BACKGROUND_COLOR, FONT_COLOR, FONT_SIZE, FONT_STYLE, FONT_WEIGHT, MARGIN, PADDING, TEXT_ALIGN, TEXT_DECORATION, TEXT_TRANSFORM } from './components/NavigationLink';

type Data = {
  text: string,
  path: string,
  align: string,
  backgroundColor: string,
  fontColor: string,
  fontSize: string,
  fontStyle: string,
  fontWeight: string,
  textAlign: string,
  textDecoration: string,
  textTransform: string,
  margin: string,
  padding: string,
}


const NavigationLinkPlugin: CellPlugin<Data> = {
  Renderer: ({ data }) => (
        <NavigationLink
          text={data.text}
          path={data.path}
          align={data.align}
          backgroundColor={data.backgroundColor}
          fontColor={data.fontColor}
          fontSize={data.fontSize}
          fontStyle={data.fontStyle}
          fontWeight={data.fontWeight}
          textAlign={data.textAlign}
          textDecoration={data.textDecoration}
          textTransform={data.textTransform}
          margin={data.margin}
          padding={data.padding}
        />
  ),
  id: 'navigationLinkPlugin',
  title: 'Navigation Link',
  description: 'A navigation link.',
  version: 1,
  controls: [
    {
      title: 'Labels',
      controls: {
        type: 'autoform',
        schema: {
          properties: {
            text: {
              type: 'string',
              default: '',
            },
            path: {
              type: 'string',
              default: '',
            },
          },
          required: ['text', 'path'],
        },
      },
    },
    {
      title: 'Styles',
      controls: {
        type: 'autoform',
        schema: {
          properties: {
            align: {
              type: 'string',
              default: ALIGN,
            },
            backgroundColor: {
              type: 'string',
              default: BACKGROUND_COLOR,
            },
            fontColor: {
              type: 'string',
              default: FONT_COLOR,
            },
            fontSize: {
              type: 'string',
              default: FONT_SIZE,
            },
            fontStyle: {
              type: 'string',
              default: FONT_STYLE,
            },
            fontWeight: {
              type: 'string',
              default: FONT_WEIGHT,
            },
            textAlign: {
              type: 'string',
              default: TEXT_ALIGN,
            },
            textDecoration: {
              type: 'string',
              default: TEXT_DECORATION,
            },
            textTransform: {
              type: 'string',
              default: TEXT_TRANSFORM,
            },
            margin: {
              type: 'string',
              default: MARGIN,
            },
            padding: {
              type: 'string',
              default: PADDING,
            },
          },
        },
      },
    },
  ]
};

export default NavigationLinkPlugin;