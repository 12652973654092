export enum Method {
    NONE = 0,
    CARD = 1,
    ACH = 2
}

export enum AddressRequirement {
    NOT_REQUIRED = 0,
    FULL = 1,
    ZIP_ONLY = 2
}

export enum ContactRequirement {
    NOT_REQUIRED = 0,
    FULL = 1,
    EMAIL_ONLY = 2,
    PHONE_ONLY = 3
}

export class InvoiceItem {
    reference: string = "";
    name: string = "";
    amount: string = "0.00";
    supplementalData?: string = "";
}

export class Billing {
    name: string = "";
    address1: string = "";
    address2: string = "";
    city: string = "";
    state: string = "";
    zip: string = "";
    country: string = "";
}

export class Contact {
    phone: string = "";
    email: string = "";
}

export class Checkout {
    invoice: Array<InvoiceItem> = [];
    amount: string = "0.00";
    convenienceFee: string = "0.00";
    total: string = "0.00";
    iDs: string = "";
    uniqueId: string = "";
    payerName: string = "";
    billing: Billing = new Billing();
    contact: Contact = new Contact();
    initiatedBy: string = "";
    memo: string = "";
    terminalName: string = "";
    authorizeCard: boolean = false;
    offerTokenizing: boolean = false;
    cardMethod: boolean = false;
    eCheckMethod: boolean = false;
    terminalMethod: boolean = false;
    cardAddressRequirement: AddressRequirement = AddressRequirement.ZIP_ONLY;
    eCheckAddressRequirement: AddressRequirement = AddressRequirement.NOT_REQUIRED;
    terminalAddressRequirement: AddressRequirement = AddressRequirement.NOT_REQUIRED;
    cardContactRequirement: ContactRequirement = ContactRequirement.NOT_REQUIRED;
    eCheckContactRequirement: ContactRequirement = ContactRequirement.NOT_REQUIRED;
    terminalContactRequirement: ContactRequirement = ContactRequirement.NOT_REQUIRED;
    style: any = {};
}

export class CheckoutStatus {
    invoice: boolean = false;
    payBy: boolean = false;
    summary: boolean = false;
}

export enum CheckoutView {
    NONE = 0,
    INVOICE = 1,
    PAYBY = 2,
    SUMMARY = 3
}

export class Link {
    iDs: string = "";
    uniqueId: string = "";
    payerName: string = "";
    billing: Billing = new Billing();
    contact: Contact = new Contact();
    initiatedBy: string = "";
    memo: string = "";
    method: Method = Method.CARD;
    addressRequirement: AddressRequirement = AddressRequirement.NOT_REQUIRED;
    contactRequirement: ContactRequirement = ContactRequirement.EMAIL_ONLY;
    style: any = {};
}
export class LinkStatus {
    withMethod: boolean = false;
    summary: boolean = false;
}

export enum LinkView {
    NONE = 0,
    WITHMETHOD = 1,
    SUMMARY = 2
}

