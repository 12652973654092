import { NumbersOutlined, ChecklistOutlined, CurrencyExchangeOutlined, AccountBalanceOutlined } from "@mui/icons-material";
import { Box, Grid, TextField, Button, InputAdornment, useTheme } from "@mui/material";
import { IAppState } from "../../redux/storeTypes";
import { Link, Billing, Contact, LinkStatus } from "../../models/CheckoutLink";
import { connect, useDispatch } from "react-redux";
import { useEffect, useRef } from "react";
import { LinkBilling } from "./LinkBilling";
import { clearBankName, getBankName } from "../../redux/actions/orderManagement";
import { BankAccountType, TokenizeBankAccount, WalletType } from "../../models/Wallet";
import { saveBankAccountAction } from "../../redux/actions/wallet";
import { statusLinkAction } from "../../redux/actions/link";

export interface IWithMethodECheckProps {
    bankName: string;
    link: Link;
    status: LinkStatus;
    account: TokenizeBankAccount;
    setStatus: any;
}

const WithMethodECheck = ({ bankName, link, status, account, setStatus }: IWithMethodECheckProps) => {
    const dispatch = useDispatch();
    const theme = useTheme();
    const accountNumber = useRef<any>("");
    const confirmNumber = useRef<any>("");
    const routingNumber = useRef<any>("");
    const institution = useRef<any>("");

    const billingName = useRef<any>("");
    const billingAddress1 = useRef<any>("");
    const billingAddress2 = useRef<any>("");
    const billingCity = useRef<any>("");
    const billingState = useRef<any>("");
    const billingZip = useRef<any>("");
    const billingCountry = useRef<any>("");
    const billingPhone = useRef<any>("");
    const billingEmail = useRef<any>("");

    useEffect(() => {
        if (bankName) {
            institution.current.value = bankName;
            institution.current.setCustomValidity("");
        } else {
            institution.current.value = "";
            institution.current.setCustomValidity("Ensure valid routing number");
        }
    }, [bankName]);

    const validate = (event: any) => {
        event.preventDefault();
        const form = event.currentTarget;

        if (form.reportValidity()) {
            account.walletType = WalletType.Personal;
            account.bankAccountType = BankAccountType.Checking;
            account.isImplicitAuthorization = true;
            const ids = JSON.parse(link.iDs);
            account.paymentChannelId = ids.paymentChannelId;
            account.externalCustomerReference = link.uniqueId;
            account.accountHolderEmailAddress = billingEmail.current.value;
            account.accountHolderName = billingName.current.value;
            account.accountNumber = accountNumber.current.value;
            account.routingNumber = routingNumber.current.value;
            account.addressLine1 = billingAddress1.current.value;
            account.addressLine2 = billingAddress2.current.value;
            account.city = billingCity.current.value;
            account.state = billingState.current.value;
            account.zipCode = billingZip.current.value;
            account.country = billingCountry.current.value;
            dispatch(saveBankAccountAction(account, "LinkPage"));
            setStatus();
        }
    }

    const handleChange = () => {
        if (status.withMethod) dispatch(statusLinkAction({ ...status, withMethod: false }));
    }

    return (
        <form onSubmit={validate} onChange={handleChange}>
            <Grid container spacing="3" style={{ paddingTop: "8px" }}>
                <Grid item xs>
                    <TextField
                        className={'blurry fs-mask'}
                        required
                        label="Account Number"
                        fullWidth
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <NumbersOutlined sx={{ color: "lightgrey" }} />
                                </InputAdornment>
                            ),
                        }}
                        size="small"
                        autoComplete="off"
                        onKeyPress={(event) => { if (!/[0-9]/.test(event.key)) { event.preventDefault(); } }}
                        onBlur={(e) => {
                            if (e.target.value.length > 0 && confirmNumber.current.value.length > 0) {
                                if (e.target.value !== confirmNumber.current.value) {
                                    e.target.setCustomValidity("Ensure account numbers match");
                                } else {
                                    e.target.setCustomValidity("");
                                    confirmNumber.current.setCustomValidity("");
                                }
                            }
                        }}
                        onInvalid={(e) => {
                            let target = e.target as HTMLInputElement;
                            if (target.validity.tooShort) target.setCustomValidity("Please complete this entry")
                        }}
                        onInput={(e) => {
                            let target = e.target as HTMLInputElement;
                            if (target.validity.customError && target.validity.tooShort) {
                                target.setCustomValidity("Please complete this entry")
                            } else {
                                target.setCustomValidity("")
                            }
                        }}
                        inputProps={{ minLength: 4, maxLength: 17, }}
                        inputRef={accountNumber}
                    />
                </Grid>
            </Grid>
            <Grid container spacing="3" style={{ paddingTop: "8px" }}>
                <Grid item xs>
                    <TextField
                        className={'blurry fs-mask'}
                        required
                        label="Confirm Account Number"
                        fullWidth
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <ChecklistOutlined sx={{ color: "lightgrey" }} />
                                </InputAdornment>
                            ),
                        }}
                        size="small"
                        autoComplete="off"
                        onCut={(event) => event.preventDefault()}
                        onCopy={(event) => event.preventDefault()}
                        onPaste={(event) => event.preventDefault()}
                        onKeyPress={(event) => { if (!/[0-9]/.test(event.key)) { event.preventDefault(); } }}
                        onBlur={(e) => {
                            if (e.target.value.length > 0 && accountNumber.current.value.length > 0) {
                                if (e.target.value !== accountNumber.current.value) {
                                    e.target.setCustomValidity("Ensure account numbers match");
                                } else {
                                    e.target.setCustomValidity("");
                                    accountNumber.current.setCustomValidity("");
                                }
                            }
                        }}
                        onInvalid={(e) => {
                            let target = e.target as HTMLInputElement;
                            if (target.validity.tooShort) target.setCustomValidity("Please complete this entry")
                        }}
                        onInput={(e) => {
                            let target = e.target as HTMLInputElement;
                            if (target.validity.customError && target.validity.tooShort) {
                                target.setCustomValidity("Please complete this entry")
                            } else {
                                target.setCustomValidity("")
                            }
                        }}
                        inputProps={{ minLength: 4, maxLength: 17, }}
                        inputRef={confirmNumber}
                    />
                </Grid>
            </Grid>
            <Grid container spacing="3" style={{ paddingTop: "8px" }}>
                <Grid item xs>
                    <TextField
                        className={'blurry fs-mask'}
                        required
                        label="Routing Number"
                        fullWidth
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <CurrencyExchangeOutlined sx={{ color: "lightgrey" }} />
                                </InputAdornment>
                            ),
                        }}
                        size="small"
                        autoComplete="off"
                        onKeyPress={(event) => { if (!/[0-9]/.test(event.key)) { event.preventDefault(); } }}
                        onBlur={(e) => {
                            if (e.target.value.length >= 9) {
                                dispatch(getBankName(e.target.value));
                            } else {
                                dispatch(clearBankName());
                            }
                        }}
                        onInvalid={(e) => {
                            let target = e.target as HTMLInputElement;
                            if (target.validity.tooShort) target.setCustomValidity("Please complete this entry")
                        }}
                        onInput={(e) => {
                            let target = e.target as HTMLInputElement;
                            if (target.validity.customError && target.validity.tooShort) {
                                target.setCustomValidity("Please complete this entry")
                            } else {
                                target.setCustomValidity("")
                            }
                        }}
                        inputProps={{ minLength: 9, maxLength: 9 }}
                        inputRef={routingNumber}
                    />
                </Grid>
            </Grid>
            <Grid container spacing="3" style={{ paddingTop: "8px" }}>
                <Grid item xs>
                    <TextField
                        required
                        label="Banking Institution"
                        fullWidth
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <AccountBalanceOutlined sx={{ color: "lightgrey" }} />
                                </InputAdornment>
                            ),
                            sx: {
                                backgroundColor: theme.palette.action.hover,
                            }
                        }}
                        size="small"
                        onKeyDown={(event) => { event.preventDefault() }}
                        inputProps={{ readOnly: false, tabIndex: -1 }}
                        inputRef={institution}
                    />
                </Grid>
            </Grid>
            <LinkBilling
                payerName={link.payerName}
                billing={link.billing || new Billing()}
                contact={link.contact || new Contact()}
                addressRequirement={link.addressRequirement}
                contactRequirement={link.contactRequirement}
                billingName={billingName}
                billingAddress1={billingAddress1}
                billingAddress2={billingAddress2}
                billingCity={billingCity}
                billingState={billingState}
                billingZip={billingZip}
                billingCountry={billingCountry}
                billingPhone={billingPhone}
                billingEmail={billingEmail}
            />
            <Box sx={{ textAlign: 'center', paddingTop: '8px' }}>
                <Button type="submit" fullWidth variant="contained" size='small' color="primary">CONTINUE</Button>
            </Box>                        
        </form>
    );
};

const mapStateToProps = (state: IAppState) => {
    return {
        bankName: state.orderManagement.bankName,
        link: state.link.link,
        status: state.link.status,
        account: state.wallet.bankAccount,
    };
};

export default connect(mapStateToProps)(WithMethodECheck);

  