import React, { useState } from "react";
import {  Row, Col, Form, Modal, InputGroup } from 'react-bootstrap';
import RequiredIcon from '../../components/RequiredIcon';
import FormActions from '../../components/layout/FormActions';
import { connect, useDispatch } from "react-redux";
import { IAppState } from "../../redux/storeTypes";
import { faPlus } from '@fortawesome/pro-light-svg-icons';
import { faEye, faEyeSlash } from "@fortawesome/pro-regular-svg-icons";
import { Card } from "../../models/User";
import Switch from "react-switch";
import { sendSuccessToastAction, sendWarningToastAction } from "../../redux/actions/toast";
import { saveCardDetails } from "../../redux/actions/paymentMethods";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import InputMask from "react-input-mask";
import BillingAddress from "../BillingAddress";

export interface ICheckout {
    showForm: boolean,
    setShowForm: (value: boolean) => void
}

const Checkout = ({ showForm, setShowForm }: ICheckout) => {
    const dispatch = useDispatch();
    const [validated, setValidated] = useState(false);
    const [useSameAddress, setUseSameAddress] = useState(true);
    const [paymentType, setPaymentType] = useState('');
    const [showCardNumber, setShowCardNumber] = useState(false);
    const [showCVV, setShowCVV] = useState(false);
    const [defaultCard, setDefaultCard] = useState(false);

    const handleSubmit = (event: any) => {
        const form = event.currentTarget;
        event.preventDefault();
        if (form.checkValidity() !== false) {
            const card = new Card();
            card.cardType = form.elements?.cardType?.value;
            card.cardholderName = form.elements?.nameOnCard?.value;
            card.cardNumber = form.elements?.cardNumber?.value;
            card.paymentType = form.elements?.paymentType?.value;
            card.expDate = form.elements?.expirationDate?.value;
            card.default = defaultCard;
            dispatch(saveCardDetails(card, 'saveCard'));
            dispatch(sendSuccessToastAction("User access has been successfully updated"))
            setShowForm(false);
        }
        else {
            dispatch(sendWarningToastAction("Please Check Constituents Details"));
        }
        setValidated(true);

    };
    const clearForm = () => {
        setShowForm(false);
        setValidated(false);
    }

    const renderToggles = () => (
        <>
            <Row>
                <Switch
                    onChange={setUseSameAddress}
                    checked={useSameAddress}
                    onColor={'#0057B6'}
                    offColor={'#BEBEBE'}
                    handleDiameter={18}
                    uncheckedIcon={false}
                    checkedIcon={false}
                    height={22}
                    width={44}
                />
                <span>Use the same billing address</span>
            </Row>
            {!useSameAddress &&
                (
                    <BillingAddress />
                
                )
            }
            <Row>
                <Switch
                    onChange={setDefaultCard}
                    checked={defaultCard}
                    onColor={'#0057B6'}
                    offColor={'#BEBEBE'}
                    handleDiameter={18}
                    uncheckedIcon={false}
                    checkedIcon={false}
                    height={22}
                    width={44}
                />
                <span>Set as default</span>
            </Row>
        </>
    );
    return (
        <>
            <Modal show={showForm} size="lg" onHide={() => setShowForm(false)}>
                <Modal.Header closeButton />
                <Modal.Body>
                    <h3 className="fw-bold mb-4">Add new Payment method</h3>
                    <Form id='' noValidate validated={validated} onSubmit={handleSubmit}>
                        <Row>
                            <Form.Group controlId="paymentType">
                                <Form.Label>Payment Type</Form.Label>
                                <Form.Control as='select' required value={paymentType} onChange={e => setPaymentType(e.target.value)}>
                                    <option value='' selected>Select Payment type</option>
                                    <option value='creditCard'>Credit Card</option>
                                    <option value='eCheck'>eCheck</option>
                                </Form.Control>
                            </Form.Group>
                        </Row>
                        {paymentType === 'creditCard' &&
                            (
                                <>
                                    <Row>
                                        <Form.Group controlId="cardType">
                                            <Form.Label><RequiredIcon />Card type</Form.Label>
                                            <Form.Control as="select" required>
                                                <option value=''>Select card type</option>
                                                <option value='amex'>American Express</option>
                                                <option value='amex-debit'>American Express - Debit</option>
                                                <option value='disc'>Discover</option>
                                                <option value='disc-debit'>Discover - Debit</option>
                                                <option value='master'>Master Card</option>
                                                <option value='master-debit'>Master Card - Debit</option>
                                                <option value='visa'>Visa</option>
                                                <option value='visa-debit'>Visa - Debit</option>
                                            </Form.Control>
                                            <Form.Control.Feedback type="invalid">Select the Card type.</Form.Control.Feedback>
                                        </Form.Group>
                                    </Row>
                                    <Row>
                                        <Form.Group controlId="nameOnCard">
                                            <Form.Label><RequiredIcon />Cardholder name</Form.Label>
                                            <Form.Control type="input" required maxLength={50} placeholder="Cardholder name" />
                                            <Form.Control.Feedback type="invalid">Enter the cardholder’s name.</Form.Control.Feedback>
                                        </Form.Group>
                                    </Row>
                                    <Row>
                                            <Form.Group controlId="cardNumber">
                                                <Form.Label><RequiredIcon />Card number</Form.Label>
                                                <InputGroup hasValidation>
                                                    <Form.Control type={showCardNumber ? "text" : "password"} required minLength={12} maxLength={16} className="password" />
                                                    <InputGroup.Append>
                                                        <InputGroup.Text onClick={() => setShowCardNumber(!showCardNumber)}>
                                                            <FontAwesomeIcon icon={showCardNumber ? faEyeSlash : faEye} size="sm" />
                                                        </InputGroup.Text>
                                                    </InputGroup.Append>
                                                    <Form.Control.Feedback type="invalid">Please enter a valid 12-16 digit long Card number.</Form.Control.Feedback>
                                                </InputGroup>
                                            </Form.Group>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <Form.Group controlId="expirationDate">
                                                <Form.Label><RequiredIcon />Expiration date</Form.Label>
                                                <InputMask id="expirationDate" name="expirationDate" required type="input" pattern="[0-9\/\s]{5}" mask="99/99" className="form-control" />
                                                <Form.Control.Feedback type="invalid">Please enter the Expiration date.</Form.Control.Feedback>
                                            </Form.Group>
                                        </Col>
                                        <Col>
                                            <Form.Group controlId="cvv">
                                                <Form.Label><RequiredIcon />CVV</Form.Label>
                                                <InputGroup hasValidation>
                                                    <Form.Control type={showCVV ? "text" : "password"} required minLength={3} maxLength={4} className="password" />
                                                    <InputGroup.Append>
                                                        <InputGroup.Text onClick={() => setShowCVV(!showCVV)}>
                                                            <FontAwesomeIcon icon={showCVV ? faEyeSlash : faEye} size="sm" />
                                                        </InputGroup.Text>
                                                    </InputGroup.Append>
                                                    <Form.Control.Feedback type="invalid">Please enter the CVV number.</Form.Control.Feedback>
                                                </InputGroup>
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    {renderToggles()}
                                </>
                            )}
                        {paymentType === 'eCheck' &&
                            (
                                <>
                                    <Row>
                                        <Form.Group controlId="">
                                            <Form.Label><RequiredIcon />Name on account</Form.Label>
                                            <Form.Control type="input" required maxLength={100} placeholder="Enter name" />
                                            <Form.Control.Feedback type="invalid">Please enter account holder's name.</Form.Control.Feedback>
                                        </Form.Group>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <Form.Group controlId="">
                                                <Form.Label><RequiredIcon />Routing number</Form.Label>
                                                <Form.Control type="number" required maxLength={32} placeholder="Enter routing number" />
                                                <Form.Control.Feedback type="invalid">Please enter routing number.</Form.Control.Feedback>
                                            </Form.Group>
                                        </Col>
                                        <Col>
                                            <Form.Group controlId="">
                                                <Form.Label><RequiredIcon />Confirm routing number</Form.Label>
                                                <Form.Control type="number" required maxLength={32} placeholder="Confirm routing number" />
                                                <Form.Control.Feedback type="invalid">Please confirm routing number.</Form.Control.Feedback>
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <Form.Group controlId="">
                                                <Form.Label><RequiredIcon />Account number</Form.Label>                                              
                                                <InputGroup hasValidation>
                                                    <Form.Control type={showCardNumber ? "text" : "password"} required minLength={12} maxLength={16} className="password" />
                                                    <InputGroup.Append>
                                                        <InputGroup.Text onClick={() => setShowCardNumber(!showCardNumber)}>
                                                            <FontAwesomeIcon icon={showCardNumber ? faEyeSlash : faEye} size="sm" />
                                                        </InputGroup.Text>
                                                    </InputGroup.Append>
                                                    <Form.Control.Feedback type="invalid">Please enter a valid 12-16 digit long Card number.</Form.Control.Feedback>
                                                </InputGroup>
                                            </Form.Group>
                                        </Col>
                                        <Col>
                                            <Form.Group controlId="">
                                            <Form.Label><RequiredIcon />Confirm account number</Form.Label>                                              
                                                <InputGroup hasValidation>
                                                    <Form.Control type={showCardNumber ? "text" : "password"} required minLength={12} maxLength={16} className="password" />
                                                    <InputGroup.Append>
                                                        <InputGroup.Text onClick={() => setShowCardNumber(!showCardNumber)}>
                                                            <FontAwesomeIcon icon={showCardNumber ? faEyeSlash : faEye} size="sm" />
                                                        </InputGroup.Text>
                                                    </InputGroup.Append>
                                                    <Form.Control.Feedback type="invalid">Please enter a valid 12-16 digit long Card number.</Form.Control.Feedback>
                                                </InputGroup>
                                                </Form.Group>
                                        </Col>
                                    </Row>
                                    {renderToggles()}
                                </>
                            )
                        }
                        <FormActions hasSubmit customProps={{ submitButtonDisplay: 'Add' }} onCancel={clearForm} />
                    </Form>
                </Modal.Body>
            </Modal>
        </>
    );
};

const mapStateToProps = (state: IAppState) => {
    return {
    };
};

export default connect(mapStateToProps)(Checkout);