import React, { useState, useEffect } from 'react';
import { connect, useDispatch } from "react-redux";
import { Switch } from "react-router-dom";
import { Routes } from "../routes";
import PaymentSettings from "./PaymentSettings";

import { GET_WEB_APP_SETTINGS_REQUEST, GET_WEB_APP_SETTINGS_SUCCESS, GET_WEB_APP_SETTINGS_FAILURE, setPublicClientApplication } from "../redux/actions/webAppSettings";

// components
import { getWebAppSettingsAction } from '../redux/actions/webAppSettings'
import { IActionResult, IAppState } from '../redux/storeTypes';
import RouteWithLoader from '../components/layout/RouteWithLoader';
import WebApplicationStart from "./WebApplicationStart";
import LoginPage from "./dynamic/LoginPage";
import CheckoutPage from "./dynamic/CheckoutPage";
import LinkPage from "./dynamic/LinkPage";
import ReceiptPage from "./dynamic/ReceiptPage";
import AccountPage from './dynamic/AccountPage';
import GenericPage from './dynamic/GenericPage';
import ShoppingCartPage from './dynamic/ShoppingCartPage';
import LandingPage from './dynamic/LandingPage';
import QuickPayPage from './dynamic/QuickPayPage';
import { PublicClientApplication } from '@azure/msal-browser';

export interface IHomePageProps {
  publicClientApplication: PublicClientApplication,
  actionResult: IActionResult
}

const HomePageSubdomain = ({ publicClientApplication, actionResult }: IHomePageProps) => {
  //console.log("HomePageSubdomain");
  const dispatch = useDispatch();
  const actionToken = "HomePage";
  const [webAppSettingsLoaded, setWebAppSettingsLoaded] = useState(false);
  const subdomain = localStorage.getItem("subdomain");

    useEffect(() => {
        if (!webAppSettingsLoaded) {
            dispatch(setPublicClientApplication(publicClientApplication));
            dispatch(getWebAppSettingsAction(actionToken));
        }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [webAppSettingsLoaded]);

  useEffect(() => {
    if (actionResult && actionResult.result && actionResult.type === GET_WEB_APP_SETTINGS_REQUEST && actionResult.token === actionToken) {
      if (actionResult.result === GET_WEB_APP_SETTINGS_SUCCESS) {
        setWebAppSettingsLoaded(true);
      } else if (actionResult.result === GET_WEB_APP_SETTINGS_FAILURE) {

      }
    }
  }, [actionResult]);

  if (webAppSettingsLoaded) {
    return (
      <Switch>
        <RouteWithLoader exact path={Routes.WebApplicationStart.path} component={WebApplicationStart} />
        <RouteWithLoader exact path={Routes.AccountPage.path} component={AccountPage} />
        <RouteWithLoader exact path={Routes.CheckoutPage.path} component={CheckoutPage} />
        <RouteWithLoader exact path={Routes.LinkPage.path} component={LinkPage} />
        <RouteWithLoader exact path={Routes.GenericPage.path} component={GenericPage} />
        <RouteWithLoader exact path={Routes.LandingPage.path} component={LandingPage} />
        <RouteWithLoader exact path={Routes.LoginPage.path} component={LoginPage} />
        <RouteWithLoader exact path={Routes.ReceiptPage.path} component={ReceiptPage} />
        <RouteWithLoader exact path={Routes.ShoppingCartPage.path} component={ShoppingCartPage} />
        <RouteWithLoader exact path={Routes.QuickPay.path} component={QuickPayPage} />
        <RouteWithLoader exact path={Routes.PaymentSettings.path} component={PaymentSettings} />
      </Switch>
    )
  } else {
    return (
      <>
        <span className="authorizing">Loading...</span>
      </>
    )
  }

};


const mapStateToProps = (state: IAppState) => {
  return {
    actionResult: state.webAppSettings.actionResult
  };
};

export default connect(mapStateToProps)(HomePageSubdomain);