import React, { useState, useEffect } from 'react';
import { Route } from "react-router-dom";
import Preloader from "../../components/Preloader";
import ToastContainer from "../ToastContainer";

const RouteWithLoader = ({ component: Component, ...rest }) => {
    const [loaded, setLoaded] = useState(false);
  
    useEffect(() => {
      const timer = setTimeout(() => setLoaded(true), 1000);
      return () => clearTimeout(timer);
    }, []);
  
    return (
      <Route {...rest} render={props => ( <> <Preloader show={loaded ? false : true} />  <main>  <ToastContainer /> <Component {...props} /> </main> </>  ) } /> 
    );
  };


export default RouteWithLoader;