import React, { useState } from "react";
import { Button, Card, Col, Container, Form, Modal, Row } from "react-bootstrap";
import Breadcrumb from '../components/layout/Breadcrumb';
import { connect } from "react-redux";
import PageFooter from "../components/layout/PageFooter";
import PageHeader from "../components/layout/PageHeader";
import { IAppState } from "../redux/storeTypes";
import FormHeader from "../components/layout/FormHeader";
import FormActions from "../components/layout/FormActions";
import BillingAddress from "./BillingAddress";

const BillingSettings = () => {
    const [showBillInfoForm, setShowBillInfoForm] = useState<boolean>(false);
    const [showBillNotfForm, setShowBillNotfForm] = useState<boolean>(false);
    const [validated, setValidated] = useState<boolean>(false);

    const clearForm = () => {
        setShowBillInfoForm(false);
        setShowBillNotfForm(false);
        setValidated(false);
    }
    return (
        <>
            <PageHeader />
            <Modal show={showBillInfoForm} size="lg" onHide={() => setShowBillInfoForm(false)}>
                <Modal.Header closeButton></Modal.Header>
                <Modal.Body>
                    <FormHeader title="Edit Billing Information" description="Section description" />
                    <BillingAddress />
                    <Form id='clientUserSubmit' noValidate validated={validated} >
                        <FormActions hasSubmit customProps={{ submitButtonDisplay: 'Update' }} onCancel={clearForm} />
                    </Form>
                </Modal.Body>
            </Modal>
            <Modal show={showBillNotfForm} size="lg" onHide={() => setShowBillNotfForm(false)}>
                <Modal.Header closeButton></Modal.Header>
                <Modal.Body>
                    <FormHeader title="Edit Billing notification" description="Section description" />

                    <h6>Delivery method</h6>
                    <Form.Group as={Row} controlId="scheduledDate">
                        <Col>

                            <div className="form-check form-switch">
                                <input className="form-check-input" type="checkbox" id="flexSwitchCheckDefault" />
                                <label className="form-check-label" htmlFor="flexSwitchCheckDefault">Email</label>
                            </div>
                        </Col>
                        <Col>
                            <div className="form-check form-switch">
                                <input className="form-check-input" type="checkbox" id="flexSwitchCheckDefault" />
                                <label className="form-check-label" htmlFor="flexSwitchCheckDefault">SMS</label>
                            </div>
                        </Col>
                    </Form.Group>
                    <br />
                    <h6>Alert types</h6>
                    <Form.Group as={Row} controlId="scheduledDate">
                        <Col>

                            <div className="form-check form-switch">
                                <input className="form-check-input" type="checkbox" id="flexSwitchCheckDefault" />
                                <label className="form-check-label" htmlFor="flexSwitchCheckDefault">New bill issued</label>
                            </div>
                        </Col>
                        <Col>
                            <div className="form-check form-switch">
                                <input className="form-check-input" type="checkbox" id="flexSwitchCheckDefault" />
                                <label className="form-check-label" htmlFor="flexSwitchCheckDefault">Due date has passed</label>
                            </div>
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row} controlId="scheduledDate">
                        <Col>

                            <div className="form-check form-switch">
                                <input className="form-check-input" type="checkbox" id="flexSwitchCheckDefault" />
                                <label className="form-check-label" htmlFor="flexSwitchCheckDefault">Due date is today</label>
                            </div>
                        </Col>
                        <Col>
                            <div className="form-check form-switch">
                                <input className="form-check-input" type="checkbox" id="flexSwitchCheckDefault" />
                                <label className="form-check-label" htmlFor="flexSwitchCheckDefault">Payment withdrawals</label>
                            </div>
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row} controlId="scheduledDate">
                        <Col>
                            <div className="form-check form-switch">
                                <input className="form-check-input" type="checkbox" id="flexSwitchCheckDefault" />
                                <label className="form-check-label" htmlFor="flexSwitchCheckDefault">Notify of paperless option</label>
                            </div>
                        </Col>
                    </Form.Group>

                    <Form id='clientUserSubmit' noValidate validated={validated} >
                        <FormActions hasSubmit customProps={{ submitButtonDisplay: 'Update' }} onCancel={clearForm} />
                    </Form>

                </Modal.Body>
            </Modal>
            <Container className="mt-4">
                <Breadcrumb name='Home/Billing Settings' />
                <Card>
                    <Card.Header className="payment-container">
                        <h2>Billing information</h2>
                        <Button variant="link" className="brand-link-primary" onClick={() => setShowBillInfoForm(true)}>Edit</Button>
                    </Card.Header>
                    <Card.Body>
                        <Form>
                            <Form.Group as={Row} controlId="billingAddress">
                                <Form.Label column sm="4">
                                    Billing address
                                </Form.Label>
                                <Col sm="8">
                                    <Form.Control plaintext readOnly
                                        defaultValue="123 Hope St.
                                    Austin, TX 78978
                                    USA" />
                                </Col>
                            </Form.Group>
                        </Form>
                    </Card.Body>
                </Card>
                <Card>
                    <Card.Header>
                        <h2>Billing notification</h2>
                        <Button variant="link" className="brand-link-primary" onClick={() => setShowBillNotfForm(true)}>Edit</Button>
                    </Card.Header>
                    <Card.Body>
                        <Form.Group as={Row} controlId="deliveryMethod">
                            <Form.Label column sm="4">
                                Delivery method
                            </Form.Label>
                            <Col sm="8">
                                <Form.Control plaintext readOnly placeholder="Email
                                SMS" />
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} controlId="alertTypes">
                            <Form.Label column sm="4">
                                Alert types
                            </Form.Label>
                            <Col sm="8">
                                <Form.Control plaintext readOnly placeholder="New bill issued
                                Due date has passed
                                Due date is today
                                Payment withdrawels
                                Notify of paperless option" />
                            </Col>
                        </Form.Group>
                    </Card.Body>
                </Card>
            </Container>

            <PageFooter />
        </>
    )
};

const mapStateToProps = (state: IAppState) => {
    return {
    };
};

export default connect(mapStateToProps)(BillingSettings);