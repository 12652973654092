import { useState } from 'react';
import { formatter } from './NericSearchResults';
import { Button, Form } from 'react-bootstrap';
import { connect, useDispatch } from "react-redux";
import { IAppState } from '../../../../../../redux/storeTypes';
import { updateOrderDetailsAction, updateShoppingCart } from '../../../../../../redux/actions/orderManagement';
import { Order } from '../../../../../../models/Order';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCartPlus, faCheck } from "@fortawesome/free-solid-svg-icons";
import moment from 'moment-timezone';
import { sendSuccessToastAction } from '../../../../../../redux/actions/toast';

interface IRadioComponent {
    selectedRow: any,
    onClose: any,
    observesWeekends: any,
    durationOfPendingHours: any,
    shoppingCart?: any,
    order?: any,
}

const LoganPaymentRules = ({ selectedRow, onClose, observesWeekends, durationOfPendingHours, order, shoppingCart }: IRadioComponent) => {

    const today = moment();
    const [selectedOption, setSelectedOption] = useState<any>({ uniqueItemId: `${selectedRow?.msbId}`, option: '' });
    const paymentOptions =
        today.isBefore(moment().month(1).date(28)) ?
            (selectedRow?.metaData?.FirstHalfBalance == 0 ?
                [{ id: 2, name: 'Second Half', condition: `Second Half Balance $${selectedRow?.metaData?.SecondHalfBalance}`, amount: selectedRow?.metaData?.SecondHalfBalance }]
                : [
                    { id: 1, name: 'First Half', condition: `First Half Balance $${selectedRow?.metaData?.FirstHalfBalance}`, amount: selectedRow?.metaData?.FirstHalfBalance },
                    { id: 'F', name: 'Full', condition: `First Half Balance $${selectedRow?.metaData?.FirstHalfBalance} + Second Half Balance $${selectedRow?.metaData?.SecondHalfBalance}`, amount: selectedRow?.metaData?.FirstHalfBalance + selectedRow?.metaData?.SecondHalfBalance }
                ])
            :
            today.isBetween(moment().month(2).date(1), moment().month(3).date(30), null, '[]') ?
                (selectedRow?.metaData?.FirstHalfBalance == 0 ?
                    [{ id: 2, name: 'Second Half', condition: `Second Half Balance $${selectedRow?.metaData?.SecondHalfBalance}`, amount: selectedRow?.metaData?.SecondHalfBalance }]
                    : [
                        { id: 1, name: 'First Half', condition: `First Half Balance $${selectedRow?.metaData?.FirstHalfBalance} + First Half Interest $${selectedRow?.metaData?.FirstHalfDelinquentInterest}`, amount: selectedRow?.metaData?.FirstHalfBalance + selectedRow?.metaData?.FirstHalfDelinquentInterest },
                        { id: 'F', name: 'Full', condition: `First Half Balance $${selectedRow?.metaData?.FirstHalfBalance} + Second Half Balance $${selectedRow?.metaData?.SecondHalfBalance}`, amount: selectedRow?.metaData?.FirstHalfBalance + selectedRow?.metaData?.SecondHalfBalance }
                    ])
                :
                today.isBetween(moment().month(4).date(1), moment().month(5).date(6), null, '[]') ?
                    (selectedRow?.metaData?.FirstHalfBalance == 0 ?
                        [{ id: 2, name: 'Second Half', condition: `Second Half Balance $${selectedRow?.metaData?.SecondHalfBalance}`, amount: selectedRow?.metaData?.SecondHalfBalance }] : [
                            { id: 1, name: 'First Half', condition: `First Half Balance $${selectedRow?.metaData?.FirstHalfBalance} + First Half Interest $${selectedRow?.metaData?.FirstHalfDelinquentInterest}`, amount: selectedRow?.metaData?.FirstHalfBalance + selectedRow?.metaData?.FirstHalfDelinquentInterest },
                            { id: 'F', name: 'Full', condition: `First Half Balance $${selectedRow?.metaData?.FirstHalfBalance} + Second Half Balance $${selectedRow?.metaData?.SecondHalfBalance} + Full Interest $${selectedRow?.metaData?.FullDelinquentInterest}`, amount: selectedRow?.metaData?.FirstHalfBalance + selectedRow?.metaData?.SecondHalfBalance + selectedRow?.metaData?.FullDelinquentInterest }
                        ])
                    :
                    (selectedRow?.metaData?.FirstHalfBalance == 0 ?
                        [{ id: 2, name: 'Second Half', condition: `Second Half Balance $${selectedRow?.metaData?.SecondHalfBalance} + Second Half Interest $${selectedRow?.metaData?.SecondHalfDelinquentInterest}`, amount: selectedRow?.metaData?.SecondHalfBalance + selectedRow?.metaData?.SecondHalfDelinquentInterest }] : [
                            { id: 1, name: 'First Half', condition: `First Half Balance $${selectedRow?.metaData?.FirstHalfBalance} + First Half Interest $${selectedRow?.metaData?.FirstHalfDelinquentInterest}`, amount: selectedRow?.metaData?.FirstHalfBalance + selectedRow?.metaData?.FirstHalfDelinquentInterest },
                            { id: 'F', name: 'Full', condition: `First Half Balance $${selectedRow?.metaData?.FirstHalfBalance} + Second Half Balance $${selectedRow?.metaData?.SecondHalfBalance} + Full Interest $${selectedRow?.metaData?.FullDelinquentInterest}`, amount: selectedRow?.metaData?.FirstHalfBalance + selectedRow?.metaData?.SecondHalfBalance + selectedRow?.metaData?.FullDelinquentInterest }
                        ])

    const dispatch = useDispatch();
    const displayPayments = () => {
        return (
            paymentOptions.map((option: any, index: any) => (
                <div key={index} style={{ marginBottom: '20px' }}>
                    <label className='neric_label'>
                        <input
                            type='radio'
                            className='neric_input'
                            id={`${option.id}`}
                            onChange={(e) => setSelectedOption({ ...selectedOption, option: option })}
                            disabled={option.id == 1 && selectedRow?.metaData?.GrandTotal < 25}
                            name='paymentOption'
                        />{option.name} - <strong>{formatter.format(option.amount)}</strong> {`(${option.condition})`}
                    </label>
                </div>
            ))
        );
    }


    const handleSubmit = (e: any) => {
        e.preventDefault();
        const shoppingCartObject = [
            {
                taxpayerName: selectedRow?.metaData?.TaxpayerName,
                situsAddress: selectedRow?.metaData?.SitusAddress,
                amount: selectedOption?.option?.amount,
                itemName: selectedOption?.option?.id,
                parcelNumber: selectedRow?.metaData?.ParcelNumber,
                id: selectedRow?.metaData?.ExternalReferenceNumber,
            }];
        const OrderLinesObject = [
            {
                itemReferenceNumber: selectedRow?.metaData?.ParcelNumber,
                quantity: 1,
                unitPrice: selectedOption?.option?.amount,
                itemMetadata: {
                    uiReferenceId: selectedRow?.metaData?.ExternalReferenceNumber,
                    statusUpdate: true,
                    itemMsbid: selectedRow?.msbId,
                    observesWeekends: observesWeekends,
                    durationOfPendingHours: durationOfPendingHours,
                    TaxpayerNameID: selectedRow?.metaData?.TaxpayerNameID,
                    PropertyTypeCode: selectedRow?.metaData?.PropertyTypeCode,
                    CAMAAccountNumber: selectedRow?.metaData?.CAMAAccountNumber,
                    TaxYear: selectedRow?.metaData?.TaxYear,
                    StatementNumber: selectedRow?.metaData?.StatementNumber,
                    PaymentCode: selectedOption?.option?.id.toString(),
                    PaymentAmount: selectedOption?.option?.amount
                },
                itemName: selectedOption?.option?.id,
            }];
        if (order) {
            const updatedOrderLines = order?.orderLines.filter((orderLine: any) => !OrderLinesObject.some((selected: any) => orderLine?.itemMetadata?.uiReferenceId === selected?.itemMetadata?.uiReferenceId)).concat(OrderLinesObject);
            const updatedShoppingCart = shoppingCart.filter((cart: any) => !shoppingCartObject.some((selected: any) => cart?.id === selected?.id)).concat(shoppingCartObject);
            order.methodName = "Sale";
            order.orderLines = updatedOrderLines;
            dispatch(updateShoppingCart(updatedShoppingCart, 'shoppingCartActionToken'));
            dispatch(updateOrderDetailsAction(order, 'actionToken'));
        }
        else {
            let _order = new Order();
            _order.methodName = "Sale";
            _order.orderLines = OrderLinesObject;
            dispatch(updateShoppingCart(shoppingCartObject, 'shoppingCartActionToken'));
            dispatch(updateOrderDetailsAction(_order, 'actionToken'));
        }
        dispatch(sendSuccessToastAction('Added to cart'))
        onClose();
    }

    const inCart = shoppingCart?.some((cart: any) => cart?.id === selectedRow?.metaData?.ExternalReferenceNumber);

    return (
        <>
            <div>
                {!inCart ?
                    <div className='neric_container'>
                        <div>
                            {displayPayments()}
                        </div>
                    </div>
                    :
                    <div className="stCharles-accordion accordion">
                        <div className='card'>
                            <div className='agreement_toggle_header card-header' style={{ justifyContent: 'center' }}>
                                <FontAwesomeIcon color='green' icon={faCartPlus} />Added to cart
                            </div>
                        </div>
                    </div>
                }
            </div>
            <div>
                <Form.Group className="form-footer">
                    <Button variant="outline-secondary" onClick={onClose}>Close</Button>
                    <Button type="submit" disabled={selectedOption.option == ''} onClick={(e) => handleSubmit(e)}>Add to cart</Button>
                </Form.Group>
            </div>
        </>
    )
}

const mapStateToProps = (state: IAppState) => {
    return {
        isFetching: state.account.isFetching,
        shoppingCart: state.orderManagement.shoppingCart,
        order: state.orderManagement.order,
    };
};

export default connect(mapStateToProps)(LoganPaymentRules);
