import { PaymentCardTypeEnum } from "./Order";

export enum BankAccountType {
    Unknown = 0,
    Checking = 1,
    Savings = 2,
}

export enum WalletType {
    Unknown = 0,
    Personal = 1,
    PersonalShared = 2,
    Corporate = 3,
    CorporateShared = 4
}

export enum VantivPaymentType {
    Unknown = 0,
    Recurring = 1,
    Installment = 2,
    CardHolderInitiated = 3,
    CredentialOnFile = 4
}

export class TokenizeBankAccount {
    msbId?: string;
    msbWalletIdentifier: number = 0;
    walletType: WalletType = WalletType.Personal;
    bankAccountType: BankAccountType = BankAccountType.Unknown;
    paymentChannelId?: string;
    accountHolderEmailAddress: string = "";
    isUserInitiated: boolean = false;
    accountHolderName: string = "";
    accountNumber: string = "";
    routingNumber: string = "";
    addressLine1: string = "";
    addressLine2: string = "";
    city: string = "";
    state: string = "";
    zipCode: string = "";
    country: string = "";
    isImplicitAuthorization: boolean = false;
    externalCustomerReference: string = "";
}

export class TokenizeCard {
    msbId?: string;
    expirationMonth: string = "";
    expirationYear: string = "";
    cardNumber: string = "";
    cvv: string = "";
    cardLogo: string = "";
    avsResponseCode: string = "";
    cvvResponseCode: string = "";
    cardNumberMasked: string = "";
    bin: string = "";
    cardLevelResults: string = "";
    msbWalletIdentifier: number = 0;
    walletOwnerEmailAddress: string = "";
    walletType: WalletType = WalletType.Personal;
    internalCardType: PaymentCardTypeEnum = PaymentCardTypeEnum.Unknown;
    paymentChannelId?: string;
    cardHolderEmailAddress: string = "";
    isUserInitiated: boolean = false;
    nameOnCard: string = "";
    addressLine1: string = "";
    addressLine2: string = "";
    city: string = "";
    state: string = "";
    zipCode: string = "";
    country: string = "";
    terminalId: string = "";
    laneId: number = 0;
    isCardPresent: boolean = false;
    isImplicitAuthorization: boolean = false;
    vantivPaymentType: VantivPaymentType = VantivPaymentType.Unknown;
    externalCustomerReference: string = "";
}