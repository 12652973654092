import {
    SEND_TOAST, REMOVE_TOAST
} from '../actions/toast';

export default function auth(state = {
    toasts: []
}, { type, payload }) {
    switch (type) {
        case SEND_TOAST: 
            let _toasts  = [...state.toasts];
            _toasts.push(payload.toast);
            return Object.assign({}, state, {
                toasts: _toasts
            });
        case REMOVE_TOAST:
            let __toasts = [];

            for (let x = 0; x < state.toasts.length; x++) {
                if (state.toasts[x].id !== payload.id) {
                    __toasts.push(state.toasts[x]);
                }
            }

            return Object.assign({}, state, {
                toasts: __toasts
            });
        default:
            return state;
    }
}
