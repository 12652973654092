import React from "react";

export interface FormHeaderProps {
    title: string;
    description: string;
}

const FormHeader = ({ title, description }: FormHeaderProps) => {
    return (
        <div className="form-header">
            <h2>{title}</h2>
            <p>{description}</p>
        </div>
    );
};

export default FormHeader;