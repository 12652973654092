import { Row, Col, Modal, Form, Button, Spinner, OverlayTrigger, Popover } from "react-bootstrap"
import { useEffect, useState } from "react";
import { IAppState } from '../../../../../../redux/storeTypes';
import { connect, useDispatch } from "react-redux";
import Table from '../../../../../../components/Table';
import FormHeader from "../../../../../../components/layout/FormHeader";
import CurrencyInput from '../../../../../../components/currency/CurrencyInput';
import { CurrencyFormatter } from "../../../../../../components/Formatters";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCartPlus, faCheck, faInfoCircle, faTimes, faTimesCircle, faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";
import { updateOrderDetailsAction, updateShoppingCart } from "../../../../../../redux/actions/orderManagement";
import { sendSuccessToastAction } from "../../../../../../redux/actions/toast";
import { ItemStatusType, Order } from "../../../../../../models/Order";
import _ from "lodash";
import React from "react";

interface IForsythSearchResults {
    accountNumberLabel: string,
    billNumberLabel: string,
    billYearLabel: string,
    ownerLabel: string,
    situsAddressLabel: string,
    billStatusLabel: string,
    totalDueLabel: string,
    dueDateLabel: string,
    paymentOptionsLabel: string,
    observesWeekends: boolean,
    durationOfPendingHours: number,
    overlayText: string,
    overlayBackground: string,
    overlayTextColor: string,
    overlayIconColor: string,
    margin: string,
    padding: string,
    headerBackgroundColor: string,
    headerPadding: string,
    itemDetails: any,
    order?: any,
    shoppingCart?: any,
    isFetching: boolean,
    itemSearchDetails?:any
}

export const ACCOUNT_NUMBER_LABEL = 'Account Number';
export const BILL_NUMBER_LABEL = 'Bill Number';
export const BILL_YEAR_LABEL = 'Bill Year';
export const OWNER_LABEL = 'Owner';
export const SITUS_ADDRESS_LABEL = 'Situs Address'
export const TOTAL_DUE_LABEL = 'Total Due';
export const DUE_DATE_LABEL = 'Due Date'
export const BILL_STATUS_LABEL = 'Status'
export const PAYMENT_OPTIONS_LABEL = 'Payment Options';
export const OBSERVES_WEEKENDS = false;
export const DURATION_OF_PENDING_HOURS = 24;
export const OVERLAY_TEXT = 'There are no available online payment options on this parcel at this time. Please contact Forsyth County Tax Administration, NC at 336-703-2300.'; 
export const OVERLAY_BACKGROUND = '#fef1b5'; 
export const OVERLAY_TEXT_COLOR = '#1e1e1e';
export const OVERLAY_ICON_COLOR = 'orange';
export const MARGIN = '0px';
export const PADDING = '0px';
export const HEADER_BACKGROUND_COLOR = 'rgb(250, 250, 250)';
export const HEADER_PADDING = '0';

const checkStyleForDefault = (style: string, defaultStyle: string) => {
    return style && style !== '' ? style : defaultStyle;
}

export const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
});

export const DateFormatter = (date: any, row: any) => {
    if (date) {
        const dateString = moment(date).format('MM/DD/YYYY');
        return (
            <span>{dateString}</span>
        );
    }
}

const ForsythSearchResults = ({ accountNumberLabel, billNumberLabel, billYearLabel, ownerLabel, situsAddressLabel, billStatusLabel,
    totalDueLabel, dueDateLabel, paymentOptionsLabel, durationOfPendingHours, observesWeekends, itemSearchDetails,
    overlayText, overlayBackground, overlayTextColor, overlayIconColor, margin, padding, headerBackgroundColor, headerPadding, itemDetails, shoppingCart, order, isFetching }: IForsythSearchResults) => {

    if (!accountNumberLabel) { accountNumberLabel = ACCOUNT_NUMBER_LABEL }
    if (!billYearLabel) { billYearLabel = BILL_YEAR_LABEL }
    if (!billNumberLabel) { billNumberLabel = BILL_NUMBER_LABEL }
    if (!ownerLabel) { ownerLabel = OWNER_LABEL }
    if (!situsAddressLabel) { situsAddressLabel = SITUS_ADDRESS_LABEL }
    if (!billStatusLabel) { billStatusLabel = BILL_STATUS_LABEL }
    if (!totalDueLabel) { totalDueLabel = TOTAL_DUE_LABEL }
    if (!dueDateLabel) { dueDateLabel = DUE_DATE_LABEL }
    if (!paymentOptionsLabel) { paymentOptionsLabel = PAYMENT_OPTIONS_LABEL }
    if (!durationOfPendingHours) { durationOfPendingHours = DURATION_OF_PENDING_HOURS }
    if (!observesWeekends) { observesWeekends = OBSERVES_WEEKENDS }
    if (!overlayText) { overlayText = OVERLAY_TEXT }
    if (!overlayBackground) { overlayBackground = OVERLAY_BACKGROUND }
    if (!overlayTextColor) { overlayTextColor = OVERLAY_TEXT_COLOR }
    if (!overlayIconColor) { overlayIconColor = OVERLAY_ICON_COLOR }
    if (!margin) { margin = MARGIN }
    if (!padding) { padding = PADDING }
    if (!headerBackgroundColor) { headerBackgroundColor = HEADER_BACKGROUND_COLOR }
    if (!headerPadding) { headerPadding = HEADER_PADDING }

    const [amount, setAmount] = useState<any>();
    const dispatch = useDispatch();
    const [selectedOption, setSelectedOption] = useState<any>({ option: 'full' });
    const [cartErrorMessage, setCartErrorMessage] = useState<string>('');

    const amountFormatter = (cell: any) => {
        return (<span>{formatter.format(cell)}</span>)
    }

    const paymentOptionsFormatter = (cell: any, row: any) => {
        const isViewable = row?.itemPaymentStatus != ItemStatusType.Pending && row?.metaData?.BillStatus == 'UNPAID';
        const infoOverlay = row?.itemPaymentStatus == ItemStatusType.Paid || row?.metaData?.BillStatus == 'PAID' || row?.metaData?.BillStatus == 'DEFERRED' || row?.metaData?.TotalDue == 0
        if (isViewable) {
            return (
                <span>
                    <a
                        onClick={() => { setSelectedRow(row); setCartErrorMessage(''); setSelectedOption({ option: 'full' }); setAmount(null); setShowModal(true) }}
                    >
                        {paymentOptionsLabel}
                    </a>
                </span>
            )
        }
        else if (infoOverlay) {
            return (
                <span style={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
                    <OverlayTrigger trigger="click" rootClose placement="top" overlay={(
                        <Popover id="popover-positioned-top" style={{ backgroundColor: overlayBackground, color: overlayTextColor, minWidth: '400px' }}>
                            <Popover.Content>
                                <div style={{ display: 'flex', alignItems: 'center', columnGap: '8px', fontSize: 'medium' }}>
                                    <FontAwesomeIcon icon={faExclamationTriangle} color={overlayIconColor} className="btn-icon" />
                                {overlayText}
                                </div>
                            </Popover.Content>
                        </Popover>
                    )}>
                        <FontAwesomeIcon icon={faInfoCircle} className="btn-icon" style={{ color: "#6495ed", cursor: 'pointer' }} />
                    </OverlayTrigger>
                </span>
            )
        }
    }

    const ownerFormatter = (cell: any, row: any) => {
        const SecondaryOwnerPresent = row?.metaData?.SecondaryOwner != '' || false;
        const OtherOwnerPresent = row?.metaData?.OtherOwner != '' || false;
        const ownerName = SecondaryOwnerPresent && OtherOwnerPresent ? `${cell}*${row?.metaData?.SecondaryOwner}*${row?.metaData?.OtherOwner}` : SecondaryOwnerPresent ? `${cell}*${row?.metaData?.SecondaryOwner}` : OtherOwnerPresent ? `${cell}*${row?.metaData?.OtherOwner}` : cell;
        const lines = _.split(ownerName, '*');
        return lines.map((line: any, index: any) => (
            <React.Fragment key={index}>
                {index == 0 ?
                    <div>{line}</div> :
                    <div style={{ marginLeft: '8px', fontSize: 'smaller' }}>{line}</div>
                }
            </React.Fragment>
        ))
    }

    const statusFormatter = (cell: any, row: any) => {
        const statusValue = row?.metaData?.BillStatus == 'PAID' ? 'Paid' : row?.itemPaymentStatus == ItemStatusType.Pending ? 'Pending' : row?.metaData?.BillStatus == 'UNPAID' ? 'Unpaid' : _.startCase(_.toLower(row?.metaData?.BillStatus));
        let color: string = "orange";
        if (statusValue === 'Paid') { color = "#52C41A" }
        if (statusValue === 'Pending') { color = "#f5b759" }
        if (statusValue === 'Unpaid') { color = "#F5222D"}
        return (
            <span style={{ display: 'flex', alignItems: 'center' }}>
                <FontAwesomeIcon icon={statusValue === 'Paid' ? faCheck : statusValue === 'Pending' ? faInfoCircle :  faTimes } className="btn-icon" style={{ color: `${color}` }} />
                {statusValue}
            </span>
        )
    }

    const initialColumns = [{
        dataField: 'metaData.ParcelID',
        text: accountNumberLabel,
        headerStyle: {
            backgroundColor: checkStyleForDefault(headerBackgroundColor, HEADER_BACKGROUND_COLOR)
        },
        editable: false,
    }, {
        dataField: 'metaData.BillNumber',
        text: billNumberLabel,
        editable: false,
        headerStyle: {
            backgroundColor: checkStyleForDefault(headerBackgroundColor, HEADER_BACKGROUND_COLOR)
        },
    }, {
        dataField: 'metaData.BillYear',
        text: billYearLabel,
        headerStyle: {
            backgroundColor: checkStyleForDefault(headerBackgroundColor, HEADER_BACKGROUND_COLOR)
        },
        editable: false,
        sort: true,
    }, {
        dataField: 'metaData.PrimaryOwner',
        text: ownerLabel,
        headerStyle: {
            backgroundColor: checkStyleForDefault(headerBackgroundColor, HEADER_BACKGROUND_COLOR)
        },
        editable: false,
        formatter: ownerFormatter,
        sort: true,
    }, {
        dataField: 'metaData.SitusAddress',
        text: situsAddressLabel,
        headerStyle: {
            backgroundColor: checkStyleForDefault(headerBackgroundColor, HEADER_BACKGROUND_COLOR)
        },
        editable: false,
    }, {
        dataField: 'metaData.TotalDue',
        text: totalDueLabel,
        headerStyle: {
            backgroundColor: checkStyleForDefault(headerBackgroundColor, HEADER_BACKGROUND_COLOR)
        },
        editable: false,
        formatter: amountFormatter,
        sort: true,
    }, {
        dataField: 'metaData.DueDate',
        text: dueDateLabel,
        headerStyle: {
            backgroundColor: checkStyleForDefault(headerBackgroundColor, HEADER_BACKGROUND_COLOR)
        },
        editable: false,
        sort: true,
        formatter: DateFormatter
    }, {
        dataField: 'metaData.BillStatus',
        text: billStatusLabel,
        headerStyle: {
            backgroundColor: checkStyleForDefault(headerBackgroundColor, HEADER_BACKGROUND_COLOR)
        },
        editable: false,
        sort: true,
        formatter: statusFormatter,
    }, {
        dataField: 'metaData.ExternalReferenceNumber',
        text: paymentOptionsLabel,
        headerStyle: {
            backgroundColor: checkStyleForDefault(headerBackgroundColor, HEADER_BACKGROUND_COLOR)
        },
        editable: false,
        formatter: paymentOptionsFormatter
    }];


    const [columns, setColumns] = useState<any>(initialColumns);
    const [data, setData] = useState<any>([]);
    const [selectedRow, setSelectedRow] = useState<any>([]);
    const [showModal, setShowModal] = useState<boolean>(false);

    const mappingDetails = [
        { heading: billNumberLabel, value: selectedRow?.metaData?.BillNumber },
        { heading: accountNumberLabel, value: selectedRow?.metaData?.ParcelID },
        { heading: billYearLabel, value: selectedRow?.metaData?.BillYear },
        { heading: ownerLabel, value: ownerFormatter(selectedRow?.metaData?.PrimaryOwner, selectedRow) },
        { heading: situsAddressLabel, value: selectedRow?.metaData?.SitusAddress },
        { heading: totalDueLabel, value: CurrencyFormatter(selectedRow?.metaData?.TotalDue) },
        { heading: dueDateLabel, value: DateFormatter(selectedRow?.metaData?.DueDate, selectedRow) },
        { heading: billStatusLabel, value: statusFormatter(selectedRow?.metaData?.BillStatus, selectedRow) },
    ]

    const handleRadioSelect = (e: any) => {
        setCartErrorMessage('');
        setSelectedOption({ option: e.target.id });
        setAmount(null)
    }

    const partialAmountChange = (value: any) => {
        if (value > selectedRow?.metaData?.TotalDue) {
            setCartErrorMessage('Amounts cannot be greater than total due')
            setAmount(null)
            return
        }
        setCartErrorMessage('')
        setAmount(value)
    }

    useEffect(() => {
        if (itemDetails) {
            const filterByKey = itemSearchDetails?.searchBy == 'BillNumber' || itemSearchDetails?.searchBy == 'ParcelID';
            const filteredItems = filterByKey ? itemDetails.filter((item: any) => {
                let itemValue = item?.metaData[itemSearchDetails?.searchBy]
                return itemValue === itemSearchDetails?.referenceNumber
            }) : itemDetails;
            setData(filteredItems)
        }
    }, [itemDetails])

    const renderTable = () => {
        if (isFetching) {
            return (<div style={{ textAlign: 'center' }} ><Spinner animation="border" /> </div>);
        }
        else if (data.length === 0) {
            return (<div>Currently there are no records to display. Please do a search to find the record you are looking for.</div>)
        } else {
            return (
                <>
                    <Table
                        keyField="msbId"
                        data={data}
                        remote={false}
                        columns={columns}
                        sizePerPage={5}
                    />
                </>);
        }
    };

    const inCart = shoppingCart?.some((cart: any) => cart?.id === selectedRow?.metaData?.ExternalReferenceNumber);

    const handleSubmit = (e: any) => {
        e.preventDefault();
        const shoppingCartObject = [
            {
                accountNumber: selectedRow?.metaData?.ParcelID,
                billNumber: selectedRow?.metaData?.BillNumber,
                billYear: selectedRow?.metaData?.BillYear,
                owner: selectedRow?.metaData?.PrimaryOwner,
                situsAddress: selectedRow?.metaData?.SitusAddress,
                id: selectedRow?.metaData?.ExternalReferenceNumber,
                paymentAmount: selectedOption?.option === 'partial' ? _.toNumber(amount) : Number(selectedRow?.metaData?.TotalDue),
                dueDate: selectedRow?.metaData?.DueDate,
            }];
        const OrderLinesObject = [
            {
                accountNumber: selectedRow?.metaData?.ParcelID,
                itemReferenceNumber: selectedRow?.metaData?.ExternalReferenceNumber,
                quantity: 1,
                unitPrice: selectedOption?.option === 'partial' ? _.toNumber(amount) : Number(selectedRow?.metaData?.TotalDue),
                itemMetadata: {
                    uiReferenceId: selectedRow?.metaData?.ExternalReferenceNumber,
                    statusUpdate: true,
                    itemMsbid: selectedRow?.msbId,
                    observesWeekends: observesWeekends,
                    durationOfPendingHours: durationOfPendingHours
                },
                itemName: selectedRow?.metaData?.BillNumber,
            }];
        if (order) {
            const updatedOrderLines = order?.orderLines.filter((orderLine: any) => !OrderLinesObject.some((selected: any) => orderLine?.itemMetadata?.uiReferenceId === selected?.itemMetadata?.uiReferenceId)).concat(OrderLinesObject);
            const updatedShoppingCart = shoppingCart.filter((cart: any) => !shoppingCartObject.some((selected: any) => cart?.id === selected?.id)).concat(shoppingCartObject);
            order.methodName = "Sale";
            order.orderLines = updatedOrderLines;
            dispatch(updateShoppingCart(updatedShoppingCart, 'shoppingCartActionToken'));
            dispatch(updateOrderDetailsAction(order, 'actionToken'));
        }
        else {
            let _order = new Order();
            _order.methodName = "Sale";
            _order.orderLines = OrderLinesObject;
            dispatch(updateShoppingCart(shoppingCartObject, 'shoppingCartActionToken'));
            dispatch(updateOrderDetailsAction(_order, 'actionToken'));
        }
        dispatch(sendSuccessToastAction('Added to cart'))
        setShowModal(false);
    }

    return (

        <div style={{ margin: margin, padding: padding }}>
            <Row><Col>{renderTable()}</Col></Row>
            <Modal show={showModal} size={'lg'} backdrop="static" onHide={() => setShowModal(false)}>
                <Modal.Header closeButton />
                <Modal.Body>
                    <FormHeader title="Item Details" description={"Below are the selected item details"} />
                    <Form.Group controlId="printTerminal" >
                        <>
                            {Object.entries(mappingDetails).map(
                                ([index, selectedArray]: any) => {
                                    return (
                                        <div key={index} className="neric_details_row">
                                            <h3>{selectedArray.heading}</h3>
                                            <span>{selectedArray.value}</span>
                                        </div>
                                    )
                                }
                            )}
                        </>
                    </Form.Group>
                    <hr />
                    {!inCart ?
                        <>
                            <FormHeader title="Payment Options" description={"Please select the following payment options"} />
                            <Form.Group controlId="paymentOptions">
                                <div className='stCharles_cart'>
                                    <div className='box'>
                                        <label>
                                            <input
                                                type='radio'
                                                name='paymentOption'
                                                className='neric_input'
                                                id='full'
                                                onChange={handleRadioSelect}
                                                checked={selectedOption?.option === 'full'}
                                            />
                                            Total Due
                                        </label>
                                        <span className='box_span'>{formatter.format(Number(selectedRow?.metaData?.TotalDue))}</span>
                                    </div>
                                    <br />
                                    <div className='box'>
                                        <label>
                                            <input
                                                type='radio'
                                                name='paymentOption'
                                                className='neric_input'
                                                id='partial'
                                                onChange={(e) => setSelectedOption({ option: `${e.target.id}` })}
                                                checked={selectedOption?.option === 'partial'}
                                            />Payment Amount
                                        </label>
                                        <Form.Group controlId="amount" className='box_span'>
                                            <CurrencyInput
                                                required
                                                className="form-control"
                                                id="summaryAmount"
                                                name="summaryAmount"
                                                placeholder="$ 0.00"
                                                maxLength={10}
                                                decimalsLimit={2}
                                                prefix="$ "
                                                disabled={selectedOption?.option != 'partial'}
                                                value={amount}
                                                onValueChange={(value: any) => partialAmountChange(value)}
                                            />
                                        </Form.Group>
                                        {cartErrorMessage && selectedOption?.option === 'partial' &&
                                            <div role="alert" id="paymentErrorBox" style={{ color: 'red' }}>
                                                <FontAwesomeIcon icon={faTimesCircle} color='red' /> {cartErrorMessage}
                                            </div>
                                        }
                                    </div>
                                </div>
                            </Form.Group>
                        </>
                        :
                        <div className="stCharles-accordion accordion">
                            <div className='card'>
                                <div className='agreement_toggle_header card-header' style={{ justifyContent: 'center' }}>
                                    <FontAwesomeIcon color='green' icon={faCartPlus} />Added to cart
                                </div>
                            </div>
                        </div>
                    }
                    <Form.Group className="form-footer">
                        <Button variant="outline-secondary" onClick={() => setShowModal(false)}>Close</Button>
                        <Button type="submit"
                            disabled={inCart || selectedOption?.option == 'partial' && (amount == undefined || amount == 0 || amount > selectedRow?.metaData?.TotalDue) || selectedRow?.metaData?.TotalDue == 0} onClick={(e) => handleSubmit(e)}
                        >
                            {`Add to cart`}
                        </Button>
                    </Form.Group>
                </Modal.Body>
            </Modal>
        </div>
    )
}

const mapStateToProps = (state: IAppState) => {
    return {
        isFetching: state.account.isFetching,
        itemDetails: state.account.itemDetails,
        shoppingCart: state.orderManagement.shoppingCart,
        order: state.orderManagement.order,
        itemSearchDetails: state.orderManagement.itemSearchDetails
    };
};

export default connect(mapStateToProps)(ForsythSearchResults);