import { CellPlugin } from '@react-page/editor';
import PikeSearchResults, {
    NAME_LABEL, ACCOUNT_NUMBER_LABEL, TAX_YEAR_LABEL, PROPERTY_TYPE_LABEL,
    ADDRESS_LABEL, DUE_DATE_LABEL,BALANCE_DUE_LABEL,STATUS_LABEL, VIEW_DETAILS_LABEL,
    HEADER_BACKGROUND_COLOR, HEADER_PADDING, MARGIN, PADDING, DELINQUENT_LABEL, 
    DELINQUENT_BACKGROUND_COLOR, DELINQUENT_TEXT_COLOR, DELINQUENT_TEXT_ALIGN,
    OBSERVES_WEEKENDS, DURATION_OF_PENDING_HOURS
} from './components/PikeSearchResults';

type Data = {
    name: string,
    accountNumber: string,
    taxYear: string,
    propertyType: string,
    address: string,
    balanceDue: string,
    status: string,
    dueDate: string,
    viewDetails: string,
    delinquentLabel: string,
    delinquentBackgroundColor: string,
    delinquentTextColor: string,
    delinquentTextAlign: string,
    observesWeekends: boolean,
    durationOfPendingHours: number,
    headerBackgroundColor: string,
    headerPadding: string,
    margin: string,
    padding: string,
}

const PikeSearchResultsPlugin: CellPlugin<Data> = {
    Renderer: ({ data }) => (
        <PikeSearchResults
            nameLabel={data.name}
            accountNumberLabel={data.accountNumber}
            taxYearLabel={data.taxYear}
            propertyTypeLabel={data.propertyType}
            addressLabel={data.address}
            dueDateLabel={data.dueDate}
            balanceDueLabel={data.balanceDue}
            statusLabel={data.status}
            viewDetailsLabel={data.viewDetails}
            delinquentLabel={data.delinquentLabel}
            delinquentBackgroundColor={data.delinquentBackgroundColor}
            delinquentTextColor={data.delinquentTextColor}
            delinquentTextAlign={data.delinquentTextAlign}
            observesWeekends={data.observesWeekends}
            durationOfPendingHours={data.durationOfPendingHours}
            margin={data.margin}
            padding={data.padding}
            headerBackgroundColor={data.headerBackgroundColor}
            headerPadding={data.headerPadding}
        />
    ),
    id: 'pikeResultsPlugin',
    title: 'PIKE Search Results',
    description: 'PIKE table results',
    version: 1,
    controls: [
        {
            title: 'Labels',
            controls: {
                type: 'autoform',
                schema: {
                    properties: {
                        name: {
                            type: 'string',
                            default: NAME_LABEL,
                        },
                        accountNumber: {
                            type: 'string',
                            default: ACCOUNT_NUMBER_LABEL,
                        },
                        taxYear: {
                            type: 'string',
                            default: TAX_YEAR_LABEL,
                        },
                        propertyType: {
                            type: 'string',
                            default: PROPERTY_TYPE_LABEL,
                        },
                        address: {
                            type: 'string',
                            default: ADDRESS_LABEL,
                        },
                        dueDate: {
                            type: 'string',
                            default: DUE_DATE_LABEL,
                        },
                        status:{
                            type: 'string',
                            default: STATUS_LABEL, 
                        },
                        balanceDue: {
                            type: 'string',
                            default: BALANCE_DUE_LABEL,
                        },
                        viewDetails: {
                            type: 'string',
                            default: VIEW_DETAILS_LABEL,
                        },
                        delinquentLabel: {
                            type: 'string',
                            default: DELINQUENT_LABEL,
                        },
                        delinquentBackgroundColor: {
                            type: 'string',
                            default: DELINQUENT_BACKGROUND_COLOR,
                        },
                        delinquentTextColor: {
                            type: 'string',
                            default: DELINQUENT_TEXT_COLOR,
                        },
                        delinquentTextAlign:{
                            type: 'string',
                            default: DELINQUENT_TEXT_ALIGN
                        },
                        observesWeekends:{
                            type: 'boolean',
                            default: OBSERVES_WEEKENDS
                        },
                        durationOfPendingHours:{
                            type: 'number',
                            default: DURATION_OF_PENDING_HOURS
                        }
                    },
                    required: [],
                },
            },
        },
        {
            title: 'Styles',
            controls: {
                type: 'autoform',
                schema: {
                    properties: {
                        margin: {
                            type: 'string',
                            default: MARGIN,
                        },
                        padding: {
                            type: 'string',
                            default: PADDING,
                        },
                        headerBackgroundColor: {
                            type: 'string',
                            default: HEADER_BACKGROUND_COLOR,
                        },
                        headerPadding: {
                            type: 'string',
                            default: HEADER_PADDING,
                        }
                    },
                    required: [],
                },
            },
        },
    ],
};

export default PikeSearchResultsPlugin;