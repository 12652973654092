
import React from "react";
import { Container } from 'react-bootstrap';
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { IAppState } from "../redux/storeTypes";
import { Routes } from "../routes";
import ProductPage from "../pages/ProductPage";

export interface IProductPageProps {
}



const Product = ({ }: IProductPageProps) => {
  return (
    <main className="marketing">
      <ProductPage />
    </main>
  );
};

const mapStateToProps = (state: IAppState) => {
  return {
  };
};

export default connect(mapStateToProps)(Product);

