import React from 'react';

interface BarProps {
    backgroundColor: string;
    icon?: any;
    message: string;
}



const HeaderBar = ({ backgroundColor, icon, message }: BarProps) => {
    
    return (
        <div style={{ backgroundColor: backgroundColor , width:'calc(100% - 20px)', marginLeft:'10px', borderRadius: '4px' }}>
            <div style={{minHeight: '40px' ,display:'flex',alignItems:'center'}}>
                <img src={icon} alt="icon"/>
                <span style={{marginLeft:'10px'}}>{message}</span>
            </div>
        </div>
    )
}


export default HeaderBar