import { CellPlugin } from '@react-page/editor';
import React from 'react';
import { Col, Row } from 'react-bootstrap';

// use a type here, not an interface
type Data = {
  label: string
}

const SignatureDisclaimerPropertyPlugin: CellPlugin<Data> = {
  Renderer: ({ data }) => (
        <Row>
            <span style={{ display: 'inline', float: 'left' }}>x</span>
            <hr />
            <Col>{data.label}</Col>
        </Row>
  ),
  id: 'signatureDisclaimerPropertyPlugin',
  title: 'Signature Disclaimer Property',
  description: 'Signature Disclaimer Property.',
  version: 1,
  controls: {
    type: 'autoform',
    schema: {
      properties: {
        label: {
          type: 'string',
          default: 'I agree to pay the above total amount according to the card issuer agreement.',
        },
      },
      required: ['label'],
    },
  },
};

export default SignatureDisclaimerPropertyPlugin;