import { CellPlugin } from '@react-page/editor';
import LoganSearchResults, {
    TAXPAYER_NAME_LABEL, PARCEL_NUMBER_LABEL, STREET_ADDRESS_LABEL, PROPERTY_TYPE_LABEL, PROPERTY_TYPE_DESCRIPTION,
    TAX_YEAR_LABEL, BALANCE_LABEL, STATEMENT_NUMBER_LABEL, STATUS_LABEL, ACTIONS_LABEL, OBSERVES_WEEKENDS, DURATION_OF_PENDING_HOURS,
    HEADER_BACKGROUND_COLOR, HEADER_PADDING, MARGIN, PADDING,
    OVERLAY_TEXT, OVERLAY_BACKGROUND, OVERLAY_TEXT_COLOR, OVERLAY_ICON_COLOR
} from './components/LoganSearchResults';

type Data = {
    title: string,
    taxPayerName: string,
    parcelNumber: string,
    streetAddress: string,
    propertyTypeCode: string,
    propertyTypeDescription: string,
    taxYear: string,
    balance: string,
    statementNumber: string,
    status: string,
    actions: string,
    observesWeekends: boolean,
    durationOfPendingHours: number,
    overlayText: string,
    overlayBackground: string,
    overlayTextColor: string,
    overlayIconColor: string,
    headerBackgroundColor: string,
    headerPadding: string,
    margin: string,
    padding: string,
}

const LoganSearchResultsPlugin: CellPlugin<Data> = {
    Renderer: ({ data }) => (
        <LoganSearchResults
            taxPayerNameLabel={data.taxPayerName}
            parcelNumberLabel={data.parcelNumber}
            streetAddressLabel={data.streetAddress}
            propertyTypeCodeLabel={data.propertyTypeCode}
            propertyTypeDescriptionLabel={data.propertyTypeDescription}
            taxYearLabel={data.taxYear}
            balanceLabel={data.balance}
            statementNumberLabel={data.statementNumber}
            statusLabel={data.status}
            actionsLabel={data.actions}
            observesWeekends={data.observesWeekends}
            durationOfPendingHours={data.durationOfPendingHours}
            overlayText={data.overlayText}
            overlayBackground={data.overlayBackground}
            overlayTextColor={data.overlayTextColor}
            overlayIconColor={data.overlayIconColor}
            margin={data.margin}
            padding={data.padding}
            headerBackgroundColor={data.headerBackgroundColor}
            headerPadding={data.headerPadding}
        />
    ),
    id: 'loganSearchResultsPlugin',
    title: 'Logan Search Results',
    description: 'Logan search table results',
    version: 1,
    controls: [
        {
            title: 'Labels',
            controls: {
                type: 'autoform',
                schema: {
                    properties: {
                        taxPayerName: {
                            type: 'string',
                            default: TAXPAYER_NAME_LABEL,
                        },
                        parcelNumber: {
                            type: 'string',
                            default: PARCEL_NUMBER_LABEL,
                        },
                        streetAddress: {
                            type: 'string',
                            default: STREET_ADDRESS_LABEL,
                        },
                        propertyTypeCode: {
                            type: 'string',
                            default: PROPERTY_TYPE_LABEL,
                        },
                        propertyTypeDescription: {
                            type: 'string',
                            default: PROPERTY_TYPE_DESCRIPTION,
                        },
                        taxYear: {
                            type: 'string',
                            default: TAX_YEAR_LABEL,
                        },
                        balance:{
                            type: 'string',
                            default: BALANCE_LABEL,
                        },
                        statementNumber: {
                            type: 'string',
                            default: STATEMENT_NUMBER_LABEL,
                        },
                        status: {
                            type: 'string',
                            default: STATUS_LABEL,
                        },
                        actions: {
                            type: 'string',
                            default: ACTIONS_LABEL,
                        },
                        observesWeekends: {
                            type: 'boolean',
                            default: OBSERVES_WEEKENDS
                        },
                        durationOfPendingHours: {
                            type: 'number',
                            default: DURATION_OF_PENDING_HOURS
                        },
                        overlayText:{
                            type: 'string',
                            default: OVERLAY_TEXT
                        },
                        overlayBackground:{
                            type:'string',
                            default: OVERLAY_BACKGROUND
                        },
                        overlayTextColor:{
                            type:'string',
                            default: OVERLAY_TEXT_COLOR
                        },
                        overlayIconColor:{
                            type:'string',
                            default: OVERLAY_ICON_COLOR
                        }
                    },
                    required: [],
                },
            },
        },
        {
            title: 'Styles',
            controls: {
                type: 'autoform',
                schema: {
                    properties: {
                        margin: {
                            type: 'string',
                            default: MARGIN,
                        },
                        padding: {
                            type: 'string',
                            default: PADDING,
                        },
                        headerBackgroundColor: {
                            type: 'string',
                            default: HEADER_BACKGROUND_COLOR,
                        },
                        headerPadding: {
                            type: 'string',
                            default: HEADER_PADDING,
                        }
                    },
                    required: [],
                },
            },
        },
    ],
};

export default LoganSearchResultsPlugin;