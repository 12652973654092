import { useEffect } from "react";
import { Row, Col, Form, Table } from "react-bootstrap"
import { connect, useDispatch } from "react-redux";
import { OrderLine } from "../../../../../../models/Order";
import { addOrderLineAction, ADD_ORDER_LINE, calculateOrderAction } from "../../../../../../redux/actions/orderManagement";
import { IActionResult, IAppState } from "../../../../../../redux/storeTypes";
import PerfectScrollbar from 'react-perfect-scrollbar';
import 'react-perfect-scrollbar/dist/css/styles.css';

interface ITylerEaglePaymentReference {
    itemReferenceLabel: string,
    descriptionLabel: string,
    amountLabel: string,
    itemReference: string,
    description: string,
    amount: number,
    actionResult: IActionResult,
    headerBackgroundColor: string
}

export const ITEM_REFERENCE_LABEL = 'Item Reference';
export const DESCRIPTION_LABEL = 'Description';
export const AMOUNT_LABEL = 'Amount';
export const HEADER_BACKGROUND_COLOR = 'rgb(250, 250, 250)';

const checkStyleForDefault = (style:string, defaultStyle:string) => {
    return style && style !== ''?style:defaultStyle;
}

const TylerEaglePaymentReference = ({ itemReferenceLabel, descriptionLabel, amountLabel, itemReference, description, amount, headerBackgroundColor, actionResult }: ITylerEaglePaymentReference) => {
    const actionToken = "TylerEaglePaymentReference";
    const dispatch = useDispatch();
    
    if (!itemReferenceLabel) {
        itemReferenceLabel = ITEM_REFERENCE_LABEL;
    }

    if (!descriptionLabel) {
        descriptionLabel = DESCRIPTION_LABEL;
    }

    if (!amountLabel) {
        amountLabel = AMOUNT_LABEL;
    }

    const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
    });

    useEffect(() => {
        if (itemReference && amount) {
            let orderLine = new OrderLine();

            if (itemReference) {
                orderLine.itemReferenceNumber = itemReference;
                orderLine.itemName = description;
            }
            
            orderLine.quantity = 1;
            orderLine.unitPrice = Number(amount!);
    
            dispatch(addOrderLineAction(orderLine, actionToken));
        }
    }, [itemReference, amount]);

    useEffect(() => {
        if (actionResult && actionResult.type === ADD_ORDER_LINE) {
            dispatch(calculateOrderAction(actionToken));
        }
    }, [actionResult]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <>
        <Row className="mb-0">
            <Col>
                <PerfectScrollbar>
                    <Table responsive bordered={true} className="table-payment-method">
                            <thead>
                                <tr>
                                    <th style={{backgroundColor:checkStyleForDefault(headerBackgroundColor, HEADER_BACKGROUND_COLOR)}}>{itemReferenceLabel}</th>
                                    <th style={{backgroundColor:checkStyleForDefault(headerBackgroundColor, HEADER_BACKGROUND_COLOR)}}>{descriptionLabel}</th>
                                    <th style={{backgroundColor:checkStyleForDefault(headerBackgroundColor, HEADER_BACKGROUND_COLOR)}}>{amountLabel}</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>{itemReference}</td>
                                    <td>{description}</td>
                                    <td>{formatter.format(amount)}</td>
                                </tr>
                            </tbody>
                    </Table>
                </PerfectScrollbar>
            </Col>
        </Row>
      </>
    )
}

const mapStateToProps = (state: IAppState) => {
    return {
        isSaving: state.orderManagement.isSaving,
        actionResult: state.orderManagement.actionResult,
        errorMessage: state.orderManagement.errorMessage
    };
};

export default connect(mapStateToProps)(TylerEaglePaymentReference);