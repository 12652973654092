import { CellPlugin } from '@react-page/editor';
import ItemShoppingCart, { TAX_TYPE_LABEL,TITLE, SUBTITLE, SELECTED_INSTALLMENTS_LABEL,ACCOUNT_NUMBER_LABEL,BALANCE_DUE_LABEL,ACTIONS_LABEL,PAYMENT_AMOUNT_LABEL,
    HEADER_BACKGROUND_COLOR,HEADER_PADDING,MARGIN,PADDING
} from './components/ItemShoppingCart';

type Data = {
    title: string,
    subTitle: string,
    taxType: string,
    hideTaxType: boolean,
    selectedInstallment: string,
    hideSelectedInstallments: boolean,
    accountNumber: string,
    balanceDue: string,
    staticPaymentAmount: boolean,
    paymentAmount: string,
    actions: string,
    headerBackgroundColor: string,
    headerPadding: string,
    margin: string,
    padding: string,
}

const ItemShoppingCartPlugin: CellPlugin<Data> = {
    Renderer: ({ data }) => (
        <ItemShoppingCart
            title = {data.title}
            subTitle={data.subTitle}
            taxTypeLabel={data.taxType}
            hideTaxType={data.hideTaxType}
            selectedInstallmentsLabel={data.selectedInstallment}
            hideSelectedInstallments={data.hideSelectedInstallments}
            accountNumberLabel={data.accountNumber}
            balanceDueLabel={data.balanceDue}
            staticPaymentAmount={data.staticPaymentAmount}
            paymentAmountLabel={data.paymentAmount}
            actionsLabel={data.actions}
            margin={data.margin}
            padding={data.padding}
            headerBackgroundColor={data.headerBackgroundColor}
            headerPadding={data.headerPadding}

        />
    ),
    id: 'itemShoppingCartPlugin',
    title: 'Item Shopping Cart',
    description: 'Adds items to shopping cart',
    version: 1,
    controls: [
        {
            title: 'Labels',
            controls: {
                type: 'autoform',
                schema: {
                    properties: {
                        title: {
                            type: 'string',
                            default: TITLE,
                        },
                        subTitle: {
                            type: 'string',
                            default: SUBTITLE,
                        },
                        taxType: {
                            type: 'string',
                            default: TAX_TYPE_LABEL,
                        },
                        hideTaxType: {
                            type: 'boolean',
                        },
                        selectedInstallment: {
                            type: 'string',
                            default: SELECTED_INSTALLMENTS_LABEL,
                        },
                        hideSelectedInstallments: {
                            type: 'boolean',
                        },
                        accountNumber: {
                            type: 'string',
                            default: ACCOUNT_NUMBER_LABEL,
                        },
                        paymentAmount: {
                            type: 'string',
                            default: PAYMENT_AMOUNT_LABEL,
                        },
                        staticPaymentAmount: {
                            type: 'boolean'
                        },
                        balanceDue: {
                            type: 'string',
                            default: BALANCE_DUE_LABEL,
                        },
                        actions: {
                            type: 'string',
                            default: ACTIONS_LABEL,
                        }
                    },
                    required: [],
                },
            },
        },
        {
            title: 'Styles',
            controls: {
                type: 'autoform',
                schema: {
                    properties: {
                        margin: {
                            type: 'string',
                            default: MARGIN,
                        },
                        padding: {
                            type: 'string',
                            default: PADDING,
                        },
                        headerBackgroundColor: {
                            type: 'string',
                            default: HEADER_BACKGROUND_COLOR,
                        },
                        headerPadding: {
                            type: 'string',
                            default: HEADER_PADDING,
                        }
                    },
                    required: [],
                },
            },
        },
    ],
};

export default ItemShoppingCartPlugin;