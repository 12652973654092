import { CellPlugin } from '@react-page/editor';
import React from 'react';
import { Col, Row } from 'react-bootstrap';

// use a type here, not an interface
type Data = {
    label?: string,
    value: boolean
}


const PinVerifiedPropertyPlugin: CellPlugin<Data> = {
    Renderer: ({ data }) => (
        <Row>
          <>
            <Col>
              <div className="text-center">
                <p>PIN VERIFIED</p>
                <p><strong>I agree to pay the above total amount according to the card issuer agreement.</strong></p> 
                <p><strong>Thank you</strong></p>
              </div>
            </Col>
          </>
        </Row>
    ),
    id: 'pinVerifiedPropertyPlugin',
    title: 'Pin Verified Property',
    description: 'Pin Verified Property.',
    version: 1,
    controls: {
        type: 'autoform',
        schema: {
            properties: {
                label: {
                    type: 'string',
                    default: 'Pin Verified',
                },
            },
        },
    },
};

export default PinVerifiedPropertyPlugin;