import axios from 'axios';
import { Dispatch } from 'react';
import { TokenizeBankAccount, TokenizeCard } from '../../models/Wallet';
import store from '../store';

export const CLEAR_REQUEST = 'CLEAR_REQUEST';

export const ADD_CARD_REQUEST = 'ADD_CARD_REQUEST';
export const ADD_CARD_SUCCESS = 'ADD_CARD_SUCCESS';
export const ADD_CARD_FAILURE = 'ADD_CARD_FAILURE';

export const SAVE_CARD_REQUEST = 'SAVE_CARD_REQUEST';
export const SAVE_CARD_SUCCESS = 'SAVE_CARD_SUCCESS';
export const SAVE_CARD_FAILURE = 'SAVE_CARD_FAILURE';

export const ADD_BANKACCOUNT_REQUEST = 'ADD_BANKACCOUNT_REQUEST';
export const ADD_BANKACCOUNT_SUCCESS = 'ADD_BANKACCOUNT_SUCCESS';
export const ADD_BANKACCOUNT_FAILURE = 'ADD_BANKACCOUNT_FAILURE';

export const SAVE_BANKACCOUNT_REQUEST = 'SAVE_BANKACCOUNT_REQUEST';
export const SAVE_BANKACCOUNT_SUCCESS = 'SAVE_BANKACCOUNT_SUCCESS';
export const SAVE_BANKACCOUNT_FAILURE = 'SAVE_BANKACCOUNT_FAILURE';

export const clearAction = (actionToken?: string) => (dispatch: Dispatch<any>) => {
    dispatch({
        type: CLEAR_REQUEST,
        payload: {
            actionToken
        }
    });
};

export const saveCardAction = (card: TokenizeCard, actionToken?: string) => (dispatch: Dispatch<any>) => {
    dispatch({
        type: SAVE_CARD_REQUEST,
        payload: {
            card: card,
            actionToken: actionToken
        }
    });
};

export const addCardAction = (card: TokenizeCard, actionToken?: string) => (dispatch: Dispatch<any>) => {
    dispatch({
        type: ADD_CARD_REQUEST,
        payload: {
            actionToken: actionToken
        }
    });

    let baseApiUrl = store.getState().webAppSettings.baseApiUrl;
    let _url = `${baseApiUrl}/MSB_Wallet/api/v1/Cards/tokenizeWithZeroDollarAuthorization`;
    let bearerToken = store.getState().webAppSettings.bearerToken;

    const config = {
        headers: {
            Authorization: `Bearer ${bearerToken}`,
        }
    };

    axios.post(_url, card, config)
        .then(function (response) {
            dispatch({
                type: ADD_CARD_SUCCESS,
                payload: {
                    actionToken: actionToken
                }
            });

        })
        .catch(function (error) {
            let errorMessage = 'Unkown Error';
            if (error.response) {
                errorMessage = error.response.data.message;
            }
            dispatch({
                type: ADD_CARD_FAILURE,
                payload: {
                    error: errorMessage,
                    actionToken: actionToken
                }
            });
        });
};

export const saveBankAccountAction = (bankAccount: TokenizeBankAccount, actionToken?: string) => (dispatch: Dispatch<any>) => {
    dispatch({
        type: SAVE_BANKACCOUNT_REQUEST,
        payload: {
            bankAccount: bankAccount,
            actionToken: actionToken
        }
    });
};

export const addBankAccountAction = (bankAccount: TokenizeBankAccount, actionToken?: string) => (dispatch: Dispatch<any>) => {
    dispatch({
        type: ADD_BANKACCOUNT_REQUEST,
        payload: {
            actionToken: actionToken
        }
    });

    let baseApiUrl = store.getState().webAppSettings.baseApiUrl;
    let _url = `${baseApiUrl}/MSB_Wallet/api/v1/BankAccounts/addBankAccount`;
    let bearerToken = store.getState().webAppSettings.bearerToken;

    const config = {
        headers: {
            Authorization: `Bearer ${bearerToken}`,
        }
    };

    axios.post(_url, bankAccount, config)
        .then(function (response) {
            dispatch({
                type: ADD_BANKACCOUNT_SUCCESS,
                payload: {
                    actionToken: actionToken
                }
            });

        })
        .catch(function (error) {
            let errorMessage = 'Unkown Error';
            if (error.response) {
                errorMessage = error.response.data.message;
            }
            dispatch({
                type: ADD_BANKACCOUNT_FAILURE,
                payload: {
                    error: errorMessage,
                    actionToken: actionToken
                }
            });
        });
};

