import {
    SET_TYLER_EAGLE_REQUEST, 
    POST_TYLER_EAGLE_CANCEL_REQUEST, POST_TYLER_EAGLE_CANCEL_SUCCESS, POST_TYLER_EAGLE_CANCEL_FAILURE
} from '../actions/tylerEagleIntegration';

export default function tylerEagleIntegration(state = {
    isSaving: false,
    tylerEagleRequest: null,
    tylerEagleCancel: null,
    actionResult: null
}, { type, payload }) {
    switch (type) {
        case SET_TYLER_EAGLE_REQUEST:
            return Object.assign({}, state, {
                tylerEagleRequest: payload.tylerEagleRequest,
            });
            case POST_TYLER_EAGLE_CANCEL_REQUEST:
                return Object.assign({}, state, {
                    isSaving: true,
                    actionResult: { type: POST_TYLER_EAGLE_CANCEL_REQUEST, result: null, token: payload.actionToken } ,
                });
            case POST_TYLER_EAGLE_CANCEL_SUCCESS:
                return Object.assign({}, state, {
                    isSaving: false,
                    actionResult: { type: POST_TYLER_EAGLE_CANCEL_REQUEST, result: POST_TYLER_EAGLE_CANCEL_SUCCESS, token: payload.actionToken } ,
                    tylerEagleCancel: payload.tylerEagleCancel
                });
            case POST_TYLER_EAGLE_CANCEL_FAILURE:
                return Object.assign({}, state, {
                    isSaving: false,
                    actionResult: { type: POST_TYLER_EAGLE_CANCEL_REQUEST, result: POST_TYLER_EAGLE_CANCEL_FAILURE, token: payload.actionToken } ,
                });
        default:
            return state;
    }
}


