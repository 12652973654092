import React from 'react';
import { Link } from 'react-router-dom';
import { Container, Col, Nav, Row, Form, Button, Tab, Card } from 'react-bootstrap';
import { Routes } from '../routes';
import logo from '../assets/img/logo.svg';
import screen from '../assets/img/screen.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCloud, faEnvelope, faEye, faFileContract, faNetworkWired, faPhoneAlt } from '@fortawesome/free-solid-svg-icons';

const ContactPage = () => {

    const setRoute = (path:string) => {
        return path.replace(":id", "Demo");
    }

    return (
        <>
            <Container>
                <header className="d-flex flex-wrap align-items-center justify-content-center justify-content-md-between py-3">
                    <a href="/" className="d-flex align-items-center col-md-3 mb-2 mb-md-0 text-dark text-decoration-none">
                        <img src={logo} alt="MSB Logo" />
                    </a>
                    <ul className="nav col-12 col-md-auto mb-2 justify-content-center mb-md-0">
                        <li className="nav-item">
                            <Link to={setRoute(Routes.Product.path)} className="nav-link px-3">Product</Link>
                        </li>
                        {/* <li className="nav-item">
                            <Link to={setRoute(Routes.PAboutroduct.path)} className="nav-link px-3">About</Link>
                        </li> */}
                        <li className="nav-item">
                            <Link to={setRoute(Routes.Contact.path)} className="nav-link px-3">Contact</Link>
                        </li>
                        {/* <li className="nav-item"><a href="/status.html" className="nav-link px-3">Status</a></li> */}
                    </ul>
                </header>
            </Container>

            <Container fluid className="py-5 gray-2">
                <Row className="text-center">
                  <Col>
                    <Container>
                      <div className="explore-solutions">
                        <Row>
                          <Col>
                            <h1 className="display-3 bw-bold px-4">Get in touch with our team</h1>
                            <div className="col-lg-9 col-sm-12 mx-auto">
                                <p className="lead mt-3 mb-5">Select a category to email the support team or contact us directly by using the form below.</p>
                            </div>
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <div className="solution-cards">
                              <Card>
                                <Card.Body>
                                  <div className="solution-icon">
                                    <FontAwesomeIcon icon={faFileContract} />
                                  </div>
                                  <Card.Title className="fw-bold">Tech Support</Card.Title>
                                  <Card.Text>
                                     <a href="">email@emailaddress.com</a>
                                  </Card.Text>
                                </Card.Body>
                              </Card>
                              {/* <Card>
                                <Card.Body>
                                  <div className="solution-icon">
                                    <FontAwesomeIcon icon={faNetworkWired} />
                                  </div>
                                  <Card.Title>Website Issue</Card.Title>
                                  <Card.Text>
                                    Nullam quis risus eget urna mollis ornare vel eu leo.
                                  </Card.Text>
                                </Card.Body>
                              </Card> */}
                              <Card>
                                <Card.Body>
                                  <div className="solution-icon">
                                    <FontAwesomeIcon icon={faEnvelope} />
                                  </div>
                                  <Card.Title className="fw-bold">Contact Sales</Card.Title>
                                  <Card.Text>
                                    <a href="">email@emailaddress.com</a>
                                  </Card.Text>
                                </Card.Body>
                              </Card>
                              <Card>
                                <Card.Body>
                                  <div className="solution-icon">
                                    <FontAwesomeIcon icon={faPhoneAlt} />
                                  </div>
                                  <Card.Title className="fw-bold">Billing & Account</Card.Title>
                                  <Card.Text>
                                    (123) 456-7890
                                  </Card.Text>
                                </Card.Body>
                              </Card>
                              {/* <Card>
                                <Card.Body>
                                  <div className="solution-icon">
                                    <FontAwesomeIcon icon={faNetworkWired} />
                                  </div>
                                  <Card.Title>Lorem ipsum</Card.Title>
                                  <Card.Text>
                                    Nullam quis risus eget urna mollis ornare vel eu leo. 
                                  </Card.Text>
                                </Card.Body>
                              </Card> */}
                              {/* <Card>
                                <Card.Body>
                                  <div className="solution-icon">
                                    <FontAwesomeIcon icon={faNetworkWired} />
                                  </div>
                                  <Card.Title>Lorem ipsum</Card.Title>
                                  <Card.Text>
                                    Nullam quis risus eget urna mollis ornare vel eu leo.
                                  </Card.Text>
                                </Card.Body>
                              </Card> */}
                            </div>
                          </Col>
                        </Row>
                      </div>
                    </Container>
                  </Col>
                </Row>
            </Container>

            <Container className="py-5">
              <Row className="text-center">
                <Col>
                  <h1 className="display-3 bw-bold px-4 mb-4">Have a question about MSB?<br/>Contact us directly</h1>
                </Col>
              </Row>
              <Row>
                <Col lg={6} sm={12} className="mx-auto">
                  <Form>
                    <Row>
                      <Col>
                        <Form.Group controlId="formGridFirstName">
                          <Form.Label>First name</Form.Label>
                          <Form.Control type="text" placeholder="Enter first name" />
                        </Form.Group>
                      </Col>
                      <Col>
                        <Form.Group controlId="formGridLastName">
                          <Form.Label>Last name</Form.Label>
                          <Form.Control type="text" placeholder="Enter last name" />
                        </Form.Group>
                      </Col>
                    </Row>

                    <Row>
                      <Form.Group controlId="formGridEmail">
                        <Form.Label>Email address</Form.Label>
                        <Form.Control type="email" placeholder="Enter email address" />
                      </Form.Group>
                    </Row>
                    
                    <Row>
                      <Form.Group controlId="formGridSubject">
                        <Form.Label>Subject</Form.Label>
                        <Form.Control placeholder="How can we help you?" />
                      </Form.Group>
                    </Row>
                    
                    <Row>
                      <Form.Group controlId="formGridCountry">
                        <Form.Label>Country</Form.Label>
                        <Form.Control as="select" defaultValue="Choose...">
                          <option>Choose...</option>
                          <option>...</option>
                        </Form.Control>
                      </Form.Group>
                    </Row>

                    <Row>
                      <Form.Group controlId="exampleForm.ControlTextarea1">
                        <Form.Label>Message</Form.Label>
                        <Form.Control as="textarea" rows={3} placeholder="Enter message" />
                      </Form.Group>
                    </Row>

                    <Button className="btn msb-btn--primary w-100 mb-4" type="submit">
                      Submit
                    </Button>

                    <small className="text-muted">By clicking "Submit" you consent to allow MSB to store and process the personal information submitted above and agree to our <a href="#">terms and conditions</a> as well as our <a href="#">Privacy Policy</a>.</small>
                  </Form>
                </Col>
              </Row>
            </Container>

            <footer className="gradient-2">
                <div className="container">
                    <div className="row py-5">
                        <div className="col-md-6 col-sm-12">
                            <a href="#" className="me-3">Privacy Policy</a>
                            <a href="#">Terms of Use</a>
                        </div>
                        <div className="col-md-3 col-sm-12">
                            <h5 className="fw-bold">Address</h5>
                            <p className="dark-gray">Municipal Services Bureau<br />
                                325 Daniel Zenker Drive<br />
                                Suite 3<br />
                                Horseheads, NY 14845
                            </p>
                        </div>
                        <div className="col-md-3 col-sm-12">
                            <h5 className="fw-bold">Contact</h5>
                            <p className="dark-gray">
                                Monday &ndash; Friday<br />
                                8:00 am EST &ndash; 12:00 am EST<br />
                                Phone: 1-800-616-0166<br />
                                Fax: 1-800-756-4110
                            </p>
                        </div>
                    </div>
                </div>
            </footer>
            <footer className="footer">
                <div className="container">
                    <div className="row pt-5 mb-5">
                        <div className="col-12">
                            <p className="gray-7">© 2021 Gila, LLC d/b/a Municipal Services Bureau (MSB) is a registered ISO of Fifth Third
                            Bank, N.A.
                            Cincinnati, OH. All rights reserved. The MSB logo is a registered service mark of Gila, LLC. Gila, LLC is a
                            subsidiary of Navient Corporation. Navient Corporation and its subsidiaries, including Gila, LLC, are not
                    sponsored by or agencies of the United States of America.</p>
                        </div>
                    </div>
                </div>
            </footer>
        </>
    );
}
export default ContactPage;