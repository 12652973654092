import React, { useEffect, useState } from "react";
import { Form, Col, Row, Button } from "react-bootstrap";
import RequiredIcon from "../../../../../../components/RequiredIcon";
import { StateDropdown } from "../../../../../../components/StateDropdown";
import Inputmask from "react-input-mask";
import { SUBMIT_ADDRESS_CHANGE_FAILURE, SUBMIT_ADDRESS_CHANGE_REQUEST, SUBMIT_ADDRESS_CHANGE_SUCCESS, submitStCharlesAddressChange } from "../../../../../../redux/actions/account";
import { connect, useDispatch } from "react-redux";
import FormHeader from '../../../../../../components/layout/FormHeader';
import { IAppState } from '../../../../../../redux/storeTypes';
import { sendSuccessToastAction, sendWarningToastAction } from "../../../../../../redux/actions/toast";

export interface IStCharlesAddressFormProps {
    selectedRow: any,
    firstNameLabel: string,
    lastNameLabel: string,
    emailLabel: string,
    phoneNumberLabel: string,
    newAddressLabel: string,
    cityLabel: string,
    stateLabel: string,
    zipLabel: string,
    checkboxDisclaimer: string,
    addressChangeDisclaimer: string,
    firstNameRequired: boolean,
    lastNameRequired: boolean,
    emailRequired: boolean,
    phoneNumberRequired: boolean,
    newAddressRequired: boolean,
    cityRequired: boolean,
    stateRequired: boolean,
    zipCodeRequired: boolean,
    checkboxDisclaimerRequired: boolean,
    isFetching?: boolean,
    onClose?: any
    address?: Address,
    actionResult?: any,
    isSaving?: any
}

export class Address {
    name?: string;
    email?: string;
    phone?: string;
    address?: string;
    city?: string;
    state?: string;
    zip?: string;
    pin?: string;
    taxYear?: string;
    billNumber?: string;
    taxBillId?: string;
    type?: string;
    revObjId?: string;
}

const StCharlesAddressForm = ({ selectedRow, firstNameLabel, lastNameLabel, emailLabel, phoneNumberLabel, newAddressLabel, cityLabel, stateLabel, zipLabel, checkboxDisclaimer, addressChangeDisclaimer,
    firstNameRequired, lastNameRequired, emailRequired, phoneNumberRequired, newAddressRequired, cityRequired, stateRequired, zipCodeRequired, checkboxDisclaimerRequired,
    isFetching, isSaving, onClose, address, actionResult }: IStCharlesAddressFormProps) => {

    const [validated, setValidated] = useState<boolean>(false);
    const [agreeToDisclaimer, setAgreeToDisclaimer] = useState<boolean>(false);
    const dispatch = useDispatch();

    useEffect(() => {
        if (actionResult && actionResult.type === SUBMIT_ADDRESS_CHANGE_REQUEST) {
            if (actionResult && actionResult.result === SUBMIT_ADDRESS_CHANGE_SUCCESS) {
                dispatch(sendSuccessToastAction('Address will be updated'));
                onClose();
            }
            if (actionResult && actionResult.result === SUBMIT_ADDRESS_CHANGE_FAILURE) {
                dispatch(sendWarningToastAction('Error changing the address, Please try again later'));
            }
        }
    }, [actionResult]);

    const handleSubmit = (event: any) => {
        const form = event.currentTarget;
        event.preventDefault();
        if (form.checkValidity() !== false) {
            let _address: Address = Object.assign({}, address!!);
            _address.name = `${form.elements.firstName.value.replace(/ +/g, ' ').trim()} ${form.elements.lastName.value.replace(/ +/g, ' ').trim()}`;
            _address.email = form.elements.email.value;
            _address.phone = form.elements.phoneNumber.value;
            _address.address = form.elements.newAddress.value.replace(/(\r\n|\n|\r)+/g, ' ').replace(/ +/g, ' ').trim();
            _address.city = form.elements.city.value.replace(/ +/g, ' ').trim();
            _address.state = form.elements.state.value;
            _address.zip = form.elements.zipCode.value;
            _address.pin = selectedRow?.pin;
            _address.taxYear = selectedRow?.billInformation[0]?.taxYear;
            _address.billNumber = selectedRow?.billInformation[0]?.billNumber;
            _address.taxBillId = selectedRow?.billInformation[0]?.taxBillId;
            _address.type = selectedRow?.propertyType;
            _address.revObjId = selectedRow?.revObjId
            dispatch(submitStCharlesAddressChange(_address, 'address'))
        }
        setValidated(true);
    };

    return (
        <Form id="stCharles_address_form" noValidate validated={validated} onSubmit={handleSubmit}>
            <FormHeader title={'Address Change'} description={`Bill Number: ${selectedRow?.billInformation[0]?.billNumber}, Owner: ${selectedRow?.ownerName} , PIN: ${selectedRow?.pin}, Tax Year: ${selectedRow?.billInformation[0]?.taxYear}`} />
            <div className="form-header">
                <span>{addressChangeDisclaimer}</span>
            </div>
            <Row>
                <Col lg={6} sm={12}>
                    <Form.Group controlId="firstName">
                        <Form.Label>{firstNameRequired && <RequiredIcon />}{firstNameLabel}</Form.Label>
                        <Form.Control required={firstNameRequired} type="input" disabled={isFetching} maxLength={40} placeholder="Enter first name" />
                        <Form.Control.Feedback type="invalid">Please enter first name</Form.Control.Feedback>
                    </Form.Group>
                </Col>
                <Col lg={6} sm={12}>
                    <Form.Group controlId="lastName">
                        <Form.Label>{lastNameRequired && <RequiredIcon />}{lastNameLabel}</Form.Label>
                        <Form.Control required={lastNameRequired} type="input" disabled={isFetching} maxLength={40} placeholder="Enter last name" />
                        <Form.Control.Feedback type="invalid">Please enter last name</Form.Control.Feedback>
                    </Form.Group>
                </Col>
            </Row>
            <Row>
                <Col lg={6} sm={12}>
                    <Form.Group controlId="email">
                        <Form.Label>{emailRequired && <RequiredIcon />}{emailLabel}</Form.Label>
                        <Form.Control required={emailRequired} type="email" disabled={isFetching} maxLength={100} placeholder="Enter email" />
                        <Form.Control.Feedback type="invalid">Please enter email</Form.Control.Feedback>
                    </Form.Group>
                </Col>
                <Col lg={6} sm={12}>
                    <Form.Group controlId="phoneNumber">
                        <Form.Label>{phoneNumberRequired && <RequiredIcon />}{phoneNumberLabel}</Form.Label>
                        <Inputmask disabled={isFetching} required={phoneNumberRequired} type="input" id="phoneNumber" pattern="[0-9\-\(\)\s]{14}" name="phoneNumber" className="form-control" mask="(999) 999-9999" placeholder="(999) 999-9999" />
                    </Form.Group>
                </Col>
            </Row>
            <Row>
                <Col lg={6} sm={12}>
                    <Form.Group controlId="newAddress">
                        <Form.Label>{newAddressRequired && <RequiredIcon />}{newAddressLabel}</Form.Label>
                        <Form.Control required={newAddressRequired} as="textarea" disabled={isFetching} placeholder="Enter new address" />
                        <Form.Control.Feedback type="invalid">Please enter new address</Form.Control.Feedback>
                    </Form.Group>
                </Col>
            </Row>
            <Row>
                <Col lg={4} sm={12}>
                    <Form.Group controlId="city">
                        <Form.Label>{cityRequired && <RequiredIcon />}{cityLabel}</Form.Label>
                        <Form.Control type="input" required={cityRequired} disabled={isFetching} maxLength={40} placeholder="Enter city" />
                        <Form.Control.Feedback type="invalid">Please enter city</Form.Control.Feedback>
                    </Form.Group>
                </Col>
                <Col lg={4} sm={12}>
                    <Form.Group controlId="state">
                        <Form.Label>{stateRequired && <RequiredIcon />}{stateLabel}</Form.Label>
                        <StateDropdown required={stateRequired} disabled={isFetching} ></StateDropdown>
                        <Form.Control.Feedback type="invalid">Please select a state</Form.Control.Feedback>
                    </Form.Group>
                </Col>
                <Col lg={4} sm={12}>
                    <Form.Group controlId="zipCode">
                        <Form.Label>{zipCodeRequired && <RequiredIcon />}{zipLabel}</Form.Label>
                        <Inputmask id="zipCode" name="zipCode" required={zipCodeRequired} type="input" disabled={isFetching} pattern="[0-9]{5}" mask="99999" className="form-control" placeholder="Enter Zip code" />
                        <Form.Control.Feedback type="invalid">Please enter a zip code</Form.Control.Feedback>
                    </Form.Group>
                </Col>
            </Row>
            <Row>
                <Col lg={8} sm={12}>
                    <Form.Check style={{ marginTop: "10px" }} label={<span>{checkboxDisclaimerRequired && <RequiredIcon />}{checkboxDisclaimer}</span>} disabled={isFetching} required={checkboxDisclaimerRequired} checked={agreeToDisclaimer} onChange={(e) => setAgreeToDisclaimer(e.target.checked)} />
                </Col>
            </Row>
            <div style={{ marginTop: "20px", float: 'right' }}>
                <Form.Group style={{ display: 'flex', justifyContent: 'space-between', marginTop: 0 }}>
                    <Button disabled={isSaving} type="submit">Submit</Button>
                </Form.Group>
            </div>
        </Form>
    );
};

const mapStateToProps = (state: IAppState) => {
    return {
        isFetching: state.account.isFetching,
        isSaving: state.account.isSaving,
        errorMessage: state.account.errorMessage,
        actionResult: state.account.actionResult
    };
};

export default connect(mapStateToProps)(StCharlesAddressForm);
