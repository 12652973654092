import { Paper, TextField } from "@mui/material";
import { Link } from "../../models/CheckoutLink";
import { connect } from "react-redux";
import { IAppState } from "../../redux/storeTypes";

export interface IMemoProps {
    link: Link;
}

const Memo = ({ link }: IMemoProps) => {
    return (
        <>
        {link &&  link.memo &&
        <Paper style={{ padding: "8px" }}>
            <TextField type="text" label="Memo" multiline value={link.memo} style={{ width: "100%" }} inputProps={{ readOnly: true, }} size="small" />
        </Paper>}
        </>
    );
};

const mapStateToProps = (state: IAppState) => {
    return {
        link: state.link.link,
    };
};

export default connect(mapStateToProps)(Memo);