import { CellPlugin } from '@react-page/editor';
import ItemSearch, {
    SEARCH_PLACEHOLDER, ACCOUNT_ID_LABEL, ADDRESS_LABEL, AMOUNT_DUE_LABEL, ACTIONS_LABEL, OWNERS_LABEL, HEADER_BACKGROUND_COLOR,
    PAYMENT_DATE_LABEL, PAYMENT_METHOD_LABEL, PAYMENT_OPTION_LABEL, CONVENIENCE_FEE_LABEL, PAYMENT_REMINDER_DATE_LABEL, EMAIL_ADDRESS_LABEL,EMAIL_ADDRESS_PLACEHOLDER
} from './components/ItemSearch';

// use a type here, not an interface
type Data = {
    title: string;
    searchPlaceholder: string,
    accountId: string,
    owners: string,
    address: string,
    amountDue: string,
    action: string,
    paymentDate: string,
    paymentOption: string,
    convenienceFee: string,
    paymentMethod: string,
    paymentReminderDate: string,
    emailAddress: string,
    emailAddressPlaceholder: string,
    headerBackgroundColor: string
}

const ItemSearchPlugin: CellPlugin<Data> = {
    Renderer: ({ data }) => (
        <ItemSearch
            searchPlaceholder={data.searchPlaceholder}
            accountIdLabel={data.accountId}
            ownersLabel={data.owners}
            addressLabel={data.address}
            amountDueLabel={data.amountDue}
            actionLabel={data.action}
            paymentDateLabel={data.paymentDate}
            paymentMethodLabel={data.paymentMethod}
            convenienceFeeLabel={data.convenienceFee}
            paymentOptionLabel={data.paymentOption}
            paymentReminderDateLabel={data.paymentReminderDate}
            emailAddressLabel={data.emailAddress}
            emailAddressPlaceholder={data.emailAddressPlaceholder}
            headerBackgroundColor={data.headerBackgroundColor}
        />
    ),
    id: 'itemSearchPlugin',
    title: 'Item Search',
    description: 'Item Search',
    version: 1,
    controls: [
        {
            title: 'Labels',
            controls: {
                type: 'autoform',
                schema: {
                    properties: {
                        searchPlaceholder: {
                            type: 'string',
                            default: SEARCH_PLACEHOLDER,
                        },
                        accountId: {
                            type: 'string',
                            default: ACCOUNT_ID_LABEL,
                        },
                        owners: {
                            type: 'string',
                            default: OWNERS_LABEL,
                        },
                        address: {
                            type: 'string',
                            default: ADDRESS_LABEL,
                        },
                        amountDue: {
                            type: 'string',
                            default: AMOUNT_DUE_LABEL,
                        },
                        action: {
                            type: 'string',
                            default: ACTIONS_LABEL,
                        }
                    },
                    required: ['accountId', 'owners', 'address', 'amountDue'],
                },
            },
        },
        {
            title: 'Styles',
            controls: {
                type: 'autoform',
                schema: {
                    properties: {
                        headerBackgroundColor: {
                            type: 'string',
                            default: HEADER_BACKGROUND_COLOR,
                        }
                    },
                    required: [],
                },
            },
        },
        {
            title: 'Modal Labels',
            controls: {
                type: 'autoform',
                schema: {
                    properties: {
                        paymentDate: {
                            type: 'string',
                            default: PAYMENT_DATE_LABEL,
                        },
                        paymentOption: {
                            type: 'string',
                            default: PAYMENT_OPTION_LABEL,
                        },
                        paymentMethod: {
                            type: 'string',
                            default: PAYMENT_METHOD_LABEL,
                        },
                        convenienceFee: {
                            type: 'string',
                            default: CONVENIENCE_FEE_LABEL,
                        },
                        paymentReminderDate: {
                            type: 'string',
                            default: PAYMENT_REMINDER_DATE_LABEL,
                        },
                        emailAddress: {
                            type: 'string',
                            default: EMAIL_ADDRESS_LABEL,
                        },
                        emailAddressPlaceholder: {
                            type: 'string',
                            default: EMAIL_ADDRESS_PLACEHOLDER,
                        },
                    },
                    required: [],
                },
            },
        },
    ],
};

export default ItemSearchPlugin;