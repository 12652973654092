import { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { CurrencyFormatter } from "../../../../../../components/Formatters";
import Table from "../../../../../../components/Table";
import { Order, OrderLine} from "../../../../../../models/Order";
import { RedirectRequest } from "../../../../../../models/RedirectIntegration";
import { calculateOrderAction, updateOrderDetailsAction, UPDATE_ORDER_DETAILS } from "../../../../../../redux/actions/orderManagement";
import { IActionResult, IAppState } from "../../../../../../redux/storeTypes";

interface IInvoicePayment {
    itemsHeaderLabel: string;
    itemReferenceLabel: string;
    itemNameLabel: string;
    itemAmountLabel: string;
    showItemNameOption: boolean;
    actionResult: IActionResult;
    redirectRequest: RedirectRequest;
    fullStoryMask: boolean;
    allowLesserAmount: boolean;
    allowGreaterAmount: boolean;
    minimumAmount: string;
    order: Order;
}

export const ITEMS_HEADER_LABEL = 'Invoice';
export const ITEM_REFERENCE_LABEL = 'Item Reference';
export const ITEM_NAME_LABEL = 'Item Name';
export const ITEM_AMOUNT_LABEL = 'Item Amount';
export const FULL_STORY_MASK = false;
export const ALLOW_LESSER_AMOUNT = false;
export const ALLOW_GREATER_AMOUNT = false;
export const MINIMUM_AMOUNT = "5.00";

const InvoicePayment = ({ itemsHeaderLabel, itemReferenceLabel, itemNameLabel, itemAmountLabel, showItemNameOption, actionResult, redirectRequest, fullStoryMask, allowLesserAmount, allowGreaterAmount, minimumAmount, order }: IInvoicePayment) => {
    const actionToken = "InvoicePayment";
    const dispatch = useDispatch();
    const [invoice, setInvoice] = useState(redirectRequest?.invoice.map(a => { return { ...a } }));
    const minValue = parseFloat(minimumAmount);

    if (!itemsHeaderLabel) itemsHeaderLabel = ITEMS_HEADER_LABEL;
    if (!itemReferenceLabel) itemReferenceLabel = ITEM_REFERENCE_LABEL;
    if (!itemNameLabel) itemNameLabel = ITEM_NAME_LABEL;
    if (!itemAmountLabel) itemAmountLabel = ITEM_AMOUNT_LABEL;
    fullStoryMask = fullStoryMask || FULL_STORY_MASK;
    fullStoryMask = fullStoryMask || FULL_STORY_MASK;
    allowLesserAmount = allowLesserAmount || ALLOW_LESSER_AMOUNT;
    allowGreaterAmount = allowGreaterAmount || ALLOW_GREATER_AMOUNT;
    minimumAmount = minimumAmount || MINIMUM_AMOUNT;

    const columns = [
        {
            dataField: 'reference',
            editable: false,
            text: itemReferenceLabel,
        },
        {
            dataField: 'name',
            editable: false,
            text: itemNameLabel,
        },
        {
            dataField: 'amount',
            editable: allowLesserAmount || allowGreaterAmount,
            text: itemAmountLabel,
            formatter: CurrencyFormatter,
        },
    ];

    if (!showItemNameOption) columns.splice(1, 1);

    useEffect(() => {
        if (actionResult && actionResult.type === UPDATE_ORDER_DETAILS) {
            dispatch(calculateOrderAction(actionToken));
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [actionResult]);

    const handleTableChange = (type: any, newState: any) => {
        const _invoice = [...invoice];
        const newValue = parseFloat(newState.cellEdit.newValue.trim());
        if (!isNaN(newValue)) {
            const oldItem = redirectRequest.invoice.find((i) => i.reference === newState.cellEdit.rowId);
            if (oldItem) {
                const oldValue = parseFloat(oldItem.amount);
                const newItem = _invoice.find((i) => i.reference === newState.cellEdit.rowId);
                if ((allowLesserAmount && newValue >= minValue && newValue <= oldValue) || (allowGreaterAmount && newValue > oldValue)) {
                    newItem!.amount = newValue.toFixed(2);
                } else {
                    newItem!.amount = oldValue.toFixed(2);
                }
            }
        }
        setInvoice(_invoice);
    };

    useEffect(() => {
        if (invoice.length) {
            const _order = Object.assign({}, order);
            _order.orderLines = [];
            invoice.forEach((item) => {
                let _orderLine = new OrderLine();
                _orderLine.itemName = item.name;
                _orderLine.itemReferenceNumber = item.reference;
                _orderLine.unitPrice = Number(item.amount);
                _orderLine.quantity = 1;
                _orderLine.itemMetaData = JSON.parse(item.supplementalData || "{}");
                _order.orderLines.push(_orderLine);
            });
            dispatch(updateOrderDetailsAction(_order, actionToken));
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [invoice]);

    function updateElement(o: any) {
        var event = new Event('input', { bubbles: true });
        return o ? o.dispatchEvent(event) : false;
    }

    useEffect(() => {
        if (redirectRequest?.invoice) {
            const _order = new Order();

            redirectRequest.invoice.forEach((item) => {
                let _orderLine = new OrderLine();
                _orderLine.itemName = item.name;
                _orderLine.itemReferenceNumber = item.reference;
                _orderLine.unitPrice = Number(item.amount);
                _orderLine.quantity = 1;
                _orderLine.itemMetaData = JSON.parse(item.supplementalData || "{}");
                _order.orderLines.push(_orderLine);
            });

            _order.methodName = "Sale";
            _order.externalCustomerReference = redirectRequest.uniqueId || "";
            _order.nameOnCard = redirectRequest.billingName || "";
            _order.addressLine1 = redirectRequest.billingAddress1 || "";
            _order.addressLine2 = redirectRequest.billingAddress2 || "";
            _order.city = redirectRequest.billingCity || "";
            _order.state = redirectRequest.billingState || "";
            _order.zip = redirectRequest.billingZip || "";
            _order.phone = redirectRequest.billingPhone || "";
            _order.emailAddress = redirectRequest.billingEmail || "";
            _order.initiatedBy = redirectRequest.initiatedBy || "";

            let metadata = JSON.parse(redirectRequest.supplementalData || "{}");
            Object.entries(metadata).map(([key, value]) => {
                let element = document!.getElementById("cmd_" + key)! as HTMLFormElement;
                if (element) {
                    element.value = value;
                    updateElement(element);
                }
            })
            dispatch(updateOrderDetailsAction(_order, actionToken));
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [redirectRequest]);

    return (
        <div className={fullStoryMask ? "fs-mask" : ""}>
            <h2 className="fw-bold" style={{marginBottom: '0px'}}>{itemsHeaderLabel}</h2>
            <div style={{ textAlign: 'right', fontSize: '0.8em', fontWeight: 'normal' }}>
                {(allowLesserAmount && !allowGreaterAmount) && <i>Lesser amounts are allowed with ${minValue.toFixed(2)} minimum. Click on amount to change.</i>}
                {(!allowLesserAmount && allowGreaterAmount) && <i>Greater amounts are allowed. Click on amount to change.</i>}
                {(allowLesserAmount && allowGreaterAmount) && <i>Any amounts are allowed with ${minValue.toFixed(2)} minimum. Click on amount to change.</i>}
                <i>&nbsp;</i>
            </div>
            <Table
                remote={true}
                keyField="reference"
                columns={columns}
                data={invoice || []}
                onTableChange={handleTableChange}
            />
      </div>
    )
}

const mapStateToProps = (state: IAppState) => {
    return {
        isSaving: state.orderManagement.isSaving,
        order: state.orderManagement.order,
        actionResult: state.orderManagement.actionResult,
        errorMessage: state.orderManagement.errorMessage,
        redirectRequest: state.redirectIntegration.redirectRequest,
    };
};

export default connect(mapStateToProps)(InvoicePayment);