import { Row, Col, Modal, Form, Button, Spinner } from "react-bootstrap"
import { useEffect, useState } from "react";
import { IAppState } from '../../../../../../redux/storeTypes';
import { connect } from "react-redux";
import Table from '../../../../../../components/Table';
import { ItemType } from "../../../../../../models/Account";
import FormHeader from "../../../../../../components/layout/FormHeader";
import RadioComponent from "./NericRadioComponent";
import { CurrencyFormatter } from "../../../../../../components/Formatters";
import { ItemStatusType } from "../../../../../../models/Order";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faInfoCircle, faTimes } from "@fortawesome/free-solid-svg-icons";

interface INericSearchResults {
    taxTypeLabel: string,
    hideTaxType: boolean,
    billNumberLabel: string,
    owner1Label: string,
    owner2Label: string,
    parcelLocationLabel: string,
    balanceDueLabel: string,
    statusLabel: string,
    paymentOptionsLabel: string,
    observesWeekends: boolean,
    durationOfPendingHours: number,
    margin: string,
    padding: string,
    headerBackgroundColor: string,
    headerPadding: string,
    itemDetails: any,
    isFetching: boolean
    numberOfInstallments?: number,
}

export const TAX_TYPE_LABEL = 'Tax type';
export const HIDE_TAX_TYPE = true;
export const BILL_NUMBER_LABEL = 'NERIC Bill Number';
export const OWNER1_LABEL = 'Owner 1';
export const OWNER2_LABEL = 'Owner 2';
export const PARCEL_LOCATION_LABEL = 'Parcel Location'
export const BALANCE_DUE_LABEL = 'Balance Due';
export const STATUS_LABEL = 'Status';
export const PAYMENT_OPTIONS_LABEL = 'Payment Options';
export const NUMBER_OF_INSTALLMENTS = 1;
export const OBSERVES_WEEKENDS = false;
export const DURATION_OF_PENDING_HOURS = 24;
export const MARGIN = '0px';
export const PADDING = '0px';
export const HEADER_BACKGROUND_COLOR = 'rgb(250, 250, 250)';
export const HEADER_PADDING = '0';

const checkStyleForDefault = (style: string, defaultStyle: string) => {
    return style && style !== '' ? style : defaultStyle;
}

export const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
});

const NericSearchResults = ({ taxTypeLabel, hideTaxType, billNumberLabel, owner1Label, owner2Label, parcelLocationLabel, balanceDueLabel,
    statusLabel, paymentOptionsLabel, numberOfInstallments, durationOfPendingHours, observesWeekends, margin, padding, headerBackgroundColor, headerPadding, itemDetails, isFetching }: INericSearchResults) => {

    if (!taxTypeLabel) { taxTypeLabel = TAX_TYPE_LABEL }
    if (!billNumberLabel) { billNumberLabel = BILL_NUMBER_LABEL }
    if (!owner1Label) { owner1Label = OWNER1_LABEL }
    if (!owner2Label) { owner2Label = OWNER2_LABEL }
    if (!parcelLocationLabel) { parcelLocationLabel = PARCEL_LOCATION_LABEL}
    if (!balanceDueLabel) { balanceDueLabel = BALANCE_DUE_LABEL }
    if (!statusLabel) { statusLabel = STATUS_LABEL }
    if (!paymentOptionsLabel) { paymentOptionsLabel = PAYMENT_OPTIONS_LABEL }
    if (!numberOfInstallments) { numberOfInstallments = NUMBER_OF_INSTALLMENTS }
    if (!durationOfPendingHours) { durationOfPendingHours = DURATION_OF_PENDING_HOURS }
    if (!observesWeekends) { observesWeekends = OBSERVES_WEEKENDS }
    if (!margin) { margin = MARGIN }
    if (!padding) { padding = PADDING }
    if (!headerBackgroundColor) { headerBackgroundColor = HEADER_BACKGROUND_COLOR }
    if (!headerPadding) { headerPadding = HEADER_PADDING }

    const amountFormatter = (cell: any) => {
        return (<span>{formatter.format(cell)}</span>)
    }

    const taxFormatter = (cell: any, row: any) => {
        return (
            <span>{ItemType[cell]}</span>
        );

    }

    const statusFormatter = (cell: any, row: any) => {
        const statusValue = (row?.itemPaymentStatus == ItemStatusType.Paid || row?.metaData?.GrandTotal == 0) ? 'Paid' : row?.itemPaymentStatus == ItemStatusType.Pending ? 'Pending' : 'Unpaid';
        let color: string = "orange";
        if (statusValue === 'Paid') { color = "#52C41A" }
        if (statusValue === 'Pending') { color = "#f5b759" }
        if (statusValue === 'Unpaid') { color = "#F5222D" }
        return (
            <span style={{ display: 'flex', alignItems: 'center' }}>
                <FontAwesomeIcon icon={statusValue === 'Paid' ? faCheck : statusValue === 'Pending' ? faInfoCircle : faTimes} className="btn-icon" style={{ color: `${color}` }} />
                {statusValue}
            </span>
        )
    }

    const paymentOptionsFormatter = (cell: any, row: any) => {
        const isViewable = row?.itemPaymentStatus != ItemStatusType.Pending && row?.itemPaymentStatus != ItemStatusType.Paid && row?.metaData?.GrandTotal > 0;
        if (isViewable) {
            return (
                <span><a onClick={() => { setSelectedRow(row); setShowModal(true) }}>Payment Plans</a></span>
            )
        }
    }

    const initialColumns = [{
        dataField: 'metaData.ItemType',
        text: taxTypeLabel,
        hidden: hideTaxType,
        headerStyle: {
            backgroundColor: checkStyleForDefault(headerBackgroundColor, HEADER_BACKGROUND_COLOR)
        },
        formatter: taxFormatter,
        editable: false,
        sort: true
    }, {
        dataField: 'metaData.BillNumber',
        text: billNumberLabel,
        editable: false,
        headerStyle: {
            backgroundColor: checkStyleForDefault(headerBackgroundColor, HEADER_BACKGROUND_COLOR)
        },
        sort: true,
    }, {
        dataField: 'metaData.OwnerName',
        text: owner1Label,
        headerStyle: {
            backgroundColor: checkStyleForDefault(headerBackgroundColor, HEADER_BACKGROUND_COLOR)
        },
        editable: false,

    }, {
        dataField: 'metaData.InCareOfName',
        text: owner2Label,
        headerStyle: {
            backgroundColor: checkStyleForDefault(headerBackgroundColor, HEADER_BACKGROUND_COLOR)
        },
        editable: false,

    }, {
        dataField: 'metaData.ParcelLocation',
        text: parcelLocationLabel,
        headerStyle: {
            backgroundColor: checkStyleForDefault(headerBackgroundColor, HEADER_BACKGROUND_COLOR)
        },
        editable: false,

    }, {
        dataField: 'metaData.GrandTotal',
        text: balanceDueLabel,
        headerStyle: {
            backgroundColor: checkStyleForDefault(headerBackgroundColor, HEADER_BACKGROUND_COLOR)
        },
        editable: false,
        formatter: amountFormatter,

    }, {
        dataField: 'status',
        text: statusLabel,
        headerStyle: {
            backgroundColor: checkStyleForDefault(headerBackgroundColor, HEADER_BACKGROUND_COLOR)
        },
        formatter: statusFormatter,
        editable: false,
    }, {
        dataField: 'action',
        text: paymentOptionsLabel,
        headerStyle: {
            backgroundColor: checkStyleForDefault(headerBackgroundColor, HEADER_BACKGROUND_COLOR)
        },
        formatter: paymentOptionsFormatter,
        editable: false,
    }];

    const [columns, setColumns] = useState<any>(initialColumns);
    const [data, setData] = useState<any>([]);
    const [selectedRow, setSelectedRow] = useState<any>([]);
    const [showModal, setShowModal] = useState<boolean>(false);


    const mappingDetails = [
        { heading: billNumberLabel, value: selectedRow?.metaData?.BillNumber },
        { heading: owner1Label, value: selectedRow?.metaData?.OwnerName },
        { heading: owner2Label, value: selectedRow?.metaData?.InCareOfName },
        { heading: parcelLocationLabel, value: selectedRow?.metaData?.ParcelLocation },
        { heading: balanceDueLabel, value: CurrencyFormatter(selectedRow?.metaData?.GrandTotal) },
    ]

    useEffect(() => {
        const newColumns = columns.map((column: any) => {
            if (column.dataField !== 'taxType') return column;
            return {
                ...column,
                hidden: hideTaxType
            }
        })
        setColumns(newColumns)
    }, [hideTaxType]);

    useEffect(() => {
        if (itemDetails) {
            setData(itemDetails)
        }
    }, [itemDetails])

    const renderTable = () => {
        if (isFetching) {
            return (<div style={{ textAlign: 'center' }} ><Spinner animation="border" /> </div>);
        }
        else if (data.length === 0) {
            return (<div>Currently there are no records to display. Please do a search to find the record you are looking for.</div>)
        } else {
            return (
                <>
                    <Table
                        keyField="msbId"
                        data={data}
                        remote={false}
                        columns={columns}
                        sizePerPage={5}
                    />
                </>);
        }
    };

    return (

        <div style={{ margin: margin, padding: padding }}>
            <Row><Col>{renderTable()}</Col></Row>
            <Modal show={showModal} size={'lg'} backdrop="static" onHide={() => setShowModal(false)}>
                <Modal.Header closeButton />
                <Modal.Body>
                        <FormHeader title="Item Details" description={"Below are the selected item details"} />
                        <Form.Group controlId="printTerminal" >
                            <>
                                {Object.entries(mappingDetails).map(
                                    ([index, selectedArray]: any) => {
                                        return (
                                            <div key={index} className="neric_details_row">
                                                <h3>{selectedArray.heading}</h3>
                                                <span>{selectedArray.value}</span>
                                            </div>
                                        )
                                    }
                                )}
                            </>
                        </Form.Group>
                        <hr />
                        <FormHeader title="Payment Options" description={"Please select the following payment options"} />
                        <Form.Group controlId="paymentOptions">
                        {
                            selectedRow ?
                                <RadioComponent selectedRow={selectedRow} onClose={() => setShowModal(false)} numberOfInstallments={numberOfInstallments} observesWeekends={observesWeekends} durationOfPendingHours={durationOfPendingHours} />
                                :
                                <span> 'There are no payment options.</span>
                        }
                        </Form.Group>
                </Modal.Body>
            </Modal>
        </div>
    )
}

const mapStateToProps = (state: IAppState) => {
    return {
        isFetching: state.account.isFetching,
        itemDetails: state.account.itemDetails,
        numberOfInstallments : state.paymentChannelWebApplication.webApplication?.settings?.itemNumberOfInstallments
    };
};

export default connect(mapStateToProps)(NericSearchResults);