import {
    GET_WEB_APPLICATION_REQUEST,
    GET_WEB_APPLICATION_SUCCESS,
    GET_WEB_APPLICATION_FAILURE,
    GET_PAYMENT_CHANNEL_REQUEST,
    GET_PAYMENT_CHANNEL_SUCCESS,
    GET_PAYMENT_CHANNEL_FAILURE,
    SAVE_WEB_APPLICATION_REQUEST,
    SAVE_WEB_APPLICATION_SUCCESS,
    SAVE_WEB_APPLICATION_FAILURE,
    GET_WEB_PAGE_REQUEST,
    GET_WEB_PAGE_SUCCESS,
    GET_WEB_PAGE_FAILURE,

} from '../actions/paymentChannelWebApplications';

export default function paymentChannelWebApplication(state = {
    isFetching: false,
    isSaving: false,
    webApplication: null,
    paymentChannel: null,
    webPage: null,
    actionResult: null
}, { type, payload }) {
    switch (type) {
        case GET_WEB_APPLICATION_REQUEST:
            return Object.assign({}, state, {
                isFetching: true,
                webApplication: null,
                actionResult: { type: GET_WEB_APPLICATION_REQUEST, result: null, token: payload.actionToken } ,
            });
        case GET_WEB_APPLICATION_SUCCESS:
            return Object.assign({}, state, {
                isFetching: false,
                webApplication: payload.webApplication,
                actionResult: { type: GET_WEB_APPLICATION_REQUEST, result: GET_WEB_APPLICATION_SUCCESS, token: payload.actionToken } ,
            });
        case GET_WEB_APPLICATION_FAILURE:
            return Object.assign({}, state, {
                isFetching: false,
                actionResult: { type: GET_WEB_APPLICATION_REQUEST, result: GET_WEB_APPLICATION_FAILURE, token: payload.actionToken } ,
                errorMessage: payload.error
            });
        case GET_PAYMENT_CHANNEL_REQUEST:
            return Object.assign({}, state, {
                isFetching: true,
                actionResult: { type: GET_PAYMENT_CHANNEL_REQUEST, result: null, token: payload.actionToken } ,
            });
        case GET_PAYMENT_CHANNEL_SUCCESS:
            return Object.assign({}, state, {
                isFetching: false,
                paymentChannel: payload.paymentChannel,
                actionResult: { type: GET_PAYMENT_CHANNEL_REQUEST, result: GET_PAYMENT_CHANNEL_SUCCESS, token: payload.actionToken } ,
            });
        case GET_PAYMENT_CHANNEL_FAILURE:
            return Object.assign({}, state, {
                isFetching: false,
                actionResult: { type: GET_PAYMENT_CHANNEL_REQUEST, result: GET_PAYMENT_CHANNEL_FAILURE, token: payload.actionToken } ,
                errorMessage: payload.error
            });
        case GET_WEB_PAGE_REQUEST:
            return Object.assign({}, state, {
                isFetching: true,
                webPage: null,
                actionResult: { type: GET_WEB_PAGE_REQUEST, result: null, token: payload.actionToken } ,
            });
        case GET_WEB_PAGE_SUCCESS:
            return Object.assign({}, state, {
                isFetching: false,
                webPage: payload.webPage,
                actionResult: { type: GET_WEB_PAGE_REQUEST, result: GET_WEB_PAGE_SUCCESS, token: payload.actionToken } ,
            });
        case GET_WEB_PAGE_FAILURE:
            return Object.assign({}, state, {
                isFetching: false,
                actionResult: { type: GET_WEB_PAGE_REQUEST, result: GET_WEB_PAGE_FAILURE, token: payload.actionToken } ,
                errorMessage: payload.error
            });
        case SAVE_WEB_APPLICATION_REQUEST:
            return Object.assign({}, state, {
                isSaving: true,
                webApplication: null,
                actionResult: { type: SAVE_WEB_APPLICATION_REQUEST, result: null, token: payload.actionToken } ,
            });
        case SAVE_WEB_APPLICATION_SUCCESS:
            return Object.assign({}, state, {
                isSaving: false,
                webApplication: payload.webApplication,
                actionResult: { type: SAVE_WEB_APPLICATION_REQUEST, result: SAVE_WEB_APPLICATION_SUCCESS, token: payload.actionToken } ,
            });
        case SAVE_WEB_APPLICATION_FAILURE:
            return Object.assign({}, state, {
                isSaving: false,
                actionResult: { type: SAVE_WEB_APPLICATION_REQUEST, result: SAVE_WEB_APPLICATION_FAILURE, token: payload.actionToken } ,
                errorMessage: payload.error
            });
        default:
            return state;
    }
}
