import * as React from "react";
import { useState } from "react";
import { Row, Col, Form } from "react-bootstrap"
import InputMask from "react-input-mask";
import { connect, useDispatch } from "react-redux";
import RequiredIcon from '../../../../../../components/RequiredIcon';
import { StateDropdown } from "../../../../../../components/StateDropdown";
import { Order } from "../../../../../../models/Order";
import { addAccountDetailsAction } from "../../../../../../redux/actions/orderManagement";
import { IAppState } from "../../../../../../redux/storeTypes";
import TextMask  from "react-text-mask";
import { isValid } from "postcode";
import _ from "lodash";

export interface IAccountDetails {
    nameRequired: boolean,
    nameLabel: string,
    namePlaceholder: string,
    addressLine1Label: string,
    addressLine1Placeholder: string,
    addressLine2Label: string,
    addressLine2Placeholder: string,
    countryLabel: string,
    cityLabel: string,
    cityPlaceholder: string,
    stateLabel: string,
    addressRequired: boolean,
    zipCodeLabel: string,
    zipCodePlaceholder: string,
    zipCodeRequired: boolean,
    phoneNumberLabel: string,
    phoneNumberRequired: boolean,
    emailAddressLabel: string,
    emailAddressRequired: boolean,
    fullStoryMask: boolean,
    order: Order,
    isSaving: boolean,
    margin: string,
    padding: string
}

export const NAME_LABEL = 'Name';
export const NAME_PLACEHOLDER = 'Enter full name';
export const NAME_REQUIRED = false;
export const ADDRESS_LINE_1_LABEL = 'Address line 1';
export const ADDRESS_LINE_1_PLACEHOLDER = 'Enter address line 1';
export const ADDRESS_LINE_2_LABEL = 'Address line 2';
export const ADDRESS_LINE_2_PLACEHOLDER = 'Enter address line 2';
export const COUNTRY_LABEL = 'Country';
export const CITY_LABEL = 'City';
export const CITY_PLACEHOLDER = 'Enter city';
export const STATE_LABEL = 'State/Province/Territory';
export const ADDRESS_REQUIRED = false;
export const ZIP_CODE_LABEL = 'Zip code';
export const ZIP_CODE_PLACEHOLDER = 'Enter zip code';
export const ZIP_CODE_REQUIRED = false;
export const PHONE_NUMBER_LABEL = 'Phone number';
export const PHONE_NUMBER_REQUIRED = false;
export const EMAIL_ADDRESS_LABEL = 'Email address';
export const EMAIL_ADDRESS_REQUIRED = false;
export const MARGIN = '0px';
export const PADDING = '0px';
export const FULL_STORY_MASK = false;

const AccountDetails = ({ nameRequired, nameLabel, namePlaceholder, addressLine1Label, addressLine1Placeholder,
    addressLine2Label, addressLine2Placeholder, countryLabel, cityLabel, cityPlaceholder, stateLabel, addressRequired, zipCodeLabel, zipCodePlaceholder,
    zipCodeRequired, phoneNumberLabel, phoneNumberRequired, emailAddressLabel, emailAddressRequired, fullStoryMask,
    order, isSaving, margin, padding }: IAccountDetails) => {
    
    nameRequired = nameRequired || NAME_REQUIRED;
    nameLabel = nameLabel || NAME_LABEL;
    namePlaceholder = namePlaceholder || NAME_PLACEHOLDER
    addressLine1Label = addressLine1Label || ADDRESS_LINE_1_LABEL;
    addressLine1Placeholder = addressLine1Placeholder || ADDRESS_LINE_1_PLACEHOLDER;
    addressLine2Label = addressLine2Label || ADDRESS_LINE_2_LABEL;
    addressLine2Placeholder = addressLine2Placeholder || ADDRESS_LINE_2_PLACEHOLDER;
    countryLabel = countryLabel || COUNTRY_LABEL;
    cityLabel = cityLabel || CITY_LABEL;
    cityPlaceholder = cityPlaceholder || CITY_PLACEHOLDER;
    stateLabel = stateLabel || STATE_LABEL;
    addressRequired = addressRequired || ADDRESS_REQUIRED;
    zipCodeLabel = zipCodeLabel || ZIP_CODE_LABEL;
    zipCodePlaceholder = zipCodePlaceholder || ZIP_CODE_PLACEHOLDER;
    zipCodeRequired = zipCodeRequired || ZIP_CODE_REQUIRED;
    phoneNumberLabel = phoneNumberLabel || PHONE_NUMBER_LABEL;
    phoneNumberRequired = phoneNumberRequired || PHONE_NUMBER_REQUIRED;
    emailAddressLabel = emailAddressLabel || EMAIL_ADDRESS_LABEL;
    emailAddressRequired = emailAddressRequired || EMAIL_ADDRESS_REQUIRED;
    fullStoryMask = fullStoryMask || FULL_STORY_MASK;

    const [accountDetails, setAccountDetails] = useState(
        {
            accountCustomerName: "", accountAddressLine1: "", accountAddressLine2: "",
            accountCountry: "United States", accountCity: "", accountState: "", accountZipCode: "",
            accountPhoneNumber: "", accountEmailAddress: ""
        });

    const dispatch = useDispatch();
    const [ukZipCode, setUkZipCode] = useState<string>('');
    const [ukZipCodeInvalid, isUkZipCodeInvalid] = useState<boolean>(false);


    const handleChange = (e: any) => {
        setAccountDetails({ ...accountDetails, [e.target.name]: e.target.value });
    }

    const updateAccountDetails = () => {
        dispatch(addAccountDetailsAction(accountDetails))
    }

    const checkZipCode = (e: any) => {
        const stringValue = e.target.value;
        setUkZipCode(stringValue)
        const zipCheck = !isValid(stringValue);
        isUkZipCodeInvalid(zipCheck);
    }


    return (
        <div style={{ margin: margin, padding: padding }} className={fullStoryMask ? "fs-mask" : ""}>
            <div onBlur={updateAccountDetails}>
                <Row>
                    <Col md={6} sm={12}>
                        <Form.Group>
                            <Form.Label>{(nameRequired) ? <RequiredIcon /> : ''}{nameLabel}</Form.Label>
                            <Form.Control maxLength={50} type="input" id={`cmd_${nameLabel}`} name="accountCustomerName" placeholder={namePlaceholder} required={nameRequired} defaultValue={order?.nameOnCard} onChange={handleChange} />
                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Col md={6} sm={12}>
                        <Form.Group>
                            <Form.Label>{(addressRequired) ? <RequiredIcon /> : ''}{addressLine1Label}</Form.Label>
                            <Form.Control maxLength={40} type="input" id={`cmd_${addressLine1Label}`} name="accountAddressLine1" placeholder={addressLine1Placeholder} required={addressRequired} onChange={handleChange} />
                        </Form.Group>
                    </Col>
                    <Col md={6} sm={12}>
                        <Form.Group>
                            <Form.Label>{addressLine2Label}</Form.Label>
                            <Form.Control maxLength={40} type="input" id={`cmd_${addressLine2Label}`} name="accountAddressLine2" placeholder={addressLine2Placeholder} onChange={handleChange} />
                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Col md={6} sm={12}>
                        <Form.Group>
                            <Form.Label>{(addressRequired) ? <RequiredIcon /> : ''}{countryLabel}</Form.Label>
                            <Form.Control as="select" id={`cmd_${countryLabel}`} name="accountCountry" required={addressRequired} onChange={handleChange}>
                                <option>United States</option>
                                <option>Mexico</option>
                                <option>Canada</option>
                                <option>United Kingdom</option>
                            </Form.Control>
                        </Form.Group>
                    </Col>
                    <Col md={6} sm={12}>
                        <Form.Group>
                            <Form.Label>{(addressRequired) ? <RequiredIcon /> : ''}{cityLabel}</Form.Label>
                            <Form.Control maxLength={28} type="input" id={`cmd_${cityLabel}`} name="accountCity" placeholder={cityPlaceholder} required={addressRequired} onChange={handleChange} />
                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    {accountDetails?.accountCountry !== 'United Kingdom' &&
                        <Col md={4} sm={12}>
                            <Form.Group>
                                <Form.Label>{(addressRequired) ? <RequiredIcon /> : ''}{stateLabel}</Form.Label>
                                <StateDropdown country={accountDetails?.accountCountry} required={addressRequired} id={`cmd_${stateLabel}`} name="accountState" onChange={handleChange}></StateDropdown>
                            </Form.Group>
                        </Col>
                    }
                    <Col md={accountDetails?.accountCountry === 'United Kingdom' ? 4 : 2} sm={12}>
                        <Form.Group>
                            <Form.Label>{(zipCodeRequired) ? <RequiredIcon /> : ''}{zipCodeLabel}</Form.Label>
                            {accountDetails?.accountCountry === 'Canada' ?
                                <TextMask
                                    name="accountZipCode"
                                    id={`cmd_${zipCodeLabel}`}
                                    required={zipCodeRequired}
                                    type="text"
                                    pattern="^[A-Za-z][0-9][A-Za-z][ ]?[0-9][A-Za-z][0-9]$"
                                    mask={[/[A-Za-z]/, /\d/, /[A-Za-z]/, ' ', /\d/, /[A-Za-z]/, /\d/]}
                                    onChange={handleChange}
                                    value={accountDetails.accountZipCode}
                                    className="form-control"
                                    placeholder={zipCodePlaceholder}
                                    disabled={isSaving}
                                />
                                :
                                accountDetails?.accountCountry === 'United Kingdom' ?
                                    <Form.Control type="input" className="form-control" required={zipCodeRequired} value={ukZipCode} onChange={checkZipCode} placeholder="AA9A 9AA" maxLength={8} disabled={isSaving} />
                                    :
                                    <InputMask id={`cmd_${zipCodeLabel}`} name="accountZipCode" type="input" disabled={isSaving} pattern="[0-9]{5}" mask="99999" className="form-control" placeholder={zipCodePlaceholder} required={zipCodeRequired} onChange={handleChange} value={accountDetails.accountZipCode} />
                            }
                        </Form.Group>
                        {ukZipCodeInvalid && ukZipCode != '' && accountDetails?.accountCountry === 'United Kingdom' &&
                            <Form.Group controlId="zipCodeMask">
                                <Form.Control type="input" isInvalid={ukZipCodeInvalid && ukZipCode != ''} style={{ display: 'none' }} required={ukZipCodeInvalid && ukZipCode != ''} />
                                <Form.Control.Feedback type="invalid"> Please enter a valid zip code.</Form.Control.Feedback>
                            </Form.Group>
                        }
                    </Col>
                </Row>
                <Row>
                    <Col md={6} sm={12}>
                        <Form.Group>
                            <Form.Label>{(phoneNumberRequired) ? <RequiredIcon /> : ''}{phoneNumberLabel}</Form.Label>
                            <InputMask type="input" name="accountPhoneNumber" id={`cmd_${phoneNumberLabel}`} pattern="[0-9\-\(\)\s]{14}" className="form-control" mask="(999) 999-9999" placeholder="(999) 999-9999" required={phoneNumberRequired} onChange={handleChange} />
                        </Form.Group>
                    </Col>
                    <Col md={6} sm={12}>
                        <Form.Group>
                            <Form.Label>{(emailAddressRequired) ? <RequiredIcon /> : ''}{emailAddressLabel}</Form.Label>
                            <Form.Control type="email" placeholder="email@address.com" id={`cmd_${emailAddressLabel}`} maxLength={100} name="accountEmailAddress" required={emailAddressRequired} onChange={handleChange} />
                            <Form.Control.Feedback type="invalid">{emailAddressRequired}</Form.Control.Feedback>
                        </Form.Group>
                    </Col>
                </Row>
            </div>
        </div>
    )
}

const mapStateToProps = (state: IAppState) => {
    return {
        order: state.orderManagement.order,
        isSaving: state.orderManagement.isSaving
    };
};

export default connect(mapStateToProps)(AccountDetails);