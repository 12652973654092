import { Row, Col, Form, Button, Spinner } from "react-bootstrap"
import { useEffect, useRef, useState } from "react";
import RequiredIcon from "../../../../../../components/RequiredIcon";
import { connect, useDispatch } from "react-redux";
import { getMultiItems, getStCharlesItems, getStCharlesItemsByAddress, resetItems } from "../../../../../../redux/actions/account";
import { Field } from "../MultiSearchPlugin";
import { IAppState } from "../../../../../../redux/storeTypes";
import Autocomplete from 'react-autocomplete';
import store from "../../../../../../redux/store";
import { PaymentChannel } from "../../../../../../models/Client";
import _ from "lodash";
import { clearItemSearch, setItemSearch } from "../../../../../../redux/actions/orderManagement";

interface IAccountMultiSearch {
    taxTypeLabel: string,
    hideTaxType: boolean,
    taxTypeKey: string,
    taxTypeDynamicOptions: Field[],
    selectTaxTypeOptionLabel: string,
    searchByLabel: string,
    hideSearchBy: boolean,
    searchByDynamicOptions: Field[],
    selectSearchByOptionLabel: string,
    referenceNumberLabel: string,
    referenceNumberPlaceholder: string,
    searchLabel: string,
    useCustomAPI: boolean,
    useTypeAhead: boolean,
    resetLabel: string,
    margin: string,
    padding: string,
    resetButtonBackgroundColor: string,
    resetButtonFontColor: string,
    resetButtonFontSize: string,
    resetButtonFontStyle: string,
    resetButtonFontWeight: string,
    resetButtonBorderColor: string,
    submitButtonBackgroundColor: string,
    submitButtonFontColor: string,
    submitButtonFontSize: string,
    submitButtonFontStyle: string,
    submitButtonFontWeight: string,
    isFetching?: boolean,
}

export const TAX_TYPE_LABEL = 'Tax type';
export const HIDE_TAX_TYPE = false;
export const TAX_TYPE_KEY = 'ItemType';
export const SELECT_TAX_TYPE_OPTION_LABEL = 'Select Tax type';
export const HIDE_SEARCH_BY = false;
export const USE_CUSTOM_API = false;
export const USE_TYPE_AHEAD = false;
export const SEARCH_BY_LABEL = 'Search by';
export const SELECT_SEARCH_BY_OPTION_LABEL = 'Select Search by';
export const REFERENCE_NUMBER_LABEL = 'Reference number';
export const REFERENCE_NUMBER_PLACEHOLDER = 'Enter reference number';
export const RESET_LABEL = 'Reset';
export const SEARCH_LABEL = 'Search';
export const MARGIN = '0px';
export const PADDING = '0px';
export const RESET_BUTTON_FONT_COLOR = '#414141';
export const RESET_BUTTON_FONT_SIZE = '.875rem';
export const RESET_BUTTON_FONT_STYLE = 'normal';
export const RESET_BUTTON_FONT_WEIGHT = 'normal';
export const RESET_BUTTON_BORDER_COLOR = '#D9D9D9';
export const RESET_BUTTON_BACKGROUND_COLOR = '#FFFFFF';
export const SUBMIT_BUTTON_FONT_COLOR = '#FFFFFF';
export const SUBMIT_BUTTON_FONT_SIZE = '.875rem';
export const SUBMIT_BUTTON_FONT_STYLE = 'normal';
export const SUBMIT_BUTTON_FONT_WEIGHT = 'normal';
export const SUBMIT_BUTTON_BACKGROUND_COLOR = '#0057b6';

const checkStyleForDefault = (style: string, defaultStyle: string) => {
    return style && style !== '' ? style : defaultStyle;
}

const checkStyleForDefault3 = (defaultStyle: string) => {
    return defaultStyle as any;
}

const MultiSearch = ({ taxTypeLabel, hideTaxType, taxTypeKey, taxTypeDynamicOptions, selectTaxTypeOptionLabel, searchByLabel, useCustomAPI, useTypeAhead, hideSearchBy, searchByDynamicOptions, selectSearchByOptionLabel, referenceNumberLabel, referenceNumberPlaceholder, searchLabel, resetLabel,
    margin, padding, resetButtonBackgroundColor, resetButtonFontColor, resetButtonFontSize, resetButtonFontStyle, resetButtonFontWeight, resetButtonBorderColor,
    submitButtonBackgroundColor, submitButtonFontColor, submitButtonFontSize, submitButtonFontStyle, submitButtonFontWeight, isFetching }: IAccountMultiSearch) => {

    type Item = {
        key: any;
        value: string;
    }

    const [taxType, setTaxType] = useState<string>('');
    const [accountSearchBy, setAccountSearchBy] = useState<string>('');
    const [referenceNumber, setReferenceNumber] = useState<string>('');
    const [dynamicField, setDynamicField] = useState<string>('');
    const actionToken = 'MultiSearch';
    const [value, setValue] = useState('') as any;
    const [showDropdown, setShowDropdown] = useState(false);
    const [items, setItems] = useState<Item[]>([]);
    const [loading, setLoading] = useState(false);
    const [selectedItem, setSelectedItem] = useState(null);
    const [controller, setController] = useState(new AbortController());
    const [deepLink, setDeepLink] = useState<any>();
    const dispatch = useDispatch();

    if (!taxTypeLabel) { taxTypeLabel = TAX_TYPE_LABEL }
    if (!hideTaxType) { hideTaxType = HIDE_TAX_TYPE }
    if (!taxTypeKey) { taxTypeKey = TAX_TYPE_KEY }
    if (!selectTaxTypeOptionLabel) { selectTaxTypeOptionLabel = SELECT_TAX_TYPE_OPTION_LABEL }
    if (!searchByLabel) { searchByLabel = SEARCH_BY_LABEL }
    if (!hideSearchBy) { hideSearchBy = HIDE_SEARCH_BY }
    if (!useCustomAPI) { useCustomAPI = USE_CUSTOM_API }
    if (!useTypeAhead) { useTypeAhead = USE_TYPE_AHEAD }
    if (!selectSearchByOptionLabel) { selectSearchByOptionLabel = SELECT_SEARCH_BY_OPTION_LABEL }
    if (!referenceNumberLabel) { referenceNumberLabel = REFERENCE_NUMBER_LABEL }
    if (!referenceNumberPlaceholder) { referenceNumberPlaceholder = REFERENCE_NUMBER_PLACEHOLDER }
    if (!resetButtonBackgroundColor) { resetButtonBackgroundColor = RESET_BUTTON_BACKGROUND_COLOR }
    if (!resetButtonFontColor) { resetButtonFontColor = RESET_BUTTON_FONT_COLOR }
    if (!resetButtonFontSize) { resetButtonFontSize = RESET_BUTTON_FONT_SIZE }
    if (!resetButtonFontStyle) { resetButtonFontStyle = RESET_BUTTON_FONT_STYLE }
    if (!resetButtonFontWeight) { resetButtonFontWeight = RESET_BUTTON_FONT_WEIGHT }
    if (!resetButtonBorderColor) { resetButtonBorderColor = RESET_BUTTON_BORDER_COLOR }
    if (!submitButtonBackgroundColor) { submitButtonBackgroundColor = SUBMIT_BUTTON_BACKGROUND_COLOR }
    if (!submitButtonFontColor) { submitButtonFontColor = SUBMIT_BUTTON_FONT_COLOR }
    if (!submitButtonFontSize) { submitButtonFontSize = SUBMIT_BUTTON_FONT_SIZE }
    if (!submitButtonFontStyle) { submitButtonFontStyle = SUBMIT_BUTTON_FONT_STYLE }
    if (!submitButtonFontWeight) { submitButtonFontWeight = SUBMIT_BUTTON_FONT_WEIGHT }
    if (!searchLabel) { searchLabel = SEARCH_LABEL }
    if (!resetLabel) { resetLabel = RESET_LABEL }

    const handleTaxTypeChange = (e: any) => {
        setTaxType(e.target.value);
        setAccountSearchBy('');
        setReferenceNumber('');
        setSelectedItem(null);
        setItems([]);
        setValue('');
        setLoading(false);
    }

    const handleAccountSearchByChange = (e: any) => {
        const selectedIndex = e.target.options.selectedIndex;
        setDynamicField(e.target.options[selectedIndex].getAttribute('data-key'));
        setAccountSearchBy(e.target.value);
        setReferenceNumber('');
        setSelectedItem(null);
        setItems([]);
        setShowDropdown(false);
        setValue('');
        setLoading(false);
    }

    const handleItemSearch = (event: any) => {
        dispatch(setItemSearch({ taxTypeKey: taxTypeKey, taxType: taxType, searchBy: accountSearchBy, referenceNumber: referenceNumber }, 'ItemSearch'))
        if (!useCustomAPI) {
            dispatch(getMultiItems(taxType, accountSearchBy, encodeURIComponent(referenceNumber), actionToken,taxTypeKey));
        } else { dispatch(getStCharlesItems(taxType, accountSearchBy, encodeURIComponent(referenceNumber), actionToken)); }
    }

    const doResetSearch = () => {
        dispatch(clearItemSearch());
        dispatch(resetItems());
        setTaxType('');
        setAccountSearchBy('');
        setReferenceNumber('');
        setSelectedItem(null);
        setItems([]);
        setValue('');
        setLoading(false)
    };

    const queryParameters = (url: any) => {
        const queryString = url.split('?')[1];
        const queryParamsArray = queryString ? queryString.split('&') : [];
        return  _.chain(queryParamsArray)
            .map(param => param.split('='))
            .fromPairs()
            .value();
    } 

    useEffect(() => {
        const getDeepLink = sessionStorage.getItem('deepLink');
        if (getDeepLink) {
            setDeepLink(getDeepLink);
            const deepLinkUrl = queryParameters(getDeepLink);
            const resultObject = _.chain(searchByDynamicOptions)
                .filter(prop => deepLinkUrl[prop?.key])
                .map(prop => [prop.key, deepLinkUrl[prop?.key]])
                .fromPairs()
                .value();
            const getHeading = _.find(searchByDynamicOptions,prop => deepLinkUrl[prop?.key]) as any;
            setAccountSearchBy(Object.keys(resultObject)[0]);
            setDynamicField(getHeading?.value);
            setReferenceNumber(Object.values(resultObject)[0]);
            dispatch(setItemSearch({ taxTypeKey: taxTypeKey, taxType: taxType, searchBy: Object.keys(resultObject)[0], referenceNumber: Object.values(resultObject)[0] }, 'ItemSearch'))
            dispatch(getMultiItems('', '', '', 'deepLink','', getDeepLink));
            sessionStorage.removeItem('deepLink')
        }
    }, [deepLink])


    const fetchItems = async (inputValue: any) => {
        setShowDropdown(false);
        if (inputValue?.length > 3) {
            controller.abort();
            const newController = new AbortController();
            setController(newController)
            try {
                setLoading(true);
                let baseApiUrl = store.getState().webAppSettings.baseApiUrl;
                let paymentChannel = store.getState().paymentChannelWebApplication.paymentChannel! as PaymentChannel;

                let clientId = paymentChannel.department.client.msbId!;
                let departmentId = paymentChannel.department.msbId!;
                let paymentChannelId = paymentChannel.msbId!;

                let bearerToken = store.getState().webAppSettings.bearerToken;
                const headers = new Headers();
                headers.append('Authorization', `Bearer ${bearerToken}`);
                headers.append('ClientId', `${clientId}`);
                headers.append('DepartmentId', `${departmentId}`);
                headers.append('PaymentChannelId', `${paymentChannelId}`);

                const addressType = accountSearchBy == 'Address1' ? 'ownerAddressQuickSearch' : accountSearchBy == 'PropertyAddress' ? 'propertyAddressQuickSearch' : ''
                const response = await fetch(`${baseApiUrl}/MSB_Item/api/v1/SaintCharlesLookup/${addressType}?address=${encodeURIComponent(inputValue)}&recordCount=10000`, {
                    signal: newController.signal,
                    headers: headers
                });
                const data = await response.json();
                setLoading(false);
                const itemsArray = Object.keys(data).map(key => ({
                    key: key,
                    value: data[key]
                }))
                setItems(itemsArray);
                setShowDropdown(true);
            } catch (error) {
                setLoading(false);
                console.log('Error', error)
            }
        }
    }

    const fetchDataSet = async (key: any) => {
        dispatch(getStCharlesItemsByAddress(key,accountSearchBy,'Address'));
    }

    const handleInputChange = async (e: any) => {
        const inputValue = e.target.value.toLowerCase();
        setValue(inputValue);
        await fetchItems(inputValue)
    }

    const modifyDynamicOptionsBasedOnTaxType = ['BL', 'PP'].includes(taxType);
    const filteredDynamicOptions = searchByDynamicOptions?.filter((options: any) => {
        if (modifyDynamicOptionsBasedOnTaxType) {
            return options.key === 'AccountNumber'
        } else { return true }
    })
    const modifiedDynamicOptions = modifyDynamicOptionsBasedOnTaxType ? filteredDynamicOptions : searchByDynamicOptions;

    return (

        <div style={{ margin: margin, padding: padding }}>
            <Row>
                {!hideTaxType &&
                    <Col>
                        <Form.Group controlId="taxType">
                            <Form.Label><RequiredIcon />{taxTypeLabel}</Form.Label>
                            <Form.Control as='select' value={taxType} onChange={handleTaxTypeChange}>
                                <option value=''>{selectTaxTypeOptionLabel}</option>
                                {
                                    taxTypeDynamicOptions && taxTypeDynamicOptions.map((field, index) => <option key={field.key} value={field.key}>{field.value}</option>)
                                }
                            </Form.Control>
                        </Form.Group>
                    </Col>
                }
                {!hideSearchBy &&
                    <Col>
                        <Form.Group controlId="searchBy">
                            <Form.Label><RequiredIcon />{searchByLabel}</Form.Label>
                            <Form.Control as='select' value={accountSearchBy} onChange={handleAccountSearchByChange} disabled={taxType === '' && !hideTaxType}>
                                <option value=''>{SELECT_SEARCH_BY_OPTION_LABEL}</option>
                                {
                                    searchByDynamicOptions && modifiedDynamicOptions.map((field, index) => <option key={field.key} data-key={field.value} value={field.key}>{field.value}</option>)
                                }
                            </Form.Control>
                        </Form.Group>
                    </Col>
                }
                {accountSearchBy &&
                    <>
                        {(useTypeAhead) && (accountSearchBy == 'Address1' || accountSearchBy == 'PropertyAddress') ?
                            <Col>
                                <Form.Group controlId="referenceNumber">
                                    <Form.Label><RequiredIcon />{dynamicField}</Form.Label>
                                    <div style={{display: 'grid'}}>
                                    <Autocomplete
                                        getItemValue={(item:any) => item.value}
                                        items={items}
                                        value={value}
                                        renderItem={(item: any, isHighlighted: any) =>
                                            <div style={{ background: isHighlighted ? '#337ab7' : 'white', color: isHighlighted? 'white' : 'black' }}>
                                                <div style={{ marginLeft: '20px', marginBottom: '8px',  marginRight: '20px'}}>{item?.value} </div>
                                            </div>
                                        }
                                        open={showDropdown}
                                        onChange={handleInputChange}
                                        onSelect={async (value: any) => {
                                            setValue(value);
                                            setShowDropdown(false);
                                            await fetchDataSet(value);
                                        }}
                                        inputProps={{ className: 'form-control', type: 'input', placeholder:`Enter ${dynamicField}`}}
                                        renderMenu={(items,value,style) => (
                                            <div style={{ ...style, top: undefined, left:undefined, position: 'absolute', zIndex: 1000, maxHeight:'240px', float: 'left', minWidth: '160px', padding: '5px 0', margin: '2px 0 0', overflowY:'auto', textAlign: 'left', listStyle: 'none', backgroundColor: '#fff', backgroundClip: 'padding-box', border: '1px solid rgba(0,0,0,.15)',borderRadius:'4px',boxShadow:'0 6px 12px rgba(0,0,0,.175)', cursor: 'pointer'}}>
                                                {items?.length!= 0 ? items : <span style={{ marginLeft: '20px', color:'grey'}}>No address Found</span>} 
                                            </div>
                                        )}
                                        
                                    />
                                    {loading && <div style={{ textAlign: 'center' }} ><Spinner animation="border" /> </div>}
                                    </div>
                                </Form.Group>
                            </Col>
                            :
                            <Col>
                                <Form.Group controlId="referenceNumber">
                                    <Form.Label><RequiredIcon />{dynamicField}</Form.Label>
                                    <Form.Control type="input" maxLength={_.find(searchByDynamicOptions, options => dynamicField === options.value)?.maxLength} placeholder={`Enter ${dynamicField}`}  value={referenceNumber} onChange={(e) => setReferenceNumber(e.target.value)} disabled={accountSearchBy === '' && !hideSearchBy} />
                                </Form.Group>
                            </Col>
                        }
                    </>
                }
                <Col>
                    <Button className="brand-btn-primary" style={{
                        marginTop: '29px',
                        backgroundColor: checkStyleForDefault(resetButtonBackgroundColor, RESET_BUTTON_BACKGROUND_COLOR),
                        borderColor: checkStyleForDefault(resetButtonBorderColor, RESET_BUTTON_BORDER_COLOR),
                        color: checkStyleForDefault(resetButtonFontColor, RESET_BUTTON_FONT_COLOR),
                        fontSize: checkStyleForDefault(resetButtonFontSize, RESET_BUTTON_FONT_SIZE),
                        fontStyle: checkStyleForDefault(resetButtonFontStyle, RESET_BUTTON_FONT_STYLE),
                        fontWeight: checkStyleForDefault3(resetButtonFontWeight)
                    }} onClick={doResetSearch} disabled={isFetching}>
                        {resetLabel}
                    </Button>
                    {!((useTypeAhead) && (accountSearchBy === 'Address1' || accountSearchBy === 'PropertyAddress')) &&
                        <Button className="brand-btn-primary" style={{
                            marginTop: '29px',
                            marginLeft: '8px',
                            backgroundColor: checkStyleForDefault(submitButtonBackgroundColor, SUBMIT_BUTTON_BACKGROUND_COLOR),
                            borderColor: checkStyleForDefault(submitButtonBackgroundColor, SUBMIT_BUTTON_BACKGROUND_COLOR),
                            color: checkStyleForDefault(submitButtonFontColor, SUBMIT_BUTTON_FONT_COLOR),
                            fontSize: checkStyleForDefault(submitButtonFontSize, SUBMIT_BUTTON_FONT_SIZE),
                            fontStyle: checkStyleForDefault(submitButtonFontStyle, SUBMIT_BUTTON_FONT_STYLE),
                            fontWeight: checkStyleForDefault3(submitButtonFontWeight)
                        }} disabled={referenceNumber?.length === 0 || isFetching} onClick={handleItemSearch}>
                            {searchLabel}
                        </Button>
                    }
                </Col>
            </Row>
        </div>

    )
}


const mapStateToProps = (state: IAppState) => {
    return {
        isFetching: state.account.isFetching,
    };
};

export default connect(mapStateToProps)(MultiSearch);
