import { CellPlugin } from '@react-page/editor';
import ForsythShoppingCart, { TITLE, SUBTITLE, ACCOUNT_NUMBER_LABEL, BILL_NUMBER_LABEL, BILL_YEAR_LABEL, 
    OWNER_LABEL, SITUS_ADDRESS_LABEL, DUE_DATE_LABEL, TOTAL_DUE_LABEL, AMOUNT_LABEL,
    ACTIONS_LABEL, HEADER_BACKGROUND_COLOR, HEADER_PADDING, MARGIN, PADDING,  
    CART_BUTTON_BACKGROUND_COLOR, CART_BUTTON_FONT_COLOR, CART_BUTTON_FONT_SIZE, 
    CART_BUTTON_FONT_STYLE, CART_BUTTON_FONT_WEIGHT, CART_BUTTON_BORDER_COLOR, CART_ICON_COLOR
} from './components/ForsythShoppingCart';

type Data = {
    title: string,
    subTitle: string,
    accountNumber: string,
    billNumber: string,
    billYear: string,
    owner: string,
    situsAddress: string,
    dueDate: string,
    totalDue: string,
    amount: string,
    actions: string,
    headerBackgroundColor: string,
    headerPadding: string,
    margin: string,
    padding: string,
    cartButtonBackgroundColor: string,
    cartButtonFontColor: string,
    cartButtonFontSize: string,
    cartButtonFontStyle: string,
    cartButtonFontWeight: string,
    cartButtonBorderColor: string,
    cartIconColor: string
}

const ForsythShoppingCartPlugin: CellPlugin<Data> = {
    Renderer: ({ data }) => (
        <ForsythShoppingCart
            title = {data.title}
            subTitle={data.subTitle}
            accountNumberLabel={data.accountNumber}
            billYearLabel={data.billYear}
            billNumberLabel={data.billNumber}
            ownerLabel={data.owner}
            situsAddressLabel={data.situsAddress}
            dueDateLabel={data.dueDate}
            totalDueLabel={data.totalDue}
            amountLabel={data.amount}
            actionsLabel={data.actions}
            margin={data.margin}
            padding={data.padding}
            cartButtonBackgroundColor={data.cartButtonBackgroundColor}
            cartButtonFontColor={data.cartButtonFontColor}
            cartButtonFontSize={data.cartButtonFontSize}
            cartButtonFontStyle={data.cartButtonFontStyle}
            cartButtonFontWeight={data.cartButtonFontWeight}
            cartButtonBorderColor={data.cartButtonBorderColor}
            headerBackgroundColor={data.headerBackgroundColor}
            headerPadding={data.headerPadding}
            cartIconColor={data.cartIconColor}
        />
    ),
    id: 'forsythShoppingCartPlugin',
    title: 'Forsyth Shopping Cart ',
    description: 'Adds items to shopping cart',
    version: 1,
    controls: [
        {
            title: 'Labels',
            controls: {
                type: 'autoform',
                schema: {
                    properties: {
                        title: {
                            type: 'string',
                            default: TITLE,
                        },
                        subTitle: {
                            type: 'string',
                            default: SUBTITLE,
                        },
                        accountNumber: {
                            type: 'string',
                            default: ACCOUNT_NUMBER_LABEL,
                        },
                        billYear: {
                            type: 'string',
                            default: BILL_YEAR_LABEL,
                        },
                        owner: {
                            type: 'string',
                            default: OWNER_LABEL,
                        },
                        billNumber: {
                            type: 'string',
                            default: BILL_NUMBER_LABEL,
                        },
                        situsAddress: {
                            type: 'string',
                            default: SITUS_ADDRESS_LABEL,
                        },
                        totalDue: {
                            type: 'string',
                            default: TOTAL_DUE_LABEL,
                        },
                        amount: {
                            type: 'string',
                            default: AMOUNT_LABEL,
                        },
                        dueDate: {
                            type: 'string',
                            default: DUE_DATE_LABEL,
                        },
                        actions: {
                            type: 'string',
                            default: ACTIONS_LABEL,
                        }
                    },
                    required: [],
                },
            },
        },
        {
            title: 'Styles',
            controls: {
                type: 'autoform',
                schema: {
                    properties: {
                        margin: {
                            type: 'string',
                            default: MARGIN,
                        },
                        padding: {
                            type: 'string',
                            default: PADDING,
                        },
                        headerBackgroundColor: {
                            type: 'string',
                            default: HEADER_BACKGROUND_COLOR,
                        },
                        headerPadding: {
                            type: 'string',
                            default: HEADER_PADDING,
                        },
                        cartButtonBackgroundColor: {
                            type: 'string',
                            default: CART_BUTTON_BACKGROUND_COLOR,
                        },
                        cartButtonFontColor: {
                            type: 'string',
                            default: CART_BUTTON_FONT_COLOR,
                        },
                        cartButtonFontSize: {
                            type: 'string',
                            default: CART_BUTTON_FONT_SIZE,
                        },
                        cartButtonFontStyle: {
                            type: 'string',
                            default: CART_BUTTON_FONT_STYLE,
                        },
                        cartButtonFontWeight: {
                            type: 'string',
                            default: CART_BUTTON_FONT_WEIGHT,
                        },
                        cartButtonBorderColor: {
                            type: 'string',
                            default: CART_BUTTON_BORDER_COLOR,
                        },
                        cartIconColor: {
                            type: 'string',
                            default: CART_ICON_COLOR
                        }
                        
                    },
                    required: [],
                },
            },
        },
    ],
};

export default ForsythShoppingCartPlugin;