import React from 'react';
import { Link } from 'react-router-dom';
import { Routes } from '../routes';
import logo from '../assets/img/logo.svg';
import screen from '../assets/img/screen.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/pro-light-svg-icons';

const Landingpage = () => {

    const setRoute = (path:string) => {
        return path.replace(":id", "Demo");
    }

    return (
        <>
            <div className="container">
                <header className="d-flex flex-wrap align-items-center justify-content-center justify-content-md-between py-3">
                    <a href="/" className="d-flex align-items-center col-md-3 mb-2 mb-md-0 text-dark text-decoration-none">
                        <img src={logo} alt="MSB Logo" />
                    </a>
                    <ul className="nav col-12 col-md-auto mb-2 justify-content-center mb-md-0">
                        <li className="nav-item">
                            <Link to={setRoute(Routes.Product.path)} className="nav-link px-3">Product</Link>
                        </li>
                        {/* <li className="nav-item">
                            <Link to={setRoute(Routes.PAboutroduct.path)} className="nav-link px-3">About</Link>
                        </li> */}
                        <li className="nav-item">
                            <Link to={setRoute(Routes.Contact.path)} className="nav-link px-3">Contact</Link>
                        </li>
                        {/* <li className="nav-item">
                            <Link to={setRoute(Routes.Status.path)} className="nav-link px-3">Status</Link>
                        </li> */}
                    </ul>
                </header>
            </div>
            <section>
                <div className="gradient-1">
                    <div className="container">
                        <div className="row pt-5 my-5 text-center">
                            <h1 className="display-1 fw-bold px-4"><span className="primary-purple">Transformative</span> payment processing
                  </h1>
                            <div className="col-lg-6 mx-auto">
                                <p className="lead mt-3 mb-5">Maximize your cash flow and streamline the payment process for your constituents.
                    </p>
                                <div className="d-grid gap-2 d-sm-flex justify-content-sm-center mb-5">
                                    <Link to={setRoute(Routes.Product.path)} className="btn btn-outline-primary" style={{ width: "160px" }}>
                                        Learn more
                                    </Link>
                                </div>
                            </div>
                            <div className="overflow-hidden mt-5">
                                <div className="container px-0 pt-5">
                                    <img src={screen} className="img-fluid rounded-3 shadow-lg" alt="MSB Payment System" width={1100} height={502} loading="lazy" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section>
                <div className="container">
                    <div className="row pt-5 my-5 text-center">
                        <h2 className="fw-bold primary-purple display-4">Municipal Services Bureau</h2>
                        <h1 className="display-3 fw-bold mb-4">We accelerate your cash flow</h1>
                        <div className="col-lg-8 mx-auto">
                            <p className="lead mb-4">Municipal Services Bureau (MSB) offers outsourced third-party debt collection and call
                            center services to government entities nationwide. MSB operates as a financial services company for
                    municipalities in billing, notification and payment processing of delinquent municipal fees and fines.</p>
                        </div>
                    </div>
                </div>
            </section>
            <section>
                <div className="container">
                    <div className="row pt-5 pb-5">
                        <div className="col-lg-12">
                            <div className="cta-blue">
                                <div className="cta-text">
                                    <h3 className="display-4">Ready to get started?</h3>
                                    <p className="lead">Discover innovative, secure technologies designed to move your agency forward.</p>
                                </div>
                                <div className="cta-button">
                                   
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <footer className="gradient-2">
                <div className="container">
                    <div className="row py-5 my-5">
                        <div className="col-md-6 col-sm-12">
                            <a href="#" className="me-3">Privacy Policy</a>
                            <a href="#">Terms of Use</a>
                        </div>
                        <div className="col-md-3 col-sm-12">
                            <h5 className="fw-bold">Address</h5>
                            <p className="dark-gray">Municipal Services Bureau<br />
                                325 Daniel Zenker Drive<br />
                                Suite 3<br />
                                Horseheads, NY 14845
                            </p>
                        </div>
                        <div className="col-md-3 col-sm-12">
                            <h5 className="fw-bold">Contact</h5>
                            <p className="dark-gray">
                                Monday &ndash; Friday<br />
                                8:00 am EST &ndash; 12:00 am EST<br />
                                Phone: 1-800-616-0166<br />
                                Fax: 1-800-756-4110
                            </p>
                        </div>
                    </div>
                </div>
            </footer>
            <footer className="footer">
                <div className="container">
                    <div className="row pt-5 mb-5">
                        <div className="col-12">
                            <p className="gray-7">© 2021 Gila, LLC d/b/a Municipal Services Bureau (MSB) is a registered ISO of Fifth Third
                            Bank, N.A.
                            Cincinnati, OH. All rights reserved. The MSB logo is a registered service mark of Gila, LLC. Gila, LLC is a
                            subsidiary of Navient Corporation. Navient Corporation and its subsidiaries, including Gila, LLC, are not
                    sponsored by or agencies of the United States of America.</p>
                        </div>
                    </div>
                </div>
            </footer>
        </>
    );
}
export default Landingpage;