import { CellPlugin } from '@react-page/editor';
import React from 'react';
import { Col, Row } from 'react-bootstrap';

// use a type here, not an interface
type Data = {
    label: string,
    value: string
}

const getValue = (value: string) => {
    if (value && value.length > 0) {
        return value;
    } else {
        return "";
    }
}

const SignaturePropertyPlugin: CellPlugin<Data> = {
    Renderer: ({ data }) => (
        <Row>
            <Col>
                <img style={{ display: 'block', maxWidth: "30%", margin: 'auto' }} src={`data:image/png;base64,${getValue(data.value)}`} />
                <div className="signature-line" style={{ borderBottom:'1px solid #414141', width:'100%', height:'24px' }}>x</div>
            </Col>
        </Row>
    ),
    id: 'signaturePropertyPlugin',
    title: 'Signature Property',
    description: 'Signature Property.',
    version: 1,
    controls: {
        type: 'autoform',
        schema: {
            properties: {
                label: {
                    type: 'string',
                    default: 'Signature',
                },
            },
            required: ['label'],
        },
    },
};

export default SignaturePropertyPlugin;