import { PaymentCardTypeEnum } from './Order'
export class User  {
    msbId?: string;
    userTypeEnum: UserTypeEnum = UserTypeEnum.Unknown;
    firstName: string = "";
    lastName: string = "";
    email: string = "";
    title: string = "";
    phoneNumber: string = "";
    preferences: any;
    userClients: Array<UserClient> = new Array<UserClient>();
    cards?: Array<UserCards> = new Array<UserCards>();
    bankAccounts?: Array<BankAccounts> = new Array<BankAccounts>();
    objectId: string = "";
}

export class UserCards {
    userId?: string;
    token?: string;
    nameOnCard?: string;
    expirationDate?: string;
    internalCardType?: string;
    vantivCardType?: string;
    lastFourOnCard?: string;
    isPrimaryCard?: boolean;
    msbId?: string;
    userTypeEnum: UserTypeEnum = UserTypeEnum.Unknown;
    addressLine1?: string = "";
    addressLine2?: string = "";
    state?: string = "";
    city?: string = "";
    zipCode?: string = ""
}

export class CreditCard {
    cardNumber: string = "";
    nameOnCard: string = "";
    expirationMonth: string = "";
    expirationYear: string = "";
    cVV: string = "";
    internalCardType: PaymentCardTypeEnum = PaymentCardTypeEnum.Unknown
    addressLine1: string = "";
    addressLine2: string = "";
    state: string = "";
    city: string = "";
    zipCode: string = ""
}
export class eCheck {
    bankAccountType: string = "";
    accountHolderName: string = "";
    accountNumber: string = "";
    routingNumber: string = "";
    addressLine1: string = "";
    addressLine2: string = "";
    state: string = "";
    city: string = "";
    zipCode: string = ""
}

export class BankAccounts {
    msbId?: string;
    userTypeEnum?: UserTypeEnum = UserTypeEnum.Unknown;
    bankAccountType?: string;
    accountHolderName?: string;
    accountNumber?: string;
    routingNumber?: string;
    isPrimaryAccount?: string;
    addressLine1?: string = "";
    addressLine2?: string = "";
    state?: string = "";
    city?: string = "";
    zipCode?: string = ""
}

export enum UserTypeEnum
{
    Unknown     = 0,
    Navient     = 1,
    Client      = 2,
    Constituent = 3
}

export class UserClient {
    clientMSBId: string;
    constructor(message: string) {
      this.clientMSBId = message;
    }
}

export class UserAuthRequest {
    email: string = "";
    objectId: string = "";
}

export class LabelValue {
    value: string = "";
    label: string = ""

    constructor(label:string, value: string) {
        this.label = label;
        this.value = value;
    }
}

export class Card {
    paymentType: string = '';
    cardType: string = '';
    cardholderName: string = '';
    cardNumber!: number;
    expDate!: string;
    cvv!: number;
    addressLine1: string = '';
    addressLine2: string = '';
    country: string = '';
    city: string = '';
    state: string = '';
    zip: string = '';
    default: boolean = false;
}