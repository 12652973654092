import { CellPlugin } from '@react-page/editor';
import * as React from 'react';
import { Form } from 'react-bootstrap';
import RequiredIcon from '../../../../../components/RequiredIcon';

type Data = {
    label: string,
    name: string,
    required: boolean,
    margin: string,
    padding: string,
}

const LABEL = 'Date';
const NAME = 'date';
const REQUIRED = false;
const MARGIN = '0px';
const PADDING = '0px';

const DatePickerPlugin: CellPlugin<Data> = {
    Renderer: ({ data }) => (
        <Form.Group controlId={`cmd_${data.name}`} style={{ margin: data.margin, padding: data.padding, alignItems: 'flex-end' }}>
            <Form.Label>{(data.required) ? <RequiredIcon /> : ''}{data.label}</Form.Label>
            <Form.Control type="date" required={data.required} />
            <Form.Control.Feedback type="invalid">Please enter a valid {data.label}.</Form.Control.Feedback>
        </Form.Group>
    ),
    id: 'DatePickerPlugin',
    title: 'Date Picker',
    description: 'Date picker.',
    version: 1,
    controls: [
        {
            title: 'Labels',
            controls: {
                type: 'autoform',
                schema: {
                    properties: {
                        label: {
                            type: 'string',
                            default: LABEL,
                        },
                        name: {
                            type: 'string',
                            default: NAME,
                        },
                        required: {
                            type: 'boolean',
                            default: REQUIRED,
                        },
                    },
                    required: [],
                },
            },
        },
        {
            title: 'Styles',
            controls: {
                type: 'autoform',
                schema: {
                    properties: {
                        margin: {
                            type: 'string',
                            default: MARGIN,
                        },
                        padding: {
                            type: 'string',
                            default: PADDING,
                        },
                    },
                    required: [],
                },
            },
        },
    ]
};

export default DatePickerPlugin;