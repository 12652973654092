import React, { useState } from "react";
import { Button, Table, Modal, Form, Row, Col } from 'react-bootstrap';
import PageSectionContainer from '../../components/layout/PageSectionContainer';
import RequiredIcon from '../../components/RequiredIcon';
import { connect } from "react-redux";
import { IAppState } from "../../redux/storeTypes";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/pro-light-svg-icons';
import FormActions from '../../components/layout/FormActions';
import FormHeader from '../../components/layout/FormHeader';
export interface IOpenOrders {
}

const mockData: OpenOrder[] = [
    {
        paymentReference: 'Invoice 1',
        price: 100,
        qty: 1
    },
    {
        paymentReference: 'Invoice 2',
        price: 120,
        qty: 7
    },
    {
        paymentReference: 'Invoice 3',
        price: 10,
        qty: 10
    }
]

class OpenOrder {
    paymentReference: string = '';
    price: number = 0;
    qty: number = 0;
}

const OpenOrders = ({ }: IOpenOrders) => {
    const [showForm, setShowForm] = useState<boolean>(false);
    const [validated, setValidated] = useState<boolean>(false);
    const [order, setOrder] = useState<OpenOrder>(new OpenOrder());
    const [orderList, setOrderList] = useState<Array<OpenOrder>>(mockData); //TODO: replace with API endpoints

    const handleSubmit = (event: any) => {
        const form = event.currentTarget;
        event.preventDefault();
        if (form.checkValidity() !== false) {
            order.paymentReference = form.elements.paymentReference.value;
            order.price = form.elements.price.value;
            order.qty = form.elements.qty.value;
            orderList.push(order)
            setOrderList(new Array<OpenOrder>().concat(orderList));
            setOrder(new OpenOrder());
            setShowForm(false);
            setValidated(false);
        }
        else {
            setValidated(true);
        }
    };
    const clearForm = () => {
        setOrder(new OpenOrder());
        setShowForm(false);
        setValidated(false);
    }
    return (
        <>
            <Modal show={showForm} size="lg" onHide={() => setShowForm(false)}>
                <Modal.Header closeButton></Modal.Header>
                <Modal.Body>

                    <FormHeader title="Add new item" description="Section description" />

                    <Form id='clientUserSubmit' noValidate validated={validated} onSubmit={handleSubmit}>
                        <Row>
                            <Form.Group as={Col} controlId="paymentReference">
                                <Form.Label><RequiredIcon />Payment Reference</Form.Label>
                                <Form.Control required type="input" placeholder="Enter payment reference" defaultValue={order?.paymentReference} />
                                <Form.Control.Feedback type="invalid">Payment Reference is required.</Form.Control.Feedback>
                            </Form.Group>
                        </Row>
                        <Row>
                            <Form.Group as={Col} controlId="price">
                                <Form.Label><RequiredIcon />Price</Form.Label>
                                <Form.Control required type="input" placeholder="price" />
                                <Form.Control.Feedback type="invalid">Price is required.</Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group as={Col} controlId="qty">
                                <Form.Label><RequiredIcon />Quantity</Form.Label>
                                <Form.Control required type="input" pattern="[0-9]" placeholder="quantity" />
                                <Form.Control.Feedback type="invalid">Quantity is required.</Form.Control.Feedback>
                            </Form.Group>
                        </Row>
                        <FormActions hasSubmit customProps={{ submitButtonDisplay: 'Add' }} onCancel={clearForm} />
                    </Form>
                </Modal.Body>
            </Modal>
            <PageSectionContainer title='Open orders' headerTag='h2' showHeaderBorder={true}>
                <p>Select which items below you would like to pay. You can add additional items as needed.</p>
                <Table responsive className='order_table'>
                    <thead >
                        <tr>
                            <th>Payment Reference</th>
                            <th>Price</th>
                            <th>Qty</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {orderList.map(item => (
                            <tr>
                                <td><input style={{margin: "5px"}} type="checkbox"></input>{item.paymentReference}</td>
                                <td>&#36;{item.price}</td>
                                <td>{item.qty}</td>
                                <td style={{ color: "#0F6B26" }}>View details</td>
                            </tr>
                        ))}
                    </tbody>
                </Table>

                <Button className="brand-btn-primary" style={{ display: 'block', margin: '1rem auto 0' }} onClick={() => setShowForm(true)}>
                    <FontAwesomeIcon icon={faPlus} size="sm" className="btn-icon" /> Add Item
            </Button>
            </PageSectionContainer>
        </>
    );
};

const mapStateToProps = (state: IAppState) => {
    return {
    };
};

export default connect(mapStateToProps)(OpenOrders);
