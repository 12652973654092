import React, { useState } from "react";
import { connect } from "react-redux";
import PageHeader from "../components/layout/PageHeader";
import { IAppState } from "../redux/storeTypes";
import { Container, Row, Col, Button, Table as ReactBsTable, Form, InputGroup, } from 'react-bootstrap';
import PageSectionContainer from "../components/layout/PageSectionContainer";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/pro-light-svg-icons';
import { faEye, faEyeSlash } from "@fortawesome/pro-regular-svg-icons";
import RequiredIcon from '../components/RequiredIcon';
import { Routes } from "../routes";
import Breadcrumb from '../components/layout/Breadcrumb';
import InputMask from "react-input-mask";
import { Link, Redirect } from "react-router-dom";
import BillingAddress from "./BillingAddress";
import ECheckHelpIcon from "../components/ECheckHelpIcon";

export interface IQuickPay {

}

const QuickPay = () => {
    const [paymentType, setPaymentType] = useState('creditCard');
    const [redirect, setRedirect] = useState('');
    const [showCardNumber, setShowCardNumber] = useState(false);
    const [showCVV, setShowCVV] = useState(false);

    const setRoute = (path: string) => {
        return path.replace(":id", "Demo");
    }

    if (redirect !== "") {
        return (<Redirect push to={redirect} />)
    } else {
        return (
            <>
                <PageHeader />

                <Container className="mt-4">
                    <Breadcrumb name='QuickPay' />
                    <Row>
                        <Col lg={8} md={8} sm={12}>
                            <PageSectionContainer title='Quick Pay' headerTag='h2' showHeaderBorder={true}>
                                <Form>
                                    <p>Please enter the order details below to proceed with your payment.</p>
                                    <Row>
                                        <Col>
                                            <Form.Group controlId="">
                                                <Form.Label>Payment Reference</Form.Label>
                                                <Form.Control type="input" placeholder="Enter payment reference" />
                                            </Form.Group>
                                        </Col>
                                        <Col>
                                            <Form.Group controlId="">
                                                <Form.Label>Amount</Form.Label>
                                                <Form.Control type="input" placeholder="0.00" />
                                            </Form.Group>
                                        </Col>
                                        <Col>
                                            <Button className="brand-btn-primary" style={{ marginTop: '29px' }}>
                                                <FontAwesomeIcon icon={faPlus} size="sm" className="btn-icon" /> Add Item
                                            </Button>
                                        </Col>
                                    </Row>
                                    <hr />
                                    <h2>Payment details</h2>
                                    <p>Please enter the payment details below to proceed with your payment.</p>
                                    <Row>
                                        <Form.Group controlId="">
                                            <Form.Label>Payment Type</Form.Label>
                                            <Form.Control as='select' required value={paymentType} onChange={e => setPaymentType(e.target.value)}>
                                                <option value='' selected>Select Payment type</option>
                                                <option value='creditCard'>Credit Card</option>
                                                <option value='eCheck'>eCheck</option>
                                            </Form.Control>
                                        </Form.Group>
                                    </Row>
                                    {paymentType === 'creditCard' &&
                                        (
                                            <>
                                                <Row>
                                                    <Col md={12}>
                                                        <Form.Group controlId="nameOnCard">
                                                            <Form.Label><RequiredIcon />Cardholder name</Form.Label>
                                                            <Form.Control type="input" required maxLength={50} placeholder="Cardholder name" />
                                                            <Form.Control.Feedback type="invalid">Please enter the Cardholder’s name.</Form.Control.Feedback>
                                                        </Form.Group>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col md={6} sm={12}>
                                                        <Form.Group controlId="cardType">
                                                            <Form.Label><RequiredIcon />Card type</Form.Label>
                                                            <Form.Control as="select" required>
                                                                <option>American Express</option>
                                                                <option>American Express - Debit</option>
                                                                <option>Discover</option>
                                                                <option>Discover - Debit</option>
                                                                <option>Master Card</option>
                                                                <option>Master Card - Debit</option>
                                                                <option>Visa</option>
                                                                <option>Visa - Debit</option>
                                                            </Form.Control>
                                                            <Form.Control.Feedback type="invalid">Please select the Card type.</Form.Control.Feedback>
                                                        </Form.Group>
                                                    </Col>
                                                    <Col md={6} sm={12}>
                                                        <Form.Group controlId="cardNumber">
                                                            <Form.Label><RequiredIcon />Card number</Form.Label>
                                                            <InputGroup hasValidation>
                                                                <Form.Control type={showCardNumber ? "text" : "password"} required minLength={12} maxLength={16} className="password" />
                                                                <InputGroup.Append>
                                                                    <InputGroup.Text onClick={() => setShowCardNumber(!showCardNumber)}>
                                                                        <FontAwesomeIcon icon={showCardNumber ? faEyeSlash : faEye} size="sm" />
                                                                    </InputGroup.Text>
                                                                </InputGroup.Append>
                                                                <Form.Control.Feedback type="invalid">Please enter a valid 12-16 digit long Card number.</Form.Control.Feedback>
                                                            </InputGroup>
                                                        </Form.Group>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col md={3} sm={6}>
                                                        <Form.Group controlId="expirationDate">
                                                            <Form.Label><RequiredIcon />Expiration date</Form.Label>
                                                            <InputMask id="expirationDate" name="expirationDate" required type="input" pattern="[0-9\/\s]{5}" mask="99/99" className="form-control" />
                                                            <Form.Control.Feedback type="invalid">Please enter the Expiration date.</Form.Control.Feedback>
                                                        </Form.Group>
                                                    </Col>
                                                    <Col md={3} sm={6}>
                                                        <Form.Group controlId="cvv">
                                                            <Form.Label><RequiredIcon />CVV</Form.Label>
                                                            <InputGroup hasValidation>
                                                                <Form.Control type={showCVV ? "text" : "password"} required minLength={3} maxLength={4} className="password" />
                                                                <InputGroup.Append>
                                                                    <InputGroup.Text onClick={() => setShowCVV(!showCVV)}>
                                                                        <FontAwesomeIcon icon={showCVV ? faEyeSlash : faEye} size="sm" />
                                                                    </InputGroup.Text>
                                                                </InputGroup.Append>
                                                                <Form.Control.Feedback type="invalid">Please enter the CVV number.</Form.Control.Feedback>
                                                            </InputGroup>
                                                        </Form.Group>
                                                    </Col>
                                                </Row>
                                            </>
                                        )}
                                    {paymentType === 'eCheck' &&
                                        (
                                            <>
                                                <Row>
                                                    <Form.Group controlId="nameAccount">
                                                        <Form.Label><RequiredIcon />Name on account</Form.Label>
                                                        <Form.Control type="input" required maxLength={100} placeholder="Enter name" />
                                                        <Form.Control.Feedback type="invalid">Please enter account holder's name.</Form.Control.Feedback>
                                                    </Form.Group>
                                                </Row>
                                                <Row>
                                                    <Col>
                                                        <Form.Group controlId="bankNumber">
                                                            <Form.Label><RequiredIcon />Bank account number<ECheckHelpIcon /></Form.Label>
                                                            <Form.Control type="input" required maxLength={32} placeholder="Enter bank account number" />
                                                            <Form.Control.Feedback type="invalid">Please enter routing number.</Form.Control.Feedback>
                                                        </Form.Group>
                                                    </Col>
                                                    <Col>
                                                        <Form.Group controlId="confirmBankNumber">
                                                            <Form.Label><RequiredIcon />Confirm bank account number</Form.Label>
                                                            <Form.Control type="input" required maxLength={32} placeholder="Confirm bank account number" />
                                                            <Form.Control.Feedback type="invalid">Please confirm routing number.</Form.Control.Feedback>
                                                        </Form.Group>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col>
                                                        <Form.Group controlId="routingNumber">
                                                            <Form.Label><RequiredIcon />Routing number<ECheckHelpIcon /></Form.Label>
                                                            <Form.Control type="input" required maxLength={32} placeholder="Enter routing number" />
                                                            <Form.Control.Feedback type="invalid">Please enter routing number.</Form.Control.Feedback>
                                                        </Form.Group>
                                                    </Col>
                                                    <Col>
                                                        <Form.Group controlId="confirmRoutingNumber">
                                                            <Form.Label><RequiredIcon />Confirm routing number</Form.Label>
                                                            <Form.Control type="input" required maxLength={32} placeholder="Confirm routing number" />
                                                            <Form.Control.Feedback type="invalid">Please confirm routing number.</Form.Control.Feedback>
                                                        </Form.Group>
                                                    </Col>
                                                </Row>
                                            </>
                                        )
                                    }

                                    <hr />
                                    <BillingAddress />

                                </Form>
                            </PageSectionContainer>
                        </Col>
                        <Col lg={4} md={4} sm={12}>
                            <PageSectionContainer>
                                <ReactBsTable responsive>
                                    <tbody>
                                        <th>Payment Summary</th>
                                        <tr>
                                            <td>Subtotal</td>
                                            <td>$200</td>
                                        </tr>
                                        <tr>
                                            <td>Convenience Fee</td>
                                            <td>$0.12</td>
                                        </tr>
                                        <tr>
                                            <td>Total amount</td>
                                            <td>$200.12</td>
                                        </tr>
                                    </tbody>
                                </ReactBsTable>
                                <Form.Check style={{ marginTop: "48px", marginBottom: "48px" }} label="I agree to the Terms and Conditions" />
                                <Button className="brand-btn-primary w-100" onClick={() => { setRedirect(setRoute(Routes.ReceiptStatic.path)); }}>Submit payment</Button>
                                <Link className="brand-link-primary" style={{ marginTop: "8px", display: "block", textAlign: "center" }} to={setRoute(Routes.LoginStatic.path)}>Cancel payment</Link>
                            </PageSectionContainer>
                        </Col>
                    </Row>
                </Container>
            </>
        );
    }
};

const mapStateToProps = (state: IAppState) => {
    return {
    };
};

export default connect(mapStateToProps)(QuickPay);