import { PaymentType } from './Order'
export class Schedule {
    userIdentifier?: string;
    itemIdentifier: string = "";
    paymentDate: string = "";
    recipientAddresses : string [] = [];
    paymentOption: ScheduledPortionType | string = ScheduledPortionType.Unknown;
    preFlightEmailScheduleType: PreFlightEmailScheduleType = PreFlightEmailScheduleType.NoReminder;
    paymentType: PaymentType = PaymentType.Unknown;
    paymentRequestHeaders: Headers = new Headers();
    orderRequestPayload: Payload = new Payload();
    preFlightEmailModel: PreFlightEmailModel = new PreFlightEmailModel();
}
export class Headers {
    ClientId: string = "";
    DepartmentId: string = "";
    PaymentChannelId: string = "";
}

export class Payload {
    paymentType: PaymentType = PaymentType.Unknown;
    shouldTokenizeCard: boolean = false;
    methodName: string = "";
    amount: number = 0;
    convenienceFee: number = 0;
    totalAmount: number = 0;
    cardType: string = "";
    isCardPresent: boolean = false;
    laneId: number = 0;
    isDebitCard: boolean = false;
    terminalId: string = "";
    isMailOrTelephoneOrder: boolean = false;
    orderLines: any;
    metadata: any;
    token: any;
    isTokenizedPayment: boolean = false;
    initiatedBy: string = "";
    eCheckAccountNumber: string = "";
    eCheckRoutingNumber: string = "";
    phone?: string;
    addressLine1?: string;
    addressLine2?: string;
    zip?: string;
    city?: string;
    country?: string;
    state?: string;
    nameOnCard?: string;
    emailAddress?: string;
    creditCardNumber?: string;
    expirationDate?: string;
    cvv?: string;
    clientId: string = "";
    departmentId: string = "";
    paymentChannelId?: string;
}

export class ConvenienceFee {
    paymentType?: PaymentType;
    methodName?: string;
    cardType?: string;
    orderLines?: any
}

export class PreFlightEmailModel  {
    clientName: string = "";
    departmentName: string = "";
    departmentPhoneNumber: string = "";
    departmentEmail: string = "";
    accountNumber: string = "";
    scheduledPortionType: ScheduledPortionType | string = ScheduledPortionType.Unknown;
    paymentAmount: number = 0;
    paymentMethod: string = "";
    scheduledPaymentDate: string = "";
    clientId: string = "";
    departmentId: string = "";
    paymentChannelId?: string;
    externalItemReference?: string;
    paymentType: PaymentType = PaymentType.Unknown;
}

export enum ScheduleCompletionResult {
    Unknown = 0,
    Pending = 1,
    Success = 2,
    Error = 3
}

export enum ScheduledPortionType {
    Unknown = 0,
    Full = 1,
    FirstHalf = 2,
    SecondHalf = 3
}

export enum PreFlightEmailScheduleType
{
    NoReminder = 0,
    OneDays    = 1,
    TwoDays    = 2,
    ThreeDays  = 3,
    FourDays   = 4,
    FiveDays   = 5,
    SixDays    = 6,
    SevenDays  = 7
}
