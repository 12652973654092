import React, { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { Redirect, useParams } from "react-router-dom";
import { IActionResult, IAppState } from "../redux/storeTypes";
import { Routes } from "../routes";
import { getPaymentChannelAction, getWebApplicationForSubdomainAction, GET_WEB_APPLICATION_FAILURE, GET_WEB_APPLICATION_REQUEST, GET_WEB_APPLICATION_SUCCESS } from "../redux/actions/paymentChannelWebApplications";
import { WebApplication, WebPageEnum } from "../models/PaymentChannelWebApplication";
import { Content, StatusEnum } from "../models/CMS";
import type { Value } from '@react-page/editor';
import Editor, { CellPlugin } from '@react-page/editor';

import image from '@react-page/plugins-image';
import { TylerEagleRequest } from "../models/TylerEagleIntegration";
import { setTylerEagleRequestAction } from "../redux/actions/tylerEagleIntegration";
import { PublicClientApplication, EventType } from "@azure/msal-browser";
import { getClientAction, getDepartmentAction } from "../redux/actions/clients";
import { RedirectRequest } from "../models/RedirectIntegration";
import { setRedirectRequestAction } from "../redux/actions/redirectIntegration";
import { IntegrationType, PaymentChannel, WebChannelTypeEnum } from "../models/Client";
import { getContentAction } from "../redux/actions/cms";

export interface IParams {
  id: string
}

export interface IWebApplicationStartProps {
    content: Content,
    webApplication: WebApplication,
    paymentChannel: PaymentChannel,
    actionResult: IActionResult,
    nexusUrl: string,
    b2cRedirectUri: string,
    b2cClientId: string, 
    b2cAuthority: string
}

const WebApplicationStart = ({ webApplication, content, paymentChannel, actionResult, nexusUrl, b2cRedirectUri, b2cClientId, b2cAuthority }:IWebApplicationStartProps) => {
    console.log("WebApplicationStart");
    let actionToken = "WebApplicationStart"
    const dispatch = useDispatch();
    const { id }:IParams = useParams();
    const [value, setValue] = useState<Value>();
    const [redirect, setRedirect] = useState<string>("");

    let imagePlugin = image as CellPlugin<unknown, unknown>;

    const cellPlugins = new Array<CellPlugin>();
    cellPlugins.push(imagePlugin);

    const setRoute = (path:string, webApplicationName:string) => {
        return path.replace(":id", webApplicationName);
    }

    var msalInstance: any;

    useEffect(() => {
        dispatch(getWebApplicationForSubdomainAction(id));
    }, []); // eslint-disable-line react-hooks/exhaustive-deps


    useEffect(() => {
        if (actionResult && actionResult.result && actionResult.type === GET_WEB_APPLICATION_REQUEST && actionResult.token === actionToken) {
            if (actionResult.result === GET_WEB_APPLICATION_SUCCESS) {
            } else if (actionResult.result === GET_WEB_APPLICATION_FAILURE) {           
            }
        }
    }, [actionResult]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (content) {
            //DISABLE the iFRAME CHECK!
            /*
            if (window.self !== window.top) {
                const siteSettings = JSON.parse(content.revisions[0].value);
                const urlException = `https://${siteSettings.siteUrlPostPaymentRedirectUrl}`;
                const urlParentSite = document.location.ancestorOrigins[document.location.ancestorOrigins.length - 1] || document.referrer;
                if (urlException !== urlParentSite) {
                    setRedirect("iFrame-Warning");
                    return;
                }
            }
            */
            //dispatch(getPaymentChannelAction(webApplication.paymentChannelMsbId));
        }
    }, [content]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (webApplication) {
            //dispatch(getContentAction(webApplication.siteSettingsContentId, actionToken));
            if (window.self !== window.top) {
                const urlException = `https://${webApplication.greenListedInlineFrameUrl}`;
                const urlParentSite = document.location.ancestorOrigins[document.location.ancestorOrigins.length - 1] || document.referrer;
                if (urlException !== urlParentSite) {
                    setRedirect("iFrame-Warning");
                    return;
                }
            }
            dispatch(getPaymentChannelAction(webApplication.paymentChannelMsbId));
        }
    }, [webApplication]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (paymentChannel) {
            let path = Routes.LandingPage.path;

            path = Routes.LoginPage.path;

            if (window.location.search) {
                let search = window.location.search.substring(1);
                if (search === WebPageEnum.Payment.toString()) {
                    path = Routes.CheckoutPage.path;
                } else if (search === WebPageEnum.Account.toString()) {
                    path = Routes.AccountPage.path;
                } else if (search === WebPageEnum.Landing.toString()) {
                    path = Routes.LandingPage.path;
                } else if (search === WebPageEnum.Login.toString()) {
                    path = Routes.LoginPage.path;
                } else if (search === WebPageEnum.Receipt.toString()) {
                    path = Routes.ReceiptPage.path;
                } else if (search === WebPageEnum.ShoppingCart.toString()) {
                    path = Routes.ShoppingCartPage.path;
                }
            } 

            if (paymentChannel.integrationType === IntegrationType[IntegrationType.TylerEagle]) {
                localStorage.removeItem("subdomain");
                path = Routes.QuickPay.path;
                const urlSearchParams = new URLSearchParams(window.location.search);
                if (urlSearchParams.has("tylereagle")) {
                    var tylereagle = urlSearchParams.get("tylereagle")!;
                    var tylerEagleRequest = JSON.parse(tylereagle) as TylerEagleRequest;
                    tylerEagleRequest.nexusRedirectUrl = `${nexusUrl}?tylereagle=${tylereagle}`;
                    dispatch(setTylerEagleRequestAction(tylerEagleRequest));
                }
            } else if (paymentChannel.integrationType === IntegrationType[IntegrationType.Redirect]) {
                path = Routes.QuickPay.path;
                try {
                    let params = new URLSearchParams(window.location.search);
                    let bill = atob(params.get("bill") as string);
                    let redirectRequest = JSON.parse(bill) as RedirectRequest;
                    if (redirectRequest.invoice) dispatch(setRedirectRequestAction(redirectRequest));
                } catch (e) {
                }
            } else {
                if (webApplication.paymentChannel.webChannelType === WebChannelTypeEnum[WebChannelTypeEnum.BlindPayment]) {
                    path = Routes.LandingPage.path;
                } else {
                    let msalConfig = {
                    auth: {
                        clientId: b2cClientId,
                        authority: b2cAuthority,
                        knownAuthorities: [b2cAuthority],
                        redirectUri: b2cRedirectUri,
                        postLogoutRedirectUri: "/"
                    }};

                    // eslint-disable-next-line react-hooks/exhaustive-deps
                    msalInstance = new PublicClientApplication(msalConfig);
                    const accounts = msalInstance.getAllAccounts();
      
                    if (accounts.length > 0) {
                        msalInstance.setActiveAccount(accounts[0]);
                    } 
      
                    msalInstance.addEventCallback((event:any) => {
                        if (event.eventType === EventType.LOGIN_SUCCESS && event.payload) {
                            const payload = event.payload;
                            const account = payload.account;
                            msalInstance.setActiveAccount(account);
                        }
                    });
      
                    msalInstance.handleRedirectPromise().then((authResult:any) => {
                        const account = msalInstance.getActiveAccount();
                        if (!account) {
                            msalInstance.loginRedirect();
                        }
                    }).catch((err: any)=>{
                        console.log(err);
                    });
                }
            }

            dispatch(getClientAction(actionToken));
            dispatch(getDepartmentAction(actionToken));
        
            setRedirect(setRoute(path, webApplication.subdomain));
        }
    }, [paymentChannel]);  // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (content !== null) {
            for (let x = 0; x < content.revisions.length;x++) {
                if (content.revisions[x].statusEnum === StatusEnum.Draft) {
                    let revision = content.revisions[x];
                    let value = JSON.parse(revision.value);
                    setValue(value);
                    break;
                }
            }
        }
    }, [content]);   

    if (redirect !== "") {
        if (redirect === "iFrame-Warning") {
            return (
                <div style={{ textAlign: "center" }}>
                    <h1 style={{ color: "red", fontSize: "1.8em", fontWeight: "bold" }}>If you see this page, the web-application link you have clicked may be subject to possible clickjacking.</h1>
                    <h2>Please inform your provider with the reference of the application from where you clicked this link.</h2>
                </div>
            );
        } else {
            return (<Redirect push to={redirect!} />);
        }
    } else {
        return (
            <>
                <Editor cellPlugins={cellPlugins} value={value} readOnly={true} />
            </>
        );
    }
};

const mapStateToProps = (state: IAppState) => {
  return {
    b2cRedirectUri: state.webAppSettings.b2cRedirectUri,
    b2cClientId: state.webAppSettings.b2cClientId,
    b2cAuthority: state.webAppSettings.b2cAuthority,
    nexusUrl: state.webAppSettings.nexusUrl,
    webApplication: state.paymentChannelWebApplication.webApplication,
    paymentChannel: state.paymentChannelWebApplication.paymentChannel,
    content: state.cms.content
  };
};

export default connect(mapStateToProps)(WebApplicationStart);

