import React from "react";

export interface FormHeaderProps {
    title: string;
    description?: string;
    iconImg?: any; // Icon used for modal confirmations
}

const FormHeader = ({ title, description, iconImg }: FormHeaderProps) => {
    return (
        <div className="form-header">
            <div className="form-icon">
              <img src={iconImg} alt="icon" />
            </div>
            <div className="form-title">
              <h2>{title}</h2>
              <p>{description}</p>
            </div>
        </div>
    );
};

export default FormHeader;