import React, { useEffect, useState } from 'react';
import { DateWithoutUTC } from '../../../../../../components/Formatters';
import { formatter } from './NericSearchResults';
import FormHeader from '../../../../../../components/layout/FormHeader';
import { Button, Form } from 'react-bootstrap';
import { connect, useDispatch } from "react-redux";
import { IAppState } from '../../../../../../redux/storeTypes';
import { addToShoppingCart, updateOrderDetailsAction, updateShoppingCart } from '../../../../../../redux/actions/orderManagement';
import { Order } from '../../../../../../models/Order';
import { resetItems } from '../../../../../../redux/actions/account';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
interface IRadioComponent {
    selectedRow: any,
    onClose: any,
    numberOfInstallments: number,
    observesWeekends: any,
    durationOfPendingHours: any,
    shoppingCart?: any,
    order?: any,
}

const RadioComponent = ({ selectedRow, onClose, numberOfInstallments, observesWeekends, durationOfPendingHours, order, shoppingCart }: IRadioComponent) => {

    const dispatch = useDispatch();
    const [selectedOption, setSelectedOption] = useState<any>({ uniqueItemId: `${selectedRow?.msbId}`, option: '' });
    const [installments, setInstallments] = useState([
        {
            id: 1,
            name: 'Installment 1',
            amount: selectedRow?.metaData?.InstallmentFirstDue,
            penalty: selectedRow?.metaData?.InstallmentFirstPenalty,
            label: `Principal: ${formatter.format(selectedRow?.metaData?.InstallmentFirstDue)} Penalty: ${formatter.format(selectedRow?.metaData?.InstallmentFirstPenalty)} Total Due: ${formatter.format(selectedRow?.metaData?.InstallmentFirstDue + selectedRow?.metaData?.InstallmentFirstPenalty)} Due on ${DateWithoutUTC(selectedRow?.metaData?.InstallmentFirstDueDate)}`,
            dueDate: selectedRow?.metaData?.InstallmentFirstDueDate,
            selected: false,
            totalDue: selectedRow?.metaData?.InstallmentFirstDue + selectedRow?.metaData?.InstallmentFirstPenalty,
            uniqueItemId: `${selectedRow?.msbId}`
        }, {
            id: 2,
            name: 'Installment 2',
            amount: selectedRow?.metaData?.InstallmentSecondDue,
            penalty: selectedRow?.metaData?.InstallmentSecondPenalty,
            label: `Principal: ${formatter.format(selectedRow?.metaData?.InstallmentSecondDue)} Penalty: ${formatter.format(selectedRow?.metaData?.InstallmentSecondPenalty)} Total Due: ${formatter.format(selectedRow?.metaData?.InstallmentSecondDue + selectedRow?.metaData?.InstallmentSecondPenalty)} Due on ${DateWithoutUTC(selectedRow?.metaData?.InstallmentSecondDueDate)}`,
            dueDate: selectedRow?.metaData?.InstallmentSecondDueDate,
            selected: false,
            totalDue: selectedRow?.metaData?.InstallmentSecondDue + selectedRow?.metaData?.InstallmentSecondPenalty,
            uniqueItemId: `${selectedRow?.msbId}`
        }, {
            id: 3,
            name: 'Installment 3',
            amount: selectedRow?.metaData?.InstallmentThirdDue,
            penalty: selectedRow?.metaData?.InstallmentThirdPenalty,
            label: `Principal: ${formatter.format(selectedRow?.metaData?.InstallmentThirdDue)} Penalty: ${formatter.format(selectedRow?.metaData?.InstallmentThirdPenalty)} Total Due: ${formatter.format(selectedRow?.metaData?.InstallmentThirdDue + selectedRow?.metaData?.InstallmentThirdPenalty)} Due on ${DateWithoutUTC(selectedRow?.metaData?.InstallmentThirdDueDate)}`,
            dueDate: selectedRow?.metaData?.InstallmentThirdDueDate,
            selected: false,
            totalDue: selectedRow?.metaData?.InstallmentThirdDue + selectedRow?.metaData?.InstallmentThirdPenalty,
            uniqueItemId: `${selectedRow?.msbId}`
        }, {
            id: 4,
            name: 'Installment 4',
            amount: selectedRow?.metaData?.InstallmentFourthDue,
            penalty: selectedRow?.metaData?.InstallmentFourthPenalty,
            label: `Principal: ${formatter.format(selectedRow?.metaData?.InstallmentFourthDue)} Penalty: ${formatter.format(selectedRow?.metaData?.InstallmentFourthPenalty)} Total Due: ${formatter.format(selectedRow?.metaData?.InstallmentFourthDue + selectedRow?.metaData?.InstallmentFourthPenalty)} Due on ${DateWithoutUTC(selectedRow?.metaData?.InstallmentFourthDueDate)}`,
            dueDate: selectedRow?.metaData?.InstallmentFourthDueDate,
            selected: false,
            totalDue: selectedRow?.metaData?.InstallmentFourthDue + selectedRow?.metaData?.InstallmentFourthPenalty,
            uniqueItemId: `${selectedRow?.msbId}`
        },
    ]);

    const handleCheckboxDisable = (index: any) => {
        let isDisabled = true;
        switch (index) {
            case 0:
                isDisabled = installments[index].amount === 0;
                break;
            case 1: case 2: case 3:
                isDisabled = installments[index].amount === 0 ||
                    (!(installments[index - 1].amount === 0 && !installments[index - 1].selected) && (installments[index - 1].amount != 0 && !installments[index - 1].selected));
                break;
            default:
                isDisabled = true;
                break;
        }
        return isDisabled
    }

    const handleCheckboxChange = (index: any) => {
        setSelectedOption(null);
        const updatedInstallments = installments.map((installment, i) => {
            if (i === index) {
                return { ...installment, selected: !installment.selected }
            } else if (i > index) {
                return {
                    ...installment,
                    selected: false,
                };
            } else {
                return { ...installment }
            }
        })
        setInstallments(updatedInstallments)

        const allNonZeroInstallmentsSelected = updatedInstallments.every((installment: any) => installment.amount === 0 || installment.selected);
        setSelectedOption(allNonZeroInstallmentsSelected ? { ...selectedOption, option: 'full' } : null);

    }

    const isDisabled = () => {
        const disableButton = selectedOption?.option === 'full' || installments.some(installment => installment.selected === true)
        return !disableButton
    }

    const displayInstallments = (installments: any, noOfInstallments: any) => {
        const slicedInstallments = installments.slice(0, noOfInstallments);
        return (
            slicedInstallments.map((installment: any, index: any) => (
                <div>
                    <span className='neric_installment_subheading'>{`Installment ${index + 1}`}</span>
                    {installment?.totalDue == 0 ?
                        <div style={{ display: 'flex' }}><FontAwesomeIcon size='sm' icon={faCheck} className="btn-icon" style={{ color: `#52C41A` }} /> Paid</div>
                        :
                        <label key={index} className='neric_label'>
                            <input
                                type='checkbox'
                                className='neric_input'
                                id={`installment-${index}`}
                                onChange={() => handleCheckboxChange(index)}
                                disabled={handleCheckboxDisable(index)}
                                checked={installment.selected}
                            />{installment.label}
                        </label>
                    }
                </div>
            )))
    }

    const handleSubmit = (e: any) => {
        e.preventDefault()

        const selectedInstallments = installments.filter((installment: any) => installment?.selected);
        const selectedFullAmount = installments.filter((installment: any) => installment?.amount != 0);

        const selectedPaymentPlan = selectedOption?.option == 'full' ? selectedFullAmount : selectedInstallments;

        const selectedPaymentOptions = selectedOption?.option == 'full' ? selectedFullAmount.map(installment => installment.id) : selectedInstallments.map(installment => installment.id);
        const paymentOptionsCart = selectedOption?.option == 'full' ? selectedFullAmount.map(installment => installment) : selectedInstallments.map(installment => installment);
        const totalInstallmentAmounts = selectedOption?.option == 'full' ? selectedFullAmount.reduce((sum, installment) => sum + installment.amount + installment.penalty, 0) :
            selectedInstallments.reduce((sum, installment) => sum + installment.amount + installment.penalty, 0);

        const paymentPortionInfo = { ...selectedRow, paymentAmount: totalInstallmentAmounts, paymentPortion: selectedPaymentOptions, selectedOrderLines: paymentOptionsCart };

        const selectedOrderLines = selectedPaymentPlan.map((paymentDetails: any) => ({
            accountNumber: selectedRow?.metaData?.AccountNumber,
            itemReferenceNumber: selectedRow?.metaData?.AccountNumber,
            quantity: 1,
            unitPrice: Number(paymentDetails?.amount + paymentDetails?.penalty),
            itemId: paymentDetails?.uniqueItemId,
            itemName: `Installment ${paymentDetails?.id}`,
            itemMetaData: {
                schoolSwisCode: selectedRow?.metaData?.SchoolCode,
                swis: selectedRow?.metaData?.Swis,
                section: selectedRow?.metaData?.Section,
                subSection: selectedRow?.metaData?.SubSection,
                block: selectedRow?.metaData?.Block,
                lot: selectedRow?.metaData?.Lot,
                subLot: selectedRow?.metaData?.SubLot,
                suffix: selectedRow?.metaData?.Suffix,
                printKey: selectedRow?.metaData?.Printkey,
                billNumber: selectedRow?.metaData?.BillNumber,
                installmentNumber: paymentDetails?.id,
                paymentEnclosed: paymentDetails?.amount,
                penaltyEnclosed: paymentDetails?.penalty,
                statusUpdate: true,
                itemMsbid: paymentDetails?.uniqueItemId,
                observesWeekends: observesWeekends,
                durationOfPendingHours: durationOfPendingHours
            }
        }));
        if (order) {
            const updatedOrderLines = order?.orderLines.filter((orderLine: any) => !selectedOrderLines.some((selected: any) => orderLine?.itemId === selected?.itemId)).concat(selectedOrderLines);
            const updatedShoppingCart = shoppingCart?.map((details: any) => {
                if (details?.msbId == selectedRow?.msbId) {
                    return { ...details, paymentAmount: totalInstallmentAmounts, paymentPortion: selectedPaymentOptions, selectedOrderLines: paymentOptionsCart }
                }
                return details
            }).filter(Boolean);
            const updatedCart = !updatedShoppingCart.some((item: any) => item.msbId === paymentPortionInfo?.msbId)
            if (updatedCart) {
                updatedShoppingCart.push(paymentPortionInfo)
            }
            order.orderLines = updatedOrderLines;
            dispatch(updateOrderDetailsAction(order, 'actionToken'));
            dispatch(updateShoppingCart(updatedShoppingCart, 'shoppingCartActionToken'));
        } else {
            let _order = new Order();
            _order.methodName = "Sale";
            _order.orderLines = selectedOrderLines;
            dispatch(addToShoppingCart(paymentPortionInfo, 'shoppingCartActionToken'));
            dispatch(updateOrderDetailsAction(_order, 'actionToken'));
        }
        dispatch(resetItems());
        onClose();
    }

    useEffect(() => {
        if (selectedOption?.option === 'full') {
            const updatedInstallments = installments.map((installment: any) => ({ ...installment, selected: false }));
            setInstallments(updatedInstallments)
        }
    }, [selectedOption])

    useEffect(() => {
        if (selectedRow) {
            if (shoppingCart) {
                const isFullAmount = shoppingCart.find((cartDetails: any) => cartDetails.msbId == selectedOption?.uniqueItemId && cartDetails?.paymentPortion.includes(0));
                if (isFullAmount) {
                    setSelectedOption({ ...selectedOption, option: 'full' });
                } else {
                    setInstallments(installments.map((installment: any) => {
                        const matchingObj = shoppingCart.find((cartDetails: any) => cartDetails?.paymentPortion.includes(installment?.id) && cartDetails.msbId === installment.uniqueItemId && !cartDetails?.paymentPortion.includes(0));
                        if (matchingObj) {
                            return { ...installment, selected: !matchingObj.paymentPortion?.includes(0) };
                        }
                        return installment;
                    }));
                }
            }
        }
    }, [selectedRow])

    return (
        <div>
            <div className='neric_container'>
                <div>
                    <label className='neric_label'>
                        <input
                            type='radio'
                            name='paymentOption'
                            className='neric_input'
                            id='full'
                            value={selectedOption?.option}
                            disabled={selectedRow?.metaData?.GrandTotal == 0}
                            onChange={(e) => setSelectedOption({ ...selectedOption, option: `${e.target.id}` })}
                            checked={selectedOption?.option === 'full'}
                        /> {`Pay full amount - ${formatter.format(selectedRow?.metaData?.GrandTotal)} Due on ${DateWithoutUTC(selectedRow?.metaData?.BillDueDate)}`}
                    </label>
                </div>
                {numberOfInstallments > 1 &&
                    <>
                        <div className='verticalLine'></div>
                        <div>
                            <FormHeader title="Installments" description={""} />
                            {displayInstallments(installments, numberOfInstallments)}
                        </div>
                    </>
                }
            </div>
            <Form.Group className="form-footer">
                <Button variant="outline-secondary" onClick={onClose}>Close</Button>
                <Button type="submit" disabled={isDisabled()} onClick={(e) => handleSubmit(e)}>Add to cart</Button>
            </Form.Group>
        </div>
    )
}

const mapStateToProps = (state: IAppState) => {
    return {
        isFetching: state.account.isFetching,
        shoppingCart: state.orderManagement.shoppingCart,
        order: state.orderManagement.order,
    };
};

export default connect(mapStateToProps)(RadioComponent);
