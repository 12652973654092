
import React, { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { Redirect, useParams } from "react-router-dom";
import { IActionResult, IAppState } from "../../redux/storeTypes";
import { WebApplication, WebPage, WebPageEnum } from "../../models/PaymentChannelWebApplication";
import { Content, StatusEnum } from "../../models/CMS";
import type { Value } from '@react-page/editor';
import Editor, { CellPlugin } from '@react-page/editor';

import image from '@react-page/plugins-image';
import { getContentAction } from "../../redux/actions/cms";
import { Routes } from "../../routes";
import { PublicClientApplication } from "@azure/msal-browser";
import { POST_AUTH_FAILURE, POST_AUTH_REQUEST, POST_AUTH_SUCCESS, signInAction } from "../../redux/actions/auth";
import { User, UserTypeEnum } from "../../models/User";
import { saveUserAction, SAVE_USER_FAILURE, SAVE_USER_REQUEST, SAVE_USER_SUCCESS } from "../../redux/actions/users";

export interface IParams {
  id: string
}

export interface ILoginPageProps {
  content: Content,
  webApplication: WebApplication,
  publicClientApplication: PublicClientApplication,
  actionResult: IActionResult,
  usersActionResult: IActionResult
}

const LoginPage = ({ publicClientApplication, webApplication, content, actionResult, usersActionResult }: ILoginPageProps) => {
  let actionToken = "LoginPage"
  const dispatch = useDispatch();
  const { id }:IParams = useParams();
  const [value, setValue] = useState<Value>();
  const [webPage, setWebPage] = useState<WebPage>();
  const [redirect, setRedirect] = useState<string>("");

  const setRoute = (path:string, webApplicationName:string) => {
    return path.replace(":id", webApplicationName) + "?" + WebPageEnum.Login;
  }

  useEffect(() => {
    const accountInfo = publicClientApplication.getActiveAccount();
      
    if (!accountInfo) {
      localStorage.setItem("subdomain", id);
      window.location.href = `${window.location.protocol}//${window.location.host}`;
    } else {
      //TODO - get/create user... then route to accountPage
      let idTokenClaims = accountInfo?.idTokenClaims as any;
      let email = idTokenClaims.sub;
      let oid = idTokenClaims.oid;

      dispatch(signInAction(email, oid, publicClientApplication, actionToken));
    }
  }, []);

  useEffect(() => {
    if (actionResult && actionResult.result) {
        if (actionResult.type === POST_AUTH_REQUEST && actionResult.token === actionToken) {
            if (actionResult.result === POST_AUTH_SUCCESS) {
              setRedirect(setRoute(Routes.AccountPage.path, webApplication.subdomain));
            } else if (actionResult.result === POST_AUTH_FAILURE) {
                const accountInfo = publicClientApplication.getActiveAccount();
                let idTokenClaims = accountInfo?.idTokenClaims as any;

                var user = new User();
                user.objectId = idTokenClaims.oid;
                user.userTypeEnum = UserTypeEnum.Constituent;
                user.email = idTokenClaims.email;
                user.firstName = idTokenClaims.given_name;
                user.lastName = idTokenClaims.family_name;

                dispatch(saveUserAction(user, actionToken));
            }
        }
    }
  }, [actionResult]);

  
  useEffect(() => {
    if (usersActionResult && usersActionResult.result) {
        if (usersActionResult.type === SAVE_USER_REQUEST && usersActionResult.token === actionToken) {
            if (usersActionResult.result === SAVE_USER_SUCCESS) {
              const accountInfo = publicClientApplication.getActiveAccount();
              let idTokenClaims = accountInfo?.idTokenClaims as any;
              let email = idTokenClaims.sub;
              let oid = idTokenClaims.oid;
              dispatch(signInAction(email, oid, publicClientApplication, actionToken));
            }
        }
    }
  }, [usersActionResult]);  

  let imagePlugin = image as CellPlugin<unknown, unknown>;  
  const cellPlugins = new Array<CellPlugin>();
  cellPlugins.push(imagePlugin);

  /*
  useEffect(() => {
    if (webApplication) {
      let webPage = webApplication.webPages.filter(_ => _.webPageEnum === WebPageEnum.Login)[0];
      if (webPage) { 
        if (!webPage.content && webPage.contentId !== '00000000-0000-0000-0000-000000000000') {
          dispatch(getContentAction(webPage.contentId, actionToken));
        } else {
          //// TODO - need to hanlde, as there is content for page
        }

        setWebPage(webPage);
      }
    } else {
      ////redirect back to start and load the web application
      setRedirect(setRoute(Routes.WebApplicationStart.path, id));
    }
  }, []);
  */

  /*
  useEffect(() => {
    if (content && webPage && content.msbId! === webPage.contentId) {
        for (let x = 0; x < content.revisions.length;x++) {
            if (content.revisions[x].statusEnum === StatusEnum.Draft) {
                let revision = content.revisions[x];
                let value = JSON.parse(revision.value);
                setValue(value);
                break;
            }
        }
    }
  }, [content]);   
*/

  if (redirect !== "") {
    return (<Redirect push to={redirect!} />)
  } else {
    return (
      <>
          <Editor cellPlugins={cellPlugins} value={value} readOnly={true} />
      </>
    )
  }
};

const mapStateToProps = (state: IAppState) => {
  return {
    actionResult: state.auth.actionResult,
    usersActionResult: state.users.actionResult,
    publicClientApplication: state.webAppSettings.publicClientApplication,
    webApplication: state.paymentChannelWebApplication.webApplication,
    content: state.cms.content
  };
};

export default connect(mapStateToProps)(LoginPage);

