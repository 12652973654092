import { Row, Modal, Form, Spinner, OverlayTrigger, Popover } from "react-bootstrap"
import { useEffect, useState } from "react";
import { IAppState } from '../../../../../../redux/storeTypes';
import { connect, useDispatch } from "react-redux";
import Table from '../../../../../../components/Table';
import { mockData } from '../mocks/stCharles';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faTimes, faInfoCircle  } from "@fortawesome/free-solid-svg-icons";

import moment from "moment";
import StCharlesDetails from "./StCharlesDetails";
import StCharlesFacet from "./StCharlesFacet";
import { resetAddressAction } from "../../../../../../redux/actions/account";
import _ from "lodash";
import React from "react";

interface IStCharlesSearchResults {
    ownerLabel: string,
    taxYearLabel: string,
    billNumberLabel: string,
    taxTypeLabel: string,
    addressLabel: string,
    statusLabel: string,
    paidDateLabel: string,
    statusFilterLabel: string,
    taxTypeFilterLabel: string,
    taxYearFilterLabel: string,
    actionsLabel: string,
    disableCart: boolean,
    disableCartDateRanges: string,
    delinquentLabel: string,
    delinquentBackgroundColor: string,
    delinquentTextColor: string,
    delinquentTextAlign: string,
    firstNameLabel: string,
    lastNameLabel: string,
    emailLabel: string,
    phoneNumberLabel: string,
    newAddressLabel: string,
    cityLabel: string,
    stateLabel: string,
    zipLabel: string,
    checkboxDisclaimer: string,
    addressChangeDisclaimer: string,
    firstNameRequired: boolean,
    lastNameRequired: boolean,
    emailRequired: boolean,
    phoneNumberRequired: boolean,
    newAddressRequired: boolean,
    cityRequired: boolean,
    stateRequired: boolean,
    zipCodeRequired: boolean,
    checkboxDisclaimerRequired: boolean,
    margin: string,
    padding: string,
    headerBackgroundColor: string,
    headerPadding: string,
    itemDetails: any,
    isFetching: boolean,
    errorMessage: any
}

export const OWNER_LABEL = 'Owner Name';
export const TAX_YEAR_LABEL = 'Year';
export const BILL_NUMBER_LABEL = 'Account/PIN';
export const ADDRESS_LABEL = 'Description';
export const TAX_TYPE_LABEL = 'Type';
export const STATUS_LABEL = 'Paid';
export const PAID_DATE_LABEL = 'Paid Date';
export const ACTIONS_LABEL = 'Actions';
export const DISABLE_CART = false;
export const DELINQUENT_LABEL = 'All the following records must be paid';
export const DELINQUENT_BACKGROUND_COLOR = '#f0f0f0';
export const DELINQUENT_TEXT_ALIGN = 'center';
export const DELINQUENT_TEXT_COLOR = 'black';
export const STATUS_FILTER_LABEL = 'Status';
export const TAX_TYPE_FILTER_LABEL = 'Type';
export const TAX_YEAR_FILTER_LABEL = 'Years';
export const FIRST_NAME_LABEL = 'First Name';
export const LAST_NAME_LABEL = 'Last Name';
export const EMAIL_LABEL = 'Email';
export const PHONE_NUMBER_LABEL = 'Phone Number';
export const NEW_ADDRESS_LABEL = 'New Address';
export const CITY_LABEL = 'City';
export const STATE_LABEL = 'State';
export const ZIP_CODE_LABEL = 'Zip';
export const CHECK_BOX_DISCLAIMER = 'I certify under penalty of perjury under the laws of the State of Missouri that I am the tax payer of record for this tax bill and the foregoing submission is true and correct.'
export const ADDRESS_CHANGE_DISCLAIMER = 'The following is a web-based request feature to update your mailing address for your respective bill.'
export const FIRST_NAME_REQUIRED = false;
export const LAST_NAME_REQUIRED = false;
export const EMAIL_REQUIRED = false;
export const PHONE_NUMBER_REQUIRED = false;
export const NEW_ADDRESS_REQUIRED = false;
export const CITY_REQUIRED = false;
export const STATE_REQUIRED = false;
export const ZIP_CODE_REQUIRED = false;
export const CHECK_BOX_DISCLAIMER_REQUIRED = false;
export const MARGIN = '0px';
export const PADDING = '0px';
export const HEADER_BACKGROUND_COLOR = 'rgb(250, 250, 250)';
export const HEADER_PADDING = '0';

const checkStyleForDefault = (style: string, defaultStyle: string) => {
    return style && style !== '' ? style : defaultStyle;
}

export const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
});

const StCharlesSearchResults = ({ ownerLabel, taxYearLabel, billNumberLabel, taxTypeLabel, addressLabel, statusLabel, paidDateLabel, statusFilterLabel, taxTypeFilterLabel, addressChangeDisclaimer,
    taxYearFilterLabel, actionsLabel, disableCart, delinquentLabel, delinquentBackgroundColor, delinquentTextAlign, delinquentTextColor, disableCartDateRanges, firstNameLabel, lastNameLabel, emailLabel, 
    phoneNumberLabel, newAddressLabel, cityLabel, stateLabel, zipLabel, checkboxDisclaimer,
    firstNameRequired, lastNameRequired, emailRequired, phoneNumberRequired, newAddressRequired, cityRequired, stateRequired, zipCodeRequired, checkboxDisclaimerRequired,
    margin, padding, headerBackgroundColor, headerPadding, itemDetails, isFetching, errorMessage }: IStCharlesSearchResults) => {

    if (!ownerLabel) { taxTypeLabel = OWNER_LABEL }
    if (!taxYearLabel) { taxTypeLabel = TAX_YEAR_LABEL }
    if (!billNumberLabel) { billNumberLabel = BILL_NUMBER_LABEL }
    if (!taxTypeLabel) { taxTypeLabel = TAX_TYPE_LABEL }
    if (!addressLabel) { addressLabel = ADDRESS_LABEL }
    if (!statusLabel) { statusLabel = STATUS_LABEL }
    if (!paidDateLabel) { paidDateLabel = PAID_DATE_LABEL }
    if (!statusFilterLabel) { statusFilterLabel = STATUS_FILTER_LABEL }
    if (!taxTypeFilterLabel) { taxTypeFilterLabel = TAX_TYPE_FILTER_LABEL }
    if (!taxYearFilterLabel) { taxYearFilterLabel = TAX_YEAR_FILTER_LABEL }
    if (!actionsLabel) { actionsLabel = ACTIONS_LABEL }
    if (!disableCart) { disableCart = DISABLE_CART }
    if (!disableCartDateRanges) { disableCartDateRanges = '' }
    if (!delinquentLabel) { delinquentLabel = DELINQUENT_LABEL }
    if (!delinquentBackgroundColor) { delinquentBackgroundColor = DELINQUENT_BACKGROUND_COLOR }
    if (!delinquentTextAlign) { delinquentTextAlign = DELINQUENT_TEXT_ALIGN }
    if (!delinquentTextColor) { delinquentTextColor = DELINQUENT_TEXT_COLOR}
    if (!firstNameLabel) { firstNameLabel = FIRST_NAME_LABEL }
    if (!lastNameLabel) { lastNameLabel = LAST_NAME_LABEL }
    if (!emailLabel) { emailLabel = EMAIL_LABEL }
    if (!phoneNumberLabel) { phoneNumberLabel = PHONE_NUMBER_LABEL }
    if (!newAddressLabel) { newAddressLabel = NEW_ADDRESS_LABEL }
    if (!cityLabel) { cityLabel = CITY_LABEL }
    if (!stateLabel) { stateLabel = STATE_LABEL }
    if (!zipLabel) { zipLabel = ZIP_CODE_LABEL }
    if (!checkboxDisclaimer) { checkboxDisclaimer = CHECK_BOX_DISCLAIMER }
    if (!addressChangeDisclaimer) { addressChangeDisclaimer = ADDRESS_CHANGE_DISCLAIMER }
    if (!firstNameRequired) { firstNameRequired = FIRST_NAME_REQUIRED }
    if (!lastNameRequired) { lastNameRequired = LAST_NAME_REQUIRED }
    if (!emailRequired) { emailRequired = EMAIL_REQUIRED }
    if (!phoneNumberRequired) { phoneNumberRequired = PHONE_NUMBER_REQUIRED }
    if (!newAddressRequired) { newAddressRequired = NEW_ADDRESS_REQUIRED }
    if (!cityRequired) { cityRequired = CITY_REQUIRED }
    if (!stateRequired) { stateRequired = STATE_REQUIRED }
    if (!zipCodeRequired) { zipCodeRequired = ZIP_CODE_REQUIRED }
    if (!checkboxDisclaimerRequired) { checkboxDisclaimerRequired = CHECK_BOX_DISCLAIMER_REQUIRED }
    if (!margin) { margin = MARGIN }
    if (!padding) { padding = PADDING }
    if (!headerBackgroundColor) { headerBackgroundColor = HEADER_BACKGROUND_COLOR }
    if (!headerPadding) { headerPadding = HEADER_PADDING }

    const statusFormatter = (cell: any) => {
        let color: string = "#F5222D";
        if (cell === 'Paid') { color = "#52C41A" }
        if (cell === 'Pending') { color = "#f5b759"}
        return (
            <span style={{ display: 'flex', alignItems: 'center' }}><FontAwesomeIcon icon={cell === 'Paid' ? faCheck : cell === 'Pending' ? faInfoCircle : faTimes} className="btn-icon" style={{ color: `${color}` }} />{cell}</span>
        )
    }

    const DateFormatter = (date: any) => {
        if (date) {
            const dateString = moment(date).format('MM/DD/YYYY');
            return (
                <span>{dateString}</span>
            );
        }
    }

    const ownerFormatter = (cell: any) => {
        const lines = _.split(cell, '*');
        return lines.map((line: any, index: any) => (
            <React.Fragment key={index}>
                {index == 0 ?
                    <div>{line}</div> :
                    <div style={{ marginLeft: '8px', fontSize: 'smaller' }}>{line}</div>
                }
            </React.Fragment>
        ))
    }

    const ActionsFormatter = (cell: any, row: any) => {
        return (
            <div>
            <span style={{marginRight:'10px'}}><a onClick={() => { dispatch(resetAddressAction()); setSelectedRow(row); setShowModal(true) }}>{actionsLabel}</a></span>
            <OverlayTrigger trigger="click" rootClose placement="top" overlay={(
                    <Popover id="popover-positioned-top">
                        <Popover.Content>
                            <span>
                                <li><strong>View Details</strong></li>
                                {row?.paymentStatus === 'Unpaid' && !disableCartFeature && <li><strong>Add To Cart</strong></li>}
                                <li><strong>Address Change</strong></li>
                            </span>
                        </Popover.Content>
                    </Popover>
            )}>
                <FontAwesomeIcon icon={faInfoCircle} style={{ color: "#8C8C8C", cursor: 'pointer' }} />
            </OverlayTrigger>
            </div>
        )
    }

    const initialColumns = [{
        dataField: 'billInformation[0].cadOwner',
        text: ownerLabel,
        headerStyle: {
            backgroundColor: checkStyleForDefault(headerBackgroundColor, HEADER_BACKGROUND_COLOR)
        },
        formatter: ownerFormatter,
        editable: false,
    }, {
        dataField: 'billInformation[0].taxYear',
        text: taxYearLabel,
        headerStyle: {
            backgroundColor: checkStyleForDefault(headerBackgroundColor, HEADER_BACKGROUND_COLOR)
        },
        editable: false,
    }, {
        dataField: 'ain',
        text: billNumberLabel,
        editable: false,
        headerStyle: {
            backgroundColor: checkStyleForDefault(headerBackgroundColor, HEADER_BACKGROUND_COLOR)
        },
        sort: true,
    }, {
        dataField: 'ownerFullAddress',
        text: addressLabel,
        headerStyle: {
            backgroundColor: checkStyleForDefault(headerBackgroundColor, HEADER_BACKGROUND_COLOR)
        },
        editable: false,
    }, {
        dataField: 'billInformation[0].classCodes[0].description',
        text: taxTypeLabel,
        editable: false,
        headerStyle: {
            backgroundColor: checkStyleForDefault(headerBackgroundColor, HEADER_BACKGROUND_COLOR)
        },
        sort: true
    }, {
        dataField: 'paymentStatus', // Check
        text: statusLabel,
        formatter: statusFormatter,
        headerStyle: {
            backgroundColor: checkStyleForDefault(headerBackgroundColor, HEADER_BACKGROUND_COLOR)
        },
        editable: false,
    }, {
        dataField: 'billInformation[0].paymentInformation[0].paymentDate', // Check
        text: paidDateLabel,
        formatter: DateFormatter,
        headerStyle: {
            backgroundColor: checkStyleForDefault(headerBackgroundColor, HEADER_BACKGROUND_COLOR)
        },
        editable: false,
    }, {
        dataField: 'billInformation[0].id',
        text: actionsLabel,
        headerStyle: {
            backgroundColor: checkStyleForDefault(headerBackgroundColor, HEADER_BACKGROUND_COLOR)
        },
        formatter: ActionsFormatter,
        editable: false,
    }];

    const [columns, setColumns] = useState<any>(initialColumns);

    const [selectedRow, setSelectedRow] = useState<any>([]);
    const [showModal, setShowModal] = useState<boolean>(false);
    const [data, setData] = useState<any>([]);
    const [filters, setFilters] = useState<any>({ paymentStatus: [], propertyType: [], taxYear: [] });
    const [filteredData, setFilteredData] = useState([]);
    const [customErrorMessage, setErrorMessage] = useState('');
    const dispatch = useDispatch();

    const [startDateRange, endDateRange] = disableCartDateRanges.split('<->');
    const startDate = moment(startDateRange, 'MM/DD/YYYY');
    const endDate = moment(endDateRange, 'MM/DD/YYYY');

    const userTimeInCentral = moment().tz('America/Chicago')
    const isWithinRange = userTimeInCentral.isSameOrAfter(startDate, 'day') && userTimeInCentral.isSameOrBefore(endDate, 'day')
    const disableCartFeature = disableCart || isWithinRange;

    const handleFilterChange = (filterType: any, filterValue: any) => {
        setFilters((prevFilters: any) => ({
            ...prevFilters,
            [filterType]: prevFilters[filterType].includes(filterValue)
                ? prevFilters[filterType].filter((value: any) => value !== filterValue)
                : [...prevFilters[filterType], filterValue]
        }))
    }

    useEffect(() => {
        if (itemDetails) {
            setData(itemDetails);
            setFilteredData(itemDetails);
            setFilters({ paymentStatus: [], propertyType: [], taxYear: [] })
            setErrorMessage(errorMessage?.response?.data?.message || errorMessage?.response?.data?.Message)
        }
    }, [itemDetails, errorMessage])

    useEffect(() => {
        const filteredItems = data.filter((item: any) => {
            return Object.keys(filters).every(key => {
                return filters[key].length === 0 || filters[key].includes(item[key])
            });
        });
        setFilteredData(filteredItems)
    }, [filters, data])

    const renderTable = () => {
        if (isFetching) {
            return (<div style={{ textAlign: 'center' }} ><Spinner animation="border" /> </div>);
        }
        else if (filteredData.length === 0) {
            return (
                <div style={{ textAlign: 'center' }}>Currently there are no records to display. Please do a search to find the record you are looking for.</div>
            )
        }
        else {
            return (
                <>
                    <Table
                        keyField="msbId"
                        data={filteredData}
                        remote={false}
                        columns={columns}
                        sizePerPage={10}
                    />
                </>);
        }
    };

    return (

        <div style={{ margin: margin, padding: padding }}>
            <div className="stCharles_table_details">
                <StCharlesFacet data={data} filteredData={filteredData} filters={filters} onFilterChange={handleFilterChange} isFetching={isFetching} />
                <Row className="table_view">{renderTable()}</Row>
            </div>
            <Modal show={showModal} size={'xl'} backdrop="static" onHide={() => setShowModal(false)}>
                <Modal.Header closeButton />
                <Modal.Body>
                    <Form.Group>
                        {selectedRow ?
                            <StCharlesDetails selectedRow={selectedRow} onClose={() => setShowModal(false)}
                                firstNameLabel={firstNameLabel} lastNameLabel={lastNameLabel} emailLabel={emailLabel} phoneNumberLabel={phoneNumberLabel} addressChangeDisclaimer={addressChangeDisclaimer}
                                newAddressLabel={newAddressLabel} cityLabel={cityLabel} stateLabel={stateLabel} zipLabel={zipLabel} checkboxDisclaimer={checkboxDisclaimer}
                                firstNameRequired={firstNameRequired} lastNameRequired={lastNameRequired} emailRequired={emailRequired} phoneNumberRequired={phoneNumberRequired}
                                newAddressRequired={newAddressRequired} cityRequired={cityRequired} stateRequired={stateRequired} zipCodeRequired={zipCodeRequired} 
                                checkboxDisclaimerRequired={checkboxDisclaimerRequired} disableCartFeature={disableCartFeature} delinquentLabel={delinquentLabel}
                                delinquentBackground={delinquentBackgroundColor} delinquentTextColor={delinquentTextColor} delinquentTextAlign={delinquentTextAlign} 
                            /> : <span> 'There are no details.</span>}
                    </Form.Group>
                </Modal.Body>
            </Modal>
        </div>
    )
}

const mapStateToProps = (state: IAppState) => {
    return {
        isFetching: state.account.isFetching,
        itemDetails: state.account.itemDetails,
        errorMessage: state.account.errorMessage
    };
};

export default connect(mapStateToProps)(StCharlesSearchResults);