import _ from 'lodash';
import store from '../redux/store';

export const UniformFees = (paymentChannelId?: string) => {
    let baseApiUrl = store.getState().webAppSettings.baseApiUrl;
    let bearerToken = store.getState().webAppSettings.bearerToken;
    let url = `${baseApiUrl}/MSB_Client/api/v1/PaymentChannels/GetAllFeesForPaymentChannel/${paymentChannelId}`

    return fetch(url, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${bearerToken}`
        },
    })
    .then(response => {
        if (response.ok) return response.json();
        throw new Error(response.status.toString());
    })
    .then(data => {
        const fees: any[] = [];
        data.forEach((d: any) => {
            if (d.paymentType >= 1 && d.paymentType <= 10) fees.push(d.percentage);
        });        
        return fees.every(f => f === fees[0]);
    })
    .catch((error) => {
        console.log("FEE TYPE ERROR", error);
        return false;
    });
};

