import {
    GET_CLIENT_REQUEST, GET_CLIENT_SUCCESS, GET_CLIENT_FAILURE,
    GET_DEPARTMENT_REQUEST, GET_DEPARTMENT_SUCCESS, GET_DEPARTMENT_FAILURE,
    GET_PAYPAL_CREDENTIALS_REQUEST, GET_PAYPAL_CREDENTIALS_SUCCESS, GET_PAYPAL_CREDENTIALS_FAILURE,
    SET_AUTHORIZATION_POST_REQUEST, SET_AUTHORIZATION_POST_SUCCESS, SET_AUTHORIZATION_POST_FAILURE
} from '../actions/clients';

export default function clients(state = {
    isFetching: false,
    isSaving: false,
    errorMessage: '',
    client: null,
    department: null,
    payPalCredential: null,
    actionResult: null,
    receipt: null
}, { type, payload }) {
    switch (type) {
    case GET_CLIENT_REQUEST:
        return Object.assign({}, state, {
            isFetching: true,
            actionResult: { type: GET_CLIENT_REQUEST, result: null, token: payload.actionToken },
            client: null,
            department: null
        });
    case GET_CLIENT_SUCCESS:
        return Object.assign({}, state, { 
            isFetching: false,
            actionResult: { type: GET_CLIENT_REQUEST, result: GET_CLIENT_SUCCESS, token: payload.actionToken } ,
            client: payload.client,
        });
    case GET_CLIENT_FAILURE:
        return Object.assign({}, state, {
            isFetching: false,
            actionResult: { type: GET_CLIENT_REQUEST, result: GET_CLIENT_FAILURE, token: payload.actionToken } ,
            errorMessage: payload.error
        });
    case GET_DEPARTMENT_REQUEST:
        return Object.assign({}, state, {
            isFetching: true,
            actionResult: { type: GET_DEPARTMENT_REQUEST, result: null, token: payload.actionToken },
        });
    case GET_DEPARTMENT_SUCCESS:
        return Object.assign({}, state, {
            isFetching: false,
            department: payload.department,
            actionResult: { type: GET_DEPARTMENT_REQUEST, result: GET_DEPARTMENT_SUCCESS, token: payload.actionToken },
        });
    case GET_DEPARTMENT_FAILURE:
        return Object.assign({}, state, {
            isFetching: false,
            actionResult: { type: GET_DEPARTMENT_REQUEST, result: GET_DEPARTMENT_FAILURE, token: payload.actionToken },
            errorMessage: payload.error
        });
    case GET_PAYPAL_CREDENTIALS_REQUEST:
        return Object.assign({}, state, {
            isFetching: true,
            payPalCredential: null,
            actionResult: { type: GET_PAYPAL_CREDENTIALS_REQUEST, result: null, token: payload.actionToken },
        });
    case GET_PAYPAL_CREDENTIALS_SUCCESS:
        return Object.assign({}, state, {
            isFetching: false,
            payPalCredential: payload.payPalCredential,
            actionResult: { type: GET_PAYPAL_CREDENTIALS_REQUEST, result: GET_PAYPAL_CREDENTIALS_SUCCESS, token: payload.actionToken },
        });
    case GET_PAYPAL_CREDENTIALS_FAILURE:
        return Object.assign({}, state, {
            isFetching: false,
            payPalCredential: null,
            actionResult: { type: GET_PAYPAL_CREDENTIALS_REQUEST, result: GET_PAYPAL_CREDENTIALS_FAILURE, token: payload.actionToken },
            errorMessage: payload.error
        });
    case SET_AUTHORIZATION_POST_REQUEST:
        return Object.assign({}, state, {
            isFetching: true,
            actionResult: { type: SET_AUTHORIZATION_POST_REQUEST, result: null, token: payload.actionToken },
            errorMessage: "",
        });
    case SET_AUTHORIZATION_POST_SUCCESS:
        return Object.assign({}, state, {
            isFetching: false,
            actionResult: { type: SET_AUTHORIZATION_POST_REQUEST, result: SET_AUTHORIZATION_POST_SUCCESS, token: payload.actionToken },
            errorMessage: "",
        });
    case SET_AUTHORIZATION_POST_FAILURE:
        return Object.assign({}, state, {
            isFetching: false,
            actionResult: { type: SET_AUTHORIZATION_POST_REQUEST, result: SET_AUTHORIZATION_POST_FAILURE, token: payload.actionToken },
            errorMessage: payload.error
        });
    default:
        return state;
    }
}
