export class Client {
    id?: string;
    msbId?: string;
    businessName: string = "";
    businessEstablishedDate: string = "";
    timeZoneInfo: string = "";
    observesDaylightSavings: boolean = false;

    updatedAt: string = "";
    createdAt: string = "";
    isArchived: boolean = false;
    onBoardingStatus: keyof typeof ClientStatusEnum = ClientStatusEnum[ClientStatusEnum.Initialized] as keyof typeof ClientStatusEnum;

    clientAddresses: Array<Address> = new Array<Address>();
    clientContacts: Array<Contact> = new Array<Contact>();
    departments: Array<Department> = new Array<Department>();
    merchantProcessors: Array<MerchantProcessor> = new Array<MerchantProcessor>();
}

export class Address {
    msbId?: string;
    clientMsbId: string = "";
    departmentMsbId?: string;
    addressReferenceName: string = "";
    addressTypeEnum: AddressTypeEnum = AddressTypeEnum.Physical;
    addressLine1: string = "";
    addressLine2: string = "";
    city: string = "";
    state: string = "";
    zipCode: string = "";
}

export class Contact {
    msbId?: string;
    clientMsbId: string = "";
    departmentMsbId?: string;
    contactReferenceName: string = "";
    contactTypeEnum: number = 0;
    firstName: string = "";
    lastName: string = "";
    phoneNumber: string = "";
    emailAddress: string = "";
    faxNumber: string = "";
}

export enum ReceiptTypeEnum {
    Unknown = 0,
    CardPresent = 1,
    CardNotPresent = 2,
    eCheck = 3,
    Authorization = 9,
    Confirmation = 10
}

export class Receipt {
    msbId?: string;
    name: string = "";
    receiptType: ReceiptTypeEnum = ReceiptTypeEnum.Unknown;
    contentId: string = "";
}

export class Department {
    id?: string;
    msbId?: string;
    clientId: string = "";
    client: Client = new Client();
    name: string = "";
    legalBusinessName: string = "";
    businessWebsite: string = "";
    bankFundingDescriptor: string = "";
    achDebitDescriptor: string = "";
    debitFeeMethod: DebitFeeMethodEnum = DebitFeeMethodEnum.DirectDebit;
    feeBurdenMethod: FeeBurdenMethodEnum = FeeBurdenMethodEnum.ConvenienceFee;
    fundingMethod: FundingMethodEnum = FundingMethodEnum.NextDay;
    chargebackFee: number = 0;
    nsfFee: number = 0;
    isConvenienceFeeClientAbsorbed: boolean = false;
    onBoardingStatus?: keyof typeof DepartmentStatusEnum = DepartmentStatusEnum[DepartmentStatusEnum.Initialized] as keyof typeof DepartmentStatusEnum;
    clientBankAccounts?: Array<BankAccount> = new Array<BankAccount>();
    departmentAddresses?: Array<Address> = new Array<Address>();
    departmentContacts?: Array<Contact> = new Array<Contact>();
    paymentChannels?: Array<PaymentChannel> = new Array<PaymentChannel>();
    convenienceFees?: Array<ConvenienceFee> = new Array<ConvenienceFee>();
    departmentsPrinters?: Array<Printer> = new Array<Printer>();
    receipts?: Array<Receipt> = new Array<Receipt>();
}

export enum PaymentTypeEnum
{
    Unknown = 0,
    AmericanExpressCredit = 1,
    AmericanExpressDebit = 2,
    DiscoverCredit = 3,
    DiscoverDebit = 4,
    MastercardCredit = 5,
    MastercardDebit = 6,
    MastercardDebitPIN = 7,
    VisaCredit = 8,
    VisaDebit = 9,
    VisaDebitPIN = 10,
    eCheck = 11
}

export class ConvenienceFee
{
    msbId?: string;
    departmentMsbId: string = "";
    paymentTypeEnum: PaymentTypeEnum = PaymentTypeEnum.AmericanExpressCredit;
    minimumAmount: any = 0.0;
    maximumAmount: any = 0.0;
    minimumConvenienceFeeAmount: any = 0.0;
    maximumConvenienceFeeAmount: any = 0.0;
    percentage: any = 0.0;
    flatFee: any = 0.0;
    order: number = 0;
    feeType: ConvenienceFeeType = ConvenienceFeeType.Percentage;
}

export enum ConvenienceFeeType
{
    Percentage = 0,
    FlatFee = 1
}

export class MerchantIdsCreateRequest
{
    clientMsbId: string = "";
    departmentMsbId: string = "";
    eCommerceCCDebitDescriptor: string = "";
    retailCCDebitDescriptor: string = "";
    ownershipTypeEnum: OwnershipTypeEnum = OwnershipTypeEnum.Unknown;
    checkForDuplicates: boolean = false;
    mccCode: string = "";
    batchCloseMethodEnum: BatchCloseMethodEnum = BatchCloseMethodEnum.Unknown;
    batchCloseTime: string = "";
    signaturePromptEnum: SignaturePromptEnum = SignaturePromptEnum.Never;
    signatureThreshold: number = 0;
    useVisa: boolean = true;
    useDiscover: boolean = false;
    useMastercard: boolean = true;
    useAmericanExpress: boolean = false;
}

export class MerchantIdsCreateResponse {
    cardPresentMerchantID: MerchantProcessor;
    cardNotPresentMerchantID: MerchantProcessor;

    constructor(cardPresentMerchantID: MerchantProcessor, cardNotPresentMerchantID: MerchantProcessor) {
        this.cardPresentMerchantID = cardPresentMerchantID;
        this.cardNotPresentMerchantID = cardNotPresentMerchantID;
    }
}

export class ErrorResponse
{
    correlationId: string = "";
    httpStatusCode: string = "";
    httpStatusMessage: string = "";
    errors: Array<InternalError> = [];
}

export class InternalError
{
    errorCode: string = "";
    errorMessage: string = "";
    target: string = "";
}

export class MerchantProcessor {
    id?: string;
    msbId?: string;
    clientId: string = "";
    //channels: [],
    fundingAccounts: Array<FundingAccount> = new Array<FundingAccount>();
    terminals: Array<Terminal> = new Array<Terminal>();
    merchantIdentifier: string = "";
    businessType: BusinessTypeEnum = BusinessTypeEnum.Unknown;
    processor: string = "";
    isValid: boolean = false;
    processorType: keyof typeof MerchantProcessorTypeEnum = "Vantiv";
    createdAt?: string;
    updatedAt?: string;
    vantivCredential?: VantivCredential = new VantivCredential();
    vantivConfiguration: VantivConfiguration = new VantivConfiguration();
    merchantAddress: Address = new Address();
    merchantContact: Contact = new Contact();
    payPalCredential: PayPalCredential = new PayPalCredential();
}

export class ProcessorInformation {
    businessType?: string;
}

export class FundingAccount {
    vantivFundingAccountId: string = "";
    merchantProcessorId: string = "";
}

export class VantivCredential {
    msbId?: string;
    merchantProcessorId?: string;
    accountId: string = "";
    accountToken: string = "";
    acceptorId: string = "";
    id?: string;
    createdAt?: string;
    updatedAt?: string;
}

export class VantivConfiguration {
    merchantProcessorId?: string;
    federalTaxId: string = "";
    dbaName: string = "";
    legalBusinessName: string = "";
    businessWebsite: string = "";
    vantivIdentifier?: string;
    ownershipType: keyof typeof OwnershipTypeEnum = "Government";
    businessType: keyof typeof BusinessTypeEnum = "Unknown";
    mccCode: string = "9399";
    creditCardDebitDescriptor: string = "";
    underWritingStatus?: keyof typeof SubMerchantUnderWritingStatusEnum;
    underWritingReason?: string;
    batchCloseMethodType: keyof typeof BatchCloseMethodEnum = "MerchantInitiated";
    batchCloseTime: string = "23:59:00";
    promptForSignature: keyof typeof SignaturePromptEnum = "Never";
    promptForSignatureThresholdAmount: string = "0.00";
    acceptedCardTypes: Array<SubMerchantAcceptedCardTypeEnum> = new Array<SubMerchantAcceptedCardTypeEnum>();
}

export enum MerchantProcessorTypeEnum {
    Unknown = 0,
    Vantiv = 1
}

export enum SubMerchantUnderWritingStatusEnum {
    Unknown = 0,
    Pending = 1,
    Approved = 2,
    Rejected = 3
}

export enum PromptForSignature {
    Unknown = 0,
    TimeInitiated
}

export class SubMerchantResult {
    eCommerceMerchantID?: MerchantProcessor;
    retailMerchantID?: MerchantProcessor;
    errors?: Map<string, Array<string>>;
}

export class PayPalCredential {
    id?: string;
    msbId?: string;
    createdAt?: string;
    updatedAt?: string;
    isArchived?: boolean;
    createdByMsbId?: string;
    updatedByMsbId?: string;
    allowCard: boolean = false;
    allowCredit: boolean = false;
    allowVenmo: boolean = false;
    payPalMerchantIdentifier?: string;
    navientCommissionPercentage: number = 0.00;
    permissionGranted: boolean = false;
    consentStatus: boolean = false;
    isEmailConfirmed: boolean = false;
    isPaymentReceivable: boolean = false;
}

export class ApprovalChecklist {
    msbId?: string;
    clientMsbId: string = "";
    merchantApplication: boolean = false;
    w9Signed: boolean = false;
    achFormSigned: boolean = false;
    verballyConfirmedACH: boolean = false;
    paymentProcessingContract: boolean = false;
    ofacSanctions: boolean = false;
    fincen: boolean = false;
    governmentLocationExists: boolean = false;
    convenienceFees: boolean = false;
    comments: string = "";
    isApprovalSubmitted: boolean = false;
}

export enum IntegrationType {
    None = 0,
    TylerEagle = 1,
    Redirect = 2,
}

export class PaymentChannel {
    id: string = "";
    msbId?: string;
    createdAt?: string = "";
    updatedAt?: string = "";
    isArchived: boolean = false;
    departmentId: string = "";
    department: Department = new Department();
    processors: Array<Processor> = new Array<Processor>();
    convenienceFees: Array<ConvenienceFee> = new Array<ConvenienceFee>();
    softBlockedAcceptedCards: Array<SubMerchantAcceptedCardTypeEnum> = new Array<SubMerchantAcceptedCardTypeEnum>();
    name: string = "";
    webApiKey: string = "";
    paymentChannelType: keyof typeof PaymentChannelTypeEnum = "Select";
    webChannelType: keyof typeof WebChannelTypeEnum = "BlindPayment";
    integrationType: keyof typeof IntegrationType = "None";
    isActive: boolean = false;
    checkForDuplicateTransactions: boolean = false;
    requiresCvv: boolean = false;
    enableAvsChecks: boolean = false;
    requiresReferenceNumber: boolean = false;
    electronicCheckPaymentsEnabled: boolean = false;
    creditCardPaymentsEnabled: boolean = false;
    manualEntryPaymentsEnabled: boolean = false;
    terminalPaymentsEnabled: boolean = false;
    payPalPaymentsEnabled: boolean = false;
    embeddedCheckoutIsEnabled: boolean = false;
    embeddedLinksIsEnabled: boolean = false;
    tokenizationIsEnabled: boolean = false;
}

export enum SubMerchantAcceptedCardTypeEnum {
    None = 0,
    Visa = 1,
    MasterCard = 2,
    AmericanExpress = 4,
    Discover = 8
}

export class Processor {
    paymentChannelId: string = "";
    merchantProcessorId: string = "";
    merchantProcessor?: MerchantProcessor;
}

export enum PaymentChannelTypeEnum {
    Select = 0,
    QuickPay = 1, // Quick Pay (QP)
    TerminalPay = 2, //Terminal Pay
    IVR = 3, // Interactive Voice Response (IVR)
    WebAPI = 4,
    Kiosk = 5, //Kiosk (Kiosk)
    Web = 6, //Web
    PointOfSale = 7 //PointOfSale
}

export class Printer {
    msbId?: string;
    departmentMsbId: string = "";
    name: string = "";
    ipAddress: string = "";
    userName: string = "";
    password: string = "";
    statusEnum: PrinterStatusEnum = PrinterStatusEnum.Offline;
    serial: string = "";
}

export enum PrinterStatusEnum {
    Offline = 0,
    Online = 1,
    Unknown = 2
}

export class Terminal {
    msbId?: string;
    departmentMsbId: string = "";
    name: string = "";
    idleMessage: string = "";
    laneId: number = 0;
    status?: string;
    statusLastReceived?: string;
}

export enum TerminalStatusEnum {
    Offline = 0,
    Online = 1,
    Unknown = 2
}

export enum ClientStatusEnum {
    Initialized = 0,
    DetailsComplete = 1,
    AddressComplete = 2,
    ContactsComplete = 3,
    SettlementComplete = 4,
    AccountingComplete = 5,
    MerchantProcessorComplete = 6,
    ApprovalPending = 7,
    ApprovalRejected = 8,
    Complete = 9
}

export enum DepartmentStatusEnum {
    Initialized = 0,
    DetailsComplete = 1,
    AddressComplete = 2,
    ContactsComplete = 3,
    SettlementComplete = 4,
    AccountingComplete = 5,
    MerchantProcessorComplete = 6,
    ApprovalPending = 7,
    ApprovalRejected = 8,
    Complete = 9
}

export enum BankAccountToUseEnum {
    GilaDeposit = 1,
    GilaChargeback = 2
}

export enum ContactTypeEnum {
    Primary = 1,
    CustomerService = 2,
}

export enum AddressTypeEnum {
    Physical = 1,
    TerminalDeployment = 2
}

export enum OwnershipTypeEnum {
    Unknown = 0,
    PublicCorporation = 1,
    PrivateCorporation = 2,
    SECRegulatedCorporation = 3,
    Government = 4,
    SoleProprietorship = 5,
    LLC = 6,
    Partnership = 7,
    NonProfit = 8,
    FinancialInstitution = 9,
    Trust = 10
}

export enum BusinessTypeEnum {
    Unknown = 0,
    AutoRental = 1,
    ECommerce = 2,
    Lodging = 3,
    MOTO = 4,
    Restaurant = 5,
    Retail = 6
}

export enum SettlementTypeEnum {
    Unknown = 0,
    Deposit = 1,
    Chargeback = 2,
}
export enum AccountDDATypeEnum {
    Checking = 1,
    Savings = 2
}

export enum AccountACHTypeEnum {
    Commercial = 1,
    Private = 2
}

export enum AccountCategoryEnum {
    Invalid = 0,
    CreditDeposits = 1,
    CreditChargebacks = 2,
    CreditProcessingFees = 3,
    DebitDeposits = 4,
    DebitChargeBacks = 5,
    DebitConvenienceFees = 6,
    CreditConvenienceFees = 7,
}

export enum BatchCloseMethodEnum {
    Unknown = 0,
    TimeInitiated = 1,
    MerchantInitiated = 2
}

export enum CheckDuplicatesEnum {
    Yes = 1,
    No = 2
}

export enum DebitFeeMethodEnum {
    DirectDebit = 1,
    BillOut = 2,
    Net = 3
}

export enum FeeBurdenMethodEnum {
    ConvenienceFee = 1,
    ClientAbsorbed = 2
}

export enum FundingMethodEnum {
    SameDay = 1,
    NextDay = 2,
    TwoDay = 3,
    Other = 4
}
export enum SignaturePromptEnum {
    Never = 1,
    UseThreshold = 2,
}

export enum YesNoEnum {
    NotProvided = 0,
    Yes = 1,
    No = 2,
    NotSure = 3
}

export enum GoodsSoldEnum {
    NotProvided = 0,
    Bonds = 1
}

export enum RefundPolicyEnum {
    NotProvided = 0,
    NoRefund = 1,
    Refund30Days = 2,
    Other = 3
}

export enum ChargedOnCardEnum {
    NotProvided = 0,
    Order = 1,
    Shipment = 2
}

export enum DeliveredAsEnum {
    NotProvided = 0,
    Digitially = 1,
    Physically = 2,
    Both = 3
}

export class BankAccount 
{
    msbId?: string;
    accountDDATypeEnum: AccountDDATypeEnum = AccountDDATypeEnum.Checking;
    bankAccountNumber:string = "";
    bankRoutingNumber:string = "";
}

export enum WebChannelTypeEnum{
    None = 0,
    BlindPayment = 1,
    ShoppingCart = 2,
    WebIntegration = 3
}

