import { useEffect, useRef } from "react";
import { connect, useDispatch } from "react-redux";
import { useLocation, useParams } from "react-router-dom";
import { IAppState } from "../../redux/storeTypes";
import { Container, Box, ThemeProvider, CssBaseline } from '@mui/material';
import Header from "../checkout/Header";
import Invoice from "../checkout/Invoice";
import Summary from "../checkout/Summary";
import PayBy from "../checkout/PayBy";
import Memo from "../checkout/Memo";
import Footer from "../checkout/Footer";
import { Checkout, CheckoutStatus, CheckoutView } from "../../models/CheckoutLink";
import { processCheckoutAction, statusCheckoutAction, viewCheckoutAction } from "../../redux/actions/checkout";
import { Order } from "../../models/Order";
import { updateOrderDetailsAction } from "../../redux/actions/orderManagement";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import { getPaymentChannelAction } from "../../redux/actions/paymentChannelWebApplications";
import { PaymentChannel } from "../../models/Client";
import createCustomTheme from "../../components/theme";

export interface IParams {
    id: string
}

export interface ICheckoutPageProps {
    order: Order;
    paymentChannel: PaymentChannel;
    siteKey: string;
    checkout: Checkout;
}

const CheckoutPage = ({ order, paymentChannel, siteKey, checkout }: ICheckoutPageProps) => {
    const actionToken = "CheckoutPage"
    const location = useLocation();
    const dispatch = useDispatch();
    const origin = useRef("");
    const { id }: IParams = useParams();

    const message = (msg: any) => {
        window.parent.postMessage(JSON.stringify(msg, null, 4), origin.current);
    }

    const listenerOptions = { capture: true, once: false, passive: true };

    const responder = (e: { origin: any; data: string; }) => {
        if (e.data === id) {
            origin.current = e.origin;
            window.removeEventListener("message", responder, listenerOptions);
        }
    };

    useEffect(() => {
        if (id) window.addEventListener("message", responder, listenerOptions);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id]);

    useEffect(() => {
        if (id) {
            const e = new Uint8Array(Buffer.from(id, 'hex'));
            window.crypto.subtle.importKey('raw', e, { name: 'AES-CBC', length: 256 }, false, ['decrypt'])
                .then(k => {
                    window.crypto.subtle.decrypt({ name: 'AES-CBC', iv: e.slice(0, 16) }, k, Buffer.from(new URLSearchParams(location.search).get("context")!, 'hex'))
                        .then(d => {
                            const params = new TextDecoder().decode(d);
                            dispatch(processCheckoutAction(JSON.parse(params.substring(params.indexOf('{"'))) as Checkout, actionToken));
                        })
                        .catch(error => { console.error('error:', error); });
                })
                .catch(error => {
                    console.error('import error:', error);
                });
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id]);

    useEffect(() => {
        if (checkout) {
            dispatch(statusCheckoutAction(new CheckoutStatus()));
            dispatch(viewCheckoutAction(CheckoutView.NONE));
            const ids = JSON.parse(checkout.iDs);
            dispatch(getPaymentChannelAction(ids.paymentChannelId));
            const _order = new Order();
            dispatch(updateOrderDetailsAction(_order, actionToken));
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [checkout]);


    if (window.self !== window.top && checkout && order && paymentChannel) {
        const customTheme = createCustomTheme({
            mode: checkout.style?.Mode,
            fontSize: checkout.style?.Font?.Size,
            fontFamily: checkout.style?.Font?.Family,
            colorBackground: checkout.style?.Color?.Background,
            colorPrimary: checkout.style?.Color?.Primary
        });
        return (
            <ThemeProvider theme={customTheme}>
                <CssBaseline />
                <Container maxWidth="sm">
                    <Box sx={{ my: 0, mx: 0 }}>
                        <Header />
                        <Invoice />
                        <PayBy />
                        <GoogleReCaptchaProvider reCaptchaKey={siteKey} useRecaptchaNet={true}>
                            <Summary msg={message} />
                        </GoogleReCaptchaProvider>
                        <Memo />
                        <Footer />
                    </Box>
                </Container>
            </ThemeProvider>
        )
    } else {
        return (<></>)
    }
};

const mapStateToProps = (state: IAppState) => {
    return {
        order: state.orderManagement.order,
        paymentChannel: state.paymentChannelWebApplication.paymentChannel,
        siteKey: state.webAppSettings.siteKey,
        checkout: state.checkout.checkout,
    };
};

export default connect(mapStateToProps)(CheckoutPage);

