
import React from "react";
import { Container } from 'react-bootstrap';
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { IAppState } from "../redux/storeTypes";
import { Routes } from "../routes";
import ContactPage from "../pages/ContactPage";

export interface IContactPageProps {
}



const Contact = ({ }: IContactPageProps) => {
  return (
    <main className="marketing">
      <ContactPage />
    </main>
  );
};

const mapStateToProps = (state: IAppState) => {
  return {
  };
};

export default connect(mapStateToProps)(Contact);

