import { Row, Col, Spinner, Form } from "react-bootstrap"
import Table from '../../../../../../components/Table';
import { IAppState } from "../../../../../../redux/storeTypes";
import { connect, useDispatch } from "react-redux";
import { ItemStatusType, Order } from "../../../../../../models/Order";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCartPlus, faShoppingCart, faCheck, faInfoCircle, faTimes } from "@fortawesome/free-solid-svg-icons";
import { useEffect, useState } from "react";
import { updateOrderDetailsAction, updateShoppingCart } from "../../../../../../redux/actions/orderManagement";

interface ILarimerSearchResults {
    citationNumberLabel: string,
    fullNameLabel: string,
    issueDateLabel: string,
    amountDueLabel: string,
    statusLabel: string,
    actionsLabel: string,
    addToCartLabel: string,
    addToCartIconColor: string,
    inCartLabel: string,
    inCartIconColor: string,
    observesWeekends: boolean,
    durationOfPendingHours: number,
    margin: string,
    padding: string,
    headerBackgroundColor: string,
    headerPadding: string,
    shoppingCart: any,
    order: Order,
    itemDetails: any,
    isFetching: boolean
}

export const CITATION_NUMBER_LABEL = 'Citation number';
export const FULL_NAME_LABEL = 'Full Name';
export const ISSUE_DATE_LABEL = 'Issue Date';
export const AMOUNT_DUE_LABEL = 'Amount';
export const STATUS_LABEL = 'Status';
export const ACTIONS_LABEL = 'Actions';
export const ADD_TO_CART_LABEL = 'Add To Cart';
export const ADD_TO_CART_ICON_COLOR = '#007bff';
export const IN_CART_ICON_COLOR = '#28a745';
export const IN_CART_LABEL = 'In Cart';
export const OBSERVES_WEEKENDS = false;
export const DURATION_OF_PENDING_HOURS = 24;
export const MARGIN = '0px';
export const PADDING = '0px';
export const HEADER_BACKGROUND_COLOR = 'rgb(250, 250, 250)';
export const HEADER_PADDING = '0';

export const DateFormatter = (date: any, row: any) => {
    if (date) {
        const dateString = moment(date).format('MM/DD/YYYY');
        return (
            <span>{dateString}</span>
        );
    }
}


const checkStyleForDefault = (style: string, defaultStyle: string) => {
    return style && style !== '' ? style : defaultStyle;
}

const LarimerSearchResults = ({ citationNumberLabel, fullNameLabel, issueDateLabel, amountDueLabel, statusLabel, actionsLabel,
    addToCartLabel, addToCartIconColor, inCartLabel,inCartIconColor,durationOfPendingHours, observesWeekends, 
    margin, padding, headerBackgroundColor, headerPadding, shoppingCart, order, itemDetails, isFetching }: ILarimerSearchResults) => {

    if (!citationNumberLabel) { citationNumberLabel = CITATION_NUMBER_LABEL }
    if (!fullNameLabel) { fullNameLabel = FULL_NAME_LABEL }
    if (!issueDateLabel) { issueDateLabel = ISSUE_DATE_LABEL }
    if (!amountDueLabel) { amountDueLabel = AMOUNT_DUE_LABEL }
    if (!statusLabel) { statusLabel = STATUS_LABEL }
    if (!actionsLabel) { actionsLabel = ACTIONS_LABEL }
    if (!addToCartLabel) { addToCartLabel = ADD_TO_CART_LABEL }
    if (!addToCartIconColor) { addToCartIconColor = ADD_TO_CART_ICON_COLOR }
    if (!inCartLabel) { inCartLabel = IN_CART_LABEL }
    if (!inCartIconColor) { inCartIconColor = IN_CART_ICON_COLOR }
    if (!durationOfPendingHours) { durationOfPendingHours = DURATION_OF_PENDING_HOURS }
    if (!observesWeekends) { observesWeekends = OBSERVES_WEEKENDS }
    if (!margin) { margin = MARGIN }
    if (!padding) { padding = PADDING }
    if (!headerBackgroundColor) { headerBackgroundColor = HEADER_BACKGROUND_COLOR }
    if (!headerPadding) { headerPadding = HEADER_PADDING }

    const [selectedRow, setSelectedRow] = useState<any>();
    const dispatch = useDispatch();
    const [data, setData] = useState<any>([]);

    const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
    });

    const amountFormatter = (cell: any) => {
        return (<span>{formatter.format(cell)}</span>)
    }

    const statusFormatter = (cell: any, row: any) => {
        const statusValue = (row?.itemPaymentStatus == ItemStatusType.Paid || row?.metaData?.TotalDue == 0) ? 'Paid' : row?.itemPaymentStatus == ItemStatusType.Pending ? 'Pending' : 'Unpaid';
        let color: string = "orange";
        if (statusValue === 'Paid') { color = "#52C41A" }
        if (statusValue === 'Pending') { color = "#f5b759" }
        if (statusValue === 'Unpaid') { color = "#F5222D" }
        return (
            <span style={{ display: 'flex', alignItems: 'center' }}>
                <FontAwesomeIcon icon={statusValue === 'Paid' ? faCheck : statusValue === 'Pending' ? faInfoCircle : faTimes} className="btn-icon" style={{ color: `${color}` }} />
                {statusValue}
            </span>
        )
    }

    const actionFormatter = (cell: any, row: any) => {
        const isViewable = row?.itemPaymentStatus != ItemStatusType.Pending && row?.itemPaymentStatus != ItemStatusType.Paid && row?.metaData?.TotalDue > 0;
        if (isViewable) {
            if (row?.addedToCart) {
                return (
                    <span><FontAwesomeIcon color={inCartIconColor} icon={faCartPlus} /> {inCartLabel}</span>
                )
            } else {
                return (
                    <span><a onClick={() => { setSelectedRow(row) }}>
                        <FontAwesomeIcon color={addToCartIconColor} icon={faShoppingCart} /> {addToCartLabel}
                    </a></span>
                )
            }
        }
    }

    useEffect(() => {
        if (selectedRow) {
            const shoppingCartObject = [
                {
                    citationNumber: selectedRow?.metaData?.CitationNumber,
                    name: selectedRow?.metaData?.Name,
                    issueDate: selectedRow?.metaData?.IssueDate,
                    id: selectedRow?.metaData?.ReferenceNumber,
                    paymentAmount: Number(selectedRow?.metaData?.TotalDue),
                }];
            const OrderLinesObject = [
                {
                    itemReferenceNumber: selectedRow?.metaData?.ReferenceNumber,
                    quantity: 1,
                    unitPrice: Number(selectedRow?.metaData?.TotalDue),
                    itemMetadata: {
                        uiReferenceId: selectedRow?.metaData?.ReferenceNumber,
                        statusUpdate: true,
                        itemMsbid: selectedRow?.msbId,
                        citationNumber: selectedRow?.metaData?.CitationNumber,
                        name: selectedRow?.metaData?.Name,
                        issueDate: selectedRow?.metaData?.IssueDate,
                        observesWeekends: observesWeekends,
                        durationOfPendingHours: durationOfPendingHours
                    },
                    itemName: 'Citation',
                }];
            if (order) {
                const updatedOrderLines = order?.orderLines.filter((orderLine: any) => !OrderLinesObject.some((selected: any) => orderLine?.itemMetadata?.uiReferenceId === selected?.itemMetadata?.uiReferenceId)).concat(OrderLinesObject);
                const updatedShoppingCart = shoppingCart.filter((cart: any) => !shoppingCartObject.some((selected: any) => cart?.id === selected?.id)).concat(shoppingCartObject);
                order.methodName = "Sale";
                order.orderLines = updatedOrderLines;
                dispatch(updateShoppingCart(updatedShoppingCart, 'shoppingCartActionToken'));
                dispatch(updateOrderDetailsAction(order, 'actionToken'));
            }
            else {
                let _order = new Order();
                _order.methodName = "Sale";
                _order.orderLines = OrderLinesObject;
                dispatch(updateShoppingCart(shoppingCartObject, 'shoppingCartActionToken'));
                dispatch(updateOrderDetailsAction(_order, 'actionToken'));
            }
        }
    }, [selectedRow]);

    useEffect(() => {
        if (itemDetails) {
            const modifiedDetails = itemDetails?.map((details: any) => ({
                ...details,
                addedToCart: shoppingCart?.map((cart: any) => cart.id).includes(details?.metaData?.ReferenceNumber)
            }))
            setData(modifiedDetails);
            setColumns(initialColumns);
            setSelectedRow(null)
        }
    }, [itemDetails, shoppingCart])

    const initialColumns = [{
        dataField: 'metaData.CitationNumber',
        text: citationNumberLabel,
        editable: false,
        headerStyle: {
            backgroundColor: checkStyleForDefault(headerBackgroundColor, HEADER_BACKGROUND_COLOR)
        },
    }, {
        dataField: 'metaData.Name',
        text: fullNameLabel,
        editable: false,
        headerStyle: {
            backgroundColor: checkStyleForDefault(headerBackgroundColor, HEADER_BACKGROUND_COLOR)
        },
        sort: true,
    }, {
        dataField: 'metaData.IssueDate',
        text: issueDateLabel,
        headerStyle: {
            backgroundColor: checkStyleForDefault(headerBackgroundColor, HEADER_BACKGROUND_COLOR)
        },
        editable: false,
        formatter: DateFormatter,
        sort: true
    }, {
        dataField: 'metaData.TotalDue',
        text: amountDueLabel,
        headerStyle: {
            backgroundColor: checkStyleForDefault(headerBackgroundColor, HEADER_BACKGROUND_COLOR)
        },
        editable: false,
        formatter: amountFormatter,

    }, {
        dataField: 'status',
        text: statusLabel,
        headerStyle: {
            backgroundColor: checkStyleForDefault(headerBackgroundColor, HEADER_BACKGROUND_COLOR)
        },
        formatter: statusFormatter,
        editable: false,
    }, {
        dataField: 'id',
        text: actionsLabel,
        headerStyle: {
            width : '120px', textAlign: 'center', backgroundColor: checkStyleForDefault(headerBackgroundColor, HEADER_BACKGROUND_COLOR)
        },
        formatter: actionFormatter,
        editable: false,
        style: { width : '120px', textAlign: 'center'}
    }];

    const [columns, setColumns] = useState<any>(initialColumns);

    const renderTable = () => {
        if (isFetching) {
            return (<div style={{ textAlign: 'center' }} ><Spinner animation="border" /> </div>);
        }
        else if (data.length === 0) {
            return (<div>Currently there are no records to display. Please do a search to find the record you are looking for.</div>)
        } else {
            return (
                <>
                    <Table
                        keyField="msbId"
                        data={data}
                        columns={columns}
                        remote={false}
                        sizePerPage={5}
                    />
                </>);
        }
    };

    return (

        <div style={{ margin: margin, padding: padding }}>
            <Row>
                <Col>
                    {renderTable()}
                </Col>
            </Row>
        </div>

    )
}


const mapStateToProps = (state: IAppState) => {
    return {
        isFetching: state.account.isFetching,
        shoppingCart: state.orderManagement.shoppingCart,
        order: state.orderManagement.order,
        itemDetails: state.account.itemDetails,
    };
};

export default connect(mapStateToProps)(LarimerSearchResults);