import { Row, Col, Form, Button, Modal, ListGroup, Dropdown, Spinner } from "react-bootstrap"

import { useEffect, useState } from "react";
import FormHeaderConfirmation from "../../../../../../components/FormHeaderConfirmation";
import icoWarning from "../../../../../../assets/img/icons/ico-warning-outline.svg";
import { connect, useDispatch } from "react-redux";
import { IActionResult, IAppState } from "../../../../../../redux/storeTypes";
import { User } from "../../../../../../models/User";
import {
    calculateConvenienceFee, CALCULATE_CONVENIENCE_FEE, CALCULATE_CONVENIENCE_FEE_FAILURE, CALCULATE_CONVENIENCE_FEE_SUCCESS, deleteScheduledPayments, updatedScheduledPayment,
    UPDATED_SCHEDULE_PAYMENT_FAILURE, UPDATED_SCHEDULE_PAYMENT_REQUEST, UPDATED_SCHEDULE_PAYMENT_SUCCESS,
    userScheduledItems
} from "../../../../../../redux/actions/account";
import { DateWithoutUTCFormatter, CurrencyFormatter } from '../../../../../../components/Formatters';
import Table from '../../../../../../components/Table';
import moment from "moment";
import { Order, PaymentType } from "../../../../../../models/Order";
import { PreFlightEmailScheduleType, Schedule, ScheduledPortionType } from "../../../../../../models/Schedule";
import store from "../../../../../../redux/store";
import { handleEmail, handlePayLoad } from "./Payload";
import { sendErrorToastAction, sendSuccessToastAction } from "../../../../../../redux/actions/toast";
import { Client, Department, PaymentChannel } from "../../../../../../models/Client";
import icoError from "../../../../../../assets/img/icons/ico-error-outline.svg";
import HeaderBar from "../../../../../../components/HeaderBar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircle } from "@fortawesome/free-solid-svg-icons";
import { printOnlyModal } from "./ItemSearch";
import { faTimesCircle } from "@fortawesome/pro-light-svg-icons";

interface IUserScheduledPayments {
    scheduledDateLabel: string,
    accountIdLabel: string,
    paymentTypeLabel: string,
    convenienceFeeLabel: string,
    totalAmountScheduledLabel: string,
    statusLabel: string,
    scheduledPaymentOptionLabel: string,
    amountDueLabel: string,
    actionLabel: string,
    scheduledDate: string,
    accountId: string,
    paymentOption: string,
    paymentType: string,
    amountDue: string,
    convenienceFee: string,
    totalAmountScheduled: string,
    status: string,
    action: any,
    ownersLabel: string,
    addressLabel: string,
    paymentDateLabel: string,
    paymentOptionLabel: string,
    paymentMethodLabel: string,
    paymentReminderDateLabel: string,
    emailAddressLabel: string,
    emailAddressPlaceholder: string,
    userDetails: User,
    scheduledItems: any,
    currentSchedule: any,
    headerBackgroundColor: string,
    actionResult: IActionResult,
    calculatedConvenienceFee: Order,
    errorMessage: string,
    isFetching: boolean,
    isSaving: boolean,
    scheduledPaymentsLoading: boolean,
    client: Client,
    department: Department,
    paymentChannel: PaymentChannel
}

export const SCHEDULED_DATE_LABEL = 'Scheduled Date';
export const ACCOUNT_ID_LABEL = 'Account ID';
export const PAYMENT_OPTION_LABEL = 'Payment Option'
export const PAYMENT_TYPE_LABEL = 'Payment Type';
export const CONVENIENCE_FEE_LABEL = 'Convenience Fee';
export const AMOUNT_DUE_LABEL = 'Amount Due';
export const TOTAL_AMOUNT_SCHEDULED_LABEL = 'Total Amount Scheduled';
export const STATUS_LABEL = 'Status';
export const ACTIONS_LABEL = 'Actions';
export const OWNERS_LABEL = 'Owners';
export const ADDRESS_LABEL = 'Address';
export const PAYMENT_DATE_LABEL = 'Select Future Payment Date';
export const SCHEDULED_PAYMENT_OPTION_LABEL = 'Select Payment Option';
export const PAYMENT_METHOD_LABEL = 'Select Payment Method';
export const PAYMENT_REMINDER_DATE_LABEL = 'Select Payment Reminder';
export const EMAIL_ADDRESS_LABEL = 'Email Address';
export const EMAIL_ADDRESS_PLACEHOLDER = 'Enter Email Address'
export const HEADER_BACKGROUND_COLOR = 'rgb(250, 250, 250)';
export const HEADER_PADDING = '0';

const checkStyleForDefault = (style: string, defaultStyle: string) => {
    return style && style !== '' ? style : defaultStyle;
}

const UserScheduledPayments = ({ scheduledDateLabel, accountIdLabel, paymentTypeLabel, convenienceFeeLabel, totalAmountScheduledLabel, statusLabel, scheduledPaymentOptionLabel,
    amountDueLabel, actionLabel, ownersLabel, addressLabel, paymentDateLabel, paymentOptionLabel, paymentMethodLabel, headerBackgroundColor, userDetails, scheduledItems, currentSchedule,
    paymentReminderDateLabel, emailAddressLabel, emailAddressPlaceholder, actionResult, calculatedConvenienceFee, errorMessage, isFetching, scheduledPaymentsLoading, isSaving, client, department, paymentChannel }: IUserScheduledPayments) => {

    const actionToken = "ScheduledPayments";
    const dispatch = useDispatch();
    const daysDiffernce = (selectedDate: any, todaysDate: any) => { return Math.ceil(Math.abs(selectedDate - todaysDate) / (1000 * 60 * 60 * 24)) };

    const [scheduleModal, showScheduleModal] = useState<boolean>(false);
    const [rowData, setRowData] = useState<any>([]);
    const [deleteModal, setDeleteModal] = useState<boolean>(false);
    const [data, setData] = useState<any>([]);
    const [validated, setValidated] = useState(false);
    const [paymentDate, setPaymentDate] = useState<string>('');
    const [paymentType, setPaymentType] = useState<any>('');
    const [paymentOption, setPaymentOption] = useState<any>();
    const [paymentDetails, setPaymentDetails] = useState<any>([]);
    const [deleteValidation, setDeleteValidation] = useState<boolean>(false);
    const [paymentReminder, setPaymentReminder] = useState<any>();
    const [emailAddress, setEmailAddress] = useState<string>('');
    const [customErrorMessage, setCustomErrorMessage] = useState<string>('');
    const [paymentReminderOptions, setPaymentReminderOptions] = useState<any>([
        PreFlightEmailScheduleType.OneDays,
        PreFlightEmailScheduleType.TwoDays,
        PreFlightEmailScheduleType.ThreeDays,
        PreFlightEmailScheduleType.FourDays,
        PreFlightEmailScheduleType.FiveDays,
        PreFlightEmailScheduleType.SixDays,
        PreFlightEmailScheduleType.SevenDays
    ]
    );
    const [agreeToAchTerms, setAgreeToAchTerms] = useState<boolean>(true);
    const [calculationError, setCalculationError] = useState<boolean>(false);

    if (!scheduledDateLabel) { scheduledDateLabel = SCHEDULED_DATE_LABEL }
    if (!accountIdLabel) { accountIdLabel = ACCOUNT_ID_LABEL }
    if (!paymentOptionLabel) { paymentOptionLabel = PAYMENT_OPTION_LABEL }
    if (!paymentTypeLabel) { paymentTypeLabel = PAYMENT_TYPE_LABEL }
    if (!convenienceFeeLabel) { convenienceFeeLabel = CONVENIENCE_FEE_LABEL }
    if (!amountDueLabel) { amountDueLabel = AMOUNT_DUE_LABEL }
    if (!totalAmountScheduledLabel) { totalAmountScheduledLabel = TOTAL_AMOUNT_SCHEDULED_LABEL }
    if (!statusLabel) { statusLabel = STATUS_LABEL }
    if (!actionLabel) { actionLabel = ACTIONS_LABEL }
    if (!ownersLabel) { ownersLabel = OWNERS_LABEL }
    if (!addressLabel) { addressLabel = ADDRESS_LABEL }
    if (!paymentDateLabel) { paymentDateLabel = PAYMENT_DATE_LABEL }
    if (!scheduledPaymentOptionLabel) { scheduledPaymentOptionLabel = SCHEDULED_PAYMENT_OPTION_LABEL }
    if (!paymentMethodLabel) { paymentMethodLabel = PAYMENT_METHOD_LABEL }
    if (!paymentReminderDateLabel) { paymentReminderDateLabel = PAYMENT_REMINDER_DATE_LABEL }
    if (!emailAddressLabel) { emailAddressLabel = EMAIL_ADDRESS_LABEL }
    if (!emailAddressPlaceholder) { emailAddressPlaceholder = EMAIL_ADDRESS_PLACEHOLDER }

    const actionsFormatter = (cell: any, row: any) => {
        return (
            <Dropdown>
                <Dropdown.Toggle disabled={!row?.paymentWorkItem?.nextRun} variant="link" id="manage-client-dropdown">Manage</Dropdown.Toggle>
                <Dropdown.Menu>
                    <Dropdown.Item onSelect={() => { setValidated(false); showScheduleModal(true); setCustomErrorMessage(''); setRowData(row) }}>Edit Schedule</Dropdown.Item>
                    <Dropdown.Item onSelect={() => { setValidated(false); checkDeleteValidation(row); setRowData(row) }}>Delete Schedule</Dropdown.Item>
                </Dropdown.Menu>
            </Dropdown>
        )
    }

    const amountDueFormatter = (cell: any, row: any) => {
        const amountDue = row?.scheduledPortionType === "FirstHalf" ? row?.item?.metaData?.FirstHalfDue :
            row?.scheduledPortionType === "SecondHalf" ? row?.item?.metaData?.SecondHalfDue :
                row?.item?.metaData?.GrandTotal
        return (
            <span>{CurrencyFormatter(amountDue)}</span>
        )
    }

    const ScheduledDateFormatter = (cell: any, row: any) => {
        if (row?.paymentWorkItem?.completionResults?.length > 0) {
            return <span>{DateWithoutUTCFormatter(row?.paymentWorkItem?.completionResults[0].finishedAt)}</span>
        }
        return <span>{DateWithoutUTCFormatter(cell)}</span>
    }

    const statusFormatter = (cell: any, row: any) => {

        let color: string = "#F5222D";
        if (cell === 'Pending') { color = "#FADB14" }
        else if (cell === 'Success') { color = "#52C41A" }
        return (
            <span style={{ display: 'flex', alignItems: 'center' }}><FontAwesomeIcon icon={faCircle} size="xs" className="btn-icon" style={{ color: `${color}` }} />{cell}</span>
        )
    }

    const checkDeleteValidation = (rowDetails: any) => {
        const items = scheduledItems.map((details: any) => details?.item?.externalItemReference)
        const exisitingItem = items.some(function (item: any, secondItem: any) {
            return items.indexOf(item) != secondItem && item === rowDetails?.item?.externalItemReference
        });
        if (items.length > 1 && rowDetails?.scheduledPortionType === 'FirstHalf' && exisitingItem) {
            setDeleteValidation(true);
        }
        else { setDeleteModal(true); }
    }

    const reminderOptionsCalculation = (selectedDate: any) => {
        const maximumDays = daysDiffernce(new Date(selectedDate), new Date());
        const reminderOptions = Array.from({ length: maximumDays }, (_, i) => i + 1).slice(0, 7);
        return reminderOptions
    }

    const columns = [{
        dataField: 'paymentWorkItem.nextRun',
        text: scheduledDateLabel,
        editable: false,
        headerStyle: {
            backgroundColor: checkStyleForDefault(headerBackgroundColor, HEADER_BACKGROUND_COLOR)
        },
        formatter: ScheduledDateFormatter,
        sort: true
    }, {
        dataField: 'item.externalItemReference',
        text: accountIdLabel,
        editable: false,
        headerStyle: {
            backgroundColor: checkStyleForDefault(headerBackgroundColor, HEADER_BACKGROUND_COLOR)
        },
        sort: true
    }, {
        dataField: 'scheduledPortionType',
        text: paymentOptionLabel,
        editable: false,
        headerStyle: {
            backgroundColor: checkStyleForDefault(headerBackgroundColor, HEADER_BACKGROUND_COLOR)
        },
        sort: true
    }, {
        dataField: 'paymentWorkItem.apiWorkInformation.payload.paymentType',
        text: paymentTypeLabel,
        headerStyle: {
            backgroundColor: checkStyleForDefault(headerBackgroundColor, HEADER_BACKGROUND_COLOR)
        },
        editable: false
    }, {
        dataField: 'paymentWorkItem.apiWorkInformation.payload.convenienceFee',
        text: convenienceFeeLabel,
        headerStyle: {
            backgroundColor: checkStyleForDefault(headerBackgroundColor, HEADER_BACKGROUND_COLOR)
        },
        formatter: CurrencyFormatter,
        editable: false
    }, {
        dataField: 'amountDue',
        text: amountDueLabel,
        formatter: amountDueFormatter,
        headerStyle: {
            backgroundColor: checkStyleForDefault(headerBackgroundColor, HEADER_BACKGROUND_COLOR)
        },
        editable: false
    }, {
        dataField: 'paymentWorkItem.apiWorkInformation.payload.totalAmount',
        text: totalAmountScheduledLabel,
        formatter: CurrencyFormatter,
        headerStyle: {
            backgroundColor: checkStyleForDefault(headerBackgroundColor, HEADER_BACKGROUND_COLOR)
        },
        editable: false
    }, {
        dataField: 'paymentScheduleStatus',
        text: statusLabel,
        formatter: statusFormatter,
        headerStyle: {
            backgroundColor: checkStyleForDefault(headerBackgroundColor, HEADER_BACKGROUND_COLOR)
        },
        editable: false
    }, {
        dataField: 'id',
        text: actionLabel,
        formatter: actionsFormatter,
        headerStyle: {
            backgroundColor: checkStyleForDefault(headerBackgroundColor, HEADER_BACKGROUND_COLOR)
        },
        editable: false,
    }];

    const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
    });

    useEffect(() => {
        if (userDetails) {
            setPaymentDetails(userDetails);
            dispatch(userScheduledItems(userDetails.msbId!, 'ScheduledPayments'));
        }
    }, [userDetails, currentSchedule]);

    useEffect(() => {
        if (scheduledItems) {
            setData(scheduledItems)
        }
    }, [scheduledItems]);


    useEffect(() => {
        if (actionResult && actionResult.result) {
            if (actionResult.type === CALCULATE_CONVENIENCE_FEE) {
                setCalculationError(false);
                if (actionResult.result === CALCULATE_CONVENIENCE_FEE_SUCCESS) {
                    setCalculationError(false);
                } else if (actionResult.result === CALCULATE_CONVENIENCE_FEE_FAILURE) {
                    setCalculationError(true);
                }
            }
            if (actionResult.type === UPDATED_SCHEDULE_PAYMENT_REQUEST) {
                if (actionResult.result === UPDATED_SCHEDULE_PAYMENT_SUCCESS) {
                    dispatch(sendSuccessToastAction("Scheduled payment has been successfully updated"));
                    showScheduleModal(false);
                    setRowData('');
                } else if (actionResult.result === UPDATED_SCHEDULE_PAYMENT_FAILURE) {
                    dispatch(sendErrorToastAction("Scheduled payment could not be updated."));
                }
            }
        }
    }, [actionResult]);

    useEffect(() => {
        if (rowData) {
            setPaymentDate(moment(rowData?.paymentWorkItem?.nextRun).format('YYYY-MM-DD'));
            setPaymentOption(rowData?.scheduledPortionType);
            setPaymentType(PaymentType[rowData?.paymentWorkItem?.apiWorkInformation?.payload?.paymentType]);
            setPaymentReminder(PreFlightEmailScheduleType[rowData?.preFlightEmailScheduleType])
            const email = rowData?.preFlightWorkItem?.emailWorkInformation?.recipientAddresses[0];
            setEmailAddress(email ? email : userDetails?.email);
            const reminderOptions = reminderOptionsCalculation(rowData?.paymentWorkItem?.nextRun);
            setPaymentReminderOptions(reminderOptions);
        }
    }, [rowData]);


    useEffect(() => {
        if (paymentOption) {
            const amount = paymentOption == ScheduledPortionType[2] ? rowData?.item?.metaData.FirstHalfDue
                : paymentOption == ScheduledPortionType[3] ? rowData?.item?.metaData.SecondHalfDue
                    : paymentOption == ScheduledPortionType[1] ? rowData?.item?.metaData.GrandTotal
                        : paymentOption;

            const ConvenienceFee = {
                paymentType: paymentType,
                methodName: 'Sale',
                cardType: paymentType == PaymentType.CreditCard ? userDetails?.cards && userDetails?.cards[0]?.internalCardType : 'ElectronicCheck',
                orderLines: [{ "ItemReferenceNumber": rowData?.item?.metaData.AccountNumber, "ItemId": rowData?.item?.msbId, "UnitPrice": amount, "Quantity": 1 }]
            }
            dispatch(calculateConvenienceFee(ConvenienceFee, actionToken));
        }
    }, [paymentOption, paymentType]);


    const setErrorToastAction = (selectedOption: any, dueDate: string, customMessage?: string) => {
        setPaymentDate(moment(rowData?.paymentWorkItem?.nextRun).format('YYYY-MM-DD'))
        customMessage ?
            setCustomErrorMessage(`${customMessage}`) :
            setCustomErrorMessage(`Cannot schedule a ${ScheduledPortionType[selectedOption].replace(/([A-Z])/g, ' $1').trim()} payment after the due date, i.e. ${moment(dueDate).format('MM/DD/YYYY')}`);
    }

    const handlePaymentDateChange = (e: any) => {

        const selectedDate = e.target.value;
        const presentDate = new Date();
        if (new Date(selectedDate) < presentDate) {
            setPaymentDate(moment(rowData?.paymentWorkItem?.nextRun).format('YYYY-MM-DD'))
            setCustomErrorMessage(`Scheduled payments must be after ${moment(presentDate).format('MM/DD/YYYY')}`);
            return false;
        }
        if (paymentOption === ScheduledPortionType[2] && new Date(selectedDate) > new Date(rowData?.item?.metaData?.DateRangeFirstPO)) {
            setErrorToastAction(2, rowData?.item?.metaData?.DateRangeFirstPO)
            return false;
        }
        if (paymentOption === ScheduledPortionType[3]) {
            if (new Date(selectedDate) > new Date(rowData?.item?.metaData?.DateRangeSecondPO)) {
                setErrorToastAction(3, rowData?.item?.metaData?.DateRangeSecondPO)
                return false;
            }
            const firstHalfScheduledDate = scheduledItems?.map((item: any) =>
                (item.scheduledPortionType === "FirstHalf" && item?.item?.externalItemReference === rowData?.item?.externalItemReference) ?
                    item?.paymentWorkItem?.nextRun : null).filter(Boolean);
            if (firstHalfScheduledDate && new Date(selectedDate) < new Date(firstHalfScheduledDate)) {
                setErrorToastAction(3, rowData?.metaData?.DateRangeSecondPO, 'Second Half payment cannot be scheduled before the First Half payment')
                return false;
            }
        }
        if (paymentOption === ScheduledPortionType[1] && new Date(selectedDate) > new Date(rowData?.item?.metaData?.DateRangeFullPO)) {
            setErrorToastAction(1, rowData?.item?.metaData?.DateRangeFullPO)
            return false;
        }
        setPaymentDate(e.target.value);
        setCustomErrorMessage('');
        const reminderOptions = reminderOptionsCalculation(selectedDate)
        setPaymentReminderOptions(reminderOptions);
    }

    const dynamicOptions = paymentReminderOptions.map((item: any) => {
        return (
            <option key={item} value={PreFlightEmailScheduleType[item]}>
                {item === 1 ? `${item} day before` : `${item} days before`}
            </option>

        )
    })

    const handleSubmit = (event: any) => {
        const form = event.currentTarget;
        event.preventDefault();
        if (form.checkValidity() !== false) {
            let schedule = new Schedule();
            const paymentReminderDate = form.elements.paymentReminderDate.value;

            schedule.userIdentifier = userDetails.msbId;
            schedule.itemIdentifier = rowData?.msbId;

            const dateFormat = new Date(paymentDate);
            dateFormat.setHours(dateFormat.getHours() + 4);
            schedule.paymentDate = dateFormat.toISOString();

            schedule.recipientAddresses = [emailAddress];
            schedule.paymentOption = paymentOption!;
            schedule.preFlightEmailScheduleType = paymentReminderDate ? paymentReminderDate : PreFlightEmailScheduleType.NoReminder;
            schedule.paymentType = paymentType!;
            schedule.paymentRequestHeaders.ClientId = paymentChannel.department.client.msbId!;
            schedule.paymentRequestHeaders.DepartmentId = paymentChannel.department.msbId!;
            schedule.paymentRequestHeaders.PaymentChannelId = paymentChannel.msbId!;

            const payloadDetails = { paymentType: paymentType!, paymentOption: paymentOption!, calculatedConvenienceFee: calculatedConvenienceFee, userDetails: userDetails, emailAddress: emailAddress }
            const payLoad = handlePayLoad(payloadDetails);
            payLoad.clientId = paymentChannel.department.client.msbId!;
            payLoad.departmentId = paymentChannel.department.msbId!;
            payLoad.paymentChannelId = paymentChannel.msbId!;
            schedule.orderRequestPayload = payLoad;

            if (paymentReminderDate != PreFlightEmailScheduleType.NoReminder) {
                const emailDetails = {
                    accountNumber: rowData?.externalItemReference,
                    paymentType: paymentType!,
                    paymentOption: paymentOption!,
                    paymentAmount: calculatedConvenienceFee.totalAmount,
                    userDetails: userDetails,
                    paymentDate: paymentDate,
                    paymentReminderDate: PreFlightEmailScheduleType[paymentReminderDate],
                    clientName: client?.businessName,
                    departmentDetails: department
                }

                const emailModel = handleEmail(emailDetails);
                emailModel.clientId = paymentChannel.department.client.msbId!;
                emailModel.departmentId = paymentChannel.department.msbId!;
                emailModel.paymentChannelId = paymentChannel.msbId!;
                emailModel.externalItemReference = rowData?.item?.externalItemReference;
                emailModel.paymentType = paymentType!;

                schedule.preFlightEmailModel = emailModel;
                dispatch(updatedScheduledPayment(rowData?.msbId, schedule, actionToken));
            } else {
                const { preFlightEmailModel, ...scheduleReset } = schedule;
                dispatch(updatedScheduledPayment(rowData?.msbId, scheduleReset, actionToken));
            }
        }
        setValidated(true);
    };

    const renderTable = () => {
        if (scheduledPaymentsLoading) {
            return (<div style={{ textAlign: 'center' }} ><Spinner animation="border" /> </div>);
        } else {
            return (
                <Table
                    keyField="msbId"
                    data={data}
                    columns={columns}
                    remote={false}
                />);
        }
    };

    const renderPaymentSchedule = () => {
        return (
            <Form id='updatedSchedulePayments' className="mt-0" noValidate validated={validated} onSubmit={handleSubmit}>
                <ListGroup variant="flush">
                    <ListGroup.Item className="p-0">
                        <div>
                            <div className="details_title">
                                <h2>{`Edit ${rowData?.item?.metaData?.AccountNumber} Payment Schedule`}</h2>
                                <p className="mb-4">Below are the details for this account.</p>
                            </div>
                            <div className="details_description">
                                <h3>{accountIdLabel}</h3>
                                <span>{rowData?.item?.metaData?.AccountNumber}</span>
                            </div>
                            <div className="details_description">
                                <h3>{ownersLabel}</h3>
                                <span>{rowData?.item?.metaData?.OwnerName}</span>
                            </div>
                            <div className="details_description">
                                <h3>{addressLabel}</h3>
                                <span>{rowData?.item?.metaData?.Address1}</span>
                            </div>

                        </div>
                    </ListGroup.Item>
                    <hr />
                    <ListGroup.Item className="p-0">
                        <div className="details_title">
                            <h2>Payment Schedule</h2>
                            <p>Please select the future payment date, payment method and payment option.</p>
                            <p style={{ color: '#F5222D', marginTop: '8px' }}><strong>Statutory due dates:</strong></p>
                            <ul style={{ color: '#F5222D' }}>
                                <li><strong>1st Half: Last Day of February</strong></li>
                                <li><strong>Full: April 30th</strong></li>
                                <li><strong>2nd Half: June 17th</strong></li>
                            </ul>
                        </div>
                        <div>
                            {customErrorMessage.length != 0 &&
                                <Form.Group as={Row} className="mb-3">
                                    <HeaderBar backgroundColor='#FFFBE6' message={customErrorMessage} icon={icoWarning} />
                                </Form.Group>
                            }
                            <Form.Group as={Row} controlId="paymentDate" className="mb-3">
                                <Form.Label column>{paymentDateLabel}</Form.Label>
                                <Col>
                                    <Form.Control type="Date" required value={paymentDate} onKeyDown={(e: any) => { e.preventDefault() }} onChange={handlePaymentDateChange} />
                                </Col>
                            </Form.Group>
                            <Form.Group as={Row} controlId="paymentType" className="mb-3">
                                <Form.Label column>{paymentMethodLabel}</Form.Label>
                                <Col>
                                    <Form.Control as="select" required value={paymentType} onChange={(e) => setPaymentType(e.target.value)}>
                                        <option value="">Select payment method</option>
                                        {paymentDetails?.cards && paymentDetails?.cards.length > 0 && <option value={PaymentType.CreditCard}>Credit Card</option>}
                                        {paymentDetails?.bankAccounts && paymentDetails?.bankAccounts.length > 0 && <option value={PaymentType.ECheck}>eCheck</option>}
                                    </Form.Control>
                                </Col>
                            </Form.Group>
                            <Form.Group as={Row} controlId="paymentOption" className="mb-3" >
                                <Form.Label column>{paymentOptionLabel}</Form.Label>
                                <Col>
                                    <Form.Control as="select" required disabled={true} value={paymentOption} >
                                        <option value={ScheduledPortionType[0]} >Select payment option</option>
                                        <option value={ScheduledPortionType[2]}>{`First Half (${formatter.format(rowData?.item?.metaData?.FirstHalfDue)})`}</option>
                                        <option value={ScheduledPortionType[3]}>{`Second Half (${formatter.format(rowData?.item?.metaData?.SecondHalfDue)})`}</option>
                                        <option value={ScheduledPortionType[1]}>{`Full Payment (${formatter.format(rowData?.item?.metaData?.GrandTotal)})`}</option>
                                    </Form.Control>
                                </Col>
                            </Form.Group>
                            {!isFetching ? <>
                                {calculatedConvenienceFee?.convenienceFee > 0 &&
                                    <Form.Group as={Row} controlId="ConvenieceFee" className="mb-3 d-flex align-content-center">
                                        <Form.Label column>{convenienceFeeLabel}</Form.Label>
                                        <Col>
                                            <span>{formatter.format(calculatedConvenienceFee.convenienceFee)}</span>
                                        </Col>
                                    </Form.Group>
                                } </> : <div style={{ textAlign: 'center', marginTop: '1.5rem' }} ><Spinner animation="border" /> </div>}
                            <p style={{ color: '#F5222D', marginTop: '24px', marginBottom: '8px' }}><strong>Future payments can only be scheduled on or before statutory due dates. For a same day payment, logout and click the "Make Payment" button.</strong></p>
                            <p style={{ color: '#141414' }}><strong>Returned payments will be charged a $20 fee. Late payments will incur late interest of 1% per month.</strong></p>
                        </div>
                        <hr />
                    </ListGroup.Item>
                    <ListGroup.Item className="p-0">
                        <div className="details_title">
                            <h2>Payment Reminder Notification</h2>
                            <p>Please select how many days to be notified before your scheduled payment and enter in your email address below to send payment reminders to.</p>
                        </div>
                        <div>
                            <Form.Group as={Row} controlId="paymentReminderDate" className="mb-3">
                                <Form.Label column>{paymentReminderDateLabel}</Form.Label>
                                <Col>
                                    <Form.Control as="select" value={PreFlightEmailScheduleType[paymentReminder]}
                                        disabled={!paymentDate}
                                        onChange={(e: any) => setPaymentReminder(PreFlightEmailScheduleType[e.target.value])}
                                    >
                                        <option value={PreFlightEmailScheduleType.NoReminder} >No reminder</option>
                                        {dynamicOptions}
                                    </Form.Control>
                                </Col>
                            </Form.Group>
                            <Form.Group as={Row} controlId="email" className="mb-3">
                                <Form.Label column>{emailAddressLabel}</Form.Label>
                                <Col>
                                    <Form.Control required maxLength={50} type="email" placeholder={emailAddressPlaceholder} value={emailAddress} onChange={(e) => setEmailAddress(e.target.value)} />
                                    <Form.Control.Feedback type="invalid">Please enter a valid email address.</Form.Control.Feedback>
                                </Col>
                            </Form.Group>
                            {(paymentType == PaymentType.ECheck) && paymentOption ?
                                <Form.Group as={Row} className="mb-3" controlId="formBasicCheckbox">
                                    <Form.Check type="checkbox" disabled={isSaving} checked={agreeToAchTerms} onChange={(e) => setAgreeToAchTerms(e.target.checked)}
                                        label={
                                            <span>
                                                By checking here, I authorize Municipal Services Bureau to initiate a single ACH electronic debit to my account ending in {userDetails?.bankAccounts && userDetails?.bankAccounts[0]?.accountNumber?.substr(-4)} in the 
                                                amount of {formatter.format(calculatedConvenienceFee?.totalAmount)} on {moment(paymentDate).format('MM/DD/YYYY')}.
                                                I agree that ACH transactions I authorize comply with all applicable laws.
                                                Once payment is authorized, there cannot be any changes or corrections. I understand that I may call during normal business hours if I have any questions.
                                                It is recommended that you {<a onClick={() => printOnlyModal()} href="#">print</a>} a copy of this authorization and maintain it for your records.
                                            </span>
                                        } />
                                </Form.Group>
                                : null
                            }
                            <Form.Group controlId="saveSchedule" className="form-footer mb-0 d-print-none">
                                <Button form='updatedSchedulePayments' type="submit" disabled={!calculatedConvenienceFee || isSaving || isFetching || (paymentType == PaymentType.ECheck && !agreeToAchTerms)}>
                                    Save
                                </Button>
                                <Button form='updatedSchedulePayments' style={{ marginLeft: 'auto' }} disabled={isSaving} variant="link"
                                    onClick={() => { showScheduleModal(false); setRowData([]) }}>
                                    Cancel
                                </Button>
                            </Form.Group>
                            {(calculationError && errorMessage) ?
                                <div className="alert alert-danger mb-0 mt-2 d-flex align-items-start" role="alert" id="paymentErrorBox">
                                    <FontAwesomeIcon icon={faTimesCircle} size="sm" />{errorMessage}
                                </div>
                                :
                                <></>
                            }
                        </div>
                    </ListGroup.Item>
                </ListGroup>
            </Form>
        )
    }

    return (
        <>
            <Modal show={scheduleModal} id="section-to-print" size="lg" onHide={() => showScheduleModal(false)} backdrop="static">
                <Modal.Header closeButton />
                <Modal.Body>
                    {renderPaymentSchedule()}
                </Modal.Body>
            </Modal>

            <Modal show={deleteValidation} className="modal-confirmation">
                <Modal.Body>
                    <FormHeaderConfirmation iconImg={icoError} title="Unable to delete scheduled payment" />
                    <div className="confirmation-body">
                        <p>In order to delete the scheduled First Half payment, you must delete the scheduled Second Half payment first.</p>
                        <Form.Group className="confirmation-footer">
                            <Button onClick={() => setDeleteValidation(false)}>
                                Close
                            </Button>
                        </Form.Group>
                    </div>
                </Modal.Body>
            </Modal>
            <Modal show={deleteModal} className="modal-confirmation">
                <Modal.Body>
                    <FormHeaderConfirmation iconImg={icoWarning} title="Delete scheduled payment" />
                    <div className="confirmation-body">
                        <p>Are you sure you would like to delete this scheduled payment?</p>
                        <Form.Group className="confirmation-footer">
                            <Button variant="outline-secondary" onClick={() => setDeleteModal(false)}>
                                Cancel
                            </Button>
                            <Button onClick={() => { dispatch(deleteScheduledPayments(rowData?.msbId)); setDeleteModal(false); }}>
                                Confirm
                            </Button>
                        </Form.Group>
                    </div>
                </Modal.Body>
            </Modal>
            <Row>
                <Col>
                    {renderTable()}
                </Col>
            </Row>
        </>
    )
}

const mapStateToProps = (state: IAppState) => {
    return {
        isFetching: state.account.isFetching,
        isSaving: state.account.isSaving,
        scheduledPaymentsLoading: state.account.scheduledPaymentsLoading,
        itemDetails: state.account.itemDetails,
        actionResult: state.account.actionResult,
        userDetails: state.account.user,
        currentSchedule: state.account.currentSchedule,
        scheduledItems: state.account.scheduledItems,
        calculatedConvenienceFee: state.account.order,
        errorMessage: state.account.errorMessage,
        client: state.clients.client,
        department: state.clients.department,
        paymentChannel: state.paymentChannelWebApplication.paymentChannel
    };
};

export default connect(mapStateToProps)(UserScheduledPayments);

