import {
    SET_REDIRECT_REQUEST
} from '../actions/redirectIntegration';

export default function redirectIntegration(state = {
    isSaving: false,
    redirectRequest: null,
    actionResult: null
}, { type, payload }) {
    switch (type) {
        case SET_REDIRECT_REQUEST:
            return Object.assign({}, state, {
                redirectRequest: payload.redirectRequest
            });
        default:
            return state;
    }
}


