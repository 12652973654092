import { Paper, TextField } from "@mui/material";
import { Checkout } from "../../models/CheckoutLink";
import { IAppState } from "../../redux/storeTypes";
import { connect } from "react-redux";

export interface IMemoProps {
    checkout: Checkout;
}

const Memo = ({ checkout }: IMemoProps) => {
    return (
        <>
        {checkout &&  checkout.memo &&
        <Paper style={{ padding: "8px" }}>
            <TextField type="text" label="Memo" multiline value={checkout.memo} style={{ width: "100%" }} inputProps={{ readOnly: true, }} size="small" />
        </Paper>}
        </>
    );
};

const mapStateToProps = (state: IAppState) => {
    return {
        checkout: state.checkout.checkout,
    };
};

export default connect(mapStateToProps)(Memo);