import axios from 'axios';
import { WebAppSettings } from '../../models/WebAppSettings';
import { Dispatch } from 'react';
import { PublicClientApplication } from '@azure/msal-browser';

export const SET_CONFIG = 'SET_CONFIG';
export const SET_PUBLIC_CLIENT_APPLICATION = 'SET_PUBLIC_CLIENT_APPLICATION';
export const GET_WEB_APP_SETTINGS_REQUEST = 'GET_WEB_APP_SETTINGS_REQUEST';
export const GET_WEB_APP_SETTINGS_SUCCESS = 'GET_WEB_APP_SETTINGS_SUCCESS';
export const GET_WEB_APP_SETTINGS_FAILURE = 'GET_WEB_APP_SETTINGS_FAILURE';

export const setConfigAction = (environment:string, baseApiUrl:string, clientApiMock?:boolean) => (dispatch: Dispatch<any>) => {
    dispatch({
        type: SET_CONFIG,
        payload: {
            environment,
            baseApiUrl,
            clientApiMock,
        }
    });
};

export const setPublicClientApplication = (publicClientApplication:PublicClientApplication) => (dispatch: Dispatch<any>) => {
    dispatch({
        type: SET_PUBLIC_CLIENT_APPLICATION,
        payload: {
            publicClientApplication
        }
    });
};

export const getWebAppSettingsAction = (actionToken?:string) => (dispatch:Dispatch<any>) => {
    dispatch({
        type: GET_WEB_APP_SETTINGS_REQUEST,
        payload: {
            actionToken
        }
    });

    /*
    let webAppSettings = new WebAppSettings();
    webAppSettings.environment = "DEV";
    webAppSettings.wsmBaseUrl = "http://nwnifimsb010";
    webAppSettings.wsmBearerToken = "123";

    dispatch({
        type: GET_WEB_APP_SETTINGS_SUCCESS,
        payload: {
            webAppSettings,
            actionToken
        }
    });
    */

    
    let _url = window.location.protocol + "//" + window.location.host + "/nexus/settings";
    let webAppSettings = new WebAppSettings();

    axios.post(_url)
        .then(function (response) {
            webAppSettings = response.data;
            dispatch({
                type: GET_WEB_APP_SETTINGS_SUCCESS,
                payload: {
                    webAppSettings,
                    actionToken
                }
            });
        })
        .catch(function (error) {
            console.log(error);
            dispatch({
                type: GET_WEB_APP_SETTINGS_FAILURE,
                payload: {
                    error,
                    actionToken
                }
            });
        })
        .then(function () {
        }); 
        
};