import React from 'react';
import { Link } from 'react-router-dom';
import { Container, Col, Nav, Row, Form, Button, Tab, Card } from 'react-bootstrap';
import { Routes } from '../routes';
import logo from '../assets/img/logo.svg';
import processDiagram from '../assets/img/process-diagram-fpo.png';
import suiteImg1 from '../assets/img/nexus-optics-fpo.png';
import suiteImg2 from '../assets/img/suiteImg2.png';
import suiteImg3 from '../assets/img/suiteImg3.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChartArea, faCloud, faCodeBranch, faDollarSign, faEye, faGlobe, faNetworkWired, faShieldAlt, faThLarge } from '@fortawesome/free-solid-svg-icons';

const ProductPage = () => {

    const setRoute = (path:string) => {
        return path.replace(":id", "Demo");
    }

    return (
        <>
            <Container>
                <header className="d-flex flex-wrap align-items-center justify-content-center justify-content-md-between py-3">
                    <a href="/" className="d-flex align-items-center col-md-3 mb-2 mb-md-0 text-dark text-decoration-none">
                        <img src={logo} alt="MSB Logo" />
                    </a>
                    <ul className="nav col-12 col-md-auto mb-2 justify-content-center mb-md-0">
                        <li className="nav-item">
                            <Link to={setRoute(Routes.Product.path)} className="nav-link px-3">Product</Link>
                        </li>
                        {/* <li className="nav-item">
                            <Link to={setRoute(Routes.PAboutroduct.path)} className="nav-link px-3">About</Link>
                        </li> */}
                        <li className="nav-item">
                            <Link to={setRoute(Routes.Contact.path)} className="nav-link px-3">Contact</Link>
                        </li>
                        {/* <li className="nav-item"><a href="/status.html" className="nav-link px-3">Status</a></li> */}
                    </ul>
                </header>
            </Container>

            <Container fluid className="gray-2">
                <Row className="py-5 text-center">
                  <Col>
                    <Container>
                      <Row>
                        <Col>
                          <h1 className="display-3 fw-bold px-4">How MSB Payment System works</h1>
                          <div className="col-lg-9 col-sm-12 mx-auto">
                              <p className="lead mt-3 mb-5">We partner with your agency to provide audited, redundant, and compliant payment solutions that deliver exceptional user experiences for your organization’s most mission-critical functions. We make sure your payment systems are on-brand and beautiful.</p>
                          </div>
                          <div className="overflow-hidden mt-5">
                              <div className="container p-5">
                                  <img src={processDiagram} className="img-fluid" alt="MSB Process" width={1100} height={502} loading="lazy" />
                              </div>
                          </div>
                        </Col>
                      </Row>
                    </Container>
                  </Col>
                </Row>
            </Container>

            <Container>
              <Row className="py-5">
                <Col>
                  <Tab.Container id="tabs-product-feature" defaultActiveKey="first">
                    <Row>
                      <Col lg={6} sm={12}>
                        <Row>
                          <Col>
                            <h1 className="display-3 fw-bold mb-4">Powerful suite of tools</h1>
                            <p className="lead mb-4">Municipal Services Bureau (MSB) offers outsourced third-party debt collection and call
                            center services to government entities nationwide. MSB operates as a financial services company for municipalities in billing, notification and payment processing of delinquent municipal fees and fines.</p>
                          </Col>
                        </Row>
                        <Nav className="flex-column product-feature-tabs">
                          <Nav.Item>
                            <Nav.Link eventKey="first">
                              <div className="tab-label">
                                <h5 className="fw-bold">Nexus Optics</h5>
                                <p>Fully-managed notification environment</p>
                              </div>
                              <div className="tab-icon">
                                <FontAwesomeIcon icon={faEye}  />
                              </div>
                            </Nav.Link>
                          </Nav.Item>
                          <Nav.Item>
                            <Nav.Link eventKey="second">
                              <div className="tab-label">
                                <h5 className="fw-bold">Nexus Cloud</h5>
                                <p>Secure, scalable digital government cloud</p>
                              </div>
                              <div className="tab-icon">
                                <FontAwesomeIcon icon={faCloud} />
                              </div>
                            </Nav.Link>
                          </Nav.Item>
                          <Nav.Item>
                            <Nav.Link eventKey="third">
                              <div className="tab-label">
                                <h5 className="fw-bold">OpenSaaS Technology</h5>
                                <p>Complete open sourced software-as-a-service</p>
                              </div>
                              <div className="tab-icon">
                                <FontAwesomeIcon icon={faNetworkWired} />
                              </div>
                            </Nav.Link>
                          </Nav.Item>
                        </Nav>
                      </Col>
                      <Col lg={6} sm={12}>
                        <Tab.Content>
                          <Tab.Pane eventKey="first">
                            <img src={suiteImg1} className="img-fluid rounded-3" alt="MSB Payment System" width={1100} height={502} loading="lazy" />
                          </Tab.Pane>
                          <Tab.Pane eventKey="second">
                            <img src={suiteImg3} className="img-fluid rounded-3 shadow-lg" alt="MSB Payment System" width={1100} height={502} loading="lazy" />
                          </Tab.Pane>
                          <Tab.Pane eventKey="third">
                            <img src={suiteImg2} className="img-fluid rounded-3" alt="MSB Payment System" width={1100} height={502} loading="lazy" />
                          </Tab.Pane>
                        </Tab.Content>
                      </Col>
                    </Row>
                  </Tab.Container>
                </Col>
              </Row>
            </Container>

            <Container fluid className="gray-2">
                <Row className="py-5 text-center">
                  <Col>
                    <Container>
                      <div className="explore-solutions">
                        <Row>
                          <Col>
                            <h1 className="display-3 fw-bold px-4">Explore the solutions</h1>
                            <div className="col-lg-9 col-sm-12 mx-auto">
                                <p className="lead mt-3 mb-5">Discover innovative, secure technologies designed to quickly and effortlessly move your agency forward.</p>
                            </div>
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <div className="solution-cards">
                              <Card className="text-center">
                                <Card.Body>
                                  <div className="solution-icon">
                                    <FontAwesomeIcon icon={faThLarge} />
                                  </div>
                                  <Card.Title className="fw-bold">Onboarding</Card.Title>
                                  <Card.Text>
                                     Streamline digital government deployment in minutes, not days
                                  </Card.Text>
                                </Card.Body>
                              </Card>
                              <Card className="text-center">
                                <Card.Body>
                                  <div className="solution-icon">
                                    <FontAwesomeIcon icon={faDollarSign} />
                                  </div>
                                  <Card.Title className="fw-bold">Payments</Card.Title>
                                  <Card.Text>
                                    Easy set up, launch and manage government web and mobile payments
                                  </Card.Text>
                                </Card.Body>
                              </Card>
                              <Card className="text-center">
                                <Card.Body>
                                  <div className="solution-icon">
                                    <FontAwesomeIcon icon={faChartArea} />
                                  </div>
                                  <Card.Title className="fw-bold">Reporting</Card.Title>
                                  <Card.Text>
                                    Easy download and time-saving report scheduling
                                  </Card.Text>
                                </Card.Body>
                              </Card>
                              <Card className="text-center">
                                <Card.Body>
                                  <div className="solution-icon">
                                    <FontAwesomeIcon icon={faGlobe} />
                                  </div>
                                  <Card.Title className="fw-bold">Websites</Card.Title>
                                  <Card.Text>
                                    Responsive framework for a clean, interactive user experience 
                                  </Card.Text>
                                </Card.Body>
                              </Card>
                              <Card className="text-center">
                                <Card.Body>
                                  <div className="solution-icon">
                                    <FontAwesomeIcon icon={faCodeBranch} />
                                  </div>
                                  <Card.Title className="fw-bold">CMS</Card.Title>
                                  <Card.Text>
                                    Organize, write and edit digital government website content
                                  </Card.Text>
                                </Card.Body>
                              </Card>
                              <Card className="text-center">
                                <Card.Body>
                                  <div className="solution-icon">
                                    <FontAwesomeIcon icon={faShieldAlt} />
                                  </div>
                                  <Card.Title className="fw-bold">Security</Card.Title>
                                  <Card.Text>
                                    Industry best practices for security and maintenance
                                  </Card.Text>
                                </Card.Body>
                              </Card>
                            </div>
                          </Col>
                        </Row>
                      </div>
                    </Container>
                  </Col>
                </Row>
            </Container>

            <Container>
                <Row className="py-5 my-5">
                    <Col lg={12}>
                        <div className="cta-blue">
                            <div className="cta-text">
                                <h3 className="display-4">Ready to get started?</h3>
                                <p className="lead">Discover innovative, secure technologies designed to move your agency forward.</p>
                            </div>
                            <div className="cta-button">
                              
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>

            <footer className="gradient-2">
                <div className="container">
                    <div className="row py-5">
                        <div className="col-md-6 col-sm-12">
                            <a href="#" className="me-3">Privacy Policy</a>
                            <a href="#">Terms of Use</a>
                        </div>
                        <div className="col-md-3 col-sm-12">
                            <h5 className="fw-bold">Address</h5>
                            <p className="dark-gray">Municipal Services Bureau<br />
                                325 Daniel Zenker Drive<br />
                                Suite 3<br />
                                Horseheads, NY 14845
                            </p>
                        </div>
                        <div className="col-md-3 col-sm-12">
                            <h5 className="fw-bold">Contact</h5>
                            <p className="dark-gray">
                                Monday &ndash; Friday<br />
                                8:00 am EST &ndash; 12:00 am EST<br />
                                Phone: 1-800-616-0166<br />
                                Fax: 1-800-756-4110
                            </p>
                        </div>
                    </div>
                </div>
            </footer>
            <footer className="footer">
                <div className="container">
                    <div className="row pt-5 mb-5">
                        <div className="col-12">
                            <p className="gray-7">© 2021 Gila, LLC d/b/a Municipal Services Bureau (MSB) is a registered ISO of Fifth Third
                            Bank, N.A.
                            Cincinnati, OH. All rights reserved. The MSB logo is a registered service mark of Gila, LLC. Gila, LLC is a
                            subsidiary of Navient Corporation. Navient Corporation and its subsidiaries, including Gila, LLC, are not
                    sponsored by or agencies of the United States of America.</p>
                        </div>
                    </div>
                </div>
            </footer>
        </>
    );
}
export default ProductPage;