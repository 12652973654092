import { NumbersOutlined, ChecklistOutlined, CurrencyExchangeOutlined, AccountBalanceOutlined } from "@mui/icons-material";
import { Box, Grid, TextField, Button, InputAdornment, FormControlLabel, Checkbox, Typography, useTheme } from "@mui/material";
import { IAppState } from "../../redux/storeTypes";
import { Checkout, Billing, Contact, CheckoutStatus } from "../../models/CheckoutLink";
import { connect, useDispatch } from "react-redux";
import { useEffect, useRef } from "react";
import { Order, PaymentCardTypeEnum, PaymentType } from "../../models/Order";
import { CheckoutBilling } from "./CheckoutBilling";
import { clearBankName, getBankName, simpleCalculateOrderAction, updateOrderDetailsAction } from "../../redux/actions/orderManagement";
import { statusCheckoutAction } from "../../redux/actions/checkout";

export interface IPayByECheckProps {
    order: Order;
    bankName: string;
    checkout: Checkout;
    status: CheckoutStatus;
    setStatus: any;
}

const PayByECheck = ({ order, bankName, checkout, status, setStatus }: IPayByECheckProps) => {
    const dispatch = useDispatch();
    const theme = useTheme();
    const accountNumber = useRef<any>("");
    const confirmNumber = useRef<any>("");
    const routingNumber = useRef<any>("");
    const institution = useRef<any>("");
    const tokenizingRequested = useRef<any>(false);


    const billingName = useRef<any>("");
    const billingAddress1 = useRef<any>("");
    const billingAddress2 = useRef<any>("");
    const billingCity = useRef<any>("");
    const billingState = useRef<any>("");
    const billingZip = useRef<any>("");
    const billingCountry = useRef<any>("");
    const billingPhone = useRef<any>("");
    const billingEmail = useRef<any>("");

    useEffect(() => {        
        if (bankName) {
            institution.current.value = bankName;
            institution.current.setCustomValidity("");
        } else {
            institution.current.value = "";
            institution.current.setCustomValidity("Ensure valid routing number");
        }
    }, [bankName]);

    const validate = (event: any) => {
        event.preventDefault();
        const form = event.currentTarget;

        if (form.reportValidity()) {
            order.methodName = "Sale";
            order.isMailOrTelephoneOrder = false;
            order.addressLine1 = billingAddress1.current.value
            order.addressLine2 = billingAddress2.current.value;
            order.city = billingCity.current.value;
            order.state = billingState.current.value;
            order.zip = billingZip.current.value;
            order.country = billingCountry.current.value;
            order.phone = billingPhone.current.value?.replace(/[^0-9]/g, "") || "";
            order.emailAddress = billingEmail.current.value;
            order.initiatedBy = checkout.initiatedBy;
            order.paymentType = PaymentType.ECheck;
            order.cardType = PaymentCardTypeEnum.ElectronicCheck;
            order.nameOnCard = billingName.current.value;
            order.creditCardNumber = "";
            order.expirationDate = "";
            order.cvv = "";
            order.eCheckAccountHolderName = billingName.current.value;
            order.eCheckAccountNumber = accountNumber.current.value;
            order.eCheckRoutingNumber = routingNumber.current.value;
            order.externalCustomerReference = checkout.uniqueId;
            const ids = JSON.parse(checkout.iDs);
            order.clientMsbId = ids.clientId;
            order.departmentMsbId = ids.departmentId;
            order.paymentChannelMsbId = ids.paymentChannelId;
            order.shouldTokenizeCard = tokenizingRequested.current.checked;
            order.isImplicitAuthorization = tokenizingRequested.current.checked;
            dispatch(updateOrderDetailsAction(order, "CheckoutPage"));
            dispatch(simpleCalculateOrderAction("CheckoutPage"));
            setStatus();
        }
    }

    const handleChange = () => {
        if (status.payBy) dispatch(statusCheckoutAction({ ...status, payBy: false }));
    }

    return (
        <form onSubmit={validate} onChange={handleChange}>
            <Grid container spacing="3" style={{ paddingTop: "8px" }}>
                <Grid item xs>
                    <TextField
                        className={'blurry fs-mask'}
                        required
                        label="Account Number"
                        fullWidth
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <NumbersOutlined sx={{ color: "lightgrey" }} />
                                </InputAdornment>
                            ),
                        }}
                        size="small"
                        autoComplete="off"
                        onKeyPress={(event) => { if (!/[0-9]/.test(event.key)) { event.preventDefault(); } }}
                        onBlur={(e) => {
                            if (e.target.value.length > 0 && confirmNumber.current.value.length > 0) {
                                if (e.target.value !== confirmNumber.current.value) {
                                    e.target.setCustomValidity("Ensure account numbers match");
                                } else {
                                    e.target.setCustomValidity("");
                                    confirmNumber.current.setCustomValidity("");
                                }
                            }
                        }}
                        onInvalid={(e) => {
                            let target = e.target as HTMLInputElement;
                            if (target.validity.tooShort) target.setCustomValidity("Please complete this entry")
                        }}
                        onInput={(e) => {
                            let target = e.target as HTMLInputElement;
                            if (target.validity.customError && target.validity.tooShort) {
                                target.setCustomValidity("Please complete this entry")
                            } else {
                                target.setCustomValidity("")
                            }
                        }}
                        inputProps={{ minLength: 4, maxLength: 17, }}
                        inputRef={accountNumber}
                    />
                </Grid>
            </Grid>
            <Grid container spacing="3" style={{ paddingTop: "8px" }}>
                <Grid item xs>
                    <TextField
                        className={'blurry fs-mask'}
                        required
                        label="Confirm Account Number"
                        fullWidth
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <ChecklistOutlined sx={{ color: "lightgrey" }} />
                                </InputAdornment>
                            ),
                        }}
                        size="small"
                        autoComplete="off"
                        onCut={(event) => event.preventDefault()}
                        onCopy={(event) => event.preventDefault()}
                        onPaste={(event) => event.preventDefault()}
                        onKeyPress={(event) => { if (!/[0-9]/.test(event.key)) { event.preventDefault(); } }}
                        onBlur={(e) => {
                            if (e.target.value.length > 0 && accountNumber.current.value.length > 0) {
                                if (e.target.value !== accountNumber.current.value) {
                                    e.target.setCustomValidity("Ensure account numbers match");
                                } else {
                                    e.target.setCustomValidity("");
                                    accountNumber.current.setCustomValidity("");
                                }
                            }
                        }}
                        onInvalid={(e) => {
                            let target = e.target as HTMLInputElement;
                            if (target.validity.tooShort) target.setCustomValidity("Please complete this entry")
                        }}
                        onInput={(e) => {
                            let target = e.target as HTMLInputElement;
                            if (target.validity.customError && target.validity.tooShort) {
                                target.setCustomValidity("Please complete this entry")
                            } else {
                                target.setCustomValidity("")
                            }
                        }}
                        inputProps={{ minLength: 4, maxLength: 17, }}
                        inputRef={confirmNumber}
                    />
                </Grid>
            </Grid>
            <Grid container spacing="3" style={{ paddingTop: "8px" }}>
                <Grid item xs>
                    <TextField
                        className={'blurry fs-mask'}
                        required
                        label="Routing Number"
                        fullWidth
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <CurrencyExchangeOutlined sx={{ color: "lightgrey" }} />
                                </InputAdornment>
                            ),
                        }}
                        size="small"
                        autoComplete="off"
                        onKeyPress={(event) => { if (!/[0-9]/.test(event.key)) { event.preventDefault(); } }}
                        onBlur={(e) => {
                            if (e.target.value.length >= 9) {
                                dispatch(getBankName(e.target.value));
                            } else {
                                dispatch(clearBankName());
                            }
                        }}
                        onInvalid={(e) => {
                            let target = e.target as HTMLInputElement;
                            if (target.validity.tooShort) target.setCustomValidity("Please complete this entry")
                        }}
                        onInput={(e) => {
                            let target = e.target as HTMLInputElement;
                            if (target.validity.customError && target.validity.tooShort) {
                                target.setCustomValidity("Please complete this entry")
                            } else {
                                target.setCustomValidity("")
                            }
                        }}
                        inputProps={{ minLength: 9, maxLength: 9 }}
                        inputRef={routingNumber}
                    />
                </Grid>
            </Grid>
            <Grid container spacing="3" style={{ paddingTop: "8px" }}>
                <Grid item xs>
                    <TextField
                        required
                        label="Banking Institution"
                        fullWidth
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <AccountBalanceOutlined sx={{ color: "lightgrey" }} />
                                </InputAdornment>
                            ),
                            sx: {
                                backgroundColor: theme.palette.action.hover,
                            }
                        }}
                        size="small"
                        onKeyDown={(event) => { event.preventDefault() }}
                        inputProps={{ readOnly: false, tabIndex: -1 }}
                        inputRef={institution}
                    />
                </Grid>
            </Grid>
            <CheckoutBilling
                payerName={checkout.payerName}
                billing={checkout.billing || new Billing()}
                contact={checkout.contact || new Contact()}
                addressRequirement={checkout.eCheckAddressRequirement}
                contactRequirement={checkout.eCheckContactRequirement}
                billingName={billingName}
                billingAddress1={billingAddress1}
                billingAddress2={billingAddress2}
                billingCity={billingCity}
                billingState={billingState}
                billingZip={billingZip}
                billingCountry={billingCountry}
                billingPhone={billingPhone}
                billingEmail={billingEmail}
            />
            {checkout.offerTokenizing &&
            <Box component="div" textAlign={'center'} marginTop={'8px'} sx={{ backgroundColor: theme.palette.action.hover, }}>
                <FormControlLabel control={<Checkbox size="small" inputRef={tokenizingRequested} />} label={<Typography sx={{ mx: 0 }} fontSize={'0.9em'} color={theme.palette.text.primary}>Save account for future use.</Typography>} />
            </Box>}
            <Box sx={{ textAlign: 'center', paddingTop: '8px' }}>
                <Button type="submit" fullWidth variant="contained" size='small' color="primary">CONTINUE</Button>
            </Box>                        
        </form>
    );
};

const mapStateToProps = (state: IAppState) => {
    return {
        order: state.orderManagement.order,
        bankName: state.orderManagement.bankName,
        checkout: state.checkout.checkout,
        status: state.checkout.status,
    };
};

export default connect(mapStateToProps)(PayByECheck);

  