import React from "react";
import { Button, ButtonToolbar, Container, ListGroup } from 'react-bootstrap';
import { connect } from "react-redux";
import { IAppState } from "../redux/storeTypes";
import PageSectionContainer from "../components/layout/PageSectionContainer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import PageHeader from "../components/layout/PageHeader";

export interface IReceipt {
}

const Receipt = ({ }: IReceipt) => {
  return (
    <main>
      <PageHeader />
      <Container className="mt-4">
        <PageSectionContainer>
        <ListGroup variant="flush">
          <ListGroup.Item>
            <div className="receiptDetails">
              <div className="receiptTitleContainer">
                <FontAwesomeIcon icon={faCheckCircle} style={{ color: "#52C41A" }} size="4x" />
                <h5 className="receiptTitle">Payment successful</h5>
                <span className="receiptClient">Gila LLC / MSB Receipt</span>
              </div>
              <div className="receiptBody">
                <div className="receiptDetail">
                  <h6>Confirmation ID</h6>
                  <span>123456</span>
                </div>
                <div className="receiptDetail">
                  <h6>Transaction ID</h6>
                  <span>CC531499</span>
                </div>
                <div className="receiptDetail">
                  <h6>Transaction date</h6>
                  <span>5/27/2021 02:23:42 PM</span>
                </div>
                <div className="receiptDetail">
                  <h6>Payment Channel</h6>
                  <span>County Sheriff VPOS</span>
                </div>
                <div className="receiptDetail">
                  <h6>Reference number</h6>
                  <span>111946042021</span>
                </div>
                <div className="receiptDetail">
                  <h6>Product type</h6>
                  <span>Bond</span>
                </div>
                <div className="receiptDetail">
                  <h6>Payment type</h6>
                  <span>Credit Card</span>
                </div>
                <div className="receiptDetail">
                  <h6>Card type</h6>
                  <span>Master Card - Debit</span>
                </div>
                <div className="receiptDetail">
                  <h6>Last 4 digits of credit card</h6>
                  <span>4242</span>
                </div>
                <div className="receiptDetail">
                  <h6>Name</h6>
                  <span>Jane Doe</span>
                </div>
                <div className="receiptDetail">
                  <h6>City, State Zip code</h6>
                  <span>Austin, Texas 78789</span>
                </div>
                <div className="receiptDetail">
                  <h6>Subtotal</h6>
                  <span>$200.00</span>
                </div>
                <div className="receiptDetail">
                  <h6>Convenience fee</h6>
                  <span>$0.12</span>
                </div>
                <div className="receiptDetail">
                  <h6>Total amount</h6>
                  <span>$200.12</span>
                </div>
                <div className="receiptDetail">
                  <h6 className="receiptAmount">Amount paid</h6>
                  <span className="receiptAmount">$200.12</span>
                </div>
              </div>

              <hr />

              <div className="receiptDetail">
                <h6>Order details</h6>
              </div>
              <div className="receiptDetail">
                <h6>Invoice 111946
                    1 x $100.00</h6>
                <span>$100.00</span>
              </div>
              <div className="receiptDetail">
                <h6>Invoice 093046
                  1 x $100.00</h6>
                <span>$100.00</span>
              </div>
              <div className="receiptDetail">
                <h6>Payment notes</h6>
                <span>This is a payment comment memo</span>
              </div>

              <hr />

              <div className="receiptDisclaimer">
                <div className="transactionReceipt">
                  This payment will be posted to your account consistent with the posting timeframes established by the agency you are paying, and may not immediately reflect in your balance. Please do not submit payment again until your balance has updated.
                    </div>
                <div className="transactionReceipt">
                  Respectfully,
                    </div>
                <div className="transactionReceipt">
                  <span className="receiptAddress">Gila LLC/MSB</span>
                  <span className="receiptAddress">P.O. Box 16755</span>
                  <span className="receiptAddress">Austin, Texas 78761</span>
                  <span className="receiptAddress">Toll Free: (800) 616-0166</span>
                  <span className="receiptAddress">Fax: (512) 371-9994</span>
                  <a className="receiptAddress" href="https://www.msbpay.com/" target="_blank" rel="noopener noreferrer">www.msbpay.com</a>
                </div>
                <div className="receiptButtons">
                  <ButtonToolbar style={{ float: "right" }}>
                    <Button variant="outline-primary" className="m-1" onClick={() => window.print()}>Print Receipt</Button>
                    <Button variant="outline-primary" className="m-1" >Email Receipt</Button>
                  </ButtonToolbar>
                </div>
              </div>
            </div>
          </ListGroup.Item>
        </ListGroup>
        </PageSectionContainer>
      </Container>
    </main>
  );
};

const mapStateToProps = (state: IAppState) => {
  return {
  };
};

export default connect(mapStateToProps)(Receipt);