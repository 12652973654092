import axios from 'axios';
import { EmailRequest } from '../../models/EmailRequest';
import { Dispatch } from 'react';
import store from "../store";
import { Client, Department, PaymentChannel } from '../../models/Client';

export const SEND_EMAIL_REQUEST = 'SEND_EMAIL_REQUEST';
export const SEND_EMAIL_SUCCESS = 'SEND_EMAIL_SUCCESS';
export const SEND_EMAIL_FAILURE = 'SEND_EMAIL_FAILURE';

export const sendEmailAction = (emailRequest:EmailRequest, actionToken?:string) => (dispatch : Dispatch<any>) => {
    dispatch({
        type: SEND_EMAIL_REQUEST,
        payload: {
            actionToken
        }
    });

    let paymentChannel = store.getState().paymentChannelWebApplication.paymentChannel! as PaymentChannel;

    let clientMsbId = paymentChannel.department.client.msbId!;
    let departmentMsbId = paymentChannel.department.msbId!;
    let paymentChannelMsbId = paymentChannel.msbId!;
    
    let baseApiUrl = store.getState().webAppSettings.baseApiUrl;
    let _url = baseApiUrl + "/MSB_Email/api/v1/Email";
    let bearerToken = store.getState().webAppSettings.bearerToken;

    const config = {
        headers: {
            Authorization: `Bearer ${bearerToken}`,
            ClientId: clientMsbId,
            DepartmentId: departmentMsbId,
            PaymentChannelId: paymentChannelMsbId
        }
    };

    axios.post(_url, emailRequest, config)
    .then(function (response) {
        dispatch({
            type: SEND_EMAIL_SUCCESS,
            payload: {
                actionToken
            }
        });
    })
    .catch(function (error) {
        dispatch({
            type: SEND_EMAIL_FAILURE,
            payload: {
                error,
                actionToken
            }
        });
    });
};

