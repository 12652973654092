import React, { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { IActionResult, IAppState } from "../../redux/storeTypes";
import { Card, Col, Container, Row } from "react-bootstrap";
import { setAuthorizeEncryptedPaymentAction, SET_AUTHORIZATION_POST_FAILURE, SET_AUTHORIZATION_POST_REQUEST, SET_AUTHORIZATION_POST_SUCCESS } from "../../redux/actions/clients";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle, faHourglass, faTimesCircle } from "@fortawesome/free-regular-svg-icons";

export interface IParams {
    id: string
}

export interface IAuthorizationPageProps {
    actionResult: IActionResult,
    errorMessage: string,
}

const AuthorizationPage = ({ actionResult, errorMessage }: IAuthorizationPageProps) => {
    console.log("AuthorizationPage");
    const actionToken = "AuthorizationPage";
    const { id }: IParams = useParams();
    const dispatch = useDispatch();
    const [authorized, setAuthorized] = useState(false);

    useEffect(() => {
        if (id === "paymentOnFile") {
            setTimeout(() => {
                dispatch(setAuthorizeEncryptedPaymentAction(window.location.search, actionToken));
            }, 5000);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id]);

    useEffect(() => {
        if (actionResult && actionResult.result) {
            if (actionResult.type === SET_AUTHORIZATION_POST_REQUEST && actionResult.token === actionToken) {
                if (actionResult.result === SET_AUTHORIZATION_POST_SUCCESS) {
                    setAuthorized(true);
                }
                if (actionResult.result === SET_AUTHORIZATION_POST_FAILURE) {
                    console.log(errorMessage);
                }
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [actionResult]);

    if (id === "paymentOnFile") {
        return (
            <>
                <Container>
                    <br />
                    <Card>
                        <Card.Body>
                            <Row>
                                <Col>
                                    <img src={"https://msbgovserv.com/assets/img/MSB-Logo-2022.png"} width="250" alt="MSB Logo" style={{ float: "right" }} />
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>
                    <Card>
                        <Card.Body>
                            <Row>
                                <Col>
                                    <div style={{ textAlign: "center" }}>
                                        <h1 style={{ margin: "30px 0px", fontSize: "40px", fontWeight: "bold" }}>Payment on File Authorization</h1>
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                {!authorized ?
                                    <Col>
                                        {!errorMessage ?
                                            <div style={{ textAlign: "center" }}>
                                                <FontAwesomeIcon icon={faHourglass} size="5x" style={{ color: 'royalblue' }} />
                                                <h1 style={{ margin: "20px 0px", fontSize: "20px" }}>Your authorization is being registered. Please wait....</h1>
                                            </div>
                                            :
                                            <div style={{ textAlign: "center" }}>
                                                <FontAwesomeIcon icon={faTimesCircle} size="5x" style={{ color: 'red' }} />
                                                <h1 style={{ margin: "20px 0px", fontSize: "20px" }}>Your authorization failed. Please contact your local provider.</h1>
                                                <h1 style={{ margin: "20px 0px", fontSize: "10px", fontStyle: "italic" }}>Error Message: [{errorMessage}]</h1>
                                            </div>
                                        }
                                    </Col>
                                    :
                                    <Col>
                                        <div style={{ textAlign: "center" }}>
                                            <FontAwesomeIcon icon={faCheckCircle} size="5x" style={{ color: 'green' }} />
                                            <h1 style={{ margin: "20px 0px", fontSize: "20px" }}>Authorization successful.</h1>
                                            <h1 style={{ margin: "20px 0px", fontSize: "20px" }}>An acknowledgement email will be sent to your current email address.</h1>
                                            <h1 style={{ margin: "20px 0px", fontSize: "20px" }}>Thank you.</h1>
                                        </div>
                                        <div style={{ textAlign: "center" }}>
                                            <h1 style={{ margin: "20px 0px", fontSize: "10px", fontStyle: "italic" }}>You may close this page in your browser.</h1>
                                        </div>
                                    </Col>
                                }
                            </Row>
                        </Card.Body>
                    </Card>
                    <Card>
                        <Card.Body>
                            <Row>
                                <Col>
                                    <div style={{ textAlign: "left" }}>
                                        <a href="https://msbgovserv.com/privacy/" target="_blank" rel="noreferrer" style={{ fontSize: "14px" }}>Privacy Notice</a>
                                    </div>
                                </Col>
                                <Col>
                                    <div style={{ textAlign: "right" }}>
                                        <a href="/Payment_Terms_and_Conditions.pdf" target="_blank" rel="noreferrer" style={{ fontSize: "14px" }}>Terms & Conditions</a>
                                    </div>
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>
                    <div style={{ textAlign: "center" }}>
                        <h1 style={{ fontSize: "13px", fontStyle: "italic"  }}>Powered by MSB</h1>
                    </div>
                </Container>
            </>
        );
    } else {
        return(<></>);
    }
};

const mapStateToProps = (state: IAppState) => {
    return {
        actionResult: state.clients.actionResult,
        errorMessage: state.clients.errorMessage,
    };
};

export default connect(mapStateToProps)(AuthorizationPage);

