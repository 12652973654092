import { CellPlugin } from '@react-page/editor';
import NericSearchResults, {
    TAX_TYPE_LABEL, HIDE_TAX_TYPE, BILL_NUMBER_LABEL, OWNER1_LABEL, OWNER2_LABEL, PARCEL_LOCATION_LABEL, BALANCE_DUE_LABEL, PAYMENT_OPTIONS_LABEL,
    STATUS_LABEL, HEADER_BACKGROUND_COLOR, HEADER_PADDING, MARGIN, PADDING, OBSERVES_WEEKENDS, DURATION_OF_PENDING_HOURS
} from './components/NericSearchResults';

type Data = {
    title: string,
    taxType: string,
    hideTaxType: boolean,
    billNumber: string,
    owner1: string,
    owner2: string,
    parcelLocation: string,
    balanceDue: string,
    status: string,
    paymentOptions: string,
    observesWeekends: boolean,
    durationOfPendingHours: number,
    headerBackgroundColor: string,
    headerPadding: string,
    margin: string,
    padding: string,
}

const NericSearchResultsPlugin: CellPlugin<Data> = {
    Renderer: ({ data }) => (
        <NericSearchResults
            taxTypeLabel={data.taxType}
            hideTaxType={data.hideTaxType}
            billNumberLabel={data.billNumber}
            owner1Label={data.owner1}
            owner2Label={data.owner2}
            parcelLocationLabel={data.parcelLocation}
            balanceDueLabel={data.balanceDue}
            statusLabel={data.status}
            paymentOptionsLabel={data.paymentOptions}
            observesWeekends={data.observesWeekends}
            durationOfPendingHours={data.durationOfPendingHours}
            margin={data.margin}
            padding={data.padding}
            headerBackgroundColor={data.headerBackgroundColor}
            headerPadding={data.headerPadding}
        />
    ),
    id: 'nericResultsPlugin',
    title: 'NERIC Search Results',
    description: 'NERIC table results',
    version: 1,
    controls: [
        {
            title: 'Labels',
            controls: {
                type: 'autoform',
                schema: {
                    properties: {
                        taxType: {
                            type: 'string',
                            default: TAX_TYPE_LABEL,
                        },
                        hideTaxType: {
                            type: 'boolean',
                        },
                        billNumber: {
                            type: 'string',
                            default: BILL_NUMBER_LABEL,
                        },
                        owner1: {
                            type: 'string',
                            default: OWNER1_LABEL,
                        },
                        owner2: {
                            type: 'string',
                            default: OWNER2_LABEL,
                        },
                        parcelLocation: {
                            type: 'string',
                            default: PARCEL_LOCATION_LABEL,
                        },
                        balanceDue: {
                            type: 'string',
                            default: BALANCE_DUE_LABEL,
                        },
                        paymentOptions: {
                            type: 'string',
                            default: PAYMENT_OPTIONS_LABEL,
                        },
                        status: {
                            type: 'string',
                            default: STATUS_LABEL,
                        },
                        observesWeekends:{
                            type: 'boolean',
                            default: OBSERVES_WEEKENDS
                        },
                        durationOfPendingHours:{
                            type: 'number',
                            default: DURATION_OF_PENDING_HOURS
                        },
                    },
                    required: [],
                },
            },
        },
        {
            title: 'Styles',
            controls: {
                type: 'autoform',
                schema: {
                    properties: {
                        margin: {
                            type: 'string',
                            default: MARGIN,
                        },
                        padding: {
                            type: 'string',
                            default: PADDING,
                        },
                        headerBackgroundColor: {
                            type: 'string',
                            default: HEADER_BACKGROUND_COLOR,
                        },
                        headerPadding: {
                            type: 'string',
                            default: HEADER_PADDING,
                        }
                    },
                    required: [],
                },
            },
        },
    ],
};

export default NericSearchResultsPlugin;