import axios from 'axios';
import { Dispatch } from 'react';
import { Department, PaymentChannel } from '../../models/Client';
import store from '../store';

export const GET_CLIENT_REQUEST = 'GET_CLIENT_REQUEST';
export const GET_CLIENT_SUCCESS = 'GET_CLIENT_SUCCESS';
export const GET_CLIENT_FAILURE = 'GET_CLIENT_FAILURE';

export const GET_DEPARTMENT_REQUEST = 'GET_DEPARTMENT_REQUEST';
export const GET_DEPARTMENT_SUCCESS = 'GET_DEPARTMENT_SUCCESS';
export const GET_DEPARTMENT_FAILURE = 'GET_DEPARTMENT_FAILURE';

export const SAVE_PAYPAL_CREDENTIALS_REQUEST = 'SAVE_PAYPAL_CREDENTIALS_REQUEST';
export const SAVE_PAYPAL_CREDENTIALS_SUCCESS = 'SAVE_PAYPAL_CREDENTIALS_SUCCESS';
export const SAVE_PAYPAL_CREDENTIALS_FAILURE = 'SAVE_PAYPAL_CREDENTIALS_FAILURE';

export const GET_PAYPAL_CREDENTIALS_REQUEST = 'GET_PAYPAL_CREDENTIALS_REQUEST';
export const GET_PAYPAL_CREDENTIALS_SUCCESS = 'GET_PAYPAL_CREDENTIALS_SUCCESS';
export const GET_PAYPAL_CREDENTIALS_FAILURE = 'GET_PAYPAL_CREDENTIALS_FAILURE';

export const SET_AUTHORIZATION_POST_REQUEST = 'SET_AUTHORIZATION_POST_REQUEST';
export const SET_AUTHORIZATION_POST_SUCCESS = 'SET_AUTHORIZATION_POST_SUCCESS';
export const SET_AUTHORIZATION_POST_FAILURE = 'SET_AUTHORIZATION_POST_FAILURE';

export const getClientAction = (actionToken?:string) => (dispatch:Dispatch<any>) => {
    dispatch({
        type: GET_CLIENT_REQUEST,
        payload: {
            actionToken
        }
    });

    let paymentChannel = store.getState().paymentChannelWebApplication.paymentChannel! as PaymentChannel;

    let clientMsbId = paymentChannel.department.client.msbId!;
    let departmentMsbId = paymentChannel.department.msbId!;
    let paymentChannelMsbId = paymentChannel.msbId!;

    let baseApiUrl = store.getState().webAppSettings.baseApiUrl;
    let _url =  `${baseApiUrl}/MSB_Client/api/v1/Clients/${clientMsbId}`;
    let bearerToken = store.getState().webAppSettings.bearerToken;

    const config = {
        headers: {
            Authorization: `Bearer ${bearerToken}`,
            ClientId: clientMsbId,
            DepartmentId: departmentMsbId,
            PaymentChannelId: paymentChannelMsbId
        }
    };

    axios.get(_url, config)
        .then(function (response) {
            let client = response.data;

            dispatch({
                type: GET_CLIENT_SUCCESS,
                payload: {
                    client,
                    actionToken
                }
            });
        })
        .catch(function (error) {
            dispatch({
                type: GET_CLIENT_FAILURE,
                payload: {
                    error,
                    actionToken
                }
            });
        })
        .then(function () {
        }); 
};

export const getDepartmentAction = (actionToken?: string) => (dispatch: Dispatch<any>) => {
    dispatch({
        type: GET_DEPARTMENT_REQUEST,
        payload: {
            actionToken
        }
    });

    let paymentChannel = store.getState().paymentChannelWebApplication.paymentChannel! as PaymentChannel;

    let clientMsbId = paymentChannel.department.client.msbId!;
    let departmentMsbId = paymentChannel.department.msbId!;
    let paymentChannelMsbId = paymentChannel.msbId!;

    let baseApiUrl = store.getState().webAppSettings.baseApiUrl;
    let _url = `${baseApiUrl}/MSB_Client/api/v1/Departments/${departmentMsbId}`;
    let bearerToken = store.getState().webAppSettings.bearerToken;

    const config = {
        headers: {
            Authorization: `Bearer ${bearerToken}`,
            ClientId: clientMsbId,
            DepartmentId: departmentMsbId,
            PaymentChannelId: paymentChannelMsbId
        }
    };

    let department = new Department();

    axios.get(_url, config)
        .then(function (response) {
            department = response.data;
            dispatch({
                type: GET_DEPARTMENT_SUCCESS,
                payload: {
                    department,
                    actionToken
                }
            });
        })
        .catch(function (error) {
            dispatch({
                type: GET_DEPARTMENT_FAILURE,
                payload: {
                    error,
                    actionToken
                }
            });
        })
        .then(function () {
        });
};

export const getDepartmentOnlyAction = (departmentId: string, actionToken?: string) => (dispatch: Dispatch<any>) => {
    dispatch({
        type: GET_DEPARTMENT_REQUEST,
        payload: {
            actionToken
        }
    });

    let baseApiUrl = store.getState().webAppSettings.baseApiUrl;
    let _url = `${baseApiUrl}/MSB_Client/api/v1/Departments/${departmentId}`;
    let bearerToken = store.getState().webAppSettings.bearerToken;

    const config = {
        headers: {
            Authorization: `Bearer ${bearerToken}`,
        }
    };

    let department = new Department();

    axios.get(_url, config)
        .then(function (response) {
            department = response.data;
            dispatch({
                type: GET_DEPARTMENT_SUCCESS,
                payload: {
                    department,
                    actionToken
                }
            });
        })
        .catch(function (error) {
            dispatch({
                type: GET_DEPARTMENT_FAILURE,
                payload: {
                    error,
                    actionToken
                }
            });
        })
        .then(function () {
        });
};

export const savePayPalCredentialsAction = (params: any, actionToken?: string) => (dispatch: Dispatch<any>) => {
    dispatch({
        type: SAVE_PAYPAL_CREDENTIALS_REQUEST,
        payload: {
            actionToken
        }
    });

    let baseApiUrl = store.getState().webAppSettings.baseApiUrl;
    let _url = `${baseApiUrl}/MSB_Client/api/v1/PayPalCredentials/completePayPalCredentialBoarding${params}`;
    let bearerToken = store.getState().webAppSettings.bearerToken;

    const config = {
        headers: {
            Authorization: `Bearer ${bearerToken}`,
        }
    };

    axios.patch(_url, {}, config)
        .then(function (response) {
            dispatch({
                type: SAVE_PAYPAL_CREDENTIALS_SUCCESS,
                payload: {
                    actionToken
                }
            });
        })
        .catch(function (error) {
            dispatch({
                type: SAVE_PAYPAL_CREDENTIALS_FAILURE,
                payload: {
                    error,
                    actionToken
                }
            });
        })
        .then(function () {
        });
};

export const getPayPalCredentialsAction = (id: string, actionToken?: string) => (dispatch: Dispatch<any>) => {
    dispatch({
        type: GET_PAYPAL_CREDENTIALS_REQUEST,
        payload: {
            actionToken
        }
    });

    let baseApiUrl = store.getState().webAppSettings.baseApiUrl;
    let _url = `${baseApiUrl}/MSB_Client/api/v1/PayPalCredentials/${id}`;
    let bearerToken = store.getState().webAppSettings.bearerToken;

    const config = {
        headers: {
            Authorization: `Bearer ${bearerToken}`,
        }
    };

    axios.get(_url, config)
        .then(function (response) {
            dispatch({
                type: GET_PAYPAL_CREDENTIALS_SUCCESS,
                payload: {
                    payPalCredential: response.data,
                    actionToken
                }
            });
        })
        .catch(function (error) {
            dispatch({
                type: GET_PAYPAL_CREDENTIALS_FAILURE,
                payload: {
                    error,
                    actionToken
                }
            });
        })
        .then(function () {
        });
};

export const setAuthorizeEncryptedPaymentAction = (params: any, actionToken?: string) => (dispatch: Dispatch<any>) => {
    dispatch({
        type: SET_AUTHORIZATION_POST_REQUEST,
        payload: {
            actionToken
        }
    });

    let baseApiUrl = store.getState().webAppSettings.baseApiUrl;
    let _url = `${baseApiUrl}/MSB_Wallet/api/v1/AuthorizationPostBack/authorizeEncryptedPaymentMethod${params}`;
    let bearerToken = store.getState().webAppSettings.bearerToken;

    const config = {
        headers: {
            Authorization: `Bearer ${bearerToken}`,
        }
    };

    axios.post(_url, {}, config)
        .then(function (response) {
            dispatch({
                type: SET_AUTHORIZATION_POST_SUCCESS,
                payload: {
                    actionToken
                }
            });
        })
        .catch(function (error) {
            let errorMessage = 'We could not complete your authorization.';
            if (error.response) {
                errorMessage = error.response.data.message;
            }
            dispatch({
                type: SET_AUTHORIZATION_POST_FAILURE,
                payload: {
                    error: errorMessage,
                    actionToken
                }
            });
        })
        .then(function () {
        });
};
