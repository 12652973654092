import React, { useState } from "react";
import { Container, Row, Col, Button, Table as ReactBsTable, Form } from 'react-bootstrap';
import PageSectionContainer from '../../components/layout/PageSectionContainer';
import { connect } from "react-redux";
import { Redirect } from 'react-router-dom';
import { IAppState } from "../../redux/storeTypes";
import PageHeader from '../../components/layout/PageHeader';
import Breadcrumb from '../../components/layout/Breadcrumb';
import { Routes } from '../../routes';
import AddPaymentModal from "./AddPaymentModal";

export interface ICheckout {
}

const Checkout = ({ }: ICheckout) => {
  const setRoute = (path:string) => {
    return path.replace(":id", "Demo");
  }

  const [redirect, setRedirect] = useState('');
  const [showAddNewPmtForm, setShowAddNewPmtForm] = useState(false);
  const addNewCard = () => {
    setShowAddNewPmtForm(true)
  }
  if (redirect != "") {
    return (<Redirect push to={redirect} />)
  } else {
    return (
      //<main>
      <>
        <PageHeader />

          <Container className="mt-4">
            <Form>
              <Breadcrumb name='Checkout' />
              <Row>
                <Col lg={8} md={8} sm={12}>
                  <AddPaymentModal showForm={showAddNewPmtForm} setShowForm={setShowAddNewPmtForm} />
                  <PageSectionContainer title='Payment details' headerTag='h2' showHeaderBorder={true}>
                    <h2>Payment amount</h2>
                    <p>Pay the current balance or pay another amount towards your balance.</p>
                    <Row className="payment-details">
                      <Col>
                        <Form.Check type="radio" label="Current balance due Jun 1, 2021" />
                      </Col>
                      <Col>
                        $200.12
                      </Col>
                    </Row>
                    <Row className="payment-details">
                      <Col>
                        <Form.Check type="radio" label="Pay another amount" />
                      </Col>
                      <Col>
                        <Form.Control placeholder="$0.00" />
                      </Col>
                    </Row>
                    <hr />
                    <h2>Payment date</h2>
                    <p>Select the date you would like your payment to be processed.</p>
                    <Row>
                      <Col>
                        <span>Select payment date</span>
                      </Col>
                      <Col>
                        <Form.Group controlId="paymentDate">
                          <Form.Control type="date" name="paymentDate" />
                        </Form.Group>
                      </Col>
                    </Row>
                    <hr />
                    <h2>Payment method</h2>
                    <p>Use your default payment method or add a new payment method for this transaction.</p>
                    <Row className="payment-details">
                      <Col>                         
                        <Form.Check type="radio" name="paymentMethod" label="Current balance due Jun 1, 2021" />
                      </Col>
                    </Row>
                    <Row className="payment-details">
                      <Col>
                        <Form.Check type="radio" name="paymentMethod" label="Add new Payment method" onClick={addNewCard} />                          
                      </Col>
                    </Row>
                    <hr />
                    <Row>
                      <Col>
                        <h2>Payment notes</h2>
                        <p>Enter notes specific to this payment transaction.</p>
                        <Form.Control as="textarea" />
                      </Col>
                    </Row>
                  </PageSectionContainer>
                  <PageSectionContainer title='Billing details' headerTag='h2' showHeaderBorder={true}>
                        <h2>Billing address</h2>
                        <p>Pay the current balance or pay another amount towards your balance.</p>
                        <Row className="payment-details">
                          <Col>
                            <Form.Check type="radio" name="billingAddress" label="Use current billing address" />
                          </Col>
                        </Row>
                        <Row className="payment-details">
                          <Col>
                            <Form.Check type="radio" name="billingAddress" label="Use a different billing address" />
                          </Col>
                        </Row>
                  </PageSectionContainer>
                </Col>
                <Col lg={4} md={4} sm={12}>
                  <PageSectionContainer>
                    <ReactBsTable responsive>
                      <tbody>
                        <th>Payment summary</th>
                        <tr>
                          <td>Subtotal</td>
                          <td>$200.00</td>
                        </tr>
                        <tr>
                          <td>Convenience fee</td>
                          <td>$0.12</td>
                        </tr>
                        <tr>
                          <td>Total amount</td>
                          <td>$200.12</td>
                        </tr>
                      </tbody>
                    </ReactBsTable>
                    <Form.Check style={{ padding: '30px 10px' }} label="I agree to the Terms and Conditions" />
                    <Button className="brand-btn-primary checkoutButton" onClick={() => { setRedirect(setRoute(Routes.ReceiptStatic.path)); }}>Submit payment</Button>
                    <Button className="btn-cancel checkoutButton" onClick={() => { setRedirect(setRoute(Routes.AccountLandingStatic.path)); }}>Cancel payment</Button>
                  </PageSectionContainer>
                </Col>
              </Row>
            </Form>
          </Container>

      </>
    );
  }
};

const mapStateToProps = (state: IAppState) => {
  return {
  };
};

export default connect(mapStateToProps)(Checkout);