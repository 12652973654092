import React from "react";
import { Navbar, Nav } from 'react-bootstrap';
import statencLogo from "../../assets/img/state-nc-logo.png";
import { Routes } from '../../routes';
import logo from '../../assets/img/logo.svg';

export default () => {
    const setRoute = (path:string) => {
        return path.replace(":id", "Demo");
    }

    return (
        <header className="msb-portal__header">
            <Navbar expand="lg" className="justify-content-between px-3 msb-portal__nav">
                <Navbar.Brand href="#home">
                    <img style={{ height: 40, marginTop: '20px', marginBottom: '20px' }} src={logo} />
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav defaultActiveKey={setRoute(Routes.LoginStatic.path)} as="ul" className="mr-auto">
                        <Nav.Item as="li">
                            <Nav.Link href={setRoute(Routes.LoginStatic.path)}>Login</Nav.Link>
                        </Nav.Item>
                        <Nav.Item as="li">
                            <Nav.Link href={setRoute(Routes.RegisterStatic.path)}>Register</Nav.Link>
                        </Nav.Item>
                        <Nav.Item as="li">
                            <Nav.Link href={setRoute(Routes.AccountLandingStatic.path)}>Make Payment</Nav.Link>
                        </Nav.Item>
                    </Nav>
                </Navbar.Collapse>
            </Navbar>
        </header>
    );
};
