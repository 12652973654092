import axios from 'axios';
import { Dispatch } from 'react';
import { PaymentChannel } from '../../models/Client';
import { PaymentType, ProcessedOrder } from '../../models/Order';
import { RedirectRequest } from "../../models/RedirectIntegration";
import store from '../store';

export const SET_REDIRECT_REQUEST = 'SET_REDIRECT_REQUEST';

export const setRedirectRequestAction = (redirectRequest: RedirectRequest) => (dispatch: Dispatch<any>) => {
    dispatch({
        type: SET_REDIRECT_REQUEST,
        payload: {
            redirectRequest
        }
    });
};

class Receipt {
    TransactionUniqueID: string = "";
    TransactionToken: string = "";
    TransactionID: string = "";
    TransactionAmount: string = "";
    ConvenienceFeeAmount: string = "";
    PaymentReference: string = "";
    PaymentMethod: string = "";
    PaymentAccount: string = "";
    PaymentName: string = "";
    ResponseCode: string = "";
}

export const setRedirectResponseAction = () => (dispatch: Dispatch<any>) => {
    let bearerToken = store.getState().webAppSettings.bearerToken;
    let redirectRequest = store.getState().redirectIntegration.redirectRequest;
    let pc = store.getState().paymentChannelWebApplication.paymentChannel! as PaymentChannel;
    let po = store.getState().orderManagement.processedOrder! as ProcessedOrder;
    let redirectDefined = (redirectRequest?.successReturnURL);
    if (redirectRequest?.postbackURL) {
        let receipt = new Receipt();
        receipt.TransactionUniqueID = redirectRequest?.uniqueId;
        receipt.TransactionToken = "";
        receipt.TransactionID = po?.processorData?.receipt?.orderId as string || po?.processorData?.receipt?.orderTransaction?.orderIdentifier as string;
        receipt.TransactionAmount = po?.processorData?.receipt?.amount as string;
        receipt.ConvenienceFeeAmount = po?.processorData?.receipt?.convenienceFee as string;
        receipt.PaymentReference = po?.processorData?.receipt?.accountNumbers as string;
        receipt.PaymentMethod = ((po?.processorData?.receipt?.vantivPaymentType === "Debit") ? "DebitCard" : (typeof po?.processorData?.receipt?.paymentType === "number"? PaymentType[po?.processorData?.receipt?.paymentType as any] : po?.processorData?.receipt?.paymentType)) + (po?.processorData?.receipt?.cardLogo ? (":" + po?.processorData?.receipt?.cardLogo.toUpperCase()) : "");
        receipt.PaymentAccount = po?.processorData?.receipt?.lastFourOnCard as string || po?.processorData?.receipt?.accountNumberLastFour as string || po?.processorData?.receipt?.orderTransaction?.initiatedBy as string;
        receipt.PaymentName = po?.processorData?.receipt?.nameOnCard as string;
        receipt.ResponseCode = "SUCCESS";

        const config = {
            headers: {
                Authorization: `Bearer ${bearerToken}`,
                PaymentChannelId: pc.msbId
            }
        };

        axios.post("/Redirection/Responder", { "url": redirectRequest.postbackURL, "clientId": redirectRequest?.clientId, "receipt": receipt }, config)
            .then(function (response) {
                if (redirectDefined) redirector(redirectRequest.successReturnURL, "SUCCESSFUL");
            })
            .catch(function (error) {
                if (redirectDefined) redirector(redirectRequest.successReturnURL, "SUCCESSFUL");
            });
    } else {
        if (redirectDefined) redirector(redirectRequest.successReturnURL, "SUCCESSFUL");
    }

    return redirectDefined;
};

export const setRedirectCancelAction = () => (dispatch: Dispatch<any>) => {
    let redirectRequest = store.getState().redirectIntegration.redirectRequest;
    if (redirectRequest?.cancelReturnURL) {
        redirector(redirectRequest.cancelReturnURL, "CANCELLED");
    } else {
        redirector("https://msbgovserv.com/payment-processing", "");
    }
};

const redirector = (url: string, result: string) => {
    let _url = new URL(url);
    if (result) _url.searchParams.append("result", result);

    window.history.replaceState({ tabForId: "/" }, "", "/");
    window.location.href = _url.href;
};

