import { Row, Col, Spinner, Card, Button } from "react-bootstrap"
import { useEffect, useState } from "react";
import Table from '../../../../../../components/Table';
import * as React from "react";
import { connect, useDispatch } from "react-redux";
import { IAppState } from "../../../../../../redux/storeTypes";
import { Order } from "../../../../../../models/Order";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { updateOrderDetailsAction } from "../../../../../../redux/actions/orderManagement";

interface IOrderLineCart {
    title: string,
    subTitle: string,
    itemNameLabel: string,
    hideItemName: boolean,
    itemReferenceLabel: string,
    hideItemReference: boolean,
    unitPriceLabel: string,
    quantityLabel: string,
    hideQuantity: boolean,
    actionsLabel: string,
    margin: string,
    padding: string,
    fullStoryMask: boolean,
    headerBackgroundColor: string,
    headerPadding: string,
    order: Order,
}

export const TITLE = 'Order Line Cart';
export const SUBTITLE = 'Your cart is currently empty'
export const ITEM_NAME_LABEL = 'Item name/description';
export const HIDE_ITEM_NAME = true;
export const ITEM_REFERENCE_LABEL = 'Item reference';
export const UNIT_PRICE_LABEL = 'Unit price';
export const QUANTITY_LABEL = 'Quantity';
export const HIDE_QUANTITY = true;
export const ACTIONS_LABEL = 'Actions';
export const MARGIN = '0px';
export const PADDING = '0px';
export const HEADER_BACKGROUND_COLOR = 'rgb(250, 250, 250)';
export const HEADER_PADDING = '0';
export const FULL_STORY_MASK = false;

const checkStyleForDefault = (style: string, defaultStyle: string) => {
    return style && style !== '' ? style : defaultStyle;
}

const OrderLineCart = ({ title, subTitle, itemNameLabel, hideItemName, itemReferenceLabel, hideItemReference, unitPriceLabel,fullStoryMask, quantityLabel, hideQuantity, actionsLabel, margin, padding, headerBackgroundColor, headerPadding, order }: IOrderLineCart) => {
    const dispatch = useDispatch();
    const actionToken = 'OrderLineCart';
    const [deleteRow, setDeleteRow] = useState<any>('');

    fullStoryMask = fullStoryMask || FULL_STORY_MASK;

    const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
    });

    const amountFormatter = (cell: any) => {
        return (<span>{formatter.format(cell)}</span>)
    }

    const actionsFormatter = (cell: any, row: any) => {
        return (
            <Button size="sm" variant="outline-secondary" onClick={() => setDeleteRow(row)}>
                <FontAwesomeIcon icon={faTrash} />
            </Button>
        );
    }

    const initalColumns = [
        {
            dataField: 'itemName',
            text: itemNameLabel,
            editable: false,
            hidden: hideItemName,
            classes: 'fs-mask', 
            headerStyle: {
                backgroundColor: checkStyleForDefault(headerBackgroundColor, HEADER_BACKGROUND_COLOR)
            },
        },
        {
            dataField: 'itemReferenceNumber',
            text: itemReferenceLabel,
            editable: false,
            hidden: hideItemReference,
            classes: 'fs-mask', 
            headerStyle: {
                backgroundColor: checkStyleForDefault(headerBackgroundColor, HEADER_BACKGROUND_COLOR)
            },
        },
        {
            dataField: 'unitPrice',
            text: unitPriceLabel,
            editable: false,
            headerStyle: {
                backgroundColor: checkStyleForDefault(headerBackgroundColor, HEADER_BACKGROUND_COLOR)
            },
            formatter: amountFormatter,
        },
        {
            dataField: 'quantity',
            text: quantityLabel,
            editable: false,
            hidden: hideQuantity,
            headerStyle: {
                backgroundColor: checkStyleForDefault(headerBackgroundColor, HEADER_BACKGROUND_COLOR)
            },
        },
        {
            dataField: 'id',
            text: actionsLabel,
            editable: false,
            headerStyle: {
                backgroundColor: checkStyleForDefault(headerBackgroundColor, HEADER_BACKGROUND_COLOR)
            },
            formatter: actionsFormatter,
        }
    ];

    const [columns, setColumns] = useState<any>(initalColumns);

    useEffect(() => {
        const newColumns = columns.map((column: any) => {
            if (column.dataField !== 'itemName') return column;
            return {
                ...column,
                text: itemNameLabel,
                hidden: hideItemName
            };
        });
        setColumns(newColumns)
    }, [hideItemName, itemNameLabel]);

    useEffect(() => {
        const newColumns = columns.map((column: any) => {
            if (column.dataField !== 'itemReferenceNumber') return column;
            return {
                ...column,
                text: itemReferenceLabel,
                hidden: hideItemReference
            };
        });
        setColumns(newColumns)
    }, [hideItemReference, itemReferenceLabel]);

    useEffect(() => {
        const newColumns = columns.map((column: any) => {
            if (column.dataField !== 'unitPrice') return column;
            return {
                ...column,
                text: unitPriceLabel
            };
        });
        setColumns(newColumns)
    }, [unitPriceLabel]);

    useEffect(() => {
        const newColumns = columns.map((column: any) => {
            if (column.dataField !== 'quantity') return column;
            return {
                ...column,
                text: quantityLabel,
                hidden: hideQuantity
            };
        });
        setColumns(newColumns);
    }, [hideQuantity, quantityLabel]);

    useEffect(() => {
        const newColumns = columns.map((column: any) => {
            if (column.dataField !== 'id') return column;
            return {
                ...column,
                text: actionsLabel
            };
        });
        setColumns(newColumns);
    }, [actionsLabel]);

    useEffect(() => {
        if (order) {
            let _updatedOrderLines = order?.orderLines.map((orderline: any) => {
                if (orderline?.itemId != deleteRow?.itemId) return orderline;
            }).filter(Boolean);
            order.orderLines = _updatedOrderLines;
            dispatch(updateOrderDetailsAction(order, actionToken));
        }
    }, [deleteRow]);

    return (
        <div style={{ margin: margin, padding: padding }} className={fullStoryMask ? "fs-mask" : ""}>
            <Row>
                <Col>
                    <label>{title}</label>
                    <div style={{ marginTop: '10px' }}>
                        <Table
                            remote={false}
                            keyField="OrderLineCart"
                            data={order?.orderLines || []}
                            columns={columns}
                        />
                        {(order?.orderLines.length > 0) ? "" : <div style={{ textAlign: 'center' }}><i>{subTitle}</i></div>}
                    </div>
                </Col>
            </Row>
        </div>
    )
}

const mapStateToProps = (state: IAppState) => {
    return {
        order: state.orderManagement.order,
    };
};

export default connect(mapStateToProps)(OrderLineCart);

