import { CheckCircleOutlined, ErrorOutlineOutlined } from "@mui/icons-material";
import { Accordion, AccordionSummary, Typography, Box, AccordionDetails, Grid, TextField, Button, CircularProgress, Dialog, DialogContent, DialogContentText, DialogActions, DialogTitle, FormControlLabel, Checkbox, useTheme } from "@mui/material";
import { Checkout, CheckoutStatus, CheckoutView } from "../../models/CheckoutLink";
import { IActionResult, IAppState } from "../../redux/storeTypes";
import { connect, useDispatch } from "react-redux";
import { useCallback, useEffect, useRef, useState } from "react";
import { statusCheckoutAction, viewCheckoutAction } from "../../redux/actions/checkout";
import { Order, PaymentType } from "../../models/Order";
import { PROCESS_ORDER_FAILURE, PROCESS_ORDER_REQUEST, PROCESS_ORDER_SUCCESS, simpleProcessOrderAction } from "../../redux/actions/orderManagement";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import moment from "moment";

export interface ISummaryProps {
    msg: any;
    order: Order;
    checkout: Checkout;
    status: CheckoutStatus;
    view: CheckoutView;
    errorMessage: string;
    actionResult: IActionResult;
}

const Summary = ({ msg, order, checkout, status, view, errorMessage, actionResult }: ISummaryProps) => {
    const actionToken = "CheckoutPage"
    const dispatch = useDispatch();
    const theme = useTheme();
    const [submitted, setSubmitted] = useState(false);
    const [expanded, setExpanded] = useState(false);
    const [processing, setProcessing] = useState(false);
    const [complete, setComplete] = useState(false);
    const [failure, setFailure] = useState(false);
    const { executeRecaptcha } = useGoogleReCaptcha();

    useEffect(() => {
        if (status) {
            if (status.invoice && status.payBy) {
                setExpanded(true);
                if (!status.summary) {
                    dispatch(statusCheckoutAction({ ...status, summary: true }));
                }
            } else {
                if (status.summary) {
                    dispatch(statusCheckoutAction({ ...status, summary: false }));
                }
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [status]);

    useEffect(() => {
        if (view !== CheckoutView.SUMMARY) setExpanded(status.invoice && status.payBy && view === CheckoutView.NONE);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [view]);

    useEffect(() => {
        if (expanded) {
            dispatch(viewCheckoutAction(CheckoutView.SUMMARY));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [expanded]);

    useEffect(() => {
        if (actionResult && actionResult.result) {
            if (actionResult.type === PROCESS_ORDER_REQUEST && actionResult.token === actionToken) {
                if (actionResult.result === PROCESS_ORDER_SUCCESS) {
                    setProcessing(false);
                    setComplete(true);
                    msg({ Response: "SUCCESS", Message: `${order.methodName} Complete${(order.shouldTokenizeCard ? ", Token Generated" : "")}`, ID: checkout.uniqueId });
                }
                if (actionResult.result === PROCESS_ORDER_FAILURE) {
                    setProcessing(false);
                    setFailure(true);
                }
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [actionResult]);

    const handleFailure = () => {
        setFailure(false);
        setSubmitted(false);
        setExpanded(false);
        dispatch(statusCheckoutAction({ ...status, payBy: false }));
    }

    const handleReCaptchaVerify = useCallback(async (_order: Order) => {
        if (!executeRecaptcha) {
            console.log('Execute recaptcha not yet available');
            return;
        }
        setProcessing(true);
        const token = await executeRecaptcha(actionToken);
        dispatch(simpleProcessOrderAction(_order, actionToken, token, checkout.authorizeCard));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [executeRecaptcha]);

    const handlePayment = (event: any) => {
        event.preventDefault();
        const form = event.currentTarget;
        if (form.reportValidity()) {
            setSubmitted(true);
            handleReCaptchaVerify(order);
        }
    }

    return (
        <>
        <Accordion expanded={expanded} disableGutters={true}>
            <AccordionSummary>
                <Typography><Box component="span">{status?.summary ? <CheckCircleOutlined sx={{ color: 'green', verticalAlign: 'bottom' }} /> : <ErrorOutlineOutlined sx={{ color: 'gold', verticalAlign: 'bottom' }} />} Summary</Box></Typography>
            </AccordionSummary>
            <AccordionDetails>
                <form onSubmit={handlePayment}>
                    <Grid container spacing="3">
                        <Grid item xs>
                            <TextField fullWidth inputProps={{ readOnly: true, style: { textAlign: "right" }}} value={order.amount.toLocaleString('en-US', { style: 'currency', currency: 'USD' })} label="Amount" size="small" variant="standard" />
                            <TextField fullWidth inputProps={{ readOnly: true, style: { textAlign: "right" }}} value={order.convenienceFee.toLocaleString('en-US', { style: 'currency', currency: 'USD' })} label="Convenience Fee" size="small" variant="standard" />
                            <TextField fullWidth inputProps={{ readOnly: true, style: { textAlign: "right" } }} value={order.totalAmount.toLocaleString('en-US', { style: 'currency', currency: 'USD' })} label="Total" size="small" variant="standard" />
                        </Grid>
                    </Grid>
                    {(order.paymentType === PaymentType.ECheck || (order.paymentType === PaymentType.CreditCard && !order.isCardPresent)) &&
                    <Grid container>
                        <Grid item xs>
                            <Box>
                                <FormControlLabel
                                    required
                                    control={<Checkbox size="small" />}
                                    label={
                                        <Typography sx={{ my: 1, textAlign: 'justify' }} fontSize={'0.75em'} color={theme.palette.text.primary}>
                                            I agree to accept the convenience fee charges.
                                        </Typography>
                                    }
                                    sx={{ '& .MuiFormControlLabel-asterisk': { display: 'none', }, alignItems: 'flex-start' }}
                                />
                            </Box>
                            <Box sx={{ my: -1 }}>
                                <FormControlLabel
                                    required
                                    control={<Checkbox size="small" />}
                                    label={
                                        <Typography sx={{ textAlign: 'justify' }} fontSize={'0.75em'} color={theme.palette.text.primary}>
                                            I agree to the <a href="https://payments.msbpay.navient.com/Payment_Terms_and_Conditions.pdf" target={"_blank"} rel={"noreferrer"}>terms & conditions</a>.
                                        </Typography>
                                    }
                                    sx={{ '& .MuiFormControlLabel-asterisk': { display: 'none', }, }}
                                />
                            </Box>
                            {order.paymentType === PaymentType.ECheck &&
                            <Box>
                                <FormControlLabel
                                    required
                                    control={<Checkbox size="small" />}
                                    label={
                                        <Typography sx={{ my: 1, textAlign: 'justify' }} fontSize={'0.75em'} color={theme.palette.text.primary}>
                                            By checking here, I authorize Municipal Services Bureau to initiate a single ACH electronic debit to my account ending in <b>xxxx-{order.eCheckAccountNumber.slice(-4)}</b> in the amount of <b>{order.totalAmount.toLocaleString('en-US', { style: 'currency', currency: 'USD' })}</b> on <b>{moment().format('MM/DD/YYYY')}</b>. I agree that ACH transactions I authorize comply with all applicable laws. Once payment is authorized, there cannot be any changes or corrections. I understand that I may call during normal business hours if I have any questions. It is recommended that you <b dangerouslySetInnerHTML={{ __html: '<a href="javascript:window.print()">print</a>' }}></b>  a copy of this authorization and maintain it for your records.
                                        </Typography>
                                    }
                                    sx={{ '& .MuiFormControlLabel-asterisk': { display: 'none', }, alignItems: 'flex-start' }}
                                />
                            </Box>}
                        </Grid>
                    </Grid>}
                    <Grid container style={{ paddingTop: "8px" }}>
                        <Grid item xs>
                            <Button type="submit" fullWidth variant="contained" disabled={submitted}>PAY NOW</Button>
                        </Grid>
                    </Grid>
                </form>
            </AccordionDetails>
        </Accordion>
        <Dialog open={processing} fullWidth maxWidth="sm">
            <DialogTitle style={{ textAlign: 'center' }}><CircularProgress sx={{ color: 'blue', fontSize: 48 }} /></DialogTitle>
            <DialogContent style={{ textAlign: 'center' }}>                    
                <DialogContentText>Processing...</DialogContentText>
            </DialogContent>
        </Dialog>
        <Dialog open={complete} fullWidth maxWidth="sm">
            <DialogTitle style={{ textAlign: 'center' }}><CheckCircleOutlined sx={{ color: 'green', fontSize: 48 }} /></DialogTitle>
            <DialogContent style={{ textAlign: 'center' }}>
                <DialogContentText>Complete</DialogContentText>
            </DialogContent>
        </Dialog>
        <Dialog open={failure} fullWidth maxWidth="sm">
            <DialogTitle style={{ textAlign: 'center' }}><ErrorOutlineOutlined sx={{ color: 'red', fontSize: 48 }} /></DialogTitle>
            <DialogContent style={{ textAlign: 'center' }}>
                <DialogContentText>{errorMessage}</DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleFailure}>OK</Button>
            </DialogActions>
        </Dialog>
        </>
    );
};

const mapStateToProps = (state: IAppState) => {
    return {
        order: state.orderManagement.order,
        checkout: state.checkout.checkout,
        status: state.checkout.status,
        view: state.checkout.view,
        actionResult: state.orderManagement.actionResult,
        errorMessage: state.orderManagement.errorMessage
    };
};

export default connect(mapStateToProps)(Summary);