import {
    CLEAR_LINK_REQUEST,
    PROCESS_LINK_REQUEST,
    STATUS_LINK_REQUEST,
    VIEW_LINK_REQUEST,
} from '../actions/link';

export default function link(state = {
    link: null,
    status: null,
    view: null,
    actionResult: null
}, { type, payload }) {
    switch (type) {
        case CLEAR_LINK_REQUEST:
            return Object.assign({}, state, {
                link: null,
                actionResult: { type: CLEAR_LINK_REQUEST, result: null, token: payload.actionToken },
            });
        case PROCESS_LINK_REQUEST:
            return Object.assign({}, state, {
                link: payload.link,
                actionResult: { type: PROCESS_LINK_REQUEST, result: null, token: payload.actionToken },
            });
        case STATUS_LINK_REQUEST:
            return Object.assign({}, state, {
                status: payload.status,
                actionResult: { type: STATUS_LINK_REQUEST, result: null, token: payload.actionToken },
            });
        case VIEW_LINK_REQUEST:
            return Object.assign({}, state, {
                view: payload.view,
                actionResult: { type: VIEW_LINK_REQUEST, result: null, token: payload.actionToken },
            });
        default:
            return state;
    }
}


