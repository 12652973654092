import { Row, Col, Spinner, Card, Button, Form } from "react-bootstrap"
import { useEffect, useState } from "react";
import Table from '../../../../../../components/Table';
import { Order, OrderLine } from "../../../../../../models/Order";
import { updateShoppingCart, ADD_ORDER_LINE, calculateOrderAction, updateOrderDetailsAction, UPDATE_ORDER_DETAILS, addOrderLineAction } from '../../../../../../redux/actions/orderManagement';
import { IActionResult, IAppState } from '../../../../../../redux/storeTypes';
import { connect, useDispatch } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { resetItems } from "../../../../../../redux/actions/account";
import { ItemType, MetaData } from "../../../../../../models/Account";
import { DateWithoutUTC } from "../../../../../../components/Formatters";

interface IItemShoppingCart {
    title: string,
    subTitle: string,
    taxTypeLabel: string,
    hideTaxType: boolean,
    selectedInstallmentsLabel: string,
    hideSelectedInstallments: boolean,
    paymentAmountLabel: string,
    accountNumberLabel: string,
    balanceDueLabel: string,
    staticPaymentAmount: boolean,
    actionsLabel: string,
    margin: string,
    padding: string,
    headerBackgroundColor: string,
    headerPadding: string,
    actionResult: IActionResult,
    shoppingCart: any,
    order: Order,
}

export const TITLE = 'Shopping Cart';
export const SUBTITLE = 'The First half payment is due date by 2/28/2022, Second half payment is due by 6/15/2022, and Full payment is due by 4/30/2022.'
export const TAX_TYPE_LABEL = 'Tax type';
export const HIDE_TAX_TYPE = false;
export const SELECTED_INSTALLMENTS_LABEL = 'Selected Payment Plan'
export const HIDE_SELECTED_INSTALLMENTS = false;
export const ACCOUNT_NUMBER_LABEL = 'Account number';
export const PAYMENT_AMOUNT_LABEL = 'Payment Amount';
export const BALANCE_DUE_LABEL = 'Balance Due';
export const ACTIONS_LABEL = 'Actions';
export const MARGIN = '0px';
export const PADDING = '0px';
export const HEADER_BACKGROUND_COLOR = 'rgb(250, 250, 250)';
export const HEADER_PADDING = '0';

const checkStyleForDefault = (style: string, defaultStyle: string) => {
    return style && style !== '' ? style : defaultStyle;
}

const ItemShoppingCart = ({ title, subTitle, taxTypeLabel, hideTaxType, selectedInstallmentsLabel, hideSelectedInstallments, actionResult, accountNumberLabel, balanceDueLabel, actionsLabel,
    margin, padding, headerBackgroundColor, staticPaymentAmount, headerPadding, shoppingCart, order, paymentAmountLabel }: IItemShoppingCart) => {

    if (!title) { title = TITLE }
    if (!subTitle) { subTitle = SUBTITLE }
    if (!taxTypeLabel) { taxTypeLabel = TAX_TYPE_LABEL }
    if (!hideTaxType) { hideTaxType = HIDE_TAX_TYPE }
    if (!selectedInstallmentsLabel) { selectedInstallmentsLabel = SELECTED_INSTALLMENTS_LABEL }
    if (!hideSelectedInstallments) { hideSelectedInstallments = HIDE_SELECTED_INSTALLMENTS }
    if (!accountNumberLabel) { accountNumberLabel = ACCOUNT_NUMBER_LABEL }
    if (!paymentAmountLabel) { paymentAmountLabel = PAYMENT_AMOUNT_LABEL }
    if (!balanceDueLabel) { balanceDueLabel = BALANCE_DUE_LABEL }
    if (!actionsLabel) { actionsLabel = ACTIONS_LABEL }
    if (!margin) { margin = MARGIN }
    if (!padding) { padding = PADDING }
    if (!headerBackgroundColor) { headerBackgroundColor = HEADER_BACKGROUND_COLOR }
    if (!headerPadding) { headerPadding = HEADER_PADDING }

    const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
    });

    const amountFormatter = (cell: any) => {
        return (<span>{formatter.format(cell)}</span>)
    }

    const actionsFormatter = (cell: any, row: any) => {
        return (
            <Button size="sm" variant="outline-secondary" onClick={() => setDeleteRow(row)}>
                <FontAwesomeIcon icon={faTrash} />
            </Button>
        );

    }

    const taxFormatter = (cell: any, row: any) => {
        return (
            <span>{ItemType[cell]}</span>
        );

    }

    const orderLineFormatter = (cell: any, row: any) => {
        const selectedOrderLines = row?.selectedOrderLines;
         if (selectedOrderLines) {
            return selectedOrderLines.map((orderLine: any) => (
                (
                    <ul style={{paddingLeft:'1rem', marginBottom: '0.5rem'}}>
                        <li>
                        <h2>{orderLine?.id === 0 ? 'Full amount' : `${orderLine.name}`}</h2>
                        <p style={{ marginBottom: '0rem'}}>
                            { orderLine?.id === 0 ? `${formatter.format(orderLine.totalAmount)} Due on ${DateWithoutUTC(orderLine?.billDueDate)}` 
                            :`${orderLine.label}`}
                            </p>
                        </li>
                    </ul>
                )
            ))
        }
    }

    const balanceDueFormatter = (cell: any, row: any) => {
        if (staticPaymentAmount) {
            const todaysDate = new Date();
            const firstHalfDueDate = todaysDate < new Date(row?.metaData?.DateRangeFirstPO);
            const secondHalfDueDate = todaysDate < new Date(row?.metaData?.DateRangeSecondPO);
            const fullDueDate = todaysDate < new Date(row?.metaData?.DateRangeFullPO);
            const defaultBalanceDueRule = firstHalfDueDate ? row?.metaData?.FirstHalfDue : secondHalfDueDate ? row?.metaData?.SecondHalfDue : fullDueDate ? row?.metaData?.GrandTotal : ''
            return (<span>{formatter.format(defaultBalanceDueRule)}</span>)
        }
        else {
            return (<span>{formatter.format(cell)}</span>)
        }
    }

    const initalColumns = [{
        dataField: 'metaData.ItemType',
        text: taxTypeLabel,
        editable: false,
        hidden: hideTaxType,
        headerStyle: {
            backgroundColor: checkStyleForDefault(headerBackgroundColor, HEADER_BACKGROUND_COLOR)
        },
        formatter: taxFormatter,
        sort: true
    }, {
        dataField: 'metaData.AccountNumber',
        text: accountNumberLabel,
        editable: false,
        headerStyle: {
            backgroundColor: checkStyleForDefault(headerBackgroundColor, HEADER_BACKGROUND_COLOR)
        },
        sort: true,
    }, {
        dataField: 'selectedInstallments',
        text: selectedInstallmentsLabel,
        hidden: hideSelectedInstallments,
        editable: false,
        headerStyle: {
            backgroundColor: checkStyleForDefault(headerBackgroundColor, HEADER_BACKGROUND_COLOR)
        },
        sort: false,
        formatter: orderLineFormatter,
    }, {
        dataField: 'paymentAmount',
        text: paymentAmountLabel,
        editable: false,
        headerStyle: {
            backgroundColor: checkStyleForDefault(headerBackgroundColor, HEADER_BACKGROUND_COLOR)
        },
        sort: true,
        formatter: amountFormatter,
    }, {
        dataField: 'metaData.GrandTotal',
        text: balanceDueLabel,
        headerStyle: {
            backgroundColor: checkStyleForDefault(headerBackgroundColor, HEADER_BACKGROUND_COLOR)
        },
        editable: false,
        formatter: balanceDueFormatter,

    }, {
        dataField: 'id',
        text: actionsLabel,
        headerStyle: {
            backgroundColor: checkStyleForDefault(headerBackgroundColor, HEADER_BACKGROUND_COLOR)
        },
        formatter: actionsFormatter,
        editable: false,
    }];


    const [columns, setColumns] = useState<any>(initalColumns);
    const dispatch = useDispatch();
    const [deleteRow, setDeleteRow] = useState<any>('');
    const actionToken = 'ItemShoppingCart';


    useEffect(() => {
        const newColumns = columns.map((column: any) => {
            if (column.dataField === 'taxType') {
                return { ...column, hidden: hideTaxType }
            }
            if (column.dataField === 'selectedInstallments') {
                return { ...column, hidden: hideSelectedInstallments }
            }
            return column
        })
        setColumns(newColumns)
    }, [hideTaxType, hideSelectedInstallments]);

    useEffect(() => {
        if (shoppingCart && order) {
            let _shoppingCart = shoppingCart.map((shoppingCartDetails: any) => {
                if (shoppingCartDetails?.msbId != deleteRow?.msbId) {
                    return shoppingCartDetails
                }
            }).filter(Boolean)

            let _updatedOrderLines = order?.orderLines.map((orderDetails: any) => {
                if (orderDetails?.itemId != deleteRow?.msbId) {
                    return orderDetails
                }
            }).filter(Boolean)
            order.orderLines = _updatedOrderLines;
            dispatch(updateShoppingCart(_shoppingCart, actionToken));
            dispatch(updateOrderDetailsAction(order, actionToken));
            dispatch(resetItems());
        }
    }, [deleteRow]);

    useEffect(() => {
        if (actionResult && actionResult.type === ADD_ORDER_LINE) {
            dispatch(calculateOrderAction(actionToken));
        }
        if (actionResult && actionResult.type === UPDATE_ORDER_DETAILS) {
            dispatch(calculateOrderAction(actionToken));
        }

    }, [actionResult]);

    const renderTable = () => {
        const loading = false;
        if (loading) {
            return (<div style={{ textAlign: 'center' }} ><Spinner animation="border" /> </div>);
        } else if (shoppingCart?.length > 0) {
            return (
                <Table
                    keyField="msbId"
                    data={shoppingCart}
                    columns={columns}
                    remote={false}
                />);
        }
        else { return (<div>Your shopping cart is currently empty.</div>) }
    };

    return (
        <div style={{ margin: margin, padding: padding }}>
            <Row>
                <Col>
                    {renderTable()}
                </Col>
            </Row>
        </div>

    )
}

const mapStateToProps = (state: IAppState) => {
    return {
        isSaving: state.orderManagement.isSaving,
        actionResult: state.orderManagement.actionResult,
        order: state.orderManagement.order,
        shoppingCart: state.orderManagement.shoppingCart
    };
};

export default connect(mapStateToProps)(ItemShoppingCart);
