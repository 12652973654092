import axios from 'axios';

import { User, UserAuthRequest } from '../../models/User';
import { Dispatch } from 'react';
import store from "../../redux/store";
import { PublicClientApplication } from "@azure/msal-browser";

export const POST_AUTH_REQUEST = 'POST_AUTH_REQUEST';
export const POST_AUTH_SUCCESS = 'POST_AUTH_SUCCESS';
export const POST_AUTH_FAILURE = 'POST_AUTH_FAILURE';

export const SAVE_CURRENT_USER_REQUEST = 'SAVE_CURRENT_USER_REQUEST';
export const SAVE_CURRENT_USER_SUCCESS = 'SAVE_CURRENT_USER_SUCCESS';
export const SAVE_CURRENT_USER_FAILURE = 'SAVE_CURRENT_USER_FAILURE';

export const LOGOUT_REQUEST = 'LOGOUT_REQUEST';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';
export const RESET_REQUEST = 'RESET_REQUEST';
export const RESET_SUCCESS = 'RESET_SUCCESS';
export const PASSWORD_RESET_EMAIL_REQUEST = 'PASSWORD_RESET_EMAIL_REQUEST';
export const PASSWORD_RESET_EMAIL_SUCCESS = 'PASSWORD_RESET_EMAIL_SUCCESS';

export const CLEAR_AUTH_ACTION_RESULT = 'CLEAR_AUTH_ACTION_RESULT';

export const doLogOut = () => (dispatch: Dispatch<any>) => {
   let msalInstance = store.getState().auth.msalInstance as PublicClientApplication;
   msalInstance.logoutRedirect();
};

export const signInAction = (email:string, oid: string, msalInstance: PublicClientApplication, actionToken?: string, ) => (dispatch : Dispatch<any>) => {
    dispatch({
        type: POST_AUTH_REQUEST,
        payload: {
            actionToken
        }
    });
    
    let baseApiUrl = store.getState().webAppSettings.baseApiUrl;
    let _url = "/MSB_User/api/v1/Authentication/Authenticate";
    let bearerToken = store.getState().webAppSettings.bearerToken;

    axios.defaults.baseURL = baseApiUrl;
    axios.defaults.headers.common['Authorization'] = `Bearer ${bearerToken}`;

    let userAuthRequest = new UserAuthRequest();

    userAuthRequest.objectId = oid;
    userAuthRequest.email =  email!;

    axios.post(_url, userAuthRequest)
    .then(function (response) {
        let currentUser = response.data.user;
        let token = response.data.token;
        axios.defaults.headers.common['MsbBearer'] = `${token}`;

        dispatch({
            type: POST_AUTH_SUCCESS,
            payload: {
                currentUser,
                token,
                msalInstance,
                actionToken
            }
        });

    })
    .catch(function (error) {
        dispatch({
            type: POST_AUTH_FAILURE,
            payload: {
                error,
                actionToken
            }
        });
    });
};

export const clearActionResult = () => (dispatch: Dispatch<any>) => {
    dispatch({
        type: CLEAR_AUTH_ACTION_RESULT,
        payload: {
        }
    });
}

export const saveCurrentUserAction = (currentUser: User, actionToken?:string) => (dispatch: Dispatch<any>) => {
    dispatch({
        type: SAVE_CURRENT_USER_REQUEST,
        payload: {
            actionToken
        }
    });
    
    let _url = "/MSB_User/api/v1/Users/" + currentUser.msbId;

    axios.put(_url, currentUser)
        .then(function (response) {
            let currentUser = response.data;
            dispatch({
                type: SAVE_CURRENT_USER_SUCCESS,
                payload: {
                    currentUser,
                    actionToken
                }
            });
        })
        .catch(function (error) {
            dispatch({
                type: SAVE_CURRENT_USER_FAILURE,
                payload: {
                    error,
                    actionToken
                }
            });
        })
        .then(function () {
        }
    );
};