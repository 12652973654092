import { CellPlugin } from '@react-page/editor';
import LarimerShoppingCart, { TITLE, SUBTITLE, CITATION_NUMBER_LABEL, FULL_NAME_LABEL, ISSUE_DATE_LABEL, 
    AMOUNT_DUE_LABEL, ACTIONS_LABEL, HEADER_BACKGROUND_COLOR, HEADER_PADDING, MARGIN, PADDING,  
    CART_BUTTON_BACKGROUND_COLOR, CART_BUTTON_FONT_COLOR, CART_BUTTON_FONT_SIZE, 
    CART_BUTTON_FONT_STYLE, CART_BUTTON_FONT_WEIGHT, CART_BUTTON_BORDER_COLOR, CART_ICON_COLOR
} from './components/LarimerShoppingCart';

type Data = {
    title: string,
    subTitle: string,
    citationNumber: string,
    fullName: string,
    issueDate: string,
    amountDue: string,
    actions: string,
    headerBackgroundColor: string,
    headerPadding: string,
    margin: string,
    padding: string,
    cartButtonBackgroundColor: string,
    cartButtonFontColor: string,
    cartButtonFontSize: string,
    cartButtonFontStyle: string,
    cartButtonFontWeight: string,
    cartButtonBorderColor: string,
    cartIconColor: string
}

const LarimerShoppingCartPlugin: CellPlugin<Data> = {
    Renderer: ({ data }) => (
        <LarimerShoppingCart
            title = {data.title}
            subTitle={data.subTitle}
            citationNumberLabel={data.citationNumber}
            fullNameLabel={data.fullName}
            issueDateLabel={data.issueDate}
            amountDueLabel={data.amountDue}
            actionsLabel={data.actions}
            margin={data.margin}
            padding={data.padding}
            cartButtonBackgroundColor={data.cartButtonBackgroundColor}
            cartButtonFontColor={data.cartButtonFontColor}
            cartButtonFontSize={data.cartButtonFontSize}
            cartButtonFontStyle={data.cartButtonFontStyle}
            cartButtonFontWeight={data.cartButtonFontWeight}
            cartButtonBorderColor={data.cartButtonBorderColor}
            headerBackgroundColor={data.headerBackgroundColor}
            headerPadding={data.headerPadding}
            cartIconColor={data.cartIconColor}
        />
    ),
    id: 'larimerShoppingCartPlugin',
    title: 'Larimer Shopping Cart ',
    description: 'Adds items to shopping cart',
    version: 1,
    controls: [
        {
            title: 'Labels',
            controls: {
                type: 'autoform',
                schema: {
                    properties: {
                        title: {
                            type: 'string',
                            default: TITLE,
                        },
                        subTitle: {
                            type: 'string',
                            default: SUBTITLE,
                        },
                        citationNumber: {
                            type: 'string',
                            default: CITATION_NUMBER_LABEL,
                        },
                        fullName: {
                            type: 'string',
                            default: FULL_NAME_LABEL,
                        },
                        issueDate: {
                            type: 'string',
                            default: ISSUE_DATE_LABEL,
                        },
                        amountDue: {
                            type: 'string',
                            default: AMOUNT_DUE_LABEL,
                        },
                        actions: {
                            type: 'string',
                            default: ACTIONS_LABEL,
                        }
                    },
                    required: [],
                },
            },
        },
        {
            title: 'Styles',
            controls: {
                type: 'autoform',
                schema: {
                    properties: {
                        margin: {
                            type: 'string',
                            default: MARGIN,
                        },
                        padding: {
                            type: 'string',
                            default: PADDING,
                        },
                        headerBackgroundColor: {
                            type: 'string',
                            default: HEADER_BACKGROUND_COLOR,
                        },
                        headerPadding: {
                            type: 'string',
                            default: HEADER_PADDING,
                        },
                        cartButtonBackgroundColor: {
                            type: 'string',
                            default: CART_BUTTON_BACKGROUND_COLOR,
                        },
                        cartButtonFontColor: {
                            type: 'string',
                            default: CART_BUTTON_FONT_COLOR,
                        },
                        cartButtonFontSize: {
                            type: 'string',
                            default: CART_BUTTON_FONT_SIZE,
                        },
                        cartButtonFontStyle: {
                            type: 'string',
                            default: CART_BUTTON_FONT_STYLE,
                        },
                        cartButtonFontWeight: {
                            type: 'string',
                            default: CART_BUTTON_FONT_WEIGHT,
                        },
                        cartButtonBorderColor: {
                            type: 'string',
                            default: CART_BUTTON_BORDER_COLOR,
                        },
                        cartIconColor: {
                            type: 'string',
                            default: CART_ICON_COLOR
                        }
                        
                    },
                    required: [],
                },
            },
        },
    ],
};

export default LarimerShoppingCartPlugin;