import { CellPlugin } from '@react-page/editor';
import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { CurrencyFormatter } from '../../../../components/Formatters';

// use a type here, not an interface
type Data = {
    label: string,
    tag: string,
    value: any
}

const getValue = (value: any) => {

    if (value && value.length > 0) {
        return value;
    } else {
        var orderLine = [{}] as any;
        orderLine[0].itemReferenceNumber = "XYZ"
        orderLine[0].itemName = "Blind Payment";
        orderLine[0].quantity = "1";
        orderLine[0].unitPrice = "50";
        console.log(orderLine, 'orderLine')
        return orderLine;
    }
}

const OrderLinesPropertyPlugin: CellPlugin<Data> = {
    Renderer: ({ data }) => (
        <>
            <Row>
                <Col><strong>{data.label}</strong></Col>
                <Col>&nbsp;</Col>
            </Row>
            {
                (getValue(data.value) || []).map((orderLine: any, index: any) => (
                    <div style={{ marginBottom: "5px"}}>
                        <Row key={index}>
                            <Col>
                                <div><strong>Item Reference</strong></div>
                            </Col>
                            <Col>
                                <div style={{ wordBreak: "break-all" }}>{orderLine.itemReferenceNumber}</div>
                            </Col>
                        </Row>
                        {orderLine.itemName && <>
                        <Row key={index}>
                            <Col>
                                <div><strong>Item Name</strong></div>
                            </Col>
                            <Col>
                                <div style={{ wordBreak: "break-all" }}>{orderLine.itemName}</div>
                            </Col>
                        </Row>
                        </>}
                        <Row key={index}>
                            <Col>
                                <div>{orderLine.quantity} x {CurrencyFormatter(orderLine.unitPrice)}</div>
                            </Col>
                            <Col>
                                <div>
                                    {CurrencyFormatter(orderLine.quantity * orderLine.unitPrice)}
                                </div>
                            </Col>
                        </Row>
                    </div>
                ))
            }
        </>
    ),
    id: 'orderLinesPropertyPlugin',
    title: 'Order Lines Property',
    description: 'Order Lines Property.',
    version: 1,
    controls: {
        type: 'autoform',
        schema: {
            properties: {
                label: {
                    type: 'string',
                    default: 'Order Details',
                },
                tag: {
                    type: 'string',
                    default: 'Order Details',
                    readOnly: true
                },
            },
            required: ['label'],
        },
    },
};

export default OrderLinesPropertyPlugin;