import { CellPlugin } from '@react-page/editor';
import UserPaymentMethods,
{
    PAYMENT_TYPE_LABEL, CARD_TYPE_LABEL, ACCOUNT_NUMBER_LABEL,
    EXPIRATION_DATE_LABEL, ACTIONS_LABEL, HEADER_BACKGROUND_COLOR,
    NAME_ON_ACCOUNT_LABEL, NAME_ON_ACCOUNT_PLACEHOLDER, NAME_ON_ACCOUNT_REQUIRED,
    BANK_ACCOUNT_NUMBER_LABEL, BANK_ACCOUNT_NUMBER_REQUIRED, BANK_ROUTING_NUMBER_LABEL, BANK_ROUTING_NUMBER_REQUIRED,
    CONFIRM_BANK_ACCOUNT_NUMBER_LABEL, CONFIRM_BANK_ACCOUNT_NUMBER_REQUIRED, FINANCIAL_INSTITUTION_LABEL,
    CARDHOLDER_NAME_LABEL, CARDHOLDER_NAME_PLACEHOLDER, CARDHOLDER_NAME_REQUIRED, CARD_TYPE_REQUIRED, CARD_NUMBER_LABEL, CARD_NUMBER_REQUIRED,
    MODAL_CARD_TYPE_LABEL, MODAL_EXPIRATION_DATE_LABEL, EXPIRATION_DATE_REQUIRED, CVV_LABEL, CVV_REQUIRED,
    ADDRESS_LINE_1_LABEL, ADDRESS_LINE_1_PLACEHOLDER, ADDRESS_LINE_2_LABEL,
    ADDRESS_LINE_2_PLACEHOLDER, CITY_LABEL, CITY_PLACEHOLDER, STATE_LABEL,
    ZIP_CODE_LABEL, ZIP_CODE_REQUIRED
} from './components/UserPaymentMethods';

type Data = {
    title: string;
    paymentType: string,
    cardType: string,
    accountNumber: string,
    expirationDate: string,
    actions: string,
    headerBackgroundColor: string,
    cardholderName: string,
    cardholderNamePlaceholder: string,
    cardholderNameRequired: string,
    modalCardType: string,
    cardTypeRequired: string,
    cardNumber: string,
    cardNumberRequired: string,
    modalExpirationDate: string,
    expirationDateRequired: string,
    cvv: string,
    cvvRequired: string,
    nameOnAccount: string,
    nameOnAccountPlaceholder: string,
    nameOnAccountRequired: string,
    bankAccountNumber: string,
    bankAccountNumberRequired: string,
    confirmBankAccountNumber: string,
    confirmBankAccountNumberRequired: string,
    bankRoutingNumber: string,
    bankRoutingNumberRequired: string,
    financialInstitution: string,
    addressLine1: string,
    addressLine1Placeholder: string,
    addressLine2: string,
    addressLine2Placeholder: string,
    city: string,
    cityPlaceholder: string,
    state: string,
    zipCode: string,
    zipCodeRequired: string,
}

const UserPaymentMethodsPlugin: CellPlugin<Data> = {
    Renderer: ({ data }) => (
        <UserPaymentMethods
            paymentTypeLabel={data.paymentType}
            cardTypeLabel={data.cardType}
            accountNumberLabel={data.accountNumber}
            expirationDateLabel={data.expirationDate}
            actionsLabel={''}
            headerBackgroundColor={data.headerBackgroundColor}
            cardholderNameLabel={data.cardholderName}
            cardholderNamePlaceholder={data.cardholderNamePlaceholder}
            cardholderNameRequired={data.cardholderNameRequired}
            modalCardTypeLabel={data.modalCardType}
            cardTypeRequired={data.cardTypeRequired}
            cardNumberLabel={data.cardNumber}
            cardNumberRequired={data.cardNumberRequired}
            modalExpirationDateLabel={data.modalExpirationDate}
            expirationDateRequired={data.expirationDateRequired}
            cvvLabel={data.cvv}
            cvvRequired={data.cvvRequired}
            nameOnAccountLabel={data.nameOnAccount}
            nameOnAccountPlaceholder={data.nameOnAccountPlaceholder}
            nameOnAccountRequired={data.nameOnAccountRequired}
            bankAccountNumberLabel={data.bankAccountNumber}
            bankAccountNumberRequired={data.bankAccountNumberRequired}
            confirmBankAccountNumberLabel={data.confirmBankAccountNumber}
            confirmBankAccountNumberRequired={data.confirmBankAccountNumberRequired}
            bankRoutingNumberLabel={data.bankRoutingNumber}
            bankRoutingNumberRequired={data.bankRoutingNumberRequired}
            financialInstitutionLabel={data.financialInstitution}
            addressLine1Label={data.addressLine1}
            addressLine1Placeholder={data.addressLine1Placeholder}
            addressLine2Label={data.addressLine2}
            addressLine2Placeholder={data.addressLine2Placeholder}
            cityLabel={data.city}
            cityPlaceholder={data.cityPlaceholder}
            stateLabel={data.state}
            zipCodeLabel={data.zipCode}
            zipCodeRequired={data.zipCodeRequired}
        />
    ),
    id: 'userPaymentMethodsPlugin',
    title: 'Payment Methods',
    description: 'Payment Methods',
    version: 1,
    controls: [
        {
            title: 'Labels',
            controls: {
                type: 'autoform',
                schema: {
                    properties: {
                        paymentType: {
                            type: 'string',
                            default: PAYMENT_TYPE_LABEL,
                        },
                        cardType: {
                            type: 'string',
                            default: CARD_TYPE_LABEL,
                        },
                        accountNumber: {
                            type: 'string',
                            default: ACCOUNT_NUMBER_LABEL,
                        },
                        expirationDate: {
                            type: 'string',
                            default: EXPIRATION_DATE_LABEL,
                        },
                        actions: {
                            type: 'string',
                            default: ACTIONS_LABEL,
                        },
                    },
                    required: ['paymentType', 'cardType', 'accountNumber', 'expirationDate', 'actions'],
                },
            },
        },
        {
            title: 'Modal Labels',
            controls: {
                type: 'autoform',
                schema: {
                    properties: {
                        cardholderName: {
                            type: 'string',
                            default: CARDHOLDER_NAME_LABEL,
                        },
                        cardholderNamePlaceholder: {
                            type: 'string',
                            default: CARDHOLDER_NAME_PLACEHOLDER,
                        },
                        cardholderNameRequired: {
                            type: 'string',
                            default: CARDHOLDER_NAME_REQUIRED,
                        },
                        modalCardType: {
                            type: 'string',
                            default: MODAL_CARD_TYPE_LABEL,
                        },
                        cardTypeRequired: {
                            type: 'string',
                            default: CARD_TYPE_REQUIRED,
                        },
                        cardNumber: {
                            type: 'string',
                            default: CARD_NUMBER_LABEL,
                        },
                        cardNumberRequired: {
                            type: 'string',
                            default: CARD_NUMBER_REQUIRED,
                        },
                        modalExpirationDate: {
                            type: 'string',
                            default: MODAL_EXPIRATION_DATE_LABEL,
                        },
                        expirationDateRequired: {
                            type: 'string',
                            default: EXPIRATION_DATE_REQUIRED,
                        },
                        cvv: {
                            type: 'string',
                            default: CVV_LABEL,
                        },
                        cvvRequired: {
                            type: 'string',
                            default: CVV_REQUIRED,
                        },
                        nameOnAccount: {
                            type: 'string',
                            default: NAME_ON_ACCOUNT_LABEL,
                        },
                        nameOnAccountPlaceholder: {
                            type: 'string',
                            default: NAME_ON_ACCOUNT_PLACEHOLDER,
                        },
                        nameOnAccountRequired: {
                            type: 'string',
                            default: NAME_ON_ACCOUNT_REQUIRED,
                        },
                        bankAccountNumber: {
                            type: 'string',
                            default: BANK_ACCOUNT_NUMBER_LABEL,
                        },
                        bankAccountNumberRequired: {
                            type: 'string',
                            default: BANK_ACCOUNT_NUMBER_REQUIRED,
                        },
                        confirmBankAccountNumber: {
                            type: 'string',
                            default: CONFIRM_BANK_ACCOUNT_NUMBER_LABEL,
                        },
                        confirmBankAccountNumberRequired: {
                            type: 'string',
                            default: CONFIRM_BANK_ACCOUNT_NUMBER_REQUIRED,
                        },
                        bankRoutingNumber: {
                            type: 'string',
                            default: BANK_ROUTING_NUMBER_LABEL,
                        },
                        bankRoutingNumberRequired: {
                            type: 'string',
                            default: BANK_ROUTING_NUMBER_REQUIRED,
                        },
                        financialInstitution: {
                            type: 'string',
                            default: FINANCIAL_INSTITUTION_LABEL,
                        },
                        addressLine1: {
                            type: 'string',
                            default: ADDRESS_LINE_1_LABEL,
                        },
                        addressLine1Placeholder: {
                            type: 'string',
                            default: ADDRESS_LINE_1_PLACEHOLDER,
                        },
                        addressLine2: {
                            type: 'string',
                            default: ADDRESS_LINE_2_LABEL,
                        },
                        addressLine2Placeholder: {
                            type: 'string',
                            default: ADDRESS_LINE_2_PLACEHOLDER,
                        },
                        city: {
                            type: 'string',
                            default: CITY_LABEL,
                        },
                        cityPlaceholder: {
                            type: 'string',
                            default: CITY_PLACEHOLDER,
                        },
                        state: {
                            type: 'string',
                            default: STATE_LABEL,
                        },
                        zipCode: {
                            type: 'string',
                            default: ZIP_CODE_LABEL,
                        },

                    },
                    required: [],
                },
            },
        },
        {
            title: 'Styles',
            controls: {
                type: 'autoform',
                schema: {
                    properties: {
                        headerBackgroundColor: {
                            type: 'string',
                            default: HEADER_BACKGROUND_COLOR,
                        }
                    },
                    required: [],
                },
            },
        }
    ],
};

export default UserPaymentMethodsPlugin;