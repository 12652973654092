import { CheckCircleOutlined, ErrorOutlineOutlined, ExpandMore } from "@mui/icons-material";
import { Accordion, AccordionSummary, Typography, Box, AccordionDetails, TableContainer, Paper, Table, TableHead, TableRow, TableCell, TableBody, Button } from "@mui/material";
import { IAppState } from "../../redux/storeTypes";
import { connect, useDispatch } from "react-redux";
import { Checkout, CheckoutStatus, CheckoutView, InvoiceItem } from "../../models/CheckoutLink";
import { useEffect, useState } from "react";
import { statusCheckoutAction, viewCheckoutAction } from "../../redux/actions/checkout";
import { Order, OrderLine } from "../../models/Order";
import { updateOrderDetailsAction } from "../../redux/actions/orderManagement";

export interface IInvoiceProps {
    order: Order,
    checkout: Checkout;
    status: CheckoutStatus;
    view: CheckoutView;
}

const Invoice = ({ order, checkout, status, view }: IInvoiceProps) => {
    const actionToken = "CheckoutPage"
    const dispatch = useDispatch();
    const [expanded, setExpanded] = useState(true);
    const [invoiceItems, setInvoiceItems] = useState<Array<InvoiceItem>>(new Array<InvoiceItem>());
    const [subtotal, setSubtotal] = useState<number>(0.00);

    useEffect(() => {
        if (checkout) {
            setInvoiceItems(checkout.invoice || []);
            setSubtotal(checkout.invoice && checkout.invoice.length > 0 ? checkout.invoice.reduce((acc, obj) => acc + parseFloat(obj.amount), 0) : parseFloat(checkout.amount));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [checkout]);

    useEffect(() => {
        if (status) {
            if (!status.invoice) setExpanded(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [status]);

    useEffect(() => {
        if (status.invoice && view !== CheckoutView.INVOICE) setExpanded(false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [view]);

    const statusInvoice = () => {
        setExpanded(false);
        dispatch(statusCheckoutAction({ ...status, invoice: true }));
        order.orderLines = [];
        if (checkout.invoice.length > 0) {
            checkout.invoice.forEach((item) => {
                let orderLine = new OrderLine();
                orderLine.itemName = item.name;
                orderLine.itemReferenceNumber = item.reference;
                orderLine.unitPrice = Number(item.amount);
                orderLine.quantity = 1;
                orderLine.itemMetaData = JSON.parse(item.supplementalData || "{}");
                order.orderLines.push(orderLine);
            });
        } else {
            let orderLine = new OrderLine();
            orderLine.itemName = "N/A";
            orderLine.itemReferenceNumber = "N/A";
            orderLine.unitPrice = subtotal;
            orderLine.quantity = 1;
            orderLine.itemMetaData = {};
            order.orderLines.push(orderLine);
        }
        order.amount = subtotal;
        dispatch(updateOrderDetailsAction(order, actionToken));
    }

    const handleExpansion = () => {
        const _expanded = expanded ? !status?.invoice : true && status?.payBy;
        setExpanded(_expanded);
        if (_expanded) {
            dispatch(viewCheckoutAction(CheckoutView.INVOICE));
        } else {
            dispatch(viewCheckoutAction(CheckoutView.NONE));
        }
    }

    return (
        <>
            <Accordion defaultExpanded={true} expanded={expanded} disableGutters={true}>
                <AccordionSummary expandIcon={<ExpandMore onClick={handleExpansion} />}>
                    <Typography style={{ flex: 1 }}><Box component="span">{status?.invoice ? < CheckCircleOutlined sx={{ color: 'green', verticalAlign: 'bottom' }} /> : <ErrorOutlineOutlined sx={{ color: 'red', verticalAlign: 'bottom' }} />} Invoice</Box></Typography>
                    <Box component="div" textAlign={'center'} display={'flex'}>{subtotal.toLocaleString('en-US', { style: 'currency', currency: 'USD' })}</Box>
                </AccordionSummary>
                <AccordionDetails>
                    {invoiceItems.length > 0 &&
                    <TableContainer component={Paper} sx={{ maxHeight: 257 }}>
                        <Table size="small">
                            <TableHead>
                                <TableRow>
                                    <TableCell sx={{ fontWeight: "bold" }}>Description<br />Reference</TableCell>
                                    <TableCell sx={{ fontWeight: "bold" }} align="right">Amount</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {invoiceItems.map((row) => (
                                    <TableRow key={row.reference} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                        <TableCell component="th" scope="row">{row.name}<br />{row.reference}</TableCell>
                                        <TableCell align="right">{parseFloat(row.amount).toLocaleString('en-US', { style: 'currency', currency: 'USD' })}</TableCell>
                                    </TableRow>
                                ))}
                                <TableRow>
                                    <TableCell align="right" colSpan={1}>Subtotal</TableCell>
                                    <TableCell align="right">{subtotal.toLocaleString('en-US', { style: 'currency', currency: 'USD' })}</TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>}
                    <Box sx={{ textAlign: 'center', marginTop: '8px' }}>
                        <Button type="button" fullWidth variant="contained" size='small' color="primary" onClick={statusInvoice}>CONTINUE</Button>
                    </Box>
                </AccordionDetails>
            </Accordion>
        </>
    );
};

const mapStateToProps = (state: IAppState) => {
    return {
        order: state.orderManagement.order,
        checkout: state.checkout.checkout,
        status: state.checkout.status,
        view: state.checkout.view,
    };
};

export default connect(mapStateToProps)(Invoice);