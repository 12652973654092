import React from "react";
import { connect } from "react-redux";
import { Card, Col, Container, Form, Row } from "react-bootstrap";
import Switch from "react-switch";
import Breadcrumb from '../components/layout/Breadcrumb';
import PageFooter from "../components/layout/PageFooter";
import PageHeader from "../components/layout/PageHeader";
import { IAppState } from "../redux/storeTypes";
import { useState } from "react";
import FormActions from "../components/layout/FormActions";

const PaymentSettings = () => {
    const [autoPayments, setAutoPayments] = useState(false);
    const [paperless, setPaperless] = useState(false);
    const [schedulePayment, setSchedulePayment] = useState(false);
    const clearForm = () => {

    }
    return (
        <>
            <PageHeader />

            <Container className="mt-4">
                <Breadcrumb name='Home/Auto pay & Paperless' />

                <Card>
                    <Card.Header>
                        <h2>Automatic payments</h2>
                        <span>{autoPayments ? 'Enabled' : 'Disabled'}</span>
                        <Switch
                            onChange={setAutoPayments}
                            checked={autoPayments}
                            onColor={'#2C9942'}
                            offColor={'#BEBEBE'}
                            handleDiameter={12}
                            uncheckedIcon={false}
                            checkedIcon={false}
                            height={16}
                            width={28}
                        />
                    </Card.Header>
                    <Card.Body>
                        <Form>
                            <Form.Group as={Row} controlId="paymentAmount">
                                <Form.Label column sm="6">
                                    Next payment amount
                                </Form.Label>
                                <Col sm="6">
                                    <Form.Control plaintext readOnly defaultValue="$200" />
                                </Col>
                            </Form.Group>

                            <Form.Group as={Row} controlId="scheduledDate">
                                <Form.Label column sm="6">
                                    Scheduled date
                                </Form.Label>
                                <Col sm="6">
                                    <Form.Control plaintext readOnly placeholder="Tuesday, August 3, 2021" />
                                </Col>
                            </Form.Group>
                            <Form.Group as={Row} controlId="paymentMethod">
                                <Form.Label column sm="6">
                                    Payment method
                                </Form.Label>
                                <Col sm="6">
                                    <Form.Control plaintext readOnly placeholder="Mastercard ending in 1234" />
                                </Col>
                            </Form.Group>
                        </Form>
                    </Card.Body>
                </Card>

                <Card>
                    <Card.Header className="border-0">
                        <h2>Paperless billing</h2>
                        <span>{paperless ? 'Enabled' : 'Disabled'}</span>
                        <Switch
                            onChange={setPaperless}
                            checked={paperless}
                            onColor={'#2C9942'}
                            offColor={'#BEBEBE'}
                            handleDiameter={12}
                            uncheckedIcon={false}
                            checkedIcon={false}
                            height={16}
                            width={28}
                        />
                    </Card.Header>                    
                </Card>

                <Card>
                    <Card.Header>
                        <h2>Schedule payment</h2>
                        <span>{schedulePayment ? 'Enabled' : 'Disabled'}</span>
                        <Switch
                            onChange={setSchedulePayment}
                            checked={schedulePayment}
                            onColor={'#2C9942'}
                            offColor={'#BEBEBE'}
                            handleDiameter={12}
                            uncheckedIcon={false}
                            checkedIcon={false}
                            height={16}
                            width={28}
                        />
                    </Card.Header>
                    <Card.Body>
                        <Form>
                            <Form.Group as={Row} controlId="paymentAmount">
                                <Form.Label column sm="8">
                                    Select payment date
                                </Form.Label>
                                <Col sm="4">
                                    <Form.Group controlId="paymentDate">
                                        <Form.Control type="date" name="paymentDate" />
                                    </Form.Group>
                                </Col>
                            </Form.Group>
                        </Form>
                    </Card.Body>
                </Card>
                
                <div>
                    <FormActions hasSubmit customProps={{ submitButtonDisplay: 'Save' }} onCancel={clearForm} />
                </div>
            </Container>

            <PageFooter />
        </>
    );
};

const mapStateToProps = (state: IAppState) => {
    return {
    };
};

export default connect(mapStateToProps)(PaymentSettings);




