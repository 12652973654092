import {
    SET_CONFIG, SET_PUBLIC_CLIENT_APPLICATION, GET_WEB_APP_SETTINGS_REQUEST, GET_WEB_APP_SETTINGS_SUCCESS, GET_WEB_APP_SETTINGS_FAILURE
} from '../actions/webAppSettings';

export default function webAppSettings(state = {
    environment: '',
    baseApiUrl: '',
    bearerToken: '',
    nexusUrl: '',
    siteKey: '',
    clientApiMock: false,
    publicClientApplication: null,
    actionResult: null
}, { type, payload }) {
    switch (type) {
        case SET_CONFIG:
            return Object.assign({}, state, {
                environment: payload.environment,
                baseApiUrl: payload.baseApiUrl,
                clientApiMock: payload.clientApiMock
            });
        case SET_PUBLIC_CLIENT_APPLICATION:
            return Object.assign({}, state, {
                publicClientApplication: payload.publicClientApplication
            });
        case GET_WEB_APP_SETTINGS_REQUEST:
            return Object.assign({}, state, {
                isFetching: true,
                actionResult: { type: GET_WEB_APP_SETTINGS_REQUEST, result: null, token: payload.actionToken } ,
            });
        case GET_WEB_APP_SETTINGS_SUCCESS:
            return Object.assign({}, state, {
                isFetching: false,
                environment: payload.webAppSettings.environment,
                baseApiUrl: payload.webAppSettings.wsmBaseUrl,
                bearerToken: payload.webAppSettings.wsmBearerToken,
                nexusUrl: payload.webAppSettings.nexusUrl,
                siteKey: payload.webAppSettings.siteKey,
                actionResult: { type: GET_WEB_APP_SETTINGS_REQUEST, result: GET_WEB_APP_SETTINGS_SUCCESS, token: payload.actionToken } ,
            });
        case GET_WEB_APP_SETTINGS_FAILURE:
            return Object.assign({}, state, {
                isFetching: false,
                actionResult: { type: GET_WEB_APP_SETTINGS_REQUEST, result: GET_WEB_APP_SETTINGS_FAILURE, token: payload.actionToken } ,
                errorMessage: payload.error
            });
        default:
            return state;
    }
}


