import {
    GET_ITEMS_REQUEST, GET_ITEMS_SUCCESS, GET_ITEMS_FAILURE,
    GET_USER_PAYMENT_METHODS_REQUEST, GET_USER_PAYMENT_METHODS_SUCCESS, GET_USER_PAYMENT_METHODS_FAILURE,
    ADD_CREDIT_CARD_REQUEST, ADD_CREDIT_CARD_SUCCESS, ADD_CREDIT_CARD_FAILURE,
    ADD_BANK_ACCOUNT_REQUEST, ADD_BANK_ACCOUNT_SUCCESS, ADD_BANK_ACCOUNT_FAILURE,
    DELETE_CREDIT_CARD_REQUEST, DELETE_CREDIT_CARD_SUCCESS, DELETE_CREDIT_CARD_FAILURE,
    DELETE_BANK_ACCOUNT_REQUEST, DELETE_BANK_ACCOUNT_SUCCESS, DELETE_BANK_ACCOUNT_FAILURE,
    CALCULATE_CONVENIENCE_FEE, CALCULATE_CONVENIENCE_FEE_SUCCESS, CALCULATE_CONVENIENCE_FEE_FAILURE, RESET_CONVENIENCE_FEE_REQUEST,
    SCHEDULE_PAYMENT_REQUEST, SCHEDULE_PAYMENT_SUCCESS, SCHEDULE_PAYMENT_FAILURE,UPDATED_SCHEDULE_PAYMENT_REQUEST, 
    UPDATED_SCHEDULE_PAYMENT_SUCCESS, UPDATED_SCHEDULE_PAYMENT_FAILURE,
    SCHEDULE_ITEMS_REQUEST, SCHEDULE_ITEMS_SUCCESS, SCHEDULE_ITEMS_FAILURE,
    DELETE_PAYMENT_REQUEST, DELETE_PAYMENT_SUCCESS, DELETE_PAYMENT_FAILURE,
    MULTI_ITEMS_SEARCH_REQUEST,MULTI_ITEMS_SEARCH_SUCCESS,MULTI_ITEMS_SEARCH_FAILURE,MULTI_ITEMS_RESET_REQUEST,
    RESET_BANK_NAME, RETRIEVE_BANK_NAME, SUBMIT_ADDRESS_CHANGE_REQUEST, SUBMIT_ADDRESS_CHANGE_SUCCESS, SUBMIT_ADDRESS_CHANGE_FAILURE,
    SUBMIT_ADDRESS_RESET_REQUEST
} from '../actions/account';

export default function account(state = {
    isFetching: false,
    userPaymentsLoading: false,
    itemSearchLoading: false,
    scheduledPaymentsLoading: false,
    isSaving: false,
    itemDetails: null,
    actionResult: null,
    user: null,
    order: null,
    scheduledItems: null,
    currentSchedule: null,
    paymentMethodUniqueId: null,
    bankName: '',
    errorMessage: ''
}, { type, payload }) {
    switch (type) {
        case GET_ITEMS_REQUEST:
            return Object.assign({}, state, {
                isFetching: true,
                itemSearchLoading: true,
                actionResult: { type: GET_ITEMS_REQUEST, result: null, token: payload.actionToken },
            });
        case GET_ITEMS_SUCCESS:
            return Object.assign({}, state, {
                isFetching: false,
                itemSearchLoading: false,
                itemDetails: payload.items,
                actionResult: { type: GET_ITEMS_REQUEST, result: GET_ITEMS_SUCCESS, token: payload.actionToken },
            });
        case GET_ITEMS_FAILURE:
            return Object.assign({}, state, {
                isFetching: false,
                itemSearchLoading: false,
                actionResult: { type: GET_ITEMS_REQUEST, result: GET_ITEMS_FAILURE, token: payload.actionToken },
                errorMessage: payload.error
            });
        case GET_USER_PAYMENT_METHODS_REQUEST:
            return Object.assign({}, state, {
                isFetching: true,
                userPaymentsLoading: true,
                actionResult: { type: GET_USER_PAYMENT_METHODS_REQUEST, result: null, token: payload.actionToken },
            });
        case GET_USER_PAYMENT_METHODS_SUCCESS:
            return Object.assign({}, state, {
                isFetching: false,
                userPaymentsLoading: false,
                user: payload.user,
                actionResult: { type: GET_USER_PAYMENT_METHODS_REQUEST, result: GET_USER_PAYMENT_METHODS_SUCCESS, token: payload.actionToken },
            });
        case GET_USER_PAYMENT_METHODS_FAILURE:
            return Object.assign({}, state, {
                isFetching: false,
                userPaymentsLoading: false,
                actionResult: { type: GET_USER_PAYMENT_METHODS_REQUEST, result: GET_USER_PAYMENT_METHODS_FAILURE, token: payload.actionToken },
                errorMessage: payload.error
            });
        case ADD_CREDIT_CARD_REQUEST:
            return Object.assign({}, state, {
                isSaving: true,
                actionResult: { type: ADD_CREDIT_CARD_REQUEST, result: null, token: payload.actionToken },
            });
        case ADD_CREDIT_CARD_SUCCESS:
            return Object.assign({}, state, {
                isSaving: false,
                actionResult: { type: ADD_CREDIT_CARD_REQUEST, result: ADD_CREDIT_CARD_SUCCESS, token: payload.actionToken },
                paymentMethodUniqueId: payload.paymentMethodUniqueId,
            });
        case ADD_CREDIT_CARD_FAILURE:
            return Object.assign({}, state, {
                isSaving: false,
                actionResult: { type: ADD_CREDIT_CARD_REQUEST, result: ADD_CREDIT_CARD_FAILURE, token: payload.actionToken },
                errorMessage: payload.error
            });
        case ADD_BANK_ACCOUNT_REQUEST:
            return Object.assign({}, state, {
                isSaving: true,
                actionResult: { type: ADD_BANK_ACCOUNT_REQUEST, result: null, token: payload.actionToken },
            });
        case ADD_BANK_ACCOUNT_SUCCESS:
            return Object.assign({}, state, {
                isSaving: false,
                actionResult: { type: ADD_BANK_ACCOUNT_REQUEST, result: ADD_BANK_ACCOUNT_SUCCESS, token: payload.actionToken },
                paymentMethodUniqueId: payload.paymentMethodUniqueId,
            });
        case ADD_BANK_ACCOUNT_FAILURE:
            return Object.assign({}, state, {
                isSaving: false,
                actionResult: { type: ADD_BANK_ACCOUNT_REQUEST, result: ADD_BANK_ACCOUNT_FAILURE, token: payload.actionToken },
                errorMessage: payload.error
            });
        case DELETE_CREDIT_CARD_REQUEST:
            return Object.assign({}, state, {
                isFetching: true,
                actionResult: { type: DELETE_CREDIT_CARD_REQUEST, result: null, token: payload.actionToken },
            });
        case DELETE_CREDIT_CARD_SUCCESS:
            return Object.assign({}, state, {
                isFetching: false,
                actionResult: { type: DELETE_CREDIT_CARD_REQUEST, result: DELETE_CREDIT_CARD_SUCCESS, token: payload.actionToken },
                paymentMethodUniqueId: payload.paymentMethodUniqueId
            });
        case DELETE_CREDIT_CARD_FAILURE:
            return Object.assign({}, state, {
                isFetching: false,
                actionResult: { type: DELETE_CREDIT_CARD_REQUEST, result: DELETE_CREDIT_CARD_FAILURE, token: payload.actionToken },
                errorMessage: payload.error
            });
        case DELETE_BANK_ACCOUNT_REQUEST:
            return Object.assign({}, state, {
                isFetching: true,
                actionResult: { type: DELETE_BANK_ACCOUNT_REQUEST, result: null, token: payload.actionToken },
            });
        case DELETE_BANK_ACCOUNT_SUCCESS:
            return Object.assign({}, state, {
                isFetching: false,
                actionResult: { type: DELETE_BANK_ACCOUNT_REQUEST, result: DELETE_BANK_ACCOUNT_SUCCESS, token: payload.actionToken },
                paymentMethodUniqueId: payload.paymentMethodUniqueId
            });
        case DELETE_BANK_ACCOUNT_FAILURE:
            return Object.assign({}, state, {
                isFetching: false,
                actionResult: { type: DELETE_BANK_ACCOUNT_REQUEST, result: DELETE_BANK_ACCOUNT_FAILURE, token: payload.actionToken },
                errorMessage: payload.error
            });
        case CALCULATE_CONVENIENCE_FEE:
            return Object.assign({}, state, {
                isFetching: true,
                order: null,
                errorMessage: null,
                actionResult: { type: CALCULATE_CONVENIENCE_FEE, result: null, token: payload.actionToken },
            });
        case CALCULATE_CONVENIENCE_FEE_SUCCESS:
            return Object.assign({}, state, {
                isFetching: false,
                order: payload.order,
                actionResult: { type: CALCULATE_CONVENIENCE_FEE, result: CALCULATE_CONVENIENCE_FEE_SUCCESS, token: payload.actionToken }
            });
        case CALCULATE_CONVENIENCE_FEE_FAILURE:
            return Object.assign({}, state, {
                isFetching: false,
                order: null,
                actionResult: { type: CALCULATE_CONVENIENCE_FEE, result: CALCULATE_CONVENIENCE_FEE_FAILURE, token: payload.actionToken },
                errorMessage: payload.error
            });
        case RESET_CONVENIENCE_FEE_REQUEST:
            return Object.assign({}, state, {
                isFetching: false,
                order: null,
                actionResult: null,
            });
        case SCHEDULE_PAYMENT_REQUEST:
            return Object.assign({}, state, {
                isSaving: true,
                actionResult: { type: SCHEDULE_PAYMENT_REQUEST, result: null, token: payload.actionToken },
            });
        case SCHEDULE_PAYMENT_SUCCESS:
            return Object.assign({}, state, {
                isSaving: false,
                actionResult: { type: SCHEDULE_PAYMENT_REQUEST, result: SCHEDULE_PAYMENT_SUCCESS, token: payload.actionToken },
                currentSchedule: payload.currentSchedule,
            });
        case SCHEDULE_PAYMENT_FAILURE:
            return Object.assign({}, state, {
                isSaving: false,
                actionResult: { type: SCHEDULE_PAYMENT_REQUEST, result: SCHEDULE_PAYMENT_FAILURE, token: payload.actionToken },
                errorMessage: payload.error
            });
        case UPDATED_SCHEDULE_PAYMENT_REQUEST:
            return Object.assign({}, state, {
                isFetching: true,
                isSaving: true,
                actionResult: { type: UPDATED_SCHEDULE_PAYMENT_REQUEST, result: null, token: payload.actionToken },
            });
        case UPDATED_SCHEDULE_PAYMENT_SUCCESS:
            return Object.assign({}, state, {
                isFetching: false,
                isSaving: false,
                actionResult: { type: UPDATED_SCHEDULE_PAYMENT_REQUEST, result: UPDATED_SCHEDULE_PAYMENT_SUCCESS, token: payload.actionToken },
                currentSchedule: payload.currentSchedule,
            });
        case UPDATED_SCHEDULE_PAYMENT_FAILURE:
            return Object.assign({}, state, {
                isFetching: false,
                isSaving: false,
                actionResult: { type: UPDATED_SCHEDULE_PAYMENT_REQUEST, result: UPDATED_SCHEDULE_PAYMENT_FAILURE, token: payload.actionToken },
                errorMessage: payload.error
            });
        case SCHEDULE_ITEMS_REQUEST:
            return Object.assign({}, state, {
                isFetching: true,
                scheduledPaymentsLoading: true,
                actionResult: { type: SCHEDULE_ITEMS_REQUEST, result: null, token: payload.actionToken },
            });
        case SCHEDULE_ITEMS_SUCCESS:
            return Object.assign({}, state, {
                isFetching: false,
                scheduledPaymentsLoading: false,
                actionResult: { type: SCHEDULE_ITEMS_REQUEST, result: SCHEDULE_ITEMS_SUCCESS, token: payload.actionToken },
                scheduledItems: payload.userItems,
            });
        case SCHEDULE_ITEMS_FAILURE:
            return Object.assign({}, state, {
                isFetching: false,
                scheduledPaymentsLoading: false,
                actionResult: { type: SCHEDULE_ITEMS_REQUEST, result: SCHEDULE_ITEMS_FAILURE, token: payload.actionToken },
                errorMessage: payload.error
            });
        case DELETE_PAYMENT_REQUEST:
            return Object.assign({}, state, {
                isFetching: true,
                actionResult: { type: DELETE_PAYMENT_REQUEST, result: null, token: payload.actionToken },
            });
        case DELETE_PAYMENT_SUCCESS:
            return Object.assign({}, state, {
                isFetching: false,
                actionResult: { type: DELETE_PAYMENT_REQUEST, result: DELETE_PAYMENT_SUCCESS, token: payload.actionToken },
                currentSchedule: payload.currentSchedule
            });
        case DELETE_PAYMENT_FAILURE:
            return Object.assign({}, state, {
                isFetching: false,
                actionResult: { type: DELETE_PAYMENT_REQUEST, result: DELETE_PAYMENT_FAILURE, token: payload.actionToken },
                errorMessage: payload.error
            });
        case RESET_BANK_NAME:
            return Object.assign({}, state, {
                bankName: ''
            });
        case RETRIEVE_BANK_NAME:
            return Object.assign({}, state, {
                bankName: payload.bankName,
                actionResult: { type: RETRIEVE_BANK_NAME, result: null, token: null },
            });
        case MULTI_ITEMS_SEARCH_REQUEST:
            return Object.assign({}, state, {
                isFetching: true,
                actionResult: { type: MULTI_ITEMS_SEARCH_REQUEST, result: null, token: payload.actionToken },
            });
        case MULTI_ITEMS_SEARCH_SUCCESS:
            return Object.assign({}, state, {
                isFetching: false,
                actionResult: { type: MULTI_ITEMS_SEARCH_REQUEST, result: MULTI_ITEMS_SEARCH_SUCCESS, token: payload.actionToken },
                itemDetails: payload.multiItems,
            });
        case MULTI_ITEMS_SEARCH_FAILURE:
            return Object.assign({}, state, {
                isFetching: false,
                actionResult: { type: MULTI_ITEMS_SEARCH_REQUEST, result: MULTI_ITEMS_SEARCH_FAILURE, token: payload.actionToken },
                errorMessage: payload.error,
                itemDetails: []
            });
        case SUBMIT_ADDRESS_CHANGE_REQUEST:
            return Object.assign({}, state, {
                isSaving: true,
                actionResult: { type: SUBMIT_ADDRESS_CHANGE_REQUEST, result: null, token: payload.actionToken },
            });
        case SUBMIT_ADDRESS_CHANGE_SUCCESS:
            return Object.assign({}, state, {
                isSaving: false,
                actionResult: { type: SUBMIT_ADDRESS_CHANGE_REQUEST, result: SUBMIT_ADDRESS_CHANGE_SUCCESS, token: payload.actionToken },
            });
        case SUBMIT_ADDRESS_CHANGE_FAILURE:
            return Object.assign({}, state, {
                isSaving: false,
                actionResult: { type: SUBMIT_ADDRESS_CHANGE_REQUEST, result: SUBMIT_ADDRESS_CHANGE_FAILURE, token: payload.actionToken },
            });
        case SUBMIT_ADDRESS_RESET_REQUEST:
            return Object.assign({}, state, {
                isFetching: false,
                actionResult: {}
            });
        case MULTI_ITEMS_RESET_REQUEST:
            return Object.assign({}, state, {
                isFetching: false,
                itemDetails: [],
            });

        default:
            return state;
    }
}


