import {
    CLEAR_CHECKOUT_REQUEST,
    PROCESS_CHECKOUT_REQUEST,
    STATUS_CHECKOUT_REQUEST,
    VIEW_CHECKOUT_REQUEST,
} from '../actions/checkout';

export default function checkout(state = {
        checkout: null,
        status: null,
        view: null,
        actionResult: null
    }, { type, payload }) {
    switch (type) {
        case CLEAR_CHECKOUT_REQUEST:
            return Object.assign({}, state, {
                checkout: null,
                actionResult: { type: CLEAR_CHECKOUT_REQUEST, result: null, token: payload.actionToken },
            });
        case PROCESS_CHECKOUT_REQUEST:
            return Object.assign({}, state, {
                checkout: payload.checkout,
                actionResult: { type: PROCESS_CHECKOUT_REQUEST, result: null, token: payload.actionToken },
            });
        case STATUS_CHECKOUT_REQUEST:
            return Object.assign({}, state, {
                status: payload.status,
                actionResult: { type: STATUS_CHECKOUT_REQUEST, result: null, token: payload.actionToken },
            });
        case VIEW_CHECKOUT_REQUEST:
            return Object.assign({}, state, {
                view: payload.view,
                actionResult: { type: VIEW_CHECKOUT_REQUEST, result: null, token: payload.actionToken },
            });
        default:
            return state;
    }
}


