import { Grid, Box, Dialog, DialogTitle, DialogContent, DialogContentText, IconButton } from "@mui/material";
import { Link } from "../../models/CheckoutLink";
import { IAppState } from "../../redux/storeTypes";
import { connect } from "react-redux";
import { CancelOutlined } from "@mui/icons-material";
import { useState } from "react";

export interface IHeaderProps {
    msg?: any;
    link: Link;
}

const Header = ({ msg, link }: IHeaderProps) => {
    const [cancelled, setCancelled] = useState(false);
    //const [countdown, setCountdown] = useState(480);

    /*
    useEffect(() => {
        if (countdown === 0) {
            msg({ Response: "CANCEL", Message: "Session Expired", ID: checkout.uniqueId });
            return;
        }
        const interval = setInterval(() => {
            setCountdown(countdown - 1);
        }, 1000);
        return () => clearInterval(interval);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [countdown]);
    */

    const handleCancel = () => {
        setCancelled(true);
        msg({ Response: "CANCEL", Message: "User Initiated", ID: link.uniqueId });
    }

    return (
        <>
        {/*
        <Paper>
            <Grid container>
                <Grid item xs>
                    <Box component="div" textAlign={'center'} fontWeight={'fontWeightLight'} fontSize={'0.7em'} sx={{ width: '80px' }}>Time remaining {moment.utc(countdown * 1000).format('mm:ss')}</Box>
                </Grid>
                <Grid item xs>
                    <Box component="div" textAlign={'center'} color={'silver'} fontWeight={'fontWeightLight'} fontSize={'0.9em'}>LINK</Box>
                </Grid>
                <Grid item xs>
                    <Box component="div" textAlign={'right'}><IconButton sx={{ fontSize: '0.8em' }} onClick={handleCancel}>Cancel <CancelOutlined /></IconButton></Box>
                </Grid>
            </Grid>
            <Dialog open={cancelled} fullWidth maxWidth="sm">
                <DialogTitle style={{ textAlign: 'center' }}><CancelOutlined sx={{ color: 'black', fontSize: 48 }} /></DialogTitle>
                <DialogContent style={{ textAlign: 'center' }}>
                    <DialogContentText>Cancelled</DialogContentText>
                </DialogContent>
            </Dialog>
        </Paper>
         */}
        </>
    );
};

const mapStateToProps = (state: IAppState) => {
    return {
        link: state.link.link,
    };
};

export default connect(mapStateToProps)(Header);