import { CreditCardOutlined, AccountBalanceOutlined, CalendarMonthOutlined, LockOpenOutlined } from "@mui/icons-material";
import { Box, Grid, TextField, Button, InputAdornment, FormControlLabel, Checkbox, Typography, useTheme } from "@mui/material";
import { IAppState } from "../../redux/storeTypes";
import { Checkout, Billing, Contact, CheckoutStatus } from "../../models/CheckoutLink";
import { connect, useDispatch } from "react-redux";
import { useRef, useState } from "react";
import { Order, PaymentCardTypeEnum, PaymentMethodOnFileType, PaymentType } from "../../models/Order";
import InputMask from "react-input-mask";
import { isInvalidCardExpiration } from "../../utils/globalCalculations";
import { CheckoutBilling } from "./CheckoutBilling";
import { PaymentCardType } from "../../utils/binLookup";
import _ from "lodash";
import { simpleCalculateOrderAction, updateOrderDetailsAction } from "../../redux/actions/orderManagement";
import { PaymentChannel, SubMerchantAcceptedCardTypeEnum } from "../../models/Client";
import { statusCheckoutAction } from "../../redux/actions/checkout";

export interface IPayByCreditCardProps {
    order: Order;
    paymentChannel: PaymentChannel;
    checkout: Checkout;
    status: CheckoutStatus;
    setStatus: any;
}

const PayByCreditCard = ({ order, paymentChannel, checkout, status, setStatus }: IPayByCreditCardProps) => {
    const dispatch = useDispatch();
    const theme = useTheme();
    const cardNumber = useRef<any>("");
    const cvvNumber = useRef<any>("");
    const cardIssuer = useRef<any>("");
    const expirationDate = useRef<any>("");
    const tokenizingRequested = useRef<any>(false);

    const billingName = useRef<any>("");
    const billingAddress1 = useRef<any>("");
    const billingAddress2 = useRef<any>("");
    const billingCity = useRef<any>("");
    const billingState = useRef<any>("");
    const billingZip = useRef<any>("");
    const billingCountry = useRef<any>("");
    const billingPhone = useRef<any>("");
    const billingEmail = useRef<any>("");

    const validate = (event: any) => {
        event.preventDefault();
        const form = event.currentTarget;

        if (form.reportValidity()) {
            order.methodName = (checkout.authorizeCard) ? "Authorization" : "Sale";
            order.isMailOrTelephoneOrder = false;
            order.addressLine1 = billingAddress1.current.value
            order.addressLine2 = billingAddress2.current.value;
            order.city = billingCity.current.value;
            order.state = billingState.current.value;
            order.zip = billingZip.current.value;
            order.country = billingCountry.current.value;
            order.phone = billingPhone.current.value?.replace(/[^0-9]/g, "") || "";
            order.emailAddress = billingEmail.current.value;
            order.initiatedBy = checkout.initiatedBy;
            order.paymentType = PaymentType.CreditCard;
            const cardType = _.startCase(_.camelCase(cardIssuer.current.value.replace("AMEX", "AMERICAN EXPRESS"))).replaceAll(' ', '');
            order.cardType = PaymentCardTypeEnum[cardType as keyof typeof PaymentCardTypeEnum];
            order.nameOnCard = billingName.current.value;
            order.creditCardNumber = cardNumber.current.value;
            order.expirationDate = expirationDate.current.value;
            order.cvv = cvvNumber.current.value;
            order.eCheckAccountHolderName = "";
            order.eCheckAccountNumber = "";
            order.eCheckRoutingNumber = "";
            order.isCardPresent = false;
            order.isDebitCard = cardIssuer.current.value.indexOf("Debit") >= 0;
            order.externalCustomerReference = checkout.uniqueId;
            const ids = JSON.parse(checkout.iDs);
            order.clientMsbId = ids.clientId;
            order.departmentMsbId = ids.departmentId;
            order.paymentChannelMsbId = ids.paymentChannelId;
            order.shouldTokenizeCard = tokenizingRequested.current.checked;
            if (tokenizingRequested.current.checked) order.paymentMethodOnFileType = PaymentMethodOnFileType.Card;
            order.isImplicitAuthorization = tokenizingRequested.current.checked;
            dispatch(updateOrderDetailsAction(order, "CheckoutPage"));
            dispatch(simpleCalculateOrderAction("CheckoutPage"));
            setStatus();
        }
    }

    const getOptions = () => {
        let options = [];
        if (!paymentChannel?.softBlockedAcceptedCards.some(c => c === SubMerchantAcceptedCardTypeEnum.AmericanExpress)) {
            options.push(PaymentCardTypeEnum.AmericanExpressCredit);
            options.push(PaymentCardTypeEnum.AmericanExpressDebit);
        }
        if (!paymentChannel?.softBlockedAcceptedCards.some(c => c === SubMerchantAcceptedCardTypeEnum.Discover)) {
            options.push(PaymentCardTypeEnum.DiscoverCredit);
            options.push(PaymentCardTypeEnum.DiscoverDebit);
        }
        if (!paymentChannel?.softBlockedAcceptedCards.some(c => c === SubMerchantAcceptedCardTypeEnum.MasterCard)) {
            options.push(PaymentCardTypeEnum.MastercardCredit);
            options.push(PaymentCardTypeEnum.MastercardDebit);
        }
        if (!paymentChannel?.softBlockedAcceptedCards.some(c => c === SubMerchantAcceptedCardTypeEnum.Visa)) {
            options.push(PaymentCardTypeEnum.VisaCredit);
            options.push(PaymentCardTypeEnum.VisaDebit);
        }
        return options;
    }

    const [options] = useState(getOptions());

    const handleChange = () => {
        if (status.payBy) dispatch(statusCheckoutAction({ ...status, payBy: false }));
    }

    return (
        <form onSubmit={validate} onChange={handleChange}>
            <Grid container spacing="3" style={{ paddingTop: "8px" }}>
                <Grid item xs>
                    <TextField
                        className={'blurry fs-mask'}
                        required
                        label="Card Number"
                        fullWidth
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <CreditCardOutlined sx={{ color: "lightgrey" }} />
                                </InputAdornment>
                            ),
                        }}
                        size="small"
                        autoComplete="off"
                        onKeyPress={(event) => { if (!/[0-9]/.test(event.key)) { event.preventDefault(); } }}
                        onBlur={(e) => {
                            if (e.target.value.length >= 8) {
                                PaymentCardType(e.target.value).then(result => {
                                    const type = _.startCase(PaymentCardTypeEnum[result]).toUpperCase().replace("AMERICAN EXPRESS", "AMEX");
                                    cardIssuer.current.value = type;
                                    cvvNumber.current.value = "";
                                    if (options.find(i => i === result)) {
                                        switch (result) {
                                            case PaymentCardTypeEnum.AmericanExpressCredit:
                                            case PaymentCardTypeEnum.AmericanExpressDebit:
                                                cvvNumber.current.minLength = 4;
                                                cvvNumber.current.maxLength = 4;
                                                cardIssuer.current.setCustomValidity("");
                                                break;
                                            default:
                                                cvvNumber.current.minLength = 3;
                                                cvvNumber.current.maxLength = 3;
                                                cardIssuer.current.setCustomValidity("");
                                        }
                                    } else {
                                        cardIssuer.current.setCustomValidity(`Card ${type} is not allowed. Use another.`);
                                    }
                                });
                            }
                        }}
                        onInvalid={(e) => {
                            let target = e.target as HTMLInputElement;
                            if (target.validity.tooShort) target.setCustomValidity("Please complete this entry")
                        }}
                        onInput={(e) => {
                            let target = e.target as HTMLInputElement;
                            if (target.validity.customError && target.validity.tooShort) {
                                target.setCustomValidity("Please complete this entry")
                            } else {
                                target.setCustomValidity("")
                            }
                        }}
                        inputProps={{ minLength: 15, maxLength: 16, }}
                        inputRef={cardNumber}
                    />
                </Grid>
            </Grid>
            <Grid container spacing="3" style={{ paddingTop: "8px" }}>
                <Grid item xs>
                    <TextField
                        required
                        label="Card Issuer/Scheme"
                        fullWidth
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <AccountBalanceOutlined sx={{ color: "lightgrey" }} />
                                </InputAdornment>
                            ),
                            sx: {
                                backgroundColor: theme.palette.action.hover,
                            }
                        }}
                        size="small"
                        onKeyDown={(event) => { event.preventDefault() }}
                        inputProps={{ readOnly: false, tabIndex: -1 }}
                        inputRef={cardIssuer}
                    />
                </Grid>
            </Grid>
            <Grid container spacing="3" style={{ paddingTop: "8px" }}>
                <Grid item xs>
                    <InputMask
                        mask="99/99"
                        maskPlaceholder={null}
                        onChange={(e) => {
                            if (e.target.validity.valid) {
                                const expiredDate = isInvalidCardExpiration(e.target.value)
                                if (expiredDate) {
                                    e.target.setCustomValidity(expiredDate);
                                } else {
                                    e.target.setCustomValidity("");
                                }
                            } else {
                                e.target.setCustomValidity("");
                            }
                        }}>
                        {() =>
                            <TextField
                                required
                                label="Expiration Date"
                                placeholder="MM/YY"
                                fullWidth
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <CalendarMonthOutlined sx={{ color: "lightgrey" }} />
                                        </InputAdornment>
                                    ),
                                }}
                                size="small"
                                autoComplete="off"
                                inputProps={{ pattern: "(0[1-9]|1[0-2])/[2-9]{2}" }}
                                inputRef={expirationDate}
                            />}
                    </InputMask>
                </Grid>
                <Grid item xs>
                    <TextField
                        className={'blurry fs-mask'}
                        required
                        label="CVV Number"
                        fullWidth
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <LockOpenOutlined sx={{ color: "lightgrey" }} />
                                </InputAdornment>
                            ),
                        }}
                        size="small"
                        autoComplete="off"
                        onKeyPress={(event) => { if (!/[0-9]/.test(event.key)) { event.preventDefault(); } }}
                        onInvalid={(e) => {
                            let target = e.target as HTMLInputElement;
                            if (target.validity.tooShort) target.setCustomValidity("Please complete this entry")
                        }}
                        onInput={(e) => {
                            let target = e.target as HTMLInputElement;
                            if (target.validity.customError && target.validity.tooShort) {
                                target.setCustomValidity("Please complete this entry")
                            } else {
                                target.setCustomValidity("")
                            }
                        }}
                        inputProps={{ minLength: 3, maxLength: 4, }}
                        inputRef={cvvNumber}
                    />
                </Grid>
            </Grid>
            <CheckoutBilling
                payerName={checkout.payerName}
                billing={checkout.billing || new Billing()}
                contact={checkout.contact || new Contact()}
                addressRequirement={checkout.cardAddressRequirement}
                contactRequirement={checkout.cardContactRequirement}
                billingName={billingName}
                billingAddress1={billingAddress1}
                billingAddress2={billingAddress2}
                billingCity={billingCity}
                billingState={billingState}
                billingZip={billingZip}
                billingCountry={billingCountry}
                billingPhone={billingPhone}
                billingEmail={billingEmail}
            />
            {checkout.offerTokenizing && !checkout.authorizeCard &&
            <Box component="div" textAlign={'center'} marginTop={'8px'} sx={{ backgroundColor: theme.palette.action.hover }}>
                <FormControlLabel control={<Checkbox size="small" inputRef={tokenizingRequested} />} label={<Typography sx={{ mx: 0 }} fontSize={'0.9em'} color={theme.palette.text.primary}>Save card for future use.</Typography>} />
            </Box>}
            <Box sx={{ textAlign: 'center', paddingTop: '8px' }}>
                <Button type="submit" fullWidth variant="contained" size='small' color="primary">CONTINUE</Button>
            </Box>
            <Box sx={{ fontSize: "9px", textAlign: "center", paddingTop: '5px', color: "silver" }}>
                This site is protected by reCAPTCHA and the Google
                <a href="https://policies.google.com/privacy" target={"_blank"} rel={"noreferrer"}> Privacy Policy</a> and
                <a href="https://policies.google.com/terms" target={"_blank"} rel={"noreferrer"}> Terms of Service</a> apply.
            </Box>
        </form>
    );
};

const mapStateToProps = (state: IAppState) => {
    return {
        order: state.orderManagement.order,
        paymentChannel: state.paymentChannelWebApplication.paymentChannel,
        checkout: state.checkout.checkout,
        status: state.checkout.status,
    };
};

export default connect(mapStateToProps)(PayByCreditCard);

  