export const Routes = {
  Landing: { path: "/" },
  Product: { path: "/product" },
  Contact: { path: "/contact" },
  Status: { path: "/status" },
  WebApplicationStart: { path: "/:id" },

  GenericPage: { path: "/:id/Page" },
  AccountPage: { path: "/:id/Account" },
  CheckoutPage: { path: "/:id/Checkout" },
  LinkPage: { path: "/:id/Link" },
  LandingPage: { path: "/:id/Landing" },
  LoginPage: { path: "/:id/Login" },
  ReceiptPage: { path: "/:id/Receipt" },
  AuthorizationPage: { path: "/:id/Authorization" }, 
  ShoppingCartPage: { path: "/:id/ShoppingCart" },
  Register: { path: "/:id/Register" },
  QuickPay: { path: "/:id/QuickPay" },
  PaymentSettings: { path: "/:id/PaymentSettings" },
  PartnerReferralPage: { path: "/:id/PartnerReferral" }, 

  LoginStatic: { path: "/:id/Login/Static" },
  AccountLandingStatic: { path: "/:id/Account/Static" },
  CheckoutStatic: { path: "/:id/Checkout/Static" },
  ReceiptStatic: { path: "/:id/Receipt/Static" },
  QuickPayStatic: { path: "/:id/QuickPay/Static" },
  RegisterStatic: { path: "/:id/Register/Static" },
  PaymentSettingsStatic: { path: "/:id/PaymentSettings/Static" },
  PaymentMethodStatic: { path: "/:id/PaymentMethod/Static" },
  AccountInfoStatic: { path: "/:id/AccountInfo/Static" },
  BillingSettingsStatic: { path: "/:id/BillingSettings/Static" },
  BillingAddressStatic: { path: "/:id/BillingAddress/Static" },
  ForsythDeepLink: { path: "/:id/Search"}
};
