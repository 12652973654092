import { CreditCardOutlined, AccountBalanceOutlined, CalendarMonthOutlined, LockOpenOutlined } from "@mui/icons-material";
import { Box, Grid, TextField, Button, InputAdornment, useTheme } from "@mui/material";
import { IAppState } from "../../redux/storeTypes";
import { Link, Billing, Contact, LinkStatus } from "../../models/CheckoutLink";
import { connect, useDispatch } from "react-redux";
import { useRef, useState } from "react";
import { PaymentCardTypeEnum } from "../../models/Order";
import InputMask from "react-input-mask";
import { isInvalidCardExpiration } from "../../utils/globalCalculations";
import { PaymentCardType } from "../../utils/binLookup";
import _ from "lodash";
import { LinkBilling } from "./LinkBilling";
import { TokenizeCard, WalletType } from "../../models/Wallet";
import { saveCardAction } from "../../redux/actions/wallet";
import { PaymentChannel, SubMerchantAcceptedCardTypeEnum } from "../../models/Client";
import { statusLinkAction } from "../../redux/actions/link";

export interface IWithMethodCreditCardProps {
    paymentChannel: PaymentChannel;
    link: Link;
    status: LinkStatus;
    card: TokenizeCard;
    setStatus: any;
}

const WithMethodCreditCard = ({ paymentChannel, link, status, card, setStatus }: IWithMethodCreditCardProps) => {
    const dispatch = useDispatch();
    const theme = useTheme();
    const cardNumber = useRef<any>("");
    const cvvNumber = useRef<any>("");
    const cardIssuer = useRef<any>("");
    const expirationDate = useRef<any>("");

    const billingName = useRef<any>("");
    const billingAddress1 = useRef<any>("");
    const billingAddress2 = useRef<any>("");
    const billingCity = useRef<any>("");
    const billingState = useRef<any>("");
    const billingZip = useRef<any>("");
    const billingCountry = useRef<any>("");
    const billingPhone = useRef<any>("");
    const billingEmail = useRef<any>("");

    const validate = (event: any) => {
        event.preventDefault();
        const form = event.currentTarget;

        if (form.reportValidity()) {
            card.walletType = WalletType.Personal;
            const cardType = _.startCase(_.camelCase(cardIssuer.current.value.replace("AMEX", "AMERICAN EXPRESS"))).replaceAll(' ', '');
            card.internalCardType = PaymentCardTypeEnum[cardType as keyof typeof PaymentCardTypeEnum];
            card.isImplicitAuthorization = true;
            const ids = JSON.parse(link.iDs);
            card.paymentChannelId = ids.paymentChannelId;
            card.externalCustomerReference = link.uniqueId;
            card.cardHolderEmailAddress = billingEmail.current.value;
            card.nameOnCard = billingName.current.value;
            const date = expirationDate.current.value.split("/");
            card.expirationMonth = date[0];
            card.expirationYear = date[1];
            card.cardNumber = cardNumber.current.value;
            card.cvv = cvvNumber.current.value;
            card.addressLine1 = billingAddress1.current.value;
            card.addressLine2 = billingAddress2.current.value;
            card.city = billingCity.current.value;
            card.state = billingState.current.value;
            card.zipCode = billingZip.current.value;
            card.country = billingCountry.current.value;
            dispatch(saveCardAction(card, "LinkPage"));
            setStatus();
        }
    }

    const getOptions = () => {
        let options = [];
        if (!paymentChannel?.softBlockedAcceptedCards.some(c => c === SubMerchantAcceptedCardTypeEnum.AmericanExpress)) {
            options.push(PaymentCardTypeEnum.AmericanExpressCredit);
            options.push(PaymentCardTypeEnum.AmericanExpressDebit);
        }
        if (!paymentChannel?.softBlockedAcceptedCards.some(c => c === SubMerchantAcceptedCardTypeEnum.Discover)) {
            options.push(PaymentCardTypeEnum.DiscoverCredit);
            options.push(PaymentCardTypeEnum.DiscoverDebit);
        }
        if (!paymentChannel?.softBlockedAcceptedCards.some(c => c === SubMerchantAcceptedCardTypeEnum.MasterCard)) {
            options.push(PaymentCardTypeEnum.MastercardCredit);
            options.push(PaymentCardTypeEnum.MastercardDebit);
        }
        if (!paymentChannel?.softBlockedAcceptedCards.some(c => c === SubMerchantAcceptedCardTypeEnum.Visa)) {
            options.push(PaymentCardTypeEnum.VisaCredit);
            options.push(PaymentCardTypeEnum.VisaDebit);
        }
        return options;
    }

    const [options] = useState(getOptions());

    const handleChange = () => {
        if (status.withMethod) dispatch(statusLinkAction({ ...status, withMethod: false }));
    }

    return (
        <form onSubmit={validate} onChange={handleChange}>
            <Grid container spacing="3" style={{ paddingTop: "8px" }}>
                <Grid item xs>
                    <TextField
                        className={'blurry fs-mask'}
                        required
                        label="Card Number"
                        fullWidth
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <CreditCardOutlined sx={{ color: "lightgrey" }} />
                                </InputAdornment>
                            ),
                        }}
                        size="small"
                        autoComplete="off"
                        onKeyPress={(event) => { if (!/[0-9]/.test(event.key)) { event.preventDefault(); } }}
                        onBlur={(e) => {
                            if (e.target.value.length >= 8) {
                                PaymentCardType(e.target.value).then(result => {
                                    const type = _.startCase(PaymentCardTypeEnum[result]).toUpperCase().replace("AMERICAN EXPRESS", "AMEX");
                                    cardIssuer.current.value = type;
                                    cvvNumber.current.value = "";
                                    if (options.find(i => i === result)) {
                                        switch (result) {
                                            case PaymentCardTypeEnum.AmericanExpressCredit:
                                            case PaymentCardTypeEnum.AmericanExpressDebit:
                                                cvvNumber.current.minLength = 4;
                                                cvvNumber.current.maxLength = 4;
                                                cardIssuer.current.setCustomValidity("");
                                                break;
                                            default:
                                                cvvNumber.current.minLength = 3;
                                                cvvNumber.current.maxLength = 3;
                                                cardIssuer.current.setCustomValidity("");
                                        }
                                    } else {
                                        cardIssuer.current.setCustomValidity(`Card ${type} is not allowed. Use another.`);
                                    }
                                });
                            }
                        }}
                        onInvalid={(e) => {
                            let target = e.target as HTMLInputElement;
                            if (target.validity.tooShort) target.setCustomValidity("Please complete this entry")
                        }}
                        onInput={(e) => {
                            let target = e.target as HTMLInputElement;
                            if (target.validity.customError && target.validity.tooShort) {
                                target.setCustomValidity("Please complete this entry")
                            } else {
                                target.setCustomValidity("")
                            }
                        }}
                        inputProps={{ minLength: 15, maxLength: 16, }}
                        inputRef={cardNumber}
                    />
                </Grid>
            </Grid>
            <Grid container spacing="3" style={{ paddingTop: "8px" }}>
                <Grid item xs>
                    <TextField
                        required
                        label="Card Issuer/Scheme"
                        fullWidth
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <AccountBalanceOutlined sx={{ color: "lightgrey" }} />
                                </InputAdornment>
                            ),
                            sx: {
                                backgroundColor: theme.palette.action.hover,
                            }
                        }}
                        size="small"
                        onKeyDown={(event) => { event.preventDefault() }}
                        inputProps={{ readOnly: false, tabIndex: -1 }}
                        inputRef={cardIssuer}
                    />
                </Grid>
            </Grid>
            <Grid container spacing="3" style={{ paddingTop: "8px" }}>
                <Grid item xs>
                    <InputMask
                        mask="99/99"
                        maskPlaceholder={null}
                        onChange={(e) => {
                            if (e.target.validity.valid) {
                                const expiredDate = isInvalidCardExpiration(e.target.value)
                                if (expiredDate) {
                                    e.target.setCustomValidity(expiredDate);
                                } else {
                                    e.target.setCustomValidity("");
                                }
                            } else {
                                e.target.setCustomValidity("");
                            }
                        }}>
                        {() =>
                            <TextField
                                required
                                label="Expiration Date"
                                placeholder="MM/YY"
                                fullWidth
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <CalendarMonthOutlined sx={{ color: "lightgrey" }} />
                                        </InputAdornment>
                                    ),
                                }}
                                size="small"
                                autoComplete="off"
                                inputProps={{ pattern: "(0[1-9]|1[0-2])/[2-9]{2}" }}
                                inputRef={expirationDate}
                            />}
                    </InputMask>
                </Grid>
                <Grid item xs>
                    <TextField
                        className={'blurry fs-mask'}
                        required
                        label="CVV Number"
                        fullWidth
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <LockOpenOutlined sx={{ color: "lightgrey" }} />
                                </InputAdornment>
                            ),
                        }}
                        size="small"
                        autoComplete="off"
                        onKeyPress={(event) => { if (!/[0-9]/.test(event.key)) { event.preventDefault(); } }}
                        onInvalid={(e) => {
                            let target = e.target as HTMLInputElement;
                            if (target.validity.tooShort) target.setCustomValidity("Please complete this entry")
                        }}
                        onInput={(e) => {
                            let target = e.target as HTMLInputElement;
                            if (target.validity.customError && target.validity.tooShort) {
                                target.setCustomValidity("Please complete this entry")
                            } else {
                                target.setCustomValidity("")
                            }
                        }}
                        inputProps={{ minLength: 3, maxLength: 4, }}
                        inputRef={cvvNumber}
                    />
                </Grid>
            </Grid>
            <LinkBilling
                payerName={link.payerName}
                billing={link.billing || new Billing()}
                contact={link.contact || new Contact()}
                addressRequirement={link.addressRequirement}
                contactRequirement={link.contactRequirement}
                billingName={billingName}
                billingAddress1={billingAddress1}
                billingAddress2={billingAddress2}
                billingCity={billingCity}
                billingState={billingState}
                billingZip={billingZip}
                billingCountry={billingCountry}
                billingPhone={billingPhone}
                billingEmail={billingEmail}
            />
            <Box sx={{ textAlign: 'center', paddingTop: '8px' }}>
                <Button type="submit" fullWidth variant="contained" size='small' color="primary">CONTINUE</Button>
            </Box>
            <Box sx={{ fontSize: "9px", textAlign: "center", paddingTop: '5px', color: "silver" }}>
                This site is protected by reCAPTCHA and the Google
                <a href="https://policies.google.com/privacy" target={"_blank"} rel={"noreferrer"}> Privacy Policy</a> and
                <a href="https://policies.google.com/terms" target={"_blank"} rel={"noreferrer"}> Terms of Service</a> apply.
            </Box>
        </form>
    );
};

const mapStateToProps = (state: IAppState) => {
    return {
        paymentChannel: state.paymentChannelWebApplication.paymentChannel,
        link: state.link.link,
        status: state.link.status,
        card: state.wallet.card,
    };
};

export default connect(mapStateToProps)(WithMethodCreditCard);
