import { CellPlugin } from '@react-page/editor';
import LarimerSearchResults, {
    CITATION_NUMBER_LABEL, FULL_NAME_LABEL, ISSUE_DATE_LABEL, AMOUNT_DUE_LABEL, STATUS_LABEL, ACTIONS_LABEL,
    ADD_TO_CART_LABEL, ADD_TO_CART_ICON_COLOR, IN_CART_LABEL, IN_CART_ICON_COLOR, OBSERVES_WEEKENDS, DURATION_OF_PENDING_HOURS,
    HEADER_BACKGROUND_COLOR, HEADER_PADDING, MARGIN, PADDING
} from './components/LarimerSearchResults';

type Data = {
    title: string,
    citationNumber: string,
    fullName: string,
    issueDate: string,
    amountDue: string,
    status: string,
    actions: string,
    addToCart: string,
    addToCartIconColor: string,
    inCart: string,
    inCartIconColor: string,
    observesWeekends: boolean,
    durationOfPendingHours: number,
    headerBackgroundColor: string,
    headerPadding: string,
    margin: string,
    padding: string,
}

const LarimerSearchResultsPlugin: CellPlugin<Data> = {
    Renderer: ({ data }) => (
        <LarimerSearchResults
            citationNumberLabel={data.citationNumber}
            fullNameLabel={data.fullName}
            issueDateLabel={data.issueDate}
            amountDueLabel={data.amountDue}
            statusLabel={data.status}
            actionsLabel={data.actions}
            inCartLabel={data.inCart}
            inCartIconColor={data.inCartIconColor}
            addToCartIconColor={data.addToCartIconColor}
            addToCartLabel={data.addToCart}
            observesWeekends={data.observesWeekends}
            durationOfPendingHours={data.durationOfPendingHours}
            margin={data.margin}
            padding={data.padding}
            headerBackgroundColor={data.headerBackgroundColor}
            headerPadding={data.headerPadding}
        />
    ),
    id: 'larimerSearchResultsPlugin',
    title: 'Larimer Search Results',
    description: 'Larimer search table results',
    version: 1,
    controls: [
        {
            title: 'Labels',
            controls: {
                type: 'autoform',
                schema: {
                    properties: {
                        citationNumber: {
                            type: 'string',
                            default: CITATION_NUMBER_LABEL,
                        },
                        fullName: {
                            type: 'string',
                            default: FULL_NAME_LABEL,
                        },
                        issueDate: {
                            type: 'string',
                            default: ISSUE_DATE_LABEL,
                        },
                        amountDue: {
                            type: 'string',
                            default: AMOUNT_DUE_LABEL,
                        },
                        status: {
                            type: 'string',
                            default: STATUS_LABEL,
                        },
                        actions: {
                            type: 'string',
                            default: ACTIONS_LABEL,
                        },
                        addToCart:{
                            type: 'string',
                            default: ADD_TO_CART_LABEL, 
                        },
                        inCart:{
                            type: 'string',
                            default: IN_CART_LABEL,
                        },
                        observesWeekends:{
                            type: 'boolean',
                            default: OBSERVES_WEEKENDS
                        },
                        durationOfPendingHours:{
                            type: 'number',
                            default: DURATION_OF_PENDING_HOURS
                        }
                    },
                    required: [],
                },
            },
        },
        {
            title: 'Styles',
            controls: {
                type: 'autoform',
                schema: {
                    properties: {
                        margin: {
                            type: 'string',
                            default: MARGIN,
                        },
                        padding: {
                            type: 'string',
                            default: PADDING,
                        },
                        headerBackgroundColor: {
                            type: 'string',
                            default: HEADER_BACKGROUND_COLOR,
                        },
                        headerPadding: {
                            type: 'string',
                            default: HEADER_PADDING,
                        },
                        addToCartIconColor: {
                            type: 'string',
                            default: ADD_TO_CART_ICON_COLOR,
                        },
                        inCartIconColor: {
                            type: 'string',
                            default: IN_CART_ICON_COLOR, 
                        }
                    },
                    required: [],
                },
            },
        },
    ],
};

export default LarimerSearchResultsPlugin;