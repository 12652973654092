import React, { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { Redirect, useParams } from "react-router-dom";
import { IActionResult, IAppState } from "../../redux/storeTypes";
import { WebApplication, WebPage, WebPageEnum } from "../../models/PaymentChannelWebApplication";
import { Content, StatusEnum } from "../../models/CMS";
import type { Value } from '@react-page/editor';
import Editor, { CellPlugin } from '@react-page/editor';

import image from '@react-page/plugins-image';
import { getContentAction } from "../../redux/actions/cms";
import { Routes } from "../../routes";

export interface IParams {
    id: string
}

export interface IShoppingCartPageProps {
    content: Content,
    webApplication: WebApplication,
    actionResult: IActionResult
}

const ShoppingCartPage = ({ webApplication, content, actionResult }: IShoppingCartPageProps) => {
    let actionToken = "ShoppingCartPage"
    const dispatch = useDispatch();
    const { id }:IParams = useParams();
    const [value, setValue] = useState<Value>();
    const [webPage, setWebPage] = useState<WebPage>();
    const [redirect, setRedirect] = useState<string>("");

    const setRoute = (path:string, webApplicationName:string) => {
        return path.replace(":id", webApplicationName) + "?" + WebPageEnum.ShoppingCart;
    }

    let imagePlugin = image as CellPlugin<unknown, unknown>;

    const cellPlugins = new Array<CellPlugin>();
    cellPlugins.push(imagePlugin);

    useEffect(() => {
        if (webApplication) {
            let webPage = webApplication.webPages.filter(_ => _.webPageType === WebPageEnum.ShoppingCart)[0];
            if (webPage) { 
                if (webPage.contentId !== '00000000-0000-0000-0000-000000000000') {
                    dispatch(getContentAction(webPage.contentId, actionToken));
                } else {
                    //// TODO - need to hanlde, as there is content for page
                }
                setWebPage(webPage);
            }
        } else {
            ////redirect back to start and load the web application
            setRedirect(setRoute(Routes.WebApplicationStart.path, id));
        }
    }, [webApplication]);

    useEffect(() => {
        if (content && webPage && content.msbId! === webPage.contentId) {
            for (let x = 0; x < content.revisions.length;x++) {
                if (content.revisions[x].statusEnum === StatusEnum.Draft) {
                    let revision = content.revisions[x];
                    let value = JSON.parse(revision.value);
                    setValue(value);
                    break;
                }
            }
        }
    }, [content]);   

    if (redirect !== "") {
        return (<Redirect push to={redirect!} />)
    } else {
        return (
            <>
                <Editor cellPlugins={cellPlugins} value={value} readOnly={true} />
            </>
        )
    }
};

const mapStateToProps = (state: IAppState) => {
    return {
        webApplication: state.paymentChannelWebApplication.webApplication,
        content: state.cms.content
    };
};

export default connect(mapStateToProps)(ShoppingCartPage);

