import { CellPlugin } from '@react-page/editor';
import PaymentSummary, { CANCEL_BUTTON_LABEL, CONVENIENCE_FEE_ACKNOWLEDGMENT_LABEL, CONVENIENCE_FEE_LABEL, PAYMENT_SUMMARY_LABEL, SUBMIT_BUTTON_LABEL, SUBTOTAL_LABEL, TERMS_LABEL, TERMS_URL, TOTAL_AMOUNT_LABEL,
  SUBMIT_BUTTON_FONT_COLOR, SUBMIT_BUTTON_FONT_SIZE, SUBMIT_BUTTON_FONT_STYLE, SUBMIT_BUTTON_FONT_WEIGHT, SUBMIT_BUTTON_BACKGROUND_COLOR } from './components/PaymentSummary';

type Data = {
  title: string
  paymentSummary: string,
  subtotal: string,
  convenienceFee: string,
  totalAmount: string,
  requireConvenienceFeeAcknowledgment: boolean,
  convenienceFeeAcknowledgment: string,
  termsAndConditionsUrl: string,
  termsAndConditions: string,
  submitButton: string,
  cancelButton: string,
  submitButtonBackgroundColor: string,
  submitButtonFontColor: string,
  submitButtonFontSize: string,
  submitButtonFontStyle: string,
  submitButtonFontWeight: string,
}

const PaymentSummaryPlugin: CellPlugin<Data> = {
  Renderer: ({data}) => (
   <PaymentSummary 
      paymentSummaryLabel = {data.paymentSummary}
      subtotalLabel = {data.subtotal}
      convenienceFeeLabel = {data.convenienceFee}
      totalAmountLabel = {data.totalAmount}
      requireConvenienceFeeAcknowledgment = {data.requireConvenienceFeeAcknowledgment}
      convenienceFeeAcknowledgmentLabel = {data.convenienceFeeAcknowledgment}
      termsUrl = {data.termsAndConditionsUrl}
      termsLabel = {data.termsAndConditions}
      submitButtonLabel = {data.submitButton}
      cancelButtonLabel = {data.cancelButton}
      submitButtonBackgroundColor = {data.submitButtonBackgroundColor}
      submitButtonFontColor = {data.submitButtonFontColor}
      submitButtonFontSize = {data.submitButtonFontSize}
      submitButtonFontStyle = {data.submitButtonFontStyle}
      submitButtonFontWeight = {data.submitButtonFontWeight}
   />
  ),
  id: 'paymentSummaryPlugin',
  title: 'Payment Summary Plugin',
  description: 'Displays the payment summary.',
  version: 1,
  controls:  [
    {
      title: 'Labels',
      controls: {
        type: 'autoform',
        schema: {
          properties: {
            paymentSummary: {
              type: 'string',
              default: PAYMENT_SUMMARY_LABEL,
            },
            subtotal: {
              type: 'string',
              default: SUBTOTAL_LABEL,
            },
            convenienceFee: {
              type: 'string',
              default: CONVENIENCE_FEE_LABEL,
            },
            totalAmount: {
              type: 'string',
              default: TOTAL_AMOUNT_LABEL,
            },
            requireConvenienceFeeAcknowledgment: {
              type: 'boolean',
              default: false,
            },
            convenienceFeeAcknowledgment: {
              type: 'string',
              default: CONVENIENCE_FEE_ACKNOWLEDGMENT_LABEL,
            },
            termsAndConditions: {
              type: 'string',
              default: TERMS_LABEL,
            },
            termsAndConditionsUrl: {
              type: 'string',
              default: TERMS_URL
            },
            submitButton: {
              type: 'string',
              default: SUBMIT_BUTTON_LABEL,
            },
            cancelButton: {
              type: 'string',
              default: CANCEL_BUTTON_LABEL,
            },
          },
          required: ['subtotal', 'convenienceFee', 'totalAmount', 'termsAndConditions', 'termsAndConditionsUrl', 'submitButton', 'cancelButton'],
        },
      },
    },
    {
      title: 'Styles',
      controls: {
        type: 'autoform',
        schema: {
          properties: {
            submitButtonBackgroundColor: {
              type: 'string',
              default: SUBMIT_BUTTON_BACKGROUND_COLOR,
            },
            submitButtonFontColor: {
              type: 'string',
              default: SUBMIT_BUTTON_FONT_COLOR,
            },
            submitButtonFontSize: {
              type: 'string',
              default: SUBMIT_BUTTON_FONT_SIZE,
            },
            submitButtonFontStyle: {
              type: 'string',
              default: SUBMIT_BUTTON_FONT_STYLE,
            },
            submitButtonFontWeight: {
              type: 'string',
              default: SUBMIT_BUTTON_FONT_WEIGHT,
            },
          },
        },
      },
    }
  ],
};

export default PaymentSummaryPlugin;