import { CellPlugin } from '@react-page/editor';
import { Col, Row } from 'react-bootstrap';

// use a type here, not an interface
type Data = {
    privacyOpenInNewWindow?: boolean,
    termsOfUseOpenInNewWindow?: boolean,
    termsAndConditionsOpenInNewWindow?: boolean,
    privacyPolicyLabel: string,
    privacyPolicyLink: string,
    termsOfUseLabel: string,
    termsOfUseLink: string,
    termsConditionsLabel: string,
    termsConditionsLink: string,
    fontColor: string,
}

const FONT_COLOR = '#0057B6';

const checkStyleForDefault = (style: string, defaultStyle: string) => {
    return style && style !== '' ? style : defaultStyle;
}

const FooterPrivacyPolicyTermsCondition: CellPlugin<Data> = {
    Renderer: ({ data }) => (
        <Row>
            <Col lg={12}>
                <p className="mb-0 text-center" style={{ color: '#8C8C8C' }}>
                    By continuing, you agree to accept our
                    <a href={data.privacyPolicyLink} target={data.privacyOpenInNewWindow ? '_blank' : undefined}
                        style={{
                            color: checkStyleForDefault(data.fontColor, FONT_COLOR),
                        }}> {data.privacyPolicyLabel}</a>,
                    <a href={data.termsOfUseLink} target={data.termsOfUseOpenInNewWindow ? '_blank' : undefined}
                        style={{
                            color: checkStyleForDefault(data.fontColor, FONT_COLOR),
                        }}> {data.termsOfUseLabel}</a> and
                    <a href={data.termsConditionsLink} target={data.termsAndConditionsOpenInNewWindow ? '_blank' : undefined}
                        style={{
                            color: checkStyleForDefault(data.fontColor, FONT_COLOR),
                        }}> {data.termsConditionsLabel}</a>.
                </p>
            </Col>
        </Row>
    ),
    id: 'footerPrivacyPolicyTermsCondition',
    title: 'Privacy, Terms & Condition Footer',
    description: 'A generic privacy, terms & conditions footer.',
    version: 1,
    controls: [
        {
            title: 'Labels',
            controls: {
                type: 'autoform',
                schema: {
                    properties: {
                        privacyPolicyLabel: {
                            type: 'string',
                            default: 'Privacy Policy',
                        },
                        termsOfUseLabel: {
                            type: 'string',
                            default: 'Terms of Use',
                        },
                        termsConditionsLabel: {
                            type: 'string',
                            default: 'Terms and Conditions',
                        },
                        
                    },
                    required: [],
                },
            },
        },
        {
            title: 'Styles',
            controls: {
                type: 'autoform',
                schema: {
                    properties: {
                        fontColor: {
                            type: 'string',
                            default: FONT_COLOR,
                        },
                    },
                    required: [],
                },
            },
        },
        {
            title: 'Policy links',
            controls: {
                type: 'autoform',
                schema: {
                    properties: {
                        privacyPolicyLink: {
                            type: 'string',
                            default: 'https://payments.msbpay.navient.com/Privacy_Policy.pdf',
                        },
                        privacyOpenInNewWindow: {
                            type: 'boolean'
                        },
                        termsOfUseLink: {
                            type: 'string',
                            default: 'https://payments.msbpay.navient.com/Terms_Of_Use.pdf',
                        },
                        termsOfUseOpenInNewWindow: {
                            type: 'boolean'
                        },
                        termsConditionsLink: {
                            type: 'string',
                            default: 'https://payments.msbpay.navient.com/Payment_Terms_and_Conditions.pdf',
                        },
                        termsAndConditionsOpenInNewWindow: {
                            type: 'boolean'
                        },
                    },
                    required: [],
                },
            },
        },
    ]
};

export default FooterPrivacyPolicyTermsCondition;