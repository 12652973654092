import React, { useEffect, useState } from 'react';
import { Row, Col, Form } from "react-bootstrap"
import InputMask from "react-input-mask";
import { connect } from 'react-redux';
import RequiredIcon from '../../../../../../components/RequiredIcon';
import { StateDropdown } from "../../../../../../components/StateDropdown";
import { AccountDetails, Order, PaymentType } from '../../../../../../models/Order';
import { IAppState } from '../../../../../../redux/storeTypes';
import TextMask  from "react-text-mask";
import { isValid } from "postcode";
import _ from "lodash";

export interface IBillingDetails {
    addressLine1Label: string,
    addressLine1Placeholder: string,
    addressLine2Label: string,
    addressLine2Placeholder: string,
    countryLabel: string,
    cityLabel: string,
    cityPlaceholder: string,
    stateLabel: string,
    addressRequired: boolean,
    zipCodeLabel: string,
    zipCodePlaceholder: string,
    zipCodeRequired: boolean,
    phoneNumberLabel: string,
    phoneNumberRequired: boolean,
    emailAddressLabel: string,
    emailAddressRequired: boolean,
    order: Order,
    accountDetails: AccountDetails,
    isSaving: boolean,
    isCopyOverRequired: boolean,
    copyOverLabel: string,
    fullStoryMask: boolean,
    margin: string,
    padding: string
}

export const ADDRESS_LINE_1_LABEL = 'Address line 1';
export const ADDRESS_LINE_1_PLACEHOLDER = 'Enter address line 1';
export const ADDRESS_LINE_2_LABEL = 'Address line 2';
export const ADDRESS_LINE_2_PLACEHOLDER = 'Enter address line 2';
export const COUNTRY_LABEL = 'Country';
export const CITY_LABEL = 'City';
export const CITY_PLACEHOLDER = 'Enter city';
export const STATE_LABEL = 'State/Province/Territory';
export const ADDRESS_REQUIRED = false;
export const ZIP_CODE_LABEL = 'Zip code';
export const ZIP_CODE_PLACEHOLDER = 'Enter zip code';
export const ZIP_CODE_REQUIRED = false;
export const PHONE_NUMBER_LABEL = 'Phone number';
export const PHONE_NUMBER_REQUIRED = false;
export const EMAIL_ADDRESS_LABEL = 'Email address';
export const EMAIL_ADDRESS_REQUIRED = false;
export const IS_COPY_OVER_REQUIRED = false;
export const COPY_OVER_LABEL = 'Copy over account details';
export const MARGIN = '0px';
export const PADDING = '0px';
export const FULL_STORY_MASK = false;

const BillingDetails = ({ addressLine1Label, addressLine1Placeholder, 
    addressLine2Label, addressLine2Placeholder, countryLabel, cityLabel, cityPlaceholder, stateLabel, addressRequired, 
    zipCodeLabel, zipCodePlaceholder, zipCodeRequired, phoneNumberLabel, phoneNumberRequired, 
    emailAddressLabel, emailAddressRequired, isCopyOverRequired, copyOverLabel, fullStoryMask, margin, padding, order, accountDetails, isSaving }: IBillingDetails) => {
    
    addressLine1Label = addressLine1Label || ADDRESS_LINE_1_LABEL;
    addressLine1Placeholder = addressLine1Placeholder || ADDRESS_LINE_1_PLACEHOLDER;
    addressLine2Label = addressLine2Label || ADDRESS_LINE_2_LABEL;
    addressLine2Placeholder = addressLine2Placeholder || ADDRESS_LINE_2_PLACEHOLDER;
    countryLabel = countryLabel || COUNTRY_LABEL;
    cityLabel = cityLabel || CITY_LABEL;
    cityPlaceholder = cityPlaceholder || CITY_PLACEHOLDER;
    stateLabel = stateLabel || STATE_LABEL;
    addressRequired = addressRequired || ADDRESS_REQUIRED;
    zipCodeLabel = zipCodeLabel || ZIP_CODE_LABEL;
    zipCodePlaceholder = zipCodePlaceholder || ZIP_CODE_PLACEHOLDER;
    zipCodeRequired = zipCodeRequired || ZIP_CODE_REQUIRED;
    phoneNumberLabel = phoneNumberLabel || PHONE_NUMBER_LABEL;
    phoneNumberRequired = phoneNumberRequired || PHONE_NUMBER_REQUIRED;
    emailAddressLabel = emailAddressLabel || EMAIL_ADDRESS_LABEL;
    emailAddressRequired = emailAddressRequired || EMAIL_ADDRESS_REQUIRED;
    fullStoryMask = fullStoryMask || FULL_STORY_MASK;

    const [someKey, setSomeKey] = useState(0);
    const [isChecked, setIsChecked] = useState(false);
    const [ukZipCode, setUkZipCode] = useState<string>('');
    const [ukZipCodeInvalid, isUkZipCodeInvalid] = useState<boolean>(false);


    const initialBillingDetailsState = {
        customerName: "", addressLine1: "", addressLine2: "",
        country: "United States", city: "", state: "", zipCode: "",
        phoneNumber: "", emailAddress: ""
    }

    const [billingDetails, setBillingDetails] = useState(initialBillingDetailsState);

    const handleChange = (e: any) => {
        setBillingDetails({ ...billingDetails, [e.target.name]: e.target.value });
    }

    const checkZipCode = (e: any) => {
        const stringValue = e.target.value;
        setUkZipCode(stringValue)
        const zipCheck = !isValid(stringValue);
        isUkZipCodeInvalid(zipCheck);
    }

    useEffect(() => {
        if (order) {
            if (someKey === 0) setSomeKey(Date.now());
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [order]);

    useEffect(() => {
        if (isChecked) {
            setBillingDetails({
                customerName: accountDetails?.accountCustomerName || '',
                addressLine1: accountDetails?.accountAddressLine1 || '',
                addressLine2: accountDetails?.accountAddressLine2 || '',
                country: accountDetails?.accountCountry || 'United States',
                city: accountDetails?.accountCity || '',
                state: accountDetails?.accountState || '',
                zipCode: accountDetails?.accountZipCode || '',
                phoneNumber: accountDetails?.accountPhoneNumber || '',
                emailAddress: accountDetails?.accountEmailAddress || ''
            })
        }
        else if (order) {
            setBillingDetails({
                customerName: "",
                addressLine1: order?.addressLine1 || (document.forms[0].elements.namedItem('addressLine1') as HTMLInputElement).value,
                addressLine2: order?.addressLine2 || (document.forms[0].elements.namedItem('addressLine2') as HTMLInputElement).value,
                country: order?.country || (document.forms[0].elements.namedItem('country') as HTMLInputElement).value,
                city: order?.city || (document.forms[0].elements.namedItem('city') as HTMLInputElement).value,
                state: order?.state || (document.forms[0].elements.namedItem('state') as HTMLInputElement).value,
                zipCode: order?.zip || (document.forms[0].elements.namedItem('zipCode') as HTMLInputElement).value,
                phoneNumber: order?.phone || (document.forms[0].elements.namedItem('phoneNumber') as HTMLInputElement).value,
                emailAddress: order?.emailAddress || (document.forms[0].elements.namedItem('emailAddress') as HTMLInputElement).value
            })
        }
        else { setBillingDetails(initialBillingDetailsState) }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isChecked, order])

    return (
        <div style={{margin:margin, padding:padding}} className={fullStoryMask ? "fs-mask" : ""}>
        {isCopyOverRequired &&
            <Row>
                <Col md={6} sm={12}>
                    <Form.Group controlId="copyOverAccountDetails">
                        <Form.Check style={{ marginBottom: "10px" }} label={copyOverLabel} checked={isChecked} onChange={(e) => setIsChecked(e.target.checked)}  />
                    </Form.Group>
                </Col>
            </Row>
        }
        <Row>
            <Col md={6} sm={12}>
                <Form.Group controlId="addressLine1">
                    <Form.Label>{(addressRequired) ? <RequiredIcon /> : ''}{addressLine1Label}</Form.Label>
                    <Form.Control maxLength={40} type="input" name="addressLine1" placeholder={addressLine1Placeholder} disabled={isSaving} required={addressRequired} value={billingDetails.addressLine1} onChange={handleChange} />
                </Form.Group>
            </Col>
            <Col md={6} sm={12}>
                <Form.Group controlId="addressLine2">
                    <Form.Label>{addressLine2Label}</Form.Label>
                    <Form.Control maxLength={40} type="input" name="addressLine2" placeholder={addressLine2Placeholder} disabled={isSaving} onChange={handleChange} value={billingDetails.addressLine2} />
                </Form.Group>
            </Col>
        </Row>
        <Row>
        <Col md={6} sm={12}>
                <Form.Group controlId="country">
                    <Form.Label>{(addressRequired) ? <RequiredIcon /> : ''}{countryLabel}</Form.Label>
                    <Form.Control as="select" required={addressRequired} name="country" onChange={handleChange} value={billingDetails.country}>
                            <option>United States</option>
                            <option>Mexico</option>
                            <option>Canada</option>
                            <option>United Kingdom</option>
                    </Form.Control>
                </Form.Group>
            </Col>
            <Col md={6} sm={12}>
                <Form.Group controlId="city">
                    <Form.Label>{(addressRequired) ? <RequiredIcon /> : ''}{cityLabel}</Form.Label>
                    <Form.Control maxLength={28} type="input" name="city" placeholder={cityPlaceholder} disabled={isSaving} required={addressRequired} onChange={handleChange} value={billingDetails.city}/>
                </Form.Group>
            </Col>
        </Row>
            <Row>
                {billingDetails?.country != 'United Kingdom' &&
                    <Col md={4} sm={12}>
                        <Form.Group controlId="state" key={someKey}>
                            <Form.Label>{(addressRequired) ? <RequiredIcon /> : ''}{stateLabel}</Form.Label>
                            <StateDropdown country={billingDetails?.country} disabled={isSaving} required={addressRequired} name="state" onChange={handleChange} value={billingDetails.state}></StateDropdown>
                        </Form.Group>
                    </Col>
                }
                <Col md={billingDetails?.country === 'United Kingdom' ? 4 : 2} sm={12}>
                    <Form.Group controlId="zipCode" key={someKey}>
                        <Form.Label>{(zipCodeRequired || order?.paymentType === PaymentType.CreditCard && !order?.isCardPresent) ? <RequiredIcon /> : ''}{zipCodeLabel}</Form.Label>
                        {billingDetails?.country === 'Canada' ?
                            <TextMask
                                id="zipCode"
                                name="zipCode"
                                required={zipCodeRequired || order?.paymentType === PaymentType.CreditCard && !order?.isCardPresent}
                                type="text"
                                pattern="^[A-Za-z][0-9][A-Za-z][ ]?[0-9][A-Za-z][0-9]$"
                                mask={[/[A-Za-z]/, /\d/, /[A-Za-z]/, ' ', /\d/, /[A-Za-z]/, /\d/]}
                                onChange={handleChange}
                                value={billingDetails.zipCode}
                                className="form-control"
                                placeholder="K1A 0T6"
                                disabled={isSaving}
                            />
                            :
                            billingDetails?.country === 'United Kingdom' ?
                                <Form.Control type="input" className="form-control" required={zipCodeRequired || (order?.paymentType === PaymentType.CreditCard && !order?.isCardPresent)} value={ukZipCode} onChange={checkZipCode} placeholder="AA9A 9AA" maxLength={8} disabled={isSaving} />
                                :
                                <InputMask id="zipCode" name="zipCode" type="input" disabled={isSaving} pattern="[0-9]{5}" mask="99999" className="form-control" placeholder={zipCodePlaceholder} required={zipCodeRequired || (order?.paymentType === PaymentType.CreditCard && !order?.isCardPresent)} onChange={handleChange} value={billingDetails.zipCode} />
                        }
                    </Form.Group>
                    {ukZipCodeInvalid && ukZipCode != '' && billingDetails?.country === 'United Kingdom' &&
                        <Form.Group controlId="zipCodeMask">
                            <Form.Control type="input" isInvalid={ukZipCodeInvalid && ukZipCode != ''} style={{ display: 'none' }} required={ukZipCodeInvalid && ukZipCode != ''} />
                            <Form.Control.Feedback type="invalid"> Please enter a valid zip code.</Form.Control.Feedback>
                        </Form.Group>
                    }
                </Col>
            </Row>
        <Row>
            <Col md={6} sm={12}>
                <Form.Group controlId="phoneNumber" key={someKey}>
                    <Form.Label>{(phoneNumberRequired) ? <RequiredIcon /> : ''}{phoneNumberLabel}</Form.Label>
                    <InputMask id="phoneNumber" type="input" name="phoneNumber" pattern="[0-9\-\(\)\s]{14}" className="form-control" mask="(999) 999-9999" placeholder="(999) 999-9999" disabled={isSaving} required={phoneNumberRequired} onChange={handleChange} value={billingDetails.phoneNumber}/>
                </Form.Group>
            </Col>
            <Col md={6} sm={12}>
                <Form.Group controlId="emailAddress" key={someKey}>
                    <Form.Label>{(emailAddressRequired) ? <RequiredIcon /> : ''}{emailAddressLabel}</Form.Label>
                    <Form.Control type="email" name="emailAddress" placeholder="email@address.com" maxLength={100} disabled={isSaving} required={emailAddressRequired} onChange={handleChange} value={billingDetails.emailAddress}/>
                    <Form.Control.Feedback type="invalid">{emailAddressRequired}</Form.Control.Feedback>
                </Form.Group>
            </Col>
        </Row>
      </div>
    )
}

const mapStateToProps = (state: IAppState) => {
    return {
        order: state.orderManagement.order,
        accountDetails: state.orderManagement.accountDetails,
        isSaving: state.orderManagement.isSaving
    };
};

export default connect(mapStateToProps)(BillingDetails);