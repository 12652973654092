import { CellPlugin } from '@react-page/editor';
import ForsythSearchResults, {
    ACCOUNT_NUMBER_LABEL, BILL_NUMBER_LABEL, BILL_YEAR_LABEL, OWNER_LABEL, SITUS_ADDRESS_LABEL, DUE_DATE_LABEL, TOTAL_DUE_LABEL, 
    BILL_STATUS_LABEL, PAYMENT_OPTIONS_LABEL, HEADER_BACKGROUND_COLOR, HEADER_PADDING, MARGIN, PADDING,
    OVERLAY_TEXT, OVERLAY_BACKGROUND, OVERLAY_TEXT_COLOR, OVERLAY_ICON_COLOR,OBSERVES_WEEKENDS, DURATION_OF_PENDING_HOURS
} from './components/ForsythSearchResults';

type Data = {
    title: string,
    accountNumber: string,
    billNumber: string,
    billYear: string,
    owner: string,
    situsAddress: string,
    totalDue: string,
    dueDate: string,
    billStatus: string,
    paymentOptions: string,
    observesWeekends: boolean,
    durationOfPendingHours: number,
    overlayText: string,
    overlayBackground: string,
    overlayTextColor: string,
    overlayIconColor: string,
    headerBackgroundColor: string,
    headerPadding: string,
    margin: string,
    padding: string,
}

const ForsythSearchResultsPlugin: CellPlugin<Data> = {
    Renderer: ({ data }) => (
        <ForsythSearchResults
            accountNumberLabel={data.accountNumber}
            billNumberLabel={data.billNumber}
            billYearLabel={data.billYear}
            ownerLabel={data.owner}
            situsAddressLabel={data.situsAddress}
            totalDueLabel={data.totalDue}
            dueDateLabel={data.dueDate}
            billStatusLabel={data.billStatus}
            paymentOptionsLabel={data.paymentOptions}
            observesWeekends={data.observesWeekends}
            overlayText={data.overlayText}
            overlayBackground={data.overlayBackground}
            overlayTextColor={data.overlayTextColor}
            overlayIconColor={data.overlayIconColor}
            durationOfPendingHours={data.durationOfPendingHours}
            margin={data.margin}
            padding={data.padding}
            headerBackgroundColor={data.headerBackgroundColor}
            headerPadding={data.headerPadding}
        />
    ),
    id: 'forsythResultsPlugin',
    title: 'Forsyth Search Results',
    description: 'Forsyth table results',
    version: 1,
    controls: [
        {
            title: 'Labels',
            controls: {
                type: 'autoform',
                schema: {
                    properties: {
                        accountNumber: {
                            type: 'string',
                            default: ACCOUNT_NUMBER_LABEL,
                        },
                        billYear: {
                            type: 'string',
                            default: BILL_YEAR_LABEL,
                        },
                        billNumber: {
                            type: 'string',
                            default: BILL_NUMBER_LABEL,
                        },
                        owner: {
                            type: 'string',
                            default: OWNER_LABEL,
                        },
                        situsAddress: {
                            type: 'string',
                            default: SITUS_ADDRESS_LABEL,
                        },
                        totalDue: {
                            type: 'string',
                            default: TOTAL_DUE_LABEL,
                        },
                        dueDate:{
                            type: 'string',
                            default: DUE_DATE_LABEL,
                        },
                        billStatus:{
                            type:'string',
                            default: BILL_STATUS_LABEL,
                        },
                        paymentOptions: {
                            type: 'string',
                            default: PAYMENT_OPTIONS_LABEL,
                        },
                        observesWeekends:{
                            type: 'boolean',
                            default: OBSERVES_WEEKENDS
                        },
                        durationOfPendingHours:{
                            type: 'number',
                            default: DURATION_OF_PENDING_HOURS
                        },
                        overlayText:{
                            type: 'string',
                            default: OVERLAY_TEXT
                        },
                        overlayBackground:{
                            type:'string',
                            default: OVERLAY_BACKGROUND
                        },
                        overlayTextColor:{
                            type:'string',
                            default: OVERLAY_TEXT_COLOR
                        },
                        overlayIconColor:{
                            type:'string',
                            default: OVERLAY_ICON_COLOR
                        }
                    },
                    required: [],
                },
            },
        },
        {
            title: 'Styles',
            controls: {
                type: 'autoform',
                schema: {
                    properties: {
                        margin: {
                            type: 'string',
                            default: MARGIN,
                        },
                        padding: {
                            type: 'string',
                            default: PADDING,
                        },
                        headerBackgroundColor: {
                            type: 'string',
                            default: HEADER_BACKGROUND_COLOR,
                        },
                        headerPadding: {
                            type: 'string',
                            default: HEADER_PADDING,
                        }
                    },
                    required: [],
                },
            },
        },
    ],
};

export default ForsythSearchResultsPlugin;