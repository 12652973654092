import React, { useState, useEffect } from 'react';
import { connect, useDispatch } from "react-redux";
import { Switch, Redirect } from "react-router-dom";
import { Routes } from "../routes";
import Landing from "./Landing";
import Product from "./Product";
import Contact from "./Contact";
import Status from "./Status";
import AccountLanding from "./accountLanding";
import Checkout from "./checkout";
import Receipt from "./Receipt";
import QuickPay from "./QuickPay";
import Login from "./Login";
import Register from "./Register";
import PaymentSettings from "./PaymentSettings";
import PaymentMethod from './PaymentMethod';
import AccountInfo from "./AccountInfo";
import BillingSettings from "./BillingSettings";
import PartnerReferralPage from "./dynamic/PartnerReferralPage";

import { GET_WEB_APP_SETTINGS_REQUEST, GET_WEB_APP_SETTINGS_SUCCESS, GET_WEB_APP_SETTINGS_FAILURE, setPublicClientApplication } from "../redux/actions/webAppSettings";

// components
import { getWebAppSettingsAction } from '../redux/actions/webAppSettings'
import { IActionResult, IAppState } from '../redux/storeTypes';
import RouteWithLoader from '../components/layout/RouteWithLoader';
import WebApplicationStart from "./WebApplicationStart";
import LoginPage from "./dynamic/LoginPage";
import CheckoutPage from "./dynamic/CheckoutPage";
import LinkPage from "./dynamic/LinkPage";
import ReceiptPage from "./dynamic/ReceiptPage";
import AuthorizationPage from "./dynamic/AuthorizationPage";
import AccountPage from './dynamic/AccountPage';
import GenericPage from './dynamic/GenericPage';
import ShoppingCartPage from './dynamic/ShoppingCartPage';
import LandingPage from './dynamic/LandingPage';
import QuickPayPage from './dynamic/QuickPayPage';
import { PublicClientApplication } from '@azure/msal-browser';
export interface IHomePageProps {
  publicClientApplication: PublicClientApplication,
  actionResult: IActionResult
}

const HomePage = ({ publicClientApplication, actionResult }: IHomePageProps) => {
  //console.log("HomePage");
  const dispatch = useDispatch();
  const actionToken = "HomePage";
  const [webAppSettingsLoaded, setWebAppSettingsLoaded] = useState(false);
  let subdomain:string | null = null;

  useEffect(() => {
    if (!webAppSettingsLoaded) {
        dispatch(setPublicClientApplication(publicClientApplication));
        dispatch(getWebAppSettingsAction(actionToken));
        // eslint-disable-next-line react-hooks/exhaustive-deps
        subdomain = localStorage.getItem("subdomain");
        //console.log("useEffect homepage", subdomain);
    }
  }, [webAppSettingsLoaded]);

  useEffect(() => {
    const interval = setInterval(() => {
        dispatch(getWebAppSettingsAction(actionToken));
    }, 600000);
    return () => clearInterval(interval);
  }, [dispatch]);

  useEffect(() => {
    if (actionResult && actionResult.result && actionResult.type === GET_WEB_APP_SETTINGS_REQUEST && actionResult.token === actionToken) {
      if (actionResult.result === GET_WEB_APP_SETTINGS_SUCCESS) {
        setWebAppSettingsLoaded(true);
      } else if (actionResult.result === GET_WEB_APP_SETTINGS_FAILURE) {
          alert('Something went wrong while retrieving WSM token. Please again later.')
      }
    }
  }, [actionResult]);

  if (webAppSettingsLoaded && !subdomain) {
    return (
      <Switch>
        <RouteWithLoader exact path={Routes.Landing.path} component={Landing} />
        <RouteWithLoader exact path={Routes.Product.path} component={Product} />
        <RouteWithLoader exact path={Routes.Contact.path} component={Contact} />
        <RouteWithLoader exact path={Routes.Status.path} component={Status} />

        <RouteWithLoader exact path={Routes.WebApplicationStart.path} component={WebApplicationStart} />

        <RouteWithLoader exact path={Routes.AccountPage.path} component={AccountPage} />
        <RouteWithLoader exact path={Routes.CheckoutPage.path} component={CheckoutPage} />
        <RouteWithLoader exact path={Routes.LinkPage.path} component={LinkPage} />
        <RouteWithLoader exact path={Routes.GenericPage.path} component={GenericPage} />
        <RouteWithLoader exact path={Routes.LandingPage.path} component={LandingPage} />
        <RouteWithLoader exact path={Routes.LoginPage.path} component={LoginPage} />
        <RouteWithLoader exact path={Routes.ReceiptPage.path} component={ReceiptPage} />
        <RouteWithLoader exact path={Routes.AuthorizationPage.path} component={AuthorizationPage} />
            
        <RouteWithLoader exact path={Routes.ShoppingCartPage.path} component={ShoppingCartPage} />
        <RouteWithLoader exact path={Routes.QuickPay.path} component={QuickPayPage} />
        <RouteWithLoader exact path={Routes.PaymentSettings.path} component={PaymentSettings} />
        <RouteWithLoader exact path={Routes.PartnerReferralPage.path} component={PartnerReferralPage} />

        <RouteWithLoader exact path={Routes.LoginStatic.path} component={Login} />
        <RouteWithLoader exact path={Routes.AccountLandingStatic.path} component={AccountLanding} />
        <RouteWithLoader exact path={Routes.CheckoutStatic.path} component={Checkout} />
        <RouteWithLoader exact path={Routes.ReceiptStatic.path} component={Receipt} />
        <RouteWithLoader exact path={Routes.QuickPayStatic.path} component={QuickPay} />
        <RouteWithLoader exact path={Routes.RegisterStatic.path} component={Register} />
        <RouteWithLoader exact path={Routes.PaymentSettingsStatic.path} component={PaymentSettings} />
        <RouteWithLoader exact path={Routes.PaymentMethodStatic.path} component={PaymentMethod} />
        <RouteWithLoader exact path={Routes.AccountInfoStatic.path} component={AccountInfo} />
        <RouteWithLoader exact path={Routes.BillingSettingsStatic.path} component={BillingSettings} />
        <RouteWithLoader path={Routes.ForsythDeepLink.path} component={QuickPayPage} />
        <Redirect to={Routes.Landing.path} />
      </Switch>
    )
  } else {
    return (
      <>
        <span className="authorizing">Loading...</span>
      </>
    )
  }

};


const mapStateToProps = (state: IAppState) => {
  return {
    actionResult: state.webAppSettings.actionResult
  };
};

export default connect(mapStateToProps)(HomePage);