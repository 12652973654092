import React, { useEffect, useState } from 'react';
import { Row, Spinner } from 'react-bootstrap';

interface IFacet {
    data: any;
    filteredData: any;
    filters: any;
    onFilterChange: any;
    isFetching: boolean
}

const StCharlesFacet = ({ data, filteredData, filters, onFilterChange, isFetching }: IFacet) => {

    const getFilterValues = (filterType: any) => {
        const values = new Set();
        data.forEach((item: any) => {
                values.add(item[filterType])
        });
        return Array.from(values);
    }

    const calculateCounts = (filterType: any) => {
        const counts: Record<string, number> = {};
        filteredData.forEach((item: any) => {
                const value = item[filterType];
                counts[value] = (counts[value] || 0) + 1;
        });
        return counts;
    };

    const renderFilters = (filterType: any) => {
        const filterValues = getFilterValues(filterType);
        const counts = calculateCounts(filterType);

        return filterValues.map((value: any) => {
            const count = counts[value] || 0;
            const isZeroCount = count === 0;
            return (
                <label key={value} className={isZeroCount ? 'radio_label_zero' : 'radio_label'}>
                    <input
                        type='checkbox'
                        checked={filters[filterType].includes(value)}
                        onChange={() => onFilterChange(filterType, value)}
                        className="radio_input" />
                    {value} ({counts[value] || 0})
                </label>
            )
        })
    }

    return (
        <Row className="container">
            {isFetching ?
                <div style={{ textAlign: 'center' }} ><Spinner animation="border" /> </div>
                :
                <>
                    <div className='radio_heading'>
                        <h3>Payment Status</h3>
                        {renderFilters('paymentStatus')}
                    </div>
                    <div className='radio_heading'>
                        <h3>Tax type</h3>
                        {renderFilters('propertyType')}
                    </div>
                    <div className='radio_heading'>
                        <h3>Years</h3>
                        {renderFilters('taxYear')}
                    </div>
                </>
            }
        </Row>
    )

}

export default StCharlesFacet;