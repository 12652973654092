import { CellPlugin } from '@react-page/editor';

type Data = {
  label: string,
  href: string,
  text: string,
  path: string,
  openInNewWindow?: boolean,
  backgroundColor: string,
  fontColor: string,
  textAlign: string,
}

const LABEL = 'Click here';
const FONT_COLOR = '#FFFFFF';
const BACKGROUND_COLOR = '#0057B8'
const TEXT_ALIGN = 'left';

const checkStyleForDefault = (style:string, defaultStyle:string) => {
  return style && style !== ''?style:defaultStyle;
}

const checkStyleForDefault3 = (defaultStyle:string) => {
  return defaultStyle as any;
}

const ButtonPlugin: CellPlugin<Data> = {
  Renderer: ({ data }) => (
        <div style={{ textAlign:checkStyleForDefault3(data.textAlign) }}>
          <a href={data.href} target={data.openInNewWindow ? '_blank' : undefined} className="btn" style={{ 
            backgroundColor:checkStyleForDefault(data.backgroundColor, BACKGROUND_COLOR),
            color:checkStyleForDefault(data.fontColor, FONT_COLOR),
           }} role="button">
            {data.text ? data.text : data.href}
          </a>
        </div>
  ),
  id: 'buttonPlugin',
  title: 'Button',
  description: 'A standard button.',
  version: 1,
  controls: [
    {
      title: 'Labels',
      controls: {
        type: 'autoform',
        schema: {
          properties: {
            text: {
              type: 'string',
              default: LABEL,
            },
            href: {
              type: 'string',
            },
            openInNewWindow: {
              type: 'boolean',
            },
          },
          required: ['text', 'href'],
        },
      },
    },
    {
      title: 'Styles',
      controls: {
        type: 'autoform',
        schema: {
          properties: {
            backgroundColor: {
              type: 'string',
              default: BACKGROUND_COLOR,
            },
            fontColor: {
              type: 'string',
              default: FONT_COLOR,
            },
            textAlign: {
              type: 'string',
              default: TEXT_ALIGN,
            },
          },
          required: ['text', 'href'],
        },
      },
    },
  ]
};

export default ButtonPlugin;