import axios from 'axios';
import { Dispatch } from 'react';
import { Department, PaymentChannel } from '../../models/Client';
import { WebPage, WebApplication } from '../../models/PaymentChannelWebApplication';
import store from '../store';

export const GET_WEB_APPLICATION_REQUEST = 'GET_WEB_APPLICATION_REQUEST';
export const GET_WEB_APPLICATION_SUCCESS = 'GET_WEB_APPLICATION_SUCCESS';
export const GET_WEB_APPLICATION_FAILURE = 'GET_WEB_APPLICATION_FAILURE';

export const GET_PAYMENT_CHANNEL_REQUEST = 'GET_PAYMENT_CHANNEL_REQUEST';
export const GET_PAYMENT_CHANNEL_SUCCESS = 'GET_PAYMENT_CHANNEL_SUCCESS';
export const GET_PAYMENT_CHANNEL_FAILURE = 'GET_PAYMENT_CHANNEL_FAILURE';

export const SAVE_WEB_APPLICATION_REQUEST = 'SAVE_WEB_APPLICATION_REQUEST';
export const SAVE_WEB_APPLICATION_SUCCESS = 'SAVE_WEB_APPLICATION_SUCCESS';
export const SAVE_WEB_APPLICATION_FAILURE = 'SAVE_WEB_APPLICATION_FAILURE';

export const SAVE_WEB_PAGE_REQUEST = 'SAVE_WEB_PAGE_REQUEST';
export const SAVE_WEB_PAGE_SUCCESS = 'SAVE_WEB_PAGE_SUCCESS';
export const SAVE_WEB_PAGE_FAILURE = 'SAVE_WEB_PAGE_FAILURE';

export const GET_WEB_PAGE_REQUEST = 'GET_WEB_PAGE_REQUEST';
export const GET_WEB_PAGE_SUCCESS = 'GET_WEB_PAGE_SUCCESS';
export const GET_WEB_PAGE_FAILURE = 'GET_WEB_PAGE_FAILURE';


export const getWebApplicationForSubdomainAction = (subdomain:string, actionToken?:string) => (dispatch:Dispatch<any>) => {
    dispatch({
        type: GET_WEB_APPLICATION_REQUEST,
        payload: {
            actionToken
        }
    });

    let baseApiUrl = store.getState().webAppSettings.baseApiUrl;
    let _url = baseApiUrl + "/MSB_Client/api/v1/WebApplications/bySubdomain/" + subdomain;

    let bearerToken = store.getState().webAppSettings.bearerToken;
    const config = {
        headers: { Authorization: `Bearer ${bearerToken}` }
    };

    let webApplication = new WebApplication();

    axios.get(_url, config)
        .then(function (response) {
            webApplication = response.data;
            dispatch({
                type: GET_WEB_APPLICATION_SUCCESS,
                payload: {
                    webApplication,
                    actionToken
                }
            });
        })
        .catch(function (error) {
            dispatch({
                type: GET_WEB_APPLICATION_FAILURE,
                payload: {
                    error,
                    actionToken
                }
            });
        })
        .then(function () {
        }); 
};

export const getWebApplicationForPaymentChannelAction = (paymentChannelmsbId:string, actionToken?:string) => (dispatch:Dispatch<any>) => {
    dispatch({
        type: GET_WEB_APPLICATION_REQUEST,
        payload: {
            actionToken
        }
    });

    let baseApiUrl = store.getState().webAppSettings.baseApiUrl;
    let _url = baseApiUrl + "/MSB_Client/api/v1/WebApplications/ForPaymentChannel/" + paymentChannelmsbId;

    let bearerToken = store.getState().webAppSettings.bearerToken;
    const config = {
        headers: { Authorization: `Bearer ${bearerToken}` }
    };

    axios.get(_url, config)
        .then(function (response) {
            let webApplication = response.data;
            dispatch({
                type: GET_WEB_APPLICATION_SUCCESS,
                payload: {
                    webApplication,
                    actionToken
                }
            });
        })
        .catch(function (error) {
            dispatch({
                type: GET_WEB_APPLICATION_FAILURE,
                payload: {
                    error,
                    actionToken
                }
            });
        })
        .then(function () {
        }); 
};

export const getPaymentChannelAction = (msbId:string, actionToken?:string) => (dispatch:Dispatch<any>) => {
    dispatch({
        type: GET_PAYMENT_CHANNEL_REQUEST,
        payload: {
            actionToken
        }
    });

    let baseApiUrl = store.getState().webAppSettings.baseApiUrl;
    let _url = baseApiUrl + `/MSB_Client/api/v1/PaymentChannels/${msbId}/asConstituent`;

    let bearerToken = store.getState().webAppSettings.bearerToken;
    const config = {
        headers: { Authorization: `Bearer ${bearerToken}` }
    };

    let paymentChannel = new PaymentChannel();

    axios.get(_url, config)
        .then(function (response) {
            paymentChannel = response.data;
            dispatch({
                type: GET_PAYMENT_CHANNEL_SUCCESS,
                payload: {
                    paymentChannel,
                    actionToken
                }
            });
        })
        .catch(function (error) {
            dispatch({
                type: GET_PAYMENT_CHANNEL_FAILURE,
                payload: {
                    error,
                    actionToken
                }
            });
        })
        .then(function () {
        }); 
};


export const getWebPageAction = (msbId:string, actionToken?:string) => (dispatch:Dispatch<any>) => {
    dispatch({
        type: GET_WEB_PAGE_REQUEST,
        payload: {
            actionToken
        }
    });

    let baseApiUrl = store.getState().webAppSettings.baseApiUrl;
    let _url = baseApiUrl + "/MSB_Client/api/v1/WebPages/" + msbId;

    let bearerToken = store.getState().webAppSettings.bearerToken;
    const config = {
        headers: { Authorization: `Bearer ${bearerToken}` }
    };

    axios.get(_url, config)
        .then(function (response) {
            let webPage = response.data;
            dispatch({
                type: GET_WEB_PAGE_SUCCESS,
                payload: {
                    webPage,
                    actionToken
                }
            });
        })
        .catch(function (error) {
            dispatch({
                type: GET_WEB_PAGE_FAILURE,
                payload: {
                    error,
                    actionToken
                }
            });
        })
        .then(function () {
        }); 
};

export const saveWebPageAction = (webPage:WebPage, actionToken?:string) => (dispatch: Dispatch<any>) => {
    dispatch({
        type: SAVE_WEB_PAGE_REQUEST,
        payload: {
            actionToken
        }
    });

    let baseApiUrl = store.getState().webAppSettings.baseApiUrl;
    let _url = baseApiUrl + "/MSB_Client/api/v1/WebPages"
    let bearerToken = store.getState().webAppSettings.bearerToken;
    const config = {
        headers: { Authorization: `Bearer ${bearerToken}` }
    };

    _url = _url + "/" + webPage.msbId;

    axios.put(_url, webPage, config)
        .then(function (response) {
            webPage = response.data;
            dispatch({
                type: SAVE_WEB_PAGE_SUCCESS,
                payload: {
                    webPage,
                    actionToken
                }
            });

        })
        .catch(function (error) {
            dispatch({
                type: SAVE_WEB_PAGE_FAILURE,
                payload: {
                    error,
                    actionToken
                }
            });
        });
};

export const saveWebApplicationAction = (webApplication:WebApplication, actionToken?:string) => (dispatch: Dispatch<any>) => {
    dispatch({
        type: SAVE_WEB_APPLICATION_REQUEST,
        payload: {
            actionToken
        }
    });

    let baseApiUrl = store.getState().webAppSettings.baseApiUrl;
    let _url = baseApiUrl + "/MSB_Client/api/v1/WebApplications"
    let bearerToken = store.getState().webAppSettings.bearerToken;
    const config = {
        headers: { Authorization: `Bearer ${bearerToken}` }
    };

    _url = _url + "/" + webApplication.msbId;

    axios.put(_url, webApplication, config)
        .then(function (response) {
            webApplication = response.data;
            dispatch({
                type: SAVE_WEB_APPLICATION_SUCCESS,
                payload: {
                    webApplication,
                    actionToken
                }
            });

        })
        .catch(function (error) {
            dispatch({
                type: SAVE_WEB_APPLICATION_FAILURE,
                payload: {
                    error,
                    actionToken
                }
            });
        });
};
