import React, { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { Redirect, useParams } from "react-router-dom";
import { IActionResult, IAppState } from "../../redux/storeTypes";
import { WebApplication, WebPage, WebPageEnum } from "../../models/PaymentChannelWebApplication";
import { Content, StatusEnum } from "../../models/CMS";
import type { Value } from '@react-page/editor';
import Editor, { CellPlugin } from '@react-page/editor';

import slate from '@react-page/plugins-slate';
import image from '@react-page/plugins-image';
import { getContentAction } from "../../redux/actions/cms";
import { Routes } from "../../routes";
import CardPlugin from "../paymentChannels/web/components/plugins/CardPlugin";
import ReleaseCardPlugin from "../paymentChannels/web/components/plugins/ReleaseCardPlugin";
import HeaderPlugin from "../paymentChannels/web/components/plugins/HeaderPlugin";
import HorizontalRulePlugin from "../paymentChannels/web/components/plugins/HorizontalRulePlugin";
import PlainTextPlugin from "../paymentChannels/web/components/plugins/PlainTextPlugin";
import NavigationLinkPlugin from "../paymentChannels/web/components/plugins/NavigationLinkPlugin";
import ButtonPlugin from "../paymentChannels/web/components/plugins/ButtonPlugin";
import HeroPlugin from "../paymentChannels/web/components/plugins/HeroPlugin";
import PaymentLogosPlugin from "../paymentChannels/web/components/plugins/PaymentLogosPlugin";
import SpacerPlugin from "../paymentChannels/web/components/plugins/SpacerPlugin";
import CopyrightFooterPlugin from "../paymentChannels/web/components/plugins/FooterCopyrightPlugin";
import PrivacyPolicyTermsConditionFooterPlugin from "../paymentChannels/web/components/plugins/FooterPrivacyPolicyTermsConditions";

import '@react-page/editor/lib/index.css';

export interface IParams {
    id: string
}

export interface ILandingPageProps {
    content: Content,
    webApplication: WebApplication,
    actionResult: IActionResult
}

const LandingPage = ({ webApplication, content, actionResult }: ILandingPageProps) => {
    console.log("LandingPage");
    let actionToken = "LandingPage"
    const dispatch = useDispatch();
    const { id }:IParams = useParams();
    const [value, setValue] = useState<Value>();
    const [webPage, setWebPage] = useState<WebPage>();
    const [redirect, setRedirect] = useState<string>("");

    const setRoute = (path:string, webApplicationName:string) => {
        return path.replace(":id", webApplicationName) + "?" + WebPageEnum.Landing;
    }

    let cardPlugin = CardPlugin as CellPlugin<unknown, unknown>;
    let releaseCardPlugin = ReleaseCardPlugin as CellPlugin<unknown, unknown>;
    let horizontalRulePlugin = HorizontalRulePlugin as CellPlugin<unknown, unknown>;
    let imagePlugin = image as CellPlugin<unknown, unknown>;
    let slatePlugin = slate() as CellPlugin<unknown, unknown>;
    let headerPlugin = HeaderPlugin as CellPlugin<unknown, unknown>;
    let plainTextPlugin = PlainTextPlugin as CellPlugin<unknown, unknown>;
    let navigationLinkPlugin = NavigationLinkPlugin as CellPlugin<unknown, unknown>;
    let buttonPlugin = ButtonPlugin as CellPlugin<unknown, unknown>;
    let heroPlugin = HeroPlugin as CellPlugin<unknown, unknown>;
    let paymentLogosPlugin = PaymentLogosPlugin as CellPlugin<unknown, unknown>;
    let spacerPlugin = SpacerPlugin as CellPlugin<unknown, unknown>;
    let copyrightFooterPlugin = CopyrightFooterPlugin as CellPlugin<unknown, unknown>;
    let footerPrivacyPolicyTermsCondition = PrivacyPolicyTermsConditionFooterPlugin as CellPlugin<unknown, unknown>;

    const cellPlugins = new Array<CellPlugin>();
    cellPlugins.push(imagePlugin);
    cellPlugins.push(slatePlugin);  
    cellPlugins.push(headerPlugin);  
    cellPlugins.push(cardPlugin);
    cellPlugins.push(releaseCardPlugin);
    cellPlugins.push(horizontalRulePlugin);
    cellPlugins.push(plainTextPlugin);  
    cellPlugins.push(navigationLinkPlugin);  
    cellPlugins.push(buttonPlugin);  
    cellPlugins.push(heroPlugin); 
    cellPlugins.push(paymentLogosPlugin); 
    cellPlugins.push(spacerPlugin); 
    cellPlugins.push(copyrightFooterPlugin);  
    cellPlugins.push(footerPrivacyPolicyTermsCondition);  

    useEffect(() => {
        if (webApplication) {
            const getDeepLink = sessionStorage.getItem('deepLink');
            const targetDeepLinkString = "/search";
            const isDeepLink = getDeepLink?.includes(targetDeepLinkString);
            if (isDeepLink) {
                let webLink = webApplication.webPages.filter(_ => _.webPageType === WebPageEnum.QuickPay)[0];
                if (webLink) {
                    if (webLink.contentId !== '00000000-0000-0000-0000-000000000000') {
                        dispatch(getContentAction(webLink.contentId, actionToken));
                    }
                    setWebPage(webLink);
                    setRedirect(setRoute(Routes.QuickPay.path, id));
                }
            }
            else {
                let webPage = webApplication.webPages.filter(_ => _.webPageType === WebPageEnum.Landing)[0];
                if (webPage) {
                    if (webPage.contentId !== '00000000-0000-0000-0000-000000000000') {
                        dispatch(getContentAction(webPage.contentId, actionToken));
                    } else {
                    }
                    setWebPage(webPage);
                }
            }
        } else {
            setRedirect(setRoute(Routes.WebApplicationStart.path, id));
        }
    }, [webApplication]);

    useEffect(() => {
        if (content && webPage && content.msbId! === webPage.contentId) {
            for (let x = 0; x < content.revisions.length;x++) {
                if (content.revisions[x].statusEnum === StatusEnum.Draft) {
                    let revision = content.revisions[x];
                    let value = JSON.parse(revision.value);
                    setValue(value);
                    break;
                }
            }
        }
    }, [content]);   

    if (redirect !== "") {
        return (<Redirect push to={redirect!} />)
    } else {
        return (
            <>
                <Editor cellPlugins={cellPlugins} value={value} readOnly={true} />
            </>
        )
    }
};

const mapStateToProps = (state: IAppState) => {
    return {
        webApplication: state.paymentChannelWebApplication.webApplication,
        content: state.cms.content
    };
};

export default connect(mapStateToProps)(LandingPage);

