import React from "react";
import moment from "moment-timezone";
import { Row, Col, Nav, } from "react-bootstrap";


export default () => {
  const currentYear = moment().get("year");

  return (
      <footer className="container msb-portal-footer py-5">
        <span>
          Copyright &copy; {`${currentYear} `}
        </span>
        <Nav>
          <Nav.Item>
            <Nav.Link href="#">Overview</Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link href="#">Community</Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link href="#">Contact</Nav.Link>
          </Nav.Item>
        </Nav>
      </footer>
  );
};
