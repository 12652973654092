import { PhoneOutlined, EmailOutlined, PersonOutlined } from "@mui/icons-material";
import { Grid, TextField, InputAdornment } from "@mui/material";
import { AddressRequirement, Billing, Contact, ContactRequirement } from "../../models/CheckoutLink";
import { ForwardedRef } from "react";
import InputMask from "react-input-mask";

export interface ICheckoutBillingProps {
    payerName?: string;
    billing: Billing;
    contact: Contact;
    addressRequirement: AddressRequirement;
    contactRequirement: ContactRequirement;
    billingName: ForwardedRef<any>;
    billingAddress1: ForwardedRef<any>;
    billingAddress2: ForwardedRef<any>;
    billingCity: ForwardedRef<any>;
    billingState: ForwardedRef<any>;
    billingZip: ForwardedRef<any>;
    billingCountry: ForwardedRef<any>;
    billingPhone: ForwardedRef<any>;
    billingEmail: ForwardedRef<any>;
}

export const CheckoutBilling = ({ payerName, billing, contact, addressRequirement, contactRequirement, billingName, billingAddress1, billingAddress2, billingCity, billingState, billingZip, billingCountry, billingPhone, billingEmail }: ICheckoutBillingProps) => {
    return (
        <>
            {payerName !== undefined &&
            <Grid container spacing="3" style={{ paddingTop: "8px" }} >
                <Grid item xs>
                    <TextField
                        required
                        label="Name"
                        fullWidth
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <PersonOutlined sx={{ color: "lightgrey" }} />
                                </InputAdornment>
                            ),
                        }}
                        size="small"
                        onInvalid={(e) => {
                            let target = e.target as HTMLInputElement;
                            if (target.validity.tooShort) target.setCustomValidity("Please complete this entry")
                        }}
                        onInput={(e) => {
                            let target = e.target as HTMLInputElement;
                            if (target.validity.customError && target.validity.tooShort) {
                                target.setCustomValidity("Please complete this entry")
                            } else {
                                target.setCustomValidity("")
                            }
                        }}
                        inputProps={{ minLength: 3, maxLength: 50, }}
                        defaultValue={payerName || ""}
                        inputRef={billingName}
                    />
                </Grid>
            </Grid>}
            {addressRequirement === AddressRequirement.FULL &&
            <>
            <Grid container spacing="3" style={{ paddingTop: "8px" }}>
                <Grid item xs>
                    <TextField
                        required
                        label="Address Line 1"
                        fullWidth
                        size="small"
                        onInvalid={(e) => {
                            let target = e.target as HTMLInputElement;
                            if (target.validity.tooShort) target.setCustomValidity("Please complete this entry")
                        }}
                        onInput={(e) => {
                            let target = e.target as HTMLInputElement;
                            if (target.validity.customError && target.validity.tooShort) {
                                target.setCustomValidity("Please complete this entry")
                            } else {
                                target.setCustomValidity("")
                            }
                        }}
                        inputProps={{ minLength: 3, maxLength: 40, }}
                        defaultValue={billing.address1}
                        inputRef={billingAddress1}
                    />
                </Grid>
            </Grid>
            <Grid container spacing="3" style={{ paddingTop: "8px" }}>
                <Grid item xs>
                    <TextField
                        label="Address Line 2"
                        fullWidth
                        size="small"
                        onInvalid={(e) => {
                            let target = e.target as HTMLInputElement;
                            if (target.validity.tooShort) target.setCustomValidity("Please complete this entry")
                        }}
                        onInput={(e) => {
                            let target = e.target as HTMLInputElement;
                            if (target.validity.customError && target.validity.tooShort) {
                                target.setCustomValidity("Please complete this entry")
                            } else {
                                target.setCustomValidity("")
                            }
                        }}
                        inputProps={{ minLength: 3, maxLength: 40, }}
                        defaultValue={billing.address2}
                        inputRef={billingAddress2}
                    />
                </Grid>
            </Grid>
            <Grid container spacing="3" style={{ paddingTop: "8px" }}>
                <Grid item xs>
                    <TextField
                        required
                        label="City"
                        fullWidth
                        size="small"
                        onInvalid={(e) => {
                            let target = e.target as HTMLInputElement;
                            if (target.validity.tooShort) target.setCustomValidity("Please complete this entry")
                        }}
                        onInput={(e) => {
                            let target = e.target as HTMLInputElement;
                            if (target.validity.customError && target.validity.tooShort) {
                                target.setCustomValidity("Please complete this entry")
                            } else {
                                target.setCustomValidity("")
                            }
                        }}
                        inputProps={{ minLength: 3, maxLength: 28, }}
                        defaultValue={billing.city}
                        inputRef={billingCity}
                    />
                </Grid>
                <Grid item xs>
                    <TextField
                        required
                        label="State"
                        fullWidth
                        size="small"
                        onInvalid={(e) => {
                            let target = e.target as HTMLInputElement;
                            if (target.validity.tooShort) target.setCustomValidity("Please complete this entry")
                        }}
                        onInput={(e) => {
                            let target = e.target as HTMLInputElement;
                            if (target.validity.customError && target.validity.tooShort) {
                                target.setCustomValidity("Please complete this entry")
                            } else {
                                target.setCustomValidity("")
                            }
                        }}
                        inputProps={{ minLength: 2, maxLength: 2, style: { textTransform: "uppercase" } }}
                        defaultValue={billing.state}
                        inputRef={billingState}
                    />
                </Grid>
            </Grid>
            </>}
            {(addressRequirement === AddressRequirement.FULL || addressRequirement === AddressRequirement.ZIP_ONLY) &&
            <Grid container spacing="3" style={{ paddingTop: "8px" }}>
                <Grid item xs>
                    <TextField
                        required
                        label="Zip Code"
                        fullWidth
                        size="small"
                        onInvalid={(e) => {
                            let target = e.target as HTMLInputElement;
                            if (target.validity.tooShort) target.setCustomValidity("Please complete this entry")
                        }}
                        onInput={(e) => {
                            let target = e.target as HTMLInputElement;
                            if (target.validity.customError && target.validity.tooShort) {
                                target.setCustomValidity("Please complete this entry")
                            } else {
                                target.setCustomValidity("")
                            }
                        }}
                        onKeyPress={(event) => { if (!/[a-zA-Z0-9 ]/.test(event.key)) { event.preventDefault(); } }}
                        inputProps={{ minLength: 5, maxLength: 8, style: { textTransform: "uppercase" } }}
                        defaultValue={billing.zip}
                        inputRef={billingZip}
                    />
                </Grid>
                <Grid item xs>
                    <TextField
                        required
                        label="Country"
                        fullWidth
                        size="small"
                        onInvalid={(e) => {
                            let target = e.target as HTMLInputElement;
                            if (target.validity.tooShort) target.setCustomValidity("Please complete this entry")
                        }}
                        onInput={(e) => {
                            let target = e.target as HTMLInputElement;
                            if (target.validity.customError && target.validity.tooShort) {
                                target.setCustomValidity("Please complete this entry")
                            } else {
                                target.setCustomValidity("")
                            }
                        }}
                        inputProps={{ minLength: 2, maxLength: 2, style: { textTransform: "uppercase" } }}
                        defaultValue={billing.country}
                        inputRef={billingCountry}
                    />
                </Grid>
            </Grid>}
            {(contactRequirement === ContactRequirement.FULL || contactRequirement === ContactRequirement.PHONE_ONLY) &&
            <Grid container style={{ paddingTop: "8px" }}>
                <Grid item xs>
                    <InputMask
                        mask="(999) 999-9999"
                        maskPlaceholder={null}
                        defaultValue={contact.phone.replace(/[^0-9]/g, "").replace(/([0-9]{3})([0-9]{3})([0-9]{4})/, "($1) $2-$3")}>
                        {() =>
                            <TextField
                                required
                                label="Phone"
                                fullWidth
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <PhoneOutlined sx={{ color: "lightgrey" }} />
                                        </InputAdornment>
                                    ),
                                }}
                                size="small"
                                inputProps={{ pattern: "\\([0-9]{3}\\) [0-9]{3}-[0-9]{4}" }}
                                inputRef={billingPhone}
                            />}
                    </InputMask>
                </Grid>
            </Grid>}
            {(contactRequirement === ContactRequirement.FULL || contactRequirement === ContactRequirement.EMAIL_ONLY) &&
            <Grid container style={{ paddingTop: "8px" }}>
                <Grid item xs>
                    <TextField
                        required
                        label="Email"
                        fullWidth
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <EmailOutlined sx={{ color: "lightgrey" }} />
                                </InputAdornment>
                            ),
                        }}
                        size="small"
                        onInvalid={(e) => {
                            let target = e.target as HTMLInputElement;
                            console.log(target.validity);
                            if (target.validity.typeMismatch) target.setCustomValidity("Please use proper email format")
                        }}
                        onInput={(e) => {
                            let target = e.target as HTMLInputElement;
                            if (target.validity.customError && target.validity.typeMismatch) {
                                target.setCustomValidity("Please use proper email format")
                            } else {
                                target.setCustomValidity("")
                            }
                        }}
                        inputProps={{ type: "email", minLength: 3, maxLength: 80, }}
                        defaultValue={contact.email}
                        inputRef={billingEmail}
                    />
                </Grid>
            </Grid>}
        </>
    );
};


