import { ExpandMore, ErrorOutlineOutlined, CheckCircleOutlined } from "@mui/icons-material";
import { Accordion, AccordionSummary, Typography, Box, AccordionDetails, FormControl, Stack, Paper } from "@mui/material";
import { IAppState } from "../../redux/storeTypes";
import { Link, LinkStatus, LinkView, Method } from "../../models/CheckoutLink";
import { connect, useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import { statusLinkAction, viewLinkAction } from "../../redux/actions/link";
import WithMethodCreditCard from "./WithMethodCreditCard";
import WithMethodECheck from "./WithMethodECheck";
import { TokenizeBankAccount, TokenizeCard } from "../../models/Wallet";
import { PaymentCardTypeEnum } from "../../models/Order";
import _ from "lodash";

export interface IWithMethodProps {
    card: TokenizeCard;
    account: TokenizeBankAccount;
    link: Link;
    status: LinkStatus;
    view: LinkView;
}

const WithMethod = ({ card, account, link, status, view }: IWithMethodProps) => {
    const dispatch = useDispatch();
    const [expanded, setExpanded] = useState(true);
    const [paymentSummary, setPaymentSummary] = useState("");

    useEffect(() => {
        if (status) {
            if (!status.withMethod) setExpanded(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [status]);

    useEffect(() => {
        if (view) {
            if (status.withMethod && view !== LinkView.WITHMETHOD) setExpanded(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [view]);

    useEffect(() => {
        if (card) {
            const type = _.startCase(PaymentCardTypeEnum[card.internalCardType].replace("Credit", "").replace("Debit", "")).toUpperCase().replace("AMERICAN EXPRESS", "AMEX");
            const digits = card.cardNumber.slice(-4)
            if (digits) {
                setPaymentSummary(`${type} xxxx-${digits}`);
            } else {
                setPaymentSummary("");
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [card]);

    useEffect(() => {
        if (account) {
            const type = "eCHECK";
            const digits = account.accountNumber.slice(-4)
            if (digits) {
                setPaymentSummary(`${type} xxxx-${digits}`);
            } else {
                setPaymentSummary("");
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [account]);

    const statusWithMethod = () => {
        setExpanded(false);
        dispatch(statusLinkAction({ ...status, withMethod: true }));
    }

    const handleExpansion = () => {
        const _expanded = expanded ? !status?.withMethod : true;
        setExpanded(_expanded);
        if (_expanded) {
            dispatch(viewLinkAction(LinkView.WITHMETHOD));
        } else {
            dispatch(viewLinkAction(LinkView.NONE));
        }
    }

    return (
        <>
            <Accordion defaultExpanded={true} expanded={expanded} disableGutters={true}>
                <AccordionSummary expandIcon={<ExpandMore onClick={handleExpansion} />}>
                    <Typography style={{ flex: 1 }}><Box component="span">{status?.withMethod ? < CheckCircleOutlined sx={{ color: 'green', verticalAlign: 'bottom' }} /> : <ErrorOutlineOutlined sx={{ color: 'red', verticalAlign: 'bottom' }} />} Link </Box></Typography>
                    <Box component="div" textAlign={'center'} display={'flex'}>{paymentSummary}</Box>
                </AccordionSummary>
                <AccordionDetails>
                    <FormControl style={{ width: '100%' }}>
                        <Stack>
                            {link.method === Method.CARD &&
                            <Paper sx={{ padding: '0px 10px 0px 10px' }}>
                                <Box component="div">
                                    <WithMethodCreditCard setStatus={statusWithMethod} />
                                </Box>
                            </Paper>}
                            {link.method === Method.ACH &&
                            <Paper sx={{ padding: '0px 10px 0px 10px' }}>
                                <Box component="div">
                                    <WithMethodECheck setStatus={statusWithMethod} />
                                </Box>
                            </Paper>}
                        </Stack>
                    </FormControl>
                </AccordionDetails>
            </Accordion>
        </>
    );
};

const mapStateToProps = (state: IAppState) => {
    return {
        link: state.link.link,
        status: state.link.status,
        view: state.link.view,
        card: state.wallet.card,
        account: state.wallet.bankAccount,
    };
};

export default connect(mapStateToProps)(WithMethod);

  