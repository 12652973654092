import React from "react";
import { Navbar, Nav, NavDropdown } from 'react-bootstrap';
import statencLogo from "../../assets/img/state-nc-logo.png";
import logo from '../../assets/img/logo.svg';

export default () => {
    const setRoute = (path:string) => {
        return path.replace(":id", "Demo");
    }

    return (
        <header className="msb-portal__header">
            <Navbar expand="lg" className="justify-content-between px-3 msb-portal__nav">
                <Navbar.Brand href="#home">
                    <img style={{ height: 40, marginTop: '20px', marginBottom: '20px' }} src={logo} />
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav defaultActiveKey="/" as="ul" className="mr-auto">
                        <Nav.Item as="li">
                            <Nav.Link href="/">Home</Nav.Link>
                        </Nav.Item>
                        <NavDropdown id="nav-dropdown" title="Account" alignRight>
                            <NavDropdown.Item href="/Demo/Account/Static">Open orders</NavDropdown.Item>
                            <NavDropdown.Item href="#action/3.2">Transaction history</NavDropdown.Item>
                        </NavDropdown>
                        <NavDropdown id="nav-dropdown" title="Profile" alignRight>
                            <NavDropdown.Item href="/Demo/PaymentSettings/Static">Auto pay & Paperless</NavDropdown.Item>
                            <NavDropdown.Item href="#action/3.2">Change password</NavDropdown.Item>
                            <NavDropdown.Item href="/Demo/PaymentMethod/Static">Payment method</NavDropdown.Item>
                            <NavDropdown.Item href="/Demo/AccountInfo/Static">Account info</NavDropdown.Item>
                            <NavDropdown.Item href="/Demo/BillingSettings/Static">Billing info</NavDropdown.Item>
                        </NavDropdown>
                    </Nav>
                </Navbar.Collapse>
            </Navbar>
        </header>
    );
};
