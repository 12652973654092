import { CellPlugin } from '@react-page/editor';

type Data = {
  label: string,
  href: string,
  text: string,
  path: string,
  openInNewWindow?: boolean
  textAlign: string,
}

const LABEL = 'Click here';
const TEXT_ALIGN = 'left';

const checkStyleForDefault3 = (defaultStyle:string) => {
  return defaultStyle as any;
}

const ButtonPlugin: CellPlugin<Data> = {
  Renderer: ({ data }) => (
        <div style={{ textAlign:checkStyleForDefault3(data.textAlign) }}>
          <a href={data.href} target={data.openInNewWindow ? '_blank' : undefined} className="btn btn-primary" role="button">
            {data.text ? data.text : data.href}
          </a>
        </div>
  ),
  id: 'buttonPlugin',
  title: 'Button',
  description: 'A standard button.',
  version: 1,
  controls: {
    type: 'autoform',
    schema: {
      properties: {
        text: {
          type: 'string',
          default: LABEL,
        },
        href: {
          type: 'string',
        },
        textAlign: {
          type: 'string',
          default: TEXT_ALIGN,
        },
        openInNewWindow: {
          type: 'boolean',
        },
      },
      required: ['text', 'href'],
    },
  },
};

export default ButtonPlugin;