import { PaymentChannel } from "./Client";
import { Content, StatusEnum } from "./CMS";

export enum WebApplicationEnum
{
    Unknown = 0,
    Blind = 1,
    Standard = 2
}

export enum WebPageEnum
{
    Login = 1,
    ItemDetail = 2,
    Category = 3,
    Payment = 4,
    Landing = 5,
    Account = 6,
    Receipt = 7,
    ShoppingCart = 8,
    QuickPay = 9
}

export enum OpenLinkEnum {
    NewTab = 1,
    ExistingTab = 2
}

export class WebPage {
    id?: string;
    msbId?: string;
    webApplicationId?: string;
    contentId: string = "";
    webPageType: WebPageEnum = WebPageEnum.Login;
    revision: number = 0;
    isPublished: boolean = false;
    createdAt: string = "";
    updatedAt: string = "";
}

export class WebApplication  {
    id?: string;
    msbId?: string;
    createdAt: string = "";
    updatedAt: string = "";
    paymentChannelId: string = "";
    paymentChannelMsbId: string = "";
    subdomain: string = "";
    externalReferenceId: string = "";
    //siteSettingsContentId: string = "";
    siteStyleContentId: string = "";
    webApplicationType: keyof typeof WebApplicationEnum = WebApplicationEnum[WebApplicationEnum.Blind] as keyof typeof WebApplicationEnum;
    webPages: Array<WebPage> = new Array<WebPage>();
    paymentChannel: PaymentChannel = new PaymentChannel();
    greenListedInlineFrameUrl: string = "";
    settings?: any;
}

export class SiteSettings {
    siteUrlSubDomainName: string = "";
    siteUrlClientDefinedDNSName: string = "";
    siteUrlPostPaymentRedirectUrl: string = "";
    navigationLinks: Array<SiteSetingsNavigationLinks> = new Array<SiteSetingsNavigationLinks>();
}

export class SiteSetingsNavigationLinks{
    navigationLinkLabel: string = "";
    navigationLinkUrl: string = "";
    navigationLinkOpenLink: OpenLinkEnum = OpenLinkEnum.NewTab;
}

export class SiteStyles {
    colorHeaderBackground: string = "";
    colorNavigationLinks: string = "";
    colorBranding: string = "";
    colorText: string = "";
    colorTextLinks: string = "";
    colorButtons: string = "";
    LogoFile: string = "";
}

