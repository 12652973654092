import React, { useEffect, useState } from 'react';
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Row, Col, Form, Button } from "react-bootstrap"
import { OrderLine } from '../../../../../../models/Order';
import { connect, useDispatch } from 'react-redux';
import { addOrderLineAction, ADD_ORDER_LINE, calculateOrderAction } from '../../../../../../redux/actions/orderManagement';
import { IActionResult, IAppState } from '../../../../../../redux/storeTypes';
import { sendErrorToastAction } from '../../../../../../redux/actions/toast';
import { v4 as uuidv4 } from 'uuid';

interface IPaymentReference {
    paymentDescriptionLabel: string,
    paymentDescriptionPlaceholder: string,
    showDescription: boolean,
    descriptionRequired: boolean,
    paymentReferenceLabel: string,
    paymentReferencePlaceholder: string,
    amountLabel: string,
    amountRequired: string,
    addItemLabel: string,
    actionResult: IActionResult,
    margin: string,
    padding: string,
    fullStoryMask: boolean,
    submitButtonBackgroundColor: string,
    submitButtonFontColor: string,
    submitButtonFontSize: string,
    submitButtonFontStyle: string,
    submitButtonFontWeight: string
}

export const PAYMENT_DESCRIPTION_LABEL = 'Payment Description';
export const PAYMENT_DESCRIPTION_PLACEHOLDER = 'Enter Payment Description';
export const PAYMENT_REFERENCE_LABEL = 'Payment Reference';
export const PAYMENT_REFERENCE_PLACEHOLDER = 'Enter Payment Reference';
export const AMOUNT_LABEL = 'Amount';
export const AMOUNT_REQUIRED = 'Please enter the amount.';
export const ADD_ITEM_LABEL = 'Add Item';
export const MARGIN = '0px';
export const PADDING = '0px';
export const SUBMIT_BUTTON_FONT_COLOR = '#FFFFFF';
export const SUBMIT_BUTTON_FONT_SIZE = '.875rem';
export const SUBMIT_BUTTON_FONT_STYLE = 'normal';
export const SUBMIT_BUTTON_FONT_WEIGHT = 'normal';
export const SUBMIT_BUTTON_BACKGROUND_COLOR = '#0057b6';
export const FULL_STORY_MASK = false;

const checkStyleForDefault = (style: string, defaultStyle: string) => {
    return style && style !== '' ? style : defaultStyle;
}

const checkStyleForDefault3 = (defaultStyle: string) => {
    return defaultStyle as any;
}

const PaymentReference = ({ paymentDescriptionLabel, paymentDescriptionPlaceholder, showDescription, descriptionRequired,
    paymentReferenceLabel, paymentReferencePlaceholder, amountLabel, amountRequired, addItemLabel, actionResult,fullStoryMask,
    margin, padding, submitButtonBackgroundColor, submitButtonFontColor, submitButtonFontSize, submitButtonFontStyle, submitButtonFontWeight }: IPaymentReference) => {

    const actionToken = "PaymentReference";
    const [amount, setAmount] = useState<string>('');
    const [paymentDescription, setPaymentDescription] = useState<string>('');
    const [paymentReference, setPaymentReference] = useState<string>('');
    const dispatch = useDispatch();

    if (!paymentDescriptionLabel) {
        paymentDescriptionLabel = PAYMENT_DESCRIPTION_LABEL;
    }

    if (!paymentDescriptionPlaceholder) {
        paymentDescriptionPlaceholder = PAYMENT_DESCRIPTION_PLACEHOLDER;
    }

    if (!paymentReferenceLabel) {
        paymentReferenceLabel = PAYMENT_REFERENCE_LABEL;
    }

    if (!paymentReferencePlaceholder) {
        paymentReferencePlaceholder = PAYMENT_REFERENCE_PLACEHOLDER;
    }

    if (!amountLabel) {
        amountLabel = AMOUNT_LABEL;
    }

    if (!amountRequired) {
        amountRequired = AMOUNT_REQUIRED;
    }

    if (!addItemLabel) {
        addItemLabel = ADD_ITEM_LABEL;
    }

    if (!margin) {
        margin = MARGIN;
    }

    if (!padding) {
        padding = PADDING;
    }

    if (!submitButtonBackgroundColor) {
        submitButtonBackgroundColor = SUBMIT_BUTTON_BACKGROUND_COLOR;
    }

    if (!submitButtonFontColor) {
        submitButtonFontColor = SUBMIT_BUTTON_FONT_COLOR;
    }

    if (!submitButtonFontSize) {
        submitButtonFontSize = SUBMIT_BUTTON_FONT_SIZE;
    }

    if (!submitButtonFontStyle) {
        submitButtonFontStyle = SUBMIT_BUTTON_FONT_STYLE;
    }

    if (!submitButtonFontWeight) {
        submitButtonFontWeight = SUBMIT_BUTTON_FONT_WEIGHT;
    }

    fullStoryMask = fullStoryMask || FULL_STORY_MASK;


    useEffect(() => {
        if (actionResult && actionResult.type === ADD_ORDER_LINE) {
            dispatch(calculateOrderAction(actionToken));
        }
    }, [actionResult]); // eslint-disable-line react-hooks/exhaustive-deps

    const handleAddItem = () => {
        if (!amount.trim() || !paymentReference.trim() || (showDescription && descriptionRequired && !paymentDescription.trim())) {
            dispatch(sendErrorToastAction(`Item not added to order - missing info.`));
            return;
        }

        let orderLine = new OrderLine();

        orderLine.itemId = uuidv4();
        orderLine.itemName = paymentDescription;
        orderLine.itemReferenceNumber = paymentReference;
        orderLine.quantity = 1;
        orderLine.unitPrice = Number(amount!);

        dispatch(addOrderLineAction(orderLine, actionToken));

        setAmount('');
        setPaymentDescription('');
        setPaymentReference('');
    };

    const handleAmountChange = (e: any) => {
        if (!isNaN(e.target.value)) {
            setAmount(e.target.value);
        }
    }

    const handlePaymentDescriptionChange = (e: any) => {
        setPaymentDescription(e.target.value);
    }

    const handlePaymentReferenceChange = (e:any) => {
        setPaymentReference(e.target.value);
    }

    return (
       <div className={fullStoryMask ? "fs-mask" : ""}>
        <Row style={{ margin: margin, padding: padding, alignItems: 'flex-end' }}>
            {showDescription && <Col>
                <Form.Group controlId="">
                    <Form.Label>{paymentDescriptionLabel}</Form.Label>
                    <Form.Control type="input" className="fs-mask" placeholder={paymentDescriptionPlaceholder} onChange={handlePaymentDescriptionChange} value={paymentDescription} />
                </Form.Group>
            </Col>}
            <Col>
                <Form.Group controlId="paymentReference">
                    <Form.Label>{paymentReferenceLabel}</Form.Label>
                    <Form.Control type="input" className="fs-mask" placeholder={paymentReferencePlaceholder} onChange={handlePaymentReferenceChange} value={paymentReference} />
                </Form.Group>
            </Col>
            <Col>
                <Form.Group controlId="amount">
                    <Form.Label>{amountLabel}</Form.Label>
                    <Form.Control type="input" placeholder="0.00" onChange={handleAmountChange} value={amount} />
                    <Form.Control.Feedback type="invalid">{amountRequired}</Form.Control.Feedback>
                </Form.Group>
            </Col>
            <Col>
                <Button className="brand-btn-primary"  style={{marginTop: '29px',
                        backgroundColor:checkStyleForDefault(submitButtonBackgroundColor, SUBMIT_BUTTON_BACKGROUND_COLOR),
                        borderColor:checkStyleForDefault(submitButtonBackgroundColor, SUBMIT_BUTTON_BACKGROUND_COLOR),
                        color:checkStyleForDefault(submitButtonFontColor, SUBMIT_BUTTON_FONT_COLOR),
                        fontSize:checkStyleForDefault(submitButtonFontSize, SUBMIT_BUTTON_FONT_SIZE),
                        fontStyle:checkStyleForDefault(submitButtonFontStyle, SUBMIT_BUTTON_FONT_STYLE),
                        fontWeight:checkStyleForDefault3(submitButtonFontWeight)
                    }} onClick={handleAddItem}>
                        <FontAwesomeIcon icon={faPlus} size="xs" className="btn-icon" /> {addItemLabel}
                    </Button>
                </Col>
            </Row>
        </div>
    )
}

const mapStateToProps = (state: IAppState) => {
    return {
        isSaving: state.orderManagement.isSaving,
        actionResult: state.orderManagement.actionResult,
        errorMessage: state.orderManagement.errorMessage
    };
};

export default connect(mapStateToProps)(PaymentReference);


