import { Dispatch } from 'react';
import { Checkout, CheckoutStatus, CheckoutView } from '../../models/CheckoutLink';

export const CLEAR_CHECKOUT_REQUEST = 'CLEAR_CHECKOUT_REQUEST';
export const PROCESS_CHECKOUT_REQUEST = 'PROCESS_CHECKOUT_REQUEST';
export const STATUS_CHECKOUT_REQUEST = 'STATUS_CHECKOUT_REQUEST';
export const VIEW_CHECKOUT_REQUEST = 'VIEW_CHECKOUT_REQUEST';

export const clearCheckoutAction = () => (dispatch: Dispatch<any>) => {
    dispatch({
        type: CLEAR_CHECKOUT_REQUEST
    });
};

export const processCheckoutAction = (checkout: Checkout, actionToken?: string) => async (dispatch: Dispatch<any>) => {
    dispatch({
        type: PROCESS_CHECKOUT_REQUEST,
        payload: {
            checkout: checkout,
            actionToken: actionToken
        }
    });    
};

export const statusCheckoutAction = (status: CheckoutStatus, actionToken?: string) => async (dispatch: Dispatch<any>) => {
    dispatch({
        type: STATUS_CHECKOUT_REQUEST,
        payload: {
            status: status,
            actionToken: actionToken
        }
    });
};

export const viewCheckoutAction = (view: CheckoutView, actionToken?: string) => async (dispatch: Dispatch<any>) => {
    dispatch({
        type: VIEW_CHECKOUT_REQUEST,
        payload: {
            view: view,
            actionToken: actionToken
        }
    });
};
