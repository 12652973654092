import React from "react";

export default (props) => {
  const { show } = props;

  return (
    <div
      className={`preloader bg-soft flex-column justify-content-center align-items-center ${
        show ? "" : "show"
      }`}
    >
      <div className="loader loader-element" role="status">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="circle"
          viewBox="25 25 50 50"
        >
          <circle
            className="path"
            cx="50"
            cy="50"
            r="20"
            fill="none"
            strokeWidth="2"
            strokeMiterlimit="10"
          />
        </svg>
        <span className="sr-only">Loading...</span>
      </div>
    </div>
  );
};
