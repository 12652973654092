import { CellPlugin } from '@react-page/editor';
import * as React from 'react';
import { Form } from 'react-bootstrap';
import RequiredIcon from '../../../../../components/RequiredIcon';

type Option = {
    value: string,
    label: string,
}

type Data = {
    label: string,
    name: string,
    required: boolean,
    options: Option[],
    margin: string,
    padding: string,
    fullStoryMask: boolean,
}

const LABEL = 'Option';
const NAME = 'option';
const REQUIRED = false;
const MARGIN = '0px';
const PADDING = '0px';
const FULL_STORY_MASK = false;

const SelectPlugin: CellPlugin<Data> = {
    Renderer: ({ data }) => (
        <Form.Group controlId={`cmd_${data.name}`} style={{ margin: data.margin, padding: data.padding, alignItems: 'flex-end' }} className={data.fullStoryMask ? "fs-mask" : ""}>
            <Form.Label>{(data.required) ? <RequiredIcon /> : ''}{data.label}</Form.Label>
            <Form.Control as='select' required={data.required}>
                <option value={""}>{`Select ${data.label}`}</option>
                {data.options && data.options.map((option, index) => { return (<option value={option.value} key={index}>{option.label}</option>); })}
            </Form.Control>
            <Form.Control.Feedback type="invalid">Please select a {data.label}.</Form.Control.Feedback>
        </Form.Group>
    ),
    id: 'SelectPlugin',
    title: 'Select',
    description: 'Add a select component and define its options.',
    version: 1,
    controls: [
        {
            title: 'Labels',
            controls: {
                type: 'autoform',
                schema: {
                    properties: {
                        label: {
                            type: 'string',
                            default: LABEL,
                        },
                        name: {
                            type: 'string',
                            default: NAME,
                        },
                        fullStoryMask:{
                            type: 'boolean',
                            default: FULL_STORY_MASK
                        },
                        required: {
                            type: 'boolean',
                            default: REQUIRED,
                        },
                        options: {
                            type: 'array',
                            items: {
                                type: 'object',
                                default: {
                                    value: 'option-value-1',
                                    label: 'Option Value 1',
                                },
                                properties: {
                                    value: {
                                        type: 'string',
                                    },
                                    label: {
                                        type: 'string',
                                    },
                                },
                                required: [],
                            },
                        },
                    },
                    required: [],
                },
            },
        },
        {
            title: 'Styles',
            controls: {
                type: 'autoform',
                schema: {
                    properties: {
                        margin: {
                            type: 'string',
                            default: MARGIN,
                        },
                        padding: {
                            type: 'string',
                            default: PADDING,
                        },
                    },
                    required: [],
                },
            },
        },
    ]
};

export default SelectPlugin;