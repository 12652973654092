import { CellPlugin } from '@react-page/editor';
import { Button } from 'react-bootstrap';
import { faCreditCard } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

type Data = {
  text: string,
  value: string
}

const LABEL = 'For Office Use Only';

const TylerEaglePOSRedirectPlugin: CellPlugin<Data> = {
  Renderer: ({ data }) => (
        <div className="mx-auto"><Button variant="outline-secondary" onClick={() => {window.location.href = data.value}}><FontAwesomeIcon icon={faCreditCard} style={{ marginRight: '8px' }} /> {data.text}</Button></div>
  ),
  id: 'tylerEaglePOSRedirectPlugin',
  title: 'Tyler Eagle POS Redirect',
  description: 'Tyler Eagle POS Redirect button.',
  version: 1,
  controls: {
    type: 'autoform',
    schema: {
      properties: {
        text: {
          type: 'string',
          default: LABEL,
        },
      },
      required: ['text'],
    },
  },
};

export default TylerEaglePOSRedirectPlugin;