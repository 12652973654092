import { CellPlugin } from '@react-page/editor';
import React from 'react';

// use a type here, not an interface
type Data = {
  text: string,
  fontColor: string,
  fontSize: string,
  fontStyle: string,
  fontWeight: string,
  textAlign: string,
  textDecoration: string,
  textTransform: string,
  fullStoryMask: boolean,
  margin: string,
  padding: string,
}

const FONT_COLOR = '#414141';
const FONT_SIZE = '14px';
const FONT_STYLE = 'normal';
const FONT_WEIGHT = 'normal';
const TEXT_ALIGN = 'left';
const TEXT_DECORATION = 'none';
const TEXT_TRANSFORM = 'none';
const MARGIN = '0px';
const PADDING = '0px';
const FULL_STORY_MASK = false;

const checkStyleForDefault = (style:string, defaultStyle:string) => {
  return style && style !== ''?style:defaultStyle;
}

const checkStyleForDefault2 = (style:number|undefined, defaultStyle:number) => {
  return style?style:defaultStyle;
}

const checkStyleForDefault3 = (defaultStyle:string) => {
  return defaultStyle as any;
}

const PlainTextPlugin: CellPlugin<Data> = {
  Renderer: ({ data }) => (
        <p style={{
          color:checkStyleForDefault(data.fontColor, FONT_COLOR),
          fontSize:checkStyleForDefault(data.fontSize, FONT_SIZE),
          fontStyle:checkStyleForDefault(data.fontStyle, FONT_STYLE),
          fontWeight:checkStyleForDefault3(data.fontWeight),
          textAlign:checkStyleForDefault3(data.textAlign),
          textDecoration:checkStyleForDefault3(data.textDecoration),
          textTransform:checkStyleForDefault3(data.textTransform),
          margin:checkStyleForDefault(data.margin, MARGIN),
          padding:checkStyleForDefault(data.padding, PADDING),
        }} className={data.fullStoryMask ? "fs-mask" : ""}>{data.text}</p>
  ),
  id: 'plainTextPlugin',
  title: 'Plain Text',
  description: 'Basic plain body text.',
  version: 1,
  controls: [
    {
      title: 'Labels',
      controls: {
        type: 'autoform',
        schema: {
          properties: {
            text: {
              type: 'string',
              default: '',
            },
            fullStoryMask:{
                type: 'boolean',
                default: FULL_STORY_MASK
            },
          },
          required: ['text'],
        },
      },
    },
    {
      title: 'Styles',
      controls: {
        type: 'autoform',
        schema: {
          properties: {
            fontColor: {
              type: 'string',
              default: FONT_COLOR,
            },
            fontSize: {
              type: 'string',
              default: FONT_SIZE,
            },
            fontStyle: {
              type: 'string',
              default: FONT_STYLE,
            },
            fontWeight: {
              type: 'string',
              default: FONT_WEIGHT,
            },
            textAlign: {
              type: 'string',
              default: TEXT_ALIGN,
            },
            textDecoration: {
              type: 'string',
              default: TEXT_DECORATION,
            },
            textTransform: {
              type: 'string',
              default: TEXT_TRANSFORM,
            },
            margin: {
              type: 'string',
              default: MARGIN,
            },
            padding: {
              type: 'string',
              default: PADDING,
            },
          },
        },
      },
    },
  ]
};

export default PlainTextPlugin;