import {
    GET_USER_REQUEST, GET_USER_SUCCESS, GET_USER_FAILURE,
    SAVE_USER_REQUEST, SAVE_USER_SUCCESS, SAVE_USER_FAILURE
} from '../actions/users';

export default function users(state = {
    isFetching: false,
    isSaving: false,
    errorMessage: '',
    user: null,
    actionResult: null,
}, { type, payload }) {
   
    switch (type) {
        case GET_USER_REQUEST:
            return Object.assign({}, state, {
                isFetching: true,
                user: null,
                actionResult: { type: GET_USER_REQUEST, result: null, token: payload.actionToken },
            });
        case GET_USER_SUCCESS:
            return Object.assign({}, state, {
                user: payload.user,
                isFetching: false,
                actionResult: { type: GET_USER_REQUEST, result: GET_USER_SUCCESS, token: payload.actionToken },
            });
        case GET_USER_FAILURE:
            return Object.assign({}, state, {
                errorMessage: payload.error,
                isFetching: false,
                actionResult: { type: GET_USER_REQUEST, result: GET_USER_FAILURE, token: payload.actionToken },
            });
        case SAVE_USER_REQUEST:
            return Object.assign({}, state, {
                isSaving: true,
                actionResult: { type: SAVE_USER_REQUEST, result: null, token: payload.actionToken }
            });
        case SAVE_USER_SUCCESS:
            if (true) {
                return Object.assign({}, state, {
                    user: payload.user,
                    isSaving: false,
                    actionResult: { type: SAVE_USER_REQUEST, result: SAVE_USER_SUCCESS, token: payload.actionToken }
                });         
            }   
        case SAVE_USER_FAILURE:
            return Object.assign({}, state, {
                errorMessage: payload.error,
                isSaving: false,
                actionResult: { type: SAVE_USER_REQUEST, result: SAVE_USER_FAILURE, token: payload.actionToken }
            });
        default:
            return state;
    }
}
