import { CellPlugin } from '@react-page/editor';
import MultiSearchResults, {
    TAX_TYPE_LABEL, HIDE_TAX_TYPE, ACCOUNT_NUMBER_LABEL, OWNER_LABEL, BALANCE_DUE_LABEL, STATUS_LABEL, PAYMENT_AMOUNT_LABEL, ACTIONS_LABEL,
    HEADER_BACKGROUND_COLOR, HEADER_PADDING, MARGIN, PADDING
} from './components/MultiSearchResults';

type Data = {
    title: string,
    taxType: string,
    hideTaxType: boolean,
    accountNumber: string,
    owner: string,
    balanceDue: string,
    staticPaymentAmount: boolean,
    isSchoolTaxPartial: boolean,
    isTaxPartial: boolean,
    isPerCapitaTaxPartial: boolean,
    status: string,
    paymentAmount: string,
    actions: string,
    headerBackgroundColor: string,
    headerPadding: string,
    margin: string,
    padding: string,
}

const MultiSearchResultsPlugin: CellPlugin<Data> = {
    Renderer: ({ data }) => (
        <MultiSearchResults
            taxTypeLabel={data.taxType}
            hideTaxType={data.hideTaxType}
            accountNumberLabel={data.accountNumber}
            ownerLabel={data.owner}
            balanceDueLabel={data.balanceDue}
            statusLabel={data.status}
            staticPaymentAmount={data.staticPaymentAmount}
            isSchoolTaxPartial={data.isSchoolTaxPartial}
            isTaxPartial={data.isTaxPartial}
            isPerCapitaTaxPartial={data.isPerCapitaTaxPartial}
            actionsLabel={data.actions}
            paymentAmountLabel={data.paymentAmount}
            margin={data.margin}
            padding={data.padding}
            headerBackgroundColor={data.headerBackgroundColor}
            headerPadding={data.headerPadding}

        />
    ),
    id: 'multiSearchResultsPlugin',
    title: 'Multi Search Results',
    description: 'Multi search table results',
    version: 1,
    controls: [
        {
            title: 'Labels',
            controls: {
                type: 'autoform',
                schema: {
                    properties: {
                        taxType: {
                            type: 'string',
                            default: TAX_TYPE_LABEL,
                        },
                        hideTaxType: {
                            type: 'boolean',
                        },
                        accountNumber: {
                            type: 'string',
                            default: ACCOUNT_NUMBER_LABEL,
                        },
                        owner: {
                            type: 'string',
                            default: OWNER_LABEL,
                        },
                        staticPaymentAmount: {
                            type: 'boolean'
                        },
                        isSchoolTaxPartial: {
                            type: 'boolean'
                        },
                        isTaxPartial: {
                            type: 'boolean'
                        },
                        isPerCapitaTaxPartial: {
                            type: 'boolean'
                        },
                        balanceDue: {
                            type: 'string',
                            default: BALANCE_DUE_LABEL,
                        },
                        paymentAmount: {
                            type: 'string',
                            default: PAYMENT_AMOUNT_LABEL,
                        },
                        status: {
                            type: 'string',
                            default: STATUS_LABEL,
                        },
                        actions: {
                            type: 'string',
                            default: ACTIONS_LABEL,
                        }
                    },
                    required: [],
                },
            },
        },
        {
            title: 'Styles',
            controls: {
                type: 'autoform',
                schema: {
                    properties: {
                        margin: {
                            type: 'string',
                            default: MARGIN,
                        },
                        padding: {
                            type: 'string',
                            default: PADDING,
                        },
                        headerBackgroundColor: {
                            type: 'string',
                            default: HEADER_BACKGROUND_COLOR,
                        },
                        headerPadding: {
                            type: 'string',
                            default: HEADER_PADDING,
                        }
                    },
                    required: [],
                },
            },
        },
    ],
};

export default MultiSearchResultsPlugin;