import { useRef, useState } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuestionCircle } from '@fortawesome/pro-regular-svg-icons';
import { Overlay } from "react-bootstrap";
import AchSampleCheck from '../assets/img/achSampleCheck.png';

export default () => {
    const [show, setShow] = useState(false);
    const target = useRef(null);

    return (
        <>
            <FontAwesomeIcon icon={faQuestionCircle} forwardedRef={target} onMouseOver={() => setShow(true)} onMouseOut={() => setShow(false)} style={{ color: "#8c8c8c", marginLeft: "8px" }} />
            <Overlay target={target.current} show={show} placement="top">
                {(props) => (
                    <div id="ach-help-icon" className="tooltip ach-help" {...props}>
                        <div style={{ width:"350px" }}>
                            <img src={AchSampleCheck} alt="ACH Sample Check Image" />
                        </div>
                    </div>
                )}
            </Overlay>
        </>
    );
};